<div class="body_inner">
  <div class="">
    <div class="row">
      <div class="topheader">
        <div class="topheadertitle">
          <span class="menu_icon CRMClients fa fa-user"></span>
          <h3 class="report_page_title">
            <p class="page_title_cont">Clients</p>
            <p class="page_sub_title">{{clientData?.ClientName}}</p>
          </h3>
        </div>

        <div class="topdownheader ">
          <div class="">

            <div class="block">
              <label class="labelName">Code</label>
              <p>{{clientData?.ClientCode}}</p>
            </div>

            <div class="block">
              <label class="labelName">Phone</label>
              <p>{{clientData?.Phone}}</p>
            </div>

            <div class="block">
              <label class="labelName">Email</label>
              <p>{{clientData?.Email}}</p>
            </div>

            <div class="block">
              <label class="labelName">Owner</label>
              <p>
                <img src="/assets/images/businessman.png" class="iconClass">
                <span class="ownerHeading">{{clientData?.OwnerName}}</span>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class=" ">
    <div class="row">
      <div class="col-md-8 lowpadding-right lowpadding-left-0">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>
              <li class="realted">
                <a class="" (click)="OnRelated()" [ngClass]="{'active': RealtedOn===true}">Related</a>
              </li>
              <li class="realted">
                <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Details</a>
              </li>
              <li class="realted">
                <a class="" (click)="onQuote()" [ngClass]="{'active': quoteOn===true}">Quotes</a>
              </li>
              <li class="realted">
                <a class="" (click)="onOrder()" [ngClass]="{'active': orderOn===true}">Orders</a>
              </li>
              <li class="realted">
                <a class="" (click)="onInvoice()" [ngClass]="{'active': invoiceOn===true}">Invoices</a>
              </li>
              <li class="realted">
                <a class="" (click)="onCollection()" [ngClass]="{'active': collectionOn===true}">Collections</a>
              </li>
              <li class="realted">
                <a class="" (click)="onSubscriptions()" [ngClass]="{'active': subscriptions===true}">Subscriptions</a>
              </li>
            </ul>
          </div>

          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <app-client-related [searchKeys]="searchKeys" *ngIf="RealtedOn===true"></app-client-related>
            </li>
            <li class="nav-item disabled_form">
              <app-client-detail [clientData]="clientData" *ngIf="DetailOn===true && clientData?.CustomerId">
              </app-client-detail>
            </li>
            <li class="nav-item">
              <app-order-related *ngIf="orderOn===true" [searchKeys]="searchKeys"></app-order-related>
            </li>
            <li class="nav-item">
              <app-quotation-related *ngIf="quoteOn===true" [searchKeys]="searchKeys"></app-quotation-related>
            </li>
            <li class="nav-item">
              <app-invoice-related *ngIf="invoiceOn===true" [searchKeys]="searchKeys"></app-invoice-related>
            </li>
            <li class="nav-item">
              <app-collection-related *ngIf="collectionOn===true" [searchKeys]="searchKeys"></app-collection-related>
            </li>
            <li class="nav-item">
              <app-subscriptions *ngIf="subscriptions===true" [searchKeys]="searchKeys"></app-subscriptions>
            </li>
          </ul>

        </nav>
      </div>

      <div class="col-md-4 bg_white_margin_top no_boxshadow">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>
              <li class="realted">
                <a class="" (click)=onActivity() [ngClass]="{'active': activityOn===true}">Activity</a>
              </li>
            </ul>
          </div>
          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <app-activity *ngIf="activityOn===true"></app-activity>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
</div>