<ng-template #taskResourceModel let-c="close" let-d="dismiss" style="pos-rel">
  <div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-bordered dynomic_table">
      <thead>
        <tr>
          <th clas="name_small">Name</th>
          <th class="number_extralong">Work Type</th>
          <th class="number_short">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let status of resourceForm.ResourceTable; let i=index">
          <td>
            <app-autocomplete [autoCompleteText]="status.autoCompleteText" (setAutoComplete)="setAutoComplete($event)"
              [autoCompleteValue]="status.autoCompleteValue" [key]="i" (fiteredData)="fiteredData($event)" [filteredOptions]="status.filteredOptions">
            </app-autocomplete>
          </td>
          <td>
            <input class="form-control" type="text" [(ngModel)]="status.WorkType" name="newAttribute" placeholder="Work Type"
              (input)="addFieldValue(i)" />
          </td>
          <td>
            <div class="btn-group" dropdown>
              <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
              <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <!-- <button type="button" class="btn btn-default" id="btnCancel" (click)="resetForm()">Cancel</button> -->
      <button type="submit" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
    </div>
  </div>
</ng-template>

<div class="">
  <div class="buttonHeader text-right mb-3">
      <button class="buttonNew" (click)="openTaskResourceForm(taskResourceModel)">Add/Edit</button>
  </div>
  <app-htmltable [tableData]="tableData" [columnName]="columnName"></app-htmltable>
</div>
