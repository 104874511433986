import { Router } from '@angular/router';
import { ProjectService } from './../../../../services/project/project.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-related',
  templateUrl: './project-related.component.html',
  styleUrls: ['./project-related.component.css']
})
export class ProjectRelatedComponent implements OnInit {

  @Input() searchKeys;
  projects: Array<any> = [];
  constructor(
    private projectService: ProjectService,
    public router: Router
  ) { }

  ngOnInit() {
    this.getAllProjects();
  }

  getAllProjects() {
    this.projectService.getAllProjectType().subscribe(data => {
      this.projects = data;
    });
  }

  gotoDetailPage(id) {
    const route = 'projectDetail/' + id;
    this.router.navigate([route]);
  }

}
