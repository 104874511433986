import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { CommonService } from '../../../services/common/common/common.service';
import { Component, OnInit } from '@angular/core';
import { VariableService } from '../../../services/VariableService/variable.service';
import * as Utils from '../../../../../common/utils';
import { Page } from '../../../../../models/page';
import { ColumnFilterComponent } from '../../../../../common/columnfilter/columnfilter.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-requestforquotes',
  templateUrl: './requestforquotes.component.html',
  styleUrls: ['./requestforquotes.component.css']
})
export class RequestforquotesComponent implements OnInit {
  showSpinner: Boolean = true;
  isFilterLogic: boolean;
  page = new Page();
  selectedPage: any = {};
  filterObj: any;
  public showFilter: Boolean = false;
  filterArray: Array<any> = [];
  filterLogic = '';
  columnNames: any[];
  rfqData: Array<any> = [];
  temp: any = [];
  selectedPageFilter: any;
  pageFilters: any[];
  getPageFilterCalled = false;

  searchStr: String = '';
  searchValueSet = false;
  searchFilterAdded = false;

  newListName = ''; AccessType = '';

  constructor(
    private commonService: CommonService,
    private topnavbarService: TopnavbarService,
    private variableService: VariableService,
    private modalService: NgbModal,
    private cService: CommonService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
    this.filterArray = [];
    this.filterObj = {};
    this.page.size = 15;
    this.selectedPageFilter = {};
  }

  ngOnInit() {
    this.columnNames = [];
    this.getAllColumns();
    this.getPageFilters();
  }

  getRFQData(reqOptions?: any) {
    this.variableService.setSearchKeys({
      type: this.selectedPage.pagename
    });

    if (!reqOptions) {
      reqOptions = {};
      reqOptions.PageNumber = 1;
      reqOptions.PageSize = this.page.size;
      reqOptions.TotalRecords = null;
    } reqOptions.MasterType = this.selectedPage.pagename;
    if (this.searchStr && this.searchStr.length > 1) {
      this.searchFilterAdded = true;
      reqOptions.PageNumber = 1;
      const columnNames = [];
      this.columnNames.map(column => {
        columnNames.push(column.Name);
      });

      if (this.filterArray.length === 1) {
        this.filterLogic += `1 AND ${this.filterArray.length + 1}`;
      } else if (this.filterArray.length > 1) {
        this.filterLogic += ` AND ${this.filterArray.length + 1}`;
      }
      this.filterArray.push({
        columnName: `concat(${columnNames.join(',')})`,
        sqlOperator: 'LIKE',
        value: `%${this.searchStr}%`
      });
    }
    reqOptions.Filter = {
      logic: this.filterLogic,
      FilterItems: this.filterArray
    };
    this.commonService.getTableData(reqOptions).subscribe(res => {
      const rows = res.Data;
      this.page = {
        totalElements: res.TotalRecords,
        totalPages: Math.ceil(
          res.TotalRecords / reqOptions.PageSize
        ),
        size: this.page.size,
        pageNumber: reqOptions.PageNumber - 1
      };
      this.showSpinner = false;
      this.temp = res;
      for (const row of rows) {
        row.height = Math.floor(Math.random() * 80) + 50;
      }
      this.rfqData = rows;
      if (this.getPageFilterCalled) {
        this.showFilter = true;
        this.getPageFilterCalled = false;
      }
      if (this.searchStr && this.searchStr.length > 1) {
        this.filterArray.pop();
        this.setLogic();
      }
    },
      err => {
        this.showSpinner = false;
        if (this.searchStr && this.searchStr.length > 2) {
          this.filterArray.pop();
          this.setLogic();
        }
      }
    );
  }

  setSearchStr(val) {
    this.searchStr = val;
    if (this.searchStr.length > 1) {
      this.showSpinner = true;
      this.getRFQData();
    } else if (!this.searchStr) {
      if (this.searchFilterAdded) {
        this.filterArray.pop();
        this.setLogic();
        this.searchFilterAdded = false;
      }
      this.getRFQData();
    }
  }

  setLogic() {
    if (this.filterArray.length > 1) {
      let l: any;
      // this.filterArray.splice(0, 1);
      this.filterArray.forEach((filter, i) => {
        if (i > 0) {
          l += 'AND ' + (i + 1);
        } else {
          l = '1 ';
        }
      });
      this.applyFilterLogic(l);
    } else {
      this.applyFilterLogic('');
    }
  }

  getAllColumns() {
    this.commonService.getColumnData(this.selectedPage.pagename).subscribe(res => {
      this.columnNames = res.ColumnNames;
      this.filterObj = Utils.getColumnsObject(res.ColumnNames);
    });
  }

  // Filter Related Functions
  toggle() {
    this.showFilter = !this.showFilter;
    this.rfqData = [...this.rfqData];
  }

  applyFilter(arr: any[]) {
    this.filterArray = arr;
    if (this.selectedPageFilter.FilterID) {
      this.createNewPageFilter(
        this.selectedPageFilter.FilterName,
        0,
        { FilterItems: arr, logic: this.filterLogic },
        this.selectedPageFilter.FilterID,
        1
      );
    } else {
      this.showSpinner = true;
      this.getRFQData();
    }
  }

  applyFilterLogic(logic: string) {
    this.filterLogic = logic;
  }

  addLogic() {
    this.isFilterLogic = true;
  }

  removeLogic() {
    this.filterLogic = '';
    this.isFilterLogic = false;
  }

  custommenu(content) {
    const columnFilCMP = this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static'
    });
  }

  makeColumnsVisible(data) {
    const cols = [];
    data.oldCols.forEach(c => {
      if (data.newCols[c.Name]) {
        c.Visible = data.val;
        cols.push(c);
      } else {
        cols.push(c);
      }
    });
    this.columnNames = cols;
  }

  /**Page filter (header level) */
  getPageFilterData(pageFilter) {
    this.showFilter = false;
    this.getPageFilterCalled = true;
    this.selectedPageFilter = pageFilter;
    const filters = JSON.parse(pageFilter.FilterQuery);

    this.filterLogic = filters && filters.logic ? filters.logic : '';
    this.filterArray =
      filters && filters.FilterItems ? filters.FilterItems : [];
    this.showSpinner = true;
    this.getRFQData();
  }

  getPageFilters(b = false) {
    this.cService.getPageFilter(this.selectedPage.pagename).subscribe(
      res => {
        this.pageFilters = [];

        this.pageFilters.push({
          FilterName: 'All',
          FilterID: null,
          FilterQuery: null,
          MasterType: this.selectedPage.pagename,
          AccessType: 1
        });
        res.forEach(obj => {
          this.pageFilters.push(obj);
        });
        if (!b && !this.selectedPageFilter.FilterID) {
          this.selectedPageFilter = this.pageFilters[0];
        } else {
          if (!b) {
            this.selectedPageFilter = this.pageFilters[
              this.pageFilters.length - 1
            ];
          }
        }
        this.showSpinner = true;
        this.getRFQData();
      },
      err => {
        console.log('err===', err);
      }
    );
  }

  createNewPageFilterModal(content) {
    this.modalService
      .open(content, { size: 'sm', backdrop: 'static' })
      .result.then(result => {
        // console.log(`Closed with: ${result}`);
      });
  }

  createNewPageFilter(
    name,
    accessType,
    filterQuery = {},
    filterID = null,
    mode = 0
  ) {
    this.cService
      .createPageFilter({
        FilterID: filterID,
        FilterName: name,
        AccessType: parseInt(accessType, 10),
        Mode: mode,
        FilterQuery: JSON.stringify(filterQuery),
        MasterType: this.selectedPage.pagename
      })
      .subscribe(
        res => {
          this.selectedPageFilter.FilterQuery = JSON.stringify(filterQuery);
          if (!filterID) {
            if (name !== 'All') {
              this.pageFilters.push(res);
              this.selectedPageFilter = res;
              $('#btnCancel').trigger('click');
              this.newListName = '';
              this.AccessType = '';
            }
          }

          this.getPageFilterData(this.selectedPageFilter);
        },
        err => {
          console.log('err===', err);
        }
      );
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}
