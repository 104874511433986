import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-milestone-related-detail',
  templateUrl: './milestone-related-detail.component.html',
  styleUrls: ['./milestone-related-detail.component.css']
})
export class MilestoneRelatedDetailComponent implements OnInit {
@Input() searchKeys;
  constructor() { }

  ngOnInit() {
  }

}
