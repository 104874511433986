import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "page-filter",
  templateUrl: "./pagefilter.component.html",
  styleUrls: ["./pagefilter.component.css"]
})
export class PageFilterComponent implements OnInit {
  @Input() pageFilters;
  @Input() selectedPageFilter;
  @Output() getPageFilterData = new EventEmitter();
  ngOnInit() {}
}
