import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';
@Injectable()
export class AccountService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) {
  }

  // Create Method for Accounts
  addAccount(form) {
    return this.apiService.post('api/account/create', form);
  }

  // get Method by Id
  editByAccountId(accountId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/account/getbyid?Id=${accountId}&MasterType=${q.type}&`);
  }

  getByAccountId(accountId, Account) {
    return this.apiService.get(`api/account/getbyid?Id=${accountId}&MasterType=${Account}&`);
  }

  //  Update Method for Accounts
  updateAccount(form) {
    return this.apiService.put('api/account/edit', form);
  }
  authorize(data) {
    return this.apiService.post(`api/oauth/authorize`,data);
  }
  updateToken(data){
    return this.apiService.post(`api/oauth/token`,data);
  }
}
