import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';
@Injectable()
export class ConfigurationService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  /**Milestone Service */
  addMilestoneConfig(form) {
    return this.apiService.post('api/milestoneConfig/create', form);
  }
  getMilestoneConfig() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/milestoneConfig/getall?id=${q.paramId}&MasterType=${q.type}&`);
  }

  /**Status Configuration Service */
  addStatusConfig(form) {
    return this.apiService.post('api/statusConfig/create', form);
  }
  getStatusConfig(Value) {
    return this.apiService.get(`api/statusConfig/getall?MasterType=${Value}&`);
  }

  /**Task Template Service */
  addTaskTemplate(form) {
    return this.apiService.post('api/taskTemplate/create', form);
  }

  editTaskTemplate(form) {
    return this.apiService.put('api/taskTemplate/edit', form);
  }
  getTaskTemplate() {
    return this.apiService.get(`api/taskTemplate/getall?`);
  }

  getTaskTemplateList() {
    return this.apiService.get('api/taskTemplate/GetTaskTemplateNamesList' + '?prefixText=' + '&Id=' + '&');
  }

  getTaskTemplateById(templateId) {
    return this.apiService.get(`api/taskTemplate/getbyid?Id=${templateId}&`);
  }

  /**Territory Service */
  addTerritory(form) {
    return this.apiService.post('api/territory/create', form);
  }
  updateTerritory(form) {
    return this.apiService.put('api/territory/create', form);
  }

  getTerritory() {
    return this.apiService.get(`api/territory/getall?`);
  }

  editTerritoryById(territoryId) {
    return this.apiService.get(`api/territory/getbyid?Id=${territoryId}&`);
  }

  getTerritoryLookupList() {
    return this.apiService.get('api/territory/GetTerritoryNamesList' + '?prefixText=' + '&Id=' + '&');
  }

}
