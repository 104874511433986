<div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-header">
    <h4 class="modal-title text-center">Select Payment Method</h4>
</div>
<div class="modal-body custom-btn-group">
    <form [formGroup]="paymentForm" novalidate>
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-primary active">
                <input type="radio" [(ngModel)]="type" name="type" [value]="'cash'"> Cash
            </label>
            <label class="btn btn-primary">
                <input type="radio" [(ngModel)]="type" name="type" [value]="'cc'"> Credit Card
            </label>
            <label class="btn btn-primary">
                <input type="radio" [(ngModel)]="type" name="type" [value]="'gc'"> Gift Card
            </label>
            <label class="btn btn-primary">
                <input type="radio" [(ngModel)]="type" name="type" [value]="'op'"> Online Order
            </label>
        </div>
        <div class="input-group mt-4" >
            <div class="row">
                <div class="col-lg-6">
                    <label for="text">Payment Reference</label>
                </div>
                <div class="col-lg-6">
                    <input type="text" class="form-control" formControlName="refNo">
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="" (click)="saveData()">Save</button>
</div>