import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';

@Injectable()
export class TopnavbarService {
  page: any[] = [];
  constructor(private apiService: ApiService) { }

  getCompanyList() {
    return this.apiService.get(`api/listitems/getcompanies?`);
  }

  getCurrencyPropertiesList() {
    return this.apiService.get(`api/companyProperties/getCompanyProperties?`);
  }

  setPage(page: any) {
    this.page = page;
  }

  getSelectedPage() {
    return this.page;
  }

  getMenuItems() {
    return this.apiService.get('api/useraccess/getall?');
  }

  clickToCall(from, to) {
    return this.apiService.get(`api/clickToCall/clickToCall?FromNumber=${from}&ToNumber=${to}&`);
  }
}
