import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractService } from '../../../services/contract/contract.service';
import { VariableService } from '../../../services/VariableService/variable.service';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {
  contractData: any;
  RealtedOn = false;
  DetailOn = true;
  activeTab = 'Related';
  showSpinner: Boolean = true;
  selectedPage: any = {};
  contractorId:any;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private contractService: ContractService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
    private toastr: ToastrService
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {

      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMContract',
      });

      this.contractService.contractById(params.id).subscribe(data => {
        this.contractData = data;
        this.showSpinner = false;
        this.contractorId = params.id;
      });
    });
  }

  OnRelated() {
    this.RealtedOn = true;
    this.DetailOn = false;
  }
  OnDetail() {
    this.DetailOn = true;
    this.RealtedOn = false;
  }

  onActiveTab(tab) {
    this.activeTab = tab;
  }

  gotoDetailPage(id) {
    const route = 'accountDetail/' + id;
    this.router.navigate([route]);
  }
  reNew(){
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.contractService.reNew({ContractId:this.contractData.ContractId,CompanyId:user.CompanyId}).subscribe((res)=>{
      this.showSpinner = true;
      this.contractService.contractById(this.contractorId).subscribe(data => {
        this.contractData = data;
        this.showSpinner = false;
        this.toastr.success('New opportunity created successfully.');
      });
    })
  }
}
