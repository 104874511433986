<div class="body_inner">
  <div class="">
    <div class="topheader">
      <div class="topheader">
        <div class="topheadertitle">
          <span class="menu_icon CRMResources fa fa-users">
          </span>
          <h3 class="report_page_title">
            <p class="page_sub_title">{{resourceData?.Name}}</p>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>

  <div class="row">
    <div class="col-md-8 lowpadding-right">
      <nav class="navbar navbar-default custom_navbar">
        <div class="nav nav-bar-inner disabled_form">
          <app-resource-detail [resourceData]="resourceData" *ngIf="DetailOn===true && resourceData.ResourceID">
          </app-resource-detail>
        </div>
      </nav>
    </div>

    <div class="col-md-4 bg_white_margin_top no_boxshadow">
    </div>
  </div>
</div>