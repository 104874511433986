<div class="">
    <form [formGroup]="newEventForm" novalidate (ngSubmit)="onSubmit(newEventForm)">
        <div class="form-group">
            <label for="name">Subject</label>
            <input type="textbox" class="form-control" formControlName="Subject">
        </div>
        <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="text">Start Date</label>
                <input type="text" class="form-control" formControlName="StartDate" value="{{ newEventForm.get('StartDate').value | date: 'dd-MM-yyyy' }}"
                    #bdp="bsDatepicker" bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY' }" [bsValue]="newEventForm.get('StartDate').value">
                <p class="inputdate" (click)="bdp.toggle()">
                    <i class="glyphicon glyphicon-calendar"></i>
                </p>
            </div>

            <div class="form-group  col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="name">Start Time</label>
                <div class="eventCSS">
                    <select class="form-control dropdownCss" (change)="onStartTimeChange($event.target.value)">
                        <option value="0">--None--</option>
                        <option *ngFor="let time of timeData" value={{time.timeId}}> {{time.timeValue}}</option>
                    </select>
                    <input class="form-control inputName" onfocus="this.select()" type="text" [(ngModel)]="currentStartTime"
                        formControlName="StartTime">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group  col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="text">End Date</label>
                <input type="text" class="form-control" formControlName="EndDate" #dp="bsDatepicker" value="{{ newEventForm.get('EndDate').value | date: 'dd-MM-yyyy' }}"
                    bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
                <p class="inputdate" (click)="dp.toggle()">
                    <i class="glyphicon glyphicon-calendar"></i>
                </p>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="name">End Time</label>
                <div class="eventCSS">
                    <select class="form-control dropdownCss" (change)="onEndTimeChange($event.target.value)">
                        <option value="0">--None--</option>
                        <option *ngFor="let time of timeData" value={{time.timeId}}> {{time.timeValue}}</option>
                    </select>
                    <input class="form-control inputName" onfocus="this.select()" type="text" [(ngModel)]="currentEndTime"
                        formControlName="EndTime">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 two_input">
                <label for="name">Name</label>

                <div class="select_cont mini_cont">
                    <ng-select [items]="NameTypeList" bindLabel="name" bindValue="name" lass="form-control selectType half_select"
                        [clearable]="false" formControlName="NameType" id="contactLeadTo">
                        <ng-template ng-label-tmp let-item="item">
                            <span class="{{item.avatar}}"></span>
                            <span style="padding-left:10px;">{{item.name}}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <span class="{{item.avatar}}"></span>
                            <span style="padding-left:10px;">{{item.name}}</span>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="select_cont max_cont">
                    <mat-form-field class="example-full-width app-input-no-underline material_normalise customMaterial"
                        id="contactLeadTo">
                        <input #contactLeadtextInput matInput [matAutocomplete]="auto" (input)="getLeadContactList($event.target.value)"
                            formControlName="ContactId">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayContactFn">
                            <mat-option *ngFor="let contactLead of contactLeadFilterOption" [value]="contactLead">
                                <span>{{contactLead.Text}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group  col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="tex">Meeting Type</label>
                <select class="form-control" name="" id="">
                    <option>Google Meet</option>
                    <option>Teams</option>
                    <option>On site</option>
                    <option>Phone Call</option>
                    <option>Others</option>
                </select>
            </div>
            <div class="form-group  col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="tex">Location</label>
                <input type="textbox" class="form-control" formControlName="Location">
            </div>
        </div>
        <div class="row">
            <div class="form-group  col-lg-12 col-md-12 col-sm-12 col-xs-12 two_input" *ngIf="disableDropdown===true">
                <label for="text">Related To</label>
                <div class="select_cont mini_cont">
                    <ng-select [items]="RelatedTypeList" bindLabel="name" bindValue="name" lass="form-control selectType half_select"
                        [clearable]="false" formControlName="RelatedType" id="contactLeadTo">
                        <ng-template ng-label-tmp let-item="item">
                            <span class="{{item.avatar}}"></span>
                            <span style="padding-left:10px;">{{item.name}}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <span class="{{item.avatar}}"></span>
                            <span style="padding-left:10px;">{{item.name}}</span>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="select_cont max_cont">
                    <mat-form-field class="example-full-width app-input-no-underline material_normalise customMaterial">
                        <input #relatedtextInput matInput [matAutocomplete]="auto" (input)="getRealtedTypeList($event.target.value)"
                            formControlName="RelatedToId">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayRelatedFn">
                            <mat-option *ngFor="let related of relatedTypeFilterOption" [value]="related">
                                <span>{{related.Text}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <span class="customAdd ml-4 mb-4" (click)="openParticipant(open)"><i class="fas fa-plus"></i>
                Add Participant</span>
            <ng-template #open let-c="close" let-d="dismiss" style="pos-rel">
                <div class="close-btn-container pos-abs">
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-header">
                    <h4 class="modal-title text-center">Add Participant</h4>
                </div>
                <div class="modal-body">
                    <div class="extra-pad">
                        <div class="">
                            <div class="">

                                <div class="row">
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <table class="table table-striped table-bordered dynomic_table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Type</th>
                                                    <th>ASSIGNED TO</th>
                                                    <th>ACTION</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let list of eventTableForm.ListOfEventTable; let i=index">
                                                    <td>
                                                        {{i+1}}
                                                    </td>
                                                    <td>
                                                        <select [(ngModel)]="list.Type" class="form-control" (change)="onTypeSelect($event.target,i);addFieldValue(i)"
                                                            [ngModelOptions]="{standalone: true}">
                                                            <option value="user">Employees</option>
                                                            <option value="contact">Contacts</option>
                                                        </select>
                                                    </td>

                                                    <td>
                                                        <app-autocomplete [autoCompleteText]="list.autoCompleteText"
                                                            (setAutoComplete)="setAutoComplete($event)"
                                                            [autoCompleteValue]="list.autoCompleteValue" [key]="i"
                                                            (fiteredData)="fiteredData($event)" [filteredOptions]="list.filteredOptions">
                                                        </app-autocomplete>
                                                    </td>

                                                    <td class="text-center">
                                                        <div class="btn-group">
                                                            <a class="action_btn add_btn" href="javascript:void(0)"
                                                                (click)="addFieldValue()">&#43;</a>
                                                            <a class="action_btn del_btn" href="javascript:void(0)"
                                                                (click)="deleteFieldValue(i)">&times;</a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')">Cancel</button>
                    <button type="button" class="btn btn-primary" id="btnSave" (click)="onAddParticipant()">Add</button>
                </div>
            </ng-template>
        </div>
        <div class="form-group ">
            <label for="text">All-Day Event</label>
            <input type="checkbox" class="custom_checkbox" formControlName="IsAllDayEvent">
        </div>

        <div class="text-right">
            <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!newEventForm">Save</button>
        </div>
    </form>
</div>