import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-campaign-detail',
  templateUrl: './campaign-detail.component.html',
  styleUrls: ['./campaign-detail.component.css']
})
export class CampaignDetailComponent implements OnInit {
  @Input() campaignData: any;
  campaignForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.campaignForm = this.fb.group({
      CampaignId: null,
      CampaignName: '',
      CampaignOwner: '',
      Status: '',
      ParentCampaignId: '',
      StartDate: new Date(),
      EndDate: new Date(),
      Type: '',
      Description: '',
      NumSentCampaign: null,
      BudgetCost: null,
      ExpectedResponse: null,
      ActualCost: null,
      ExpectedRevenue: null,
      IsActive: false,

      ParentCampaign: '',
      CampaignStatus: '',
      CampaignType: '',
      CampaignOwnerName: '',

    });
    this.campaignForm.patchValue(this.campaignData);
  }
}
