<div class="body_inner">
    <div class="">
        <div class="topheader">
            <div class="topheader">
                <div class="topheadertitle">
                    <span class="menu_icon CRMOpportunities glyphicon glyphicon-bookmark">
                    </span>
                    <h3 class="report_page_title">
                        <p class="page_title_cont"> Opportunities</p>
                        <p class="page_sub_title">{{opportunityData?.OpportunityName}}</p>
                    </h3>
                </div>
                <div class="">
                    <div class="topdownheader ">
                        <div class="">
                            <div class="block">
                                <label class="labelName">Account Name</label>
                                <p>
                                    <a class="dottedLine" (click)="gotoDetailPage(opportunityData.AccountId)" href="javascript:void(0)">{{opportunityData?.AccountName}}</a>
                                </p>
                            </div>
                            <div class="block">
                                <label class="labelName">Close Date</label>
                                <p>{{opportunityData?.CloseDate | date:'dd-MM-yyyy'}}</p>
                            </div>
                            <div class="block">
                                <label class="labelName">Amount</label>
                                <p>{{opportunityData?.Amount}}</p>
                            </div>
                            <div class="block">
                                <label class="labelName">Opportunity Owner</label>
                                <p>
                                    <img src="/assets/images/businessman.png" class="iconClass">
                                    <span class="ownerHeading">{{opportunityData?.OpportunityOwnerName}}</span>
                                </p>
                            </div>

                            <!-- <div class="block">
                                <label class="labelName">Skype</label>
                                <p (click)="skypeChat(contactData?.SkypeID)">
                                    <span class="ownerHeading fab fa-skype"></span>
                                    <span class="skype-chat ownerHeading" style="cursor: pointer;">{{contactData?.SkypeID}}</span>
                                </p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="steps_bar" *ngIf="!showSpinner">
            <app-opportunity-stage [opportunitystageData]=" opportunitystageData && opportunitystageData.OpportunityId"
                [convertOpportunityData]="convertOpportunityData">
            </app-opportunity-stage>
        </div>
        <div class="row ">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 lowpadding-right">
                <nav class="navbar navbar-default custom_navbar ">
                    <div class="nav nav-bar-inner ">
                        <ul>
                            <li class="realted ">
                                <a class=" " (click)=onActivity() [ngClass]="{ 'active': activityOn===true} ">Activity</a>
                            </li>
                            <li class="realted ">
                                <a class=" " (click)=OnChatter() [ngClass]="{ 'active': newChatterOn===true} ">Chatter</a>
                            </li>
                            <li class="realted ">
                                <a class=" " (click)="OnDetail() " [ngClass]="{ 'active': DetailOn===true} ">Details</a>
                            </li>
                            <li class="realted ">
                                <a class=" " (click)="onRfq() " [ngClass]="{ 'active': rfqOn===true} ">RFQ</a>
                            </li>
                        </ul>
                    </div>
                    <ul class="cl custom_content_tab ">
                        <li class="nav-item ">
                            <app-activity *ngIf="activityOn===true "></app-activity>
                        </li>
                        <li class="nav-item ">
                            <app-chatter *ngIf="newChatterOn===true "></app-chatter>
                        </li>
                        <li class="nav-item disabled_form">
                            <app-opportunity-detail [opportunityData]="opportunityData " *ngIf="DetailOn===true && opportunityData.OpportunityId "></app-opportunity-detail>
                        </li>
                        <li class="nav-item ">
                            <app-rfq-page *ngIf="rfqOn===true"></app-rfq-page>
                        </li>
                    </ul>
                </nav>
            </div>


            <div class="col-md-4 bg_white_margin_top no_boxshadow" style="margin-top: 7px;">
                <div class="tab_title body_inner_col4">
                    <app-new-quote [quoteData]="quoteData"></app-new-quote>
                    <app-tiny-contracts-right *ngIf="opportunityData && opportunityData.ContractId" [contract]="opportunityData"></app-tiny-contracts-right>

                </div>
            </div>
        </div>
        <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
    </div>
</div>