<div class="body_inner">
  <div class="">
    <div class="topheader">
      <div class="topheadertitle">
        <span class="menu_icon CRMRFQ glyphicon glyphicon-pencil">
        </span>
        <h3 class="report_page_title">
          <p class="page_title_cont">Request for Quote</p>
          <p class="page_sub_title">{{rfqData?.JobWork}}</p>
        </h3>
      </div>
    </div>
    <div class="">
      <div class="topdownheader ">
        <div class="">
          <div class="block">
            <label class="labelName">Opportunity Name</label>
            <p>{{rfqData?.DealName}}</p>
          </div>

          <div class="block">
            <label class="labelName">Account Family</label>
            <p>{{rfqData?.AccountName}}</p>
          </div>

          <div class="block">
            <label class="labelName">Deadline</label>
            <p>{{rfqData?.DeadLine|date:'dd-MM-yyyy'}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <nav class="navbar navbar-default custom_navbar">
        <div class="nav nav-bar-inner">
          <ul>
            <li class="realted">
              <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Property</a>
            </li>
          </ul>
        </div>
        <ul class="cl custom_content_tab">
          <li class="nav-item">
            <div class="col-md-8">
              <form [formGroup]="rfqStatusForm" novalidate (ngSubmit)="onSubmit(rfqStatusForm)">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label for="text">Status</label>
                  <p>
                    <select class="form-control" formControlName="Status">
                      <option value=0>--None--</option>
                      <option *ngFor="let status of StatusList" value={{status.Value}}>{{status.Text}}</option>
                    </select>
                  </p>
                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <div class="">
                  <button type="button" class="btn btn-default" id="btnCancel" (click)="reset()">Cancel</button>
                  <button type="submit" class="btn btn-primary" [disabled]="!rfqStatusForm">Save</button>
                </div>
              </form>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
</div>





<!-- <div class="body_inner">
  <div class="">
    <div class="topheader">
      <div class="topheadertitle">
        <span class="menu_icon CRMRFQ glyphicon glyphicon-pencil">
        </span>
        <h3 class="report_page_title">
          <p class="page_title_cont">Request For Quote</p>
          <p class="page_sub_title">{{rfqData?.JobWork}}</p>
        </h3>
      </div>
    </div>
  </div>
  <div class="col-md-8">
    <form [formGroup]="rfqStatusForm" novalidate (ngSubmit)="onSubmit(rfqStatusForm)">
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label for="text">Status</label>
        <p>
          <select class="form-control" formControlName="Status">
            <option value=0>--None--</option>
            <option value="1">Active</option>
            <option value="2">Completed</option>
            <option value="3">In Active</option>
            <option value="3">On Hold</option>
            <option value="3">Planning</option>

          </select>
        </p>
      </div>
      <div class="pull-right">
        <button type="button" class="btn btn-default" id="btnCancel" (click)="reset()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!rfqStatusForm">Save</button>
      </div>
    </form>
  </div>
</div> -->