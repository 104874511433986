<div class="body_inner">
  <div class="padding10">
    <div class="row">
      <div class="topheader">


        <div class="topheadertitle">
          <span class="menu_icon CRMAccounts glyphicon glyphicon-list-alt"></span>
          <h3 class="report_page_title">
            <p class="page_title_cont">Account</p>
            <p class="page_sub_title">{{accountData?.AccountName}}</p>
          </h3>
        </div>

        <div class="">
          <div class="topdownheader ">
            <div class="">

              <div class="block">
                <label class="labelName">Type</label>
                <p>{{accountData?.AccountType}}</p>
              </div>

              <div class="block">
                <label class="labelName">Phone</label>
                <p>{{accountData?.Phone}}</p>
              </div>

              <div class="block">
                <label class="labelName">Website</label>
                <p>{{accountData?.Website}}</p>
              </div>

              <div class="block">
                <label class="labelName">Account Owner</label>
                <p>
                  <img src="/assets/images/businessman.png" class="iconClass">
                  <span class="ownerHeading">{{accountData?.AccountOwnerName}}</span>
                </p>
              </div>

              <div class="block">
                <label class="labelName">Account Site</label>
                <p>{{accountData?.AccountSite}}</p>
              </div>

              <div class="block">
                <label class="labelName">Industry Consulting</label>
                <p>{{accountData?.IndustryName}}</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="">
    <div class="row">

      <div class="col-md-8 lowpadding-right">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>
              <li class="realted">
                <a class="" (click)="OnRelated()" [ngClass]="{'active': RealtedOn===true}">Related</a>
              </li>
              <li class="realted">
                <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Details</a>
              </li>
              <!-- <li class="realted">
                <a class="" (click)="OnNews()" [ngClass]="{'active': NewsOn===true}">NEWS</a>
              </li> -->
            </ul>
          </div>
          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <app-account-related-detail [searchKeys]="searchKeys" *ngIf="RealtedOn===true"></app-account-related-detail>
            </li>
            <li class="nav-item disabled_form">
              <app-account-detail [accountData]="accountData" *ngIf="DetailOn===true && accountData.AccountId"></app-account-detail>
            </li>
            <li class="nav-item">
              <app-news *ngIf="NewsOn===true"></app-news>
            </li>
          </ul>
        </nav>
      </div>

      <div class="col-md-4 bg_white_margin_top no_boxshadow">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>
              <li class="realted">
                <a class="" (click)=onActivity() [ngClass]="{'active': activityOn===true}">Activity</a>
              </li>
              <li class="realted">
                <a class="" (click)=OnChatter() [ngClass]="{'active': newChatterOn===true}">Chatter</a>
              </li>
            </ul>
          </div>
          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <app-activity *ngIf="activityOn===true"></app-activity>
            </li>
            <li class="nav-item">
              <app-chatter *ngIf="newChatterOn===true"></app-chatter>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
</div>