<div class="body_inner">
  <div class="row">
    <div class="topheader col-md-12">
      <div class="topheadertitle">
        <span class="menu_icon CRMCases glyphicon glyphicon-headphones">
        </span>
        <h3 class="report_page_title">
          <p class="page_title_cont">Ticket</p>
          <p class="page_sub_title">{{caseData?.Subject}}</p>
        </h3>
      </div>
    </div>
    <div class="col-md-12">
      <div class="topdownheader ">
        <div class="">
          <div class="block">
            <label class="labelName">Priority</label>
            <p>{{caseData?.PriorityName}}</p>
          </div>

          <div class="block">
            <label class="labelName">Status</label>
            <p>{{caseData?.StatusName}}</p>
          </div>

          <div class="block">
            <label class="labelName">Case Number</label>
            <p>{{caseData?.CaseNumber}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div class="row">
      <div class="col-md-8 no_boxshadow lowpadding-right">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>

              <li class="realted">
                <a class="" (click)="onCaseProperty()" [ngClass]="{'active': casePropertyOn===true}">Properties</a>
              </li>

              <li class="realted">
                <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Details</a>
              </li>

              <li class="realted">
                <a class="" (click)="onActivity()" [ngClass]="{'active': activityOn===true}">Activities</a>
              </li>

              <li class="realted">
                <a class="" (click)=OnChatter() [ngClass]="{'active': newChatterOn===true}">Chatter</a>
              </li>
            </ul>
          </div>

          <ul class="cl custom_content_tab">

            <li class="nav-item ">
              <app-case-properties [casePropertyData]="casePropertyData" *ngIf="casePropertyOn===true && casePropertyData?.CaseId"></app-case-properties>
            </li>

            <li class="nav-item disabled_form">
              <app-case-detail [caseData]="caseData" *ngIf="DetailOn===true && caseData?.CaseId"></app-case-detail>
            </li>

            <li class="nav-item">
              <app-chatter *ngIf="newChatterOn===true"></app-chatter>
            </li>

            <li class="nav-item">
              <app-activity *ngIf="activityOn===true"></app-activity>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-md-4 bg_white_margin_top">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>
              <li class="realted">
                <a class="" (click)=OnContact() [ngClass]="{'active': contactOn===true}">CONTACT Details</a>
              </li>
            </ul>
          </div>

          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <app-case-contact-detail *ngIf="contactOn===true"></app-case-contact-detail>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
</div>