import { GlobalService } from './../../../services/global-Service/global.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { TaskNewComponent } from '../task-new/task-new.component';
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Input
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskService } from '../../../services/task/task.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { VariableService } from '../../../services/VariableService/variable.service';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';

import { CommonService } from '../../../services/common/common/common.service';
import * as Utils from '../../../../../common/utils';
import { Page } from '../../../../../models/page';
import { ColumnFilterComponent } from '../../../../../common/columnfilter/columnfilter.component';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { DeleteModalComponent } from './../../../../../common/delete-modal/delete-modal.component';
declare var $: any;

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {
  @ViewChild('statusUpdateContent')
  statusUpdateContent: any;
  @Input() deleteData: any;
  public TaskStageModel: AutocompleteModel[];
  updateTaskData: Array<any> = [];
  selectedPageFilter: any;
  pageFilters: any[];
  getPageFilterCalled = false;
  isFilterLogic: boolean;
  page = new Page();
  selectedPage: any = {};
  filterObj: any;
  public showFilter: Boolean = false;
  filterArray: Array<any> = [];
  filterLogic = '';
  columnNames: any[];
  active = true;
  temp: any[] = [];
  showSpinner: Boolean = true;
  isActive = false;
  taskid: any;
  tasks: Array<any> = [];
  taskData: any;
  // *****Update Status parameter*****
  UpdateStatus: any;
  IsCompleted: any;
  NextDueDate: any;
  Remarks: any;
  subjectStatusName: any;

  searchStr: String = '';
  searchValueSet = false;
  searchFilterAdded = false;
  newListName = ''; AccessType = '';

  // private barCode: Subject<any> = new Subject();
  constructor(
    private modalService: NgbModal,
    private taskService: TaskService,
    private variableService: VariableService,
    private masterLookupService: MasterLookupService,
    private topnavbarService: TopnavbarService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private cService: CommonService,
    private globalService: GlobalService
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
    this.filterArray = [];
    this.filterObj = {};
    this.page.size = 15;
    this.selectedPageFilter = {};
  }

  ngOnInit() {
    this.columnNames = [];
    this.getAllColumns();
    this.getPageFilters();
  }

  // Get All Task
  getTaskData(reqOptions?: any) {
    this.variableService.setSearchKeys({
      type: this.selectedPage.pagename
    });

    if (!reqOptions) {
      reqOptions = {};
      reqOptions.PageNumber = 1;
      reqOptions.PageSize = this.page.size;
      reqOptions.TotalRecords = null;
    }
    reqOptions.MasterType = this.selectedPage.pagename;
    if (this.searchStr && this.searchStr.length > 1) {
      this.searchFilterAdded = true;
      reqOptions.PageNumber = 1;
      const columnNames = [];
      this.columnNames.map(column => {
        columnNames.push(column.Name);
      });

      if (this.filterArray.length === 1) {
        this.filterLogic += `1 AND ${this.filterArray.length + 1}`;
      } else if (this.filterArray.length > 1) {
        this.filterLogic += ` AND ${this.filterArray.length + 1}`;
      }
      this.filterArray.push({
        columnName: `concat(${columnNames.join(',')})`,
        sqlOperator: 'LIKE',
        value: `%${this.searchStr}%`
      });
    }
    reqOptions.Filter = {
      logic: _.cloneDeep(this.filterLogic),
      FilterItems: _.cloneDeep(this.filterArray)
    };

    if (this.searchStr && this.searchStr.length > 1) {
      this.filterArray.pop();
      this.setLogic();
    }
    this.commonService.getTableData(reqOptions).subscribe(
      res => {
        const rows = res.Data;
        this.page = {
          totalElements: res.TotalRecords,
          totalPages: Math.ceil(
            res.TotalRecords / reqOptions.PageSize
          ),
          size: this.page.size,
          pageNumber: reqOptions.PageNumber - 1
        };
        this.showSpinner = false;
        this.temp = res;
        for (const row of rows) {
          row.height = Math.floor(Math.random() * 80) + 50;
        }
        this.tasks = rows;
        if (this.getPageFilterCalled) {
          this.showFilter = true;
          this.getPageFilterCalled = false;
        }
      },
      err => {
        this.showSpinner = false;
        if (this.searchStr && this.searchStr.length > 2) {
          this.filterArray.pop();
          this.setLogic();
        }
      }
    );
  }

  setSearchStr(val) {
    this.searchStr = val;
    if (this.searchStr.length > 1) {
      this.showSpinner = true;
      this.getTaskData();
    } else if (!this.searchStr) {
      if (this.searchFilterAdded) {
        this.filterArray.pop();
        this.setLogic();
        this.searchFilterAdded = false;
      }
      this.getTaskData();
    }
  }

  setLogic() {
    if (this.filterArray.length > 1) {
      let l: any;
      // this.filterArray.splice(0, 1);
      this.filterArray.forEach((filter, i) => {
        if (i > 0) {
          l += 'AND ' + (i + 1);
        } else {
          l = '1 ';
        }
      });
      this.applyFilterLogic(l);
    } else {
      this.applyFilterLogic('');
    }
  }

  getAllColumns() {
    this.commonService
      .getColumnData(this.selectedPage.pagename)
      .subscribe(res => {
        this.columnNames = res.ColumnNames;
        this.filterObj = Utils.getColumnsObject(res.ColumnNames);
      });
  }

  getTaskStageList() {
    this.masterLookupService.getDropdownList('Task Stage').subscribe(data => {
      this.TaskStageModel = data;
    });
  }

  openTask() {
    const modalRef = this.modalService.open(TaskNewComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.getData.subscribe(() => {
      this.getTaskData();
    });
  }

  editopen(data) {
    this.taskService.editTaskById(data.masterId).subscribe(resData => {
      this.taskData = resData;
      const modalRef = this.modalService.open(TaskNewComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.taskData = this.taskData;
      modalRef.componentInstance.getData.subscribe(() => {
        this.getTaskData();
      });
    });
  }

  onDelete(data) {
    this.deleteData = data;
    const modalRef = this.modalService.open(DeleteModalComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.deleteData = this.deleteData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getTaskData();
    });
  }

  // Filter Related Functions
  toggle() {
    this.showFilter = !this.showFilter;
    this.tasks = [...this.tasks];
  }

  applyFilter(arr: any[]) {
    this.filterArray = arr;
    if (this.selectedPageFilter.FilterID) {
      this.createNewPageFilter(
        this.selectedPageFilter.FilterName,
        0,
        { FilterItems: arr, logic: this.filterLogic },
        this.selectedPageFilter.FilterID,
        1
      );
    } else {
      this.showSpinner = true;
      this.getTaskData();
    }
  }

  applyFilterLogic(logic: string) {
    this.filterLogic = logic;
  }

  addLogic() {
    this.isFilterLogic = true;
  }

  removeLogic() {
    this.filterLogic = '';
    this.isFilterLogic = false;
  }

  custommenu(content) {
    const columnFilCMP = this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static'
    });
  }

  makeColumnsVisible(data) {
    const cols = [];
    data.oldCols.forEach(c => {
      if (data.newCols[c.Name]) {
        c.Visible = data.val;
        cols.push(c);
      } else {
        cols.push(c);
      }
    });
    this.columnNames = cols;
  }

  /**Page filter (header level) */
  getPageFilterData(pageFilter) {
    this.showFilter = false;
    this.getPageFilterCalled = true;
    this.selectedPageFilter = pageFilter;
    const filters = JSON.parse(pageFilter.FilterQuery);

    this.filterLogic = filters && filters.logic ? filters.logic : '';
    this.filterArray =
      filters && filters.FilterItems ? filters.FilterItems : [];
    this.showSpinner = true;
    this.getTaskData();
  }

  getPageFilters(b = false) {
    this.cService.getPageFilter(this.selectedPage.pagename).subscribe(
      res => {
        this.pageFilters = [];

        this.pageFilters.push({
          FilterName: 'All',
          FilterID: null,
          FilterQuery: null,
          MasterType: this.selectedPage.pagename,
          AccessType: 1
        });
        res.forEach(obj => {
          this.pageFilters.push(obj);
        });
        if (!b && !this.selectedPageFilter.FilterID) {
          this.selectedPageFilter = this.pageFilters[0];
        } else {
          if (!b) {
            this.selectedPageFilter = this.pageFilters[
              this.pageFilters.length - 1
            ];
          }
        }
        this.showSpinner = true;
        this.getTaskData();
      },
      err => {
        console.log('err===', err);
      }
    );
  }

  createNewPageFilterModal(content) {
    this.modalService
      .open(content, { size: 'sm', backdrop: 'static' })
      .result.then(result => {
        // console.log(`Closed with: ${result}`);
      });
  }

  createNewPageFilter(
    name,
    accessType,
    filterQuery = {},
    filterID = null,
    mode = 0
  ) {
    this.cService
      .createPageFilter({
        FilterID: filterID,
        FilterName: name,
        AccessType: parseInt(accessType, 10),
        Mode: mode,
        FilterQuery: JSON.stringify(filterQuery),
        MasterType: this.selectedPage.pagename
      })
      .subscribe(
        res => {
          this.selectedPageFilter.FilterQuery = JSON.stringify(filterQuery);
          if (!filterID) {
            if (name !== 'All') {
              this.pageFilters.push(res);
              this.selectedPageFilter = res;
              $('#btnCancel').trigger('click');
              this.newListName = '';
              this.AccessType = '';
            }
          }

          this.getPageFilterData(this.selectedPageFilter);
        },
        err => {
          console.log('err===', err);
        }
      );
  }

  onSelectCheckBox(data) {
    this.updateTaskData = data;
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  getUpdataTaskData() {
    for (let i = 0; i < this.updateTaskData.length; i++) {
      this.taskid = this.updateTaskData[i].TaskId;
      this.UpdateStatus = this.updateTaskData[i].Status;
      this.IsCompleted = this.updateTaskData[i].IsCompleted;
      this.subjectStatusName = this.updateTaskData[i].Subject;
    }
  }

  statusUpdate() {
    this.resetFollowUpStatus();
    if (this.updateTaskData.length === 0) {
      this.globalService.showError();
    } else {
      this.getTaskStageList();
      this.getUpdataTaskData();
      this.modalService.open(this.statusUpdateContent, {
        size: 'sm',
        backdrop: 'static'
      });
    }
  }

  saveStatus() {
    const nextDate = this.datePipe.transform(this.NextDueDate, 'yyyy-MM-dd');
    let resMessage: any;
    this.taskService
      .addTaskFollowUp({
        Remarks: this.Remarks,
        NextDueDate: nextDate,
        IsCompleted: this.IsCompleted,
        TaskId: this.taskid,
        Status: this.UpdateStatus
      })
      .subscribe(data => {
        resMessage = data.ErrorMessages[1];
        if (resMessage === '0') {
          this.globalService.showStatusUpdate({ masterName: this.subjectStatusName });
        }
        this.getTaskData();
      });
    this.resetFollowUpStatus();
    $('#btnCancel').trigger('click');
  }

  resetFollowUpStatus() {
    this.Remarks = '';
    this.NextDueDate = '';
    this.IsCompleted = '';
    this.taskid = '';
    this.UpdateStatus = '';
  }

  // onChange(value) {
  //   this.barCode
  //     .map(event => event.target.value)
  //     .debounceTime(500)
  //     .subscribe(data => {
  //       console.log("bar code data", data);
  //     });
  // }
}
