import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { OtherEmailService } from "../../../../services/other-Email/other-email.service";
import { Router } from "@angular/router";
import { GlobalService } from "../../../../services/global-Service/global.service";
@Component({
  selector: "app-other-email",
  templateUrl: "./other-email.component.html",
  styleUrls: ["./other-email.component.css"]
})
export class OtherEmailComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private otherService: OtherEmailService,
    private router: Router,
    private globalService:GlobalService
  ) { }
  otherEmailForm: UntypedFormGroup;
  otherEmail: Array<any> = [];
  ngOnInit() {
    this.otherEmailForm = this.fb.group({
      Name: "",
      EmailID: "",
      Password: "",
      IncomingMailServer: "",
      IncomingSecureType: "",
      IncomingPortNo: "",
      FromAddress: "",
      OutgoingMailServer: "",
      OutgoingSecureType: "",
      OutgoingPortNo: "",
      IsAuthenticated: "",
      AuthenticationKey: "",
      Type:"Email"
    });
    this.editForm();
  }

  // getAllEmail() {
  //   this.otherService.getEmailSetting().subscribe(data => {
  //     this.otherEmail = data;
  //     console.log(data);

  //   });
  // }
  editForm() {
    this.otherService.getEmailSetting('Email').subscribe(settingdata => {
      this.otherEmailForm.patchValue(settingdata[0]);
    });
  }
  onSubmit(form: UntypedFormGroup) {
    if (form.value) {
      this.otherService.createEmailSetting(form.value).subscribe(data => {
        // this.router.navigate(["home"]);
        let responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccessData('Updated the configurations.');
        }else{
          this.globalService.showErrorData('Something went wrong');
        }
      });
    }
    // }
    // resetForm() {
    //   this.otherEmailForm.reset();
    // }
    // this.resetForm();
  }
  resetForm() {
    this.otherEmailForm.reset();
  }
}
