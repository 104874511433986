import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import { CampaignService } from '../../../services/campaigns/campaign.service';
import { VariableService } from '../../../services/VariableService/variable.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CampainModalComponent } from '../../../shared/components/campain-modal/campain-modal.component';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {
  campaignData: any;
  RealtedOn = true;
  DetailOn = false;
  EmailScheduleOn = false;
  activityOn = true;
  showSpinner: Boolean = true;
  searchKeys: object = {};
  selectedPage: any = {};
  constructor(
    private campaignService: CampaignService,
    private variableService: VariableService,
    private route: ActivatedRoute,
    topnavbarService: TopnavbarService,
    private modalService: NgbModal,

  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {

      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMCampaign'
      });

      this.campaignService.editByCampaignId(params.id).subscribe(data => {
        this.campaignData = data;
        this.showSpinner = false;
      });
    });
  }

  onActivity() {
    this.activityOn = true;
  }
  OnRelated() {
    this.RealtedOn = true;
    this.DetailOn = false;
    this.EmailScheduleOn = false;
  }
  OnDetail() {
    this.DetailOn = true;
    this.RealtedOn = false;
    this.EmailScheduleOn = false;
  }
  EmailSchedule() {
    this.EmailScheduleOn = true;
    this.DetailOn = false;
    this.RealtedOn = false;
  }
  openECampain(){
      let emails = [];
      const modalRef = this.modalService.open(CampainModalComponent, {
        size: 'sm',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.senderDetails = emails;
    
  }
}
