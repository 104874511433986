import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { convertToCSV } from '../utils';

@Component({
  selector: 'custom-table-header',
  templateUrl: './tableheader.component.html',
  styleUrls: ['./tableheader.component.css']
})
export class TableHeaderComponent implements OnInit {
  @Input() selectedPage: any;
  @Input() tableData: any;
  @Input() columns: any;
  @Input() commonFilterCMP: any;
  @Input() createNewFilter: any;

  @Output() toggle = new EventEmitter();
  @Output() sendEmail = new EventEmitter();

  constructor(private modalService: NgbModal) { }

  ngOnInit() { }

  exportToCSV() {
    const filteredData = [];
    this.tableData.forEach(r => {
      const newObj = {};
      this.columns.forEach(c => {
        if (c.Visible) {
          if (c.IsDate) {
            if (r[c.Name]) {
              newObj[c.Value] = moment(r[c.Name]).format('DD-MM-YYYY');
            }
          } else {
            newObj[c.Value] = r[c.Name];
          }
        }
      });
      filteredData.push(newObj);
    });
    const name = this.selectedPage.pagename + '_' + new Date().toLocaleString();
    convertToCSV(filteredData, name);
  }

  custommenu(commonFilterCMP) {
    this.modalService.open(this.commonFilterCMP, {
      size: 'sm',
      backdrop: 'static'
    });
  }

  createNewPageFilterModal() {
    this.modalService
      .open(this.createNewFilter, { size: 'sm', backdrop: 'static' });
  }

  sendSMS() {
    console.log('SMS Called');
  }
}
