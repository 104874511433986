import { QuoteService } from '../../../services/quote/quote.service';
import { VariableService } from '../../../services/VariableService/variable.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { NewQuotationComponent } from '../new-quotation/new-quotation.component';
import { GlobalService } from '../../../services/global-Service/global.service';

declare var $: any;

@Component({
  selector: 'app-new-quote',
  templateUrl: './new-quote.component.html',
  styleUrls: ['./new-quote.component.css']
})
export class NewQuoteComponent implements OnInit {
  @Input() quoteData: any;
  public quote: Array<any> = [];
  public quoteListData: Array<any> = [];
  quoteName: any;
  quoteId: any;
  responseMessage: any;
  constructor(
    private modalService: NgbModal,
    private quoteService: QuoteService,
    private router: Router,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.getAllQuotesType()
  }

  getAllQuotesType() {
    this.quoteService.getQuoteType().subscribe(data => {
      this.quoteListData = data;
    });
  }


  open() {
    const modalRef = this.modalService.open(NewQuotationComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.quoteData = this.quoteData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAllQuotesType();
    });
  }

  editOpen(data) {
    this.quoteService.editQuoteById(data.masterId).subscribe(resData => {
      this.quoteData = resData;
      const modalRef = this.modalService.open(NewQuotationComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.quoteData = this.quoteData;
      modalRef.componentInstance.getData.subscribe(() => {
        this.getAllQuotesType();
      });
    });
  }


  onDelete(deleteContent, QuoteID, QuoteName) {
    this.quoteName = QuoteName;
    this.quoteId = QuoteID;
    this.modalService.open(deleteContent, { size: 'sm', backdrop: 'static', keyboard: false });
  }

  deleteQuote() {
    this.quoteService.deleteQuote(this.quoteId).subscribe(deletedata => {
      this.responseMessage = deletedata.ErrorMessages;
      if (this.responseMessage[1] === '0') {
        this.globalService.showDelete({ masterName: 'Quote', name: this.quoteName });
      }
      this.getAllQuotesType();
    });
    $('#btnCancel').trigger('click');
    this.globalService.disposeToastr();
  }

  gotoDetailPage(id) {
    const route = 'quoteDetail/' + id;
    this.router.navigate([route]);
  }
}
