<div class="">
  <div class="custom_footer">
    <div class="container-fluid">
      <div class="">
        <p class="custom_footer_display pull-right" (click)='onBottomOpen()'><i class="fas fa-phone"></i><span class="custom_phone_label">Phone
          </span></p>
      </div>
    </div>
    <div class="custom_animate" *ngIf="show===true">
      <ng-container>
        <div class='custom_filter_div'>
          <div class="custom_close_content_header" (click)="onCloseClick.emit()">
            <p class="custom_head_display"><i class="fas fa-phone custom_phone"></i><span class="custom_head_phone_label">Phone</span>
              <span class="custom_head_minimize"><i class="fas fa-minus" title="Minimize"></i></span>
            </p>
          </div>

          <div class="custom_extension">
            <span class="menu_icon {{MasterType}}">
            </span> {{MasterName}}
          </div>

          <div class="" style="padding-top: 10px;">
            <input type="text" [(ngModel)]="PhoneNumber" placeholder="Enter extension..." class="col-md-12 col-lg-12 col-xs-12 col-sm-12">
          </div>

          <br />

          <div class="" style="padding-top:15px">
            <textarea [(ngModel)]="Notes" rows="10" placeholder="Enter notes..." class="col-md-12 col-lg-12 col-xs-12 col-sm-12"></textarea>
          </div>
          <button type="button" class="btn btn-danger pull-right" (click)="addToCallLog()">End Call</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>