
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../../../ApiService/api.service';
import { VariableService } from '../../../VariableService/variable.service';
@Injectable()
export class ChatterService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) {
  }

  getChatter() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/chatter/getall?BaseId=${q.paramId}&MasterType=${q.type}&`
    );
  }

  createChatter(form) {
    return this.apiService.post('api/chatter/create', form);
  }


}
