import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';
@Injectable()
export class DashboardService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }
  // getDashboard() {
  //   const url = `api/chart/getdata?`;
  //   return this.apiService.get(url);
  // }
  getReport(form){
    const url = `api/powerbi/report/token`;
    return this.apiService.post(url, form);
  }

  getDashboard(form){
    const url = `api/powerbi/dashboard/token`;
    return this.apiService.post(url, form);
  }

  getDashBoardConfig() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/dashBoardConfig/getall?Id=${q.paramId}&`);
  }

  addDashBoardConfig(form) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.post('api/dashBoardConfig/create', form);
  }
}
