<div class="tab_title">
    <h3 class="page_title pull-left">
        <span class="menu_icon CRMProjectTasks fa fa-tasks">
        </span>
        Project Tasks ({{projectTasks.length}})
    </h3>
    <div class="buttonHeader pull-right">
        <div class="buttonNew" (click)="onSelectUpdate(openProperties)">Status Update
        </div>
    </div>
</div>

<div class="table-container" style="border-top: 1px solid #ccc;">
    <div class="row">
        <div class="col-md-12 col-xs-12 col-lg-12 col-sm-12 mb-10">
            <ngx-datatable class="material" [headerHeight]="30" [limit]="10" [columnMode]="'force'" [footerHeight]="30"
                [selected]="selected" [selectionType]="'checkbox'" (select)='select($event)' [rowHeight]="'auto'"
                [rows]="projectTasks">

                <ngx-datatable-column [width]="36" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                    [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
                </ngx-datatable-column>

                <ngx-datatable-column name="Project Task Name">
                    <ng-template let-row="row" ngx-datatable-cell-template let-value="value" class="text-right">
                        <a (click)="gotoDetailPage(row.ProjectTaskId)"
                            href="javascript:void(0)">{{row.ProjectTaskName}}</a>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column prop="OwnerName" name="Assigned To"></ngx-datatable-column>

                <ngx-datatable-column name="Due Date">
                    <ng-template let-row="row" ngx-datatable-cell-template let-value="value" class="text-right">
                        <a>{{row.DueDate | date:'dd-MM-yyyy' }}</a>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Closed On">
                    <ng-template let-row="row" ngx-datatable-cell-template let-value="value" class="text-right">
                        <a>{{row.ClosedOn | date:'dd-MM-yyyy' }}</a>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column prop="StatusName" name="Status"></ngx-datatable-column>

            </ngx-datatable>
        </div>
    </div>
</div>

<ng-template #openProperties let-c="close" let-d="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-header">
        <h4 class="modal-title text-center">New Properties</h4>
    </div>
    <form [formGroup]="projectTaskPropertyForm" novalidate (ngSubmit)="onSubmit(projectTaskPropertyForm)">
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="text">Status</label>
                    <select class="form-control" formControlName="Status">
                        <option value=0>--None--</option>
                        <option *ngFor="let status of StatusList" value={{status.Value}}>{{status.Text}}</option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-12">
                    <label for="text">Is Finished</label>
                    <input type="checkbox" class="custom_checkbox" formControlName="IsFinished">
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-12">
                    <label for="text">Tonnage</label>
                    <input type="textbox" class="form-control" formControlName="Tonnage">
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-12">
                    <label for="text">Completion On</label>
                    <input type="text" class="form-control" formControlName="CompletionOn" #dp="bsDatepicker"
                        value="{{ projectTaskPropertyForm.get('CompletionOn').value | date: 'dd-MM-yyyy' }}"
                        bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue'}">
                    <p class="inputdate" (click)="dp.toggle()">
                        <i class="fa fa-calendar-alt"></i>
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-12">
                    <label for="text">Closed On</label>
                    <input value="{{projectTaskPropertyForm.get('ClosedOn').value | date:'dd-MM-yyyy'}}"
                        class="form-control" formControlName="ClosedOn" readonly>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')">Cancel</button>
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </form>
</ng-template>