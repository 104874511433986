import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class ClientService {

  constructor( private apiService: ApiService,
    private variableService: VariableService) { }


  addClient(form) {
    return this.apiService.post('api/clientCRM/create', form);
  }


  editByClientId(clientId) {
    return this.apiService.get(`api/clientCRM/getbyid?Id=${clientId}&`);
  }

  getByClientId(clientId, CRMClient) {
    return this.apiService.get(`api/clientCRM/getbyid?Id=${clientId}&BaseType=${CRMClient}&`);
  }


  deleteClient(clientId) {
    return this.apiService.delete(`api/clientCRM/delete?Id=${clientId}&`);
  }


  updateClient(form) {
    return this.apiService.put('api/clientCRM/edit', form);
  }

}
