import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewContractComponent } from '../new-contract/new-contract.component';
import { ContractService } from '../../../services/contract/contract.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-tiny-contracts-right',
  templateUrl: './tiny-contracts-right.component.html',
  styleUrls: ['./tiny-contracts-right.component.css']
})
export class TinyContractsRightComponent implements OnInit {
  contractorsData:any;
  @Input() contract:any;
  constructor(    private modalService: NgbModal, private contractService:ContractService, private router:Router    ) { }

  ngOnInit(): void {
    this.contractService.contractById(this.contract.ContractId).subscribe((res)=>{
      this.contractorsData = res;
    })
  }
  open() {
    const modalRef = this.modalService.open(NewContractComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.contractFormData = this.contractorsData;
    modalRef.componentInstance.getData.subscribe(() => {
      // this.getAllQuotesType();
    });
  }
  gotoDetailPage(id) {
    const route = 'contractDetail/' + id;
    this.router.navigate([route]);
  }
}
