import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;
  userId: any;
  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      NewPassword: '',
      OldPassword: '',
      UserId: ''
    });

    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.userId = user.FirstName + ' ' + user.LastName;
  }

  onSubmit(form: UntypedFormGroup) {
    console.log('form', form.value);
  }
  resetForm() {
    this.changePasswordForm.reset();
  }
}
