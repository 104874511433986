<div class="body_inner">
    <!--Product Page-->
    <div class="heading_cont">
        <div class="pull-left">
            <span class="menu_icon CRMProducts fa fa-file">
            </span>
            <h3 class="report_page_title">
                <p class="page_title_cont">Template</p>
            </h3>
        </div>
        <div class="buttonHeader pull-right">
            <div class="buttonNew" (click)="open()">New</div>
        </div>
    </div>
    <div class="table-wrapper">

        <custom-table-header [commonFilterCMP]="commonFilterCMP" [selectedPage]="selectedPage" [tableData]="rows"
            [columns]="columnNames" [createNewFilter]="createNewFilter" (toggle)="toggle()" (sendEmail)="sendEmail()">
        </custom-table-header>

        <!-- Custom Table -->
        <custom-table (getData)="getTableData($event)" [columns]="columnNames" [rows]="rows" [page]="page"
            [showFilter]="showFilter" (onSelect)="onSelectCheckBox($event)" (edit)="editTemplate($event)"
            (onDelete)="onDelete($event)" [searchStr]="searchStr" (setSearchStr)="setSearchStr($event)">
        </custom-table>

        <!-- Column Filter -->
        <ng-template #commonFilterCMP>
            <column-filter [columns]="columnNames" (setColumnsVisible)="makeColumnsVisible($event)"
                (closeModal)="closeModal()"></column-filter>
        </ng-template>
        
        <!-- Table Filters -->
        <table-filter *ngIf='showFilter' (applyFilter)="applyFilter($event)" [filterArray]="filterArray" (toggleFilter)="toggle($event)"
            [cols]="columnNames" [filterColumns]="filterObj" [filterLogic]="filterLogic" (setFilterLogic)="applyFilterLogic($event)"></table-filter>


        <ng-template #createNewFilter let-c="close" let-d="dismiss" style="pos-rel">
            <div class="close-btn-container pos-abs">
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-header">
                <h4 class="modal-title text-center">New List View</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-md-12">
                        <label for="text">
                            <span class="text-danger">*</span>List Name</label>
                        <input type="textbox" class="form-control" [(ngModel)]="newListName">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12">
                        <div class="radio">
                            <label>
                                <input type="radio" name="optradio" value="0" [(ngModel)]="AccessType">Private</label>
                        </div>
                        <div class="radio">
                            <label>
                                <input type="radio" name="optradio" value="1" [(ngModel)]="AccessType">Public</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')
                        ">Cancel</button>
                <button type="button" class="btn btn-primary"
                    (click)="createNewPageFilter(newListName, AccessType)">Save</button>
            </div>
        </ng-template>

    </div>

</div>