<div class="tab_title">
  <h3 class="page_title pull-left">
    <span class="icon_holder new_lead">
      <i class="fa fa-bullseye fa-fw" aria-hidden="true"></i>
    </span>
    Related Accounts ({{accountData?.length}})
  </h3>
</div>

<div class="related_details">
  <div class="row">
    <div class="col-md-6 col-xs-12 mb-10" *ngFor="let account of accountData">
      <a class="dottedLine" (click)="gotoDetailPage(account.AccountId)" href="javascript:void(0)">{{account.AccountName}}</a>
      <table class="custom_table">
        <tr>
          <th class="custom_name">Start Date</th>
          <th class="custom_colon">:</th>
          <td class="custom_total">{{account.StartDate}}</td>
        </tr>

        <tr>
          <th class="custom_name">Priority</th>
          <th class="custom_colon">:</th>
          <td class="custom_total">{{account.TypeName}}</td>
        </tr>

        <tr>
          <th class="custom_name">Tax</th>
          <th class="custom_colon">:</th>
          <td class="custom_total">0.00</td>
        </tr>

        <tr style="border-top: 2px solid;">
          <th class="custom_name">Contact Name</th>
          <th class="custom_colon">:</th>
          <td class="custom_total">₹ {{account.StatusName}}</td>
        </tr>
      </table>
    </div>
  </div>