import { VariableService } from './../../../services/VariableService/variable.service';
import { Component, OnInit } from '@angular/core';
import { UserReportService } from '../../../services/report/userReport/user-report.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.css']
})
export class ReportsListComponent implements OnInit {
  reports: any[];
  temp: any[];
  size: number;
  reportName: string;
  tableHeader:any;
  columns = [
    { name: 'ReportName' },
    { name: 'CreatedBy' },
    { name: 'CreatedOn' },
    { name: 'Type' },
    { name: '' }
  ];
  
  constructor(
    private _userReportService: UserReportService,
    public _router: Router,
    private variableService: VariableService,
  ) { }

  ngOnInit() {
    this.reports = [];
    this.reportsList();
    this.size = 15;
    this.tableHeader = {
      'ReportName':{
        name: 'Report Name',
        value:'ReportName',
        type:'customLink',
        linkpartialURL:'/reportsDetail/',
        className:'number_short'
      },
      'CreatedBy':{
        name: 'Created By',
        value:'CreatedBy',
        type:'label',
        className:'number_short'
      },
      'CreatedOn':{
        name: 'Created On',
        value:'CreatedOn',
        type:'label',
        className:'number_short'
      },
      'Type':{
        name: 'Type',
        value:'Type',
        type:'label',
        className:'number_short'
      },
      'Action':{
        name: 'Edit',
        value:'Edit',
        type:'link',
        className:'number_short'
      },
    }
  }

  reportsList() {
    this._userReportService.getUserReport().subscribe(
      res => {
        this.reports = res;
        this.temp = res;
      },
      err => {
        console.log('err=====', err);
      }
    );
  }

  filterRows(e: any) {
    const val = e.target.value;
    if (val) {
      const temp = this.temp.filter(function (d) {
        let searchStr = '';
        let str = '';

        str = d['ReportName'] + d['CreatedBy'];

        searchStr = str.toLowerCase();
        return searchStr.indexOf(val) !== -1 || !val;
      });
      this.reports = temp;
    } else {
      this.reports = this.temp;
    }
  }

  addReport() {
    this._router.navigate(['add-reports']);
  }

  gotoReportDetail(id: string, reportName) {
    const route = `reportsDetail/${id}`;
    this.variableService.changeMessage(reportName);
    this._router.navigate([route]);

  }

  editReport(id) {
    const route = `reportsEdit/${id}`;
    this._router.navigate([route]);
  }
  editLineItem(data){
    debugger;
    if(data.customEdit){
      const route = `reportsDetail/${data.ReportID}`;
      this._router.navigate([route]);
    }else{
      this.editReport(data.ReportID);
    }
  }
}
