<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">Edit Quote Line Items</h4>
</div>
<div class="modal-body">
  <div class="table_cont">
    <table class="table table-striped table-bordered dynomic_table">
      <thead>
        <tr>
          <th class="number_small">S.No.</th>
          <th id="Product" class="name_long">Product</th>
          <th class="number_largeshort">Qty</th>
          <th class="amt_medium">Rate</th>
          <th id="Disc" class="number_short">Disc(%)</th>
          <th class="amt_medium">Amount</th>
          <th class="number_largeshort">Tax Rate</th>

          <th class="amt_medium">Tax Amount</th>
          <th class="amt_medium">Total Value</th>
          <th class="number_short">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let list of quoteProductForm.ListOfQuoteProduct; let i=index">
          <td>
            {{i+1}}
            <input type="hidden" [(ngModel)]="list.SerialNo" value={{i+1}}>
          </td>
          <td>
            <app-autocomplete [autoCompleteText]="list.autoCompleteText" (setAutoComplete)="setAutoComplete($event)"
              [autoCompleteValue]="list.autoCompleteValue" [key]="i" (fiteredData)="fiteredData($event)"
              [filteredOptions]="list.filteredOptions">
            </app-autocomplete>
          </td>
          <td>
            <input class="form-control" type="text" [(ngModel)]="list.Quantity" step="0.01" (input)="calculate(i);addFieldValue(i)"
              (keypress)="numberOnly($event)" />
          </td>
          <td>
            <input class="form-control" type="text" [(ngModel)]="list.Rate" (input)="calculate(i);addFieldValue(i)"
              (keypress)="numberOnly($event)" />
          </td>
          <td>
            <input class="form-control" type="text" [(ngModel)]="list.Discount" (input)="calculate(i);addFieldValue(i)"
              (keypress)="numberOnly($event)" />
          </td>
          <td>
            <input class="form-control" type="text" [(ngModel)]="list.Amount" (input)="calculate(i);addFieldValue(i)"
              (keypress)="numberOnly($event)" readonly />
          </td>
          <td>
            <input class="form-control" type="text" [(ngModel)]="list.GST" (input)="calculate(i);addFieldValue(i)"
              (keypress)="numberOnly($event)" />
          </td>
          <td>
            <input class="form-control" type="text" [(ngModel)]="list.TaxAmount" (input)="calculate(i);addFieldValue(i)"
              readonly (keypress)="numberOnly($event)" />
          </td>
          <td>
            <input class="form-control" type="text" [(ngModel)]="list.TotalValue" (input)="calculate(i);addFieldValue(i)"
              readonly (keypress)="numberOnly($event)" />
          </td>
          <td>
            <div class="btn-group" dropdown>
              <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
              <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
</div>