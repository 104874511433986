import { ViewContainerRef } from '@angular/core';
import { ProjectOrderService } from './../../../services/projectOrder/project-order.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { PendingOrderService } from './../../../services/pending-order/pending-order.service';
import { Component, OnInit } from '@angular/core';
// import { CustomerInvoiceComponent } from '../customer-invoice/customer-invoice.component';
import { NewInvoiceComponent } from '../../invoice-page/new-invoice/new-invoice.component';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-invoice-order',
  templateUrl: './invoice-order.component.html',
  styleUrls: ['./invoice-order.component.css']
})
export class InvoiceOrderComponent implements OnInit {
  pendingOrderData: any;
  checkOrderdata: any[];
  ListOfTransaction: any[];
  projectInvoiceData: any;

  constructor(
    private pendingOrderService: PendingOrderService,
    private projectOrderService: ProjectOrderService,
    private modalService: NgbModal,
    private globalService: GlobalService,
  ) {
  }


  ngOnInit() {
    this.getAllProjectOrder();
    this.checkOrderdata = [];
  }

  getAllProjectOrder() {
    this.pendingOrderService.getPendingOrder().subscribe(data => {
      this.pendingOrderData = data;
    });
  }

  onIsSelected(data) {
    if (data.isCheckedValue) {
      this.checkOrderdata.push(data.data);
    } else {
      this.checkOrderdata.splice(data.index, 1);
    }
  }

  getAllProjectInvoiceList() {
    this.ListOfTransaction = [];
    if (this.checkOrderdata.length === 0 || this.checkOrderdata === undefined) {
      this.globalService.showError();
    } else {
      this.checkOrderdata.forEach((obj) => {
        this.ListOfTransaction.push(obj);
      });
      this.projectOrderService.getProjectInvoiceList(this.ListOfTransaction).subscribe(data => {
        this.projectInvoiceData = data;
        this.open();
      });
    }
  }

  onGotoInvoice() {
    this.getAllProjectInvoiceList();
  }

  open() {
    const modalRef = this.modalService.open(NewInvoiceComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'custom-modal',
      keyboard: false
    });
    this.projectInvoiceData.orderNumber = true;
    modalRef.componentInstance.projectInvoiceData = this.projectInvoiceData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAllProjectOrder();
    });
  }
}
