<form class="example-form" [formGroup]="userForm">
    <mat-form-field class="example-full-width">
        <input #textInput matInput placeholder="State" aria-label="Select User" [matAutocomplete]="auto" (input)="_filterStates($event.target.value)" formControlName="userInput">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let user of filteredUsers" [value]="user" >
                <span>{{user.Text}}</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <button type="button" class="form-control" (click)="onSubmit()">Save</button>
</form>