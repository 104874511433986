import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../../ApiService/api.service';

@Injectable()
export class UserReportService {
  constructor(
    private apiService: ApiService,
  ) {}
  getUserReport(id?: any) {
    if (!id) {
      id = null;
    }
    return this.apiService.get(`api/userreport/getall?Id=${id}&`);
  }

  createUserReport(form) {
    return this.apiService.put('api/userreport/create', form);
  }

  getReportDetail(
    id: string,
    PageSize: number,
    PageNumber: number,
    OrderBy: string
  ) {
    return this.apiService.get(
      `api/misreport/getall?Id=${id}&PageSize=${PageSize}&PageNo=${PageNumber}&OrderBy=${OrderBy}&`
    );
  }

  getPdfData(id, PageSize, PageNumber) {
    return this.apiService.get(
      `api/misreport/getall?Id=${id}&PageSize=${PageSize}&PageNo=${PageNumber}&`
    );
  }
}
