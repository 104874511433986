
    <!-- <div class="tabbable-line" id="nav-main">
      <ul class="" id="main">
        <li routerLinkActive="active">
          <a routerLink="/home">
            <span class="menu_icon home" title="Home">

            </span>
            <span class="menu_heading">Home</span>
          </a>
        </li>
        <ng-container *ngFor="let menu of menuItems">
          <li routerLinkActive="active" *ngIf="menu.roles.length > 0">
            <a [routerLink]="menu.link" (click)="setSelectedPage(menu)">
              <span *ngIf='menu.name !== "CRMPos" && menu.name !== "CRMReports"' class='menu_icon {{menu.name.replace("CRM ", " ")}}' title='{{menu.name.replace("CRM", "")}}'></span>
              <span *ngIf='menu.name == "CRMPos"' style="padding: 7px;
              line-height: 30px;
              background: orange;
              color: beige;"><i class="fas fa-hands-helping" ></i></span>
              <span *ngIf='menu.name == "CRMReports"' style="padding: 8px; 
              background: #39b4e1;
              line-height: 30px;
              color: beige;"><i class="fa fa-flag"></i></span>
              <span class="menu_heading">{{menu.name.replace("CRM", "")}}</span>
            </a>
          </li>
        </ng-container>
        <li routerLinkActive="active">
          <a [routerLink]="['/formBuilder']" >
            <span class='menu_icon formBuilder glyphicon glyphicon-tasks' title='formBuilder' style="padding: 8px;
            background: orange;
            color: beige;"></span>
            <span class="menu_heading">formBuilder</span>
          </a>
        </li>
      </ul>
    </div> -->


<div class="tile-side-nav" >
  <ul>
    <li routerLinkActive="active">
      <a routerLink="/home">
        <span title="Home" class="icons">
          <i class="fas fa-home" aria-hidden="true"></i>
        </span>
        <div class="menu_heading">Home</div>
      </a>
    </li>
    <ng-container *ngFor="let menu of menuItems">
      <li *ngIf="(menu.link=='#') || (menu.roles.length > 0 && (menu.link=='/setup' || menu.link=='/reports'))">
        <a  (click)="setSelectedPage(menu)" *ngIf="menu.link=='#'">
          <span  class="icons" *ngIf='menu.name == "CRM_Marketing"'>
            <i class="fas fa-bullhorn" aria-hidden="true"></i>
          </span>
          <span  class="icons" *ngIf='menu.name == "CRM_Projects"'>
            <i class="glyphicon glyphicon-equalizer" aria-hidden="true"></i>
          </span>
          <span  class="icons" *ngIf='menu.name == "CRM_Activities"'>
            <i class="glyphicon glyphicon-align-center" aria-hidden="true"></i>
          </span>
          <span  class="icons" *ngIf='menu.name == "CRM_Finance"'>
            <i class="glyphicon glyphicon-stats" aria-hidden="true"></i>
          </span>
          <span  class="icons"  *ngIf='menu.name == "CRM_VMS"'>
            <i class="glyphicon glyphicon-modal-window" aria-hidden="true"></i>
          </span>
          <div class="menu_heading">{{menu.name.replace("CRM_", "")}}</div>
        </a>
        <a *ngIf="menu.link=='/reports'" [routerLink]="menu.link" >
          <span  class="icons" *ngIf='menu.name == "CRM_Reports"'>
            <i class="fa fa-flag-checkered" aria-hidden="true"></i>
          </span>
          <div class="menu_heading">{{menu.name.replace("CRM_", "")}}</div>
        </a>
        <a *ngIf="menu.link=='/setup'" [routerLink]="menu.link" >
          <span  class="icons" *ngIf='menu.name == "CRM_Settings"'>
            <i class="fa fa-cog" aria-hidden="true"></i>
          </span>
          <div class="menu_heading">{{menu.name.replace("CRM_", "")}}</div>
        </a>
        <ul class="submenu" *ngIf="menu.link!=='/setup' && menu.link!=='/reports'">
          <ng-container *ngFor="let submenu of menuItems">
            <li  class="submenu-li" *ngIf="submenu.roles.length > 0 && submenu.link!=='#' && menu.MenuID==submenu.ParentMenuID" >
              <a [routerLink]="submenu.link"  (click)="setSelectedPage(submenu)" >
                <span class="menu_heading text-left">{{submenu.name.replace("CRM", "")}}</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
    </ng-container>
  </ul>
</div>