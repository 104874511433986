import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  ViewContainerRef,
  ElementRef
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { NewEventService } from '../../../../services/common/activity-page/newEvent/new-event.service';
import { AutocompleteService } from '../../../../services/autocomplete/autocomplete.service';

import { VariableService } from '../../../../services/VariableService/variable.service';
import { EmployeeInfoService } from '../../../../services/employeeInfo/employee-info.service';
import { EmailService } from '../../../../services/common/activity-page/email/email.service';
import * as Utils from '../../../../../../common/utils/';
import { GlobalService } from '../../../../services/global-Service/global.service';
declare var $: any;

@Component({
  selector: 'app-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.css']
})
export class NewEventComponent implements OnInit {
  @Output() eventEvent = new EventEmitter();
  @ViewChild('contactLeadtextInput') contactLeadtextInput: ElementRef;
  @ViewChild('relatedtextInput') relatedtextInput: ElementRef;

  userEmailList: any;
  currentStartTime: any;
  currentEndTime: any;

  toAddress: any = null;
  fromAddress: any = null;
  fromName: any;
  employeeInfoData: any;
  newEventForm: UntypedFormGroup;
  NameRealtedType: any;
  RelatedToType: any;
  textBody = '';
  public disableDropdown: Boolean = true;
  masterTypeText: any;
  masterTypeValue: any;
  timeData: any[];
  contactLeadFilterOption: any[];
  relatedTypeFilterOption: any[];
  employeefilteredOptions: any[];
  eventTableForm: EventTableForm;
  listOfEventTableObj: ListOfEventTableObj;
  optionValue: any;
  selectedOption: any;
  CRMEmployeeAssignedTo = [];
  employeeValue: any; employeeText: any;
  selectedContactLeadOption: any;
  selectedRelatedOption: any;
  RelatedText: any; RelatedValue: any;


  NameTypeList = this.globalService.NameTypeList;

  RelatedTypeList = this.globalService.RelatedTypeList;

  constructor(
    public fb: UntypedFormBuilder,
    public newEventService: NewEventService,
    public autocompleteService: AutocompleteService,
    private variableService: VariableService,
    private emailService: EmailService,
    private employeeInfoService: EmployeeInfoService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.newEventForm = this.fb.group({
      EventId: '',
      Subject: '',
      IsAllDayEvent: '',
      Location: '',
      AssignedToId: '',
      RelatedToId: '',
      EndDate: new Date(),
      StartDate: new Date(),
      ContactId: '',
      NameType: '',
      RelatedType: '',
      StartTime: '',
      EndTime: '',
      CRMEmployeeAssigned: new UntypedFormGroup({
        ParticipantID: new UntypedFormControl(),
        Type: new UntypedFormControl(),
      }),
    });
    this.getMasterTypeList();
    this.disableRelatedType();
    this.getCurrentTime();
    this.setMasterType();
    this.getTimeData();
  }
 

  setMasterType() {
    const q: any = this.variableService.getSearchKeys();

    if (q.type === 'CRMLead' || q.type === 'CRMContact' || q.type === 'CRMLocations' || q.type === 'CRMMachines') {
      this.NameRealtedType = q.type.slice(3);
      this.newEventForm.controls['ContactId'].patchValue(q.paramId);
    } else {
      this.RelatedToType = q.type.slice(3);
      this.newEventForm.controls['RelatedToId'].patchValue(q.paramId);
    }

    this.newEventForm.patchValue({
      NameType: this.NameRealtedType,
      RelatedType: this.RelatedToType,
    });
  }

  getMasterTypeList() {
    const q: any = this.variableService.getSearchKeys();
    this.autocompleteService.getMasterTypeListData(q.type, q.paramId).subscribe(data => {
      this.masterTypeText = data[0].Text;
      this.masterTypeValue = data[0].Value;

      if (q.type === 'CRMLead' || q.type === 'CRMContact' || q.type === 'CRMLocations' || q.type === 'CRMMachines') {
        const contactLeadObj = {
          Text: this.masterTypeText,
          Value: this.masterTypeValue,
        };
        this.contactLeadtextInput.nativeElement.value = contactLeadObj.Text;
        this.selectedContactLeadOption = contactLeadObj;
      } else {
        const relatedObj = {
          Text: this.masterTypeText,
          Value: this.masterTypeValue,
        };
        this.relatedtextInput.nativeElement.value = relatedObj.Text;
        this.selectedRelatedOption = relatedObj;
      }
    });
  }

  displayContactFn(contactLead: any) {
    this.selectedContactLeadOption = contactLead;
    return contactLead ? contactLead.Text : '';
  }

  displayRelatedFn(related: any) {
    this.selectedRelatedOption = related;
    return related ? related.Text : '';
  }

  getRealtedTypeList(value) {
    const relatedValue = this.newEventForm.value.RelatedType;
    if (relatedValue !== '') {
      if (value.length > 1) {
        this.autocompleteService.getAllRelatedTypeList(relatedValue, value).subscribe(
          res => {
            this.relatedTypeFilterOption = res;
          },
          err => {
            console.log('errr====', err);
          });
      }
    }
  }


  getLeadContactList(value) {
    const optionValue = this.newEventForm.value.NameType;
    if (optionValue !== '') {
      if (value.length > 1) {
        this.autocompleteService.getLeadContactList(optionValue, value).subscribe(
          res => {
            this.contactLeadFilterOption = res;
          },
          err => {
            console.log('errr====', err);
          });
      }
    }
  }

  getTimeData() {
    this.timeData = Utils.getTime();
  }

  getCurrentTime() {
    const time = new Date();
    this.currentStartTime = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    this.currentEndTime = this.currentStartTime;
  }

  disableRelatedType() {
    if (this.NameRealtedType === 'Lead' || this.NameRealtedType === 'Contact') {
      this.disableDropdown = true;
    }
  }

  setAutoCompleteEmployee(data) {
    const assignedTo = data.data.option.value.Value;
    this.newEventForm.controls['AssignedToId'].setValue(assignedTo);
    this.employeeInfoService.getEmployeeInfoList(assignedTo).subscribe(resData => {
      this.employeeInfoData = resData;
      this.fromName = resData.EmployeeName;
      // this.toAddress = this.employeeInfoData.EmailID;
    });
  }

  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMEvents').subscribe(
      res => {
        this.employeefilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  onAddParticipant() {
    this.eventTableForm.ListOfEventTable = this.eventTableForm.ListOfEventTable.filter(
      obj => {
        return obj.Type || obj.ParticipantID;
      }
    );
    this.eventTableForm.ListOfEventTable.forEach(i => {
      this.CRMEmployeeAssignedTo.push({
        ParticipantID: i.ParticipantID,
        Type: i.Type,
      });
    });
    this.newEventForm.value.CRMEmployeeAssigned = this.CRMEmployeeAssignedTo;
    this.createPromiseArray(this.CRMEmployeeAssignedTo);
    $('#btnCancel').trigger('click');
  }

  createPromiseArray(data) {
    const promiseArray = [];
    data.forEach((obj) => {
      if (obj.Type === 'user') {
        const proObj = new Promise((resolve, reject) => {
          this.employeeInfoService.getEmployeeInfoList(obj.ParticipantID).subscribe(response => {
            resolve(response);
          }, err => {
            reject(err);
          });
        });
        promiseArray.push(proObj);
      }
    });
    this.requestUserEmail(promiseArray);
  }

  requestUserEmail(promiseArray) {
    return Promise.all(promiseArray).then(res => {
      this.userEmailList = res;
    }).catch(error => {
      console.log('Error', error);
    });
  }

  dateFormat() {
    const q: any = this.variableService.getSearchKeys();
    const user = JSON.parse(sessionStorage.getItem('currentUser'));

    this.newEventForm.value.StartDate = this.datePipe.transform(this.newEventForm.value.StartDate, 'yyyy-MM-dd');
    this.newEventForm.value.EndDate = this.datePipe.transform(this.newEventForm.value.EndDate, 'yyyy-MM-dd');

    this.fromAddress = user.EmailId;
    this.fromName = user.FirstName + '' + user.LastName;

    if (this.newEventForm.value.ContactId.Id === null) {
      this.newEventForm.controls['ContactId'].
        patchValue(this.newEventForm.value.ContactId.Value);
    } else if (this.newEventForm.value.RelatedToId.Id === null) {
      this.newEventForm.controls['RelatedToId'].patchValue(this.newEventForm.value.RelatedToId.Value);
    }

    const emailListData = [];

    if (this.userEmailList !== undefined) {
      this.userEmailList.forEach((element) => {
        emailListData.push(element.EmailID);
      });
      this.toAddress = emailListData.join(',');
    }

    this.textBody = 'Dear' + ' ' + this.toAddress + ',' + '<br/>' + '<br/>' +
      '<strong>' + 'Subject :' + '</strong>' + ' ' + 'New Event' + ' " ' + this.newEventForm.value.Subject + ' " .' + '<br/>' + '<br/>' +
      'New Event' + ' " ' + this.newEventForm.value.Subject + ' " ' + 'related to' + ' ' +
      q.type.slice(3) + ' " ' + this.masterTypeText + ' " ' + 'is schedule on '
      + this.newEventForm.value.StartDate + ' - ' + this.newEventForm.value.StartTime + ' to '
      + this.newEventForm.value.EndDate + ' - ' + this.newEventForm.value.EndTime + '.' +
      '<br/>' + 'So please allocate your time.'
      + '<br/>' + '<br/>' +
      'Regards, ' + '<br/>' + user.FirstName + ' ' + user.LastName + '<br/>' + user.CompanyName + '<br/>';
  }

  onSubmit(form: UntypedFormGroup) {
    this.dateFormat();
    const eventName = this.newEventForm.value.Subject;
    const relatedId = this.newEventForm.value.RelatedToId;

    let responseMessage = [];
    this.newEventService.addNewEvent(form.value).subscribe(data => {
      responseMessage = data.ErrorMessages;
      if (responseMessage[1] === '0') {
        this.globalService.showSuccess({ masterName: 'Event', name: eventName });
      }
      if (this.fromAddress != null && this.toAddress !== null) {
        this.emailService.sendEmail({
          Subject: eventName,
          FromAddress: this.fromAddress,
          ToAddress: this.toAddress,
          FromName: this.fromName,
          TextBody: this.textBody,
          RelatedToId: relatedId,
        }).subscribe(senddata => { });
      }
      this.eventEvent.emit();
      this.resetForm();
    });
  }
  resetForm() {
    this.newEventForm.patchValue({
      Subject: '',
      IsAllDayEvent: '',
      Location: '',
      AssignedToId: '',
      EndDate: '',
      StartDate: '',
      StartTime: '',
      EndTime: ''
    });
  }

  onEndTimeChange(event) {
    this.currentEndTime = event;
  }

  onStartTimeChange(event) {
    this.currentStartTime = event;
  }

  openParticipant(open) {
    this.resetEventTableForm();
    this.resetListOfEventTableObj();
    this.eventTableForm.ListOfEventTable.push(this.listOfEventTableObj);
    this.modalService.open(open, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  addFieldValue(i) {
    this.resetListOfEventTableObj();
    const obj = this.eventTableForm.ListOfEventTable[i + 1];
    if (!obj) {
      this.eventTableForm.ListOfEventTable.push(this.listOfEventTableObj);
    }
    this.resetListOfEventTableObj();
  }
  deleteFieldValue(i: number) {
    if (this.eventTableForm.ListOfEventTable.length === 1) {
      return false;
    } else {
      this.eventTableForm.ListOfEventTable.splice(i, 1);
      return true;
    }
  }

  onTypeSelect(eventValue) {
    this.optionValue = eventValue.value;
  }

  setAutoComplete(data) {
    const value = data.data.option.value.Value;
    this.eventTableForm.ListOfEventTable[data.index].ParticipantID = value;
  }

  fiteredData(value) {
    this.selectedOption = {};
    const type = this.eventTableForm.ListOfEventTable[value.indexValue] ? this.eventTableForm.ListOfEventTable[value.indexValue].Type : '';
    this.autocompleteService.commonAutocomplete(type, value.data||'').subscribe(
      res => {
        this.eventTableForm.ListOfEventTable[value.indexValue].filteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }
  resetListOfEventTableObj() {
    this.listOfEventTableObj = {
      ParticipantID: '',
      Type: '',
    };
  }
  resetEventTableForm() {
    this.eventTableForm = {
      ListOfEventTable: [],
    };
  }
}

declare class ListOfEventTableObj {
  ParticipantID: '';
  Type: '';
}

declare class EventTableForm {
  ListOfEventTable: any;
}
