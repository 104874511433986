import { Component, OnInit } from '@angular/core';
import { NavigationItemComponent } from '../../../../components/navigation-item/navigation-item/navigation-item.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {

  }


  openNavItems() {
    const modalRef = this.modalService.open(NavigationItemComponent, {
      size: 'lg', backdrop: 'static', keyboard: false 
    });
    // modalRef.componentInstance.convertOpportunityData = this.convertOpportunityData;
  }
}
