<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon role fa fa-usersPermission fa fa-cog">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Role Permission</p>
      </h3>
    </div>
  </div>


  <div class="bg_white_margin_top min_top">
    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Role Name</label>
      <div class="col-sm-4">
        <select class="form-control" [(ngModel)]="rolePermissionForm.RoleID" (change)="onRoleName($event.target.value)">
          <option *ngFor="let role of RoleNameList" value={{role.Value}}>{{role.Text}}</option>
        </select>
      </div>
    </div>

    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>

    <table class="table table-striped">
      <thead>
        <tr>
          <th class="page_name_width"><b>Page Name</b></th>
          <th><b>Create</b></th>
          <th><b>Display</b></th>
          <th><b>Alter</b></th>
          <th><b>Delete</b></th>
          <th><b>Print</b></th>
          <th><b>Email</b></th>
          <th><b>Export</b></th>
        </tr>
      </thead>
      <tbody>

        <!-- <tr>
          <td class="filterable-cell page_name_width">{{listOfRolePermissionObj.PageName}}</td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="listOfRolePermissionObj.Create">
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="listOfRolePermissionObj.Display">
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="listOfRolePermissionObj.Alter" >
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="listOfRolePermissionObj.Delete" >
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="listOfRolePermissionObj.Print" >
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="listOfRolePermissionObj.Email" >
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="listOfRolePermissionObj.Export">
          </td>
        </tr> -->


        <tr *ngFor="let roleList of rolePermissionForm.RolePermissionList">
          <td class="filterable-cell page_name_width">{{roleList.PageName}}</td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="roleList.Create">
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="roleList.Display">
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="roleList.Alter">
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="roleList.Delete">
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="roleList.Print">
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="roleList.Email">
          </td>
          <td class="filterable-cell">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="roleList.Export">
          </td>
        </tr>
      </tbody>
    </table>
    <button type="button" class="btn btn-default" (click)="resetForm()">Cancel</button>
    <button type="button" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
    <!-- </form> -->
  </div>
</div>