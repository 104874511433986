import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { ClientService } from '../../../services/client/client.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  clientData: any;
  clientParamId: any;
  showSpinner: Boolean = true;
  RealtedOn = true;
  activityOn = true;
  quoteOn = false;
  orderOn = false;
  invoiceOn = false;
  DetailOn = false;
  collectionOn = false;
  subscriptions = false;
  selectedPage: any = {};
  constructor(private route: ActivatedRoute,
    private clientService: ClientService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.clientParamId = params.id;

      this.clientService.editByClientId(params.id).subscribe(data => {
        this.clientData = data;
        this.showSpinner = false;
      });

      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMClient',
      });
    });
  }

  OnRelated() {
    this.RealtedOn = true;
    this.quoteOn = false;
    this.orderOn = false;
    this.invoiceOn = false;
    this.DetailOn = false;
    this.collectionOn = false;
    this.subscriptions = false;
  }
  OnDetail() {
    this.DetailOn = true;
    this.RealtedOn = false;
    this.quoteOn = false;
    this.orderOn = false;
    this.invoiceOn = false;
    this.collectionOn = false;
    this.subscriptions = false;
  }

  onQuote() {
    this.DetailOn = false;
    this.RealtedOn = false;
    this.quoteOn = true;
    this.orderOn = false;
    this.invoiceOn = false;
    this.collectionOn = false;
    this.subscriptions = false;
  }

  onOrder() {
    this.DetailOn = false;
    this.RealtedOn = false;
    this.quoteOn = false;
    this.orderOn = true;
    this.invoiceOn = false;
    this.collectionOn = false;
    this.subscriptions = false;
  }

  onInvoice() {
    this.DetailOn = false;
    this.RealtedOn = false;
    this.quoteOn = false;
    this.orderOn = false;
    this.invoiceOn = true;
    this.collectionOn = false;
    this.subscriptions = false;
  }

  onCollection() {
    this.DetailOn = false;
    this.RealtedOn = false;
    this.quoteOn = false;
    this.orderOn = false;
    this.invoiceOn = false;
    this.collectionOn = true;
    this.subscriptions = false;
  }

  onSubscriptions() {
    this.DetailOn = false;
    this.RealtedOn = false;
    this.quoteOn = false;
    this.orderOn = false;
    this.invoiceOn = false;
    this.collectionOn = false;
    this.subscriptions = true;
  }

  onActivity() {
    this.activityOn = true;
  }

}
