import { GlobalService } from './../../../services/global-Service/global.service';
import { EmployeeInfoService } from '../../../services/employeeInfo/employee-info.service';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';
import { RfqService } from '../../../services/common/activity-page/rfq/rfq.service';
import { OpportunityService } from '../../../services/opportunity/opportunity.service';
import { EmailService } from '../../../services/common/activity-page/email/email.service';

@Component({
  selector: 'app-requestfor-quote',
  templateUrl: './requestfor-quote.component.html',
  styleUrls: ['./requestfor-quote.component.css']
})
export class RequestforQuoteComponent implements OnInit {
  StatusList: AutocompleteModel[];
  rfqData: any;
  DetailOn = true;
  RelatedRfqId: any;
  rfqStatusForm: UntypedFormGroup;
  showSpinner: Boolean = true;
  subject = 'RFQ status is changed';
  fromAddress: any = null;
  toAddress: any = null;
  fromName: '';
  textBody: any;
  ownerEmpName: any;
  opportunityData: any;
  constructor(
    private route: ActivatedRoute,
    private variableService: VariableService,
    private rfqService: RfqService,
    private fb: UntypedFormBuilder,
    private masterLookupService: MasterLookupService,
    private employeeInfoService: EmployeeInfoService,
    private opportunityService: OpportunityService,
    private emailService: EmailService,
    private globalService: GlobalService
  ) {
  }

  ngOnInit() {
    const q: any = this.variableService.getSearchKeys();
    this.rfqStatusForm = this.fb.group({
      RFQID: q.paramId,
      Status: '',
    });

    this.route.params.subscribe(params => {

      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMRFQ',
      });

      this.rfqService.getByRfqById(params.id).subscribe(data => {
        this.rfqData = data;
        this.showSpinner = false;
        this.rfqStatusForm.patchValue({ Status: data.RfqStatus, RFQID: data.RFQId });
        this.getOpportunityData(data.RelatedToId);
        this.getAllEmployeeInfo(data.AssignedToId);
      });
      this.getRfqStatusList();
    });
  }

  OnDetail() {
    this.DetailOn = true;
  }

  getRfqStatusList() {
    this.masterLookupService.getDropdownList('RFQ Status').subscribe(data => {
      this.StatusList = data;
    });
  }

  getAllEmployeeInfo(assignedToID) {
    this.employeeInfoService.getEmployeeInfoList(assignedToID).subscribe(infodata => {
      this.fromAddress = infodata.EmailID;
      this.ownerEmpName = infodata.EmployeeName;
    });
  }

  getOpportunityData(opportunityId) {
    if (opportunityId !== null) {
      this.opportunityService.getOpportunityById(opportunityId, 'Opportunity').subscribe(data => {
        this.opportunityData = data;
        this.onSelectEmployee(data.OwnerId);
      });
    }
  }

  onSelectEmployee(employeeID) {
    this.employeeInfoService.getEmployeeInfoList(employeeID).subscribe(data => {
      this.toAddress = data.EmailID;
      this.fromName = data.EmployeeName;
    });
  }

  checkEmailId() {
    if (this.fromAddress === '' || this.fromAddress === null) {
      this.globalService.showEmailError();
    } else if (this.toAddress === '' || this.toAddress === null) {
      this.globalService.showEmailError();
    } else {
      this.sendEmail();
    }
  }

  onSubmit(form: UntypedFormGroup) {
    this.rfqService.createRfqStatus({
      RFQID: form.value.RFQID,
      Status: form.value.Status
    }).subscribe(data => {
      this.globalService.showDefaultSuccess({ masterName: 'Request for quote' });
      this.checkEmailId();
    });
  }

  sendEmail() {
    this.textBody = 'Dear' + ' ' + this.opportunityData.OpportunityOwnerName + ',' +
      '<br/>' + '<br/>' + this.rfqData.JobWork + ',' + ' RFQ Status is changed to' + '"' +
      this.rfqData.RfqStatusName + '"' + ' for '
      + '"' + this.opportunityData.OpportunityName + '"' + '.' + ' Please verify the details. ' + '<br/>' + '<br/>' +
      'Regards, ' + '<br/>' + this.ownerEmpName;
    if (this.fromAddress != null && this.toAddress != null) {
      this.emailService.sendEmail({
        Subject: this.rfqData.DealName + ' ' + this.subject,
        FromAddress: this.fromAddress,
        ToAddress: this.toAddress,
        FromName: this.fromName,
        TextBody: this.textBody,
        RelatedToId: this.rfqData.RelatedToId,
      }).subscribe(senddata => { });
    }
  }

  reset() {
    this.rfqStatusForm.reset();
  }

}
