import * as XLSX from "xlsx";
import * as jsPDF from "jspdf";
import "jspdf-autotable";

const EXCEL_EXTENSION = ".xlsx";
const CSV_EXTENSION = ".csv";

const getColumnsObject = (a: any[]) => {
  const newObj = {};
  a.forEach((obj: any) => {
    newObj[obj.Name] = obj;
  });

  return newObj;
};

const exportToExcel = (json: any[], fileName: string) => {
  const workBook = XLSX.utils.book_new(); // create a new blank book
  const workSheet = XLSX.utils.json_to_sheet(json);

  XLSX.utils.book_append_sheet(workBook, workSheet, "data"); // add the worksheet to the book
  XLSX.writeFile(workBook, fileName + EXCEL_EXTENSION); // initiate a file download in browser
};

const converToPdf = (columns, rows, fileName) => {
  // html2pdf(element);
  var doc: any = new jsPDF("p", "pt");
  doc.autoTable(columns, rows);
  doc.save(fileName);
};

// Download CSV
const convertToCSV = (data: any, filename: string) => {
  var csvData = CSVData(data);
  var a: any = document.createElement("a");
  a.setAttribute("style", "display:none;");
  document.body.appendChild(a);
  var blob = new Blob([csvData], { type: "text/csv" });
  var url = window.URL.createObjectURL(blob);
  a.href = url;

  var isIE = /*@cc_on!@*/ false || !!(<any>document).documentMode;

  if (isIE) {
    // var retVal = navigator.msSaveBlob(blob, filename + CSV_EXTENSION);
  } else {
    a.download = filename + CSV_EXTENSION;
  }
  // If you will any error in a.download then dont worry about this.
  a.click();
};

// convert Json to CSV data
const CSVData = (objArray: any) => {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";
  var row = "";

  for (var index in objArray[0]) {
    //Now convert each value to string and comma-seprated
    row += index + ",";
  }
  row = row.slice(0, -1);
  //append Label row with line break
  str += row + "\r\n";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";

      line += '"' + array[i][index] + '"';
    }

    str += line + "\r\n";
  }

  return str;
};

const download = (content, filename, mimeType) => {
  // decode base64 string, remove space for IE compatibility
  var binary = atob(content.replace(/\s/g, ""));
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  var blob = new Blob([view], { type: mimeType });
  var url = URL.createObjectURL(blob);
  var a: any = document.createElement("a");
  a.setAttribute("style", "display:none;");
  document.body.appendChild(a);
  a.href = url;
  a.download = filename;
  a.click();
};

const converBase64toBlob = (content, contentType) => {
  contentType = contentType || "";
  var sliceSize = 512;
  var byteCharacters = window.atob(content); //method which converts base64 to binary
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, {
    type: contentType
  }); //statement which creates the blob
  return blob;
};

const getTime = () => {
  const interval = 30 * 60 * 1000; // 30 minutes
  const period = 24 * 60 * 60 * 1000; // dat period
  const timeData = [];

  // just converts any time to desired string
  const toString = function toString(time) {
    let h = time.getHours();
    const m = time.getMinutes();
    const p = h >= 12 ? 'PM' : 'AM';
    h = h || 12;
    h = h > 12 ? h - 12 : h;
    return ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2) + ' ' + p;
  };

  // start time
  const time = new Date(2010, 0, 1);

  // resulting array

  for (let t = +time; t < +time + period; t += interval) {
    const d = toString(new Date(t));
    timeData.push({ 'timeId': d, 'timeValue': d });
  }
  return timeData;
};

export { getColumnsObject, convertToCSV, exportToExcel, converToPdf, download, getTime };
