import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { serviceURLs } from '../../constants/CRMService/serviceURLs';
import { Observable } from 'rxjs';
@Injectable()
export class ApiService {
  constructor(private http: HttpClient) { }

  public get(url: string):Observable<any> {
    return this.request(url, 'GET');
  }

  public post(url: string, data: any,type?:string):Observable<any> {
    return this.request(url, 'POST', data,type);
  }

  public delete(url: string):Observable<any> {
    return this.request(url, 'DELETE');
  }

  public put(url: string, data: any):Observable<any> {
    return this.request(url, 'PUT', data);
  }

  public request(url: string, method: string, data?: any,type?:any):Observable<any> {
    const user: any = sessionStorage.getItem('currentUser');
    let createHeaders = {};
    if(type=='file'){
      // createHeaders['Content-Type']= 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW';
    }else{
      createHeaders['Content-Type']= 'application/json';}
    // console.log("currentUser", user);
    let apiURL = serviceURLs.CRMServiceBaseURL + url;
    const userData = JSON.parse(user);
    if (user) {
      const token = JSON.parse(userData.Token).access_token;
      createHeaders['Authorization']= `Bearer ${token}`;

      if (!data) {
        // tslint:disable-next-line:max-line-length
        apiURL = `${apiURL}companyId=${userData.CompanyId}&userId=${userData.UserId}&ClientId=${userData.ClientID}&IsClient=${userData.IsClient}`;
      } else {
        data.CompanyId = userData.CompanyId;
        data.UserId = userData.UserId;
        data.ClientID = userData.ClientID;
        data.IsClient = userData.IsClient;
      }
    }
    const headers = new HttpHeaders(createHeaders);

    return this.http
      .request(method,apiURL,{
        headers: headers,
        body: data ? data : null
      }).pipe()
      // .map(res => {
      //   return res;
      // });
  }
}
