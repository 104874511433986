<ng-template #rfqModel let-c="close" let-d="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-header">
        <h4 class="modal-title text-center">Add Related RFQ</h4>
    </div>
    <div class="modal-body">
        <form [formGroup]="rfqForm" novalidate (ngSubmit)="onSubmit(rfqForm,rfqModel)">
            <div class="form-group ">
                <label for="name">Job Work Details</label>
                <input type="textbox" class="form-control" formControlName="JobWork">
                <input type="hidden" formControlName="RelatedToId">
            </div>
    
            <div class="form-group">
                <label for="text">Deadline</label>
                <input type="text" class="form-control" formControlName="DeadLine" value="{{ rfqForm.get('DeadLine').value | date: 'dd-MM-yyyy' }}"
                    #dp="bsDatepicker" bsDatepicker>
                <p class="inputdate" (click)="dp.toggle()">
                    <i class="glyphicon glyphicon-calendar"></i>
                </p>
            </div>
    
            <div class="form-group">
                <label for="name">Remarks</label>
                <input type="textbox" class="form-control" formControlName="Remarks">
            </div>
    
            <div class="form-group">
                <label for="name">Total Hours Of Budget</label>
                <input type="textbox" class="form-control" formControlName="TotalHoursBudget">
            </div>
    
            <div class="form-group">
                <label for="name">Branch</label>
                <select formControlName="TerritoryId" class="form-control" >
                    <option *ngFor="let territory of TerritoryList" value={{territory.Value}}>{{territory.Text}}</option>
                </select>
            </div>
    
        <!-- <div class="form-group">
            <label for="name">Assigned To</label>
            <select formControlName="AssignedToId" class="form-control" (change)="onEmployeeSelect($event.target.value)">
                <option *ngFor="let employee of autoCompleteEmployee" value={{employee.Value}}>{{employee.Text}}</option>
            </select>
        </div> -->
            <div class="text-right">
                <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!rfqForm">Save</button>
            </div>
            
        </form> 
    </div>
  </ng-template>
<div class="">
    <div class="buttonHeader text-right">
        <button class="buttonNew" (click)="openRFQForm(rfqModel)">Add New</button>
    </div>
    <div class="borderClass">
        <div class="tab_title">
            <h3 class="page_title pull-left">
                <span class="menu_icon CRMRFQ glyphicon glyphicon-pencil">
                </span>
                Related RFQ ({{rfqDetails?.length}})
            </h3>
        </div>

        <div class="related_details">
            <div class="row">
                <!-- <div class="col-md-6 col-xs-12 mb-10" *ngFor="let rfq of rfqDetails">
                    <a class="dottedLine" (click)="gotoDetailPage(account.AccountId)" href="javascript:void(0)">{{account.AccountName}}</a>
                    <div style="margin-left: 10px;">Job Work :
                        <p>{{rfq.JobWork}}</p>
                    </div>
                    <div style="margin-left: 10px;">Remarks :
                        <p>{{rfq.Remarks}}</p>
                    </div>
                </div> -->
                <div class="col-md-5 col-xs-12 ml-4 mb-10" *ngFor="let item of rfqDetails">
                    <table class="custom_table">
                      <tr>
                        <th class="custom_name">Job Work </th>
                        <th class="custom_colon"> : </th>
                        <td class="caseheadLine"> {{item.JobWork}}</td>
                      </tr>
              
                      <tr>
                        <th class="custom_name">Remarks </th>
                        <th class="custom_colon"> : </th>
                        <td class="custom_detail_name"> {{item.Remarks}}</td>
                      </tr>
                    </table>
                  </div>
            </div>
        </div>
    </div>

    <!-- <div class="related_details">
        <div class="row">
            <div class="col-md-8 mb-10" *ngFor="let rfq of rfqDetails">
                <div>Job Work:
                    <p>{{rfq.JobWork}}</p>
                </div>
                <div>Remarks:
                    <p>{{rfq.Remarks}}</p>
                </div>
            </div>
        </div>
    </div> -->

</div>