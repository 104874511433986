import { ProjectSalesOrderComponent } from './../project-sales-order/project-sales-order.component';
import { Component, OnInit, Input } from '@angular/core';
import { ProjectOrderService } from '../../../services/projectOrder/project-order.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
  selector: 'app-project-order',
  templateUrl: './project-order.component.html',
  styleUrls: ['./project-order.component.css']
})
export class ProjectOrderComponent implements OnInit {
  @Input() editOrderData: any;
  projectOrderData: any;
  totalSum = 0;
  constructor(
    private projectOrderService: ProjectOrderService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.getAllProjectOrder();
  }

  open() {
    const modalRef = this.modalService.open(ProjectSalesOrderComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAllProjectOrder();
    });
  }

  getAllProjectOrder() {
    this.projectOrderService.getProjectsOrder().subscribe(data => {
      this.projectOrderData = data;
      this.sumAllData();
    });
  }

  sumAllData() {
    this.totalSum = 0;
    for (let i = 0; i < this.projectOrderData.length; i++) {
      if (this.projectOrderData[i].Amount !== NaN) {
        this.totalSum += this.projectOrderData[i].Amount;
      }
    }
  }

  editOrder(order) {
    this.projectOrderService.getProjectsOrderById(order.OrderId).subscribe(data => {
      this.editOrderData = data;
      const modalRef = this.modalService.open(ProjectSalesOrderComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.editOrderData = this.editOrderData;
      modalRef.componentInstance.getData.subscribe(() => {
        this.getAllProjectOrder();
      });
    });
  }
}
