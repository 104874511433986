import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'table-filter',
  templateUrl: './tablefilter.component.html',
  styleUrls: ['./tablefilter.component.css']
})
export class TableFilterComponent implements OnInit, OnChanges {
  // @Output() addLogic = new EventEmitter();
  // @Output() removeLogic = new EventEmitter();
  @Output() applyFilter = new EventEmitter();
  @Input() isFilterLogic: boolean;
  @Output() toggleFilter = new EventEmitter();
  @Output() setFilterLogic = new EventEmitter();
  filterForm: UntypedFormGroup;
  @Input() filterArray: any[];
  @Input() filterLogic: string;
  selected: boolean;
  index: any;
  edit: boolean;
  isFilterLogicShow = false;
  @Input() cols: any[];
  @Input() filterColumns: any;
  tableFilters: any[];
  sortedCols=[];
  sqlOperators = [
    'Equals',
    'Not Equal To',
    'Greater than',
    'Less than',
    'Less or Equal',
    'Greater or Equal',
    'Contains',
    'Does Not Contain',
    'Start With'
  ];
  selectedColumn: string;
  selectedOperator: string;
  constructor(private fb: UntypedFormBuilder) {
    this.filterForm = this.fb.group({
      columnName: [null, Validators.required],
      sqlOperator: null,
      sqlOperatorValue: [null, Validators.required],
      value: null,
      valueIndicator: null
    });
  }
  dynamicSort(property) {
      var sortOrder = 1;
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a,b) {
          /* next line works with strings and numbers, 
          * and you may want to customize it to your needs
          */
          var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result * sortOrder;
      }
  }
  ngOnInit() {
    this.sortedCols = [...this.cols];
    this.sortedCols = this.sortedCols.sort( this.dynamicSort("Value") )
    this.tableFilters = this.getFiletrArray(this.filterArray);
  }

  showFilterLogic() {
    this.isFilterLogicShow = true;
  }

  filter(form: any) {
    // if (this.tableFilters.length > 1) {
    // this.setLogic();
    // } else {

    // }
    this.applyFilter.next(
      this.tableFilters.filter(a => {
        return a;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    // for (let propName in changes) {
    //   let change = changes[propName];

    //   if (propName === 'cols') {
    //     this[propName] = change.currentValue.sort(function(a, b) {
    //       return a.Value.localeCompare(b.Value);
    //     });
    //   }
    // }
  }

  addFilter(form: UntypedFormGroup) {
    const obj = form.value;
    switch (obj.sqlOperatorValue) {
      case 'Start With':
        obj.sqlOperator = 'LIKE';
        obj.value = obj.valueIndicator + '%';
        break;
      case 'Equals':
        obj.sqlOperator = '=';
        obj.value = obj.valueIndicator;
        break;
      case 'Not Equal To':
        obj.sqlOperator = '!=';
        obj.value = obj.valueIndicator;
        break;
      case 'Greater than':
        obj.sqlOperator = '>';
        obj.value = obj.valueIndicator;
        break;
      case 'Less than':
        obj.sqlOperator = '<';
        obj.value = obj.valueIndicator;
        break;
      case 'Less or Equal':
        obj.sqlOperator = '<=';
        obj.value = obj.valueIndicator;
        break;
      case 'Greater or Equal':
        obj.sqlOperator = '>=';
        obj.value = obj.valueIndicator;
        break;
      case 'Contains':
        obj.sqlOperator = 'LIKE';
        obj.value = '%' + obj.valueIndicator + '%';
        break;
      case 'Does Not Contain':
        obj.sqlOperator = 'NOT LIKE';
        obj.value = '%' + obj.valueIndicator + '%';
        break;
      default:
        obj.sqlOperator = obj.sqlOperator;
        break;
    }
    if (this.edit) {
      // this.updateFilter.next({
      //   i: this.index,
      //   value: form.value
      // });
      this.tableFilters[this.index] = form.value;
    } else {
      // this.setFilter.next(form.value);
      this.tableFilters.push(obj);
    }

    // if (this.isFilterLogicShow) {
    this.setLogic();
    // }
    this.filterForm.reset();
    this.selected = false;
    this.edit = false;
    return false;
  }

  showFilter(obj: any, i) {
    this.filterForm.patchValue(obj);
    this.selected = true;
    this.edit = true;
    this.index = i;
  }

  showDetails() {
    this.selected = true;
    this.edit = false;
    this.filterForm.patchValue({
      columnName: this.cols[0].Name,
      sqlOperator: null,
      sqlOperatorValue: this.sqlOperators[0],
      value: null,
      valueIndicator: null
    });
  }

  closeFilterInput() {
    this.selected = false;
    this.edit = false;
  }

  removeFilter(i?: any) {
    // this.filterRemove.next(i);
    if (i !== undefined) {
      this.tableFilters.splice(i, 1);
    } else {
      this.tableFilters = [];
    }
    if (this.tableFilters.length > 1) {
      this.setLogic();
    } else {
      this.setLogic();
    }
  }

  togglefilter() {
    this.toggleFilter.emit();
  }

  applyFilterLogic(logic: string) {
    this.setFilterLogic.next(logic);
  }

  filtersChanged() {
    if (
      JSON.stringify(this.tableFilters) !== JSON.stringify(this.filterArray)
    ) {
      return true;
    } else {
      return false;
    }
  }

  newValueOrChanged(i, filter) {
    if (!this.filterArray[i]) {
      return true;
    } else if (JSON.stringify(this.filterArray[i]) !== JSON.stringify(filter)) {
      return true;
    } else {
      return false;
    }
  }

  cancel() {
    this.tableFilters = this.getFiletrArray(this.filterArray);
  }

  setLogic() {
    if (this.tableFilters.length > 1) {
      let l: any;
      // this.tableFilters.splice(0, 1);
      this.tableFilters.forEach((filter, i) => {
        if (i > 0) {
          l += ' AND ' + (i + 1);
        } else {
          l = '1';
        }
      });
      // if (this.tableFilters.length > 1) {
      this.applyFilterLogic(l);
      // }
    } else {
      this.applyFilterLogic('');
    }
  }

  getFiletrArray(arr: any[]) {
    return arr.filter(a => {
      return a;
    });
  }
  removeLogic() {
    // this.applyFilterLogic("");
    this.isFilterLogicShow = false;
  }
}
