<nav class="navbar navbar-default custom_navbar grey_back">
  <div class="nav-bar-inner">
    <ul class="nav navbar-nav cl" routerLinkActive="active">

      <li class="nav-item" [ngClass]="{'active': newEventOn===true}">
        <a class="nav-link" (click)=OnNewEvent()>New Meeting</a>
      </li>

      <li class="nav-item" [ngClass]="{'active': NewTaskOn===true}">
        <a class="nav-link" (click)="OnNewTask()">New Task</a>
      </li>

      <li class="nav-item" [ngClass]="{'active': logCallOn===true}">
        <a class="nav-link" (click)="OnLogcall()">Log a Call</a>
      </li>

      <li class="nav-item" [ngClass]="{'active': newEmailOn===true}">
        <a class="nav-link" (click)=OnEmail()>Send Email</a>
      </li>
    </ul>

    <ul class="cl custom_content_tab">
      <li class="nav-item">
        <app-newtask *ngIf="NewTaskOn===true" (eventNewTask)="getAllTasks()"></app-newtask>
      </li>
      <li class="nav-item">
        <app-logcall *ngIf="logCallOn===true" (eventLogCall)="getAllCallLogs()"></app-logcall>
      </li>

      <li class="nav-item">
        <app-new-event *ngIf="newEventOn===true" (eventEvent)="getAllEvents()"></app-new-event>
      </li>

      <li class="nav-item">
        <app-new-email *ngIf="newEmailOn===true" (eventEmail)="getAllEmails()"></app-new-email>
      </li>
    </ul>
  </div>
</nav>

<div class="white_bg">
  <p class="headingclass">Activity Timeline</p>
  <div class="div_strikethrough">
    <span></span>
    <p class="">Next Steps</p>
  </div>
  <!-- Email -->
  <div class="task_holder email_holder">
    <div class="task_holder_inner" *ngFor="let item of emailData" [ngClass]="{'show_details': item.active}">
      <div class="task_icon">
        <span class="arrow_icon" (click)="activateClass(item)">
          <i class="fas fa-chevron-down"></i>
        </span>
        <span class="icon_holder new_email">
          <i class="fas fa-envelope" aria-hidden="true"></i>
        </span>
      </div>
      <div class="setTaskWidth">
        <div class="event-bar-line">
          <p class="activityHeading">
            <span class="headingSub" title="{{item.Subject}}">{{item.Subject}}</span>
          </p>
          <p class="activityTo">You sent an email to
            <a class="custom_wrap" href="mailto:{{item.ToAddress}}">{{item.ToAddress}}</a>
          </p>
          <div class="tast_desc_cont tast_desc">
            <p class="custom_wrap">From Address:{{item.FromName}}</p>
            <p class="custom_wrap">To Address:{{item.ToAddress}}</p>
            <p class="custom_wrap" [innerHTML]=item.TextBody>Text Body:{{item.TextBody}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Task-->
  <div class="task_holder">
    <div class="task_holder_inner" *ngFor="let item of tasks" [ngClass]="{'show_details': item.active}">
      <div class="task_icon">
        <span class="arrow_icon" (click)="activateClass(item)">
          <i class="fas fa-chevron-down"></i>
        </span>
        <span class="icon_holder new_task">
          <i class="fa fa-tasks fa-fw" aria-hidden="true"></i>
        </span>
      </div>
      <div class="setTaskWidth">
        <div class="task-bar-line">
          <p class="activityHeading">
            <span class="headingSub" title="{{item.Subject}}">
              {{item.Subject}}</span>
          </p>
          <p class="activityTo">You have an upcoming Task</p>
          <div class="tast_desc_cont tast_desc">
            <p class="">Description:{{item.Description}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- NEW EVENT-->
  <div class="div_strikethrough">
    <span></span>
    <p class="activityName">New Event</p>
  </div>
  <div class="task_holder event_holder">
    <div class="task_holder_inner" *ngFor="let item of events" [ngClass]="{'show_details': item.active}">
      <div class="task_icon">
        <span class="arrow_icon" (click)="activateClass(item)">
          <i class="fas fa-chevron-down"></i>
        </span>
        <span class="icon_holder new_event">
          <i class="fa fa-calendar-alt fa-fw" aria-hidden="true"></i>
        </span>
      </div>
      <div class="setTaskWidth">
        <div class="event-bar-line">
          <p class="activityHeading">
            <a class="dottedLine" (click)="gotoDetailPage(item.EventId)">{{item.Subject}}</a>
          </p>
          <p class="activityTo">You have an upcoming Event</p>
          <div class="tast_desc_cont tast_desc">
            <p class="">Location:{{item.Location}}</p>
            <p class="">StartDate:{{item.StartDate | date:'dd-MM-yyyy'}} {{item.StartTime}}</p>
            <p class="">EndDate:{{item.EndDate | date:'dd-MM-yyyy'}} {{item.EndTime}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- LOG CALL-->
  <div class="div_strikethrough">
    <span></span>
    <p class="activityName">Past Activity</p>
  </div>
  <div class="task_holder log_call_holder">
    <div class="task_holder_inner" *ngFor="let item of logcalls" [ngClass]="{'show_details': item.active}">
      <div class="task_icon">
        <span class="arrow_icon" (click)="activateClass(item)">
          <i class="fas fa-chevron-down"></i>
        </span>
        <span class="icon_holder log_call">
          <i class="fa fa-address-book fa-fw" aria-hidden="true"></i>
        </span>
      </div>
      <div class="setTaskWidth">
        <div class="event-bar-line">
          <p class="activityHeading">
            <span class="headingSub" title="{{item.CreatedOn}}"> {{item.CreatedOn | date:'dd-MM-yyyy'}} </span>
            <br />
          </p>

          <p class="activityTo" title="{{item.Subject}}"> {{item.Subject}}</p>
          <div class="tast_desc_cont tast_desc">
            <p class="tast_desc">Description : {{item.Comments}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>