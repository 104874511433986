import { DatePipe } from '@angular/common';

import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, ViewContainerRef, ViewChild, ElementRef } from '@angular/core';
import { VariableService } from '../../../../services/VariableService/variable.service';
import { EmailService } from '../../../../services/common/activity-page/email/email.service';
import { EmployeeInfoService } from '../../../../services/employeeInfo/employee-info.service';
import { AutocompleteService } from '../../../../services/autocomplete/autocomplete.service';
import { TaskService } from '../../../../services/task/task.service';
import { GlobalService } from '../../../../services/global-Service/global.service';
@Component({
  selector: 'app-newtask',
  templateUrl: './newtask.component.html',
  styleUrls: ['./newtask.component.css']
})
export class NewtaskComponent implements OnInit {
  @ViewChild('contactLeadtextInput') contactLeadtextInput: ElementRef;
  @ViewChild('relatedtextInput') relatedtextInput: ElementRef;
  masterTypeText: any;
  masterTypeValue: any;
  employeeInfoData: any;
  @Output() eventNewTask = new EventEmitter();
  public disableDropdown: Boolean = true;
  newTaskForm: UntypedFormGroup;
  LeadContactNameType: any;
  taskNotifyName: any;
  RelatedToType: any;
  fromName: any;
  fromaddress: any = null;
  toaddress: any = null;
  relatedid: any;
  textBody: any;
  assignedName: any;
  employeefilteredOptions: any[];
  employeeValue: any; employeeText: any;
  selectedContactLeadOption: any;
  selectedRelatedOption: any;
  contactLeadFilterOption: any[];
  relatedTypeFilterOption: any[];

  NameTypeList = this.globalService.NameTypeList;

  RelatedTypeList = this.globalService.NameTypeList;
  
  constructor(
    public taskService: TaskService,
    public autocompleteService: AutocompleteService,
    public fb: UntypedFormBuilder,
    private variableService: VariableService,
    private emailService: EmailService,
    private employeeInfoService: EmployeeInfoService,
    private datePipe: DatePipe,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.newTaskForm = this.fb.group({
      TaskId: null,
      Subject: '',
      AssignedTo: '',
      ContactLeadId: '',
      DueDate: new Date(),
      RelatedToId: '',
      Status: '',
      RelatedType: '',
      NameType: '',
    });
    this.disableRelatedTo();
    this.setMasterType();
    this.getMasterTypeList();
  }

  setMasterType() {
    const q: any = this.variableService.getSearchKeys();

    if (q.type === 'CRMLead' || q.type === 'CRMContact') {
      this.LeadContactNameType = q.type.slice(3);
      this.newTaskForm.controls['ContactLeadId'].patchValue(q.paramId);
    } else {
      this.RelatedToType = q.type.slice(3);
      this.newTaskForm.controls['RelatedToId'].patchValue(q.paramId);
    }

    this.newTaskForm.patchValue({
      NameType: this.LeadContactNameType,
      RelatedType: this.RelatedToType,
    });
  }

  getMasterTypeList() {
    const q: any = this.variableService.getSearchKeys();
    this.autocompleteService.getMasterTypeListData(q.type, q.paramId).subscribe(data => {
      this.masterTypeText = data[0].Text;
      this.masterTypeValue = data[0].Value;

      if (q.type === 'CRMLead' || q.type === 'CRMContact') {
        const contactLeadObj = {
          Text: this.masterTypeText,
          Value: this.masterTypeValue,
        };
        this.contactLeadtextInput.nativeElement.value = contactLeadObj.Text;
        this.selectedContactLeadOption = contactLeadObj;
      } else {
        const relatedObj = {
          Text: this.masterTypeText,
          Value: this.masterTypeValue,
        };
        this.relatedtextInput.nativeElement.value = relatedObj.Text;
        this.selectedRelatedOption = relatedObj;
      }
    });
  }

  displayContactFn(contactLead: any) {
    this.selectedContactLeadOption = contactLead;
    return contactLead ? contactLead.Text : '';
  }

  displayRelatedFn(related: any) {
    this.selectedRelatedOption = related;
    return related ? related.Text : '';
  }

  getRealtedTypeList(value) {
    const relatedValue = this.newTaskForm.value.RelatedType;
    if (relatedValue !== '') {
      if (value.length > 1) {
        this.autocompleteService.getAllRelatedTypeList(relatedValue, value).subscribe(
          res => {
            this.relatedTypeFilterOption = res;
          },
          err => {
            console.log('errr====', err);
          });
      }
    }
  }


  getLeadContactList(value) {
    const optionValue = this.newTaskForm.value.NameType;
    if (optionValue !== '') {
      if (value.length > 1) {
        this.autocompleteService.getLeadContactList(optionValue, value).subscribe(
          res => {
            this.contactLeadFilterOption = res;
          },
          err => {
            console.log('errr====', err);
          });
      }
    }
  }

  disableRelatedTo() {
    if (this.LeadContactNameType === 'Lead' || this.LeadContactNameType === 'Contact') {
      this.disableDropdown = false;
    }
  }

  updateNewTask() {
    this.eventNewTask.emit();
  }

  setAutoCompleteEmployee(data) {
    const assignedTo = data.data.option.value.Value;
    this.assignedName = data.data.option.value.Text;
    this.newTaskForm.controls['AssignedTo'].patchValue(assignedTo);
    this.employeeInfoService.getEmployeeInfoList(assignedTo).subscribe(resData => {
      this.employeeInfoData = resData;
      this.toaddress = resData.EmailID;
    });
  }

  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.employeefilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  getAllEmployeeInfo() {
    this.employeeInfoService.getAllInfo().subscribe(infodata => {
      this.fromName = infodata.EmployeeName;
      this.fromaddress = infodata.EmailID;
    });
  }

  formSubmit() {
    const q: any = this.variableService.getSearchKeys();
    const user = JSON.parse(sessionStorage.getItem('currentUser'));

    this.newTaskForm.value.DueDate = this.datePipe.transform(this.newTaskForm.value.DueDate, 'yyyy-MM-dd');
    if (this.newTaskForm.value.ContactLeadId.Id === null) {
      this.newTaskForm.controls['ContactLeadId'].
        patchValue(this.newTaskForm.value.ContactLeadId.Value);
    } else if (this.newTaskForm.value.RelatedToId.Id === null) {
      this.newTaskForm.controls['RelatedToId'].patchValue(this.newTaskForm.value.RelatedToId.Value);
    }
    this.taskNotifyName = this.newTaskForm.value.Subject;
    this.relatedid = this.newTaskForm.value.RelatedToId;
    this.fromaddress = user.EmailId;

    this.textBody = 'Dear' + ' ' + this.assignedName + ',' + '<br/>' + '<br/>' +
      '<strong>' + 'Subject :' + '</strong>' + ' ' + 'New Task' + ' " ' + this.taskNotifyName + ' " .' + '<br/>' + '<br/>' +
      'New Task' + ' " ' + this.taskNotifyName + ' " ' + 'related to' + ' ' +
      q.type.slice(3) + ' " ' + this.masterTypeText + ' " ' + 'is assigned and DueDate is on'
      + ' ' + this.newTaskForm.value.DueDate + '.' + '<br/>' + 'So please check your calendar for daily task and events'
      + '<br/>' + '<br/>' +
      'Regards, ' + '<br/>' + user.FirstName + ' ' + user.LastName + '<br/>' + user.CompanyName + '<br/>';
  }

  onSubmit(form: UntypedFormGroup) {
    let responseMessage = [];
    this.formSubmit();

    this.taskService.addTask(form.value).subscribe(data => {
      responseMessage = data.ErrorMessages;
      if (responseMessage[1] === '0') {
        this.globalService.showSuccess({ masterName: 'Task', name: this.taskNotifyName });
      }
      if (this.fromaddress != null && this.toaddress !== null) {
        this.emailService.sendEmail({
          Subject: this.taskNotifyName,
          FromAddress: this.fromaddress,
          ToAddress: this.toaddress,
          FromName: this.fromName,
          TextBody: this.textBody,
          RelatedToId: this.relatedid,
        }).subscribe(senddata => { });
      }
      this.updateNewTask();
    });
    this.resetForm();
  }

  resetForm() {
    this.newTaskForm.patchValue({
      Subject: '',
      AssignedTo: '',
      DueDate: new Date(),
      Status: '',
      ToAddress: '',
      FromAddress: '',
      TextBody: '',
      FromName: '',
    });
  }
}
