<div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" style="border:1px solid lightgrey">
  <p class="headingclass" style=" margin-top: 15px;"> Event Information</p>

  <div class="row custom_row">
    <div class="col-md-6">
      <p>Subject </p>
      <p>{{eventData.Subject}}</p>
    </div>
    <div class="col-md-6">
      <p>All-Day Event</p>
      <p>{{eventData.IsAllDayEvent}}</p>
    </div>
  </div>

  <div class="row custom_row">
    <div class="col-md-6">
      <p>Start Date </p>
      <p>{{eventData.StartDate | date:'dd-MM-yyyy'}}</p>
    </div>
    <div class="col-md-6">
      <p>Start Time </p>
      <p>{{eventData.StartTime}}</p>
    </div>
  </div>

  <div class="row custom_row">
    <div class="col-md-6">
      <p>End Date </p>
      <p>{{eventData.EndDate | date:'dd-MM-yyyy'}}</p>
    </div>
    <div class="col-md-6">
      <p>End Time </p>
      <p>{{eventData.EndTime}}</p>
    </div>
  </div>

  <div class="row custom_row">
    <div class="col-md-6">
      <p>Name </p>
      <div class="row">
        <p class="custom_related_type">{{eventData.NameType}} </p>
        <p class="custom_related_name">{{eventData.ContactLeadName}}</p>
      </div>
    </div>
    <div class="col-md-6">
      <p>Related To </p>
      <div class="row">
        <p class="custom_related_type">{{eventData.RelatedType}} </p>
        <p class="custom_related_name">{{eventData.RelatedToName}}</p>
      </div>
    </div>
  </div>

  <div class="row custom_row">
    <div class="col-md-6">
      <p>Location </p>
      <p>{{eventData.Location}}</p>
    </div>
  </div>
</div>