import { Injectable } from '@angular/core';
import { ApiService } from '../ApiService/api.service';

@Injectable()
export class MultipleAddressService {

  constructor(
    private apiService: ApiService,
  ) { }
  getMultipleAddress() {
    return this.apiService.get(`api/multipleAddressCRM/getall?`);
  }

  addMultipleAddress(form) {
    return this.apiService.post('api/multipleAddressCRM/create', form);
  }

  updateMultipleAddress(form) {
    return this.apiService.put('api/multipleAddressCRM/edit', form);
  }

  editById(multipleAddressId) {
    return this.apiService.get(`api/multipleAddressCRM/getbyid?Id=${multipleAddressId}&`);
  }
}
