<div class="bg_white_margin_top bg-wight">
    <h3>
        Connect your Profiles
    </h3>
    <div class="row mt-4">
        <div class="col-md-2 col-sm-4 col-xs-12 setup-col">
            <label>Google:</label><button class="btn btn-primary" (click)="connectProfile('Google')">G-Connect</button>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-2 col-sm-4 col-xs-12 setup-col">
            <label>Teams:</label><button class="btn btn-primary" (click)="connectProfile('Teams')">Teams</button>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-2 col-sm-4 col-xs-12 setup-col">
            <label>Zoom:</label><button class="btn btn-primary" (click)="connectProfile('Zoom')">Zoom</button>
        </div>
    </div>
</div>