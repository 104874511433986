<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon mailing_address fa fa-address-card">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Multiple Address</p>
        <p class="page_sub_title">{{editName}}</p>
      </h3>
    </div>
  </div>


  <div class="bg_white_margin_top column_editor min_top">
    <form [formGroup]="mailingAddressForm" novalidate (ngSubmit)="onSubmit(mailingAddressForm)">

      <div class="form-group">
        <label class="control-label col-sm-2" for="text">Address Type</label>
        <div class="col-sm-4">
          <input type="text" class="form-control" formControlName="AddressType">
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-sm-2" for="text">Mailing Name</label>
        <div class="col-sm-4">
          <input type="text" class="form-control" formControlName="Mailing">
        </div>
      </div>

      <nav class="navbar navbar-default custom_navbar grey_back">
        <div class="nav-bar-inner">
          <ul class="nav navbar-nav cl" routerLinkActive="active">
            <li class="nav-item" [ngClass]="{'active': address===true}">
              <a class="nav-link" (click)="OnAddress()">Address</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': security===true}">
              <a class="nav-link" (click)="onCompanyRight()">Statutory Info</a>
            </li>
          </ul>

          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <div *ngIf="address===true" formGroupName="MultipleAddress">
                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Address1</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="Address1">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Address2</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="Address2">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Address3</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="Address3">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">City</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="CityName">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Country</label>
                  <div class="col-sm-4">
                    <select class="form-control" formControlName="CountryCode" (change)="onCountryStateSelect($event.target.value)">
                      <option *ngFor="let country of CountryList" value={{country.Value}}>{{country.Text}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">State</label>
                  <div class="col-sm-4">
                    <select class="form-control" formControlName="State" (change)="onStateDistrictSelect($event.target.value, code)">
                      <option *ngFor="let state of StateList" value={{state.Value}}>{{state.Text}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">District</label>
                  <div class="col-sm-4">
                    <select class="form-control" formControlName="DistrictCode">
                      <option *ngFor="let district of DistrictList" value={{district.Value}}>{{district.Text}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Phone</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="Phone">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Mobile</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="Mobile">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Fax</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="Fax">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Email</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="Email">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Website</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="Website">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Pincode</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="PostalCode">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Is Union Territory</label>
                  <div class="col-sm-4">
                    <select class="form-control" formControlName="IsUnionTerritory" [(ngModel)]="isUT">
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                  </div>
                </div>
              </div>
            </li>

            <li class="nav-item">
              <div *ngIf="security===true">

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">PAN No</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="PANNo">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">CIN No</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="CINNo">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">GSTIN</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="GSTIN">
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">VAT</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="VATNo">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      <button type="button" class="btn btn-default" (click)="resetForm()">Cancel</button>
      <button type="submit" class="btn btn-primary">Save</button>
    </form>
  </div>

  <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>

  <app-htmldatatable [tableData]="multipleAddressData" [columnName]="columnName" [columnRowName]="columnRowName" (edit)="editAddress($event)"
    (onDelete)="onDelete($event)">
  </app-htmldatatable>
</div>