import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { VariableService } from '../../VariableService/variable.service';
import { ApiService } from '../../ApiService/api.service';
@Injectable()
export class ProjectTaskService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) {
  }

  getProjectTaskType() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/projecttask/getall?BaseId=${q.paramId}&MasterType=${q.type}&`);
  }
  // Create Method for ProjectTask
  addProjectTask(form) {
    return this.apiService.post('api/projecttask/create', form);
  }
  // get by id method for ProjectTask
  projectTaskById(projectTaskId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/projecttask/getbyid?Id=${projectTaskId}&MasterType=${q.type}&`);
  }

  updateProjectTask(form) {
    return this.apiService.put('api/projecttask/edit', form);
  }

  createProjectTaskProperty(form) {
    return this.apiService.post('api/projectTaskProperty/create', form);
  }

  CheckInOut(form) {
    return this.apiService.post('api/checkinout/create', form);
  }

}
