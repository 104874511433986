import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OtherEmailService } from '../../../services/other-Email/other-email.service';
import { GlobalService } from '../../../services/global-Service/global.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";

@Component({
  selector: 'app-social-config',
  templateUrl: './social-config.component.html',
  styleUrls: ['./social-config.component.css']
})
export class SocialConfigComponent implements OnInit {
  socialForm:any;
  socialType:any;
  masterType:any;
  constructor(    
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private otherService: OtherEmailService,
    private globalService:GlobalService
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.socialType = params.id;
      if(params.id == 'sms'){
        this.socialForm = this.fb.group({
          SMSHeader: "",
          SMSAPIKey: "",
          Type:"SMS"
        });
        this.masterType = 'SMS';
      }else if(params.id == 'whatsapp'){
        this.socialForm = this.fb.group({
          WAAPIKey: "",
          WAAccountID: "",
          WAPhoneNoID:"",
          Type:"WhatsApp"
        });
        this.masterType = 'WhatsApp';
      }
    });
    this.getData();
  }
  getData(){
    this.otherService.getEmailSetting(this.masterType).subscribe(settingdata => {
      this.socialForm.patchValue(settingdata[0]);
    });
  }
  onSubmit(form: UntypedFormGroup) {
    if (form.value) {
      this.otherService.createEmailSetting(form.value).subscribe(data => {
        let responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccessData('Updated the configurations.');
        }else{
          this.globalService.showErrorData('Something went wrong');
        }
      });
    }
    // this.resetForm();
  }
  resetForm() {
    this.socialForm.reset();
  }
}
