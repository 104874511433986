<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon profile glyphicon glyphicon-user">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Change Password</p>
        <p class="page_sub_title">{{userId}}</p>
      </h3>
    </div>
  </div>

  <div class="bg_white_margin_top min_top">
    <form [formGroup]="changePasswordForm" novalidate (ngSubmit)="onSubmit(changePasswordForm)" class="forgot_pass_cont">
      <!-- <div class="form-group">
        <label class="control-label col-sm-4 col-md-4 col-xs-1" for="text">UserId</label>
        <div class="col-sm-8 col-md-8 col-xs-1">
          <input type="text" class="form-control" formControlName="UserId" [ngModel]="userId">
        </div>
      </div> -->

      <div class="form-group">
        <label class="control-label col-sm-4 col-md-4 col-xs-1" for="text">Old Password</label>
        <div class="col-sm-8 col-md-8 col-xs-1">
          <input type="password" class="form-control" formControlName="OldPassword">
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-sm-4 col-md-4 col-xs-1" for="text">New Password</label>
        <div class="col-sm-8 col-md-8 col-xs-1">
          <input type="password" class="form-control" formControlName="NewPassword">
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-default" (click)="resetForm()">Cancel</button>
        <button class="btn btn-primary" type="submit" [disabled]="!changePasswordForm">Submit</button>
      </div>
    </form>
  </div>

  <!-- <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader> -->
</div>