import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';
@Injectable()
export class MilestoneService {
  constructor(private apiService: ApiService, private variableService: VariableService) {
  }

  getMilestoneType() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/milesstone/getall?BaseId=${q.paramId}&MasterType=${q.type}&`);
  }

  addMilestone(form) {
    return this.apiService.post('api/milesstone/create', form);
  }

  milestoneById(milesStoneId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/milesstone/getbyid?Id=${milesStoneId}&MasterType=${q.type}&`);
  }

  updateMilestone(form) {
    return this.apiService.put('api/milesstone/edit', form);
  }

  createMilestoneStatus(form) {
    return this.apiService.post('api/milestoneStatus/create', form);
  }
}
