
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../../../ApiService/api.service';
import { VariableService } from '../../../VariableService/variable.service';
@Injectable()
export class RfqService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) {
  }
  // Get Method
  getRfq(CRMRFQ) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/RFQ/getall?Id=${q.paramId}&MasterType=${CRMRFQ}&`
    );
  }

  getRfqType() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/RFQ/getall?Id=${q.paramId}&MasterType=${q.type}&`
    );
  }
  // Create Method for RFQ
  createRfq(form) {
    return this.apiService.post('api/RFQ/create', form);
  }
  getByRfqById(RFQId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/RFQ/getbyid?Id=${RFQId}&MasterType=${q.type}&`);
  }

  createRfqStatus(form) {
    return this.apiService.post('api/rfqStatus/create', form);
  }
}
