import { EmailTemplateComponent } from './product/crm/shared/components/activity-page/email-template/email-template.component';
import { ActivitiesPageComponent } from './product/crm/components/activities-page/activities-page/activities-page.component';
import { POSComponent } from './product/crm/components/pos-page/pos/pos.component';
import { DashboardConfigComponent } from './product/crm/components/home-page/dashboard-config/dashboard-config.component';
import { DragdropComponent } from './product/crm/components/draggable/dragdrop/dragdrop.component';
import { Routes } from '@angular/router';
import { PosDashboardComponent } from './product/crm/components/pos-page/pos-dashboard/pos-dashboard.component';

import { AuthGuardService as AuthGuard } from './auth.service/auth-guard.service';
import { LeadsComponent } from './product/crm/components/lead-page/leads/leads.component';
import { AccountsComponent } from './product/crm/components/accounts-page/accounts/accounts.component';
import { CampaignsComponent } from './product/crm/components/campaigns-page/campaigns/campaigns.component';
import { ContactsComponent } from './product/crm/components/contact-page/contacts/contacts.component';
import { EmailsComponent } from './product/crm/components/emails-page/emails/emails.component';
import { ReportsComponent } from './product/crm/components/report-page/reports/reports.component';
import { ReportsListComponent } from './product/crm/components/report-page/reports-list/reports-list.component';
import { ReportsDetailComponent } from './product/crm/components/report-page/report-detail/report-detail.component';
import { ReportsEditComponent } from './product/crm/components/report-page/reports-edit/reports-edit.component';
import { CalendarComponent } from './product/crm/components/calendar-page/calendar/calendar.component';
import { TasksComponent } from './product/crm/components/tasks-page/tasks/tasks.component';
import { OpportunitiesComponent } from './product/crm/components/opportunities-page/opportunities/opportunities.component';
import { HomeComponent } from './product/crm/components/home-page/home/home.component';
import { CasesComponent } from './product/crm/components/case-page/cases/cases.component';
import { ContractsComponent } from './product/crm/components/contracts-page/contracts/contracts.component';
import { ProductsComponent } from './product/crm/components/products-page/products/products.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { AuthLayoutComponent } from './common/layouts/authLayout.component';
import { LayoutComponent } from './common/layouts/layout.component';
import { LeadComponent } from './product/crm/components/lead-page/lead/lead.component';
import { OpportunityComponent } from './product/crm/components/opportunities-page/opportunity/opportunity.component';
import { ContactComponent } from './product/crm/components/contact-page/contact/contact.component';
import { AccountComponent } from './product/crm/components/accounts-page/account/account.component';
import { CampaignComponent } from './product/crm/components/campaigns-page/campaign/campaign.component';
import { ProjectsComponent } from './product/crm/components/project-page/projects/projects.component';
import { ProjectTasksComponent } from './product/crm/components/project-task-page/project-tasks/project-tasks.component';
import { MilestonesComponent } from './product/crm/components/milestone-page/milestones/milestones.component';
import { ContractComponent } from './product/crm/components/contracts-page/contract/contract.component';
import { MilestoneComponent } from './product/crm/components/milestone-page/milestone/milestone.component';
import { ProjectComponent } from './product/crm/components/project-page/project/project.component';
import { ProductComponent } from './product/crm/components/products-page/product/product.component';
import { CaseComponent } from './product/crm/components/case-page/case/case.component';
import { ProjectTaskComponent } from './product/crm/components/project-task-page/project-task/project-task.component';

import { StatusConfigPageComponent } from './product/crm/components/configuration-page/status-config-page/status-config-page.component';
import { SetupComponent } from './product/crm/shared/components/setup-page/setup/setup.component';
import { OtherEmailComponent } from './product/crm/components/emails-page/other-email/other-email/other-email.component';
import { MilestoneConfigComponent } from './product/crm/components/configuration-page/milestone-config/milestone-config.component';
// tslint:disable-next-line:max-line-length
import { TerritoryManagementComponent } from './product/crm/components/configuration-page/territory-management/territory-management.component';
import { TaskTemplateComponent } from './product/crm/components/configuration-page/task-template/task-template.component';
import { QuotesComponent } from './product/crm/components/quotation-page/quotes/quotes.component';
import { EventsComponent } from './product/crm/components/event-page/events/events.component';
import { QuoteComponent } from './product/crm/components/quotation-page/quote/quote.component';
import { ResourcesComponent } from './product/crm/components/resource-page/resources/resources.component';
import { RequestforquotesComponent } from './product/crm/components/rfq-page/requestforquotes/requestforquotes.component';
import { RolePermissionComponent } from './product/crm/components/users-control-page/role-permission/role-permission.component';
import { ProfileComponent } from './product/crm/components/users-control-page/profile/profile.component';
import { NavigationItemComponent } from './product/crm/components/navigation-item/navigation-item/navigation-item.component';
import { RoleComponent } from './product/crm/components/users-control-page/role/role.component';
import { CollectionTargetComponent } from './product/crm/components/target-page/collection-target/collection-target.component';
import { ClientComponent } from './product/crm/components/client-page/client/client.component';
import { ClientsComponent } from './product/crm/components/client-page/clients/clients.component';
import { ResourceComponent } from './product/crm/components/resource-page/resource/resource.component';
import { EventComponent } from './product/crm/components/event-page/event/event.component';
import { RequestforQuoteComponent } from './product/crm/components/rfq-page/requestfor-quote/requestfor-quote.component';
import { DocumentComponent } from './product/crm/components/document-page/document/document.component';
import { SalesOrderComponent } from './product/crm/components/sales-order-page/sales-order/sales-order.component';
import { InvoiceComponent } from './product/crm/components/invoice-page/invoice/invoice.component';

import { UserTrackingComponent } from './product/crm/components/user-tracking/user-tracking.component';
import { TaskComponent } from './product/crm/components/tasks-page/task/task.component';
import { MailingAddressComponent } from './product/crm/components/mailing-address-page/mailing-address/mailing-address.component';
import { TimesheetComponent } from './product/crm/components/timesheet-page/timesheet/timesheet.component';
import { ChangePasswordComponent } from './product/crm/components/users-control-page/change-password/change-password.component';
import { FormBuilderComponent } from './product/crm/components/form-builder/form-builder.component'
import { ProfileConnectComponent } from './product/crm/components/profile-connect/profile-connect.component';
import { MachinesComponent } from './product/crm/components/machine/machines/machines.component';
import { MachineDetailsComponent } from './product/crm/components/machine/machine-details/machine-details.component';
import { LocationsComponent } from './product/crm/components/location/locations/locations.component';
import { LocationComponent } from './product/crm/components/location/location/location.component';
import { StockTakeComponent } from './product/crm/components/stock-take/stock-take.component';
import { SocialConfigComponent } from './product/crm/shared/components/social-config/social-config.component';

export const ROUTES: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'opportunities', component: OpportunitiesComponent },
      { path: 'campaigns', component: CampaignsComponent },
      // { path: 'profile', component: EmailsComponent },
      { path: 'leads', component: LeadsComponent },
      { path: 'tasks', component: TasksComponent },
      { path: 'accounts', component: AccountsComponent },
      { path: 'contacts', component: ContactsComponent },
      { path: 'add-reports', component: ReportsComponent },
      { path: 'reports', component: ReportsListComponent },
      { path: 'calendar', component: CalendarComponent },
      { path: 'statusConfig', component: StatusConfigPageComponent },
      { path: 'setup', component: SetupComponent },
      { path: 'otherEmail', component: OtherEmailComponent },
      { path: 'milestoneConfig', component: MilestoneConfigComponent },
      { path: 'territoryManagement', component: TerritoryManagementComponent },
      { path: 'taskTemplate', component: TaskTemplateComponent },
      { path: 'quotes', component: QuotesComponent },
      { path: 'events', component: EventsComponent },
      { path: 'resources', component: ResourcesComponent },
      { path: 'clients', component: ClientsComponent },
      { path: 'case', component: CasesComponent },
      { path: 'contract', component: ContractsComponent },
      { path: 'product', component: ProductsComponent },
      { path: 'project', component: ProjectsComponent },
      { path: 'projecttask', component: ProjectTasksComponent },
      { path: 'milestone', component: MilestonesComponent },
      { path: 'rfq', component: RequestforquotesComponent },
      { path: 'target', component: CollectionTargetComponent },
      { path: 'role', component: RoleComponent },
      { path: 'rolePermission', component: RolePermissionComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'documents', component: DocumentComponent },
      { path: 'navigationItem', component: NavigationItemComponent },
      { path: 'salesorder', component: SalesOrderComponent },
      { path: 'invoice', component: InvoiceComponent },
      { path: 'emailTemplate', component: EmailTemplateComponent },
      { path: 'dashBoardConfig', component: DashboardConfigComponent },
      { path: 'track-user', component: UserTrackingComponent },
      { path: 'posDashboard', component: POSComponent },
      { path: 'mailingAddress', component: MailingAddressComponent },
      { path: 'timeSheet', component: TimesheetComponent },
      { path: 'activities', component: ActivitiesPageComponent },
      { path: 'changePassword', component: ChangePasswordComponent },
      { path: 'machines', component: MachinesComponent },


      /*  FollowUp Page  */
      { path: 'campaignDetail/:id', component: CampaignComponent },
      { path: 'accountDetail/:id', component: AccountComponent },
      { path: 'contactDetail/:id', component: ContactComponent },
      { path: 'opportunityDetail/:id', component: OpportunityComponent },
      { path: 'leadDetail/:id', component: LeadComponent },
      { path: 'contractDetail/:id', component: ContractComponent },
      { path: 'milestoneDetail/:id', component: MilestoneComponent },
      { path: 'projectDetail/:id', component: ProjectComponent },
      { path: 'projecttaskDetail/:id', component: ProjectTaskComponent },
      { path: 'caseDetail/:id', component: CaseComponent },
      { path: 'productDetail/:id', component: ProductComponent },
      { path: 'quoteDetail/:id', component: QuoteComponent },
      { path: 'eventDetail/:id', component: EventComponent },
      { path: 'resourceDetail/:id', component: ResourceComponent },
      { path: 'clientDetail/:id', component: ClientComponent },
      { path: 'taskDetail/:id', component: TaskComponent },
      { path: 'machinesDetail/:id',component:MachineDetailsComponent},
      { path: 'formBuilder', component: FormBuilderComponent },
      { path: 'profileConnect', component: ProfileConnectComponent },
      { path: 'locations', component: LocationsComponent },
      { path: 'locationsDetail/:id', component: LocationComponent },
      { path: 'stocktake', component: StockTakeComponent },
      { path: 'socialConfig/:id', component: SocialConfigComponent},
      { path: 'POSNew/:id', component:PosDashboardComponent},
      { path: 'POSNew', component:PosDashboardComponent},
      

      {
        path: 'reportsDetail/:id',
        component: ReportsDetailComponent
      },
      {
        path: 'reportsEdit/:id',
        component: ReportsEditComponent
      },
      { path: 'rfqDetail/:id', component: RequestforQuoteComponent },
      { path: '**', component: HomeComponent}
    ]
  }
];

