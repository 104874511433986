import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class PosService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }
  addPos(form) {
    return this.apiService.post('api/pointOfSale/create', form);
  }
  updatePos(form) {
    return this.apiService.put('api/pointOfSale/edit', form);
  }
  search(Text){
    return this.apiService.get(`api/pointOfSale/searchmobiletaxid?Text=${Text}&`);
  }
   // get Method by Id
   editByPOSId(id) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/pointOfSale/getbyid?Id=${id}&MasterType=${q.type}&`);
  }

}
