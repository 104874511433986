<div class="btn_filter">
    <ng-container>
        <div class='custom_filter_div'>

            <div *ngIf='!filtersChanged()' class="row filter_heading">
                <div class="col-md-6 text-left">
                    <h4>Filter</h4>
                </div>
                <div class="col-md-6 text-right">
                    <div class="buttonNew setfilter" (click)="togglefilter()">
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </div>
                </div>
            </div>

            <div *ngIf='filtersChanged()' class="row filter_heading">
                <div class="col-md-6 text-left">
                    <div class="buttonNew setfilter" (click)="cancel()">Cancel</div>
                </div>
                <div class="col-md-6 text-right">
                    <div class="buttonNew setfilter" (click)="filter(filterForm)">Save</div>
                </div>
            </div>
            <div class="row set_filter_cont">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card set_filter" *ngFor="let filter of tableFilters; let i = index" (click)="showFilter(filter, i)"
                        [ngClass]="{'changed': newValueOrChanged(i, filter)}">
                        <div class="close_cont">
                            <span class="close_content" (click)="removeFilter(i)">X</span>
                        </div>
                        <div class="card-body">
                            <p class="card-title">{{filterColumns[filter.columnName].Value}}</p>
                            <p class="card-text">{{filter.sqlOperatorValue}}</p>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 text-left">
                    <div class="buttonNew setfilter" (click)="showDetails()">Add Filter</div>
                </div>
                <div class="col-md-6 text-right">
                    <div class="buttonNew setfilter" (click)="removeFilter()">Remove All</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 text-left">
                    <div class="buttonNew setfilter" (click)="showFilterLogic()">Add Logic</div>
                </div>
                <div class="col-md-6 text-right">
                    <div class="buttonNew setfilter" (click)="removeLogic()">Remove</div>
                </div>
            </div>
            <div class="row" *ngIf="isFilterLogicShow">
                <div class="col-md-12">
                    <textarea class="logic-textarea" resize="vertical" (input)="applyFilterLogic(filterLogic)"
                        [(ngModel)]="filterLogic" placeholder="Apply Logic">{{filterLogic}}</textarea>
                </div>
            </div>
            <form [formGroup]="filterForm" novalidate>
                <div class="row custom_filter_input arrow_box" *ngIf="selected">
                    <div class="close_cont">
                        <div class="close_content" (click)="closeFilterInput()">X</div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="custom_input">
                            <label>Field</label>
                            <select class="" formControlName="columnName" placeholder="Serach Owner" name="columnFilter">
                                <option disabled>--Select column to filter--</option>
                                <option *ngFor="let col of sortedCols" value="{{col.Name}}">{{col.Value}}</option>
                            </select>
                        </div>
                        <ng-container class="">
                            <div class="custom_input">
                                <label>Operator</label>
                                <select class="" formControlName="sqlOperatorValue" name="columnOperator" placeholder="Select operator">
                                    <option disabled>--Select operator--</option>
                                    <option *ngFor="let option of sqlOperators" [value]="option">{{option}}</option>
                                </select>
                            </div>
                            <div class="custom_input">
                                <label>Value</label>
                                <input class="" formControlName="valueIndicator" name="columnValue" placeholder="Search for the value">
                            </div>
                        </ng-container>
                        <button class="buttonNew setfilter" type="button" (click)="addFilter(filterForm)">{{edit ?
                            "Done" : "Set Filter"}}</button>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
</div>