<div class="">
  <form [formGroup]="casePropertyForm" novalidate (ngSubmit)="onSubmit(casePropertyForm)">
    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Type</label>
      <select class="form-control" formControlName="Type">
        <option *ngFor = "let type of CaseTypeModel" value={{type.Value}}>{{type.Text}}</option>
      </select>
    </div>

    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Priority</label>
      <select class="form-control" formControlName="Priority">
        <option *ngFor="let casePriority of CasePriorityModel" value={{casePriority.Value}}>{{casePriority.Text}}</option>
      </select>
    </div>

    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">
        <span class="text-danger">*</span>Status</label>
      <select class="form-control" formControlName="Status">
        <option *ngFor="let status of CaseStatusModel" value={{status.Value}}>{{status.Text}}</option>
      </select>
    </div>

    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="name">Assigned To</label>
      <select formControlName="OwnerId" class="form-control">
        <option *ngFor="let employee of autoCompleteEmployee" value={{employee.Value}}>{{employee.Text}}</option>
      </select>
    </div>

    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <label for="text">Remarks</label>
      <textarea class="form-control" formControlName="Description"></textarea>
    </div>

    <div class="footer" style="margin-left: 15px;">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="resetFrom()">Cancel</button>
      <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!casePropertyForm">Save</button>
    </div>
  </form>
</div>