<div >
  <div class="">
    <div class="" style="margin-bottom: 10px;">
      <span class="menu_icon CRMQuotes fa fa-tags">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Quotes ({{quoteListData.length}})</p>
      </h3>

      <div class="btn-group pull-right" dropdown style="margin-top: 10px;">
        <button type="button" dropdownToggle class="btn btn-default dropdown-toggle" style="margin-top:-9px">
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li role="menuitem">
            <a class="dropdown-item" href="javascript:void(0)" (click)="open()">New Quote</a>
          </li>
        </ul>
      </div>
    </div>
  </div>


  <!-- Delete Quote -->
  <ng-template #deleteContent let-c="close" let-d="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-header">
      <h4 class="modal-title text-center">Delete Quote</h4>
    </div>
    <div class="modal-body">
      <p class="modalparagraph">Are you sure you want to delete this quote?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="deleteQuote()">Delete</button>
    </div>
  </ng-template>


  <div class="related_details">
    <div class="row">
      <div class="col-md-12 col-xs-12 mb-10" *ngFor="let quoteList of quoteListData">

        <div class="btn-group pull-right" dropdown style="margin-right:-10px;">
          <button type="button" dropdownToggle class="btn btn-default dropdown-toggle">
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li role="menuitem">
              <a class="dropdown-item" href="javascript:void(0)" (click)="
              editOpen({masterId:quoteList.QuoteID})">Edit</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="javascript:void(0)" (click)="onDelete(deleteContent,quoteList.QuoteID , quoteList.QuoteName)">Delete</a>
            </li>
          </ul>
        </div>
        <a class="dottedLine" (click)="gotoDetailPage(quoteList.QuoteID)" href="javascript:void(0)">{{quoteList.QuoteNo}}</a>
        <table class="custom_table">
          <tr>
            <th class="custom_name">Quote Name</th>
            <th class="custom_colon">:</th>
            <td class="custom_detail_name" title="{{quoteList.QuoteName}}">{{quoteList.QuoteName}}</td>
          </tr>

          <tr>
            <th class="custom_name">Expiry Date</th>
            <th class="custom_colon">:</th>
            <td class="custom_detail_name">{{quoteList.ExpiryDate | date:'dd-MM-yyyy'}}</td>
          </tr>

          <tr>
            <th class="custom_name">Account Name</th>
            <th class="custom_colon">:</th>
            <td class="custom_detail_name" title={{quoteList.AccountName}}>{{quoteList.AccountName}}</td>
          </tr>

        </table>
        <!-- <a class="dottedLine" (click)="gotoDetailPage(quoteList.QuoteID)" href="javascript:void(0)">{{quoteList.QuoteNo}}</a>
        <div>Quote Name:
          <p class="caseheadLine" title="{{quoteList.QuoteName}}">{{quoteList.QuoteName}}</p>
        </div>
        <div>Expiration Date:
          <p title="{{quoteList.ExpiryDate}}">{{quoteList.ExpiryDate | date:'dd-MM-yyyy'}}</p>
        </div>
        <div>Account Name:
          <p class="caseheadLine">{{quoteList.AccountName}}</p>
        </div> -->
      </div>
    </div>
  </div>
</div>