<div class="bg_white_margin_top">
    <form [formGroup]="socialForm" novalidate (ngSubmit)="onSubmit(socialForm)">
      <ng-container *ngIf="socialType=='sms'">
        <p class="headingclass">SMS Configuration</p>
        <div class="form-group mt-4">
            <label class="control-label col-sm-2" for="text">Header</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" placeholder="Header" formControlName="SMSHeader">
            </div>
          </div>
        <div class="form-group">
            <label class="control-label col-sm-2" for="text">Authentication Key</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" formControlName="SMSAPIKey">
            </div>
        </div>
      </ng-container>

      <ng-container *ngIf="socialType=='whatsapp'">
        <p class="headingclass">WhatsApp Configuration</p>
        <div class="form-group mt-4">
            <label class="control-label col-sm-2" for="text">API Key</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" placeholder="" formControlName="WAAPIKey">
            </div>
          </div>
        <div class="form-group">
            <label class="control-label col-sm-2" for="text">Account ID</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" formControlName="WAAccountID">
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-2" for="text">Mobile Number</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" formControlName="WAPhoneNoID">
            </div>
        </div>
      </ng-container>
      
  
      <div class="form-group">
        <div class="col-sm-offset-2 col-sm-4">
          <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!socialForm">Save</button>
        </div>
      </div>
    </form>
  </div>