import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  UntypedFormBuilder
} from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../../../services/product/product.service';
import { GlobalService } from '../../../services/global-Service/global.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.css']
})
export class NewProductComponent implements OnInit {
  @Output() getData = new EventEmitter();
  subscription: Subscription;

  productFormData: any;
  productForm: UntypedFormGroup;
  EditNew: any;
  showSpinner: Boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private productService: ProductService,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.productForm = this.fb.group({
      ProductId: null,
      Name: new UntypedFormControl('', Validators.required),
      IsActive: false,
      Code: '',
      Description: '',
      Price:'',
      SubscriptionTerm:'',
      SubscriptionType:''
    });
    this.getEditData();
    this.getEditNewName();
  }
  getEditNewName() {
    if (this.productForm.value.ProductId === null) {
      this.EditNew = 'New';
    } else {
      this.EditNew = 'Edit';
    }
  }

  getEditData() {
    if (this.productFormData !== undefined) {
      this.productForm.patchValue(this.productFormData);
    }
  }

  onSubmit(form) {
    const productName = form.Name;
    let responseMessage = [];
    if (form.ProductId == null) {
      this.productService.addProduct(form).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Product', name: productName });
        }
        this.showSpinner = true;
        this.getData.emit();
      });
    } else {
      this.productService.updateProduct(form).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Product', name: productName });

        }
        this.showSpinner = true;
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.resetForm();
    this.globalService.disposeToastr();
  }

  resetForm() {
    this.productForm.reset();
  }
}
