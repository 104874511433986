
import { RouterModule } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ContextMenuModule } from 'ngx-contextmenu';
// import { FileUploadModule } from 'ng2-file-upload';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DatePipe } from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { DndModule } from 'ngx-drag-drop';

// import { Ng2CloudinaryModule } from 'ng2-cloudinary';
import { NgxEditorModule } from 'ngx-editor';

// Services
import { ApiService } from './crm/services/ApiService/api.service';
import { LeadService } from './crm/services/lead/lead.service';
import { CampaignService } from './crm/services/campaigns/campaign.service';
import { CalendarService } from './crm/services/calendar/calendar.service';
import { CaseService } from './crm/services/case/case.service';
import { ContactService } from './crm/services/contact/contact.service';
import { ContractService } from './crm/services/contract/contract.service';
import { DashboardService } from './crm/services/dashboard/dashboard.service';
import { OpportunityService } from './crm/services/opportunity/opportunity.service';
import { VariableService } from './crm/services/VariableService/variable.service';
import { ProductService } from './crm/services/product/product.service';
import { TaskService } from './crm/services/task/task.service';
import { CommonService } from './crm/services/common/common/common.service';
import { LeadsComponent } from './crm/components/lead-page/leads/leads.component';
import { AccountsComponent } from './crm/components/accounts-page/accounts/accounts.component';
import { CampaignsComponent } from './crm/components/campaigns-page/campaigns/campaigns.component';
import { ContactsComponent } from './crm/components/contact-page/contacts/contacts.component';
import { EmailsComponent } from './crm/components/emails-page/emails/emails.component';
import { ReportsComponent } from './crm/components/report-page/reports/reports.component';
import { ReportsDetailComponent } from './crm/components/report-page/report-detail/report-detail.component';
import { ReportsEditComponent } from './crm/components/report-page/reports-edit/reports-edit.component';
import { ReportsListComponent } from './crm/components/report-page/reports-list/reports-list.component';
import { CalendarComponent } from './crm/components/calendar-page/calendar/calendar.component';
import { TasksComponent } from './crm/components/tasks-page/tasks/tasks.component';
import { OpportunitiesComponent } from './crm/components/opportunities-page/opportunities/opportunities.component';
import { HomeComponent } from './crm/components/home-page/home/home.component';
import { CasesComponent } from './crm/components/case-page/cases/cases.component';
import { ContractsComponent } from './crm/components/contracts-page/contracts/contracts.component';
import { ProductsComponent } from './crm/components/products-page/products/products.component';
import { PageNotFoundComponent } from '../common/pagenotfound/pagenotfound.component';
import { LogcallComponent } from './crm/shared/components/activity-page/logcall/logcall.component';
import { NewtaskComponent } from './crm/shared/components/activity-page/newtask/newtask.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { AccountService } from './crm/services/accounts/account.service';
import { LoginComponent } from '../login/login.component';
import { SignupComponent } from '../signup/signup.component';
import { AuthService } from '../auth.service/auth.service';
import { AuthGuardService } from '../auth.service/auth-guard.service';
import { AccountComponent } from './crm/components/accounts-page/account/account.component';
import { CampaignComponent } from './crm/components/campaigns-page/campaign/campaign.component';
import { CaseComponent } from './crm/components/case-page/case/case.component';
import { ContactComponent } from './crm/components/contact-page/contact/contact.component';
import { ContractComponent } from './crm/components/contracts-page/contract/contract.component';
import { LeadComponent } from './crm/components/lead-page/lead/lead.component';
import { OpportunityComponent } from './crm/components/opportunities-page/opportunity/opportunity.component';
import { ProductComponent } from './crm/components/products-page/product/product.component';
import { TaskComponent } from './crm/components/tasks-page/task/task.component';
import { NewEventComponent } from './crm/shared/components/activity-page/new-event/new-event.component';
import { NewEmailComponent } from './crm/shared/components/activity-page/new-email/new-email.component';
import { NewsComponent } from './crm/shared/components/news/news.component';
import { MilestonesComponent } from './crm/components/milestone-page/milestones/milestones.component';
import { ProjectsComponent } from './crm/components/project-page/projects/projects.component';
import { ProjectTasksComponent } from './crm/components/project-task-page/project-tasks/project-tasks.component';
import { LogCallService } from './crm/services/common/activity-page/LogCall/log-call.service';
import { NewAccountComponent } from './crm/components/accounts-page/new-account/new-account.component';
import { ProjectComponent } from './crm/components/project-page/project/project.component';
import { NewProjectComponent } from './crm/components/project-page/new-project/new-project.component';
import { ProjectService } from './crm/services/project/project.service';
import { MilestoneService } from './crm/services/milestone/milestone.service';
import { AutocompleteService } from './crm/services/autocomplete/autocomplete.service';
import { NewEventService } from './crm/services/common/activity-page/newEvent/new-event.service';
import { ChatterService } from './crm/services/common/activity-page/chatter/chatter.service';
import { ChatterComponent } from './crm/shared/components/activity-page/chatter-page/chatter/chatter.component';
import { NewContactComponent } from './crm/components/contact-page/new-contact/new-contact.component';
import { NewCampaignComponent } from './crm/components/campaigns-page/new-campaign/new-campaign.component';
import { NewCaseComponent } from './crm/components/case-page/new-case/new-case.component';
import { NewContractComponent } from './crm/components/contracts-page/new-contract/new-contract.component';
import { NewOpportunityComponent } from './crm/components/opportunities-page/new-opportunity/new-opportunity.component';
import { ContactRelatedComponent } from './crm/shared/components/related-page/contact-related/contact-related.component';
import { CasesRelatedComponent } from './crm/shared/components/related-page/cases-related/cases-related.component';
import { OpportunitiesRelatedComponent } from './crm/shared/components/related-page/opportunities-related/opportunities-related.component';
import { ActivityComponent } from './crm/shared/components/activity-page/activity/activity.component';
import { NewLeadComponent } from './crm/components/lead-page/new-lead/new-lead.component';
import { MilestoneComponent } from './crm/components/milestone-page/milestone/milestone.component';
import { NewMilestoneComponent } from './crm/components/milestone-page/new-milestone/new-milestone.component';
import { ProjectTaskComponent } from './crm/components/project-task-page/project-task/project-task.component';
import { NewProjectTaskComponent } from './crm/components/project-task-page/new-project-task/new-project-task.component';
import { RfqPageComponent } from './crm/shared/components/rfq-page/rfq-page.component';
import { LeadStatusComponent } from './crm/components/lead-page/lead-status/lead-status.component';
import { EmailService } from './crm/services/common/activity-page/email/email.service';
import { NewProductComponent } from './crm/components/products-page/new-product/new-product.component';
import { RfqService } from './crm/services/common/activity-page/rfq/rfq.service';
import { MasterLookupService } from './crm/services/common/master-lookup/master-lookup.service';
import { ContactRelatedDetailComponent } from './crm/components/contact-page/contact-related-detail/contact-related-detail.component';
// tslint:disable-next-line:max-line-length
import { AccountRelatedDetailComponent } from './crm/components/accounts-page/account-related-detail/account-related-detail.component';
import { CampaignRelatedDetailComponent } from './crm/components/campaigns-page/campaign-related-detail/campaign-related-detail.component';
// tslint:disable-next-line:max-line-length
import { MilestoneRelatedComponent } from './crm/components/project-page/milestone-related/milestone-related.component';
// tslint:disable-next-line:max-line-length
import { MilestoneRelatedDetailComponent } from './crm/components/milestone-page/milestone-related-detail/milestone-related-detail.component';
import { ProjectRelatedDetailComponent } from './crm/components/project-page/project-related-detail/project-related-detail.component';
import { ProjectTaskRelatedComponent } from './crm/shared/components/related-page/project-task-related/project-task-related.component';

import { OpportunityStageComponent } from './crm/components/opportunities-page/opportunity-stage/opportunity-stage.component';
import { SetupComponent } from './crm/shared/components/setup-page/setup/setup.component';
import { OtherEmailComponent } from './crm/components/emails-page/other-email/other-email/other-email.component';
import { OtherEmailService } from './crm/services/other-Email/other-email.service';
import { EmployeeService } from './crm/services/Employee/employee.service';
import { TopnavbarService } from './crm/services/top-nav-bar/topnavbar.service';
import { ConvertLeadComponent } from './crm/components/lead-page/lead-conversion/convert-lead/convert-lead.component';
import { CampaignRelatedComponent } from './crm/shared/components/related-page/campaign-related/campaign-related.component';
import { LeadRelatedDetailComponent } from './crm/components/lead-page/lead-related-detail/lead-related-detail.component';
// tslint:disable-next-line:max-line-length
import { OpportunityConversionComponent } from './crm/components/opportunities-page/opportunity-conversion/opportunity-conversion.component';
import { SendEmailDirective } from './crm/directives/email/email.directive';
import { EmailComponent } from '../common/email/email.component';
import { SpinnerLoaderComponent } from '../common/global-loader/spinner-loader/spinner-loader.component';
import { MilestoneConfigComponent } from './crm/components/configuration-page/milestone-config/milestone-config.component';
import { StatusConfigPageComponent } from './crm/components/configuration-page/status-config-page/status-config-page.component';
import { ProjectTaskService } from './crm/services/projectTask/ProjectTask-Service/project-task.service';
import { ResourceDetailService } from './crm/services/projectTask/ResourceDetail/resource-detail.service';
import { TerritoryManagementComponent } from './crm/components/configuration-page/territory-management/territory-management.component';
import { TaskTemplateComponent } from './crm/components/configuration-page/task-template/task-template.component';
import { KickOffDetailComponent } from './crm/components/project-page/kick-off-detail/kick-off-detail.component';
import { KickoffService } from './crm/services/KickOff/kickoff.service';
import { QuotesComponent } from './crm/components/quotation-page/quotes/quotes.component';
import { NewQuoteComponent } from './crm/components/quotation-page/new-quote/new-quote.component';
import { QuoteComponent } from './crm/components/quotation-page/quote/quote.component';
import { QuoteRelatedComponent } from './crm/components/quotation-page/quote-related/quote-related.component';
import { QuoteService } from './crm/services/quote/quote.service';
import { QuoteStatusComponent } from './crm/components/quotation-page/quote-status/quote-status.component';
import { QuoteProductService } from './crm/services/quoteProduct/quote-product.service';
import { TableFilterComponent } from '../common/tablefilter/tablefilter.component';
import { TableHeaderComponent } from '../common/tableheader/tableheader.component';
import { EmailTemplateComponent } from './crm/shared/components/activity-page/email-template/email-template.component';
import { EmployeeInfoService } from './crm/services/employeeInfo/employee-info.service';
import { ColumnFilterComponent } from '../common/columnfilter/columnfilter.component';
import { CasePropertiesComponent } from './crm/components/case-page/case-properties/case-properties.component';
import { CaseContactDetailComponent } from './crm/components/case-page/case-contact-detail/case-contact-detail.component';
import { AccountRelatedComponent } from './crm/shared/components/related-page/account-related/account-related.component';
import { EventsComponent } from './crm/components/event-page/events/events.component';
import { EventComponent } from './crm/components/event-page/event/event.component';
import { EventNewComponent } from './crm/components/event-page/event-new/event-new.component';
import { TaskResourceComponent } from './crm/components/project-task-page/task-resource/task-resource.component';
import { ResourcesComponent } from './crm/components/resource-page/resources/resources.component';
import { ResourceService } from './crm/services/resource/resource.service';
import { ResourceComponent } from './crm/components/resource-page/resource/resource.component';
import { NewResourceComponent } from './crm/components/resource-page/new-resource/new-resource.component';
// common
import { CustomButtonComponent } from '../common/Button/button.component';
import { CustomTableComponent } from '../common/table/table.component';
import { PageFilterComponent } from '../common/pagefilter/pagefilter.component';

import { ClientsComponent } from './crm/components/client-page/clients/clients.component';
import { ClientService } from './crm/services/client/client.service';
import { ClientComponent } from './crm/components/client-page/client/client.component';
import { NewClientComponent } from './crm/components/client-page/new-client/new-client.component';
import { TemplateService } from './crm/services/template/template.service';
import { TaskNewComponent } from './crm/components/tasks-page/task-new/task-new.component';
import { RequestforquotesComponent } from './crm/components/rfq-page/requestforquotes/requestforquotes.component';
import { CalendarHeaderComponent } from './crm/components/calendar-page/calendar-header/calendar-header.component';
import { DatetimeheaderComponent } from './crm/components/calendar-page/datetimeheader/datetimeheader.component';
import { CollectionTargetComponent } from './crm/components/target-page/collection-target/collection-target.component';
import { TargetService } from './crm/services/target/target.service';
import { RoleComponent } from './crm/components/users-control-page/role/role.component';
import { RolePermissionComponent } from './crm/components/users-control-page/role-permission/role-permission.component';
import { ProfileComponent } from './crm/components/users-control-page/profile/profile.component';
import { RolePermissionService } from './crm/services/rolePermission/role-permission.service';
import { UserProfileService } from './crm/services/userProfile/user-profile.service';
import { Broadcaster } from './crm/services/Broadcaster/broadcaster.service';
import { RoleService } from './crm/services/role/role.service';
import { NavigationItemComponent } from './crm/components/navigation-item/navigation-item/navigation-item.component';
import { NavItemService } from './crm/services/navItem/nav-item.service';
import { RequestforQuoteComponent } from './crm/components/rfq-page/requestfor-quote/requestfor-quote.component';
import { UserReportService } from './crm/services/report/userReport/user-report.service';
import { SalesOrderService } from './crm/services/salesorder/sales-order.service';
import { DocumentComponent } from './crm/components/document-page/document/document.component';
import { DocumentService } from './crm/services/documentService/document.service';
import { ProjectOrderComponent } from './crm/components/project-page/project-order/project-order.component';
import { ProjectOrderService } from './crm/services/projectOrder/project-order.service';
import { SalesOrderComponent } from './crm/components/sales-order-page/sales-order/sales-order.component';
import { InvoiceComponent } from './crm/components/invoice-page/invoice/invoice.component';
import { QuoteServiceComponent } from './crm/components/quotation-page/quote-service/quote-service.component';
import { TermsTemplateComponent } from './crm/components/quotation-page/terms-template/terms-template.component';
import { InvoiceService } from './crm/services/invoice/invoice.service';
import { ClientRelatedComponent } from './crm/components/client-page/client-related/client-related.component';
import { ProjectRelatedComponent } from './crm/shared/components/related-page/project-related/project-related.component';
import { QuotationRelatedComponent } from './crm/shared/components/related-page/quotation-related/quotation-related.component';
import { OrderRelatedComponent } from './crm/shared/components/related-page/order-related/order-related.component';
import { InvoiceRelatedComponent } from './crm/shared/components/related-page/invoice-related/invoice-related.component';
import { CollectionRelatedComponent } from './crm/shared/components/related-page/collection-related/collection-related.component';
import { ClientCustomerService } from './crm/services/client-customer/client-customer.service';
import { RelatedEmailService } from './crm/services/relatedEmail/related-email.service';
import { DashboardConfigComponent } from './crm/components/home-page/dashboard-config/dashboard-config.component';
import { NewQuotationComponent } from './crm/components/quotation-page/new-quotation/new-quotation.component';
import { QuoteLineItemComponent } from './crm/components/quotation-page/quote-line-item/quote-line-item.component';
import { AutocompleteComponent } from './../common/autocomplete/autocomplete.component';
import { DraggableModule } from './crm/components/draggable/draggable.module';

// User tracking imports
import { UserTrackingComponent } from './crm/components/user-tracking/user-tracking.component';
import { UserTrackingService } from './crm/services/user-tracking/user-tracking.service';
import { QuoteConversionComponent } from './crm/components/quotation-page/quote-conversion/quote-conversion.component';
import { NewInvoiceComponent } from './crm/components/invoice-page/new-invoice/new-invoice.component';
import { AccountDetailComponent } from './crm/components/accounts-page/account-detail/account-detail.component';
import { CampaignDetailComponent } from './crm/components/campaigns-page/campaign-detail/campaign-detail.component';
import { CaseDetailComponent } from './crm/components/case-page/case-detail/case-detail.component';
import { ClientDetailComponent } from './crm/components/client-page/client-detail/client-detail.component';
import { ContactDetailComponent } from './crm/components/contact-page/contact-detail/contact-detail.component';
import { ContractDetailComponent } from './crm/components/contracts-page/contract-detail/contract-detail.component';
import { LeadDetailComponent } from './crm/components/lead-page/lead-detail/lead-detail.component';
import { MilestoneDetailComponent } from './crm/components/milestone-page/milestone-detail/milestone-detail.component';
import { OpportunityDetailComponent } from './crm/components/opportunities-page/opportunity-detail/opportunity-detail.component';
import { ProjectDetailComponent } from './crm/components/project-page/project-detail/project-detail.component';
import { ProjectTaskDetailComponent } from './crm/components/project-task-page/project-task-detail/project-task-detail.component';
import { ResourceDetailComponent } from './crm/components/resource-page/resource-detail/resource-detail.component';
import { NewPosComponent } from './crm/components/pos-page/new-pos/new-pos.component';
import { PosAddRowComponent } from './crm/components/pos-page/pos-add-row/pos-add-row.component';
import { PosDashboardComponent } from './crm/components/pos-page/pos-dashboard/pos-dashboard.component';
import { DeleteService } from './crm/services/deleteService/delete.service';
import { EventDetailComponent } from './crm/components/event-page/event-detail/event-detail.component';
import { PosGroupListComponent } from './crm/components/pos-page/pos-group-list/pos-group-list.component';
import { TaskDetailComponent } from './crm/components/tasks-page/task-detail/task-detail.component';
import { TaskFollowUpComponent } from './crm/components/tasks-page/task-follow-up/task-follow-up.component';
import { InvoiceOrderComponent } from './crm/components/project-page/invoice-order/invoice-order.component';
import { PendingOrderService } from './crm/services/pending-order/pending-order.service';
import { CustomerInvoiceComponent } from './crm/components/project-page/customer-invoice/customer-invoice.component';
import { ProjectInvoiceComponent } from './crm/components/project-page/project-invoice/project-invoice.component';
import { TaskOfMilestoneComponent } from './crm/shared/components/task-milestone-page/task-of-milestone/task-of-milestone.component';
import { ProjectTaskTemplateService } from './crm/services/project-task-template/project-task-template.service';
import { PosService } from './crm/services/point-of-sale/pos.service';
import { MailingAddressComponent } from './crm/components/mailing-address-page/mailing-address/mailing-address.component';
import { MultipleAddressService } from './crm/services/multiple-Address/multiple-address.service';
import { HtmlTableComponent } from '../common/HtmlTable/htmlTable.component';
import { HtmlDataTableComponent } from '../common/HtmlDataTable/htmlDataTable.component';
import { DeleteModalComponent } from './../common/delete-modal/delete-modal.component';
import { ProjectSalesOrderComponent } from './crm/components/project-page/project-sales-order/project-sales-order.component';
import { ConfigurationService } from './crm/services/configuration-Service/configuration.service';
import { ScriptService } from './crm/services/scriptFile/script.service';
import { TimesheetComponent } from './crm/components/timesheet-page/timesheet/timesheet.component';
import { TimesheetTableComponent } from './crm/components/timesheet-page/timesheet-table/timesheet-table.component';
import { NewCallLogComponent } from './crm/components/call-log-page/new-call-log/new-call-log.component';
import { GlobalService } from './crm/services/global-Service/global.service';
import { ActivitiesPageComponent } from './crm/components/activities-page/activities-page/activities-page.component';
import { ChangePasswordComponent } from './crm/components/users-control-page/change-password/change-password.component';
import { ChangePasswordService } from './crm/services/change-password/change-password.service';
import { ProductDetailComponent } from './crm/components/products-page/product-detail/product-detail.component';
import { BulkEmailComponent } from './crm/shared/components/bulk-email/bulk-email.component';
import { MessageService } from './crm/services/messageService/message.service';
import { MachineService } from './crm/services/machine/machine.service';
import { LocationService } from './crm/services/location/location.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormBuilderComponent } from './crm/components/form-builder/form-builder.component';
import {ProgressBarAngularModule} from "progress-bar-angular";
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { ProfileConnectComponent } from './crm/components/profile-connect/profile-connect.component';
import { ImportDataComponent } from './crm/shared/components/import-data/import-data.component';
import { DndDirective } from './crm/directives/dnd/dnd.directive';
import { CampainSchedulesComponent } from './crm/components/campaigns-page/campain-schedules/campain-schedules.component';
import { EmailTemplateModalComponent } from './crm/shared/components/activity-page/email-template-modal/email-template-modal.component';
import { ContractRelatedComponentComponent } from './crm/components/contracts-page/contract-related-component/contract-related-component.component';
import { TinyContractsRightComponent } from './crm/components/contracts-page/tiny-contracts-right/tiny-contracts-right.component'; 
import { MachinesComponent } from './crm/components/machine/machines/machines.component';
import { NewMachineComponent } from './crm/components/machine/new-machine/new-machine.component';
import { MachineDetailsComponent } from './crm/components/machine/machine-details/machine-details.component';
import { PlanogramComponent } from './crm/components/machine/planogram/planogram.component';
import { ItemsComponent } from './crm/shared/components/items/items.component';
import { LocationsComponent } from './crm/components/location/locations/locations.component';
import { NewLocationComponent } from './crm/components/location/new-location/new-location.component';
import { LocationComponent } from './crm/components/location/location/location.component';
import { MachineLocationComponent } from './crm/components/location/machine-location/machine-location.component';
import { StockTakeComponent } from './crm/components/stock-take/stock-take.component';
import { SubscriptionsComponent } from './crm/shared/components/related-page/subscriptions/subscriptions.component';
import { SocialConfigComponent } from './crm/shared/components/social-config/social-config.component';
import { CampainModalComponent } from './crm/shared/components/campain-modal/campain-modal.component';
import { POSComponent } from './crm/components/pos-page/pos/pos.component';
import { PosPaymentModelComponent } from './crm/components/pos-page/pos-payment-model/pos-payment-model.component';
// Maps imports
// import { AgmCoreModule } from "@agm/core";

@NgModule({
    declarations: [
        OpportunitiesComponent,
        CampaignsComponent,
        EmailsComponent,
        SendEmailDirective,
        LeadsComponent,
        TasksComponent,
        AccountsComponent,
        ContactsComponent,
        ReportsComponent,
        ReportsListComponent,
        ReportsDetailComponent,
        ReportsEditComponent,
        CalendarComponent,
        HomeComponent,
        CasesComponent,
        ContractsComponent,
        ProductsComponent,
        PageNotFoundComponent,
        LogcallComponent,
        NewtaskComponent,
        LoginComponent,
        SignupComponent,
        AccountComponent,
        CampaignComponent,
        CaseComponent,
        ContactComponent,
        ContractComponent,
        LeadComponent,
        OpportunityComponent,
        ProductComponent,
        TaskComponent,
        NewEventComponent,
        NewEmailComponent,
        NewsComponent,
        MilestonesComponent,
        ProjectsComponent,
        ProjectTasksComponent,
        NewAccountComponent,
        ProjectComponent,
        NewProjectComponent,
        ChatterComponent,
        NewContactComponent,
        NewCampaignComponent,
        NewCaseComponent,
        NewContractComponent,
        NewOpportunityComponent,
        ContactRelatedComponent,
        CasesRelatedComponent,
        OpportunitiesRelatedComponent,
        ActivityComponent,
        NewLeadComponent,
        MilestoneComponent,
        NewMilestoneComponent,
        ProjectTaskComponent,
        NewProjectTaskComponent,
        RfqPageComponent,
        LeadStatusComponent,
        NewProductComponent,
        ContactRelatedDetailComponent,
        CampaignRelatedDetailComponent,
        AccountRelatedDetailComponent,
        MilestoneRelatedComponent,
        MilestoneRelatedDetailComponent,
        ProjectRelatedDetailComponent,
        ProjectTaskRelatedComponent,
        StatusConfigPageComponent,
        OpportunityStageComponent,
        SetupComponent,
        OtherEmailComponent,
        SpinnerLoaderComponent,
        ConvertLeadComponent,
        CampaignRelatedComponent,
        LeadRelatedDetailComponent,
        OpportunityConversionComponent,
        EmailComponent,
        MilestoneConfigComponent,
        TerritoryManagementComponent,
        TaskTemplateComponent,
        KickOffDetailComponent,
        QuotesComponent,
        NewQuoteComponent,
        QuoteComponent,
        QuoteRelatedComponent,
        QuoteStatusComponent,
        TableFilterComponent,
        TableHeaderComponent,
        EmailTemplateComponent,
        ColumnFilterComponent,
        CasePropertiesComponent,
        CaseContactDetailComponent,
        AccountRelatedComponent,
        EventsComponent,
        EventComponent,
        EventNewComponent,
        TaskResourceComponent,
        ResourcesComponent,
        ResourceComponent,
        NewResourceComponent,
        CustomButtonComponent,
        CustomTableComponent,
        PageFilterComponent,
        DeleteModalComponent,
        ClientsComponent,
        ClientComponent,
        NewClientComponent,
        TaskNewComponent,
        RequestforquotesComponent,
        CalendarHeaderComponent,
        DatetimeheaderComponent,
        CollectionTargetComponent,
        RoleComponent,
        RolePermissionComponent,
        ProfileComponent,
        NavigationItemComponent,
        RequestforQuoteComponent,
        DocumentComponent,
        ProjectOrderComponent,
        SalesOrderComponent,
        InvoiceComponent,
        QuoteServiceComponent,
        TermsTemplateComponent,
        ClientRelatedComponent,
        ProjectRelatedComponent,
        QuotationRelatedComponent,
        OrderRelatedComponent,
        InvoiceRelatedComponent,
        CollectionRelatedComponent,
        DashboardConfigComponent,
        NewQuotationComponent,
        QuoteLineItemComponent,
        AutocompleteComponent,
        UserTrackingComponent,
        QuoteConversionComponent,
        NewInvoiceComponent,
        AccountDetailComponent,
        CampaignDetailComponent,
        CaseDetailComponent,
        ClientDetailComponent,
        ContactDetailComponent,
        ContractDetailComponent,
        LeadDetailComponent,
        MilestoneDetailComponent,
        OpportunityDetailComponent,
        ProjectDetailComponent,
        ProjectTaskDetailComponent,
        ResourceDetailComponent,
        NewPosComponent,
        PosAddRowComponent,
        PosDashboardComponent,
        EventDetailComponent,
        PosGroupListComponent,
        TaskDetailComponent,
        TaskFollowUpComponent,
        InvoiceOrderComponent,
        CustomerInvoiceComponent,
        ProjectInvoiceComponent,
        TaskOfMilestoneComponent,
        MailingAddressComponent,
        HtmlTableComponent,
        HtmlDataTableComponent,
        ProjectSalesOrderComponent,
        TimesheetComponent,
        TimesheetTableComponent,
        NewCallLogComponent,
        ActivitiesPageComponent,
        ChangePasswordComponent,
        ProductDetailComponent,
        BulkEmailComponent,
        FormBuilderComponent,
        ProfileConnectComponent,
        ImportDataComponent,
        DndDirective,
        CampainSchedulesComponent,
        EmailTemplateModalComponent,
        ContractRelatedComponentComponent,
        TinyContractsRightComponent,
        MachinesComponent,
        NewMachineComponent,
        MachineDetailsComponent,
        PlanogramComponent,
        ItemsComponent,
        LocationsComponent,
        NewLocationComponent,
        LocationComponent,
        MachineLocationComponent,
        StockTakeComponent,
        SubscriptionsComponent,
        SocialConfigComponent,
        CampainModalComponent,
        POSComponent,
        PosPaymentModelComponent,
        
    ],
    imports: [
        NgbModule,
        // NgbActiveModal,
        FormsModule,
        PaginationModule.forRoot(),
        BsDropdownModule.forRoot(),
        Ng2GoogleChartsModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        RouterModule,
        BsDatepickerModule.forRoot(),
        AlertModule.forRoot(),
        TooltipModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 1500,
            toastClass: 'toast',
            positionClass: 'toast-top-center',
        }),
        ToastContainerModule,
        HttpClientModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        ContextMenuModule.forRoot({
            useBootstrap4: true
        }),
        PopoverModule.forRoot(),
        TimepickerModule.forRoot(),
        NgxEditorModule,
        // FileUploadModule,
        // Ng2CloudinaryModule,
        PDFExportModule,
        MatInputModule,
        MatAutocompleteModule,
        MatIconModule,
        MatFormFieldModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        DraggableModule,
        NgSelectModule,
        ScrollingModule,
        DndModule,
        ProgressBarAngularModule,
        PowerBIEmbedModule
        
        // StoreModule.forRoot({ showHide: showHideReducer })
        // AgmCoreModule.forRoot({
        //   apiKey: "AIzaSyDPBMey9xzuihfiFdLVaJFrM5Qaixup7ss"
        // })
        // GoogleApiModule.forRoot({
        //   provide: NG_GAPI_CONFIG,
        //   useValue: gapiClientConfig
        // })
    ],
    providers: [
        DatePipe,
        ApiService,
        AccountService,
        LeadService,
        CampaignService,
        CalendarService,
        CampaignService,
        CaseService,
        ContactService,
        ContractService,
        DashboardService,
        EmailService,
        OpportunityService,
        VariableService,
        ProductService,
        TaskService,
        AuthService,
        AuthGuardService,
        LogCallService,
        ProjectService,
        MilestoneService,
        ProjectTaskService,
        AutocompleteService,
        NewEventService,
        EmailService,
        ChatterService,
        RfqService,
        OtherEmailService,
        EmployeeService,
        TopnavbarService,
        MasterLookupService,
        ResourceDetailService,
        KickoffService,
        QuoteService,
        QuoteProductService,
        EmployeeInfoService,
        ResourceService,
        ClientService,
        TemplateService,
        CommonService,
        NgbActiveModal,
        TargetService,
        RoleService,
        RolePermissionService,
        UserProfileService,
        Broadcaster,
        SalesOrderService,
        NavItemService,
        UserReportService,
        DocumentService,
        ProjectOrderService,
        InvoiceService,
        ClientCustomerService,
        RelatedEmailService,
        UserTrackingService,
        DeleteService,
        PendingOrderService,
        ProjectTaskTemplateService,
        PosService,
        MultipleAddressService,
        ConfigurationService,
        ScriptService,
        GlobalService,
        MessageService,
        MachineService,
        LocationService,
        ChangePasswordService
    ],
    bootstrap: [],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class ProductModule { }
