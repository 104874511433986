<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon milestone_configuration glyphicon glyphicon-export">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Milestone Configurations</p>
      </h3>
    </div>
  </div>

  <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>

  <div class="bg_white_margin_top min_top">
    <div class="table_cont">
      <table class="table table-striped table-bordered dynomic_table">
        <thead>
          <tr>
            <th class="number_small"><b>S.No.</b></th>
            <th class="name_full_width"><b>Milestone Name</b></th>
            <th class="number_long"><b>Completed</b></th>
            <th class="number_short"><b>Action</b></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let status of milestoneConfigForm.MilestoneStage; let i=index">
            <td>
              <select class="form-control" [(ngModel)]="status.SerialNo" (change)="addFieldValue(i)">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
              </select>
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="status.MilestoneName" (input)="addFieldValue(i)"
                placeholder="Milestone Name" />
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="status.PercentageCompleted" (input)="addFieldValue(i)"
                placeholder="%of Completed" (keypress)="numberOnly($event)" />
            </td>
            <td>
              <div class="btn-group" dropdown>
                <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
                <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>

    <br />

    <div>
      <button type="button" class="btn btn-default" (click)="resetForm()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="onSubmit()">Save</button>
    </div>

  </div>
</div>