import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  HostListener
} from '@angular/core';
import { Router } from '@angular/router';
import { TopnavbarService } from '../../product/crm/services/top-nav-bar/topnavbar.service';
import { convertToCSV } from '../utils';

import { Page } from '../../models/page';
import { MessageService } from '../../product/crm/services/messageService/message.service';

declare var $: any;
@Component({
  selector: 'custom-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class CustomTableComponent implements OnInit, OnChanges {
  @ViewChild('commonTable') commonTable: any;

  @Output() getData = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() setSearchStr = new EventEmitter();
  @Output() onSelect = new EventEmitter();

  @Input() columns: any[];
  @Input() rows: Array<any>;
  @Input() page: Page;
  @Input() showFilter: boolean;
  @Input() content: any;
  @Input() searchStr: String;
  @Input() deleteContent: any;
  @Input() showSearch=true;
  @Input() showEdit=true;
  @Input() showCheckbox=true;
  selectAll = false;

  selected = [];
  displayCheck: any;
  temp: any[] = [];
  selectedPage: any = {};
  scrollTimeout: any;
  supressPaging = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.rows = [...this.rows];
    $('#common-table .datatable-scroll').width('100%');
  }

  constructor(
    topnavbarService: TopnavbarService,
    public router: Router,
    private messageService: MessageService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('this',this)
    // tslint:disable-next-line:forin
    for (let propName in changes) {
      let change = changes[propName];
      if (change.isFirstChange()) {
      } else {
        // console.log(`prev: ${change.previousValue}, cur: ${change.currentValue}`);
      }
    }
  }

  ngOnInit() {
    this.temp = this.rows;
  }
  openCallLog(data) {
    this.messageService.toggle();
    this.messageService.sendMessage(data);
  }

  // handleScroll() {
  //   this.supressPaging = true;

  //   if (this.scrollTimeout) {
  //     clearTimeout(this.scrollTimeout);
  //   }

  //   this.scrollTimeout = setTimeout(() => {
  //     this.supressPaging = false;
  //   }, 100);
  // }

  filterLeadstable(e: any) {
  }

  searchTable(e: any) {
    const val = e.target.value.toLowerCase();
    this.setSearchStr.next(val);
  }

  getAllData(pageInfo?: any, filter?: any) {
    if (!this.supressPaging) {
      const reqOptions: any = {};
      this.selected = [];
      this.selectAll = false;
      if (pageInfo) {
        // reqOptions.PageNumber = pageInfo.offset + 1;
        // reqOptions.PageSize = pageInfo.limit;
        // reqOptions.TotalRecords = pageInfo.count;
        reqOptions.PageNumber = pageInfo.page;
        reqOptions.PageSize = this.page.size;
        reqOptions.TotalRecords = this.page.totalElements;
      } else {
        reqOptions.PageNumber = 1;
        reqOptions.PageSize = this.page.size;
        reqOptions.TotalRecords = null;
      }
      if (filter) {
        reqOptions.Filter = filter;
      }
      this.getData.next(reqOptions);
    }
  }

  gotoDetailPage(id) {
    const route =
      this.selectedPage.pagename.replace('CRM', '').toLowerCase() +
      'Detail/' +
      id;
    this.router.navigate([route]);
  }

  exportToCSV(data, columns) {
    const filteredData = [];
    data.forEach(r => {
      const newObj = {};
      columns.forEach(c => {
        if (c.Visible) {
          newObj[c.Value] = r[c.Name];
        }
      });
      filteredData.push(newObj);
    });
    const name = this.selectedPage.pagename + '_' + new Date().toLocaleString();
    convertToCSV(filteredData, name);
  }

  getRowHeight(row) {
    return row.height;
  }

  select(event, selected, selectAll=true ) {
    if(event && event.currentTarget && !event.currentTarget.checked){
      let selectedArr = this.selected.filter((item)=> {
        if(item.QuoteID !== selected.selected[0]['QuoteID'])
          return item
      })
      this.selected = [...selectedArr];
    }else if(selectAll){
      this.selected = this.selected.concat(selected.selected);
    }else if(!selectAll){
      this.selected = selected.selected;
    }
    if(this.rows.length == this.selected.length){
      this.selectAll = true;
    }else{
      this.selectAll = false;
    }
    this.onSelect.next(this.selected);
  }

  onActivate(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
    }
  }

  add() {
    this.selected.push(this.rows[1], this.rows[3]);
  }

  update() {
    this.selected = [this.rows[1], this.rows[3]];
  }

  remove() {
    this.selected = [];
  }

  delete(data) {
    setTimeout(() => {
      this.onDelete.next(data);
    });
  }
  updateSelectAll(rows){
    this.selected=[];
    this.rows.map((row)=>{
      row.checked=this.selectAll?true:false;
    });
    this.select('',this.selectAll?{selected:rows}:{selected:[]},false);
  }
  selectRow(event,row){
    this.select(event,{selected:[row]});
  }
}


