import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { ContactService } from './../../../services/contact/contact.service';
import { AccountService } from './../../../services/accounts/account.service';

import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-new-contact',
  templateUrl: './new-contact.component.html',
  styleUrls: ['./new-contact.component.css']
})
export class NewContactComponent implements OnInit {
  @Output() getData = new EventEmitter();
  contactFormData: any;
  contactForm: UntypedFormGroup;
  public TerritoryList: AutocompleteModel[];
  public LeadSourceModel: AutocompleteModel[];
  EditNew: any;
  employeefilteredOptions: any[];
  employeeText: any; employeeValue: any;
  clientfilteredOptions: any[];
  clientText: any; clientValue: any;
  accountfilteredOptions: any[];
  accountText: any; accountValue: any;
  contactfilteredOptions: any[];
  contactText: any; contactValue: any;
  public ListTypeData=[];
  constructor(
    private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private masterLookupService: MasterLookupService,
    private configService: ConfigurationService,
    private datePipe: DatePipe,
    private globalService: GlobalService,
    public activeModal: NgbActiveModal,
    private accountService: AccountService,
    private contactService: ContactService,
  ) { }

  ngOnInit() {
    this.contactForm = this.fb.group({
      ContactId: null,
      Salutation: '',
      FirstName: '',
      LastName: '',
      Title: '',
      Email: '',
      Phone: null,
      MobilePhone: null,
      ReportsToId: '',
      Department: '',
      Fax: null,
      AccountId: '',
      Description: '',
      OwnerId: '',
      HomePhone: '',
      Level: '',
      Languages: '',
      AssistantName: '',
      AssistantPhone: '',
      BirthDate: '',
      OtherPhone: '',
      LeadSource: '',
      FaceBookID: '',
      TwitterID: '',
      SkypeID: '',
      TerritoryID: '',
      Client: '',
      ListTypeID:'',
      BillingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      }),
      ShippingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      })
    });
    this.getEditData();
    this.getEditNewName();
    this.getAllTerritoryLookupList();
    this.getLeadSourceList();
    this.getListTypesStatus();

  }

  getListTypesStatus() {
    this.masterLookupService.getDropdownList('List Type').subscribe(data => {
      this.ListTypeData = data;
    });
  }

  getEditNewName() {
    if (this.contactForm.value.ContactId === null) {
      let userDetails = this.autocompleteService.getUserDetails();
      this.employeeText = userDetails.EmpName;
      this.employeeValue = userDetails.EmpID;
      this.contactForm.controls['OwnerId'].setValue(userDetails.EmpID);
      this.EditNew = 'New';
    } else {
      this.EditNew = 'Edit';
      this.contactForm.controls['BirthDate'].setValue(new Date(this.contactForm.value.BirthDate));

    }
  }

  getEditData() {
    if (this.contactFormData !== undefined) {
      this.contactForm.patchValue(this.contactFormData);

      this.employeeText = this.contactFormData.ContactOwnerName;
      this.employeeValue = this.contactFormData.OwnerId;

      this.clientText = this.contactFormData.ClientName;
      this.clientValue = this.contactFormData.Client;

      this.accountText = this.contactFormData.AccountName;
      this.accountValue = this.contactFormData.AccountId;

      this.contactText = this.contactFormData.ContactName;
      this.contactValue = this.contactFormData.ContactId;

    }
  }

  getLeadSourceList() {
    this.masterLookupService.getDropdownList('Lead Source').subscribe(data => {
      this.LeadSourceModel = data;
    });
  }

  getAllTerritoryLookupList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }


  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.employeefilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteEmployee(data) {
    const ownerId = data.data.option.value.Value;
    this.contactForm.controls['OwnerId'].setValue(ownerId);
  }

  getClientList(value) {
    this.autocompleteService.getAllClientList(value.data).subscribe(
      res => {
        this.clientfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteClient(data) {
    const clientId = data.data.option.value.Value;
    this.contactForm.controls['Client'].setValue(clientId);
  }


  getAccountList(value) {
    this.autocompleteService.getAllAccountList(value.data).subscribe(
      res => {
        this.accountfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteAccount(data) {
    const accountId = data.data.option.value.Value;
    this.contactForm.controls['AccountId'].setValue(accountId);
    if (this.EditNew === 'New') {
      this.accountService.getByAccountId(accountId, 'Account').subscribe(acc => {
        this.contactForm.patchValue({
          BillingAddress: (acc.BillingAddress),
          ShippingAddress: (acc.ShippingAddress),
        });
      });
    }
  }

  getContactList(value) {
    const accountid = this.contactForm.value.AccountId;
    this.autocompleteService.getAllContactAccountList(value.data, accountid).subscribe(
      res => {
        this.contactfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteContact(data) {
    const contactId = data.data.option.value.Value;
    this.contactForm.controls['ReportsToId'].setValue(contactId);
  }


  // Submit Function
  onSubmit(form: UntypedFormGroup) {
    const contactName = form.value.FirstName + ' ' + form.value.LastName;
    let responseMessage = [];
    this.contactForm.value.BirthDate = this.datePipe.transform(this.contactForm.value.BirthDate, 'dd-MM-yyyy');

    if (form.value.ContactId == null) {
      this.contactService.addContact(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Contact', name: contactName });
        }
        this.getData.emit();
      });
    } else {
      this.contactService.updateContact(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Contact', name: contactName });

        }
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.contactForm.reset();
    this.globalService.disposeToastr();
  }
}
