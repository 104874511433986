<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Employee</h4>
</div>
<form [formGroup]="resourceForm" novalidate (ngSubmit)="onSubmit(resourceForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="">

          <p for="text">
            <span class="text-danger">*</span>Name
          </p>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Name</label>
              <input type="textbox" class="form-control" formControlName="Name">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Code</label>
              <input type="textbox" class="form-control" formControlName="Code">
            </div>
          </div>

          <div class="row">

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Manger</label>
              <app-autocomplete [autoCompleteText]="employeeText" (setAutoComplete)="setAutoCompleteEmployee($event)"
                [autoCompleteValue]="employeeValue" (fiteredData)="getEmployeeList($event)" [filteredOptions]="employeefilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Category</label>
              <select formControlName="CategoryId" class="form-control">
                <option value=0>--None--</option>
                <option *ngFor="let category of CategoryList" value={{category.Value}}>{{category.Text}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Phone</label>
              <input type="textbox" class="form-control" formControlName="PhoneNo">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
              <label for="text">Mobile</label>
              <input type="textbox" class="form-control" formControlName="MobileNo">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
              <label for="text">Email</label>
              <input type="textbox" class="form-control" formControlName="EmailID">
            </div>

            <!-- <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Skype ID</label>
              <input class="form-control" formControlName="SkypeID" type="textbox">
            </div> -->
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Branch</label>
              <select formControlName="TerritoryID" class="form-control">
                <option value=0>--None--</option>
                <option *ngFor="let territory of TerritoryList" value={{territory.Value}}>{{territory.Text}}
                </option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Nature Of Work</label>
              <select formControlName="NatureOfWorkId" class="form-control">
                <option value=0>--None--</option>
                <option *ngFor="let work of WorkList" value={{work.Value}}>{{work.Text}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Designation</label>
              <input type="textbox" class="form-control" formControlName="Designation">
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!resourceForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!resourceForm">Save</button>
  </div>
</form>