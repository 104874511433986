import { ProjectOrderService } from './../../../services/projectOrder/project-order.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-invoice',
  templateUrl: './project-invoice.component.html',
  styleUrls: ['./project-invoice.component.css']
})
export class ProjectInvoiceComponent implements OnInit {
  columnName = [];
  projectInvoiceData: any;
  constructor(
    private projectInvoice: ProjectOrderService
  ) {
    this.columnName = [];
  }

  ngOnInit() {
    this.getProjectInvoice();
  }

  getProjectInvoice() {
    this.projectInvoice.getAllProjectInvoice().subscribe(res => {
      this.projectInvoiceData = res.Data;
      const columns = [];
      const columnsIn = res.Data[0];
      // tslint:disable-next-line:forin
      for (const key in columnsIn) {
        columns.push(key);
        this.columnName = columns;
      }
    });
  }

}
