<div class="tab_element_cont">
    <div class="tab_title">
        <h3 class="page_title pull-left">
            <span class="icon_holder new_contact">
            <i class="fa fa-wrench fa-fw" aria-hidden="true"></i>
            </span>
            Subscriptions ({{subScriptions ? subScriptions.length :'0'}})
        </h3>
    </div>
    <div class="related_details clearfix">
        <div *ngIf="subScriptions && subScriptions.length">
            <app-htmltable [tableData]="subScriptions" [columnName]="columnName"></app-htmltable>
        </div>
    </div>
</div>