import { EmployeeInfoService } from '../../../../services/employeeInfo/employee-info.service';
import { Component, OnInit, Output, EventEmitter, ViewContainerRef, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { EmailService } from '../../../../services/common/activity-page/email/email.service';
import { VariableService } from '../../../../services/VariableService/variable.service';
import { AutocompleteModel } from '../../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../../services/autocomplete/autocomplete.service';
import { RelatedEmailService } from '../../../../services/relatedEmail/related-email.service';
import { GlobalService } from '../../../../services/global-Service/global.service';
import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-new-email',
  templateUrl: './new-email.component.html',
  styleUrls: ['./new-email.component.css']
})
export class NewEmailComponent implements OnInit {
  @Output() eventEmail = new EventEmitter();
  @ViewChild('relatedtextInput') relatedtextInput: ElementRef;

  emailForm: UntypedFormGroup;
  public autoComplete: AutocompleteModel[];
  RelatedToType: any;
  user: any;
  Name: any;
  fromAddress: any;
  ToEmailId: any;
  disableCC: Boolean = false;
  disableBCC: Boolean = false;

  disableSpanCC: Boolean = true;
  disableSpanBCC: Boolean = true;
  relatedTypeFilterOption: any[];
  selectedRelatedOption: any;
  masterTypeText: any;
  masterTypeValue: any;
  editor: Editor;
  html: '';


  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '20rem',
    width: 'auto',
    translate: 'no',
    // 'toolbar': [
    //   ['bold', 'italic', 'underline', 'strikeThrough'],
    //   ['justifyLeft', 'justifyRight', 'justifyFull', 'outdent'],
    //   ['link']
    // ]
    'toolbar': [
      ['bold', 'italic'], ['underline', 'strike'],
      // ['justifyLeft', 'justifyRight', 'justifyFull', 'outdent'],
      ['align_left', 'align_center', 'align_right', 'align_justify'],
      ['link']
    ]
  };

  RelatedTypeList = [
    {
      value: 'Account', name: 'Account', avatar: '../../assets/images/accounts.png'
    },
    {
      value: 'Campaign', name: 'Campaign', avatar: '../../assets/images/campaign.png'
    },
    {
      value: 'Case', name: 'Case', avatar: '../../assets/images/cases.png'
    },
    {
      value: 'Client', name: 'Client', avatar: '../../assets/images/client.png'
    },
    {
      value: 'Contract', name: 'Contract', avatar: '../../assets/images/contracts.png'
    },
    {
      value: 'Milestone', name: 'Milestone', avatar: '../../assets/images/milestone.png'
    },
    {
      value: 'Opportunity', name: 'Opportunity', avatar: '../../assets/images/opportunity.png'
    },
    {
      value: 'Project', name: 'Project', avatar: '../../assets/images/projects.png'
    },
    {
      value: 'ProjectTask', name: 'ProjectTask', avatar: '../../assets/images/projecttasks.png'
    },
    {
      value: 'Quote', name: 'Quote', avatar: '../../assets/images/quotes.png'
    },
    {
      value: 'Contact', name: 'Contact', avatar: '../../assets/images/contacts.png'
    },
    {
      value: 'Lead', name: 'Lead', avatar: '../../assets/images/leads.png'
    },
  ];
  constructor(
    public fb: UntypedFormBuilder,
    private emailService: EmailService,
    private autocompleteService: AutocompleteService,
    private variableService: VariableService,
    private relatedEmailService: RelatedEmailService,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.editor = new Editor();
    this.emailForm = this.fb.group({
      Status: '',
      ParentId: '',
      Subject: '',
      FromName: '',
      TextBody: '',
      ToAddress: '',
      CcAddress: '',
      RelatedToId: '',
      BccAddress: '',
      EmailSentID: '',
      FromAddress: '',
      HasAttachment: '',
      MessageDate: '',
      ValidatedFromAddress: '',
      RelatedType: ''
    });
    this.getMasterTypeList();
    this.setMasterType();
    this.onCompanyList();
    this.getAllEmployeeInfo();
    this.getRelatedEmail();
  }

  setMasterType() {
    const q: any = this.variableService.getSearchKeys();

    this.RelatedToType = q.type.slice(3);
    this.emailForm.controls['RelatedToId'].patchValue(q.paramId);

    this.emailForm.patchValue({
      RelatedType: this.RelatedToType,
    });
  }

  getMasterTypeList() {
    const q: any = this.variableService.getSearchKeys();
    this.autocompleteService.getMasterTypeListData(q.type, q.paramId).subscribe(data => {
      this.masterTypeText = data[0].Text;
      this.masterTypeValue = data[0].Value;

      if (q.type !== 'CRMLead' || q.type !== 'CRMContact') {
        const relatedObj = {
          Text: this.masterTypeText,
          Value: this.masterTypeValue,
        };
        this.relatedtextInput.nativeElement.value = relatedObj.Text;
        this.selectedRelatedOption = relatedObj;
      }
    });
  }


  displayRelatedFn(related: any) {
    this.selectedRelatedOption = related;
    return related ? related.Text : '';
  }

  getRealtedTypeList(value) {
    const relatedValue = this.emailForm.value.RelatedType;
    if (relatedValue !== '') {
      if (value.length > 1) {
        this.autocompleteService.getAllRelatedTypeList(relatedValue, value).subscribe(
          res => {
            this.relatedTypeFilterOption = res;
          },
          err => {
            console.log('errr====', err);
          });
      }
    }
  }

  getRelatedEmail() {
    this.relatedEmailService.getRelatedEmail().subscribe(data => {
      this.ToEmailId = data.EmailId;
    });
  }

  onCCClick(e) {
    if (e.isTrusted === true) {
      this.disableCC = true;
      this.disableSpanCC = false;
    }
  }

  onBccClick(e) {
    if (e.isTrusted === true) {
      this.disableBCC = true;
      this.disableSpanBCC = false;
    }
  }

  getAllEmployeeInfo() {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.fromAddress = user.EmailId;
  }

  onCompanyList() {
    const tokenKey = 'currentUser';
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.Name = user.FirstName + ' ' + user.LastName;
  }

  submitData() {
    if (this.emailForm.value.RelatedToId.Id === null) {
      this.emailForm.controls['RelatedToId'].patchValue(this.emailForm.value.RelatedToId.Value);
    }
  }


  onSubmit(form: UntypedFormGroup) {
    this.submitData();
    this.emailService.sendEmail(form.value).subscribe(data => {
      this.globalService.showEmailSuccess({ name: form.value.ToAddress });
      this.eventEmail.emit();
    });
    this.resetForm();
  }
  resetForm() {
    this.emailForm.patchValue({
      Subject: '',
      TextBody: '',
      ToAddress: '',
      CcAddress: '',
      BccAddress: '',
    });
  }
}
