<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">Send Email</h4>
</div>
<form [formGroup]="bulkEmailForm" class="form-validate" (ngSubmit)="onSubmit(bulkEmailForm.value)">
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-12">
        <label for="text">
          <span class="text-danger">*</span>From</label>
        <input type="textbox" class="form-control" formControlName="FromAddress">
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12">
        <label for="text">
          <span class="text-danger">*</span>To</label>
        <input type="textbox" class="form-control" formControlName="ToAddress" [ngModel]="toEmailAddress">
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12">
        <label for="text">
          <span class="text-danger">*</span>Subject</label>
        <input type="textbox" class="form-control" formControlName="Subject">
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12">
        <label for="text">
          <span class="text-danger">*</span>Body</label>
        <!-- <app-ngx-editor [config]="editorConfig" formControlName="TextBody"></app-ngx-editor> -->
        <div class="html-Container"><ngx-editor-menu [editor]="editor"  [toolbar]="this.editorConfig.toolbar"> </ngx-editor-menu>
          <ngx-editor
            formControlName="TextBody"
            [editor]="editor"
            [disabled]="false"
            [placeholder]="'Type here...'"
        ></ngx-editor></div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-primary">Send</button>
  </div>
</form>