import { AutocompleteModel } from '../../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../../services/autocomplete/autocomplete.service';
import { AccountService } from '../../../../services/accounts/account.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-related',
  templateUrl: './account-related.component.html',
  styleUrls: ['./account-related.component.css']
})
export class AccountRelatedComponent implements OnInit {
  @Input() searchKeys;
  accountData: Array<any>;
  autoCompleteAccount: AutocompleteModel[];
  constructor(
    private accountService: AccountService,
    private autocompleteService: AutocompleteService,
    public router: Router
  ) { }

  ngOnInit() {
    // this.getAllAccountRelated();
  }

  getAutoCompleteAccount() {
    this.autocompleteService.getAccountList().subscribe(data => {
      this.autoCompleteAccount = data;
    });
  }

  getAllAccountRelated(accountId) {
    if (accountId !== null) {
      this.accountService.getByAccountId(accountId, 'Account').subscribe(data => {
        this.accountData = data;
      });
    }
  }

  gotoDetailPage(id) {
    const route = 'accountDetail/' + id;
    this.router.navigate([route]);
  }
}
