import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../util.service';
@Component({
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.css']
})
export class SpinnerLoaderComponent implements OnInit {
  constructor(private utilService:UtilService) { 
   
  }

  ngOnInit() {
    this.utilService.changeLoaderStatus('true');
  }
  ngOnDestroy(){
    this.utilService.changeLoaderStatus('false');
  }
}
