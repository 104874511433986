import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class QuoteProductService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  getQuoteProduct(Quote) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/quoteProduct/getall?Id=${q.paramId}&MasterType=${Quote}&`);
  }
  // Post Method
  addQuote(form) {
    return this.apiService.post('api/quoteProduct/create', form);
  }

  /** Quote Service */

  addQuoteService(form) {
    return this.apiService.post('api/quoteService/create', form);
  }

  getQuoteService(CRMQuote) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/quoteService/getall?Id=${q.paramId}&MasterType=${CRMQuote}&`);
  }
}
