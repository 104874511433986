import { AutocompleteService } from './../../../services/autocomplete/autocomplete.service';
import { InvoiceService } from './../../../services/invoice/invoice.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { GlobalService } from '../../../services/global-Service/global.service';
import { ProductService } from '../../../services/product/product.service';
import { NumberValidators } from './validators';

@Component({
  selector: 'app-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.css']
})
export class NewInvoiceComponent implements OnInit {
  @Input() projectInvoiceData: any;
  @Output() getData = new EventEmitter();
  public GSTTypeList: AutocompleteModel[];
  ServiceList: any;
  invoiceData: any;
  invoiceForm: UntypedFormGroup;
  selectedOption: any;
  EditNew: any;
  clientfilteredOptions: any[];
  currencyfilteredOptions: any[];

  clientText: any; 
  clientValue: any;
  currencyText: any; 
  currencyValue: any;
  projectId:any;
  projectText:any;
  currencyExchangeRate: any;
  exchangeRate: Boolean = false;
  ListofProducts:any;
  getProductList:any;
  getProjectList:any;
  
  constructor(
    public fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    public activeModal: NgbActiveModal,
    private invoiceService: InvoiceService,
    private autoCompleteService: AutocompleteService,
    private masterlookupService: MasterLookupService,
    private productService: ProductService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    let date = this.projectInvoiceData && this.projectInvoiceData.Date? this.datePipe.transform(this.projectInvoiceData.Date, 'dd-MM-yyyy'): new Date();
    this.invoiceForm = this.fb.group({
      InvoiceId: null,
      Date: date,
      CustomerID: '',
      TaxType: this.projectInvoiceData && this.projectInvoiceData.TaxTypeID? this.projectInvoiceData.TaxTypeID : '',
      Remarks: '',
      ServiceType: '',
      CurrencyId: '',
      ExchangeRate: '',
      DueDate: new Date(),
      ProjectId:"",
      InvoiceNo:'',
      Terms:'',
      ListofProducts:this.fb.array([this.createListofProduct()])
    });
    this.ListofProducts = this.invoiceForm.get('ListofProducts');
    this.editInvoiceData();
    this.getEditNewValue();
    this.getGstTypeList();
    // this.getServiceTypeList();
    if(this.projectInvoiceData){
      this.clientText = this.projectInvoiceData.ClientName;
      this.clientValue = this.projectInvoiceData.CustomerId;

      this.projectText = this.projectInvoiceData.ProjectName;
      this.projectId = this.projectInvoiceData.ProjectID;

      // this.customerInvoiceForm.ProjectID = this.projectInvoiceData.ProjectID;
      // this.customerInvoiceForm.CustomerId = this.projectInvoiceData.CustomerId;

      // this.customerInvoiceForm.Date = this.projectInvoiceData.Date,
        // this.customerInvoiceForm.TaxTypeID = this.projectInvoiceData.TaxTypeID;
    }
  }
  setAutoCompleteProduct(data,i) {
    let finalListofProducts=this.invoiceForm.get('ListofProducts').value;
      finalListofProducts[i]['ProductId']=data.data.option.value.Value;
      finalListofProducts[i]['ProductName']=data.data.option.value.Text;
    this.invoiceForm.setValue({...this.invoiceForm.value,ListofProducts:finalListofProducts})
  }
  filterData(value) {
    value.data = value.data.trim();
    this.productService.getAllPosProductList(value.data).subscribe(res => {
      this.getProductList = res;
    });
  }

  setCostCenter(data){
    this.invoiceForm.setValue({...this.invoiceForm.value,ProjectId:data.data.option.value.Value})
  }
  filterCostCenter(value){
    value.data = value.data.trim();
    this.autoCompleteService.getAllProjectList(value.data).subscribe(res => {
      this.getProjectList = res;
    });
  }

  calculate(i){
    let finalListofProducts=this.invoiceForm.get('ListofProducts').value;
    let tempObj = finalListofProducts[i],qty=tempObj['Qty']||0,rate=tempObj['Rate']||0,
                  disc=tempObj['Disc']||0,taxRate=tempObj['TaxRate']||0;
    tempObj['Amount'] = ((qty*rate)-(((qty*rate)/100)*disc)).toFixed(2) || 0;
    tempObj['TaxAmount'] = ((tempObj['Amount']*taxRate)/100).toFixed(2);
    tempObj['TotalAmount'] = (tempObj['Amount'] - tempObj['TaxAmount']).toFixed(2);
    
    finalListofProducts[i] = tempObj;
    this.invoiceForm.setValue({...this.invoiceForm.value,ListofProducts:finalListofProducts});
    console.log(this.invoiceForm.valid);

  }
  
  createListofProduct(){
    let tempObj = {
      SerialNo:this.invoiceForm?this.invoiceForm.value.ListofProducts.length:'1',
      Type:'Product',
      ProductId:'',
      Qty:['', [Validators.required, NumberValidators.isNumber()]],
      Rate:['', [Validators.required, NumberValidators.isNumber()]],
      Disc:['', [Validators.required, NumberValidators.isNumber()]],
      Amount:0,
      TaxRate:'',
      TaxAmount:0,
      TotalAmount:0,
      Description:'',
      ProductName:''
      }
      if(this.projectInvoiceData && this.projectInvoiceData.orderNumber){
        tempObj['OrderNo'] ='';
      }
    return this.fb.group(tempObj)
  }
  addListofProduct(){
    this.ListofProducts.push(this.createListofProduct())
  }
  removeListofProduct(index) {
    // this.contactList = this.form.get('contacts') as FormArray;
    this.ListofProducts.removeAt(index);
  }
  getEditNewValue() {
    if (this.invoiceForm.value.InvoiceId === null) {
      this.EditNew = 'New';
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      this.currencyText = user.CurrencyCode;
      this.currencyValue = user.CurrencyId;
      this.currencyExchangeRate = user.CurrencyId;
      this.invoiceForm.controls['CurrencyId'].setValue(user.CurrencyId);
      this.invoiceForm.controls['ExchangeRate'].setValue(user.CurrencyId);
    } else {
      this.invoiceForm.controls['Date'].setValue(new Date(this.invoiceForm.value.Date));
      this.invoiceForm.controls['DueDate'].setValue(new Date(this.invoiceForm.value.DueDate));
      this.EditNew = 'Edit';
    }
  }

  editInvoiceData() {
    if (this.invoiceData !== undefined) {
      this.invoiceData.CustomerID = this.invoiceData.CustomerId;
      this.invoiceForm.patchValue(this.invoiceData);
      (this.invoiceForm.get('ListofProducts') as FormArray).clear(); 
      this.invoiceData.ListOfProducts.forEach((prod) => {
        const ListofProductsForm = this.createListofProduct();
        ListofProductsForm.patchValue(prod); 
        (this.invoiceForm.get('ListofProducts') as FormArray).push(ListofProductsForm); 
      });
      console.log('this.invoiceForm',this.invoiceForm);
      this.currencyText=this.invoiceData.CurrencyName; 
      this.currencyValue=this.invoiceData.CurrencyId;
      this.clientText=this.invoiceData.CustomerName; 
      this.clientValue=this.invoiceData.CustomerId;
      this.projectText=this.invoiceData.ProjectName || ''; 
      this.projectId=this.invoiceData.ProjectId;
    }
  }

  getGstTypeList() {
    this.masterlookupService.getTaxTypeList('Sales').subscribe(data => {
      this.GSTTypeList = data;
    });
  }

  getServiceTypeList() {
    this.masterlookupService.getServiceList().subscribe(data => {
      this.ServiceList = data;
    });
  }
  filterServiceListData(value){
    value.data = value.data.trim();
    this.masterlookupService.getFilteredServiceList(value.data).subscribe(res => {
      this.ServiceList = res;
    });
  }
  getClientList(value) {
    this.selectedOption = {};
    this.autoCompleteService.getAllClientList(value.data).subscribe(
      res => {
        this.clientfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteClient(data) {
    const clientId = data.data.option.value.Value;
    this.invoiceForm.controls['CustomerID'].setValue(clientId);
  }

  getCurrencyList(value) {
    this.selectedOption = {};
    this.autoCompleteService.getallCurrencyList(value.data).subscribe(
      res => {
        this.currencyfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteCurrency(data) {
    const currency = data.data.option.value.Value;
    this.invoiceForm.controls['CurrencyId'].setValue(currency);
    if (this.currencyExchangeRate !== currency) {
      this.exchangeRate = true;
    } else {
      this.exchangeRate = false;
    }
  }

  onSubmit(form: UntypedFormGroup) {
    let responseMessage = [];
    this.invoiceForm.value.Date = this.datePipe.transform(
      this.invoiceForm.value.Date,
      'yyyy-MM-dd'
    );

    this.invoiceForm.value.DueDate = this.datePipe.transform(
      this.invoiceForm.value.DueDate,
      'yyyy-MM-dd'
    );

    // if (form.value.InvoiceId == null) {
      this.invoiceService.addInvoice(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showDefaultSuccess({ masterName: 'Invoice' });
        }
        this.getData.emit();
      });
    // } else {
    //   this.invoiceService.updateInvoice(form.value).subscribe(data => {
    //     responseMessage = data.ErrorMessages;
    //     if (responseMessage[1] === '0') {
    //       this.globalService.showDefaultUpdate({ masterName: 'Invoice' });
    //     }
    //     this.getData.emit();
    //   });
    // }
    this.activeModal.close();
    this.resetForm();
    this.globalService.disposeToastr();
  }

  resetForm() {
    this.invoiceForm.reset();
  }

}
