import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class ClientCustomerService {

  constructor( private apiService: ApiService,
    private variableService: VariableService) { }

  getCustomerOrder() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/customerOrder/getall?Id=${q.paramId}&`);
  }

  getCustomerInvoice() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/customerInvoice/getall?Id=${q.paramId}&`);
  }

  getCustomerQuote() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/customerQuote/getall?Id=${q.paramId}&`);
  }

  getCustomerCollection() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/customerCollection/getall?Id=${q.paramId}&`);
  }

  getCustomerSubscriptions(){
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/clientCRM/subscriptions/getall?Id=${q.paramId}&`);
  }
  addSubscripstion(form) {
    return this.apiService.post('api/clientCRM/subscriptions/create', form);
  }
}
