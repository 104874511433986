<div class="body_inner">
  <div class="heading_cont bg_white_margin_top">
    <div class="pull-left">
      <span class="menu_icon target fa fa-bullseye">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Timesheet</p>
      </h3>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-2" for="text">Resource</label>
    <div class="col-sm-4">
      <app-autocomplete [autoCompleteText]="resourceText" (setAutoComplete)="setAutoCompleteResource($event)"
        [autoCompleteValue]="resourceValue" (fiteredData)="getResourceList($event)" [filteredOptions]="resourcefilterOption">
      </app-autocomplete>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-2" for="text">Period</label>
    <div class="col-sm-4">
      <select class="form-control" [(ngModel)]="timesheetForm.PeriodType" (change)="onPeriodType($event.target.value)">
        <option value="CurrentWeek">This Week</option>
        <option value="LastWeek">Last Week</option>
        <option value="ThisMonth">This Month</option>
        <option value="Yesterday">Today</option>
        <option value="Last 15days">Last 15days</option>
        <option value="Last 30days">Last 30days</option>

      </select>
    </div>
  </div>

  <app-timesheet-table [timeSheetList]="timesheetForm.ListOfTimesheet" (addFieldValue)="addFieldValue($event)"
    (deleteFieldValue)="deleteFieldValue($event)"></app-timesheet-table>
</div>
<br />
<div class="">
  <button type="button" class="btn btn-default" (click)="resetForm()">Cancel</button>
  <button type="button" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
</div>