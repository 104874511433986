import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-status-config-page',
  templateUrl: './status-config-page.component.html',
  styleUrls: ['./status-config-page.component.css']
})
export class StatusConfigPageComponent implements OnInit {
  statusConfigForm: StatusConfigForm;
  statusConfig: Array<any> = [];
  listOfStageObj: ListOfStageObj;
  public statusRelatedTo = [
    { Value: 'Lead', text: 'Lead' },
    { Value: 'Opportunity', text: 'Opportunity' },
    { Value: 'Quotation', text: 'Quotation' },
    { Value: 'Client', text: 'Client' },
    { Value: 'Machine Type', text: 'Machine Type' },
    { Value: 'List Type', text: 'List Type' }
  ];

  public statusSerialNo = [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'
  ];

  constructor(
    public fb: UntypedFormBuilder,
    private configService: ConfigurationService,
    vcr: ViewContainerRef,
    private globalService: GlobalService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.resetStatusConfigForm();
    this.resetListOfStageObj();
    this.statusConfigForm.ListOfStage.push(this.listOfStageObj);
  }

  addFieldValue(i) {
    this.resetListOfStageObj();
    const obj = this.statusConfigForm.ListOfStage[i + 1];
    if (!obj) {
      this.statusConfigForm.ListOfStage.push(this.listOfStageObj);
    }
    this.resetListOfStageObj();
  }

  resetListOfStageObj() {
    this.listOfStageObj = {
      SerialNo: '',
      ActivityName: '',
      PercentageCompleted: ''
    };
  }
  deleteFieldValue(i) {
    if (this.statusConfigForm.ListOfStage.length === 1) {
      return false;
    } else {
      this.statusConfigForm.ListOfStage.splice(i, 1);
      return true;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selectMasterType(Value: any) {
    this.reset();
    this.statusConfigForm.MasterType = Value;
    this.configService.getStatusConfig(Value).subscribe(data => {
      this.statusConfig = data;
      this.statusConfig.map((obj, i) => {
        this.statusConfigForm['ListOfStage'].push(obj);
      });
      this.statusConfigForm['ListOfStage'].push(this.listOfStageObj);
      this.resetListOfStageObj();
    });
  }

  onSubmit() {
    this.statusConfigForm.ListOfStage = this.statusConfigForm.ListOfStage.filter(
      obj => {
        return obj.SerialNo || obj.ActivityName || obj.PercentageCompleted;
      }
    );
    this.configService
      .addStatusConfig(this.statusConfigForm)
      .subscribe(data => {
        this.globalService.showDefaultSuccess({ masterName: 'Status Configuration' });
      });
    this.resetStatusConfigForm();
    this.router.navigate(['home']);
  }

  resetStatusConfigForm() {
    this.statusConfigForm = { MasterType: '', ListOfStage: [] };
  }

  reset() {
    this.resetStatusConfigForm();
    this.resetListOfStageObj();
  }
  resetForm() {
    this.resetStatusConfigForm();
    this.resetListOfStageObj();
    this.statusConfigForm.ListOfStage.push(this.listOfStageObj);
  }
}

declare class ListOfStageObj {
  SerialNo: String;
  ActivityName: String;
  PercentageCompleted: String;
}

declare class StatusConfigForm {
  MasterType: String;
  ListOfStage: Array<any>;
}
