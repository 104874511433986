import { GlobalService } from './../../../services/global-Service/global.service';
import { NewInvoiceComponent } from './../new-invoice/new-invoice.component';
import { serviceURLs } from './../../../constants/CRMService/serviceURLs';
import { EmployeeInfoService } from './../../../services/employeeInfo/employee-info.service';
import { InvoiceService } from '../../../services/invoice/invoice.service';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { CommonService } from '../../../services/common/common/common.service';
import { ViewContainerRef, Input } from '@angular/core';
import { VariableService } from '../../../services/VariableService/variable.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Page } from '../../../../../models/page';
import { Component, OnInit } from '@angular/core';
import * as Utils from '../../../../../common/utils';
import { DeleteModalComponent } from './../../../../../common/delete-modal/delete-modal.component';
import * as _ from 'lodash';
import { Editor, toHTML } from 'ngx-editor';
import { FormGroup, FormControl } from '@angular/forms';


declare var $: any;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  @Input()
  invoiceData: any;
  invoicePrintData: Array<any> = [];
  showSpinner: Boolean = true;
  selectedPageFilter: any;
  pageFilters: any[];
  getPageFilterCalled = false;
  active = true;
  temp: any[] = [];
  alerts: any[];
  isFilterLogic: boolean;
  page = new Page();
  selectedPage: any = {};
  filterObj: any;
  public showFilter: Boolean = false;
  filterArray: Array<any> = [];
  filterLogic = '';
  invoices: any;
  ToMailId: any;
  FromEmail: any;
  Body: any;
  Subject: any;
  columnNames: any[];
  ReportId: any;
  filetype: any;
  TransactionNo: any;
  RDLName: any;
  invoice: Array<any> = [];
  deleteData: any;
  searchStr: String = '';
  searchValueSet = false;
  searchFilterAdded = false;
  newListName = ''; AccessType = '';
  editor: Editor;
  form = new FormGroup({
    editorContent: new FormControl(null),
  });
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '20rem',
    width: 'auto',
    translate: 'no',
    // 'toolbar': [
    //   ['bold', 'italic', 'underline', 'strikeThrough'],
    //   ['justifyLeft', 'justifyRight', 'justifyFull', 'outdent'],
    //   ['link']
    // ]
    'toolbar': [
      ['bold', 'italic'], ['underline', 'strike'],
      // ['justifyLeft', 'justifyRight', 'justifyFull', 'outdent'],
      ['align_left', 'align_center', 'align_right', 'align_justify'],
      ['link']
    ]
  };

  constructor(
    private modalService: NgbModal,
    private variableService: VariableService,
    private commonService: CommonService,
    private cService: CommonService,
    private topnavbarService: TopnavbarService,
    private invoiceService: InvoiceService,
    private employeeService: EmployeeInfoService,
    private globalService: GlobalService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
    this.filterArray = [];
    this.filterObj = {};
    this.page.size = 15;
    this.selectedPageFilter = {};
  }

  ngOnInit() {
    this.editor = new Editor();
    this.columnNames = [];
    this.getAllColumns();
    this.getPageFilters();
  }

  getInvoiceData(reqOptions?: any) {
    this.variableService.setSearchKeys({
      type: this.selectedPage.pagename
    });

    if (!reqOptions) {
      reqOptions = {};
      reqOptions.PageNumber = 1;
      reqOptions.PageSize = this.page.size;
      reqOptions.TotalRecords = null;
    }
    reqOptions.MasterType = this.selectedPage.pagename;
    if (this.searchStr && this.searchStr.length > 1) {
      this.searchFilterAdded = true;
      reqOptions.PageNumber = 1;
      const columnNames = [];
      this.columnNames.map(column => {
        columnNames.push(column.Name);
      });

      if (this.filterArray.length === 1) {
        this.filterLogic += `1 AND ${this.filterArray.length + 1}`;
      } else if (this.filterArray.length > 1) {
        this.filterLogic += ` AND ${this.filterArray.length + 1}`;
      }
      this.filterArray.push({
        columnName: `concat(${columnNames.join(',')})`,
        sqlOperator: 'LIKE',
        value: `%${this.searchStr}%`
      });
    }
    reqOptions.Filter = {
      logic: _.cloneDeep(this.filterLogic),
      FilterItems: _.cloneDeep(this.filterArray)
    };

    if (this.searchStr && this.searchStr.length > 1) {
      this.filterArray.pop();
      this.setLogic();
    }
    this.commonService.getTableData(reqOptions).subscribe(
      res => {
        const rows = res.Data;
        this.page = {
          totalElements: res.TotalRecords,
          totalPages: Math.ceil(
            res.TotalRecords / reqOptions.PageSize
          ),
          size: this.page.size,
          pageNumber: reqOptions.PageNumber - 1
        };
        this.showSpinner = false;
        this.temp = res;
        for (const row of rows) {
          row.height = Math.floor(Math.random() * 80) + 50;
        }
        this.invoices = rows;
        if (this.getPageFilterCalled) {
          this.showFilter = true;
          this.getPageFilterCalled = false;
        }
      },
      err => {
        this.showSpinner = false;
        if (this.searchStr && this.searchStr.length > 2) {
          this.filterArray.pop();
          this.setLogic();
        }
      }
    );
  }

  setSearchStr(val) {
    this.searchStr = val;
    if (this.searchStr.length > 1) {
      this.showSpinner = true;
      this.getInvoiceData();
    } else if (!this.searchStr) {
      if (this.searchFilterAdded) {
        this.filterArray.pop();
        this.setLogic();
        this.searchFilterAdded = false;
      }
      this.getInvoiceData();
    }
  }

  setLogic() {
    if (this.filterArray.length > 1) {
      let l: any;
      // this.filterArray.splice(0, 1);
      this.filterArray.forEach((filter, i) => {
        if (i > 0) {
          l += 'AND ' + (i + 1);
        } else {
          l = '1 ';
        }
      });
      this.applyFilterLogic(l);
    } else {
      this.applyFilterLogic('');
    }
  }

  open() {
    const modalRef = this.modalService.open(NewInvoiceComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.getData.subscribe(() => {
      this.getInvoiceData();
    });
  }

  editopen(data) {
    this.invoiceService.InvoiceEditById(data.row.ID).subscribe(resData => {
      this.invoiceData = resData;
      const modalRef = this.modalService.open(NewInvoiceComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.invoiceData = this.invoiceData;
      modalRef.componentInstance.getData.subscribe(() => {
        this.getInvoiceData();
      });
    });
  }

  getAllColumns() {
    this.commonService
      .getColumnData(this.selectedPage.pagename)
      .subscribe(res => {
        this.columnNames = res.ColumnNames;
        this.filterObj = Utils.getColumnsObject(res.ColumnNames);
      });
  }

  onDelete(data) {
    this.deleteData = data;
    const modalRef = this.modalService.open(DeleteModalComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.deleteData = this.deleteData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getInvoiceData();
    });
  }

  // Filter Related Functions
  toggle() {
    this.showFilter = !this.showFilter;
    this.invoice = [...this.invoice];
  }

  applyFilter(arr: any[]) {
    this.filterArray = arr;
    if (this.selectedPageFilter.FilterID) {
      this.createNewPageFilter(
        this.selectedPageFilter.FilterName,
        0,
        { FilterItems: arr, logic: this.filterLogic },
        this.selectedPageFilter.FilterID,
        1
      );
    } else {
      this.getInvoiceData();
    }
  }

  applyFilterLogic(logic: string) {
    this.filterLogic = logic;
  }

  addLogic() {
    this.isFilterLogic = true;
  }

  removeLogic() {
    this.filterLogic = '';
    this.isFilterLogic = false;
  }

  custommenu(content) {
    const columnFilCMP = this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static'
    });
  }

  makeColumnsVisible(data) {
    const cols = [];
    data.oldCols.forEach(c => {
      if (data.newCols[c.Name]) {
        c.Visible = data.val;
        cols.push(c);
      } else {
        cols.push(c);
      }
    });
    this.columnNames = cols;
  }

  /**Page filter (header level) */
  getPageFilterData(pageFilter) {
    this.showFilter = false;
    this.getPageFilterCalled = true;
    this.selectedPageFilter = pageFilter;
    const filters = JSON.parse(pageFilter.FilterQuery);

    this.filterLogic = filters && filters.logic ? filters.logic : '';
    this.filterArray =
      filters && filters.FilterItems ? filters.FilterItems : [];
    this.getInvoiceData();
  }

  getPageFilters(b = false) {
    this.cService.getPageFilter(this.selectedPage.pagename).subscribe(
      res => {
        this.pageFilters = [];

        this.pageFilters.push({
          FilterName: 'All',
          FilterID: null,
          FilterQuery: null,
          MasterType: this.selectedPage.pagename,
          AccessType: 1
        });
        res.forEach(obj => {
          this.pageFilters.push(obj);
        });
        if (!b && !this.selectedPageFilter.FilterID) {
          this.selectedPageFilter = this.pageFilters[0];
        } else {
          if (!b) {
            this.selectedPageFilter = this.pageFilters[
              this.pageFilters.length - 1
            ];
          }
        }
        this.getInvoiceData();
      },
      err => {
        console.log('err===', err);
      }
    );
  }

  createNewPageFilterModal(content) {
    this.modalService
      .open(content, { size: 'sm', backdrop: 'static' })
      .result.then(result => {
        // console.log(`Closed with: ${result}`);
      });
  }

  createNewPageFilter(
    name,
    accessType,
    filterQuery = {},
    filterID = null,
    mode = 0
  ) {
    this.cService
      .createPageFilter({
        FilterID: filterID,
        FilterName: name,
        AccessType: parseInt(accessType, 10),
        Mode: mode,
        FilterQuery: JSON.stringify(filterQuery),
        MasterType: this.selectedPage.pagename
      })
      .subscribe(
        res => {
          this.selectedPageFilter.FilterQuery = JSON.stringify(filterQuery);
          if (!filterID) {
            if (name !== 'All') {
              this.pageFilters.push(res);
              this.selectedPageFilter = res;
              $('#btnCancel').trigger('click');
              this.newListName = '';
              this.AccessType = '';
            }
          }

          this.getPageFilterData(this.selectedPageFilter);
        },
        err => {
          console.log('err===', err);
        }
      );
  }

  onSelectCheckBox(data) {
    this.invoicePrintData = data;
  }

  setInvoiceData() {
    for (let i = 0; i < this.invoicePrintData.length; i++) {
      this.ReportId = this.invoicePrintData[i].ReportID;
      this.filetype = 'pdf';
      this.TransactionNo = this.invoicePrintData[i].InvoiceNo;
      this.RDLName = this.invoicePrintData[i].RDLName;
      this.ToMailId = this.invoicePrintData[i].ToEmail;
    }
  }

  generatePDF() {
    if (this.invoicePrintData.length === 0) {
      this.globalService.showError();
    } else {
      this.setInvoiceData();
      const user = JSON.parse(sessionStorage.getItem('currentUser'));

      const url = `api/files/getfile?ReportId=${this.ReportId}&FileType=${
        this.filetype
        }&TransactionNo=${this.TransactionNo}&RDLName=${this.RDLName}&CompanyId=${
        user.CompanyId
        }&UserId=${user.UserId}`;
      window.open(serviceURLs.CRMServiceBaseURL + url, '_blank');
    }
  }

  sendInvoiceMail(invoiceMailContent) {
    this.reset();
    if (this.invoicePrintData.length === 0) {
      this.globalService.showError();
    } else {
      this.employeeService.getAllInfo().subscribe(infodata => {
        this.FromEmail = infodata.EmailID;
        this.setInvoiceData();
      });
      this.modalService.open(invoiceMailContent, {
        size: 'sm',
        backdrop: 'static',
        keyboard: false
      });
    }
  }

  sendMail() {
    this.Body = toHTML(this.form.value.editorContent);
    this.ToMailId = this.ToMailId;
    if (this.FromEmail === '' || this.ToMailId === '') {
      this.globalService.showEmailError();
    } else {
      const tomailName = this.ToMailId;
      this.invoiceService
        .sendInvoiceEmail(
          this.ReportId,
          this.RDLName,
          this.TransactionNo,
          this.filetype,
          this.ToMailId,
          this.FromEmail,
          this.Body,
          this.Subject
        )
        .subscribe(data => {
          this.globalService.showEmailSuccess({ name: tomailName });
        });
      $('#btnCancel').trigger('click');
      this.reset();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  reset() {
    this.Subject = '';
    this.Body = '';
    this.FromEmail = '';
    this.ToMailId = '';
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
