<div class="tab_title">
  <h3 class="page_title pull-left">
    <span class="icon_holder new_lead">
      <i class="fa fa-bullseye fa-fw" aria-hidden="true"></i>
    </span>
    Campaign History ({{campaigns?.length}})
  </h3>
</div>

<div class="related_details">
  <div class="row">
    <div class="col-md-6 col-xs-12 mb-10" *ngFor="let item of campaigns">
      <a class="dottedLine" (click)="gotoDetailPage(item.CampaignId)" href="javascript:void(0)">{{item.CampaignName}}</a>
      <table class="custom_table">
        <tr>
          <th class="custom_name">Start Date</th>
          <th class="custom_colon">:</th>
          <td class="caseheadLine">{{item.StartDate}}</td>
        </tr>

        <tr>
          <th class="custom_name">Status Name</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{item.StatusName}}</td>
        </tr>

        <tr>
          <th class="custom_name">Priority Name</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{item.TypeName}}</td>
        </tr>
      </table>

    </div>
  </div>
</div>