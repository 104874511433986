<div class="">
  <form [formGroup]="productForm">
    <div class="">
      <div class="">
        <div class="">

          <p class="headingclass"> Product Information</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="Name">
                <span class="text-danger">*</span> Product Name</label>
              <input type="textbox" class="form-control" formControlName="Name">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Product Code</label>
              <input type="textbox" class="form-control" formControlName="Code">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Product Description</label>
              <textarea class="form-control" formControlName="Description" rows="3"></textarea>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 checkbox_contant">
              <label class="form-check-label">Active</label>
              <input type="checkbox" formControlName="IsActive" class="custom_checkbox" style="left: 20px;" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>