import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common/common/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  reportPages: any[];
  columns: string[];
  selectedPage: any = null;
  columnsArray: any[];
  ReportName: string;
  AccessType: any;
  sqlOperators = [
    'Equals',
    'Not Equal To',
    'Greater than',
    'Less than',
    'Less or Equal',
    'Greater or Equal',
    'Contains',
    'Does Not Contain',
    'Start With'
  ];
  newOrderBy = {
    columnName: null,
    sortType: null
  };
  orderBy: any = [];
  editOrBy = false;
  showOrderBy = false;
  newFilter = {
    columnName: null,
    sqlOperator: null,
    sqlOperatorValue: null,
    value: null,
    valueIndicator: null
  };
  showFilter = false;
  filter = [];
  editfil = false;
  editfilId: any;
  editOrById: any;
  cols = {};
  colsType = 'add';
  constructor(private _cService: CommonService, public _router: Router) { }

  ngOnInit() {
    this.columnsArray = [];
    this.getReportLookup();
    this.columns = [];
    this.orderBy = [];
    this.AccessType = 0;
  }

  editFilter(i) {
    this.newFilter = this.filter[i];
    this.editfil = true;
    this.editfilId = i;
    this.showFilter = true;
  }

  editOrderBy(i) {
    this.newOrderBy = this.orderBy[i];
    this.editOrBy = true;
    this.editOrById = i;
    this.showOrderBy = true;
  }

  addOrderBy(obj) {
    if (!this.editOrBy) {
      this.orderBy.push(obj);
    } else {
      this.orderBy[this.editOrById] = obj;
    }

    this.showOrderBy = false;
    this.editOrById = null;
    this.editOrBy = false;
    this.newOrderBy = {
      columnName: null,
      sortType: null
    };
  }

  getReportLookup() {
    this._cService.getReportLookup().subscribe(
      data => {
        this.reportPages = data;
      },
      err => {
        console.log('err===', err);
      }
    );
  }

  showAddFilter() {
    this.showFilter = true;
  }

  showOrdBy() {
    this.showOrderBy = true;
  }

  hideAddFilter() {
    this.showFilter = false;
  }

  addFilter(obj) {
    switch (obj.sqlOperatorValue) {
      case 'Start With':
        obj.sqlOperator = 'LIKE';
        obj.value = obj.valueIndicator + '%';
        break;
      case 'Equals':
        obj.sqlOperator = '=';
        obj.value = obj.valueIndicator;
        break;
      case 'Not Equal To':
        obj.sqlOperator = '!=';
        obj.value = obj.valueIndicator;
        break;
      case 'Greater than':
        obj.sqlOperator = '>';
        obj.value = obj.valueIndicator;
        break;
      case 'Less than':
        obj.sqlOperator = '<';
        obj.value = obj.valueIndicator;
        break;
      case 'Less or Equal':
        obj.sqlOperator = '<=';
        obj.value = obj.valueIndicator;
        break;
      case 'Greater or Equal':
        obj.sqlOperator = '>=';
        obj.value = obj.valueIndicator;
        break;
      case 'Contains':
        obj.sqlOperator = 'LIKE';
        obj.value = '%' + obj.valueIndicator + '%';
        break;
      case 'Does Not Contain':
        obj.sqlOperator = 'NOT LIKE';
        obj.value = '%' + obj.valueIndicator + '%';
        break;
      default:
        obj.sqlOperator = obj.sqlOperator;
        break;
    }

    if (this.editfil) {
      this.filter[this.editfilId] = obj;
    } else {
      this.filter.push(obj);
    }
    this.newFilter = {
      columnName: null,
      sqlOperator: null,
      sqlOperatorValue: null,
      value: null,
      valueIndicator: null
    };
    this.editfil = false;
    this.editfilId = null;
    this.hideAddFilter();
  }

  deleteFilter(i) {
    this.filter.splice(i, 1);
  }

  deleteOrBy(i) {
    this.orderBy.splice(i, 1);
  }

  getColumns() {
    this.columnsArray = [];
    this.orderBy = [];
    this.filter = [];
    if (this.selectedPage !== null) {
      this._cService.getReportObjectColumn(this.selectedPage).subscribe(
        data => {
          this.columns = data.map((v)=> {return {"Name":v,'Value':v,'Visible': true}});
        },
        err => {
          console.log('err', err);
        }
      );
    }
  }

  toggleColumns(column) {
    const index = this.columnsArray.indexOf(column);
    if (index !== -1) {
      this.columnsArray.splice(index, 1);
    } else {
      this.columnsArray.push(column);
    }
  }

  setLogic() {
    if (this.filter.length > 1) {
      let l: any;
      this.filter.forEach((filter, i) => {
        if (i > 0) {
          l += 'AND ' + (i + 1);
        } else {
          l = '1 ';
        }
      });
      return l;
    } else {
      return '';
    }
  }

  saveReport() {
    const columnNameChanged = [];
    this.filter.map(a => {
      a.columnName = `[${a.columnName}]`;
      columnNameChanged.push(a);
    });
    const filter = { FilterItems: columnNameChanged, logic: this.setLogic() };
    const c = [];
    Object.keys(this.columns).map(a => {
      !this.columns[a]['Visible'] && c.push(`[${this.columns[a]['Name']}]`);
    });
    let orderBy = '';
    this.orderBy.map((a, i) => {
      if (this.orderBy.length === i + 1) {
        orderBy += `[${a.columnName}] ${a.sortType}`;
      } else {
        orderBy += `[${a.columnName}] ${a.sortType}, `;
      }
    });
    this._cService
      .createUserReport({
        ReportID: null,
        ReportName: this.ReportName,
        ObjectType: this.selectedPage,
        Mode: 0,
        ReportColumns: c.join(),
        FilterQuery: JSON.stringify(filter),
        Filter: filter,
        sqlOrderBy: orderBy,
        OrderBy: JSON.stringify(this.orderBy),
        AccessType: this.AccessType
      })
      .subscribe(
        data => {
          this._router.navigate(['reports']);
        },
        err => {
          console.log('err===', err);
        }
      );
  }

  rmCols(col, val) {
    if (this.colsType === 'add') {
      this.cols = {};
      // this.colsType = "remove";
    }
    this.colsType = 'remove';
    if (!this.cols[col.Name]) {
      this.cols[col.Name] = col.Name;
    } else {
      delete this.cols[col.Name];
    }
  }
  setCols(val) {
    if(val){
      Object.keys(this.columns).map((v,i)=>{
        if(this.columns[i]['Name'] == this.cols[this.columns[i]['Name']])
          this.columns[i]['Visible'] = !this.columns[i]['Visible'];
      });
    }else{
      Object.keys(this.columns).map((v,i)=>{
        if(this.columns[i]['Name'] == this.cols[this.columns[i]['Name']])
          this.columns[i]['Visible'] = !this.columns[i]['Visible'];
      });
    }
    // this.columns = {...this.columns,...this.cols}
    this.cols = {};
  }
   addCols(col, val) {
    if (this.colsType === 'remove') {
      this.cols = {};
      // this.colsType = "add";
    }
    this.colsType = 'add';
    if (!this.cols[col.Name]) {
      this.cols[col.Name] = col.Name;
    } else {
      delete this.cols[col.Name];
    }
  }
}
