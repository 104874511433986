import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-config',
  templateUrl: './dashboard-config.component.html',
  styleUrls: ['./dashboard-config.component.css']
})
export class DashboardConfigComponent implements OnInit {
  listOfDashboardObj: ListOfDashBoardObj;
  dashBoardForm: DashBoardForm;
  showSpinner: Boolean = true;
  dashBoardData: Array<any> = [];
  ListOfDashBoard = [];

  constructor(
    private dashBoardService: DashboardService,
    private router: Router) { }

  ngOnInit() {
    this.resetDashBoardForm();
    this.resetListOfDashBoardObj();
    this.getAllDashBoardConfig();
  }

  getAllDashBoardConfig() {
    this.resetDashBoardForm();
    this.dashBoardService.getDashBoardConfig().subscribe(data => {
      this.showSpinner = false;
      this.dashBoardData = data;
      this.dashBoardData.map((obj, i) => {
        this.dashBoardForm['ListOfDashBoard'].push(obj);
      });
    });
  }

  onSubmit() {
    this.dashBoardForm.ListOfDashBoard.forEach(i => {
      this.ListOfDashBoard.push({
        DashBoardId: i.DashBoardId,
        IsDisplay: i.IsDisplay
      });
    });

    this.dashBoardService.addDashBoardConfig({
        ListOfDashBoard: this.ListOfDashBoard
      })
      .subscribe(data => {
        this.getAllDashBoardConfig();
      });
    this.dashBoardForm.ListOfDashBoard = [];
    this.ListOfDashBoard = [];
    this.router.navigate(['home']);

  }

  resetForm() {
    this.resetDashBoardForm();
    this.resetListOfDashBoardObj();
  }
  resetListOfDashBoardObj() {
    this.listOfDashboardObj = {
      IsDisplay: ''
    };
  }
  resetDashBoardForm() {
    this.dashBoardForm = {
      ListOfDashBoard: []
    };
  }
}

declare class ListOfDashBoardObj {
  IsDisplay: '';
}

declare class DashBoardForm {
  ListOfDashBoard: Array<any>;
}
