import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';
@Injectable()
export class ContractService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) {
  }

  addContract(form) {
    return this.apiService.post('api/contract/create', form);
  }

  contractById(contractId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/contract/getbyid?Id=${contractId}&MasterType=${q.type}&`);
  }

  reNew(form) {
    return this.apiService.post(`api/contract/renew`,form);
  }

  getSubscriptions(contractId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/contract/subscriptions?Id=${contractId}&`);
  }

  updateContract(form) {
    return this.apiService.put('api/contract/edit', form);
  }
}
