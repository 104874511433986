import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  @Input() productData: any;
  productForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit() {
     this.productForm = this.fb.group({
      ProductId: null,
      Name: '',
      IsActive: '',
      Code: '',
      Description: ''
    });
    this.productForm.patchValue(this.productData);
  }

}
