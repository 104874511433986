import { QuoteStatusModel } from './../../../model/quoteStatusModel/quote-status';
import { VariableService } from './../../../services/VariableService/variable.service';
import { AutocompleteModel } from './../../../model/sharedModel/autocomplete/autocomplete-model';
import { MasterLookupService } from './../../../services/common/master-lookup/master-lookup.service';
import { OpportunityService } from './../../../services/opportunity/opportunity.service';
import { ViewContainerRef, ViewChild, ElementRef } from '@angular/core';
import { QuoteService } from '../../../services/quote/quote.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { QuoteConversionComponent } from '../quote-conversion/quote-conversion.component';
import { GlobalService } from '../../../services/global-Service/global.service';


declare var $: any;

@Component({
  selector: 'app-quote-status',
  templateUrl: './quote-status.component.html',
  styleUrls: ['./quote-status.component.css']
})
export class QuoteStatusComponent implements OnInit {
  @Input() quoteStatusData: any;
  @ViewChild('content') content: any;
  @Input() convertedQuoteData: any;
  opportunityCloseModel: AutocompleteModel[];
  quoteStageModel: QuoteStatusModel[];
  statusValue = 'Mark Status As Complete';
  currentStatus: any;
  quoteStatusForm: UntypedFormGroup;
  closeValue: any;
  @ViewChild('widthIdentifier')
  widthIdentifier: ElementRef;
  taileWidth=0;
  constructor(private quoteService: QuoteService,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private opportunityService: OpportunityService,
    private masterLookupService: MasterLookupService,
    private variableService: VariableService,
    private globalService: GlobalService,

  ) {
    this.quoteStageModel = [];
    this.currentStatus = {};
  }

  ngOnInit() {
    this.quoteStatusForm = this.fb.group({
      ConversionStatus: '',
    });
    this.getQuoteStatusList(this.convertedQuoteData.QuoteID);
  }
  calWidth(data){
    let width = this.widthIdentifier.nativeElement.offsetWidth;
      if(data){
        this.taileWidth = width/data.length;
      }
  }
  getAllQuoteStatus() {
    this.quoteService.getQuoteStatus().subscribe(data => {
      this.quoteStageModel = data;
      this.quoteStatusData = data;
      this.calWidth(data);    
    });
  }

  getclosedStage() {
    this.masterLookupService.getDropdownList('oppor Conversion').subscribe(data => {
      this.opportunityCloseModel = data;
    });
  }


  getQuoteStatusList(quoteId) {
    this.quoteService.getQuoteByIdStatus(quoteId).subscribe(data => {
      this.quoteStatusData = data;
      this.quoteStageModel = data;
      this.calWidth(data);    
    });
  }

  onSelect(value) {
    this.closeValue = value;
  }

  setCurrentStatus(status) {
    this.currentStatus = status;
    this.currentStatusName();
  }

  currentStatusName() {
    if (this.currentStatus.QuoteStage === 'Current') {
      this.statusValue = 'Mark As Current Status';
    } else if (this.currentStatus.QuoteStage === 'Completed') {
      this.statusValue = 'Mark As Current Status';
    } else if (
      this.currentStatus.QuoteStage === 'Pending' &&
      this.currentStatus.IsLast === 'No'
    ) {
      this.statusValue = 'Mark As Current Status';
    }
    if (this.currentStatus.IsLast === 'Yes') {
      this.statusValue = 'Mark Status As Complete';
    }
  }

  setCompleteStatus() {
    let responseMessage = [];
    this.quoteService.createQuoteStatus(this.currentStatus).subscribe(data => {
      responseMessage = data.ErrorMessages;
      if (responseMessage[1] === '0') {
        this.globalService.showChangeStage();
      }
      this.getAllQuoteStatus();
    });
  }

  openStatusContent() {
    if (this.currentStatus.IsLast === 'Yes') {
      this.getclosedStage();
      this.modalService.open(this.content, { size: 'sm', backdrop: 'static', keyboard: false });
    } else {
      this.setCompleteStatus();
    }
  }

  openConvertedQuote() {
    if (this.currentStatus.IsLast === 'Yes') {
      const modalRef = this.modalService.open(QuoteConversionComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.convertedQuoteData = this.convertedQuoteData;
    }
    this.setCompleteStatus();
    this.getAllQuoteStatus();
  }

  saveCloseStatus() {
    const q: any = this.variableService.getSearchKeys();
    this.opportunityService.createOpportunityCloseStatus({
      ConversionStatus: this.closeValue,
      OpportunityId: q.paramId,
    }).subscribe(data => { });
  }


  onSubmit() {
    if (this.closeValue === '1') {
      this.saveCloseStatus();
    } else {
      this.saveCloseStatus();
      this.openConvertedQuote();
    }
    $('#btnCancel').trigger('click');
    this.resetForm();
  }

  resetForm() {
    this.quoteStatusForm.reset();
  }
}
