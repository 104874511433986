import { LogCallService } from './../../../services/common/activity-page/LogCall/log-call.service';
import { EventEmitter } from '@angular/core';
import { AutocompleteService } from './../../../services/autocomplete/autocomplete.service';
import { Component, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-new-call-log',
  templateUrl: './new-call-log.component.html',
  styleUrls: ['./new-call-log.component.css']
})
export class NewCallLogComponent implements OnInit {
  @Output() getData = new EventEmitter();
  callLogForm: UntypedFormGroup;
  NameRealtedType: any;
  RelatedToType: any;
  EditNew: any;
  contactLeadFilterOption: any[];
  relatedTypeFilterOption: any[];
  contactLeadValue: any; contactLeadText: any;
  RelatedText: any; RelatedValue: any;
  selectedName = 'Contact';
  selectedRelatedType = 'Account';
  NameTypeList = this.globalService.NameTypeList;

  RelatedTypeList = this.globalService.RelatedTypeList;

  constructor(
    private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    public activeModal: NgbActiveModal,
    private callLogService: LogCallService,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.callLogForm = this.fb.group({
      CallLogId: '',
      Comments: '',
      RelatedToId: '',
      UserId: '',
      ContactLeadId: '',
      Subject: '',
      NameType: '',
      RelatedType: '',
    });
    this.getEditNewName();
  }


  getEditNewName() {
    if (this.callLogForm.value.CallLogId === null) {
      this.EditNew = 'New';
    } else {
      this.EditNew = 'Edit';
    }
  }


  getRealtedTypeList(value) {
    const relatedValue = this.callLogForm.value.RelatedType;
    if (relatedValue !== '') {
      this.autocompleteService.getAllRelatedTypeList(relatedValue, value.data).subscribe(
        res => {
          this.relatedTypeFilterOption = res;
        },
        err => {
          console.log('errr====', err);
        });
    }
  }

  setAutocompleteRelatedType(data) {
    const relatedTo = data.data.option.value.Value;
    this.callLogForm.controls['RelatedToId'].setValue(relatedTo);
  }

  getLeadContactList(value) {
    const optionValue = this.callLogForm.value.NameType;
    if (optionValue !== '') {
      this.autocompleteService.getLeadContactList(optionValue, value.data).subscribe(
        res => {
          this.contactLeadFilterOption = res;
        },
        err => {
          console.log('errr====', err);
        });
    }
  }

  setcontactLeadType(data) {
    const contactLeadTo = data.data.option.value.Value;
    this.callLogForm.controls['ContactLeadId'].setValue(contactLeadTo);
  }

  onSubmit(form: UntypedFormGroup) {
    const logCallName = form.value.Subject;
    let responseMessage = [];
    this.callLogService.addCallLogs(form.value).subscribe(data => {
      responseMessage = data.ErrorMessages;
      if (responseMessage[1] === '0') {
        this.globalService.showSuccess({ masterName: 'Task', name: logCallName });
      }
      this.getData.emit();
      this.callLogForm.reset();
      this.activeModal.close();
    });
  }
}
