<div class="tab_title">
    <h3 class="page_title pull-left">
        <span class="menu_icon CRMMileStones glyphicon glyphicon-flag">
        </span>
        Milestones ({{milestone.length}})
    </h3>
    <div class="buttonHeader pull-right">
        <div class="buttonNew" (click)="openMilestone()">New
        </div>
    </div>
</div>

<p>&nbsp;</p>

<div class="table-container" style="border-top: 1px solid #ccc;">
    <div class="row">
        <div class="col-md-12 col-xs-12 col-lg-12 col-sm-12 mb-10">
            <ngx-datatable #milestonestable class="material" [sorts]="[{prop: 'Name', dir: 'asc'}, {prop: 'Title', dir: 'asc'}]"
                [headerHeight]="30" [limit]="10" [columnMode]="'force'" [footerHeight]="30" [rowHeight]="'auto'"
                [trackByProp]="'updated'" [rows]="milestone">
                <ngx-datatable-column name="Milestone Name">
                    <ng-template let-row="row" ngx-datatable-cell-template let-value="value" class="text-right">
                        <a (click)="gotoDetailPage(row.MilesStoneId)" href="javascript:void(0)">{{row.MileStoneName}}</a>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column prop="HoursBudget" name="Total Hours Balance">
                </ngx-datatable-column>
                <ngx-datatable-column name="Deadline">
                    <ng-template let-row="row" ngx-datatable-cell-template let-value="value" class="text-right">
                        <a>{{row.DeadLine | date:'dd-MM-yyyy' }}</a>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
</div>