import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from './../ApiService/api.service';
import { VariableService } from './../VariableService/variable.service';

@Injectable()
export class LeadService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) {
  }

  addLead(form) {
    return this.apiService.post('api/lead/create', form);
  }

  leadEditById(leadId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/lead/getbyid?Id=${leadId}&MasterType=${q.type}&`);
  }

  updateLead(form) {
    return this.apiService.put('api/lead/edit', form);
  }


  getLeadStatusList() {
    return this.apiService.get('api/leadstatus/GetLeadStatusNamesList' + '?prefixText=' + '&Id=' + '&');
  }

  getByIdStatus(leadId) {
    return this.apiService.get(
      'api/leadstatus/getbyid' + '?Id=' + leadId + '&'
    );
  }

  getLeadStatusAll() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/leadstatus/getall?id=${q.paramId}&`);
  }

  createLeadStatus(form) {
    return this.apiService.put('api/leadstatus/create', form);
  }

  /**Create Lead Converstion Status */
  addLeadConversionStatus(form) {
    return this.apiService.post('api/leadConversionStatus/create', form);
  }

}
