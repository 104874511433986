<div class="body_inner">
  <!-- <div class="row"> -->
      <!-- <div class="col-md-3" *ngFor="let kpichart of kpi_dashboards;let i = index">
          <div class="card_holder" [ngClass]="DataColors[i]">
              <h2>{{kpichart.chartName}}</h2>
              <p>{{kpichart.dataTable[1]}}</p>
              <div class="img_cont">
              </div>
            </div> 
        </div>
  </div>
  <div class="row nutralise_margin">
    <div class="box_layout col-md-12" *ngFor="let chart of dashboardsFull">
      <div class="chart_cont">
        <google-chart [data]="chart" (chartError)="error($event)"></google-chart>
      </div>
    </div>
  </div> -->
  <!-- <div class="row nutralise_margin justify-content-md-center"> -->
    <!-- <div class="box_layout col-md-6" *ngFor="let chart of dashboardsHalf">
      <div class="chart_cont">
        <google-chart [data]="chart" (chartError)="error($event)"></google-chart>
      </div>
    </div> -->
    <ng-container *ngIf="loading">
      <ng-container *ngIf="!dashBoard">
        <powerbi-report [embedConfig]=embedConfig [cssClassName]="reportClass" [phasedEmbedding]="isEmbedded" [eventHandlers]="eventHandlersMap" ></powerbi-report>
      
      </ng-container> 
      <ng-container *ngIf="dashBoard">
        <powerbi-dashboard [embedConfig]=embedConfig
            [cssClassName]="reportClass"
            [eventHandlers]="eventHandlersMap"
        >
        </powerbi-dashboard>
      </ng-container>
    </ng-container>
  <!-- </div> -->
  <!-- <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader> -->
</div>