import { GlobalService } from './../../../services/global-Service/global.service';
import { AutocompleteModel } from './../../../model/sharedModel/autocomplete/autocomplete-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CaseService } from './../../../services/case/case.service';
import { MasterLookupService } from './../../../services/common/master-lookup/master-lookup.service';
import { AutocompleteService } from './../../../services/autocomplete/autocomplete.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-new-case',
  templateUrl: './new-case.component.html',
  styleUrls: ['./new-case.component.css']
})
export class NewCaseComponent implements OnInit {
  @Output() getData = new EventEmitter();
  public CaseStatusModel: AutocompleteModel[];
  public CaseTypeModel: AutocompleteModel[];
  public CaseOriginModel: AutocompleteModel[];
  public CasePriorityModel: AutocompleteModel[];
  public CaseReasonModel: AutocompleteModel[];

  caseForm: UntypedFormGroup;
  EditNew: any;
  caseFormData: any;

  employeeOwnerfilteredOptions: any[];
  employeeOwnerText: any; employeeOwnerValue: any;
  clientfilteredOptions: any[];
  clientText: any; clientValue: any;
  accountfilteredOptions: any[];
  accountText: any; accountValue: any;
  contactfilteredOptions: any[];
  contactText: any; contactValue: any;
  projectfilteredOptions: any[];
  projectText: any; projectValue: any;
  productfilteredOptions: any[];
  productText: any; productValue: any;

  constructor(
    private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private masterLookupService: MasterLookupService,
    private caseService: CaseService,
    private datePipe: DatePipe,
    public activeModal: NgbActiveModal,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.caseForm = this.fb.group({
      CaseId: null,
      OwnerId: '',
      CaseNumber: '',
      AccountId: '',
      ContactId: '',
      Status: '',
      Type: '',
      CaseOrigin: '',
      CaseReason: '',
      Product: '',
      Priority: '',
      Subject: '',
      Description: '',
      WebEmail: '',
      WebCompany: '',
      WebName: '',
      WebPhone: '',
      SLAViolation: '',
      PotentialLiability: '',
      EngineeringReqNumber: '',
      ProjectId: '',
      Client: '',
      CaseDate: new Date,
    });
    this.getCaseOriginList();
    this.getCasePriorityList();
    this.getCaseReasonList();
    this.getCaseStatusList();
    this.getCaseTypeList();
    this.getEditData();
    this.getEditNewName();
  }

  getEditNewName() {
    if (this.caseForm.value.CaseId === null) {
      this.EditNew = 'New';
      let userDetails = this.autocompleteService.getUserDetails();
      this.employeeOwnerText = userDetails.EmpName;
      this.employeeOwnerValue = userDetails.EmpID;
    } else {
      this.EditNew = 'Edit';
      this.caseForm.controls['CaseDate'].setValue(new Date(this.caseForm.value.CaseDate))
    }
  }

  getEditData() {
    if (this.caseFormData !== undefined) {

      this.caseForm.patchValue(this.caseFormData);

      this.employeeOwnerText = this.caseFormData.CaseOwnerName;
      this.employeeOwnerValue = this.caseFormData.OwnerId;

      this.clientText = this.caseFormData.ClientName;
      this.clientValue = this.caseFormData.Client;

      this.accountText = this.caseFormData.AccountName;
      this.accountValue = this.caseFormData.AccountId;

      this.contactText = this.caseFormData.ContactName;
      this.contactValue = this.caseFormData.ContactId;

      this.projectText = this.caseFormData.ProjectName;
      this.projectValue = this.caseFormData.ProjectId;

      this.productText = this.caseFormData.ProductName;
      this.productValue = this.caseFormData.Product;

    }
  }


  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.employeeOwnerfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteEmployee(data) {
    const ownerId = data.data.option.value.Value;
    this.caseForm.controls['OwnerId'].setValue(ownerId);
  }

  getClientList(value) {
    this.autocompleteService.getAllClientList(value.data).subscribe(
      res => {
        this.clientfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteClient(data) {
    const clientId = data.data.option.value.Value;
    this.caseForm.controls['Client'].setValue(clientId);
  }


  getAccountList(value) {
    this.autocompleteService.getAllAccountList(value.data).subscribe(
      res => {
        this.accountfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteAccount(data) {
    const accountId = data.data.option.value.Value;
    this.caseForm.controls['AccountId'].setValue(accountId);
  }


  getContactList(value) {
    const accountid = this.caseForm.value.AccountId;
    this.autocompleteService.getAllContactAccountList(value.data, accountid).subscribe(
      res => {
        this.contactfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteContact(data) {
    const contactId = data.data.option.value.Value;
    this.caseForm.controls['ContactId'].setValue(contactId);
  }

  getProjectList(value) {
    const clientId = this.caseForm.value.Client;
    this.autocompleteService.getProjectAccountList(value.data, clientId).subscribe(
      res => {
        this.projectfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteProject(data) {
    const projectId = data.data.option.value.Value;
    this.caseForm.controls['ProjectId'].setValue(projectId);
  }

  getProductList(value) {
    this.autocompleteService.getAllProductList(value.data).subscribe(
      res => {
        this.productfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteProduct(data) {
    const product = data.data.option.value.Value;
    this.caseForm.controls['Product'].setValue(product);
  }

  getCaseStatusList() {
    this.masterLookupService.getDropdownList('Case Status').subscribe(data => {
      this.CaseStatusModel = data;
    });
  }

  getCaseTypeList() {
    this.masterLookupService.getDropdownList('Case Type').subscribe(data => {
      this.CaseTypeModel = data;
    });
  }

  getCaseOriginList() {
    this.masterLookupService.getDropdownList('Case Origin').subscribe(data => {
      this.CaseOriginModel = data;
    });
  }

  getCaseReasonList() {
    this.masterLookupService.getDropdownList('Case Reason').subscribe(data => {
      this.CaseReasonModel = data;
    });
  }

  getCasePriorityList() {
    this.masterLookupService
      .getDropdownList('Case Priority')
      .subscribe(data => {
        this.CasePriorityModel = data;
      });
  }

  onSubmit(form: UntypedFormGroup) {
    const caseNo = form.value.CaseNumber;
    let responseMessage = [];
    this.caseForm.value.CaseDate = this.datePipe.transform(form.value.CaseDate, 'dd-MM-yyyy');
    if (form.value.CaseId == null) {
      this.caseService.addCase(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Case', name: caseNo });
        }
        this.getData.emit();
      });
      // Update Method for Case
    } else {
      this.caseService.updateCase(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Case', name: caseNo });
        }
        this.getData.emit();
      });
    }
    this.globalService.disposeToastr();
    this.activeModal.close();
    this.caseForm.reset();
  }
}
