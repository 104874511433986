import { Component, OnInit, Input } from '@angular/core';
import { CampaignService } from '../../../../services/campaigns/campaign.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-campaign-related',
  templateUrl: './campaign-related.component.html',
  styleUrls: ['./campaign-related.component.css']
})
export class CampaignRelatedComponent implements OnInit {
  @Input() searchKeys;
  campaigns: Array<any>;
  selectedPage: any = {};
  MasterType: any;
  constructor(private camapignService: CampaignService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.getAllCampaignType();
  }

  getAllCampaignType() {
    this.camapignService.getTypeCampaigns().subscribe(data => {
      this.campaigns = data;
    });
  }

  gotoDetailPage(id) {
    const route = 'campaignDetail/' + id;
    this.router.navigate([route]);
  }
}
