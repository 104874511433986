import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "../../../services/common/common/common.service";

@Component({
  selector: "app-reports",
  templateUrl: "./reports-edit.component.html",
  styleUrls: ["./reports-edit.component.css"]
})
export class ReportsEditComponent implements OnInit {
  reportPages: any[];
  reportId: string;
  columns: string[];
  selectedPage: any = null;
  columnsArray: any[];
  ReportName: string;
  AccessType: any;
  sqlOperators = [
    "Equals",
    "Not Equal To",
    "Greater than",
    "Less than",
    "Less or Equal",
    "Greater or Equal",
    "Contains",
    "Does Not Contain",
    "Start With"
  ];
  newOrderBy = {
    columnName: null,
    sortType: null
  };
  orderBy: any = [];
  editOrBy = false;
  showOrderBy = false;
  newFilter = {
    columnName: null,
    sqlOperator: null,
    sqlOperatorValue: null,
    value: null,
    valueIndicator: null
  };
  showFilter = false;
  editfil = false;
  editfilId: any;
  filter = [];
  editOrById: any;
  cols = {};
  colsType = 'add';
  constructor(
    private _cService: CommonService,
    private _activatedRoute: ActivatedRoute,
    public _router: Router
  ) {
    this._activatedRoute.params.subscribe(params => {
      // this.getReportsData(params.id);
      this.getReportData(params.id);
      this.reportId = params.id;
    });
  }

  ngOnInit() {
    this.columnsArray = [];
    this.getReportLookup();
    this.columns = [];
    this.orderBy = [];
  }

  editFilter(i) {
    this.newFilter = this.filter[i];
    this.editfil = true;
    this.editfilId = i;
    this.showFilter = true;
  }

  getReportData(id) {
    this._cService.getUserReport({ paramId: id }).subscribe(
      res => {
        this.ReportName = res[0].ReportName;
        this.selectedPage = res[0].ObjectType;
        const cArray = res[0].ReportColumns.split(",");
        const cA = [];
        const cF = [];
        cArray.map(a => {
          cA.push(a.replace("[", "").replace("]", ""));
        });
        const cFArray = JSON.parse(res[0].FilterQuery).FilterItems;
        cFArray.map(a => {
          a.columnName = a.columnName.replace("[", "").replace("]", "");
          cF.push(a);
        });
        this.orderBy = JSON.parse(res[0].OrderBy);
        this.filter = cF;
        this.columnsArray = cA;
        if (!res[0].AccessType) {
          this.AccessType = 0;
        } else {
          this.AccessType = 1;
        }
        this.getColumns();
      },
      err => {
        console.log("err", err);
      }
    );
  }

  getReportLookup() {
    this._cService.getReportLookup().subscribe(
      data => {
        this.reportPages = data;
      },
      err => {
        console.log("err===", err);
      }
    );
  }

  showAddFilter() {
    this.showFilter = true;
  }

  hideAddFilter() {
    this.showFilter = false;
  }

  editOrderBy(i) {
    this.newOrderBy = this.orderBy[i];
    this.editOrBy = true;
    this.editOrById = i;
    this.showOrderBy = true;
  }

  addOrderBy(obj) {
    if (!this.editOrBy) {
      this.orderBy.push(obj);
    } else {
      this.orderBy[this.editOrById] = obj;
    }

    this.showOrderBy = false;
    this.editOrById = null;
    this.editOrBy = false;
    this.newOrderBy = {
      columnName: null,
      sortType: null
    };
  }

  addFilter(obj) {
    switch (obj.sqlOperatorValue) {
      case "Start With":
        obj.sqlOperator = "LIKE";
        obj.value = obj.valueIndicator + "%";
        break;
      case "Equals":
        obj.sqlOperator = "=";
        obj.value = obj.valueIndicator;
        break;
      case "Not Equal To":
        obj.sqlOperator = "!=";
        obj.value = obj.valueIndicator;
        break;
      case "Greater than":
        obj.sqlOperator = ">";
        obj.value = obj.valueIndicator;
        break;
      case "Less than":
        obj.sqlOperator = "<";
        obj.value = obj.valueIndicator;
        break;
      case "Less or Equal":
        obj.sqlOperator = "<=";
        obj.value = obj.valueIndicator;
        break;
      case "Greater or Equal":
        obj.sqlOperator = ">=";
        obj.value = obj.valueIndicator;
        break;
      case "Contains":
        obj.sqlOperator = "LIKE";
        obj.value = "%" + obj.valueIndicator + "%";
        break;
      case "Does Not Contain":
        obj.sqlOperator = "NOT LIKE";
        obj.value = "%" + obj.valueIndicator + "%";
        break;
      default:
        obj.sqlOperator = obj.sqlOperator;
        break;
    }

    if (this.editfil) {
      this.filter[this.editfilId] = obj;
    } else {
      this.filter.push(obj);
    }
    this.newFilter = {
      columnName: null,
      sqlOperator: null,
      sqlOperatorValue: null,
      value: null,
      valueIndicator: null
    };
    this.editfil = false;
    this.editfilId = null;
    this.hideAddFilter();
  }

  deleteFilter(i) {
    this.filter.splice(i, 1);
  }

  deleteOrBy(i) {
    this.orderBy.splice(i, 1);
  }

  showOrdBy() {
    this.showOrderBy = true;
  }

  getColumns() {
    if (this.selectedPage !== null) {
      this._cService.getReportObjectColumn(this.selectedPage).subscribe(
        data => {
          // console.log('this.columnsArray',this.columnsArray);
          // debugger;
          this.columns = data.map((v)=> {return {"Name":v,'Value':v,'Visible': this.columnsArray.indexOf(v)>-1?false:true}});
          // this.columns.forEach(col => {
          //   if (this.columnsArray.indexOf(col) === -1) {
          //     this.columnsArray.
          //   }
          // });
          // this.columnsArray = this.columnsArray.filter(col => {
          //   return this.columns.indexOf(col) !== -1;
          // });
        },
        err => {
          console.log("err", err);
        }
      );
    }
  }

  toggleColumns(column) {
    const index = this.columnsArray.indexOf(column);
    if (index !== -1) {
      this.columnsArray.splice(index, 1);
    } else {
      this.columnsArray.push(column);
    }
  }

  setLogic() {
    if (this.filter.length > 1) {
      let l: any;
      // this.tableFilters.splice(0, 1);
      this.filter.forEach((filter, i) => {
        if (i > 0) {
          l += "AND " + (i + 1);
        } else {
          l = "1 ";
        }
      });
      // if (this.tableFilters.length > 1) {
      // this.applyFilterLogic(l);
      // }
      return l;
    } else {
      return "";
    }
    // this.addLogic.emit();
  }

  saveReport() {
    // const filter = {
    //   FilterItems: this.filter,
    //   logic: this.setLogic()
    // };
    const columnNameChanged = [];
    this.filter.map(a => {
      a.columnName = `[${a.columnName}]`;
      columnNameChanged.push(a);
    });
    const filter = { FilterItems: columnNameChanged, logic: this.setLogic() };
    const c = [];
    this.columnsArray.map(a => {
      c.push(`[${a}]`);
    });
    let orderBy = "";
    this.orderBy.map((a, i) => {
      if (this.orderBy.length === i + 1) {
        orderBy += `[${a.columnName}] ${a.sortType}`;
      } else {
        orderBy += `[${a.columnName}] ${a.sortType}, `;
      }
    });
    this._cService
      .createUserReport({
        ReportID: this.reportId,
        ReportName: this.ReportName,
        ObjectType: this.selectedPage,
        Mode: 1,
        ReportColumns: c.join(),
        FilterQuery: JSON.stringify(filter),
        Filter: filter,
        sqlOrderBy: orderBy,
        OrderBy: JSON.stringify(this.orderBy),
        AccessType: this.AccessType
      })
      .subscribe(
        data => {
          const route = `reportsDetail/${this.reportId}`;
          this._router.navigate([route]);
        },
        err => {
          console.log("err===", err);
        }
      );
  }

  rmCols(col, val) {
    if (this.colsType === 'add') {
      this.cols = {};
      // this.colsType = "remove";
    }
    this.colsType = 'remove';
    if (!this.cols[col.Name]) {
      this.cols[col.Name] = col.Name;
    } else {
      delete this.cols[col.Name];
    }
  }
  setCols(val) {
    if(val){
      Object.keys(this.columns).map((v,i)=>{
        if(this.columns[i]['Name'] == this.cols[this.columns[i]['Name']])
          this.columns[i]['Visible'] = !this.columns[i]['Visible'];
      });
    }else{
      Object.keys(this.columns).map((v,i)=>{
        if(this.columns[i]['Name'] == this.cols[this.columns[i]['Name']])
          this.columns[i]['Visible'] = !this.columns[i]['Visible'];
      });
    }
    // this.columns = {...this.columns,...this.cols}
    this.cols = {};
  }
   addCols(col, val) {
    if (this.colsType === 'remove') {
      this.cols = {};
      // this.colsType = "add";
    }
    this.colsType = 'add';
    if (!this.cols[col.Name]) {
      this.cols[col.Name] = col.Name;
    } else {
      delete this.cols[col.Name];
    }
  }
}
