import { Component, OnInit } from '@angular/core';
import { AutocompleteService } from '../../services/autocomplete/autocomplete.service';
import { ProductService } from '../../services/product/product.service';
import { MachineService } from '../../services/machine/machine.service';
import { VariableService } from '../../services/VariableService/variable.service';
import { LocationService } from '../../services/location/location.service';
import { GlobalService } from '../../services/global-Service/global.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-stock-take',
  templateUrl: './stock-take.component.html',
  styleUrls: ['./stock-take.component.css']
})
export class StockTakeComponent implements OnInit {
  selectedOption:any;
  filteredOptions:any;
  MachinefilteredOptions:any;
  autoCompleteText:any;
  autoCompleteValue:any;
  locationId:any;
  machineId:any;
  stockArr:any;
  countedDate = new Date();
  tableHeader:any;
  enableMachine=true;
  fetchProducts=false;
  constructor(private autocompleteService:AutocompleteService, 
    private productService:ProductService,
    private machineService:MachineService,
    private variableService:VariableService,
    private locationService:LocationService,
    private datePipe: DatePipe,
    private globalService:GlobalService) { }

  ngOnInit(): void {
    this.variableService.setSearchKeys({
      paramId: this.machineId,
      type: 'CRMMachines'
    });
    this.tableHeader = {
      'sn': {
        name: 'S.No.',
        type:'dynamic',
        className:'number_small'
      },
      'ProductName':{
        name: 'Product Name',
        value:'ProductName',
        type:'label',
        className:'name_long'
      },
      'RowNo':{
        name: 'Row No',
        value:'RowNo',
        type:'label',
        className:'number_short'
      },
      'Capacity':{
        name: 'Capacity',
        value:'Capacity',
        type:'label-number',
        className:'number_short'
      },
      'OpeningBalance':{
        name: 'Opening Balance',
        value:'OpeningBalance',
        type:'label-number',
        className:'number_largeshort'
      },
      'CurrentQty':{
        name: 'Current',
        value:'CurrentQty',
        type:'number',
        className:'number_short'
      },
      'FilledQty':{
        name: 'Filled',
        value:'FilledQty',
        type:'number',
        className:'number_short'
      },
      'EmptiedQty':{
        name: 'Emptied',
        value:'EmptiedQty',
        type:'number',
        className:'number_short'
      }
    }
  }
  setAutoComplete(data: any) {
    this.locationId= data.data.option.value.Value;
    this.fiteredMachineData();
  }

  fiteredData(value) {
    this.selectedOption = {};
    this.locationService.getLocationList().subscribe(
      res => {
        this.filteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }


  setAutoMachineComplete(data: any) {
    this.machineId = data.data.option.value.Value;
    this.variableService.setSearchKeys({
      paramId: this.machineId,
      type: 'CRMMachines'
    });
  }
  updateMachine(e){
    this.variableService.setSearchKeys({
      paramId: this.machineId,
      type: 'CRMMachines'
    });
  }
  fiteredMachineData() {
    this.selectedOption = {};
    this.locationService.getTypeLocationMachines({id:this.locationId,type:'CRMLocations'}).subscribe(
      res => {
        this.MachinefilteredOptions = res;
        this.enableMachine=false;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }

  searchStock(){
    this.fetchProducts=true;    
    let finalData ={
      LocationId:this.locationId,
      MachineId:this.machineId,
      DateTime:this.datePipe.transform(this.countedDate, 'yyyy-MM-dd'),
      MasterType:'CRMStockTake'
    }
    this.machineService.editPlanograms(finalData).subscribe(
      res=>{
        this.stockArr = res.ListofProducts;
        this.fetchProducts=false; 
      }
    )
  }

  addData(data){
    let finalData ={
      LocationId:this.locationId,
      MachineId:this.machineId,
      countedOn:this.datePipe.transform(this.countedDate, 'yyyy-MM-dd'),
      ListofProducts:data
    }
    this.machineService.getStockDetails(finalData).subscribe(
      res =>{
        if (res.ErrorMessages[1] === '0') {
          this.globalService.showSuccessData('Stock Taking is completed.');
          this.stockArr = null;

        }
      }
    )
  }
}
