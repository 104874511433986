<div class="bg_white_margin_top">
    <table class="table table-striped table-bordered">
        <thead>
            <tr>
                <th scope="col">Sl No.</th>
                <th *ngFor="let column of columnName" title={{column}}><b>{{column}}</b></th>
                <th scope="col" class="colBtn"><b>Edit</b></th>
                <th scope="col" class="colBtn"><b>Delete</b></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let table of tableData; let i=index;" class="custom_table_row">
                <td scope="row">{{i+1}}</td>
                <td *ngFor="let column of columnRowName" title={{table[column]}}>{{table[column]}}</td>
                <td>
                    <span class="edit_pen" (click)="edit.next({data:table})">
                        <span class="glyphicon glyphicon-pencil"></span>
                    </span>
                </td>
                <td>
                    <span class="del_bin" (click)="onDelete.next({data:table,content:content})">
                        <span class="glyphicon glyphicon-trash"></span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>