
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OpportunityStage } from '../../../model/opportunityStageModel/opportunity-stage';
import { Component, OnInit, Input, ViewContainerRef, ViewChild, ElementRef  } from '@angular/core';
import { OpportunityService } from '../../../services/opportunity/opportunity.service';
import { OpportunityConversionComponent } from '../opportunity-conversion/opportunity-conversion.component';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { GlobalService } from '../../../services/global-Service/global.service';


declare var $: any;

@Component({
  selector: 'app-opportunity-stage',
  templateUrl: './opportunity-stage.component.html',
  styleUrls: ['./opportunity-stage.component.css']
})

export class OpportunityStageComponent implements OnInit {
  public currentStatus: any;
  @Input() convertOpportunityData: any;
  public opportunityStageModel: OpportunityStage[];
  public opportunityCloseModel: AutocompleteModel[];
  public stageValue = 'Mark As Current Stage';
  closeValue: any;
  opporId: any;
  responseMessage: any;
  closeOpportunityForm: UntypedFormGroup;
  @Input() opportunitystageData: any;
  @ViewChild('widthIdentifier')
  widthIdentifier: ElementRef;
  taileWidth=0;

  constructor(
    private opportunityService: OpportunityService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private masterLookupService: MasterLookupService,
    private globalService: GlobalService,
  ) {
    this.currentStatus = {};
    this.opportunityStageModel = [];
  }

  ngOnInit() {
    this.closeOpportunityForm = this.fb.group({
      ConversionStatus: '',
    });
    this.getOpportuniyStage(this.convertOpportunityData.OpportunityId);
  }
  calWidth(data){
    let width = this.widthIdentifier.nativeElement.offsetWidth;
      if(data){
        this.taileWidth = width/data.length;
      }
  }
  completeStatus() {
    this.opportunityService.createOpportunityStatus(this.currentStatus).subscribe(data => {
      this.responseMessage = data.ErrorMessages;
      if (this.responseMessage[1] === '0') {
        this.globalService.showChangeStage();
      }
      this.getAllOpporStage();
    });
  }

  getAllOpporStage() {
    this.opportunityService.getAllOpportunityStage().subscribe(data => {
      this.opportunityStageModel = data;
      this.opportunitystageData = data;
      this.calWidth(data);    
    });
  }
  setCompleteStatus(content) {
    if (this.currentStatus.IsLast === 'Yes') {
      this.getclosedStage();
      this.modalService.open(content, { size: 'sm', backdrop: 'static' });
      this.getAllOpporStage();
    } else {
      this.completeStatus();
    }
  }

  getOpportuniyStage(opportunityId) {
    this.opportunityService.getByIdStatus(opportunityId).subscribe(data => {
      this.opportunityStageModel = data;
      this.opportunitystageData = data;
      this.opporId = opportunityId;
      this.calWidth(data);    
    });
  }
  setCurrentStatus(status) {
    this.currentStatus = status;
    this.currentStageName();
  }

  currentStageName() {
    if (this.currentStatus.Status === 'Current') {
      this.stageValue = 'Mark Stage As Complete';
    } else if (this.currentStatus.Status === 'Completed') {
      this.stageValue = 'Mark As Current Stage';
    } else if (this.currentStatus.Status === 'Pending' && this.currentStatus.IsLast === 'No') {
      this.stageValue = 'Mark As Current Stage';
    } if (this.currentStatus.IsLast === 'Yes') {
      this.stageValue = 'Select Closed Stage';
    }
  }



  openConvertedOpportunity() {
    if (this.currentStatus.IsLast === 'Yes') {
      const modalRef = this.modalService.open(OpportunityConversionComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.convertOpportunityData = this.convertOpportunityData;
    }
    this.completeStatus();
    this.getAllOpporStage();
  }


  /******************************Opportunity Close Status************************************/
  onSelect(value) {
    this.closeValue = value;
  }

  getclosedStage() {
    this.masterLookupService.getDropdownList('oppor Conversion').subscribe(data => {
      this.opportunityCloseModel = data;
    });
  }

  saveCloseStatus() {
    this.opportunityService.createOpportunityCloseStatus({
      ConversionStatus: this.closeValue,
      OpportunityId: this.opporId,
    }).subscribe(data => { });
  }

  onSubmit() {
    if (this.closeValue === '1') {
      this.saveCloseStatus();
      this.getAllOpporStage();
    } else {
      this.saveCloseStatus();
      this.openConvertedOpportunity();
      this.getAllOpporStage();
    }
    $('#btnCancel').trigger('click');
    this.resetForm();
  }

  resetForm() {
    this.closeOpportunityForm.reset();
  }
}
