import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-milestone-detail',
  templateUrl: './milestone-detail.component.html',
  styleUrls: ['./milestone-detail.component.css']
})
export class MilestoneDetailComponent implements OnInit {

  milestoneForm: UntypedFormGroup;
  @Input() milestoneData: any;

  public TaskTemplate: AutocompleteModel[];
  constructor(
    private fb: UntypedFormBuilder,
    private configService: ConfigurationService
  ) { }

  ngOnInit() {
    this.milestoneForm = this.fb.group({
      MilesStoneId: null,
      OwnerID: '',
      ProjectID: '',
      ParentMileStone: '',
      UserId: '',
      Kickoff: '',
      DeadLine: '',
      MileStoneName: '',
      Description: '',
      Alias: '',
      HoursBudget: '',
      ExpenseBudget: '',
      TaskTemplateID: '',
      ProjectClientID: '',
      MilestoneType: '',
      ProjectClientName: '',
      Project: '',
      MilestoneOwnerName: '',
      ParentMilestone: '',
    });
    this.milestoneForm.patchValue(this.milestoneData);
    this.getAllTaskTemplateList();
  }

  getAllTaskTemplateList() {
    this.configService.getTaskTemplateList().subscribe(data => {
      this.TaskTemplate = data;
    });
  }

}
