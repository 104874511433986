import { Component, OnInit } from '@angular/core';

function remove(item: string, list: string[]) {
  if (list.indexOf(item) !== -1) {
    list.splice(list.indexOf(item), 1);
  }
}

@Component({
  selector: 'app-dragdrop',
  templateUrl: './dragdrop.component.html',
  styleUrls: ['./dragdrop.component.css']
})
export class DragdropComponent implements OnInit {
  public availableBoxes: any = [
    { Name: 'Box 1', Id: 1, },
    { Name: 'Box 2', Id: 2, },
    { Name: 'Box 3', Id: 3, },
    { Name: 'Box 4', Id: 4, },
    { Name: 'Box 5', Id: 5, },
    { Name: 'Box 6', Id: 6, },
    { Name: 'Box 7', Id: 7, },
  ];

  dropzone1 = [
  ];

  dropzone2 = [
  ];

  currentBox?: string;

  move(box: string, toList: string[]): void {
    remove(box, this.availableBoxes);
    remove(box, this.dropzone1);
    remove(box, this.dropzone2);

    toList.push(box);
  }

  constructor() { }

  ngOnInit() {
  }

}
