import { AutocompleteService } from './../../../services/autocomplete/autocomplete.service';
import { ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';
import { Component, OnInit } from '@angular/core';
import { ResourceDetailService } from '../../../services/projectTask/ResourceDetail/resource-detail.service';
import { GlobalService } from '../../../services/global-Service/global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-task-resource',
  templateUrl: './task-resource.component.html',
  styleUrls: ['./task-resource.component.css']
})
export class TaskResourceComponent implements OnInit {

  resourceForm: ResourceForm;
  autoCompleteText: any;
  autoCompleteValue: any;
  filteredOptions: any[];
  listOfResourceObj: ListOfResourceObj;
  resourceDetail: Array<any> = [];
  columnName:Array<any> = ['Name','Work Type'];
  tableData:Array<any> = [];

  constructor(
    private resourceService: ResourceDetailService,
    private variableService: VariableService,
    private autocompleteService: AutocompleteService,
    private router: Router,
    private globalService: GlobalService,
    private modalService:NgbModal,
  ) {
  }

  ngOnInit() {
    this.reset();
    this.resourceForm.ResourceTable.push(this.listOfResourceObj);
    this.getAllResourceDetail();
  }
  openTaskResourceForm(rfqModel){
    this.modalService.open(rfqModel, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  addFieldValue(i) {
    this.resetListOfResourceObj();
    const obj = this.resourceForm.ResourceTable[i + 1];
    if (!obj) {
      this.resourceForm.ResourceTable.push(this.listOfResourceObj);
    }
    this.resetListOfResourceObj();
  }
  deleteFieldValue(i: number) {
    if (this.resourceForm.ResourceTable.length === 1) {
      return false;
    } else {
      this.resourceForm.ResourceTable.splice(i, 1);
      return true;
    }
  }

  fiteredData(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.resourceForm.ResourceTable[value.indexValue].filteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }

  setAutoComplete(data: any) {
    const value = data.data.option.value.Value;
    this.resourceForm.ResourceTable[data.index].ResourceID = value;
  }

  onSubmit() {
    const q: any = this.variableService.getSearchKeys();
    this.resourceForm.ProjectTaskId = q.paramId;

    this.resourceForm.ResourceTable = this.resourceForm.ResourceTable.filter(
      obj => {
        return obj.ResourceID || obj.WorkType;
      });

    this.resourceService.addResourceDetail(this.resourceForm).subscribe(data => {
      // this.globalService.showDefaultUpdate({ masterName: 'Project' });
      this.modalService.dismissAll();
      this.getAllResourceDetail();
    });
    // this.router.navigate(['projecttask']);
  }
  getAllResourceDetail() {
    this.reset();
    this.tableData = [];
    this.resourceService.getResourceDetail().subscribe(data => {
      this.resourceDetail = data;
      this.resourceDetail.map((obj) => {
        let newObj = {};
        newObj['Name'] = obj.EmployeeName;
        newObj['Work Type'] = obj.WorkType;
        obj.autoCompleteText = obj.EmployeeName;
        obj.autoCompleteValue = obj.ResourceID;
        this.resourceForm['ResourceTable'].push(obj);
        this.tableData.push(newObj);
      });
      this.resourceForm['ResourceTable'].push(this.listOfResourceObj);
      this.resetListOfResourceObj();
    });
  }

  resetListOfResourceObj() {
    this.listOfResourceObj = {
      ResourceID: '',
      WorkType: '',
    };
  }
  resetResourceForm() {
    this.resourceForm = {
      ProjectTaskId: '',
      ResourceTable: []
    };
  }
  reset() {
    this.resetResourceForm();
    this.resetListOfResourceObj();
  }
  resetForm() {
    this.resetResourceForm();
    this.resetListOfResourceObj();
    this.resourceForm.ResourceTable.push(this.listOfResourceObj);
  }
}


declare class ListOfResourceObj {
  ResourceID: '';
  WorkType: '';
}

declare class ResourceForm {
  ProjectTaskId: String;
  ResourceTable: Array<any>;
}
