import { GlobalService } from './../../../services/global-Service/global.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { RolePermissionService } from '../../../services/rolePermission/role-permission.service';

@Component({
  selector: 'app-role-permission',
  templateUrl: './role-permission.component.html',
  styleUrls: ['./role-permission.component.css']
})
export class RolePermissionComponent implements OnInit {

  public RoleNameList: AutocompleteModel[];
  showSpinner: Boolean = true;
  rolePermissionForm: RolePermissionForm;
  listOfRolePermissionObj: ListOfRolePermissionObj;
  rolePermissionData: any;

  constructor(
    private autoCompleteService: AutocompleteService,
    private rolePermissionService: RolePermissionService,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.resetRolePermissionForm();
    this.resetListOfRolePermissionObj();
    this.getAllRoleNameList();
  }

  getAllRoleNameList() {
    this.autoCompleteService.getRoleNameList().subscribe(data => {
      this.RoleNameList = data;
      this.showSpinner = false;
    });
  }

  onRoleName(roleId) {
    this.showSpinner = true;
    this.rolePermissionData = [];
    this.rolePermissionService.editRolePermissionById(roleId).subscribe(data => {
      this.rolePermissionData = data;
      this.showSpinner = false;
      // const rolePermissionForm: RolePermissionForm = { ClientId: '', RoleID: '', RolePermissionList: [] };
      this.rolePermissionForm.RolePermissionList = [];
      this.rolePermissionData.map((obj) => {
        this.rolePermissionForm['RolePermissionList'].push(obj);
      });
      // this.rolePermissionForm = rolePermissionForm;
    });
  }

  onSubmit() {
    this.rolePermissionForm.RolePermissionList = this.rolePermissionForm.RolePermissionList.filter(
      obj => {
        return obj.Create === true || obj.Display === true || obj.Alter === true || obj.Delete === true ||
          obj.Print || obj.Email || obj.Export;
      }
    );
    this.rolePermissionService.addRolePermission(this.rolePermissionForm).subscribe(data => {
      this.globalService.showDefaultUpdate({ masterName: 'Role Permission' });
    });
    this.resetForm();
  }

  resetForm() {
    this.resetRolePermissionForm();
    this.resetListOfRolePermissionObj();
  }
  resetListOfRolePermissionObj() {
    this.listOfRolePermissionObj = {
      Create: '',
      Display: '',
      Alter: '',
      Delete: '',
      Print: '',
      Email: '',
      Export: '',
      PageID: '',
      PageName: '',
    };
  }
  resetRolePermissionForm() {
    this.rolePermissionForm = {
      ClientId: '',
      RoleID: '',
      RolePermissionList: [],
    };
  }
}



declare class ListOfRolePermissionObj {
  Create: '';
  Display: '';
  Alter: '';
  Delete: '';
  Print: '';
  Email: '';
  Export: '';
  PageID: '';
  PageName: '';
}

declare class RolePermissionForm {
  ClientId: '';
  RoleID: '';
  RolePermissionList: Array<any>;
}
