import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';
@Injectable()
export class RelatedEmailService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  getRelatedEmail() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/relatedEmail/getbyid?Id=${q.paramId}&MasterType=${q.type}&`);
  }

}
