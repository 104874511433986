import { GlobalService } from './../../../../services/global-Service/global.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MasterLookupService } from './../../../../services/common/master-lookup/master-lookup.service';
import { ProjectTaskService } from '../../../../services/projectTask/ProjectTask-Service/project-task.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AutocompleteModel } from '../../../../model/sharedModel/autocomplete/autocomplete-model';
import { DatePipe } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-project-task-related',
  templateUrl: './project-task-related.component.html',
  styleUrls: ['./project-task-related.component.css']
})
export class ProjectTaskRelatedComponent implements OnInit {
  @Input() searchKeys;
  StatusList: AutocompleteModel[];

  projectTasks: Array<any> = [];
  selected = [];
  selectedUpdateData = [];
  projectTaskPropertyForm: UntypedFormGroup;
  ProjectTaskPropertyList = [];
  constructor(
    private projectTaskService: ProjectTaskService,
    private fb: UntypedFormBuilder,
    public router: Router,
    public modalService: NgbModal,
    private masterlookupService: MasterLookupService,
    public datePipe: DatePipe,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.projectTaskPropertyForm = this.fb.group({
      ProjectTaskId: '',
      Status: '',
      IsFinished: '',
      Tonnage: '',
      CompletionOn: '',
      ClosedOn: '',
      ProjectTaskName: '',
      ListOfProjectTaskProperty: new UntypedFormGroup({
        ProjectTaskID: new UntypedFormControl(),
      }),
    });
    this.getAllProjectTasks();
  }

  getAllProjectTasks() {
    this.projectTaskService.getProjectTaskType().subscribe(data => {
      this.projectTasks = data;
    });
  }

  getCaseStatusList() {
    this.masterlookupService.getDropdownList('Project Task Status').subscribe(data => {
      this.StatusList = data;
    });
  }

  select({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.selectedUpdateData = this.selected;
  }

  onSelectUpdate(openProperties) {
    if (this.selectedUpdateData === undefined || this.selectedUpdateData.length === 0) {
      this.globalService.showError();
    } else {
      this.getCaseStatusList();
      for (let i = 0; i < this.selectedUpdateData.length; i++) {
        this.projectTaskPropertyForm.patchValue({
          ProjectTaskId: this.selectedUpdateData[i].ProjectTaskId,
          Status: this.selectedUpdateData[i].Stage,
          Tonnage: this.selectedUpdateData[i].Tonnage,
          ClosedOn: this.selectedUpdateData[i].ClosedOn,
          IsFinished: this.selectedUpdateData[i].IsFinished,
          CompletionOn: this.selectedUpdateData[i].CompletionOn,
          ProjectTaskName: this.selectedUpdateData[i].ProjectTaskName,
        });
      }
      this.ProjectTaskPropertyList = [];
      this.selectedUpdateData.forEach(i => {
        this.ProjectTaskPropertyList.push({
          ProjectTaskID: i.ProjectTaskId,
        });
      });
      this.modalService.open(openProperties, { size: 'sm', backdrop: 'static', keyboard: false });
    }
  }

  onSubmit(form: UntypedFormGroup) {
    let responseMessage = '';
    this.projectTaskPropertyForm.value.CompletionOn = this.datePipe.transform(form.value.CompletionOn, 'yyyy-MM-dd');
    this.projectTaskPropertyForm.value.ListOfProjectTaskProperty = this.ProjectTaskPropertyList;

    this.projectTaskService.createProjectTaskProperty(form.value).subscribe(data => {
      responseMessage = data.ErrorMessages[1];
      if (responseMessage === '0') {
        this.globalService.showDefaultUpdate({ masterName: 'Project Task' });
      }
      this.getAllProjectTasks();
    });
    $('#btnCancel').trigger('click');
  }

  gotoDetailPage(id) {
    const route = 'projecttaskDetail/' + id;
    this.router.navigate([route]);
  }
}
