import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { ResourceService } from '../../../services/resource/resource.service';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.css']
})
export class ResourceComponent implements OnInit {
  showSpinner: Boolean = true;
  resourceData: any = {};
  selectedPage: any = {};
  DetailOn = true;
  constructor(private route: ActivatedRoute,
    private resourceService: ResourceService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {

      this.resourceService.resourceById(params.id).subscribe(data => {
        this.resourceData = data;
        this.showSpinner = false;
      });

      this.variableService.setSearchKeys({
        paramId: params.id,
      });
    });
  }
}
