import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable()
export class VariableService {
  public searchKeys: object = {};
  public messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();


  constructor() { }

  setSearchKeys(q) {
    this.searchKeys = q;
  }

  getSearchKeys() {
    return this.searchKeys;
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }
}
