<div class="custom_nav_pills_cont">

    <div class="wizard" id="wizard">
        <ul class="nav nav-pills nav-wizard" #widthIdentifier>
            <li *ngFor="let status of quoteStatusData" placement="bottom" tooltip="{{status.QuoteStatusName}}" [ngStyle]="{'width':taileWidth!==0?taileWidth+'px':'inherit'}">
                <a data-toggle="tab" class={{status.QuoteStage}} [ngClass]="{'active': toggle}" (click)="setCurrentStatus(status)">
                    <span class="tooltipClass">{{status.QuoteStatusName}}</span>
                </a>
            </li>
        </ul>

        <button type="button" [ngClass]="currentStatus.QuoteStage + ' btn btn-default stage_btn'" (click)="openStatusContent()">
            <span>{{statusValue}}</span>
        </button>

    </div>
</div>



<ng-template #content let-c="close" let-d="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-header">
        <h4 class="modal-title text-center">Close This Quote</h4>
    </div>
    <form [formGroup]="quoteStatusForm">
        <div class="modal-body">
            <div class="extra-pad">
                <div class="">
                    <div class="form-group">
                        <label for="text">Stage</label>
                        <select class="form-control" formControlName="ConversionStatus" (change)="onSelect($event.target.value)">
                            <option *ngFor="let stage of opportunityCloseModel" value={{stage.Value}}>{{stage.Text}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')
                ">Cancel</button>
            <button type="button" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
        </div>
    </form>
</ng-template>