import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { DeleteModalComponent } from './../../../../../common/delete-modal/delete-modal.component';
import { GlobalService } from '../../../services/global-Service/global.service';

declare var $: any;

@Component({
  selector: 'app-task-template',
  templateUrl: './task-template.component.html',
  styleUrls: ['./task-template.component.css']
})
export class TaskTemplateComponent implements OnInit {
  @Input() deleteData: any;
  showSpinner: Boolean = true;
  taskTemplateForm: TaskTemplateForm;
  listOfTemplateObj: ListOfTemplateObj;
  taskTemplate: Array<any> = [];
  responseMessage: any;
  taskTemplateData: any;
  editName: any;
  columnName = [];
  columnRowName = [];

  constructor(
    private configService: ConfigurationService,
    private modalService: NgbModal,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.resetTaskTemplateForm();
    this.resetListOfTemplateObj();
    this.columnName = [];
    this.columnRowName = [];
    this.getAllTaskList();
    this.taskTemplateForm.ListOfTasks.push(this.listOfTemplateObj);
  }

  addFieldValue(i) {
    this.resetListOfTemplateObj();
    const obj = this.taskTemplateForm.ListOfTasks[i + 1];
    if (!obj) {
      this.taskTemplateForm.ListOfTasks.push(this.listOfTemplateObj);
    }
    this.resetListOfTemplateObj();
  }

  numberOnly(event): boolean {
    const keycode = (event.which) ? event.which : event.keyCode;
    if (!(keycode === 8 || keycode === 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      const parts = event.srcElement.value.split('.');
      if (parts.length > 1 && keycode === 46) {
        return false;
      }
      return true;
    }
  }

  deleteFieldValue(i: number) {
    if (this.taskTemplateForm.ListOfTasks.length === 1) {
      return false;
    } else {
      this.taskTemplateForm.ListOfTasks.splice(i, 1);
      return true;
    }
  }

  getAllTaskList() {
    this.configService.getTaskTemplate().subscribe(data => {
      this.taskTemplate = data;
      this.columnName = ['Template Name', 'Type'];
      this.columnRowName = ['TemplateName', 'Type'];
      this.showSpinner = false;
    });
  }

  edit(editData) {
    this.reset();
    const taskTemplateId = editData.data.TemplateId;
    this.editName = editData.data.TemplateName;
    this.configService.getTaskTemplateById(taskTemplateId).subscribe(data => {
      this.taskTemplateData = data;
      this.taskTemplateForm.TemplateName = data.TemplateName;
      this.taskTemplateForm.Type = data.Type;

      this.taskTemplateForm.TemplateId = data.TemplateId;
      this.taskTemplateData.ListOfTasks.map((obj) => {
        this.taskTemplateForm['ListOfTasks'].push(obj);
      });
      this.taskTemplateForm['ListOfTasks'].push(this.listOfTemplateObj);
      this.resetListOfTemplateObj();
    });
  }
  onDelete(data) {
    this.deleteData = ({ id: data.data.TemplateId, name: data.data.TemplateName, page: 'ProjectTaskTemplate' });
    const modalRef = this.modalService.open(DeleteModalComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.deleteData = this.deleteData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAllTaskList();
    });
  }

  onSubmit() {
    const templateName = this.taskTemplateForm.TemplateName;
    this.taskTemplateForm.ListOfTasks = this.taskTemplateForm.ListOfTasks.filter(
      obj => {
        return obj.SerialNo || obj.TaskName || obj.PercentageCompleted || obj.Weightage;
      }
    );
    if (this.taskTemplateForm.TemplateId === '') {
      this.configService.addTaskTemplate(this.taskTemplateForm).subscribe(data => {
        this.responseMessage = data.ErrorMessages;
        if (this.responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Template', name: templateName });
        }
        this.showSpinner = true;
        this.getAllTaskList();
      });
    } else {
      this.configService.editTaskTemplate(this.taskTemplateForm).subscribe(data => {
        this.responseMessage = data.ErrorMessages;
        if (this.responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Template', name: templateName });
        }
        this.showSpinner = true;
        this.getAllTaskList();
      });
    }
    this.globalService.disposeToastr();
    this.resetForm();
  }

  resetListOfTemplateObj() {
    this.listOfTemplateObj = {
      SerialNo: '',
      TaskName: '',
      PercentageCompleted: '',
      Weightage: '',
    };
  }
  resetTaskTemplateForm() {
    this.taskTemplateForm = {
      TemplateId: '',
      TemplateName: '',
      Type: '',
      ListOfTasks: []
    };
  }

  reset() {
    this.resetTaskTemplateForm();
    this.resetListOfTemplateObj();
  }

  resetForm() {
    this.resetTaskTemplateForm();
    this.resetListOfTemplateObj();
    this.taskTemplateForm.ListOfTasks.push(this.listOfTemplateObj);
  }
}


declare class ListOfTemplateObj {
  SerialNo: '';
  TaskName: '';
  PercentageCompleted: '';
  Weightage: '';
}

declare class TaskTemplateForm {
  TemplateId: '';
  TemplateName: String;
  Type: '';
  ListOfTasks: Array<any>;
}
