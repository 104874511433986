<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">Edit Navigation Items</h4>
</div>
<div class="modal-body">
  <div class="">
    <p class="modalparagraph">Personalize your nav bar for this app</p>
    <p>NAVIGATION ITEMS ({{menuItems?.length}}) </p>

    <div class="menuiconName" *ngFor="let menu of menuItems ; let i=index"  >
      <span class="barIcon"><i class="fas fa-bars"></i></span>
      <!-- <span class='{{menu.name.replace(" CRM ", " ")}} menu_icon' title='{{menu.name.replace(" CRM ", " ")}}'> </span> -->
      <span class="menu_heading ml-2">{{menu.name.replace("CRM", "").replace("_",'')}}</span>
      <div class="col-md-4 pull-right" >
        <input type="textbox" class="form-control" [(ngModel)]="menu.UserMenuName">
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSubmit()">Save</button>
  </div>