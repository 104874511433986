import { VariableService } from './../../../services/VariableService/variable.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { UserReportService } from '../../../services/report/userReport/user-report.service';
import { ActivatedRoute } from '@angular/router';
// import * as jsPDF from 'jspdf';

import {
  convertToCSV,
  exportToExcel,
  converToPdf
} from '../../../../../common/utils';
import { Page } from '../../../../../models/page';

declare var $: any;

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.css']
})
export class ReportsDetailComponent implements OnInit {
  reports: any[];
  columnName: any[];
  reportName: any;
  loading = false;
  public paramId: string;
  page = new Page();
  suppressPaging: Boolean = false;
  scrollTimeout: any;


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.reports = [...this.reports];
    $('#common-table .datatable-scroll').width('100%');
  }

  constructor(
    private _userReportService: UserReportService,
    private _activatedRoute: ActivatedRoute,
    private variableService: VariableService
  ) {
    this.columnName = [];
    this.reports = [];
    this.page.size = 15;
    this.page.pageNumber = 0;
    this._activatedRoute.params.subscribe(params => {
      this.paramId = params.id;
      this.getReportsData(params.id);
    });
  }

  ngOnInit() {
  }

  exportTo(type) {
    this._userReportService
      .getPdfData(this.paramId, this.page.totalElements, 1)
      .subscribe(res => {
        const name = 'report_' + new Date().toLocaleString();
        switch (type) {
          case 'csv':
            convertToCSV(res.Data, name);
            break;
          case 'excel':
            exportToExcel(res.Data, name);
            break;
          case 'pdf':
            const columns = [];
            this.columnName.map(a => {
              columns.push({ title: a.toUpperCase(), dataKey: a });
            });
            converToPdf(columns, res.Data, `${name}.pdf`);
            break;
          default:
            return;

        }

      });
  }

  email() { }

  getReportsData(reqOptions) {
    this.variableService.currentMessage.subscribe(message =>
      this.reportName = message);
    // this.loading = true;

    this._userReportService
      .getReportDetail(this.paramId, reqOptions.PageSize || this.page.size, reqOptions.PageNumber || this.page.pageNumber || 1, '')
      .subscribe(
        res => {
          const columns = [];
          if (res.Data.length > 0) {
            const columnsIn = res.Data[0];
            for (const key in columnsIn) {
              // columns.push(key);
              columns.push({
                Visible:true,
                Name: key,
                Value:key})
            }
          } else {
            console.log('No columns');
          }
          this.columnName = columns;
          this.reports = res.Data;

          // this.page = {
          //   totalElements: res.TotalRecords,
          //   totalPages: Math.ceil(
          //     res.TotalRecords / this.page.size
          //   ),
          //   size: this.page.size,
          //   pageNumber: this.page.pageNumber
          // };

          this.page.totalElements = res.TotalRecords;
          if (this.page.pageNumber === 0) {
            this.page.pageNumber = 0;
          } else {
            this.page.pageNumber = this.page.pageNumber - 1;
          }
          // this.page.pageNumber = this.page.pageNumber - 1;

          // this.loading = false;

        },
        err => {
          console.log('rrr', err);
        }
      );
  }

  setPage(pageInfo: any) {
    if (!this.suppressPaging) {
      this.page.pageNumber = pageInfo.offset + 1;
      this.page.size = pageInfo.limit;
      this.page.totalElements = pageInfo.count;
      this.getReportsData(this.paramId);
    }
  }

  // handleScroll() {
  //   this.suppressPaging = true;

  //   if (this.scrollTimeout) {
  //     clearTimeout(this.scrollTimeout);
  //   }

  //   this.scrollTimeout = setTimeout(() => {
  //     this.suppressPaging = false;
  //   }, 100);
  // }

  onActivate(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
    }
  }
}
