<div class="form-container p-5">
    <div *ngIf="model && !report" class="padding20 white-bg">
        <div class="row">
            <div class="col-12">
                <div class="btn-cont">
                    <button (click)="initReport()" class="btn btn-primary pull-right">Preview</button>
                    <!-- <button (click)="updateForm()" class="btn btn-primary pull-right">Update</button>
                    <button routerLink="/application/{{model._id}}" class="btn btn-primary pull-right"
                        style="margin-right:3px;">View <i class="fa fa-external-link"></i></button> -->
                </div>
            </div>
        </div>
        <div class="spacer30"></div>
        <div class="row">
            <div class="col-4">
                <h3>Select Theme Color</h3>
                <div class="row">
                    <label class="col-8">Background Color</label>
                    <input class="colorpick col-4" type="color" name="bgColor" value="#ffffff" [(ngModel)]="model.theme.bgColor">
                </div>
                <div class="row">
                    <label class="col-8">Text Color</label>
                    <input class="colorpick col-4" type="color" name="textColor" [(ngModel)]="model.theme.textColor">
                </div>

                <h3>Select Form Fields</h3>
                <!--a draggable element-->
                <div class="auto-height">
                    <div class="fields" *ngFor="let item of fieldModels">
                        <div class="field pointer shadow-bg" [dndDraggable]="item" [dndEffectAllowed]="'copy'"
                            (dndEnd)="onDragEnd($event)">
                            <h4>
                                <!-- class=" pull-left" -->
                                <span > 
                                    <i class="fa" [ngClass]="item.icon"></i>
                                </span>
                                <span class="ml-5">{{item.label}}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                
    
            </div>
            <div class="col-8">
                <div [style.backgroundColor]="model.theme.bgColor" [style.color]="model.theme.textColor" class="mt-5 p-4">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Application Name</label>
                        <input class="form-control" type="text" [(ngModel)]="model.name" />
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Application Description</label>
                        <input class="form-control" type="text" [(ngModel)]="model.description" />
                    </div>

                    <!--a dropzone-->
                    <!--to allow dropping content that is not [dndDraggable] set dndAllowExternal to true-->
                    <section dndDropzone (dndDragover)="onDragover($event)" (dndDrop)="onDrop($event,model.attributes)"
                        class="dndDropArea" [ngClass]="{'empty':model.attributes.length == 0}"
                        >
        
                        <div class="field" *ngFor="let item of model.attributes;let i= index;" (dndStart)="onDragStart($event)"
                            (dndCanceled)="onDragCanceled($event)" (dndMoved)="onDragged(item, model.attributes, 'move')"
                            [dndEffectAllowed]="'all'" [dndDraggable]="item">
                            <div class="row form-group">
                                <div class="col-1" dndHandle><i class="fa fa-ellipsis-v dndHandle" ></i></div>
                                <div class="col-7">
                                    <div *ngIf="item.type=='text'">
                                        <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                                        <input type="text" class="form-control" id="{{item.name}}"
                                            placeholder="{{item.placeholder}}">
                                    </div>
                                    <div *ngIf="item.type=='email'">
                                        <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                                        <input type="email" class="form-control" id="{{item.name}}"
                                            placeholder="{{item.placeholder}}">
                                    </div>
                                    <div *ngIf="item.type=='phone'">
                                        <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                                        <input type="text" class="form-control" id="{{item.name}}"
                                            placeholder="{{item.placeholder}}">
                                    </div>
                                    <div *ngIf="item.type=='number'">
                                        <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                                        <input type="number" class="form-control" id="{{item.name}}" min="{{item.min}}"
                                            max="{{item.max}}" placeholder="{{item.placeholder}}">
                                    </div>
                                    <div *ngIf="item.type=='date'">
                                        <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                                        <input type="date" class="form-control" id="{{item.name}}"
                                            placeholder="{{item.placeholder}}">
                                    </div>
                                    <div *ngIf="item.type=='datetime-local'">
                                        <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                                        <input type="datetime-local" class="form-control" id="{{item.name}}"
                                            placeholder="{{item.placeholder}}">
                                    </div>
                                    <div *ngIf="item.type=='textarea'">
                                        <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                                        <textarea class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}">
                                </textarea>
                                    </div>
                                    <div *ngIf="item.type=='file'">
                                        <label>{{item.label}} (File can't be uploaded right now)</label> <label
                                            *ngIf="item.required" class="text-danger">*</label>
                                        <input type="file" class="form-control" id="{{item.name}}"
                                            placeholder="{{item.placeholder}}">
                                        <small class="form-text text-danger">(File can't be uploaded right now)</small>
                                    </div>
                                    <div *ngIf="item.type=='paragraph'">
                                        <p class="form-text">{{item.placeholder}}</p>
                                    </div>
                                    <div *ngIf="item.type=='autocomplete'">
                                        <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                                        <select class="form-control" id="{{item.name}}">
                                            <option *ngFor="let v of item.values" [value]="v.value">{{v.label}}</option>
                                        </select>
                                        <!-- <small class="form-text text-muted">{{item.description}}</small> -->
                                    </div>
                                    <div *ngIf="item.type=='checkbox'">
                                        <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                                        <div *ngFor="let v of item.values" class="inline-form-group">
                                            <label class="cust-check-bx">
                                                <input type="checkbox" [value]="v.value" name="{{item.name}}"
                                                    (click)="toggleValue(v)"> {{v.label}}
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div *ngIf="item.type=='radio'">
                                        <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                                        <div *ngFor="let v of item.values" class="inline-form-group">
                                            <label class="cust-check-bx">
                                                <input type="radio" [value]="v.value" name="{{item.name}}"> {{v.label}}
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div *ngIf="item.type=='button'" class="btn-cont">
                                        <input type="{{item.subtype}}" (click)="submit()" value="{{item.label}}"
                                            class="btn btn-primary" id="{{item.name}}">
                                    </div>
                                </div>
                                <div class="col-4">
                                    <span (click)="item.toggle=false" *ngIf="item.toggle" class="pull-right">
                                        <i   class="fa fa-chevron-up "></i>
                                    </span>
                                    <span *ngIf="!item.toggle" (click)="item.toggle=true" class="pull-right">
                                        <i class="fa fa-chevron-down "></i>
                                    </span>
                                    <span (click)="removeField(i)" class="mr-3 pull-right">
                                        <i  class="fa fa-trash "></i>
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="item.toggle" class="toggle-Wrapper">
                                <div class="form-group">
                                    <label>Required</label>
                                    <span *ngIf="item.required" (click)="item.required=false">
                                        <i  class="fa fa-toggle-on red"></i>
                                    </span>
                                    <span *ngIf="!item.required" (click)="item.required=true">
                                        <i  class="fa fa-toggle-off"></i>
                                    </span>
                                    <span class="pull-right ucfirst">{{item.type}}</span>
                                </div>
                                <div class="form-group">
                                    <label>Label</label>
                                    <input class="form-control" type="text" [(ngModel)]="item.label" />
                                </div>
                                <div class="form-group">
                                    <label>Name</label>
                                    <input class="form-control" type="text" [(ngModel)]="item.name" />
                                </div>
                                <div class="form-group">
                                    <label>Placeholder</label>
                                    <input class="form-control" type="text" [(ngModel)]="item.placeholder" />
                                </div>
                                <div class="form-group">
                                    <label>Regex</label>
                                    <input class="form-control" type="text" [(ngModel)]="item.regex" />
                                </div>
                                <div *ngIf="item.regex" class="form-group">
                                    <label>Error text</label>
                                    <input class="form-control" type="text" [(ngModel)]="item.errorText" />
                                </div>
        
                                <!-- number -->
                                <div *ngIf="item.type=='number'" class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Min</label>
                                            <input class="form-control" type="text" [(ngModel)]="item.min" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Max</label>
                                            <input class="form-control" type="text" [(ngModel)]="item.max" />
                                        </div>
                                    </div>
                                </div>
        
                                <!-- radio || checkbox || autocomplete -->
                                <div *ngIf="item.type=='radio'|| item.type=='checkbox' || item.type=='autocomplete'">
                                    <div class="row">
                                        <div class="col-5">
                                            <div class="form-group">
                                                <label>Label</label>
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="form-group">
                                                <label>Value</label>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">
                                                <label>Action</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngFor="let i of item.values; let valueIndex=index;" class="row">
                                        <div class="col-5">
                                            <div class="form-group">
                                                <input class="form-control" type="text" [(ngModel)]="i.label" />
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="form-group">
                                                <input class="form-control" type="text" [(ngModel)]="i.value" />
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">
                                                <label (click)="item.values.splice(valueIndex,1)">remove</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <div class="form-group">
                                                <input class="form-control" type="text" [(ngModel)]="value.label" />
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="form-group">
                                                <input class="form-control" type="text" [(ngModel)]="value.value" />
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">
                                                <label (click)="addValue(item.values)">Add</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
        
        
        
                            </div>
                        </div>
                        <div *ngIf="model.attributes.length == 0" class="text-center mt-5">
                            <h3 class="b-color"> Please Drag and Drop Items Here. </h3>
                        </div>
                        <!--optional placeholder element for dropzone-->
                        <!--will be removed from DOM on init-->
                        <div class="dndPlaceholder" dndPlaceholderRef>
                        </div>
                    </section>
                    
                </div>
            </div>
        </div>
    </div>
    








    <div *ngIf="report" class="padding20 white-bg">
        <div class="row">
            <div class="col-12 btn-cont">
                <button (click)="backToForm()" class="btn btn-primary pull-right">
                    << Back</button>
            </div>
            <div class="col-12 spacer30"></div>
            <!-- if records found -->
            <div *ngIf="reports?.length > 0" class="col-12">
                <div *ngFor="let records of reports" class="report-block">
                    <div *ngFor="let record of records.attributes">
                        <div *ngIf="record.type !='button' && record.type !='paragraph'" class="row">
                            <div class="col-4">{{record.label}}</div>
                            <div class="col-8">{{record.value}}</div>
                        </div>
                    </div>
                </div>
                <!-- <pre>{{reports|json}}</pre> -->
            </div>
            <!-- if no records found -->
            <!-- <div *ngIf="reports?.length == 0" class="col-12 btn-cont text-center">
                No response found
            </div> -->
    
        </div>
    
    </div>
    
    <div *ngIf="model && report" class="container marginT60  marginB30" style="max-width:500px;">
    
        <div class="row" style="padding:30px;" [style.backgroundColor]="model.theme.bgColor"
            [style.color]="model.theme.textColor">
            <div class="col-12">
                <h2>{{model.name}}</h2>
            </div>
            <div class="col-12">
                <p>{{model.description}}</p>
            </div>
            <div *ngIf="success" class="col-12">
                <div class="form-group">
                    <label>Your form has been submitted.</label>
                </div>
            </div>
            <form class="ftheme">
                <div *ngIf="!success" class="col-12">
                    <div *ngFor="let item of model.attributes" class="form-group">
                        <div *ngIf="item.type=='text'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <input type="text" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}"
                                [(ngModel)]="item.value" [required]="item.required" [ngModelOptions]="{standalone: true}"
                                autocomplete="off">
                        </div>
                        <div *ngIf="item.type=='email'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <input type="email" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}"
                                [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                        </div>
                        <div *ngIf="item.type=='phone'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <input type="email" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}"
                                [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                        </div>
                        <div *ngIf="item.type=='number'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <input type="number" class="form-control" id="{{item.name}}" min="{{item.min}}"
                                max="{{item.max}}" placeholder="{{item.placeholder}}" [(ngModel)]="item.value"
                                [ngModelOptions]="{standalone: true}" autocomplete="off">
                        </div>
                        <div *ngIf="item.type=='date'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <input type="date" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}"
                                [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                        </div>
                        <div *ngIf="item.type=='datetime-local'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <input type="datetime-local" class="form-control" id="{{item.name}}"
                                placeholder="{{item.placeholder}}" [(ngModel)]="item.value"
                                [ngModelOptions]="{standalone: true}" autocomplete="off">
                        </div>
                        <div *ngIf="item.type=='textarea'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <textarea class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}"
                                [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                </textarea>
                        </div>
                        <div *ngIf="item.type=='file'">
                            <label>{{item.label}} (File can't be uploaded right now)</label> <label *ngIf="item.required"
                                class="text-danger">*</label>
                            <input type="file" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}"
                                [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                            <small class="form-text text-danger">(File can't be uploaded right now)</small>
                        </div>
                        <div *ngIf="item.type=='paragraph'">
                            <p class="form-text">{{item.placeholder}}</p>
                        </div>
                        <div *ngIf="item.type=='autocomplete'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <select class="form-control" id="{{item.name}}" [(ngModel)]="item.value"
                                [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let v of item.values" [value]="v.value">{{v.label}}</option>
                            </select>
                            <!-- <small class="form-text text-muted">{{item.description}}</small> -->
                        </div>
                        <div *ngIf="item.type=='checkbox'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <div *ngFor="let v of item.values" class="inline-form-group">
                                <label class="cust-check-bx">
                                    <input type="checkbox" [value]="v.value" name="{{item.name}}" (click)="toggleValue(v)">
                                    {{v.label}}
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="item.type=='radio'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <div *ngFor="let v of item.values" class="inline-form-group">
                                <label class="cust-check-bx">
                                    <input type="radio" [value]="v.value" name="{{item.name}}" [(ngModel)]="item.value"
                                        [ngModelOptions]="{standalone: true}"> {{v.label}}
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="item.type=='button'" class="btn-cont">
                            <input type="{{item.subtype}}" (click)="submit()" value="{{item.label}}" class="btn btn-primary"
                                id="{{item.name}}">
                        </div>
                    </div>    
                </div>
            </form>
        </div>
    </div>
    <!-- <button (click)="show=1" class="btn btn-primary mt-8 pull-left">Show developer data</button>

    <pre *ngIf="show==1 && model">
          {{model|json}}
      </pre> -->
</div>