import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { QuoteProductService } from '../../../services/quoteProduct/quote-product.service';
import { VariableService } from '../../../services/VariableService/variable.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-quote-line-item',
  templateUrl: './quote-line-item.component.html',
  styleUrls: ['./quote-line-item.component.css']
})
export class QuoteLineItemComponent implements OnInit {
  @Input() searchKeys;
  @Input() quoteProduct: Array<any> = [];
  @Output() getData = new EventEmitter();
  autoCompleteText = '';
  autoCompleteValue: any;
  filteredOptions: any[];
  selectedOption: any;
  amount: any;
  key: any;
  ListOfQuoteProductData = [];
  taxAmount: any;
  responseMessage: any;
  quoteProductForm: QuoteProductForm;
  listOfQuoteProductObj: ListOfQuoteProductObj;
  constructor(
    private quoteProductService: QuoteProductService,
    private variableService: VariableService,
    public activeModal: NgbActiveModal,
    private autocompleteService: AutocompleteService,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.resetQuoteProductForm();
    this.resetListOfQuoteProductObj();
    this.listOfquoteProductdata();
  }

  addFieldValue(i) {
    this.resetListOfQuoteProductObj();
    const obj = this.quoteProductForm.ListOfQuoteProduct[i + 1];
    if (!obj) {
      this.quoteProductForm.ListOfQuoteProduct.push(this.listOfQuoteProductObj);
    }
    this.resetListOfQuoteProductObj();
  }

  deleteFieldValue(i: number) {
    if (this.quoteProductForm.ListOfQuoteProduct.length === 1) {
      return false;
    } else {
      this.quoteProductForm.ListOfQuoteProduct.splice(i, 1);
      return true;
    }
  }

  listOfquoteProductdata() {
    this.quoteProduct.forEach((ob, i) => {
      ob.autoCompleteText = ob.ProductName;
      ob.autoCompleteValue = ob.ProductId;
      this.quoteProductForm['ListOfQuoteProduct'].push(ob);
    });
    this.quoteProductForm['ListOfQuoteProduct'].push(this.listOfQuoteProductObj);
  }

  get quoteFormData() {
    return this.quoteProductForm.ListOfQuoteProduct;
  }

  numberOnly(event): boolean {
    const keycode = (event.which) ? event.which : event.keyCode;
    if (!(keycode === 8 || keycode === 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      const parts = event.srcElement.value.split('.');
      if (parts.length > 1 && keycode === 46) {
        return false;
      }
      return true;
    }
  }

  setAutoComplete(data: any) {
    const value = data.data.option.value.Value;
    this.quoteProductForm.ListOfQuoteProduct[data.index].ProductId = value;
  }

  fiteredData(value) {
    this.selectedOption = {};
    this.autocompleteService.getAllProductList(value.data).subscribe(
      res => {
        this.quoteProductForm.ListOfQuoteProduct[value.indexValue].filteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }

  calculate(i) {
    this.calculateTaxAmount(i);
    this.calculateTotalValue(i);
    this.calculateAmount(i);
  }

  calculateAmount(i) {
    const value = this.quoteFormData[i];
    let totalamount = (value.Quantity * value.Rate) * ((100 - value.Discount) / 100);
    this.amount = totalamount;
    if (!isNaN(totalamount)) {
      totalamount = parseFloat(totalamount.toFixed(2));
      this.quoteProductForm.ListOfQuoteProduct[i].Amount = totalamount;
    }
  }

  calculateTaxAmount(i) {
    const value = this.quoteFormData[i];
    let taxamount = this.amount * ((value.GST) / 100);
    this.taxAmount = taxamount;
    if (!isNaN(taxamount)) {
      taxamount = parseFloat(taxamount.toFixed(2));
      this.quoteProductForm.ListOfQuoteProduct[i].TaxAmount = taxamount;
    }
  }

  calculateTotalValue(i) {
    let totalvalue = this.amount + this.taxAmount;
    if (!isNaN(totalvalue)) {
      totalvalue = parseFloat(totalvalue.toFixed(2));
      this.quoteProductForm.ListOfQuoteProduct[i].TotalValue = totalvalue;
    }
  }
  onSubmit() {
    const q: any = this.variableService.getSearchKeys();
    this.quoteProductForm.QuoteID = q.paramId;
    this.quoteProductForm.ListOfQuoteProduct = this.quoteProductForm.ListOfQuoteProduct.filter(
      obj => {
        return obj.ProductId || obj.Quantity || obj.Rate || obj.Discount || obj.Amount || obj.TotalValue || obj.TaxAmount || obj.GST;
      }
    );

    this.quoteProductForm.ListOfQuoteProduct.forEach(i => {
      this.ListOfQuoteProductData.push({
        SerialNo: this.ListOfQuoteProductData.length + 1,
        ProductId: i.ProductId,
        Quantity: i.Quantity,
        Rate: i.Rate,
        Discount: i.Discount,
        Amount: i.Amount,
        TotalValue: i.TotalValue,
        TaxAmount: i.TaxAmount,
        GST: i.GST,
      });
    });

    this.quoteProductService.addQuote({
      ListOfQuoteProduct: this.ListOfQuoteProductData,
      QuoteID: q.paramId
    }).subscribe(data => {
      this.responseMessage = data.ErrorMessages;
      if (this.responseMessage[1] === '0') {
        this.globalService.showProdutAdded();
      }
      this.getData.emit();
    });
    this.activeModal.dismiss();
    this.resetForm();
  }

  resetForm() {
    this.resetQuoteProductForm();
    this.resetListOfQuoteProductObj();
  }

  resetListOfQuoteProductObj() {
    this.listOfQuoteProductObj = {
      SerialNo: '',
      ProductId: '',
      Quantity: '',
      Rate: '',
      Discount: '',
      Amount: null,
      TotalValue: null,
      TaxAmount: null,
      GST: '',
    };
  }
  resetQuoteProductForm() {
    this.quoteProductForm = {
      QuoteID: '',
      ListOfQuoteProduct: [],
    };
  }
}

declare class ListOfQuoteProductObj {
  SerialNo: string;
  ProductId: string;
  Quantity: string;
  Rate: string;
  Discount: string;
  Amount: Number;
  TotalValue: Number;
  TaxAmount: Number;
  GST: string;
}

interface QuoteProductForm {
  QuoteID: '';
  ListOfQuoteProduct: Array<any>;
}
