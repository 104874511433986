<form class="form-inline">
  <div class="form-group">
    <div class="input-group">
      <input readonly class="form-control" [placeholder]="placeholder" name="date" [(ngModel)]="dateStruct" (ngModelChange)="updateDate()"
        ngbDatepicker #datePicker="ngbDatepicker">
      <div class="input-group-append" (click)="datePicker.toggle()">
        <span class="input-group-text">
          <i class="fa fa-calendar"></i>
        </span>
      </div>
    </div>
  </div>
</form>
<ngb-timepicker [(ngModel)]="timeStruct" (ngModelChange)="updateTime()" [meridian]="true">
</ngb-timepicker>