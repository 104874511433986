<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon reports glyphicon glyphicon-th-list">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Edit Report</p>
      </h3>
    </div>
  </div>

  <div class="background-page mt-0">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 border-right">
            <label>Select Page</label>
            <div class="custom_input">
              <select [(ngModel)]="selectedPage" (change)="getColumns()">
                <option selected value="null">Select Page</option>
                <option *ngFor="let item of reportPages" [value]="item.Value">{{item.Text}}</option>
              </select>
            </div>
            <div class="row dual-list-box">
              <div class="col-md-5 ">
                <div class="actualcolums">
                  <p>Available Fields</p>
                  <ul>
                    <ng-container *ngFor="let col of columns">
                      <li *ngIf="col.Visible" (click)="addCols(col, true)" [ngClass]="{'active': cols[col.Name] === col.Name }">{{col.Value}}</li>
                    </ng-container>
                  </ul>
                </div>
              </div>
              <div class="col-md-2 addremoveColumn_cont">
                <div class="addremoveColumn">
                  <ul>
                    <li (click)="setCols(true)"><i class="fas fa-caret-right"></i></li>
                    <li (click)="setCols(false)">
                      <i class="fas fa-caret-left"></i></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-5 ">
                <div class="displaycolumns">
                  <p>Selected Fields</p>
                  <ul>
                    <ng-container *ngFor="let col of columns">
                      <li *ngIf="!col.Visible" (click)="rmCols(col, false)" [ngClass]="{'active': cols[col.Name] === col.Name}">{{col.Value}}</li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-4 clearfix">
              <h2>Add Filters</h2>
              <div class="row" *ngIf="filter.length > 0">
                <div class="col-md-2 mb-2" *ngFor="let column of filter; let i = index" (click)="editFilter(i)">
                  <div class="filter_selected">
                    <span class="deselect_filter" (click)="deleteFilter(i)">&times;</span>
                    <h6>{{column.columnName}}</h6>
                    <p class="p-f">{{column.sqlOperatorValue}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="showFilter" class="filter_cont_div">
                <div class="mb-20">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6 mb-10">
                          <div class="custom_input">
                            <label>Columns</label>
                            <select [(ngModel)]="newFilter.columnName">
                              <option selected value="null">Select columnName</option>
                              <option *ngFor="let column of columns" [value]="column.Name">{{column.Name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6 mb-10">
                          <div class="custom_input">
                            <label>Operator</label>
                            <select class="" [(ngModel)]="newFilter.sqlOperatorValue" name="columnOperator" placeholder="Select operator">
                              <option disabled>--Select operator--</option>
                              <option *ngFor="let option of sqlOperators" [value]="option">{{option}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6 mb-10">
                          <div class="custom_input">
                            <label>Value</label>
                            <input class="" [(ngModel)]="newFilter.valueIndicator" name="columnValue" placeholder="Enter the value">
                          </div>
                        </div>
                        <div class="col-md-12 mb-10">
                          <button type="button" (click)="addFilter(newFilter)" class="btn btn-primary float-right">{{editfil ? "Save" :
                            "Done"}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn_bg_white btn_grp">
                <button *ngIf="!showFilter" type="button" (click)="showAddFilter()" class="btn btn-primary float-right">Add</button>
              </div>
            </div>
            <div class="mb-4 clearfix">
              <h2>Add Order By Filters</h2>
              <div class="row" *ngIf="orderBy.length > 0">
                <div class="col-md-2 mb-2" *ngFor="let column of orderBy; let i = index" (click)="editOrderBy(i)">
                  <div class="filter_selected">
                    <span class="deselect_filter" (click)="deleteOrBy(i)">&times;</span>
                    <h6>{{column.columnName}}</h6>
                    <p class="p-f">{{column.sortType}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="showOrderBy" class="filter_cont_div">
                <div class=" mb-20">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6 mb-10">
                          <div class="custom_input">
                            <label>Columns</label>
                            <select [(ngModel)]="newOrderBy.columnName">
                              <option selected value="null">Select columnName</option>
                              <option *ngFor="let column of columns" [value]="column.Name">{{column.Name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6 mb-10">
                          <div class="custom_input">
                            <label>Operator</label>
                            <select class="" [(ngModel)]="newOrderBy.sortType" name="columnOperator" placeholder="Select operator">
                              <option selected value="null">--Select operator--</option>
                              <option value="ASC">ASC</option>
                              <option value="DESC">DESC</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-12 mb-10">
                          <button type="button" (click)="addOrderBy(newOrderBy)" class="btn btn-primary float-right">{{editOrBy ? "Save" :
                            "Done"}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn_bg_white btn_grp">
                <button *ngIf="!showOrderBy" type="button" (click)="showOrdBy()" class="btn btn-primary float-right">Add</button>
              </div>
            </div>
            <div class="mb-4 clearfix">
              <div class="form-group col-md-6">
                <label>Access Type</label>
                <div class="radio">
                  <label>
                    <input type="radio" name="optradio" [value]="0" [(ngModel)]="AccessType">Private</label>
                </div>
                <div class="radio">
                  <label>
                    <input type="radio" name="optradio" [value]="1" [(ngModel)]="AccessType">Public</label>
                </div>
              </div>
              <div class="col-md-6">
                <label>Name</label>
                <div class="custom_input">
                  <input [(ngModel)]="ReportName" placeholder="Enter Name" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="col-md-6  border-right">
         
        </div>
        <div class="col-md-6">
          <div class="row">
            
            <div class="col-md-12 text-right mtn-20">
              <button type="button" (click)="saveReport()" class="btn btn-primary float-right">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="bg_white_margin_top min_top">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <label>Select type</label>
            <div class="custom_input">
              <select [(ngModel)]="selectedPage" (change)="getColumns()">
                <option selected value="null">Select Page</option>
                <option *ngFor="let item of reportPages" [value]="item.Value">{{item.Text}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg_white_margin_top filter_cont_div">
    <h2 *ngIf="columns.length > 0">Columns</h2>
    <div class="row">
      <div class="col-md-3 mb-10" *ngFor="let column of columns">
        <div class="card" (click)="toggleColumns(column)" [ngClass]="{'selected': columnsArray.indexOf(column) !== -1}">
          <span>{{column}}</span>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="bg_white_margin_top mb-20">
      <h2>Filters</h2>
      <div class="row" *ngIf="filter.length > 0">
        <div class="col-md-3" *ngFor="let column of filter; let i = index" (click)="editFilter(i)">
          <div class="filter_selected">
            <span class="deselect_filter" (click)="deleteFilter(i)">&times;</span>
            <h2>{{column.columnName}}</h2>
            <p>{{column.sqlOperatorValue}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="showFilter" class="filter_cont_div">
        <div class="bg_white_margin_top mb-20">
          <h2>Filter</h2>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 mb-10">
                  <div class="custom_input">
                    <label>Columns</label>
                    <select [(ngModel)]="newFilter.columnName" (change)="getColumns()">
                      <option selected value="null">Select columnName</option>
                      <option *ngFor="let column of columns" [value]="column">{{column}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 mb-10">
                  <div class="custom_input">
                    <label>Operator</label>
                    <select class="" [(ngModel)]="newFilter.sqlOperatorValue" name="columnOperator" placeholder="Select operator">
                      <option disabled>--Select operator--</option>
                      <option *ngFor="let option of sqlOperators" [value]="option">{{option}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 mb-10">
                  <div class="custom_input">
                    <label>Value</label>
                    <input class="" [(ngModel)]="newFilter.valueIndicator" name="columnValue" placeholder="Enter the value">
                  </div>
                </div>
                <div class="col-md-12 mb-10">
                  <button type="button" (click)="addFilter(newFilter)" class="btn btn-primary">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_bg_white btn_grp">
        <button *ngIf="!showFilter" type="button" (click)="showAddFilter()" class="btn btn-primary">Add New Filter</button>
      </div>
    </div>
  </div>

  <div>
    <div class="bg_white_margin_top mb-30">
      <h2>Order By</h2>
      <div class="row" *ngIf="orderBy.length > 0">
        <div class="col-md-4" *ngFor="let column of orderBy; let i = index" (click)="editOrderBy(i)">
          <div class="filter_selected">
            <span class="deselect_filter" (click)="deleteOrBy(i)">&times;</span>
            <h2>{{column.columnName}}</h2>
            <p>{{column.sortType}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="showOrderBy" class="filter_cont_div">
        <div class="bg_white_margin_top mb-20">
          <h2>Order By</h2>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 mb-10">
                  <div class="custom_input">
                    <label>Columns</label>
                    <select [(ngModel)]="newOrderBy.columnName" (change)="getColumns()">
                      <option selected value="null">Select columnName</option>
                      <option *ngFor="let column of columns" [value]="column">{{column}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 mb-10">
                  <div class="custom_input">
                    <label>Operator</label>
                    <select class="" [(ngModel)]="newOrderBy.sortType" name="columnOperator" placeholder="Select operator">
                      <option selected value="null">--Select operator--</option>
                      <option value="ASC">ASC</option>
                      <option value="DESC">DESC</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12 mb-10">
                  <button type="button" (click)="addOrderBy(newOrderBy)" class="btn btn-primary">{{editOrBy ? "Save" :
                    "Done"}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_bg_white btn_grp">
        <button *ngIf="!showOrderBy" type="button" (click)="showOrdBy()" class="btn btn-primary">Add</button>
      </div>
    </div>
  </div>


  <div class="bg_white_margin_top mb-20">
    <div class="row">
      <div class="form-group col-md-6">
        <label>Access Type</label>
        <div class="radio">
          <label>
            <input type="radio" name="optradio" [value]="0" [(ngModel)]="AccessType">Private</label>
        </div>
        <div class="radio">
          <label>
            <input type="radio" name="optradio" [value]="1" [(ngModel)]="AccessType">Public</label>
        </div>
      </div>
      <div class="col-md-6">
        <label>Name</label>
        <div class="custom_input">
          <input [(ngModel)]="ReportName" placeholder="Enter Name" type="text" />
        </div>
      </div>
      <div class="col-md-12 text-right mtn-20">
        <button type="button" (click)="saveReport()" class="btn btn-primary">Save</button>
      </div>
    </div>
  </div> -->
</div>