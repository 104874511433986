<div class="bg_white_margin_top">
  <h3>
    Setup
  </h3>
  <div class="row">
    <div class="col-md-2 col-sm-4 col-xs-12 setup-col">
      <h4>General</h4>
      <div>
        <a routerLink="/statusConfig">Status Configuration</a>
      </div>
      <div>
        <a routerLink="/milestoneConfig">Milestone Configuration</a>
      </div>
      <div>
        <a routerLink="/taskTemplate">Project Task Template</a>
      </div>
      <div>
        <a href="javascript:void(0)" (click)="openNavItems()">Navigation Items</a>
      </div>
      <div>
        <a routerLink="/dashBoardConfig">Dashboards</a>
      </div>

      <!-- <div>
        <a routerLink="/activities">Activities</a>
      </div> -->
    </div>

    <div class="col-md-2 col-sm-4 col-xs-12 setup-col">
      <h4>Users and Controls</h4>
      <div>
        <a routerLink="/role">Roles</a>
      </div>
      <div>
        <a routerLink="/rolePermission">Role wise permissions</a>
      </div>
      <div>
        <a routerLink="/profile">Profiles</a>
      </div>
      <div>
        <a routerLink="/changePassword">Change Password</a>
      </div>
      <!-- <div>
        <a routerLink="/territoryManagement">Territory Management</a>
      </div> -->
      <div>
        <a routerLink="/mailingAddress">Multiple Address</a>
      </div>
    </div>

    <div class="col-md-2 col-sm-4 col-xs-12 setup-col">
      <h4>Channels</h4>
      <div>
        <a [routerLink]="['/otherEmail']">Email</a>
      </div>
      <div>
        <a [routerLink]="['/socialConfig/sms']">SMS</a>
      </div>
      <div>
        <a [routerLink]="['/socialConfig/whatsapp']">WhatsApp</a>
      </div>
      <!-- <div>
        <a [routerLink]="['/emailTemplate']">Email Template</a>
      </div> -->
      <!-- <div>
        <a href="javascript:void(0)" routerLink="/track-user">Track User</a>
      </div> -->
      <!-- <div>
        <a href="javascript:void(0)" routerLink="/timeSheet">Timesheet</a>
      </div> -->
    </div>

    <div class="col-md-2 col-sm-4 col-xs-12 setup-col">
      <h4>Marketing & Sales</h4>
      <div>
        <a routerLink="/target">Targets</a>
      </div>
      <div>
        <a routerLink="/profileConnect">Connect</a>
      </div>
      <!-- <div>
        <a routerLink="/posDashboard">Point Of Sales</a>
      </div> -->
    </div>

    <!-- <div class="col-md-2 col-sm-4 col-xs-12 setup-col">
      <h4>MIS Reports</h4>
      <div>
        <a routerLink="/reports">Reports</a>
      </div>
    </div> -->
  </div>
</div>