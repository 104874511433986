import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';
@Injectable()
export class ProjectService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) {
  }

  // Create Method for Project
  addProject(form) {
    return this.apiService.post('api/project/create', form);
  }
  // get by id method for Project
  projectById(projectId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/project/getbyid?Id=${projectId}&MasterType=${q.type}&`);
  }

  getAllProjectType() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/project/getall?BaseId=${q.paramId}&MasterType=${q.type}&`);
  }

  getProjectById(projectId, Project) {
    return this.apiService.get(`api/project/getbyid?Id=${projectId}&MasterType=${Project}&`);
  }

  updateProject(form) {
    return this.apiService.put('api/project/edit', form);
  }
}
