import { TopnavbarService } from './../../product/crm/services/top-nav-bar/topnavbar.service';
import { LogCallService } from './../../product/crm/services/common/activity-page/LogCall/log-call.service';
import { MessageService } from './../../product/crm/services/messageService/message.service';

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-bottomnavbar',
  templateUrl: 'bottomNavbar.component.html',
  styleUrls: ['bottomNavbar.component.css'],

})
export class BottomNavbarComponent implements OnInit {
  @Output() toggle = new EventEmitter();
  @Output() onCloseClick = new EventEmitter();
  @Input() show: any = Boolean;
  dataValue: any;
  MasterType: any;
  MasterName: any;
  PhoneNumber: any;
  Notes: any;
  constructor(
    private messageService: MessageService,
    private topNavbarService: TopnavbarService,
    private callLogService: LogCallService,
  ) {
  }
  ngOnInit() {
    this.messageService.change.subscribe(show => {
      this.show = show;
      this.getData();
    });
  }

  getData() {
    if (this.show === true) {
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      const mobileNo = user.EmpMobileNo;

      this.messageService.getMessage().subscribe(message => {
        this.dataValue = message;
        this.MasterType = this.dataValue.masterType;
        this.MasterName = this.dataValue.name;
        this.PhoneNumber = this.dataValue.phone;
        const phoneNumber = this.PhoneNumber.replace(/[- )(]/g, '');
        this.topNavbarService.clickToCall(mobileNo, phoneNumber).subscribe(data => {
        });
      });
    }
  }

  addToCallLog() {
    let relatedId = ''; let relatedType = ''; let contactLeadId = ''; let nameType = '';

    let subject = 'Called' + ' ' + this.MasterName + ' ' + 'at' + ' ' + this.PhoneNumber;

    if (this.dataValue.pageName === 'CRMLead' || this.dataValue.pageName === 'CRMContact') {
      contactLeadId = this.dataValue.id;
      nameType = this.dataValue.pageName.slice(3);
    } else {
      relatedId = this.dataValue.id;
      relatedType = this.dataValue.pageName.slice(3);
    }

    this.callLogService.addCallLogs({
      Comments: this.Notes,
      RelatedToId: relatedId,
      ContactLeadId: contactLeadId,
      Subject: subject,
      NameType: nameType,
      RelatedType: relatedType,
    }).subscribe(data => { });

    this.messageService.closeToggle();
    this.Notes = '';
    this.PhoneNumber = '';
    relatedId = '';
    relatedType = '';
    contactLeadId = '';
    nameType = '';
    subject = '';
    this.MasterType = '';
    this.MasterName = '';

  }

  onBottomOpen() {
    this.toggle.emit();
  }
}
