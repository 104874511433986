import { GlobalService } from './../../../services/global-Service/global.service';
import { EventEmitter } from '@angular/core';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl
} from '@angular/forms';
import { Component, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { DatePipe } from '@angular/common';
import { ContractService } from '../../../services/contract/contract.service';

@Component({
  selector: 'app-new-contract',
  templateUrl: './new-contract.component.html',
  styleUrls: ['./new-contract.component.css']
})
export class NewContractComponent implements OnInit {
  @Output() getData = new EventEmitter();
  contractForm: UntypedFormGroup;
  contractFormData: any;
  accountfilteredOptions: any[];
  ownerfilteredOptions: any[];
  contactfilteredOptions: any[];
  accountText: any; accountValue: any;
  ownerText: any; ownerValue: any;
  contactText: any; contactValue: any;
  EditNew: any;
  public contractStatusModel: AutocompleteModel[];
  constructor(
    private fb: UntypedFormBuilder,
    public autocompleteService: AutocompleteService,
    private masterLookupService: MasterLookupService,
    private globalService: GlobalService,
    private datePipe: DatePipe,
    private contractService: ContractService,
    public activeModal: NgbActiveModal,
  ) {

  }

  ngOnInit() {
    this.contractForm = this.fb.group({
      ContractId: null,
      OwnerId: '',
      StartDate: new Date(),
      ContractNumber: '',
      ContractName: '',
      ContractTerm: '',
      AccountId: '',
      OwnerExpirationNotice: '',
      Status: '',
      Description: '',
      SpecialTerms: '',
      CustomerSignedId: '',
      CustomerSignedTitle: '',
      CompanySignedDate: new Date(),
      CustomerSignedDate: new Date(),
      CompanySignedBy: '',
      BillingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      })
    });
    this.getContractStatus();
    this.getEditData();
    this.getEditNewName();
  }


  getEditNewName() {
    if (this.contractForm.value.ContractId === null) {
      this.EditNew = 'New';
    } else {
      this.contractForm.controls['StartDate'].setValue(new Date(this.contractForm.value.StartDate));
      this.contractForm.controls['CompanySignedDate'].setValue(new Date(this.contractForm.value.CompanySignedDate));
      this.contractForm.controls['CustomerSignedDate'].setValue(new Date(this.contractForm.value.CustomerSignedDate));
      this.EditNew = 'Edit';
    }
  }

  getEditData() {
    if (this.contractFormData !== undefined) {
      this.contractFormData.StartDate = new Date(this.contractFormData.StartDate);
      this.contractForm.patchValue(this.contractFormData);

      this.ownerText = this.contractFormData.ContractOwnerName;
      this.ownerValue = this.contractFormData.OwnerId;

      this.accountText = this.contractFormData.AccountName;
      this.accountValue = this.contractFormData.AccountId;

      this.contactText = this.contractFormData.CustomerSignedName;
      this.contactValue = this.contractFormData.CustomerSignedId;
    }else{
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      this.ownerText = user.EmpName;
      this.ownerValue = user.EmpID;
      this.contractForm.controls['OwnerId'].setValue(user.EmpID);
    }
  }



  getAccountList(value) {
    this.autocompleteService.getAllAccountList(value.data).subscribe(
      res => {
        this.accountfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteAccount(data) {
    const accountId = data.data.option.value.Value;
    this.contractForm.controls['AccountId'].setValue(accountId);
  }



  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMContracts').subscribe(
      res => {
        this.ownerfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteEmployee(data) {
    const ownerId = data.data.option.value.Value;
    this.contractForm.controls['OwnerId'].setValue(ownerId);
  }

  getContactList(value) {
    this.autocompleteService.getAllContactList(value.data).subscribe(
      res => {
        this.contactfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteContact(data) {
    const contactId = data.data.option.value.Value;
    this.contractForm.controls['CustomerSignedId'].setValue(contactId);
  }

  getContractStatus() {
    this.masterLookupService.getDropdownList('Campaign Status').subscribe(data => {
      this.contractStatusModel = data;
    });
  }


  DateFormatter() {
    this.contractForm.value.StartDate = this.datePipe.transform(this.contractForm.value.StartDate, 'yyyy-MM-dd');
    this.contractForm.value.CompanySignedDate = this.datePipe.transform(this.contractForm.value.CompanySignedDate, 'yyyy-MM-dd');
    this.contractForm.value.CustomerSignedDate = this.datePipe.transform(this.contractForm.value.CustomerSignedDate, 'yyyy-MM-dd');
  }
  // Submit Function
  onSubmit(form: UntypedFormGroup) {
    this.DateFormatter();
    const contractNo = form.value.ContractNumber;
    let responseMessage = [];
    if (form.value.ContractId == null) {
      this.contractService.addContract(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showDefaultSuccess({ masterName: 'Contract' });
        }
        this.getData.emit();
      });

      // Update Method Contract
    } else {
      this.contractService.updateContract(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Contract', name: contractNo });
        }
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.contractForm.reset();
    this.globalService.disposeToastr();
  }

}
