<div class="body_inner">
  <div class="padding10">
    <div class="row">
      <div class="topheader">

        <div class="topheadertitle">
          <span class="menu_icon CRMQuotes fa fa-tags">
          </span>
          <h3 class="report_page_title">
            <p class="page_title_cont">Quote</p>
            <p class="page_sub_title">{{quoteDataList?.QuoteName}}</p>
          </h3>

          <div class="buttonNew buttonNew1 pull-right" (click)="createPdf()">Create PDF
          </div>
          <div class="buttonNew buttonNew1 pull-right" (click)="openEmail(quoteMailContent)">Send Email
          </div>
        </div>

        <div class="">
          <div class="topdownheader ">
            <div class="">

              <div class="block">
                <label class="labelName">Quote Number</label>
                <p>{{quoteDataList?.QuoteNo}}</p>
              </div>

              <div class="block">
                <label class="labelName">Expiration Date</label>
                <p>{{quoteDataList?.ExpiryDate | date:'dd-MM-yy' }}</p>
              </div>

              <div class="block" *ngIf="disableIsDirect===true">
                <label class="labelName">Client Name</label>
                <p>
                  <span class="ownerHeading">{{quoteDataList?.ClientName}}</span>
                </p>
              </div>

              <div class="block" *ngIf="disableIsDirect===true">
                <label class="labelName">Owner Name</label>
                <p>
                  <span class="ownerHeading">{{quoteDataList?.OwnerName}}</span>
                </p>
              </div>

              <div class="block" *ngIf="disableIsNotDirect===true">
                <label class="labelName">Opportunity Name</label>
                <p>
                  <span class="ownerHeading">{{quoteDataList?.OpportunityName}}</span>
                </p>
              </div>

              <div class="block" *ngIf="disableIsNotDirect===true">
                <label class="labelName">Account Name</label>
                <p>
                  <span class="ownerHeading">{{quoteDataList?.AccountName}}</span>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="">
    <div class="steps_bar" *ngIf="!showSpinner">
      <app-quote-status [quoteStatusData]="quoteStatusData && quoteStatusData.QuoteID" [convertedQuoteData]="convertedQuoteData">
      </app-quote-status>
    </div>
    <div class="row ">
      <div class="col-md-8 lowpadding-right">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>
              <li class="realted">
                <a class="" (click)="OnRelated()" [ngClass]="{'active': RealtedOn===true}">Line Items</a>
              </li>
              <li class="realted">
                <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Services</a>
              </li>
              <li class="related">
                <a (click)="onTerm()" [ngClass]="{'active': termOn===true}">Z</a>
              </li>
            </ul>
          </div>
          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <app-quote-related [searchKeys]="searchKeys" *ngIf="RealtedOn===true"></app-quote-related>
            </li>
            <li class="nav-item">
              <app-quote-service *ngIf="DetailOn===true"></app-quote-service>
            </li>
            <li class="nav-item">
              <app-terms-template *ngIf="termOn===true"></app-terms-template>
            </li>
          </ul>
        </nav>
      </div>

      <div class="col-md-4 bg_white_margin_top no_boxshadow">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>
              <li class="realted">
                <a class="" (click)=onActivity() [ngClass]="{'active': activityOn===true}">Activity</a>
              </li>
            </ul>
          </div>
          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <app-activity *ngIf="activityOn===true"></app-activity>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
  <ng-template #quoteMailContent let-cl="close" let-di="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
      <button type="button" class="close" aria-label="Close" (click)="di('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-header">
      <h4 class="modal-title text-center">Send Email</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">From Email</label>
          <p>{{FromEmail}}</p>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">To Email</label>
          <p>{{ToMailId}}</p>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">
            <span class="text-danger">*</span>Body</label>
          <!-- <app-ngx-editor [config]="editorConfig" [(ngModel)]="Body"></app-ngx-editor> -->
          <div class="html-Container">
            <ngx-editor-menu [editor]="editor"  [toolbar]="this.editorConfig.toolbar"> </ngx-editor-menu>
            <form [formGroup]="form">

              <ngx-editor
                [editor]="editor"
                formControlName="Body"
                [disabled]="false"
                [placeholder]="'Type here...'"
            ></ngx-editor>
            </form>
        </div>
        </div>
      </div>
      
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="cl('Close click')">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="sendEmail()">Send</button>
    </div>
  </ng-template>
</div>