<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon territory glyphicon glyphicon-globe">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Territories</p>
        <!-- <p class="page_sub_title">{{editName}}</p> -->
      </h3>
    </div>
    <div class="pull-right">
      <div  class="buttonHeader pull-right">
        <div  class="buttonNew" (click)="open(addTerritory)">New</div>
      </div>
    </div>
  </div>

  <!-- detail Table -->
  <app-htmldatatable [tableData]="territoryData" [columnName]="columnName" [columnRowName]="columnRowName" (edit)="edit($event)"
    (onDelete)="onDelete($event)">
  </app-htmldatatable>
</div>

<ng-template #addTerritory let-modal>
  <div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Territory</h4>
  </div>
  <div class="modal-body">
      <div class="form-group">
        <label class="control-label col-sm-2" for="text">Territory Name</label>
        <div class="col-sm-4">
          <input type="text" class="form-control" [(ngModel)]="territoryForm.TerritoryName">
        </div>
      </div>
  
      <div class="form-group">
        <label class="control-label col-sm-2" for="text">Territory Manager</label>
        <div class="col-sm-4">
          <select class="form-control" [(ngModel)]="territoryForm.ManagerID">
            <option *ngFor="let manager of EmployeeList" value={{manager.Value}}>{{manager.Text}}</option>
          </select>
        </div>
      </div>
  
      <div class="form-group">
        <label class="control-label col-sm-2">Parent Territory</label>
        <div class="col-sm-4">
          <select class="form-control" [(ngModel)]="territoryForm.ParentID">
            <option *ngFor="let territory of autoCompleteTerritory" value={{territory.Value}}>{{territory.Text}}</option>
          </select>
        </div>
      </div>
  
      <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  
      <nav class="navbar navbar-default custom_navbar grey_back mt-5">
        <div class="nav-bar-inner">
          <ul class="nav navbar-nav cl" routerLinkActive="active">
            <li class="nav-item" [ngClass]="{'active': addUser===true}">
              <a class="nav-link" (click)="OnAddUser()">Add Users</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': hourBudget===true}">
              <a class="nav-link" (click)="OnHourBudget()">Hours Budget</a>
            </li>
          </ul>
  
          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <div *ngIf="addUser===true">
                <table class="table table-striped table-bordered dynomic_table">
                  <thead>
                    <tr>
                      <th class="name_short"><b>User Name</b></th>
                      <th class="name_full_width"><b>Access Type</b></th>
                      <th class="number_short"><b>Action</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let status of territoryForm.ListOfTerritoryUser; let i=index">
                      <td>
                        <select class="form-control" [(ngModel)]="status.ResourceID" (change)="addFieldValue(i)">
                          <option *ngFor="let manager of EmployeeList" value={{manager.Value}}>{{manager.Text}}</option>
                        </select>
                      </td>
  
                      <td>
                        <select class="form-control" [(ngModel)]="status.WorkType" (input)="addFieldValue(i)">
                          <option value="ReadOnly">Read Only</option>
                          <option value="Read/Write/Delete">Read/Write/Delete</option>
                        </select>
                      </td>
  
                      <td class="number_largeshort text-center">
                        <div class="btn-group" dropdown>
                          <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
                          <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
  
            <li class="nav-item">
              <div *ngIf="hourBudget===true" class="table_cont">
                <table class="table table-striped table-bordered dynomic_table">
                  <thead>
                    <tr>
                      <th class="name_short"><b>Month Name</b></th>
                      <th lass="number_small"><b>Hours</b></th>
                      <th class="number_short"><b>Action</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let status of territoryForm.ListOfHourBudget; let k=index">
                      <td>
                        <select class="form-control" [(ngModel)]="status.MonthID" (change)="addField(k)">
                          <option *ngFor="let month of MonthList" value={{month.Value}}>{{month.Text}}</option>
                        </select>
                      </td>
  
                      <td>
                        <input type="text" class="form-control" [(ngModel)]="status.HoursBudget" (input)="addField(k)">
                      </td>
  
                      <td>
                        <div class="btn-group" dropdown>
                          <a class="action_btn add_btn" href="javascript:void(0)" (click)="addField()">&#43;</a>
                          <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteField(k)">&times;</a>
                        </div>
                      </td>
                    </tr>
  
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </nav>
  
      <div class="form-group text-right">
        <button type="button" class="btn btn-default" (click)="resetForm()">Rest Form</button>
        <button type="submit" class="btn btn-primary" (click)="onSubmit()">Save</button>
      </div>
    </div>
</ng-template>
<!--  -->
