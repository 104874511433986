 import { Component, OnInit } from "@angular/core";

@Component({
  selector: "email-cmp",
  templateUrl: "./email.template.html",
  styleUrls: ["./email.style.css"]
})
export class EmailComponent implements OnInit {
  _ref: any;
  removeObject() {
    this._ref.destroy();
  }
  save() {
    alert("Saved Successfully!");
  }

  ngOnInit() {}
}
