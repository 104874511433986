import { GlobalService } from './../../../../services/global-Service/global.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VariableService } from '../../../../services/VariableService/variable.service';
import { AutocompleteService } from '../../../../services/autocomplete/autocomplete.service';
import { Component, OnInit, ViewChild, Output, EventEmitter, ViewContainerRef, ElementRef } from '@angular/core';
import { LogCallService } from '../../../../services/common/activity-page/LogCall/log-call.service';


@Component({
  selector: 'app-logcall',
  templateUrl: './logcall.component.html',
  styleUrls: ['./logcall.component.css']
})
export class LogcallComponent implements OnInit {
  @ViewChild('contactLeadtextInput') contactLeadtextInput: ElementRef;
  @ViewChild('relatedtextInput') relatedtextInput: ElementRef;
  @Output() eventLogCall = new EventEmitter();
  logCallForm: UntypedFormGroup;
  NameRealtedType: any;
  RelatedToType: any;
  public disableDropdown: Boolean = true;
  contactLeadFilterOption: any[];
  relatedTypeFilterOption: any[];
  selectedContactLeadOption: any;
  selectedRelatedOption: any;
  masterTypeText: any;
  masterTypeValue: any;

  NameTypeList = this.globalService.NameTypeList;

  RelatedTypeList = this.globalService.RelatedTypeList;

  constructor(
    public fb: UntypedFormBuilder,
    public callLogService: LogCallService,
    public autocompleteService: AutocompleteService,
    private variableService: VariableService,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.logCallForm = this.fb.group({
      CallLogId: '',
      Comments: '',
      RelatedToId: '',
      UserId: '',
      ContactLeadId: '',
      Subject: '',
      NameType: '',
      RelatedType: '',
    });
    this.getMasterTypeList();
    this.setMasterType();
  }

  setMasterType() {
    const q: any = this.variableService.getSearchKeys();

    if (q.type === 'CRMLead' || q.type === 'CRMContact') {
      this.NameRealtedType = q.type.slice(3);
      this.logCallForm.controls['ContactLeadId'].patchValue(q.paramId);
    } else {
      this.RelatedToType = q.type.slice(3);
      this.logCallForm.controls['RelatedToId'].patchValue(q.paramId);
    }

    this.logCallForm.patchValue({
      NameType: this.NameRealtedType,
      RelatedType: this.RelatedToType,
    });
  }

  getMasterTypeList() {
    const q: any = this.variableService.getSearchKeys();
    this.autocompleteService.getMasterTypeListData(q.type, q.paramId).subscribe(data => {
      this.masterTypeText = data[0].Text;
      this.masterTypeValue = data[0].Value;

      if (q.type === 'CRMLead' || q.type === 'CRMContact') {
        const contactLeadObj = {
          Text: this.masterTypeText,
          Value: this.masterTypeValue,
        };
        this.contactLeadtextInput.nativeElement.value = contactLeadObj.Text;
        this.selectedContactLeadOption = contactLeadObj;
      } else {
        const relatedObj = {
          Text: this.masterTypeText,
          Value: this.masterTypeValue,
        };
        this.relatedtextInput.nativeElement.value = relatedObj.Text;
        this.selectedRelatedOption = relatedObj;
      }
    }, err => {
      console.log('errr====', err);
    });
  }

  displayContactFn(contactLead: any) {
    this.selectedContactLeadOption = contactLead;
    return contactLead ? contactLead.Text : '';
  }

  displayRelatedFn(related: any) {
    this.selectedRelatedOption = related;
    return related ? related.Text : '';
  }

  getRealtedTypeList(value) {
    const relatedValue = this.logCallForm.value.RelatedType;
    if (relatedValue !== '') {
      if (value.length > 1) {
        this.autocompleteService.getAllRelatedTypeList(relatedValue, value).subscribe(
          res => {
            this.relatedTypeFilterOption = res;
          },
          err => {
            console.log('errr====', err);
          });
      }
    }
  }


  getLeadContactList(value) {
    const optionValue = this.logCallForm.value.NameType;
    if (optionValue !== '') {
      if (value.length > 1) {
        this.autocompleteService.getLeadContactList(optionValue, value).subscribe(
          res => {
            this.contactLeadFilterOption = res;
          },
          err => {
            console.log('errr====', err);
          });
      }
    }
  }

  submitData() {
    if (this.logCallForm.value.ContactLeadId.Id === null) {
      this.logCallForm.controls['ContactLeadId'].
        patchValue(this.logCallForm.value.ContactLeadId.Value);
    } else if (this.logCallForm.value.RelatedToId.Id === null) {
      this.logCallForm.controls['RelatedToId'].patchValue(this.logCallForm.value.RelatedToId.Value);
    }
  }


  onSubmit(form: UntypedFormGroup) {
    this.submitData();
    const logCallName = form.value.Subject;
    let responseMessage = [];
    this.callLogService.addCallLogs(form.value).subscribe(data => {
      responseMessage = data.ErrorMessages;
      if (responseMessage[1] === '0') {
        this.globalService.showSuccess({ masterName: 'Task', name: logCallName });
      }
      this.eventLogCall.emit();
    });
    this.resetForm();
  }
  resetForm() {
    this.logCallForm.patchValue({
      Comments: '',
      Subject: '',
    });
  }
}
