<div class="body_inner">
  <div class="">
    <div class="topheader">

      <div class="topheadertitle">
        <span class="menu_icon CRMMileStones glyphicon glyphicon-flag">
        </span>
        <h3 class="report_page_title">
          <p class="page_title_cont">Milestone</p>
          <p class="page_sub_title">{{milestoneData?.MileStoneName}}</p>
        </h3>
      </div>
      <div class="">
        <div class="topdownheader ">
              <div class="">
                <div class="block">
                    <label class="labelName">Project Name</label>
                    <p>{{milestoneData.Project}}</p>
                </div>
                <div class="block">
                    <label class="labelName">Project No</label>
                    <p>{{milestoneData.ProjectNo}}</p>
                </div>
                <div class="block">
                    <label class="labelName">Start Date</label>
                    <p>
                        {{milestoneData.Kickoff | date:'dd/MM/yyyy'}}
                    </p>
                </div>
                <div class="block">
                  <label class="labelName">End date</label>
                  <p>
                      {{milestoneData.DeadLine | date:'dd/MM/yyyy'}}
                  </p>
                </div>
                <div class="block">
                    <label class="labelName">% Completed</label>
                    <p>
                        {{milestoneData.OwnerName}}
                    </p>
                </div>
                <div class="block">
                  <label class="labelName">Team Lead</label>
                  <p>
                      {{milestoneData.MilestoneOwnerName}}
                  </p>
              </div>

              </div>
          </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 lowpadding-right">
      <nav class="navbar navbar-default custom_navbar">
        <div class="nav nav-bar-inner">
          <ul>
            <li class="realted">
              <a class="" (click)="OnRelated()" [ngClass]="{'active': RelatedOn===true}">Project Tasks</a>
            </li>
            <li class="realted">
              <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Details</a>
            </li>
          </ul>
        </div>
        <ul class="cl custom_content_tab">
          <li class="nav-item disabled_form">
            <app-milestone-detail [milestoneData]="milestoneData" *ngIf="DetailOn===true &&milestoneData.MilesStoneId"></app-milestone-detail>
          </li>
          <li class="nav-item">
            <app-milestone-related-detail *ngIf="RelatedOn===true" [searchKeys]="searchKeys"></app-milestone-related-detail>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-md-4 bg_white_margin_top no_boxshadow">
      <nav class="navbar navbar-default custom_navbar">
        <div class="nav nav-bar-inner">
          <ul>

            <li class="realted">
              <a class="" (click)=onActivity() [ngClass]="{'active': activityOn===true}">Activity</a>
            </li>

            <li class="activity">
              <a class="" (click)=OnChatter() [ngClass]="{'active': NewChatterOn===true}">Chatter</a>
            </li>

          </ul>
        </div>
        <ul class="cl custom_content_tab">
          <li class="nav-item">
            <app-activity *ngIf="activityOn===true"></app-activity>
          </li>
          <li class="nav-item">
            <app-chatter *ngIf="NewChatterOn===true"></app-chatter>
          </li>
        </ul>
      </nav>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
</div>