import { VariableService } from '../VariableService/variable.service';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';

@Injectable()
export class CalendarService {

  constructor(private apiService: ApiService,
    private variableService: VariableService) { }
  getCalendarEventTask() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/calendar/getall?Id=${q.paramId}&`
    );
  }
}
