import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../services/common/activity-page/email/email.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/accounts/account.service';

@Component({
  selector: 'app-profile-connect',
  templateUrl: './profile-connect.component.html',
  styleUrls: ['./profile-connect.component.css']
})
export class ProfileConnectComponent implements OnInit {

  constructor(public emailService: EmailService,    private route: ActivatedRoute,private accountService:AccountService) {
      route.queryParams.filter(params => params.code).subscribe(params => {
        window.opener['emailCodeReceived'](params.code);
        window.close();
      });
     }

  ngOnInit(): void {
  }
  connectGoogle(){
    const session = sessionStorage;
    console.log('session', session);
    const scopes =
      'https://mail.google.com/+https://www.googleapis.com/auth/userinfo.email';

    window.open(
      // tslint:disable-next-line:max-line-length
      `https://accounts.google.com/o/oauth2/auth?redirect_uri=${window.location.href}&response_type=code&client_id=215938282303-4r18b5iu3vu5dp8gjpd3hulb2l9amfd1.apps.googleusercontent.com&scope=https://mail.google.com/+https://www.googleapis.com/auth/admin.directory.resource.calendar&approval_prompt=force&access_type=offline`,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=500,width=640,height=500'
    );
    window['emailCodeReceived'] = code => {
      // console.log("parent called", code);
      this.emailService.sendEmailCode(code).subscribe(
        response => {
          // console.log("response", response);
        },
        err => {
          console.log('errr', err);
        }
      );
    };
  }

  connectProfile(type){
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    let data = {"UserId":user.UserId, "Provider":type}
    this.accountService.authorize(data).subscribe((res)=>{
      window.open(
        // tslint:disable-next-line:max-line-length
        res,
        '_blank',
        'toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=500,width=640,height=500'
      );
      window['emailCodeReceived'] = code => {
        // console.log("parent called", code);
        let finalData = {"UserId":user.UserId, "code":code,"Provider":type}
        this.accountService.updateToken(finalData).subscribe(
          response => {
            // console.log("response", response);
          },
          err => {
            console.log('errr', err);
          }
        );
      };
    })
  }
}
