import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-client-related',
  templateUrl: './client-related.component.html',
  styleUrls: ['./client-related.component.css']
})
export class ClientRelatedComponent implements OnInit {
  @Input() searchKeys;
  constructor() { }

  ngOnInit() {
  }

}
