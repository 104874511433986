<div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-header">
    <h4 class="modal-title text-center">{{EditNew}} Milestone</h4>
</div>
<form [formGroup]="milestoneForm" novalidate (ngSubmit)="onSubmit(milestoneForm)">
    <div class="modal-body">
        <div class="extra-pad">
            <div class="">
                <div class="">

                    <!-- <p class="headingclass">Information</p> -->
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">

                            <div class="form-group ">
                                <label for="text">Client</label>
                                <app-autocomplete [autoCompleteText]="clientText"
                                    (setAutoComplete)="setAutoCompleteClient($event)" [autoCompleteValue]="clientValue"
                                    (fiteredData)="getClientList($event)" [filteredOptions]="clientfilteredOptions">
                                </app-autocomplete>
                            </div>

                            <div class="form-group ">
                                <label for="text">Project</label>
                                <app-autocomplete [autoCompleteText]="projectText"
                                    (setAutoComplete)="setAutoCompleteProject($event)"
                                    [autoCompleteValue]="projectValue" (fiteredData)="getProjectList($event)"
                                    [filteredOptions]="projectfilteredOptions">
                                </app-autocomplete>
                            </div>

                            <div class="form-group ">
                                <label for="text">Team Lead</label>
                                <app-autocomplete [autoCompleteText]="ownerText"
                                    (setAutoComplete)="setAutoCompleteEmployee($event)" [autoCompleteValue]="ownerValue"
                                    (fiteredData)="getEmployeeList($event)" [filteredOptions]="ownerfilteredOptions">
                                </app-autocomplete>
                            </div>

                            <div class="form-group">
                                <label for="text">
                                    <span class="text-danger">*</span> Milestone Name</label>
                                <input type="textbox" class="form-control custom_capitalize"
                                    formControlName="MileStoneName">
                            </div>

                            <!-- <div class="form-group">
                                <label for="text">Milestone Alias</label>
                                <input type="textbox" class="form-control" formControlName="Alias">
                            </div>

                            <div class="form-group">
                                <label for="text">Parent Milestone</label>
                                <app-autocomplete [autoCompleteText]="milestoneText" (setAutoComplete)="setAutoCompleteMilestone($event)"
                                    [autoCompleteValue]="milestoneValue" (fiteredData)="getMilestoneList($event)"
                                    [filteredOptions]="milestonefilteredOptions">
                                </app-autocomplete>
                            </div> -->
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Start Date</label>
                            <input type="text" class="form-control" formControlName="Kickoff" #dp="bsDatepicker"
                                value="{{ milestoneForm.get('Kickoff').value | date: 'dd-MM-yyyy' }} " bsDatepicker
                                [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
                            <p class="inputdate" (click)="dp.toggle()">
                                <i class="fa fa-calendar-alt"></i>
                            </p>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Deadline</label>
                            <input type="text" class="form-control" formControlName="DeadLine" #bsdp="bsDatepicker"
                                value="{{ milestoneForm.get('DeadLine').value | date: 'dd-MM-yyyy' }} " bsDatepicker
                                [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
                            <p class="inputdate" (click)="bsdp.toggle()">
                                <i class="fa fa-calendar-alt"></i>
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Milestone Type</label>
                            <select formControlName="MilestoneType" class="form-control">
                                <option value=None>--None--</option>
                                <option value=Approval>Approval</option>
                                <option value=Fabrication>Fabrication</option>
                            </select>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Default Task Template</label>
                            <select formControlName="TaskTemplateID" class="form-control">
                                <option value=0>--None--</option>
                                <option *ngFor="let template of TaskTemplate" value={{template.Value}}>{{template.Text}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- <p class="headingclass">Budget</p> -->
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Milestone Hours Budget</label>
                            <input type="textbox" class="form-control" formControlName="HoursBudget">
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Milestone Expense Budget</label>
                            <input type="textbox" class="form-control" formControlName="ExpenseBudget">
                        </div>
                    </div>
                    <!-- <p class="headingclass">Description</p> -->
                    <div class="row">
                        <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label for="text">Description</label>
                            <textarea class="form-control" formControlName="Description" rows="3"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" id="btnCancel"
            (click)="activeModal.close('Close click')">Cancel</button>
        <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!milestoneForm">Save & New</button>
        <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!milestoneForm">Save</button>
    </div>
</form>