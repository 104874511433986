import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class DocumentService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }
  getDocuments() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/document/getall?Id=${q.paramId}&MasterType=${q.type}&`);
  }
  // Create Method Contract
  addDocument(file) {
    return this.apiService.post('api/upload/upload', file);
  }
  uploadDocs(file) {
    const q: any = this.variableService.getSearchKeys();
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    return this.apiService.post(`api/data/upload?MasterType=${q.type}&CompanyId=${
      user.CompanyId
      }&UserId=${user.UserId}`, file,'file');
  }
  getDocFields(file,id){
    const q: any = this.variableService.getSearchKeys(),
    data={
      MasterType:q.type,
      Id:id,
      SheetName:file
    };

    return this.apiService.post(`api/data/fields`, data,'file');
  }
  saveMappedFields(data){
    const q: any = this.variableService.getSearchKeys();
    data['MasterType'] = q.type;
    return this.apiService.post('api/data/save', data);
  }
}
