import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../../../services/location/location.service';
import { ItemsComponent } from '../../../shared/components/items/items.component';
import { VariableService } from '../../../services/VariableService/variable.service';
import { GlobalService } from './../../../services/global-Service/global.service';

@Component({
  selector: 'app-machine-location',
  templateUrl: './machine-location.component.html',
  styleUrls: ['./machine-location.component.css']
})
export class MachineLocationComponent implements OnInit {
  locationMachineData:any;
  @Input() searchKeys;
  constructor(private modalService: NgbModal, 
    private locationService:LocationService,
    private variableService:VariableService,
    private globalService:GlobalService) { }

    ngOnInit(): void {
      this.getAll();
    }
  
    getAll(){
      this.locationService.getTypeLocationMachines().subscribe(data => {
        this.locationMachineData=data;
      })
    }
    open(){
      const q: any = this.variableService.getSearchKeys();
  
      const modalRef = this.modalService.open(ItemsComponent, {
        size: 'sm',
        backdrop: 'static',
        windowClass: 'custom-modal',
        keyboard: false
      });
      modalRef.componentInstance.tableHeader = {
        'sn': {
          name: 'S.No.',
          type:'dynamic',
          className:'number_small'
        },
        'MachineName':{
          name: 'Machine Name',
          value:'MachineName',
          type:'autocomplete',
          className:'name_long'
        }, 
        'PlacedOn':{
          name: 'Placed On',
          value:'PlacedOn',
          type:'date',
          className:'amt_medium'
        }
      }
      modalRef.componentInstance.itemArr = this.locationMachineData;
      modalRef.componentInstance.getData.subscribe(() => {
        this.getAll();
      });
      modalRef.componentInstance.addData.subscribe((data) => {
        this.locationService.addMachineLocation({
          LocationId:q.paramId,
          ListofMachines:data
        }).subscribe((responseMessage)=>{
          if (responseMessage[1] === '0') {
            this.globalService.showUpdate({ masterName: 'Machines location Added/Updated' });
          }else{
            this.globalService.showErrorData('Machines location not saved.')
          }
          this.getAll();
        })
      });
    }
}
