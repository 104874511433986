import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-related-detail',
  templateUrl: './project-related-detail.component.html',
  styleUrls: ['./project-related-detail.component.css']
})
export class ProjectRelatedDetailComponent implements OnInit {
  @Input() searchKeys;
  constructor() { }

  ngOnInit() {
  }

}
