import { MasterLookupService } from './../../../services/common/master-lookup/master-lookup.service';
import { AutocompleteService } from './../../../services/autocomplete/autocomplete.service';
import { Component, OnInit, Input, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ProjectOrderService } from '../../../services/projectOrder/project-order.service';
import { GlobalService } from '../../../services/global-Service/global.service';
@Component({
  selector: 'app-customer-invoice',
  templateUrl: './customer-invoice.component.html',
  styleUrls: ['./customer-invoice.component.css']
})
export class CustomerInvoiceComponent implements OnInit {
  public bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  @Input() projectInvoiceData: any;
  @Output() getData = new EventEmitter();
  validFrom = new Date();
  public ServiceList: AutocompleteModel;
  public GstTypeList: AutocompleteModel;
  customerInvoiceForm: CustomerInvoiceForm;
  listOfCustomerInvoiceObj: ListOfCustomerInvoiceObj;
  addUser = true;
  projectText: any; projectValue: any;
  projectFilteredOptions: any[];
  currencyText: any; currencyValue: any;
  currencyfilteredOptions: any[];

  clientText: any; clientValue: any;
  clientfilteredOptions: any[];
  ListOfProjectInvoiceData = [];
  taxAmount: any;
  amount: any;
  currencyExchangeRate: any;
  exchangeRate: Boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private autocompleteService: AutocompleteService,
    private masterlookupService: MasterLookupService,
    private projectOrderService: ProjectOrderService,
    private datePipe: DatePipe,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.bsConfig.dateInputFormat = 'DD-MM-YYYY';
    this.bsConfig.showWeekNumbers = false;
    this.bsConfig.containerClass = 'theme-dark-blue';
    this.resetCustomerInvoiceForm();
    this.resetListOfCustomerInvoiceObj();
    this.customerInvoiceForm.ListOfProjectInvoice.push(this.listOfCustomerInvoiceObj);
    this.setProjectInvoiceData();
  }

  addFieldValue(i) {
    this.resetListOfCustomerInvoiceObj();
    const obj = this.customerInvoiceForm.ListOfProjectInvoice[i + 1];
    if (!obj) {
      this.customerInvoiceForm.ListOfProjectInvoice.push(this.listOfCustomerInvoiceObj);
    }
    this.resetListOfCustomerInvoiceObj();
  }
  deleteFieldValue(i: number) {
    if (this.customerInvoiceForm.ListOfProjectInvoice.length === 1) {
      return false;
    } else {
      this.customerInvoiceForm.ListOfProjectInvoice.splice(i, 1);
      return true;
    }
  }

  setProjectInvoiceData() {
    this.reset();
    this.getAllServiceList();
    this.getGstTypeList();
    this.getAllCurrencyData();

    this.clientText = this.projectInvoiceData.ClientName;
    this.clientValue = this.projectInvoiceData.CustomerId;

    this.projectText = this.projectInvoiceData.ProjectName;
    this.projectValue = this.projectInvoiceData.ProjectID;

    this.customerInvoiceForm.ProjectID = this.projectInvoiceData.ProjectID;
    this.customerInvoiceForm.CustomerId = this.projectInvoiceData.CustomerId;

    this.customerInvoiceForm.Date = this.projectInvoiceData.Date,
      this.customerInvoiceForm.TaxTypeID = this.projectInvoiceData.TaxTypeID;
    this.getProjectInvoiceTableListData();
  }

  getProjectInvoiceTableListData() {
    const projectInvoiceList = this.projectInvoiceData.ListOfProjectInvoice;
    projectInvoiceList.map((obj) => {
      this.customerInvoiceForm['ListOfProjectInvoice'].push(obj);
    });
    this.customerInvoiceForm['ListOfProjectInvoice'].push(this.listOfCustomerInvoiceObj);
    this.resetListOfCustomerInvoiceObj();
  }

  getAllCurrencyData() {
    if (this.projectInvoiceData.CurrencyID === null) {
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      this.currencyText = user.CurrencyCode;
      this.currencyValue = user.CurrencyId;
      this.customerInvoiceForm.CurrencyID = user.CurrencyId;
      this.currencyExchangeRate = user.CurrencyId;
    } else {
      this.currencyText = this.projectInvoiceData.CurrencyName;
      this.currencyValue = this.projectInvoiceData.CurrencyID;
      this.customerInvoiceForm.CurrencyID = this.projectInvoiceData.CurrencyID;
    }
  }

  getAllServiceList() {
    this.masterlookupService.getServiceList().subscribe(data => {
      this.ServiceList = data;
    });
  }

  getGstTypeList() {
    this.masterlookupService.getTaxTypeList('Sales').subscribe(data => {
      this.GstTypeList = data;
    });
  }

  fiteredDataProject(value) {
    this.autocompleteService.getAllProjectList(value.data).subscribe(
      res => {
        this.projectFilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }

  setAutoCompleteProject(data: any) {
    const value = data.data.option.value.Value;
    this.customerInvoiceForm.ProjectID = value;
  }

  getClientList(value) {
    this.autocompleteService.getAllClientList(value.data).subscribe(
      res => {
        this.clientfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }

  setAutoCompleteClient(data: any) {
    const value = data.data.option.value.Value;
    this.customerInvoiceForm.CustomerId = value;
  }

  getCurrencyList(value) {
    this.autocompleteService.getallCurrencyList(value.data).subscribe(
      res => {
        this.currencyfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }

  setAutoCompleteCurrency(data: any) {
    const value = data.data.option.value.Value;
    this.customerInvoiceForm.CurrencyID = value;
    if (this.currencyExchangeRate !== value) {
      this.exchangeRate = true;
    } else {
      this.exchangeRate = false;
    }
  }

  get customerInvoiceFormData() {
    return this.customerInvoiceForm.ListOfProjectInvoice;
  }

  calculate(i) {
    this.calculateTaxAmount(i);
    this.calculateTotalValue(i);
    this.calculateAmount(i);
  }

  calculateAmount(i) {
    const value = this.customerInvoiceFormData[i];
    let totalamount = (value.Qty * value.Rate) * ((100 - value.Disc) / 100);
    this.amount = totalamount;
    if (!isNaN(totalamount)) {
      totalamount = parseFloat(totalamount.toFixed(2));
      this.customerInvoiceForm.ListOfProjectInvoice[i].Amount = totalamount;
    }
  }

  calculateTaxAmount(i) {
    const value = this.customerInvoiceFormData[i];
    let taxamount = this.amount * ((value.TaxRate) / 100);
    this.taxAmount = taxamount;
    if (!isNaN(taxamount)) {
      taxamount = parseFloat(taxamount.toFixed(2));
      this.customerInvoiceForm.ListOfProjectInvoice[i].TaxAmount = taxamount;
    }
  }

  calculateTotalValue(i) {
    let totalvalue = 0;
    totalvalue = this.amount + this.taxAmount;
    if (!isNaN(totalvalue)) {
      totalvalue = parseFloat(totalvalue.toFixed(2));
      this.customerInvoiceForm.ListOfProjectInvoice[i].Total = totalvalue;
    }
  }

  numberOnly(event): boolean {
    const keycode = (event.which) ? event.which : event.keyCode;
    if (!(keycode === 8 || keycode === 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      const parts = event.srcElement.value.split('.');
      if (parts.length > 1 && keycode === 46) {
        return false;
      }
      return true;
    }
  }

  dateTableFormat() {
    this.customerInvoiceForm.Date = this.datePipe.transform(this.customerInvoiceForm.Date, 'dd-MM-yyyy');

    this.customerInvoiceForm.ListOfProjectInvoice.forEach(i => {
      this.ListOfProjectInvoiceData.push({
        SerialNo: this.ListOfProjectInvoiceData.length + 1,
        ServiceID: i.ServiceID,
        OrderNo: i.OrderNo,
        OrderDate: this.datePipe.transform(i.OrderDate, 'dd-MM-yyyy'),
        Qty: i.Qty,
        Rate: i.Rate,
        Amount: i.Amount,
        TaxRate: i.TaxRate,
        TaxAmount: i.TaxAmount,
        Total: i.Total,
      });
    });
    this.customerInvoiceForm.ListOfProjectInvoice = this.ListOfProjectInvoiceData;
  }

  onSubmit() {
    let responseMessage = [];
    this.customerInvoiceForm.ListOfProjectInvoice = this.customerInvoiceForm.ListOfProjectInvoice.filter(
      obj => {
        return obj.ServiceID || obj.OrderNo || obj.OrderDate || obj.Qty || obj.Amount || obj.TaxRate || obj.TaxAmount || obj.Total;
      }
    );
    this.dateTableFormat();
    this.projectOrderService.createProjectInvoiceList(this.customerInvoiceForm).subscribe(data => {
      responseMessage = data.ErrorMessages;
      if (responseMessage[2] === '0') {
        this.globalService.showConverted();
      }
    });
    this.resetForm();
    this.getData.emit();
    this.activeModal.close();
  }

  resetForm() {
    this.resetCustomerInvoiceForm();
    this.resetListOfCustomerInvoiceObj();
    this.customerInvoiceForm.ListOfProjectInvoice.push(this.listOfCustomerInvoiceObj);
  }

  reset() {
    this.resetCustomerInvoiceForm();
    this.resetListOfCustomerInvoiceObj();
  }

  OnAddUser() {
    this.addUser = true;
  }

  resetListOfCustomerInvoiceObj() {
    this.listOfCustomerInvoiceObj = {
      SerialNo: '',
      ServiceID: '',
      OrderNo: '',
      OrderDate: '',
      Qty: '',
      Rate: '',
      Disc: '',
      Amount: '',
      TaxRate: '',
      TaxAmount: '',
      Total: '',
    };
  }
  resetCustomerInvoiceForm() {
    this.customerInvoiceForm = {
      InvoiceNo: '',
      Date: '',
      ClientName: '',
      CustomerId: '',
      CurrencyID: '',
      ProjectID: '',
      TaxTypeID: '',
      Remarks: '',
      ExchangeRate: '',

      ListOfProjectInvoice: [],
    };
  }
}

declare class ListOfCustomerInvoiceObj {
  SerialNo: '';
  ServiceID: '';
  OrderNo: '';
  OrderDate: '';
  Qty: '';
  Rate: '';
  Disc: '';
  Amount: '';
  TaxRate: '';
  TaxAmount: '';
  Total: '';


}

declare class CustomerInvoiceForm {
  InvoiceNo: String;
  Date: string;
  ProjectID: String;
  ClientName: String;
  CustomerId: String;
  CurrencyID: String;
  TaxTypeID: String;
  Remarks: String;
  ExchangeRate: String;

  ListOfProjectInvoice: Array<any>;
}
