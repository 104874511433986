<div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h4 class="modal-title text-center">{{EditNew}} Location</h4>
  </div>
  <form [formGroup]="locationForm" novalidate (ngSubmit)="onSubmit(locationForm)">
    <div class="modal-body">
      <div class="extra-pad">
        <div class="">
          <div class="">
  
            <div class="row">
              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="text">
                <span class="text-danger">*</span>Location Name</label>
                <input type="textbox" class="form-control" formControlName="LocationName">
                <input type="hidden"  class="form-control" formControlName="LocationId">
              </div>
              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="text">
                    <span class="text-danger">*</span>Location Code</label>
                <input type="textbox" class="form-control" formControlName="Code">
              </div>

            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text"><span class="text-danger">*</span>Contact Person</label>
                    <input type="textbox" class="form-control" formControlName="ContactPerson">

                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">
                    <span class="text-danger">*</span>PhoneNo</label>
                    <input type="textbox" class="form-control" formControlName="PhoneNo">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">
                        <span class="text-danger">*</span>Email Id</label>
                    <input type="textbox" class="form-control" formControlName="EmailId">
                </div>
            </div>
            <p class="headingclass"> Address Information</p>

            <div formGroupName="BillingAddress" class="row">
              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="text">Street</label>
                <textarea class="form-control" id="address" rows="3" formControlName="Street"></textarea>

                <div class="row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">City</label>
                    <input type="textbox" class="form-control" formControlName="City">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">State/Province</label>
                    <input type="textbox" class="form-control" formControlName="StateName">
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">ZipCode</label>
                    <input type="textbox" class="form-control" formControlName="ZipCode">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Country</label>
                    <input type="textbox" class="form-control" formControlName="Country">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
      <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!locationForm">Save & New</button>
      <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!locationForm">Save</button>
    </div>
  </form>