
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';

@Component({
  selector: 'app-htmltable',
  templateUrl: './htmlTable.component.html',
  styleUrls: ['./htmlTable.component.css']
})
export class HtmlTableComponent implements OnInit {
  @Input() tableData: any;
  @Input() columnName: any;

  constructor(
  ) { }
  ngOnInit() {
    // console.log(this);
  }
}
