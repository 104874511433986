<div class="table-container">
    <div class="row">
        <div class="col-md-12 col-xs-12 col-lg-12 col-sm-12 mb-10">
            <table class="table table-stripped wrapped_text custom_table_cont">
                <thead>
                    <tr class="custom_head">
                        <th *ngFor="let column of columnName" title={{column}}><b>{{column}}</b></th>
                    </tr>
                </thead>
            </table>
            <div class="fixed_header_table">
                <table class="table table-stripped wrapped_text custom_table_cont">
                    <tbody class="fixed_header_table">
                        <tr *ngFor="let table of tableData">
                            <td *ngFor="let column of columnName" title={{table[column]}}>{{table[column]}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>