import { AutocompleteService } from './../../../services/autocomplete/autocomplete.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { SalesOrderService } from '../../../services/salesorder/sales-order.service';
import { VariableService } from '../../../services/VariableService/variable.service';
import { ProjectService } from '../../../services/project/project.service';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-project-sales-order',
  templateUrl: './project-sales-order.component.html',
  styleUrls: ['./project-sales-order.component.css']
})
export class ProjectSalesOrderComponent implements OnInit {
  @Output() getData = new EventEmitter();
  addUser = true;
  public GSTTypeList: AutocompleteModel[];
  public ServiceList: AutocompleteModel[];
  orderForm: UntypedFormGroup;
  projectSalesOrderForm: ProjectSalesOrderForm;
  listOfProjectSalesOrderObj: ListOfProjectSalesOrderObj;
  editOrderData: any;
  orderData: any;
  EditNew: any;
  projectId: any;
  currencyText: any; currencyValue: any;
  currencyfilteredOptions: any[];
  currencyExchangeRate: any;
  exchangeRate: Boolean = false;

  constructor(
    public fb: UntypedFormBuilder,
    private salesorderService: SalesOrderService,
    private variableService: VariableService,
    private projectService: ProjectService,
    public activeModal: NgbActiveModal,
    private masterlookupService: MasterLookupService,
    private autocompleteService: AutocompleteService,
    private datePipe: DatePipe,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    const q: any = this.variableService.getSearchKeys();
    this.projectId = q.paramId;
    this.orderForm = this.fb.group({
      OrderId: null,
      OrderDate: new UntypedFormControl(new Date),
      OrderNo: '',
      ClientName: '',
      DueDate: '',
      Remarks: '',
      ProjectId: '',
      ClientOwnerId: '',
      TaxTypeId: '',
      CurrencyId: '',
      ExchangeRate: '',
      ListOfProjectOrder: new UntypedFormGroup({
        SerialNo: new UntypedFormControl(),
        ServiceID: new UntypedFormControl(),
        NoofHours: new UntypedFormControl(),
        Amount: new UntypedFormControl(),
      }),
    });
    this.resetForm();
    this.getGstTypeList();
    this.getServiceTypeList();
    this.editformData();
    this.getEditNewName();
  }

  addFieldValue(i) {
    this.resetListOfProjectSalesOrderObj();
    const obj = this.projectSalesOrderForm.ListOfProjectSalesOrder[i + 1];
    if (!obj) {
      this.projectSalesOrderForm.ListOfProjectSalesOrder.push(this.listOfProjectSalesOrderObj);
    }
    this.resetListOfProjectSalesOrderObj();
  }
  deleteFieldValue(i: number) {
    if (this.projectSalesOrderForm.ListOfProjectSalesOrder.length === 1) {
      return false;
    } else {
      this.projectSalesOrderForm.ListOfProjectSalesOrder.splice(i, 1);
      return true;
    }
  }
  getEditNewName() {
    if (this.orderForm.value.OrderId === null) {
      this.EditNew = 'New';
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      this.currencyText = user.CurrencyCode;
      this.currencyValue = user.CurrencyId;
      this.orderForm.value.CurrencyId = this.currencyValue;
      this.getProjectorderOwnerName(this.projectId);
    } else {
      this.orderForm.controls['OrderDate'].setValue(new Date(this.orderForm.value.OrderDate));
      this.EditNew = 'Edit';
    }
  }

  getCurrencyList(value) {
    this.autocompleteService.getallCurrencyList(value.data).subscribe(
      res => {
        this.currencyfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }
  setAutoCompleteCurrency(data) {
    const currencyId = data.data.option.value.Value;
    this.orderForm.controls['CurrencyId'].setValue(currencyId);
    if (this.currencyValue !== currencyId) {
      this.exchangeRate = true;
    } else {
      this.exchangeRate = false;
    }
  }

  getGstTypeList() {
    this.masterlookupService.getTaxTypeList('Outward').subscribe(data => {
      this.GSTTypeList = data;
    });
  }

  getServiceTypeList() {
    this.masterlookupService.getServiceList().subscribe(data => {
      this.ServiceList = data;
    });
  }

  getProjectorderOwnerName(projectId) {
    this.projectService
      .getProjectById(projectId, 'CRMProject')
      .subscribe(data => {
        this.orderData = data;
        this.orderForm.patchValue({
          ClientName: this.orderData.ClientName,
          ClientOwnerId: this.orderData.Client
        });
      });
  }

  editformData() {
    if (this.editOrderData !== undefined) {
      this.orderForm.patchValue(this.editOrderData[0]);

      this.currencyText = this.editOrderData[0].CurrencyName;
      this.currencyValue = this.editOrderData[0].CurrencyId;

      const tabledata = this.editOrderData[0].ListOfProjectOrder;
      this.listOfProjectTableData(tabledata);
    }
  }

  listOfProjectTableData(tabledata) {
    this.projectSalesOrderForm['ListOfProjectSalesOrder'] = [];
    tabledata.map((obj, i) => {
      this.projectSalesOrderForm['ListOfProjectSalesOrder'].push(obj);
    });
    this.projectSalesOrderForm['ListOfProjectSalesOrder'].push(this.listOfProjectSalesOrderObj);
  }

  onTableSubmit() {
    this.orderForm.value.OrderDate = this.datePipe.transform(this.orderForm.value.OrderDate, 'yyyy-MM-dd');
    this.orderForm.value.ProjectId = this.projectId;
    this.projectSalesOrderForm.ListOfProjectSalesOrder = this.projectSalesOrderForm.ListOfProjectSalesOrder.filter(
      obj => {
        return obj.ServiceID || obj.NoofHours || obj.Amount;
      }
    );
    const listOfsalesorder = [];
    this.projectSalesOrderForm.ListOfProjectSalesOrder.forEach(i => {
      listOfsalesorder.push({
        // SerialNo: i.SerialNo.length + 1,
        ServiceID: i.ServiceID,
        NoofHours: i.NoofHours,
        Amount: i.Amount,
      });
    });
    this.orderForm.value.ListOfProjectOrder = listOfsalesorder;
  }

  onSubmit(form: UntypedFormGroup) {
    this.onTableSubmit();
    let responseMessage = [];
    if (form.value.OrderId === null) {
      this.salesorderService
        .addProjectToSalesOrder(form.value)
        .subscribe(data => {
          responseMessage = data.ErrorMessages;
          if (responseMessage[1] === '0') {
            this.globalService.showDefaultSuccess({ masterName: 'Order' });
          }
          this.getData.emit();
        });
    } else {
      this.salesorderService
        .editProjectToSalesOrder(form.value)
        .subscribe(data => {
          responseMessage = data.ErrorMessages;
          if (responseMessage[1] === '0') {
            this.globalService.showDefaultUpdate({ masterName: 'Order' });
          }
          this.getData.emit();
        });
    }
    this.globalService.disposeToastr();
    this.activeModal.close();
  }

  resetForm() {
    this.resetProjectSalesOrderForm();
    this.resetListOfProjectSalesOrderObj();
    this.projectSalesOrderForm.ListOfProjectSalesOrder.push(this.listOfProjectSalesOrderObj);
  }

  resetListOfProjectSalesOrderObj() {
    this.listOfProjectSalesOrderObj = {
      SerialNo: '',
      ServiceID: '',
      NoofHours: '',
      Amount: '',
    };
  }
  resetProjectSalesOrderForm() {
    this.projectSalesOrderForm = {
      ListOfProjectSalesOrder: [],
    };
  }

}

declare class ListOfProjectSalesOrderObj {
  SerialNo: '';
  ServiceID: '';
  NoofHours: '';
  Amount: '';
}

declare class ProjectSalesOrderForm {
  ListOfProjectSalesOrder: any;
}
