import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-account-related-detail',
  templateUrl: './account-related-detail.component.html',
  styleUrls: ['./account-related-detail.component.css']
})
export class AccountRelatedDetailComponent implements OnInit {
  @Input() searchKeys;
  constructor() { }

  ngOnInit() {
  }

}
