<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Lead</h4>
</div>
<form [formGroup]="leadForm" novalidate (ngSubmit)="onSubmit(leadForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="">
          <!-- <p class="headingclass">Lead Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Lead Status</label>
              <select class="form-control" formControlName="LeadStatus">
                <!-- <option value=0>--None--</option> -->
                <option *ngFor="let status of leadStatus" value={{status.Value}}> {{status.Text}} </option>
                >
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Lead Owner</label>
              <app-autocomplete [autoCompleteText]="ownerText" (setAutoComplete)="setAutoCompleteEmployee($event)"
                [autoCompleteValue]="ownerValue" (fiteredData)="getEmployeeList($event)" [filteredOptions]="ownerfilteredOptions">
              </app-autocomplete>
            </div>
          </div>
          <div class="row">
            
          </div>
          <div class="row">
            <div class="form-group col-lg-1 col-md-1 col-sm-12 col-xs-12">
              <label for="text">Salutation</label>
              <select class="form-control" formControlName="Salutation">
                <!-- <option value="">--None--</option> -->
                <option value="Mr.">Mr.</option>
                <option value="Ms.">Ms.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Dr.">Dr.</option>
                <option value="Prof.">Prof.</option>
              </select>
            </div>
            <div class="form-group col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <label for="text">First Name</label>
              <input type="textbox" class="form-control" formControlName="FirstName">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Last Name</label>
              <input type="textbox" class="form-control" formControlName="LastName">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Company</label>
              <input type="textbox" class="form-control" formControlName="CompanyName">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Designation</label>
                <input type="textbox" class="form-control" formControlName="Title">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Product Interest</label>
              <app-autocomplete [autoCompleteText]="productText" (setAutoComplete)="setAutoCompleteProduct($event)"
                [autoCompleteValue]="productValue" (fiteredData)="getProductList($event)" [filteredOptions]="productfilteredOptions">
              </app-autocomplete>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Email</label>
              <input type="textbox" class="form-control" formControlName="Email">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="name">Industry</label>
              <select class="form-control" formControlName="IndustryId">
                <!-- <option value=0>--None--</option> -->
                <option *ngFor="let industry of IndustryModel" value={{industry.Value}}>{{industry.Text}}</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Phone</label>
              <input type="textbox" class="form-control" formControlName="Phone">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Lead Source</label>
              <select class="form-control" formControlName="LeadSource">
                <!-- <option value=0>--None--</option> -->
                <option *ngFor="let leadSource of LeadSourceModel" value={{leadSource.Value}}> {{leadSource.Text}}
                </option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Mobile</label>
              <input type="textbox" class="form-control" formControlName="Mobile">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Rating</label>
              <select class="form-control" formControlName="Rating">
                <!-- <option value=0>--None--</option> -->
                <option *ngFor="let rating of RatingModel" value={{rating.Value}}>{{rating.Text}}</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Fax</label>
              <input type="textbox" class="form-control" formControlName="Fax">
            </div>

          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Currency</label>
              <app-autocomplete [autoCompleteText]="currencyText" (setAutoComplete)="setAutoCompleteCurrency($event)"
                [autoCompleteValue]="currencyValue" (fiteredData)="getCurrencyList($event)" [filteredOptions]="currencyfilteredOptions">
              </app-autocomplete>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">LinkedIn</label>
              <input class="form-control" formControlName="LinkdInId" type="textbox">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Lead Value</label>
              <input type="textbox" class="form-control" formControlName="LeadAmount">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Twitter</label>
              <input class="form-control" formControlName="TwitterID" type="textbox">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Branch</label>
              <select class="form-control" formControlName="TerritoryID">
                <!-- <option value=0>--None--</option> -->
                <option *ngFor="let territory of TerritoryList" value={{territory.Value}}> {{territory.Text}}
                </option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Facebook</label>
              <input class="form-control" formControlName="FaceBookID" type="textbox">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text"> Campaigns </label>
              <app-autocomplete [autoCompleteText]="campaignText" (setAutoComplete)="setAutoCompleteCampaign($event)"
                [autoCompleteValue]="campaignValue" (fiteredData)="getCampaignList($event)" [filteredOptions]="campaignfilteredOptions">
              </app-autocomplete>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">List Type</label>
              <select class="form-control" formControlName="ListTypeID">
                <option *ngFor="let listType of ListTypeData" value={{listType.Value}}> {{listType.Text}}
                </option>
              </select>
            </div>
            <!-- <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Skype ID</label>
              <input class="form-control" formControlName="SkypeID" type="textbox">
            </div> -->
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Number Of Locations</label>
              <input type="textbox" class="form-control" formControlName="NoOfLocations">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Website</label>
              <input type="textbox" class="form-control" formControlName="Website">
            </div>
          </div>




          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="exchangeRate===true">
              <label for="text">Exchange Rate</label>
              <input type="text" class="form-control" formControlName="ExchangeRate">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" rows="3" formControlName="Description"></textarea>
            </div>
          </div>

          <p class="headingclass"> Address Information</p>

          <div formGroupName="BillingAddress" class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Street</label>
              <textarea class="form-control" id="address" rows="3" formControlName="Street"></textarea>

              <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label for="text">City</label>
                  <input type="textbox" class="form-control" formControlName="City">
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label for="text">State/Province</label>
                  <input type="textbox" class="form-control" formControlName="StateName">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label for="text">ZipCode</label>
                  <input type="textbox" class="form-control" formControlName="ZipCode">
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label for="text">Country</label>
                  <input type="textbox" class="form-control" formControlName="Country">
                </div>
              </div>
            </div>
          </div>

          <!-- <p class="headingclass"> Additional Information</p>
          <div class="row">
            

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Current Generator(s)</label>
              <input type="textbox" class="form-control" formControlName="CurrentGenerators">
            </div>
          </div> -->
          
          <!-- <p class="headingclass">Description Information</p> -->
          
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!leadForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!leadForm">Save</button>
  </div>
</form>