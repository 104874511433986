import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';

@Injectable()
export class ResourceService {

  constructor(
    private apiService: ApiService,
  ) { }
  addResource(form) {
    return this.apiService.post('api/resource/create', form);
  }
  resourceById(resourceId) {
    return this.apiService.get(`api/resource/getbyid?Id=${resourceId}&`);
  }
  updateResource(form) {
    return this.apiService.put('api/resource/edit', form);
  }
}
