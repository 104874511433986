import { ClientCustomerService } from './../../../../services/client-customer/client-customer.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-quotation-related',
  templateUrl: './quotation-related.component.html',
  styleUrls: ['./quotation-related.component.css']
})
export class QuotationRelatedComponent implements OnInit {
  @Input() searchKeys;
  quoteData: Array<any> = [];
  columnName = [];
  constructor(
    private customerClientService: ClientCustomerService,
  ) {
    this.columnName = [];
  }

  ngOnInit() {
    this.getAllInvoices();
  }

  getAllInvoices() {
    this.customerClientService.getCustomerQuote().subscribe(res => {
      this.quoteData = res.Data;
      const columns = [];
      const columnsIn = res.Data[0];
      // tslint:disable-next-line:forin
      for (const key in columnsIn) {
        columns.push(key);
        this.columnName = columns;
      }
    });
  }

}
