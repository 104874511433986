import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { VariableService } from '../../../services/VariableService/variable.service';
import { AccountService } from '../../../services/accounts/account.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  searchKeys: object = {};
  selectedPage: any = {};
  RealtedOn = true;
  NewsOn = false;
  DetailOn = false;
  newChatterOn = false;
  activityOn = true;
  showSpinner: Boolean = true;
  accountData: any;
  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMAccount',
      });

      this.accountService.editByAccountId(params.id).subscribe(data => {
        this.accountData = data;
        this.showSpinner = false;
      });
    });
  }

  OnRelated() {
    this.RealtedOn = true;
    this.NewsOn = false;
    this.DetailOn = false;
  }

  onActivity() {
    this.newChatterOn = false;
    this.activityOn = true;
  }
  OnDetail() {
    this.DetailOn = true;
    this.RealtedOn = false;
    this.NewsOn = false;
  }

  OnChatter() {
    this.activityOn = false;
    this.newChatterOn = true;
  }

  OnNews() {
    this.NewsOn = true;
    this.DetailOn = false;
    this.RealtedOn = false;
  }
}
