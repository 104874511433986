import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-new-pos',
  templateUrl: './new-pos.component.html',
  styleUrls: ['./new-pos.component.css']
})
export class NewPosComponent implements OnInit {
  @Output() addToList = new EventEmitter();
  @Input() getPosData: any;
  constructor() { }

  ngOnInit() {
  }

}
