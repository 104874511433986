import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pos-group-list',
  templateUrl: './pos-group-list.component.html',
  styleUrls: ['./pos-group-list.component.css']
})
export class PosGroupListComponent implements OnInit {
  @Input() getGroupList: any;
  @Output() getItemList = new EventEmitter();
  constructor() { }
  selectedIndex: number;
  ngOnInit() {
  }

  select(index: number) {
      this.selectedIndex = index;
  }

}
