import { Injectable } from "@angular/core";
// import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { ApiService } from "../ApiService/api.service";
// import { userLocation } from "./user-tracking.model";

@Injectable()
export class UserTrackingService {
  locationData: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  watchLocationData$ = this.locationData.asObservable();
  // selectedEmployee: userLocation = new userLocation();
  constructor(
    private apiService: ApiService,
    // private firebase: AngularFireDatabase
  ) {}

  // getEmployeesList() {
  //   this.apiService.get()
  // }

  getEmployeeLocation(date, id) {
    const path = `locations/${date}/${id}`;
    console.log("path====", path);
    // this.firebase.database.ref(path).once("value", snapshot => {
    //   this.formatLocationData(snapshot);
    //   console.log("this.locationData", snapshot.val());
    //   this.formatLocationData(snapshot.val());
    // });

    // console.log("this.locationData", locationData);
  }

  getEmployeesList() {
    return this.apiService.get("api/userProfile/getall?");
  }

  formatLocationData(snapshot) {
    // var i = 0;
    let data = [];
    for (let key in snapshot) {
      const snap = snapshot[key];
      // data.push(new google.maps.LatLng(snap.latitude, snap.longitude));
    }

    this.locationData.next(data);
    // snapshot.forEach(function(userSnap) {
    //   console.log(
    //     "user %s is in position %d with %d points",
    //     snapshot.key(),
    //     i++,
    //     snapshot.val()
    //   );
    // });
  }
}
