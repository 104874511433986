<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
        <span class="menu_icon CRMLeads glyphicon glyphicon-briefcase">
        </span>
        <h3 class="report_page_title">
            <p class="page_title_cont">Dropzone</p>
        </h3>
    </div>
  </div>
  <div class="min_top">
    <div class="status_column_cont">
      <div class="status_column_holder">
        <div class="status_column_section">
          <div class="status_title_cont">
              <span class="status_title">Unassigned({{availableBoxes.length}})</span>
          </div>
          <div class="dropzone">
            <div *ngFor="let box of availableBoxes" class="box" appDroppable (dragStart)="currentBox = box">
              {{ box.Name }}
              <div class="box box-helper" *appDraggableHelper>{{ box.Name }}</div>
            </div>
          </div>
        </div>
        <div class="status_column_section">
          <div class="status_title_cont">
              <span class="status_title">Progress({{dropzone1.length}})</span>
          </div>
          <div class="dropzone" appDropzone (drop)="move(currentBox, dropzone1)" (remove)="move(currentBox, availableBoxes)">
            <div *ngFor="let box of dropzone1" class="box" appDroppable (dragStart)="currentBox = box">
              {{ box.Name }}
              <div class="box" *appDraggableHelper>{{ box.Name }}</div>
            </div>
          </div>
        </div>
        <div class="status_column_section">
            <div class="status_title_cont">
                <span class="status_title">Processed({{dropzone2.length}})</span>
            </div>
            <div class="dropzone" appDropzone (drop)="move(currentBox, dropzone2)" (remove)="move(currentBox, availableBoxes)">
              <div *ngFor="let box of dropzone2" class="box" appDroppable (dragStart)="currentBox = box">
                {{ box.Name }}
                <div class="box" *appDraggableHelper>{{ box.Name }}</div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>