<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon role fa fa-users ">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Role</p>
        <p class="page_sub_title">{{editName}}</p>

      </h3>
    </div>
  </div>

  <div class="bg_white_margin_top min_top">
    <form [formGroup]="roleForm" novalidate (ngSubmit)="onSubmit(roleForm)">
      <div class="form-group">
        <label class="control-label col-sm-2" for="text">Role Name</label>
        <div class="col-sm-4">
          <input type="text" class="form-control" formControlName="RoleName">
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-sm-2" for="text">Description</label>
        <div class="col-sm-4">
          <input type="text" class="form-control" formControlName="Description">
        </div>
      </div>

      <button type="button" class="btn btn-default" (click)="resetForm()">Cancel</button>
      <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!roleForm">Save</button>
    </form>
  </div>

  <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>

  <app-htmldatatable [tableData]="roleData" [columnName]="columnName" [columnRowName]="columnRowName" (edit)="edit($event)"
    (onDelete)="onDelete($event)">
  </app-htmldatatable>
</div>