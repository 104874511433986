<div class="body_inner">
    <div class="heading_cont">
        <div class="pull-left">
            <span class="menu_icon reports glyphicon glyphicon-th-list">
            </span>
            <h3 class="report_page_title">
                <p class="page_title_cont">List of Reports</p>
            </h3>
        </div>
    </div>
    <div class="bg_white_margin_top report_table_list pb-4">
        <div class="mb-10 min_btn_height pt-2 pl-2 pr-2">
            <button (click)="addReport()" class="btn btn-primary pull-right">Add New Report</button>
            <input (keyup)="filterRows($event)" placeholder="Search" class="pull-right  mr-4 search" />
        </div>
        <div class="row max-scroll mt-4">
            <div class="col-md-12" *ngIf="reports">
                <app-items [removeCloseIcon]="true" [tableHeader]="tableHeader" [itemArr]="reports" [cancelbutton]=false (addData)="addData($event)" [afterSaveReset]=false [addEditTable]=false [headingTitle]="d" (edit)="editLineItem($event)" [pagescroll]=false></app-items>
            </div>
        </div>
    </div>
</div>