import { Component, OnInit } from '@angular/core';
import { MachineService } from '../../../services/machine/machine.service';
import { ActivatedRoute } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';



@Component({
  selector: 'app-machine-details',
  templateUrl: './machine-details.component.html',
  styleUrls: ['./machine-details.component.css']
})
export class MachineDetailsComponent implements OnInit {
  machineDetials:any;
  showSpinner=true;
  selectedTab='Planogram';
  activityOn=true;
  constructor(private machineService:MachineService,
    private route: ActivatedRoute,
    private variableService: VariableService,
    ) { }

  ngOnInit(): void {
    this.getMachine();
  }

  getMachine(){
    this.route.params.subscribe(params => {

      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMMachines'
      });

      this.machineService.editByMachineId(params.id).subscribe(data => {
        this.machineDetials = data;
        this.showSpinner = false;
      });
    });
  }
  selectTab(){

  }
}
