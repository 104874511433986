<div class="body_inner">
    <!-- <template name="mailbox"> -->

    <div class="buttonNew" (click)="openGmailLogin()">Gmail</div>
    <!-- *ngIf="IsEmailAuthenticated" -->
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-3">
                <div class="ibox float-e-margins">
                    <div class="ibox-content mailbox-content">
                        <div class="file-manager">
                            <a class="btn btn-block btn-primary compose-mail" href="#">Compose Mail</a>
                            <div class="space-25"></div>
                            <h5>Folders</h5>
                            <ul class="folder-list m-b-md" style="padding: 0">
                                <li>
                                    <a href="#">
                                        <i class="fa fa-inbox "></i> Inbox
                                        <span class="label label-warning pull-right">16</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-envelope-o"></i> Send Mail</a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-certificate"></i> Important</a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-file-o"></i> Drafts
                                        <span class="label label-danger pull-right">2</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-trash-o"></i> Trash</a>
                                </li>
                            </ul>
                            <h5>Categories</h5>
                            <ul class="category-list" style="padding: 0">
                                <li>
                                    <a href="#">
                                        <i class="fa fa-circle text-navy"></i> Work </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-circle text-danger"></i> Documents</a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-circle text-primary"></i> Social</a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-circle text-info"></i> Advertising</a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-circle text-warning"></i> Clients</a>
                                </li>
                            </ul>

                            <h5 class="tag-title">Labels</h5>
                            <ul class="tag-list" style="padding: 0">
                                <li>
                                    <a href="">
                                        <i class="fa fa-tag"></i> Family</a>
                                </li>
                                <li>
                                    <a href="">
                                        <i class="fa fa-tag"></i> Work</a>
                                </li>
                                <li>
                                    <a href="">
                                        <i class="fa fa-tag"></i> Home</a>
                                </li>
                                <li>
                                    <a href="">
                                        <i class="fa fa-tag"></i> Children</a>
                                </li>
                                <li>
                                    <a href="">
                                        <i class="fa fa-tag"></i> Holidays</a>
                                </li>
                                <li>
                                    <a href="">
                                        <i class="fa fa-tag"></i> Music</a>
                                </li>
                                <li>
                                    <a href="">
                                        <i class="fa fa-tag"></i> Photography</a>
                                </li>
                                <li>
                                    <a href="">
                                        <i class="fa fa-tag"></i> Film</a>
                                </li>
                            </ul>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 animated fadeInRight">
                <div class="mail-box-header">

                    <form method="get" action="/dashboard1" class="pull-right mail-search">
                        <div class="input-group">
                            <input type="text" class="form-control input-sm" name="search" placeholder="Search email">
                            <div class="input-group-btn">
                                <button type="submit" class="btn btn-sm btn-primary">
                                    Search
                                </button>
                            </div>
                        </div>
                    </form>
                    <h2>
                        Inbox (16)
                    </h2>
                    <div class="mail-tools tooltip-demo m-t-md">
                        <div class="btn-group pull-right">
                            <button class="btn btn-white btn-sm">
                                <i class="fa fa-arrow-left"></i>
                            </button>
                            <button class="btn btn-white btn-sm">
                                <i class="fa fa-arrow-right"></i>
                            </button>

                        </div>
                        <button class="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="Refresh inbox">
                            <i class="fa fa-refresh"></i> Refresh</button>
                        <button class="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Mark as read">
                            <i class="fa fa-eye"></i>
                        </button>
                        <button class="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Mark as important">
                            <i class="fa fa-exclamation"></i>
                        </button>
                        <button class="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Move to trash" (click)="hideDetail()">
                            <i class="fa fa-trash-o"></i>
                        </button>

                    </div>
                </div>
                <div class="mail-box">

                    <table class="table table-hover table-mail" *ngIf="!showDetail">
                        <tbody>
                            <!-- <tr class="unread">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Anna Smith</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Lorem ipsum dolor noretek imit set.</a>
                                </td>
                                <td class="">
                                    <i class="fa fa-paperclip"></i>
                                </td>
                                <td class="text-right mail-date">6.10 AM</td>
                            </tr> -->
                            <!-- <tr class="unread">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks" checked>
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Jack Nowak</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Aldus PageMaker including versions of Lorem Ipsum.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">8.22 PM</td>
                            </tr> -->
                            <tr class="read" *ngFor="let message of messages; let i = index" (click)="showEmailDetail(i)">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="javascript:void(0)">{{message.from}}</a>
                                    <!-- <span class="label label-warning pull-right">Clients</span> -->
                                </td>
                                <td class="mail-subject">
                                    <a href="javascript:void(0)">{{message.subject}}</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">{{message.date}}</td>
                            </tr>
                            <!-- <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Mailchip</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">There are many variations of passages of Lorem Ipsum.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">Mar 22</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Alex T.</a>
                                    <span class="label label-danger pull-right">Documents</span>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Lorem ipsum dolor noretek imit set.</a>
                                </td>
                                <td class="">
                                    <i class="fa fa-paperclip"></i>
                                </td>
                                <td class="text-right mail-date">December 22</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Monica Ryther</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">The standard chunk of Lorem Ipsum used.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">Jun 12</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Sandra Derick</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Contrary to popular belief.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">May 28</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Patrick Pertners</a>
                                    <span class="label label-info pull-right">Adv</span>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">If you are going to use a passage of Lorem </a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">May 28</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Michael Fox</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Humour, or non-characteristic words etc.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">Dec 9</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Damien Ritz</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Oor Lorem Ipsum is that it has a more-or-less normal.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">Jun 11</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Anna Smith</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Lorem ipsum dolor noretek imit set.</a>
                                </td>
                                <td class="">
                                    <i class="fa fa-paperclip"></i>
                                </td>
                                <td class="text-right mail-date">6.10 AM</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Jack Nowak</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Aldus PageMaker including versions of Lorem Ipsum.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">8.22 PM</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Mailchip</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">There are many variations of passages of Lorem Ipsum.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">Mar 22</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Alex T.</a>
                                    <span class="label label-warning pull-right">Clients</span>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Lorem ipsum dolor noretek imit set.</a>
                                </td>
                                <td class="">
                                    <i class="fa fa-paperclip"></i>
                                </td>
                                <td class="text-right mail-date">December 22</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Monica Ryther</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">The standard chunk of Lorem Ipsum used.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">Jun 12</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Sandra Derick</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Contrary to popular belief.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">May 28</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Patrick Pertners</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">If you are going to use a passage of Lorem </a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">May 28</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Michael Fox</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Humour, or non-characteristic words etc.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">Dec 9</td>
                            </tr>
                            <tr class="read">
                                <td class="check-mail">
                                    <input type="checkbox" class="i-checks">
                                </td>
                                <td class="mail-ontact">
                                    <a href="#">Damien Ritz</a>
                                </td>
                                <td class="mail-subject">
                                    <a href="#">Oor Lorem Ipsum is that it has a more-or-less normal.</a>
                                </td>
                                <td class=""></td>
                                <td class="text-right mail-date">Jun 11</td>
                            </tr> -->
                        </tbody>
                    </table>
                    <div *ngIf="showDetail">
                        <iframe id="message-iframe" srcdoc="{{messageBody}}" width="100%" height="100%" style="border: none">
                        </iframe>
                    </div>
                    <div *ngIf="attachments && attachments.length > 0">
                        <a *ngFor="let attachment of attachments" download="{{attachment.filename}}" (click)="openAttachment(attachment.body.data, attachment.filename, attachment.mimeType)"
                            href="javascript:void(0)">
                            <img src="../../../../../../assets/images/pdf.jpg" width="30" />{{attachment.filename}}</a>
                    </div>
                    <!-- <div>
                        <iframe id="message-iframe" srcdoc="{{messageBody}}" width="100%" height="450px" style="border: none">
                        </iframe>
                    </div> -->

                </div>

            </div>
        </div>
    </div>

    <!-- </template> -->
</div>