import { EmployeeInfoService } from './../../../services/employeeInfo/employee-info.service';
import { EmailService } from './../../../services/common/activity-page/email/email.service';
import { NewEventService } from './../../../services/common/activity-page/newEvent/new-event.service';
import { AutocompleteService } from './../../../services/autocomplete/autocomplete.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as Utils from '../../../../../../app/common/utils';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
    selector: 'app-event-new',
    templateUrl: './event-new.component.html',
    styleUrls: ['./event-new.component.css']
})
export class EventNewComponent implements OnInit {
    @Input() eventFormData: any;
    @Output() getData = new EventEmitter();
    @Input() optionValue: any;
    addUser = true;
    selectedName = '';
    selectedRelatedType = '';
    public userEmailList: any;
    toAddress = null;
    view: String = 'month';
    timeData = [];
    eventForm: UntypedFormGroup;
    currentStartTime: any;
    currentEndTime: any;
    eventTableForm: EventTableForm;
    listOfEventTableObj: ListOfEventTableObj;
    eventTableData: any;
    CRMEmployeeAssignedTo = [];
    selectedOption: any;
    textBody: any;
    fromAddress: any;
    fromName: any;
    masterTypeText: any;
    EditNew: any;
    contactLeadFilterOption: any[];
    relatedTypeFilterOption: any[];
    contactLeadValue: any; contactLeadText: any;
    RelatedText: any; RelatedValue: any;

    NameTypeList = this.globalService.NameTypeList;

    RelatedTypeList = this.globalService.RelatedTypeList;

    constructor(
        public fb: UntypedFormBuilder,
        private autocompleteService: AutocompleteService,
        private eventService: NewEventService,
        private emailService: EmailService,
        private employeeInfoService: EmployeeInfoService,
        private datePipe: DatePipe,
        public activeModal: NgbActiveModal,
        private globalService: GlobalService,
    ) { }

    ngOnInit() {
        this.eventForm = this.fb.group({
            EventId: null,
            Subject: '',
            IsAllDayEvent: '',
            Location: '',
            AssignedToId: '',
            RelatedToId: '',
            EndDate: Date(),
            StartDate: Date(),
            ContactId: '',
            NameType: '',
            RelatedType: '',
            StartTime: '',
            EndTime: '',
            CRMEmployeeAssigned: new UntypedFormGroup({
                ParticipantID: new UntypedFormControl(),
                Type: new UntypedFormControl(),
            }),
        });
        this.resetEventTableForm();
        this.resetListOfEventTableObj();
        this.eventTableForm.ListOfEventTable.push(this.listOfEventTableObj);
        this.getTimeData();
        this.getCurrentTime();
        this.editformData();
        this.getEditNewName();
    }
    getEditNewName() {
        if (this.eventForm.value.EventId === null) {
            this.EditNew = 'New';
            this.selectedName = 'Contact';
            this.selectedRelatedType = 'Account';
        } else {
            this.eventForm.controls['StartDate'].setValue(new Date(this.eventForm.value.StartDate));
            this.EditNew = 'Edit';
            this.selectImg();
        }
    }

    selectImg() {
        if (this.eventFormData.NameType === '') {
            this.selectedName = 'Contact';
            this.selectedRelatedType = this.eventFormData.RelatedType;
        } else if (this.eventFormData.RelatedType === '') {
            this.selectedRelatedType = 'Account';
            this.selectedName = this.eventFormData.NameType;
        }
    }

    editformData() {
        if (this.eventFormData !== undefined) {
            this.contactLeadText = this.eventFormData.ContactLeadName;
            this.contactLeadValue = this.eventFormData.ContactId;

            this.RelatedText = this.eventFormData.RelatedToName;
            this.RelatedValue = this.eventFormData.RelatedToId;

            this.eventForm.patchValue(this.eventFormData);
            this.eventTableData = this.eventFormData.CRMEmployeeAssigned;
            this.listOfEventTableData();
        }
    }

    listOfEventTableData() {
        this.eventTableForm['ListOfEventTable'] = [];
        this.eventTableData.map((obj, i) => {
            obj.autoCompleteText = obj.ParticipantName;
            obj.autoCompleteValue = obj.ParticipantID;
            this.eventTableForm['ListOfEventTable'].push(obj);
        });
        this.eventTableForm['ListOfEventTable'].push(this.listOfEventTableObj);
    }

    addFieldValue(i) {
        this.resetListOfEventTableObj();
        const obj = this.eventTableForm.ListOfEventTable[i + 1];
        if (!obj) {
            this.eventTableForm.ListOfEventTable.push(this.listOfEventTableObj);
        }
        this.resetListOfEventTableObj();
    }
    deleteFieldValue(i: number) {
        if (this.eventTableForm.ListOfEventTable.length === 1) {
            return false;
        } else {
            this.eventTableForm.ListOfEventTable.splice(i, 1);
            return true;
        }
    }

    getRealtedTypeList(value) {
        const relatedValue = this.eventForm.value.RelatedType;
        if (relatedValue !== '') {
            this.autocompleteService.getAllRelatedTypeList(relatedValue, value.data).subscribe(
                res => {
                    this.relatedTypeFilterOption = res;
                },
                err => {
                    console.log('errr====', err);
                });
        }
    }

    setAutocompleteRelatedType(data) {
        const relatedTo = data.data.option.value.Value;
        this.masterTypeText = data.data.option.value.Text;
        this.eventForm.controls['RelatedToId'].setValue(relatedTo);
    }

    getLeadContactList(value) {
        const optionValue = this.eventForm.value.NameType;
        if (optionValue !== '') {
            this.autocompleteService.getLeadContactList(optionValue, value.data).subscribe(
                res => {
                    this.contactLeadFilterOption = res;
                },
                err => {
                    console.log('errr====', err);
                });
        }
    }

    setcontactLeadType(data) {
        const contactLeadTo = data.data.option.value.Value;
        this.eventForm.controls['ContactId'].setValue(contactLeadTo);
    }

    onTypeSelect(eventValue) {
        this.optionValue = eventValue.value;
    }

    fiteredData(value) {
        this.selectedOption = {};
        const type = this.eventTableForm.ListOfEventTable[value.indexValue].Type;
        this.autocompleteService.commonAutocomplete(type, value.data).subscribe(
            res => {
                this.eventTableForm.ListOfEventTable[value.indexValue].filteredOptions = res;
            },
            err => {
                console.log('errr====', err);
            }
        );
    }

    setAutoComplete(data) {
        const value = data.data.option.value.Value;
        this.eventTableForm.ListOfEventTable[data.index].ParticipantID = value;
    }

    getCurrentTime() {
        const time = new Date();
        this.currentStartTime = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        this.currentEndTime = this.currentStartTime;
    }

    getTimeData() {
        this.timeData = Utils.getTime();
    }

    onEndTimeChange(event) {
        this.currentEndTime = event;
    }

    onStartTimeChange(event) {
        this.currentStartTime = event;
    }

    onSubmit(form: UntypedFormGroup) {
        this.eventForm.value.StartDate = this.datePipe.transform(this.eventForm.value.StartDate, 'yyyy-MM-dd');
        this.eventForm.value.EndDate = this.datePipe.transform(this.eventForm.value.EndDate, 'yyyy-MM-dd');

        this.eventTableForm.ListOfEventTable = this.eventTableForm.ListOfEventTable.filter(
            obj => {
                return obj.Type || obj.ParticipantID;
            }
        );
        this.eventTableForm.ListOfEventTable.forEach(i => {
            this.CRMEmployeeAssignedTo.push({
                ParticipantID: i.ParticipantID,
                Type: i.Type,
            });
        });
        this.eventForm.value.CRMEmployeeAssigned = this.CRMEmployeeAssignedTo;
        this.createPromiseArray(this.CRMEmployeeAssignedTo);
    }

    createPromiseArray(data) {
        const promiseArray = [];

        data.forEach(dataObj => {
            if (dataObj.Type === 'user') {
                const pro = new Promise((resolve, reject) => {
                    this.employeeInfoService.getEmployeeInfoList(dataObj.ParticipantID).subscribe(response => {
                        resolve(response);
                    }, err => {
                        reject(err);
                    });
                });
                promiseArray.push(pro);
            }
        });
        this.requestUsersEmail(promiseArray);
    }

    requestUsersEmail(promiseArray) {
        return Promise.all(promiseArray).then(res => {
            this.userEmailList = res;
            this.submitform();

        }).catch(err => {
            console.log('error', err);
        });
    }

    submitform() {
        const user = JSON.parse(sessionStorage.getItem('currentUser'));

        const emailListData = [];
        let responseMessage = [];

        const eventName = this.eventForm.value.Subject;
        this.fromAddress = user.EmailId;
        this.fromName = user.FirstName + ' ' + user.LastName;

        this.userEmailList.forEach((element, i) => {
            emailListData.push(element.EmailID);
        });
        this.toAddress = emailListData.join(',');

        this.textBody = 'Dear' + ' ' + this.fromName + ',' + '<br/>' + '<br/>' +
            '<strong>' + 'Subject :' + '</strong>' + ' ' + 'New Event' + ' " ' + this.eventForm.value.Subject + ' " .' + '<br/>' + '<br/>' +
            'New Event' + ' " ' + this.eventForm.value.Subject + ' " ' + 'related to' + ' ' +
            this.eventForm.value.RelatedType + ' " ' + this.masterTypeText + ' " ' + 'is schedule on '
            + this.eventForm.value.StartDate + ' - ' + this.eventForm.value.StartTime + ' to '
            + this.eventForm.value.EndDate + ' - ' + this.eventForm.value.EndTime + '.' +
            '<br/>' + 'So please allocate your time.'
            + '<br/>' + '<br/>' +
            'Regards, ' + '<br/>' + this.fromName + '<br/>' + user.CompanyName + '<br/>';

        if (this.eventForm.value.EventId === null) {
            this.eventService.addNewEvent(this.eventForm.value).subscribe(data => {
                responseMessage = data.ErrorMessages;
                if (responseMessage[1] === '0') {
                    this.globalService.showSuccess({ masterName: 'Event', name: eventName });
                    if (this.fromAddress != null && this.toAddress !== null) {
                        this.emailService.sendEmail({
                            Subject: eventName,
                            FromAddress: this.fromAddress,
                            ToAddress: this.toAddress,
                            FromName: this.fromName,
                            TextBody: this.textBody,
                            RelatedToId: this.eventForm.value.RelatedToId,
                        }).subscribe(senddata => { });
                    }
                }
            });
            this.eventForm.reset();
        } else {
            this.eventService.addNewEvent(this.eventForm.value).subscribe(data => {
                responseMessage = data.ErrorMessages;
                if (responseMessage[1] === '0') {
                    this.globalService.showUpdate({ masterName: 'Event', name: eventName });

                }
                this.getData.emit();
            });
        }
        this.eventForm.reset();
        this.globalService.disposeToastr();
        this.activeModal.close();
    }

    resetListOfEventTableObj() {
        this.listOfEventTableObj = {
            ParticipantID: '',
            Type: '',
        };
    }
    resetEventTableForm() {
        this.eventTableForm = {
            ListOfEventTable: [],
        };
    }
}

declare class ListOfEventTableObj {
    ParticipantID: '';
    Type: '';
}

declare class EventTableForm {
    ListOfEventTable: any;
}

