import { Component, OnInit } from '@angular/core';
import { TaskService } from '../../../services/task/task.service';

@Component({
  selector: 'app-task-follow-up',
  templateUrl: './task-follow-up.component.html',
  styleUrls: ['./task-follow-up.component.css']
})
export class TaskFollowUpComponent implements OnInit {
  columnName: any[];
  taskfollowUpData: any;
  constructor(
    private taskService: TaskService,
  ) { }

  ngOnInit() {
    this.columnName = [];
    this.taskfollowUpData = [];
    this.getAllTaskFollowUp();
  }

  getAllTaskFollowUp() {
    this.taskService.getTaskFollowUp().subscribe(res => {
      const columns = [];
      if (res.Data.length > 0) {
        const columnsIn = res.Data[0];
        for (const key in columnsIn) {
          columns.push(key);
        }
      } else {
        console.log('No columns');
      }
      this.columnName = columns;
      this.taskfollowUpData = res.Data;
    });
  }
}
