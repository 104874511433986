<div class="tab_title">
  <h3 class="page_title pull-left">
    <span class="icon_holder new_contact">
      <i class="fa fa-address-card fa-fw" aria-hidden="true"></i>
    </span>
    Related Contacts ({{contacts.length}})
  </h3>
</div>

<div class="related_details">
  <div class="row">
    <div class="col-md-6 col-xs-12 mb-10" *ngFor="let item of contacts">
      <a class="dottedLine" (click)="gotoDetailPage(item.ContactId)" href="javascript:void(0)">{{item.FirstName +' '+
        item.LastName}}</a>
      <table class="custom_table">
        <tr>
          <th class="custom_name">Title</th>
          <th class="custom_colon">:</th>
          <td class="caseheadLine">{{item.Title}}</td>
        </tr>

        <tr>
          <th class="custom_name">Email</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{item.Email}}</td>
        </tr>

        <tr>
          <th class="custom_name">Phone</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{item.Phone}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>