import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';

@Injectable()
export class ProjectTaskTemplateService {

  constructor(
    private apiService: ApiService,
  ) { }

  createTaskOfMilestone(form) {
    return this.apiService.post('api/taskOfMilestone/create', form);
  }

  getAllProjectTaskTemplate(templateId) {
    return this.apiService.get(`api/projectTaskTemplate/getall?Id=${templateId}&`);
  }

}
