<ng-template #termModel let-c="close" let-d="dismiss" style="pos-rel">
  <div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label for="text">Template Name</label>
        <select class="form-control" (change)="getTemplateValue($event.target.value)">
          <option value=0>--None--</option>
          <option *ngFor="let template Of TemplateList" value={{template.Value}}>{{template.Text}} </option>
        </select>
      </div>
    </div>
  
    <div class="row">
      <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <table class="table table-bordered dynomic_table">
          <thead>
            <tr>
              <th class="number_small">S.No.</th>
              <th class="name_double">Term Name</th>
              <th class="number_short">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let list of termForm.ListOfTerm; let i=index">
              <td>
                {{i+1}}
              </td>
              <td>
                <input type="text" [(ngModel)]="list.TermsName" class="form-control" (click)="addFieldValue()">
              </td>
  
              <td>
                <div class="btn-group" dropdown>
                  <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
                  <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <button type="button" class="btn btn-primary" (click)="saveTerms()">Save</button>
    </div>
  </div>
</ng-template>


<div class="">
  <div class="buttonHeader text-right mb-3">
      <button class="buttonNew" (click)="openTermsForm(termModel)">Add/Edit</button>
  </div>
  <app-htmltable [tableData]="tableData" [columnName]="columnName"></app-htmltable>
</div>
