<div class="body_inner">
  <div class="row">
    <div class="topheader col-md-12">
      <div class="topheadertitle">
        <span class="menu_icon CRMContacts glyphicon glyphicon-book">
        </span>
        <h3 class="report_page_title">
          <p class="page_title_cont">Contact</p>
          <p class="page_sub_title">{{contactData?.ContactName}}</p>
        </h3>
      </div>

      <div class="">
        <div class="topdownheader ">
          <div class="">
            <div class="block">
              <label class="labelName">Title</label>
              <p>{{contactData?.Title}}</p>
            </div>

            <div class="block">
              <label class="labelName">Account Name</label>
              <p>
                <a class="dottedLine" (click)="gotoDetailPage(contactData.AccountId)" href="javascript:void(0)">{{contactData?.AccountName}}</a>
              </p>
            </div>

            <div class="block">
              <label class="labelName">Phone</label>
              <p>{{contactData?.Phone}}</p>
            </div>

            <div class="block">
              <label class="labelName">Mobile</label>
              <p>{{contactData?.MobilePhone}}</p>
            </div>

            <div class="block">
              <label class="labelName">Email</label>
              <p>{{contactData?.Email}}</p>
            </div>

            <div class="block">
              <label class="labelName">Contact Owner</label>
              <p>
                <img src="/assets/images/businessman.png" class="iconClass">
                <span class="ownerHeading">{{contactData?.ContactOwnerName}}</span>
              </p>
            </div>

            <!-- <div class="block">
              <label class="labelName">Skype</label>
              <p class="ownerHeading" style="cursor: pointer;" (click)="skypeChat(contactData.SkypeID)">
                <span class="fab fa-skype"></span>
                <span class="skype-chat"></span>
                {{contactData?.SkypeID}}
              </p>
            </div> -->


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="">
    <div class="row ">
      <div class="col-md-8 lowpadding-right">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>
              <li class="realted">
                <a class="" (click)="OnRelated()" [ngClass]="{'active': RealtedOn===true}">Related</a>
              </li>
              <li class="realted">
                <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Details</a>
              </li>
              <li class="realted">
                <a class="" (click)="OnNews()" [ngClass]="{'active': NewsOn===true}">NEWS</a>
              </li>
            </ul>
          </div>

          <ul class="cl custom_content_tab">
            <li class="nav-item ">
              <app-contact-related-detail [searchKeys]="searchKeys" *ngIf="RealtedOn===true"></app-contact-related-detail>
            </li>
            <li class="nav-item disabled_form">
              <app-contact-detail [contactData]="contactData" *ngIf="DetailOn===true && contactData.ContactId"></app-contact-detail>
            </li>
            <li class="nav-item">
              <app-news *ngIf="NewsOn===true"></app-news>
            </li>
          </ul>
        </nav>
      </div>

      <div class="col-md-4 bg_white_margin_top no_boxshadow">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>
              <li class="realted">
                <a class="" (click)=onActivity() [ngClass]="{'active': activityOn===true}">Activity</a>
              </li>
              <li class="realted">
                <a class="" (click)=OnChatter() [ngClass]="{'active': newChatterOn===true}">Chatter</a>
              </li>
            </ul>
          </div>

          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <app-activity *ngIf="activityOn===true"></app-activity>
            </li>
            <li class="nav-item">
              <app-chatter *ngIf="newChatterOn===true"></app-chatter>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
</div>