import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';

@Injectable()
export class DeleteService {

  constructor(
    private apiService: ApiService,
  ) { }

  deleteMasterData(masterType, masterId) {
    if (masterType === 'CRMLead') {
      return this.apiService.delete(`api/lead/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMContact') {
      return this.apiService.delete(`api/contact/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMCampaign') {
      return this.apiService.delete(`api/campaign/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMAccount') {
      return this.apiService.delete(`api/account/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMOpportunity') {
      return this.apiService.delete(`api/opportunity/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMProduct') {
      return this.apiService.delete(`api/product/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMContract') {
      return this.apiService.delete(`api/contract/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMQuote') {
      return this.apiService.delete(`api/quote/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMMilestone') {
      return this.apiService.delete(`api/milesstone/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMProject') {
      return this.apiService.delete(`api/project/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMProjectTask') {
      return this.apiService.delete(`api/projecttask/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMCase') {
      return this.apiService.delete(`api/case/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMClient') {
      return this.apiService.delete(`api/clientCRM/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMTask') {
      return this.apiService.delete(`api/task/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMEvent') {
      return this.apiService.delete(`api/newevent/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMInvoice') {
      return this.apiService.delete(`api/invoice/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMResource') {
      return this.apiService.delete(`api/resource/delete?Id=${masterId}&`);
    } else if (masterType === 'CRMTarget') {
      return this.apiService.delete(`api/target/delete?Id=${masterId}&`);
    } else if (masterType === 'UserProfile') {
      return this.apiService.delete(`api/userProfile/delete?Id=${masterId}&`);
    } else if (masterType === 'Role') {
      return this.apiService.delete(`api/role/delete?Id=${masterId}&`);
    } else if (masterType === 'Territory') {
      return this.apiService.delete(`api/territory/delete?Id=${masterId}&`);
    } else if (masterType === 'TaskTemplate') {
      return this.apiService.delete(`api/taskTemplate/delete?Id=${masterId}&`);
    } else if (masterType === 'MultipleAddress') {
      return this.apiService.delete(`api/multipleAddressCRM/delete?Id=${masterId}&`);
    }
  }
}
