import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { ProjectTaskService } from '../../../services/projectTask/ProjectTask-Service/project-task.service';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';
import { GlobalService } from '../../../services/global-Service/global.service';


declare var $: any;
@Component({
  selector: 'app-project-task',
  templateUrl: './project-task.component.html',
  styleUrls: ['./project-task.component.css']
})
export class ProjectTaskComponent implements OnInit {
  projectTaskdata: any;
  disabledata: Boolean;
  DetailOn = true;
  newChatterOn = false;
  activityOn = true;
  resourceDetailOn = false;
  showSpinner: Boolean = true;
  selectedPage: any = {};
  projectId='';
  constructor(
    private route: ActivatedRoute,
    private projectTaskService: ProjectTaskService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
    private globalService: GlobalService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectId = params.id;
      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMProjectTask',
      });

      this.projectTaskService.projectTaskById(params.id).subscribe(data => {
        this.projectTaskdata = data;
        this.showSpinner = false;
      });
    });
  }

  OnDetail() {
    this.DetailOn = true;
    this.resourceDetailOn = false;
  }
  OnChatter() {
    this.newChatterOn = true;
    this.activityOn = false;
  }
  onResourceDetail() {
    this.DetailOn = false;
    this.resourceDetailOn = true;
  }

  onActivity() {
    this.newChatterOn = false;
    this.activityOn = true;
  }
  checkIn(ProjectTaskId,checkIn){
    // const user = JSON.parse(sessionStorage.getItem('currentUser'));

    let Data = {
      ProjectTaskId:ProjectTaskId,
      Type:"Project Task",
    }
    if(!checkIn){
      Data['CheckIn']= new Date();
    }else{
      Data['CheckOut']= new Date();
    }
    this.projectTaskService.CheckInOut(Data).subscribe(res => {
      this.projectTaskService.projectTaskById(this.projectId).subscribe(data => {
        this.projectTaskdata = data;
        this.showSpinner = false;
      });
    });
  }
}
