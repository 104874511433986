import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { ProductService } from '../../../services/product/product.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { VariableService } from '../../../services/VariableService/variable.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  productData: any;
  showSpinner: Boolean = true;
  selectedPage: any = {};
  DetailOn = true;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMProduct'
      });

      this.subscription = this.productService.productById(params.id).subscribe(data => {
        this.productData = data;
        this.showSpinner = false;
      });

    });
  }
  OnDetail() {
    this.DetailOn = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
