import { Router } from '@angular/router';
import { TaskService } from '../../../../services/task/task.service';
import { NewEventService } from '../../../../services/common/activity-page/newEvent/new-event.service';
import { LogCallService } from '../../../../services/common/activity-page/LogCall/log-call.service';
import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../../../services/common/activity-page/email/email.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  logCallOn = false;
  newEventOn = true;
  NewTaskOn = false;
  newEmailOn = false;
  tasks: Array<any> = [];
  events: Array<any> = [];
  logcalls: Array<any> = [];
  emailData: any;
  constructor(
    private taskService: TaskService,
    private newEventService: NewEventService,
    private callLogService: LogCallService,
    private emailService: EmailService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getAllTasks();
    this.getAllEvents();
    this.getAllCallLogs();
    this.getAllEmails();
  }
  OnLogcall() {
    this.logCallOn = true;
    this.newEventOn = false;
    this.NewTaskOn = false;
    this.newEmailOn = false;
  }

  OnNewEvent() {
    this.newEventOn = true;
    this.logCallOn = false;
    this.NewTaskOn = false;
    this.newEmailOn = false;
  }
  OnNewTask() {
    this.NewTaskOn = true;
    this.newEventOn = false;
    this.logCallOn = false;
    this.newEmailOn = false;
  }
  OnEmail() {
    this.newEmailOn = true;
    this.logCallOn = false;
    this.NewTaskOn = false;
    this.newEventOn = false;
  }

  getAllTasks() {
    this.taskService.getTaskType().subscribe(data => {
      this.tasks = data;
    });
  }

  getAllEvents() {
    this.newEventService.getNewEventType().subscribe(data => {
      this.events = data;
    });
  }

  getAllCallLogs() {
    this.callLogService.getCallLogsType().subscribe(data => {
      this.logcalls = data;
    });
  }

  getAllEmails() {
    this.emailService.getEmailType().subscribe(data => {
      this.emailData = data;
    });
  }

  activateClass(item) {
    item.active = !item.active;
  }

  gotoDetailPage(id) {
    const route = 'eventDetail/' + id;
    this.router.navigate([route]);
  }
}
