import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';

@Injectable()
export class SalesOrderService {

  constructor(
    private apiService: ApiService,
  ) { }
  addSalesOrder(form) {
    return this.apiService.post('api/quoteSalesorder/create', form);
  }

  addProjectToSalesOrder(form) {
    return this.apiService.post('api/projectSalesorder/create', form);
  }

  editProjectToSalesOrder(form) {
    return this.apiService.put('api/projectSalesorder/edit', form);
  }
}
