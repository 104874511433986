import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service/auth.service';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private authservice: AuthService) { }

  public myErrorMessage: string;
  loading: Boolean = false;
  ngOnInit() {
    this.myErrorMessage = '';
  }
  onSubmit(form: NgForm) {
    const value = form.value;

    const user: any = {
      username: value.userName,
      password: value.passWord
    };
    const tokenKey = 'currentUser';
    this.loading = true;
    this.authservice.authLogin(user).subscribe(
      data => {
        if (data.IsAuthenticated) {
          if (data.Token) {
            sessionStorage.setItem(tokenKey, JSON.stringify(data));
          }

          this.router.navigate(['home']);
        } else {
          this.myErrorMessage = 'Invalid user';
          this.loading = false;
          $('.error_message').show();
          setTimeout(function () {
            $('.error_message').hide();
          }, 5000);
        }
      },
      err => {
        this.loading = false;
        console.log('error', err);
      }
    );
  }

  signup() {
    this.router.navigate(['signup']);
  }

  //  form.resetForm(user);   // Reset form after Submit
}
