import { Component, Input, OnInit } from '@angular/core';
import { ContractService } from '../../../services/contract/contract.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-contract-related-component',
  templateUrl: './contract-related-component.component.html',
  styleUrls: ['./contract-related-component.component.css']
})
export class ContractRelatedComponentComponent implements OnInit {
  @Input() contractData:any;
  subScriptions:any;
  columnName = ['ProductName','Quantity','Price','StartDate','EndDate'];
  constructor(private contractService:ContractService,    private datePipe: DatePipe,) { }

  ngOnInit(): void {
    this.contractService.getSubscriptions(this.contractData.ContractId).subscribe((res)=>{
      let subscription = [];
      res.forEach((v,i)=>{
        subscription.push({
          ...v,
          'StartDate' : this.datePipe.transform(v.StartDate, 'dd-MM-yyyy'),
          'EndDate' : this.datePipe.transform(v.EndDate, 'dd-MM-yyyy')
        })
      })

      this.subScriptions=subscription;
    })
  }

}
