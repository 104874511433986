<div class="">
  <form [formGroup]="caseForm">
    <div class="extra-pad">
      <div class="">

        <div class="">
          <p class="headingclass"> Ticket Information</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group ">
                <label for="text">Case Owner</label>
                <input type="textbox" class="form-control" formControlName="CaseOwnerName">
              </div>

              <div class="form-group">
                <label for="text">Case Date</label>
                <input type="text" class="form-control" formControlName="CaseDate"  value="{{ caseForm.get('CaseDate').value | date: 'dd-MM-yyyy' }} "
                  >
                <p class="inputdate">
                  <i class="fa fa-calendar-alt fa-fw"></i>
                </p>
              </div>

              <div class="form-group ">
                <label for="text">Case Number</label>
                <input type="textbox" class="form-control" formControlName="CaseNumber">
              </div>

              <div class="form-group ">
                <label for="text">Client Name</label>
                <input type="textbox" class="form-control" formControlName="ClientName">
              </div>

              <div class="form-group ">
                <label for="text">Account Name</label>
                <input type="textbox" class="form-control" formControlName="AccountName">
              </div>

              <div class="form-group ">
                <label for="text">Contact Name</label>
                <input type="textbox" class="form-control" formControlName="ContactName">
              </div>

              <div class="form-group ">
                <label for="text">Project Name</label>
                <input type="textbox" class="form-control" formControlName="ProjectName">
              </div>


            </div>
          </div>

          <p class="headingclass"> Additional Information</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Status</label>
              <input type="textbox" class="form-control" formControlName="StatusName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Type</label>
              <input type="textbox" class="form-control" formControlName="TypeName">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Case Origin</label>
              <input type="textbox" class="form-control" formControlName="OriginName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Case Reason</label>
              <input type="textbox" class="form-control" formControlName="ReasonName">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Priority</label>
              <input type="textbox" class="form-control" formControlName="PriorityName">
            </div>
          </div>

          <p class="headingclass"> Web Information</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Web Email</label>
              <input type="textbox" class="form-control" formControlName="WebEmail">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="name">Web Company</label>
              <input type="textbox" class="form-control" formControlName="WebCompany">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="name">Web Name</label>
              <input type="textbox" class="form-control" formControlName="WebName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="name">Web Phone</label>
              <input type="textbox" class="form-control" formControlName="WebPhone">
            </div>
          </div>

          <p class="headingclass">Additional Information</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Product</label>
              <input type="textbox" class="form-control" formControlName="ProductName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Engineering Req Number</label>
              <input type="textbox" class="form-control" formControlName="EngineeringReqNumber">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Potential Liability</label>
              <input type="checkbox" formControlName="PotentialLiability" class="custom_checkbox">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">SLA Violation</label>
              <input type="checkbox" formControlName="SLAViolation" class="custom_checkbox">
            </div>
          </div>

          <p class="headingclass">Description Information</p>
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Subject</label>
              <input type="textbox" class="form-control" formControlName="Subject">
            </div>

            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" formControlName="Description"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>