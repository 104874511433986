import { ClientCustomerService } from './../../../../services/client-customer/client-customer.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-order-related',
  templateUrl: './order-related.component.html',
  styleUrls: ['./order-related.component.css']
})
export class OrderRelatedComponent implements OnInit {
  @Input() searchKeys;
  columnName = [];
  orderData: Array<any> = [];
  totalSum = 0.00;
  constructor(
    private customerClientService: ClientCustomerService,

  ) {
    this.columnName = [];
  }

  ngOnInit() {
    this.getAllOrder();
  }

  getAllOrder() {
    this.customerClientService.getCustomerOrder().subscribe(res => {
      this.orderData = res.Data;
      const columns = [];
      const columnsIn = res.Data[0];
      // tslint:disable-next-line:forin
      for (const key in columnsIn) {
        columns.push(key);
        this.columnName = columns;
      }

      for (let k = 0; k < this.orderData.length; k++) {
        this.totalSum += this.orderData[k].TotalAmount;
      }
    });
  }


}
