<div class="">
    <form [formGroup]="emailForm" novalidate (ngSubmit)="onSubmit(emailForm)">

        <!-- <div class="form-group ">
            <label for="text">Name</label>
            <input type="textbox" class="form-control" formControlName="FromName" [(ngModel)]="Name">
        </div>

        <div class="form-group ">
            <label for="text">From</label>
            <input type="textbox" class="form-control" formControlName="FromAddress" [(ngModel)]="fromAddress">
        </div> -->
        <div class="form-group">
            <label for="text">To</label>
            <input type="textbox" class="form-control" formControlName="ToAddress" [(ngModel)]="ToEmailId">
        </div>

        <div *ngIf="disableCC===true">
            <div class="form-group">
                <label for="text">Cc</label>
                <input type="textbox" class="form-control" formControlName="CcAddress">
            </div>
        </div>

        <div *ngIf="disableBCC===true">
            <div class="form-group">
                <label for="text">Bcc</label>
                <input type="textbox" class="form-control" formControlName="BccAddress">
            </div>
        </div>

        <div class="form-group">
            <label for="text">Subject</label>
            <input type="textbox" class="form-control" formControlName="Subject" placeholder="Enter Subject...">
        </div>

        <div>
            <span *ngIf="disableSpanCC===true" class="listClass" (click)="onCCClick($event)">CC</span>
            <span *ngIf="disableSpanBCC===true" class="listClass" (click)="onBccClick($event)">BCC</span>
        </div>

        <div class="form-group editor">
            <!-- <app-ngx-editor [config]="editorConfig" formControlName="TextBody"></app-ngx-editor> -->
            <ngx-editor-menu [editor]="editor"  [toolbar]="this.editorConfig.toolbar"> </ngx-editor-menu>
            <ngx-editor
                formControlName="TextBody"
                [editor]="editor"
                [ngModel]="html"
                [disabled]="false"
                [placeholder]="'Type here...'"
                [height]="'100px'" 
                [minHeight]="'200px'"
            ></ngx-editor>
        </div>

        <div class="row">
            <div class="form-group  col-lg-12 col-md-12 col-sm-12 col-xs-12 two_input">
                <label for="text">Related To</label>
                <br />
                <div class="select_cont mini_cont">
                    <ng-select [items]="RelatedTypeList" bindLabel="name" bindValue="name" lass="form-control selectType half_select"
                        [clearable]="false" formControlName="RelatedType" id="contactLeadTo">
                        <ng-template ng-label-tmp let-item="item">
                            <img height="20" width="20" [src]="item.avatar" />
                            <span style="padding-left:10px;">{{item.name}}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <img height="20" width="20" [src]="item.avatar" />
                            <span style="padding-left:10px;">{{item.name}}</span>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="select_cont max_cont">
                    <mat-form-field class="example-full-width app-input-no-underline material_normalise customMaterial">
                        <input #relatedtextInput matInput [matAutocomplete]="auto" (input)="getRealtedTypeList($event.target.value)"
                            formControlName="RelatedToId">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayRelatedFn">
                            <mat-option *ngFor="let related of relatedTypeFilterOption" [value]="related">
                                <span>{{related.Text}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="text-right">
            <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!emailForm">Send</button>
        </div>
    </form>
</div>