<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Product</h4>
</div>
<form [formGroup]="productForm" class="form-validate" (ngSubmit)="onSubmit(productForm.value)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="">

          <!-- <p class="headingclass"> Product Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="Name">
                <span class="text-danger">*</span> Product Name</label>
              <input type="textbox" class="form-control" formControlName="Name" required>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label class="form-check-label">Active</label>
              <input type="checkbox" formControlName="IsActive" class="custom_checkbox" />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Product Code</label>
              <input type="textbox" class="form-control" formControlName="Code">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Standard Price</label>
              <input type="textbox" class="form-control" formControlName="Price">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Subscription Term</label>
              <input type="textbox" class="form-control" formControlName="SubscriptionTerm">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">SubscriptionType</label>
              <select class="form-control" formControlName="SubscriptionType">
                <option value="One-ime">One-ime</option>
                <option value="Renewable">Renewable</option>
                <option value="Evergreen">Evergreen</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Product Description</label>
              <textarea class="form-control" formControlName="Description" rows="3"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <!-- Add Validation  -->
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!productForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!productForm.valid">Save</button>
  </div>
</form>