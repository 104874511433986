<div class="body_inner">
  <div class="heading_cont ">
    <div class="pull-left">
      <span class="menu_icon status_configuration fa fa-cogs">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Status Configurations</p>
      </h3>
    </div>
  </div>


  <div class="bg_white_margin_top min_top">
    <div class="form-group">
      <label class="pull-left mt_5" for="text">Master Type</label>
      <div class="controls col-md-6 col-xs-8">
        <select (change)="selectMasterType($event.target.value)" class="form-control" [(ngModel)]="statusConfigForm.MasterType">
          <option value="">--Select--</option>
          <option *ngFor="let type of statusRelatedTo" value="{{type.Value}}">{{ type.text }}</option>
        </select>
      </div>
    </div>


    <p>&nbsp;</p>
    <div class="table_cont">
      <table class="table table-striped table-bordered dynomic_table">
        <thead>
          <tr>
            <th class="number_small"><b>S.No.</b></th>
            <th class="name_full_width"><b>Activity</b></th>
            <th class="number_long"><b>Completed</b> </th>
            <th class="number_short"><b>Action</b></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let status of statusConfigForm.ListOfStage; let i=index">
            <td>
              <select [(ngModel)]="status.SerialNo" (change)="addFieldValue(i)" class="form-control">
                <option *ngFor="let val of statusSerialNo" value={{val}}>
                  {{val}}
                </option>
              </select>

            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="status.ActivityName" placeholder="StatusName"
                (input)="addFieldValue(i)" />
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="status.PercentageCompleted" placeholder="%of Completed"
                (input)="addFieldValue(i)" (keypress)="numberOnly($event)" />
            </td>
            <td>
              <div class="btn-group" dropdown>
                <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
                <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <div>
      <button type="button" class="btn btn-default" (click)="resetForm()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="onSubmit()">Save</button>
    </div>
  </div>
</div>