<div class="text-right main_table_header">
  <div (click)="toggle.emit()" class="buttonNew pull-right">
    <i class="glyphicon glyphicon-filter"></i>
  </div>
  <div class="dropdown drop_list_items pull-right">
    <button class="buttonNew add_task" type="button" data-toggle="dropdown">
      <i class="fa fa-cog fa-fw" aria-hidden="true"></i>
    </button>
    <ul class="dropdown-menu add_task_dropdown create_menu">
      <li>
        <a href="javascript:void(0)" (click)="createNewPageFilterModal()">
          Create list/segment
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="exportToCSV()">
          Export to CSV
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="custommenu(commonFilterCMP)">
          Select fields to display</a>
      </li>

      <li>
        <a href="javascript:void(0)" (click)="sendEmail.emit()">
          Email</a>
      </li>

      <li>
        <a href="javascript:void(0)" (click)="sendSMS()">
          SMS</a>
      </li>
    </ul>
  </div>
</div>