import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, ViewContainerRef, ViewChild, ElementRef } from '@angular/core';
import { LeadService } from '../../../services/lead/lead.service';
import { LeadStatusModel } from '../../../model/leadModel/lead-status-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConvertLeadComponent } from '../lead-conversion/convert-lead/convert-lead.component';

@Component({
  selector: 'app-lead-status',
  templateUrl: './lead-status.component.html',
  styleUrls: ['./lead-status.component.css']
})
export class LeadStatusComponent implements OnInit {
  public leadStatusModel: LeadStatusModel[];
  @Input() leadStatusData: any;
  @Input() convertLeadData: any;
  public currentStatus: any;
  public statusValue = 'Mark Status As Complete';
  @ViewChild('widthIdentifier')
  widthIdentifier: ElementRef;
  taileWidth=0;

  constructor(
    private leadService: LeadService,
    private modalService: NgbModal,
  ) {
    this.currentStatus = {};
    this.leadStatusModel = [];
  }

  ngOnInit() {
    this.getLeadStatusData(this.convertLeadData.LeadId)
  }

  markAsCurrentStatus(LeadStatusID) {
    this.leadService.createLeadStatus(LeadStatusID).subscribe(data => {
    });
  }

  // Get By Id
  getLeadStatusData(leadId) {
    this.leadService.getByIdStatus(leadId).subscribe(data => {
      this.leadStatusModel = data;
      this.leadStatusData = data;
      this.calWidth(data);      
    });
  }
  calWidth(data){
    let width = this.widthIdentifier.nativeElement.offsetWidth;
      if(data){
        this.taileWidth = width/data.length;
      }
  }
  completeStatus() {
    this.leadService.createLeadStatus(this.currentStatus).subscribe(data => {
      this.getAllLeadStatus();
    });
  }

  getAllLeadStatus() {
    this.leadService.getLeadStatusAll().subscribe(data => {
      this.leadStatusModel = data;
      this.leadStatusData = data;
      this.calWidth(data);      
    });
  }

  setCompleteStatus() {
    if (this.currentStatus.IsLast === 'Yes') {
      const modalRef = this.modalService.open(ConvertLeadComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.convertLeadData = this.convertLeadData;
      this.getAllLeadStatus();
      this.completeStatus();
    } else {
      this.completeStatus();
    }
  }

  setCurrentStatus(status: any) {
    this.currentStatus = status;
    this.currentStatusName();
  }

  currentStatusName() {
    if (this.currentStatus.LeadStatus === 'Current') {
      this.statusValue = 'Mark Status As Complete';
    } else if (this.currentStatus.LeadStatus === 'Completed') {
      this.statusValue = 'Mark As Current Status';
    } else if (
      this.currentStatus.LeadStatus === 'Pending' &&
      this.currentStatus.IsLast === 'No'
    ) {
      this.statusValue = 'Mark As Current Status';
    }
    if (this.currentStatus.IsLast === 'Yes') {
      this.statusValue = 'Select Converted Status';
    }
  }
}
