import { NewCallLogComponent } from './../../call-log-page/new-call-log/new-call-log.component';
import { TaskNewComponent } from './../../tasks-page/task-new/task-new.component';
import { EventNewComponent } from './../../event-page/event-new/event-new.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
import { range } from 'rxjs/observable/range';

@Component({
  selector: 'app-timesheet-table',
  templateUrl: './timesheet-table.component.html',
  styleUrls: ['./timesheet-table.component.css']
})
export class TimesheetTableComponent implements OnInit {
  activityType: String = '';

  @Input() timeSheetList: any;
  @Output() addFieldValue = new EventEmitter();
  @Output() deleteFieldValue = new EventEmitter();

  constructor(
    public modalService: NgbModal,
  ) { }

  ngOnInit() {

  }

  selectType(e, i) {
    this.activityType = e;
  }


  openType() {
    console.log('Called......');
    if (this.activityType === 'Event') {
      const modalRef = this.modalService.open(EventNewComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
    } else if (this.activityType === 'Task') {
      const modalRef = this.modalService.open(TaskNewComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
    } else if (this.activityType === 'CallLog') {
      const modalRef = this.modalService.open(NewCallLogComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
    }
  }

}
