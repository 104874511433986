import { VariableService } from './../../../services/VariableService/variable.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MilestoneService } from '../../../services/milestone/milestone.service';
import { DatePipe } from '@angular/common';
import { TaskOfMilestoneComponent } from '../../../shared/components/task-milestone-page/task-of-milestone/task-of-milestone.component';
import { ConfigurationService } from '../../../services/configuration-Service/configuration.service';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-new-milestone',
  templateUrl: './new-milestone.component.html',
  styleUrls: ['./new-milestone.component.css']
})
export class NewMilestoneComponent implements OnInit {
  @Output() getData = new EventEmitter();
  @Input() taskTemplateData: any;
  milestoneForm: UntypedFormGroup;
  milestoneFormData: any;
  milestoneProjectData: any;
  EditNew: any;
  public TaskTemplate: AutocompleteModel[];
  ownerfilteredOptions: any[];
  ownerText: any; ownerValue: any;
  clientfilteredOptions: any[];
  clientText: any; clientValue: any;
  projectfilteredOptions: any[];
  projectText: any; projectValue: any;
  milestonefilteredOptions: any[];
  milestoneText: any; milestoneValue: any;

  constructor(private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private configService: ConfigurationService,
    private globalService: GlobalService,
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe,
    private milestoneService: MilestoneService,
    private modalService: NgbModal,
    private variableService: VariableService,
  ) { }

  ngOnInit() {
    this.milestoneForm = this.fb.group({
      MilesStoneId: null,
      OwnerID: '',
      ProjectID: '',
      ParentMileStone: '',
      UserId: '',
      Kickoff: new Date,
      DeadLine: new Date,
      MileStoneName: '',
      Description: '',
      Alias: '',
      HoursBudget: '',
      ExpenseBudget: '',
      TaskTemplateID: '',
      ProjectClientID: '',
      MilestoneType: '',
    });
    this.getAllTaskTemplateList();
    this.getEditData();
    this.getEditNewName();
    this.getProjectMilestone();
    this.getProjectList(' ');
  }

  getEditNewName() {
    if (this.milestoneForm.value.MilesStoneId === null) {
      this.EditNew = 'New';
    } else {
      this.EditNew = 'Edit';
    }
  }

  getProjectMilestone() {
    const q: any = this.variableService.getSearchKeys();
    if (q.type === 'CRMProject') {
      this.clientText = this.milestoneProjectData.ClientName;
      this.clientValue = this.milestoneProjectData.Client;
      this.milestoneForm.controls['ProjectClientID'].setValue(this.clientValue);

      this.projectText = this.milestoneProjectData.ProjectName;
      this.projectValue = this.milestoneProjectData.ProjectId;
      this.milestoneForm.controls['ProjectID'].setValue(this.projectValue);

      this.ownerText = this.milestoneProjectData.TeamLeadName;
      this.ownerValue = this.milestoneProjectData.TeamLead;
      this.milestoneForm.controls['OwnerID'].setValue(this.ownerValue);
    }
  }

  getEditData() {
    if (this.milestoneFormData !== undefined) {
      this.milestoneForm.patchValue(this.milestoneFormData);

      this.ownerText = this.milestoneFormData.MilestoneOwnerName;
      this.ownerValue = this.milestoneFormData.OwnerID;

      this.clientText = this.milestoneFormData.ProjectClientName;
      this.clientValue = this.milestoneFormData.ProjectClientID;

      this.milestoneText = this.milestoneFormData.MileStoneName;
      this.milestoneValue = this.milestoneFormData.ParentMileStone;

      this.projectText = this.milestoneFormData.Project;
      this.projectValue = this.milestoneFormData.ProjectID;
    }
  }


  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.ownerfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteEmployee(data) {
    const ownerId = data.data.option.value.Value;
    this.milestoneForm.controls['OwnerID'].setValue(ownerId);
  }

  getClientList(value) {
    this.autocompleteService.getAllClientList(value.data).subscribe(
      res => {
        this.clientfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteClient(data) {
    const clientId = data.data.option.value.Value;
    this.milestoneForm.controls['ProjectClientID'].setValue(clientId);
  }

  getProjectList(value) {
    const clientId = this.milestoneForm.value.ProjectClientID;
    let data = value.data || value;
    this.autocompleteService.getProjectAccountList(data, clientId).subscribe(
      res => {
        this.projectfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteProject(data) {
    const projectId = data.data.option.value.Value;
    this.milestoneForm.controls['ProjectID'].setValue(projectId);
  }

  getMilestoneList(value) {
    const projectId = this.milestoneForm.value.ProjectID;
    this.autocompleteService.getMilestoneProjectList(value.data, projectId).subscribe(
      res => {
        this.milestonefilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteMilestone(data) {
    const milestoneid = data.data.option.value.Value;
    this.milestoneForm.controls['ParentMileStone'].setValue(milestoneid);
  }

  getAllTaskTemplateList() {
    this.configService.getTaskTemplateList().subscribe(data => {
      this.TaskTemplate = data;
    });
  }


  dateFormat() {
    this.milestoneForm.value.Kickoff = this.datePipe.transform(this.milestoneForm.value.Kickoff, 'yyyy-MM-dd');
    this.milestoneForm.value.DeadLine = this.datePipe.transform(this.milestoneForm.value.DeadLine, 'yyyy-MM-dd');
  }

  // Submit Function
  onSubmit(form: UntypedFormGroup) {
    const milestoneName = form.value.MileStoneName;
    const taskTemplateId = form.value.TaskTemplateID;
    const ProjectId = form.value.ProjectID;
    let milestoneReturnId = null;
    let responseMessage = [];
    this.dateFormat();
    if (form.value.MilesStoneId == null) {
      this.milestoneService.addMilestone(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        milestoneReturnId = responseMessage[3];
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Milestone', name: milestoneName });
        }
        this.activeModal.close();
        this.getData.emit();
        this.openTaskOfMilestone({ data: taskTemplateId, milestoneId: milestoneReturnId, projectId: ProjectId });
      });
      // Update Method for Milestone
    } else {
      this.milestoneService.updateMilestone(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Milestone', name: milestoneName });
        }
        this.getData.emit();
      });
      this.activeModal.close();
    }
    this.resetForm();
    this.globalService.disposeToastr();
  }
  resetForm() {
    this.milestoneForm.reset();
  }

  openTaskOfMilestone(data) {
    this.taskTemplateData = data;
    if (data.data !== null) {
      const modalRef = this.modalService.open(TaskOfMilestoneComponent, {
        size: 'sm',
        backdrop: 'static',
        windowClass: 'custom-modal'
      });
      modalRef.componentInstance.taskTemplateData = this.taskTemplateData;
    }
  }

}
