<form [formGroup]="resourceForm">

  <div class="row">
    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Name</label>
      <input type="textbox" class="form-control" formControlName="Name">
    </div>

    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Code</label>
      <input type="textbox" class="form-control" formControlName="Code">
    </div>

    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Manager</label>
      <select formControlName="ManagerID" class="form-control">
        <option *ngFor="let employee of EmployeeList" value={{employee.Value}}>{{employee.Text}}
        </option>
      </select>
    </div>


    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Category</label>
      <select formControlName="CategoryId" class="form-control">
        <option *ngFor="let category of CategoryList" value={{category.Value}}>{{category.Text}}
        </option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Phone</label>
      <input type="textbox" class="form-control" formControlName="PhoneNo">
    </div>

    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
      <label for="text">Mobile</label>
      <input type="textbox" class="form-control" formControlName="MobileNo">
    </div>

    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
      <label for="text">Email</label>
      <input type="textbox" class="form-control" formControlName="EmailID">
    </div>


    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Branch</label>
      <select formControlName="TerritoryID" class="form-control">
        <option *ngFor="let territory of TerritoryList" value={{territory.Value}}>{{territory.Text}}
        </option>
      </select>
    </div>

  </div>
</form>