import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { MasterLookupModel } from '../../../model/sharedModel/masterLookup/master-lookup-Model';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';

@Component({
  selector: 'app-lead-detail',
  templateUrl: './lead-detail.component.html',
  styleUrls: ['./lead-detail.component.css']
})
export class LeadDetailComponent implements OnInit {
  leadForm: UntypedFormGroup;
  @Input() leadData: any;
  public TerritoryList: AutocompleteModel[];
  public IndustryModel: MasterLookupModel[];
  public LeadSourceModel: MasterLookupModel[];
  public RatingModel: MasterLookupModel[];
  constructor(
    private fb: UntypedFormBuilder,
    private masterLookupService: MasterLookupService,
    private configService: ConfigurationService,

  ) { }

  ngOnInit() {
    this.leadForm = this.fb.group({
      LeadId: 'null',
      LeadStatus: '',
      LeadOwnerName: '',
      Salutation: '',
      FirstName: '',
      LastName: '',
      OwnerId: '',
      Title: '',
      CompanyName: '',
      Website: '',
      Email: '',
      IndustryId: '',
      Phone: '',
      Fax: '',
      NoofEmployees: '',
      Mobile: '',
      LeadSource: '',
      Rating: '',
      Description: '',
      SicCode: '',
      CurrentGenerators: '',
      ProductId: '',
      NoOfLocations: ' ',
      AnnualRevenue: '',
      TerritoryName: '',
      CurrencyId: '',
      FaceBookID: '',
      TwitterID: '',
      SkypeID: '',
      LeadAmount: '',
      CurrencyName: '',
      ProductName: '',
      StatusName: '',
      LinkdInId: '',
      BillingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      })
    });
    this.getIndustryList();
    this.getLeadSourceList();
    this.getRatingList();
    this.getAllTerritoryLookupList();
    this.leadForm.patchValue(this.leadData);
  }

  getIndustryList() {
    this.masterLookupService.getDropdownList('Industry').subscribe(data => {
      this.IndustryModel = data;
    });
  }

  getLeadSourceList() {
    this.masterLookupService.getDropdownList('Lead Source').subscribe(data => {
      this.LeadSourceModel = data;
    });
  }

  getRatingList() {
    this.masterLookupService.getDropdownList('Rating').subscribe(data => {
      this.RatingModel = data;
    });
  }

  getAllTerritoryLookupList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }

  getDisplayName(id,field,Text,data){
    let name ='';
    let mapData = data.map((item)=>{
      if(item[field] == id){
        name = item[Text];
      }
    })
    return name;
  }

}
