<div class="close-btn-container pos-abs">
    <!--  -->
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h4 class="modal-title text-center">New template</h4>
  </div>
  <form [formGroup]="emailTemplateForm" class="form-validate" (ngSubmit)="onSubmit(emailTemplateForm)">
    <div class="modal-body">
      <div class="extra-pad">
        <div class="">
          <div class="">

            <!-- <p class="headingclass">Information</p> -->
            <div class="row">
              
              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="Name">
                  <span class="text-danger">*</span> Template Name</label>
                <input type="textbox" class="form-control" formControlName="EmailTemplateName" required>
              </div>

              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="text">Related Object Type</label>
                <select class="form-control" formControlName="RelatedType" (change)="onEntity($event.target.value)">
                  <option value="null">--None--</option>
                  <option value="Contact">Contact</option>
                  <option value="Lead">Lead</option>
                  <option value="Account">Account</option>
                  <option value="Campaign">Campaign</option>
                  <option value="Case">Case</option>
                  <option value="Contract">Contract</option>
                  <option value="Opportunity">Opportunity</option>
                  <option value="Product">Product</option>
                  <option value="Quote">Quote</option>
                  <option value="Project">Project</option>
                  <option value="ProjectTask">Project Task</option>
                  <option value="Milestone">Milestone</option>

                </select>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="Name">
                  <span class="text-danger">*</span>Type</label>
                  <select class="form-control" formControlName="Type">
                    <option value="email">Email</option>
                    <option value="sms">SMS</option>
                    <option value="whatsapp">WhatsApp</option>
                  </select>
              </div>
              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="Name">
                  <span class="text-danger">*</span> Category</label>
                  <select class="form-control" formControlName="Category">
                    <option value="AUTHENTICATION">Authentication</option>
                    <option value="MARKETING">Marketing</option>
                    <option value="UTILITY">Utility</option>
                  </select>
              </div>
            </div>

            <!-- <p class="headingclass">Message Content</p> -->
            <div class="row">
              <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label for="text">Subject</label>
                <input type="textbox" class="form-control" formControlName="Subject">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label for="text">Insert Fields</label>
                <div class="border">
                  <a class="item" (click)="insert(column.Name)" *ngFor="let column of columns;">{{column.Name}}</a>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label for="text">Body</label>
                <div class="html-Container"><ngx-editor-menu [editor]="editor"  [toolbar]="this.editorConfig.toolbar"> </ngx-editor-menu>
                <ngx-editor
                  formControlName="HTMLContent"
                  [editor]="editor"
                  [ngModel]="HTMLValue"
                  [disabled]="false"
                  [placeholder]="'Type here...'"
              ></ngx-editor></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">Cancel</button>
      <button type="submit" class="btn btn-primary" [disabled]="!emailTemplateForm.valid">Save</button>
    </div>
  </form>
