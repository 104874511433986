import { QuoteProductService } from './../../../services/quoteProduct/quote-product.service';
import { MasterLookupService } from './../../../services/common/master-lookup/master-lookup.service';
import { AutocompleteModel } from './../../../model/sharedModel/autocomplete/autocomplete-model';
import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { VariableService } from '../../../services/VariableService/variable.service';
import { NgbModal } from '../../../../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../services/global-Service/global.service';

declare var $: any;

@Component({
  selector: 'app-quote-service',
  templateUrl: './quote-service.component.html',
  styleUrls: ['./quote-service.component.css']
})
export class QuoteServiceComponent implements OnInit {

  @Input() searchKeys;
  quoteServiceData: Array<any> = [];
  amount: any;
  taxAmount: any;

  quoteServiceForm: QuoteServiceForm;
  listOfQuoteServiceObj: ListOfQuoteServiceObj;
  public ServiceList: AutocompleteModel;

  constructor(
    private variableService: VariableService,
    private modalService: NgbModal,
    private masterlookupService: MasterLookupService,
    private quoteService: QuoteProductService,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.getAllServiceData();
  }

  addFieldValue(i) {
    this.resetListOfQuoteServiceObj();
    const obj = this.quoteServiceForm.ListOfQuoteServiceTable[i + 1];
    if (!obj) {
      this.quoteServiceForm.ListOfQuoteServiceTable.push(this.listOfQuoteServiceObj);
    }
    this.resetListOfQuoteServiceObj();
  }

  deleteFieldValue(i: number) {
    if (this.quoteServiceForm.ListOfQuoteServiceTable.length === 1) {
      return false;
    } else {
      this.quoteServiceForm.ListOfQuoteServiceTable.splice(i, 1);
      return true;
    }
  }

  numberOnly(event): boolean {
    const keycode = (event.which) ? event.which : event.keyCode;
    if (!(keycode === 8 || keycode === 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      const parts = event.srcElement.value.split('.');
      if (parts.length > 1 && keycode === 46) {
        return false;
      }
      return true;
    }
  }

  getAllServiceList() {
    this.masterlookupService.getServiceList().subscribe(data => {
      this.ServiceList = data;
    });
  }

  getAllServiceData() {
    this.quoteService.getQuoteService('CRMQuote').subscribe(data => {
      this.quoteServiceData = data;
    });
  }

  get quoteFormData() {
    return this.quoteServiceForm.ListOfQuoteServiceTable;
  }

  calculate(i) {
    this.calculateTaxAmount(i);
    this.calculateTotalValue(i);
    this.calculateAmount(i);
  }

  calculateAmount(i) {
    const value = this.quoteFormData[i];
    let totalamount = (value.Quantity * value.Rate) * ((100 - value.Discount) / 100);
    this.amount = totalamount;
    if (!isNaN(totalamount)) {
      totalamount = parseFloat(totalamount.toFixed(2));
      this.quoteServiceForm.ListOfQuoteServiceTable[i].Amount = totalamount;
    }
  }

  calculateTaxAmount(i) {
    const value = this.quoteFormData[i];
    let taxamount = this.amount * ((value.TaxRate) / 100);
    this.taxAmount = taxamount;
    if (!isNaN(taxamount)) {
      taxamount = parseFloat(taxamount.toFixed(2));
      this.quoteServiceForm.ListOfQuoteServiceTable[i].TaxAmount = taxamount;
    }
  }

  calculateTotalValue(i) {
    const value = this.quoteFormData[i];
    let totalvalue = this.amount + this.taxAmount;
    if (!isNaN(totalvalue)) {
      totalvalue = parseFloat(totalvalue.toFixed(2));
      this.quoteServiceForm.ListOfQuoteServiceTable[i].TotalValue = totalvalue;
    }
  }

  open(openContent) {
    this.modalService.open(openContent, { windowClass: 'custom-modal', backdrop: 'static', keyboard: false });
    this.reset();
    this.getAllServiceList();
    this.quoteServiceData.map((obj, i) => {
      this.quoteServiceForm['ListOfQuoteServiceTable'].push(obj);
    });
    this.quoteServiceForm['ListOfQuoteServiceTable'].push(this.listOfQuoteServiceObj);
    this.resetListOfQuoteServiceObj();
  }

  onSubmit() {
    let responseMessage = [];
    const ListOfServicesExtraData = [];
    const ListOfQuoteServiceData = [];
    const q: any = this.variableService.getSearchKeys();
    this.quoteServiceForm.ListOfQuoteServiceTable = this.quoteServiceForm.ListOfQuoteServiceTable.filter(
      obj => {
        return obj.SerialNo || obj.ServiceId || obj.Quantity ||
          obj.Rate || obj.Discount || obj.Amount || obj.TaxRate ||
          obj.TaxAmount || obj.TotalValue || obj.Scope || obj.Deliverables || obj.Exclusions || obj.DeliverySchedule || obj.InputFileRequired;
      }
    );

    this.quoteServiceForm.ListOfQuoteServiceTable.forEach(k => {
      ListOfServicesExtraData.push({
        SerialNo: ListOfServicesExtraData.length + 1,
        ServiceID: k.ServiceId,
        Scope: k.Scope,
        Deliverables: k.Deliverables,
        Exclusions: k.Exclusions,
        DeliverySchedule: k.DeliverySchedule,
        InputFileRequired: k.InputFileRequired,
      });
    });

    this.quoteServiceForm.ListOfQuoteServiceTable.forEach(i => {
      ListOfQuoteServiceData.push({
        SerialNo: ListOfQuoteServiceData.length + 1,
        ServiceID: i.ServiceId,
        Quantity: i.Quantity,
        Rate: i.Rate,
        Discount: i.Discount,
        Amount: i.Amount,
        TaxRate: i.TaxRate,
        TaxAmount: i.TaxAmount,
        TotalValue: i.TotalValue,
      });
    });

    this.quoteService.addQuoteService({
      QuoteId: q.paramId,
      ListOfQuoteService: ListOfQuoteServiceData,
      ListOfServicesExtra: ListOfServicesExtraData
    }).subscribe(data => {
      responseMessage = data.ErrorMessages;
      if (responseMessage[1] === '0') {
        this.globalService.showServiceAdded();
      }
      this.resetForm();
      this.getAllServiceData();
    });
    $('#btnCancel').trigger('click');
  }

  resetForm() {
    this.resetQuoteServiceForm();
    this.resetListOfQuoteServiceObj();
    this.quoteServiceForm.ListOfQuoteServiceTable.push(this.listOfQuoteServiceObj);
  }

  reset() {
    this.resetQuoteServiceForm();
    this.resetListOfQuoteServiceObj();
  }

  resetListOfQuoteServiceObj() {
    this.listOfQuoteServiceObj = {
      SerialNo: '',
      ServiceId: '',
      Quantity: '',
      Rate: '',
      Discount: '',
      Amount: '',
      TaxRate: '',
      TaxAmount: '',
      TotalValue: '',

      Scope: '',
      Deliverables: '',
      Exclusions: '',
      DeliverySchedule: '',
      InputFileRequired: '',
    };
  }

  resetQuoteServiceForm() {
    this.quoteServiceForm = {
      ListOfQuoteServiceTable: [],
    };
  }
}

declare class ListOfQuoteServiceObj {
  SerialNo: string;
  ServiceId: string;
  Quantity: string;
  Rate: string;
  Discount: string;
  Amount: string;
  TaxRate: string;
  TaxAmount: string;
  TotalValue: string;

  Scope: string;
  Deliverables: string;
  Exclusions: string;
  DeliverySchedule: string;
  InputFileRequired: string;
}

declare class QuoteServiceForm {
  ListOfQuoteServiceTable: Array<any>;
}
