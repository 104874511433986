import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ContactService } from '../../../services/contact/contact.service';
import { VariableService } from '../../../services/VariableService/variable.service';

declare var SkypeWebControl: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactData: any;
  RealtedOn = true;
  NewsOn = false;
  newChatterOn = false;
  DetailOn = false;
  activityOn = true;
  searchKeys: object = {};
  showSpinner: Boolean = true;
  selectedPage: any = {};

  constructor(
    public modalService: NgbModal,
    private route: ActivatedRoute,
    private contactService: ContactService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
    public router: Router
  ) {
    this.selectedPage = this.topnavbarService.getSelectedPage();
  }

  ngOnInit() {

    this.route.params.subscribe(params => {

      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMContact',
      });

      this.contactService.editContactById(params.id).subscribe(data => {
        this.contactData = data;
        this.showSpinner = false;
      });
    });
  }


  OnChatter() {
    this.newChatterOn = true;
    this.activityOn = false;
  }

  onActivity() {
    this.newChatterOn = false;
    this.activityOn = true;
  }

  OnRelated() {
    this.RealtedOn = true;
    this.NewsOn = false;
    this.DetailOn = false;
  }
  OnDetail() {
    this.DetailOn = true;
    this.RealtedOn = false;
    this.NewsOn = false;
  }

  OnNews() {
    this.NewsOn = true;
    this.DetailOn = false;
    this.RealtedOn = false;
  }

  skypeChat(id) {
    SkypeWebControl.SDK.Chat.startChat({
      ConversationId: id
    });
  }

  gotoDetailPage(id) {
    const route = 'accountDetail/' + id;
    this.router.navigate([route]);
  }
}
