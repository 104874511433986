import { CompanyList } from '../../product/crm/model/companyListModel/company-list';
import { Component, OnInit, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TopnavbarService } from '../../product/crm/services/top-nav-bar/topnavbar.service';

declare var $: any;

@Component({
  selector: 'sidemenubar',
  templateUrl: 'sidemenubar.template.html',
  styleUrls: ['./sidemenubar.component.css']
})
export class SideMenuBarComponent implements OnInit, AfterViewInit {
  @Output() calcWidth = new EventEmitter();

  public selectedValue: any;
  companyList: CompanyList[];
  selectedCompanyName: any;
  public user: any;
  public menuItems: any[];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private topnavbarService: TopnavbarService
  ) {
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.menuItems = [];
  }
  ngOnInit() {
    this.getMenuItems();
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['login']);
  }

  openContent(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  setup() {
    this.router.navigate(['setup']);
  }

  setSelectedPage(page: any) {
    this.topnavbarService.setPage(page);
    $('body').removeClass('menu_expanded');
  }

  getMenuItems() {
    this.topnavbarService.getMenuItems().subscribe(data => {
      this.menuItems = data.Access;
      setTimeout(() => {
        this.calcWidth.next('hello');
      }, 0);
    });
  }

  ngAfterViewInit() {
    window.onscroll = function () { myFunction(); };

    const header = $('.heading_cont');
    const sticky = 50;


    function myFunction() {
      if (window.pageYOffset > sticky) {
        $('.heading_cont').addClass('sticky');
      } else {
        $('.heading_cont').removeClass('sticky animate_quotebox');
      }
    }
  }
}
