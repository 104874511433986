import { Component, OnInit, Input } from '@angular/core';
import { ContactService } from '../../../../services/contact/contact.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-contact-related',
  templateUrl: './contact-related.component.html',
  styleUrls: ['./contact-related.component.css']
})
export class ContactRelatedComponent implements OnInit {
  @Input() searchKeys;
  contacts: Array<any> = [];
  constructor(
    private contactService: ContactService,
    public router: Router
  ) { }

  ngOnInit() {
    this.getAllContacts();
  }

  getAllContacts() {
    this.contactService.getTypeContacts().subscribe(data => {
      this.contacts = data;
    });
  }

  gotoDetailPage(id) {
    const route = 'contactDetail/' + id;
    this.router.navigate([route]);
  }
}
