<div class="">

  <div class="row1">
    <p class="Coverheadingclass">Cover Sheet</p>
    <div class="labelRespresentation">

      <table class="custom_table">
        <tr>
          <th class="custom_name">Client Project Name</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.ProjectName}}</td>
        </tr>

        <tr>
          <th class="custom_name">Client Name</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.ClientName}}</td>
        </tr>

        <tr>
          <th class="custom_name">Project No.</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.ProjectNo}}</td>
        </tr>

        <tr>
          <th class="custom_name">Start Date</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.StartDate| date:'dd-MM-yyyy'}}</td>
        </tr>

        <tr>
          <th class="custom_name">Schedule & Expected End Date</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.DeadLine| date:'dd-MM-yyyy'}}</td>
        </tr>

        <tr>
          <th class="custom_name">Work Order No</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.WorkOrderNo}}</td>
        </tr>

        <tr>
          <th class="custom_name">Work Order End Date</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.EndDate| date:'dd-MM-yyyy'}}</td>
        </tr>

        <tr>
          <th class="custom_name">Client Co-Ordination</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.CoordinatorName}}</td>
        </tr>

        <tr>
          <th class="custom_name">PM/GM Responsible</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.ProjectManagerName}}</td>
        </tr>

        <tr>
          <th class="custom_name">Client Contact Name For RFI</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.ContactRFIName}}</td>
        </tr>

        <tr>
          <th class="custom_name">Client Contact Name for Delivery</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.ContactName}}</td>
        </tr>

        <tr>
          <th class="custom_name">Scope Of Work</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{kickOffProject?.ScopeofWork}}</td>
        </tr>
      </table>
    </div>


    <form [formGroup]="kickOffForm" novalidate (ngSubmit)="onSubmit(kickOffForm)">
      <div class="modalBody">
        <div class="extra-pad" style="border: 1px solid blue;
        margin: 10px;">

          <div class="row">

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
              <label for="text">KickOff Meeting Date</label>
              <input type="text" class="form-control" formControlName="KickoffDate" #bsdp="bsDatepicker" value="{{ kickOffForm.get('KickoffDate').value | date: 'dd-MM-yyyy' }}"
                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
              <p class="inputdate" (click)="bsdp.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Client Project No.</label>
              <input type="textbox" class="form-control" formControlName="ClientProjectNo">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">GC Name</label>
              <input type="textbox" class="form-control" formControlName="GCName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Owner Name</label>
              <input type="textbox" class="form-control" formControlName="OwnerName">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Team Lead</label>
              <app-autocomplete [autoCompleteText]="teamLeadText" (setAutoComplete)="setAutoCompleteTeamLead($event)"
                [autoCompleteValue]="teamLeadValue" (fiteredData)="getTeamLeadList($event)" [filteredOptions]="teamLeadEmployee">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Checked By</label>
              <app-autocomplete [autoCompleteText]="checkedByText" (setAutoComplete)="setAutoCompleteCheckedBy($event)"
                [autoCompleteValue]="checkedByValue" (fiteredData)="getCheckedByList($event)" [filteredOptions]="checkedByEmployee">
              </app-autocomplete>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Destination for Upload</label>
              <input type="textbox" class="form-control" formControlName="DestinationforUpload">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Design Dwgs Status/Result of verification customer proprty</label>
              <input type="textbox" class="form-control" formControlName="DrawingStatus">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Connection Details Available</label>
              <input type="textbox" class="form-control" formControlName="ConnectionDetails">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Sequence Number/ Phase Number</label>
              <input type="textbox" class="form-control" formControlName="SequenceNumber">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Drawing Numbering</label>
              <input type="textbox" class="form-control" formControlName="DrawingNumbering">
            </div>
          </div>

          <p class="headingclass">Client Samples</p>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Templates</label>
              <select class="form-control" formControlName="SampleTemplate">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Standard Dwg</label>
              <select class="form-control" formControlName="SampleStandard">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Reference Dwg</label>
              <select class="form-control" formControlName="SampleReference">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Reports</label>
              <select class="form-control" formControlName="SampleReports">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Advance BOM</label>
              <select class="form-control" formControlName="AdvanceBOM">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>

          <p class="headingclass">IFA Deliverable</p>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Shop and GA dwgs in PDF/DWG</label>
              <select class="form-control" formControlName="IFAShop">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Single part dwgs in PDF/DWG</label>
              <select class="form-control" formControlName="IFASingle">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">NC/DSTV files (Types)</label>
              <select class="form-control" formControlName="IFANC">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Reports (Type of reports to be mentioned)</label>
              <select class="form-control" formControlName="IFAReports">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Transmittal</label>
              <select class="form-control" formControlName="IFATransmittal">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Fabtrol Files</label>
              <select class="form-control" formControlName="IFAFabtrol">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">KSS file</label>
              <select class="form-control" formControlName="IFAKSS">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">If any Other(specfiy)</label>
              <input type="textbox" class="form-control" formControlName="IFAOther">
            </div>
          </div>

          <p class="headingclass">IFF Deliverables</p>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Shop and GA dwgs in PDF/DWG</label>
              <select class="form-control" formControlName="IFFShop">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Single part dwgs in PDF/DWG</label>
              <select class="form-control" formControlName="IFFSingle">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">NC/DSTV files (Types)</label>
              <select class="form-control" formControlName="IFFNC">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Reports (Type of reports to be mentioned)</label>
              <select class="form-control" formControlName="IFFReports">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Transmittal</label>
              <select class="form-control" formControlName="IFFTransmittal">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Fabtrol Files</label>
              <select class="form-control" formControlName="IFFFabtrol">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">KSS file</label>
              <select class="form-control" formControlName="IFFKSS">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">If any Other(specfiy)</label>
              <input type="textbox" class="form-control" formControlName="IFFOther">
            </div>
          </div>

          <p class="headingclass">Additional Information</p>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Tekla Version</label>
              <input type="textbox" class="form-control" formControlName="TeklaVersion">
            </div>
          </div>

          <p class="headingclass">Weekly updates to client</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Person Responsible</label>
              <app-autocomplete [autoCompleteText]="responsiblePersonText" (setAutoComplete)="setAutoCompleteResponsiblePerson($event)"
                [autoCompleteValue]="responsiblePersonValue" (fiteredData)="getResponsiblePersonList($event)"
                [filteredOptions]="responsiblePersonEmployee">
              </app-autocomplete>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Clarification Log</label>
              <select class="form-control" formControlName="ClarificationLog">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Reporting Day</label>
              <select formControlName="ClarificationLogDay" class="form-control">
                <option value=0>--None--</option>
                <option *ngFor="let week of WeekDayData" value={{week.WeekId}}>{{week.WeekDay}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Change Order Log</label>
              <select class="form-control" formControlName="ChangeLog">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Reporting Day</label>
              <select formControlName="ChangeLogDay" class="form-control">
                <option value=0>--None--</option>
                <option *ngFor="let week of WeekDayData" value={{week.WeekId}}>{{week.WeekDay}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Project Status List</label>
              <select class="form-control" formControlName="StatusList">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Reporting Day</label>
              <select formControlName="StatusListDay" class="form-control">
                <option value=0>--None--</option>
                <option *ngFor="let week of WeekDayData" value={{week.WeekId}}>{{week.WeekDay}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Drawing Hold List</label>
              <select class="form-control" formControlName="DrawingList">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Reporting Day</label>
              <select formControlName="DrawingListDay" class="form-control">
                <option value=0>--None--</option>
                <option *ngFor="let week of WeekDayData" value={{week.WeekId}}>{{week.WeekDay}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Model Upload</label>
              <select class="form-control" formControlName="ModelUpload">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Reporting Day</label>
              <select formControlName="ModelUploadDay" class="form-control">
                <option value=0>--None--</option>
                <option *ngFor="let week of WeekDayData" value={{week.WeekId}}>{{week.WeekDay}}
                </option>
              </select>
            </div>
          </div>

          <p class="headingclass">Remarks On Kick-off Meeting</p>
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" formControlName="Description" rows="3"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!kickOffForm">Save</button>
      </div>
    </form>
  </div>
</div>