import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class OtherEmailService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService) { }

    getEmailSetting(Type='') {
      const q: any = this.variableService.getSearchKeys();
      return this.apiService.get(`api/settings/getall?id=${q.paramId}&MasterType=${q.type || Type}&`);
    }

    createEmailSetting(form) {
      return this.apiService.post('api/settings/create' , form);
    }

    getEmailSettingById() {
      const q: any = this.variableService.getSearchKeys();
      return this.apiService.get(`api/settings/getbyid?Id=${q.UserId}&`);
    }
}

