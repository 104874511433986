import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';

@Injectable()
export class NavItemService {

  constructor(private apiService: ApiService) { }
  addNavItem(form) {
    return this.apiService.post('api/navItem/create', form);
  }

}
