import { Injectable } from '@angular/core';
import { ApiService } from '../ApiService/api.service';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(
    private apiService: ApiService,
  ) { }

  changePassword(form) {
    return this.apiService.post('api/changePassowrd/create', form);
  }
}
