import { CompanyList } from '../../product/crm/model/companyListModel/company-list';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TopnavbarService } from '../../product/crm/services/top-nav-bar/topnavbar.service';


declare var $: any;
declare var jquery: any;

@Component({
  selector: 'topnavbar',
  templateUrl: 'topnavigationnavbar.template.html',
  styleUrls: ['./topnavigationnavbar.component.css']
})
export class TopNavigationNavbarComponent implements OnInit {
  @Output() calcWidth = new EventEmitter();

  companyList: CompanyList[];
  public user: any;
  public menuItems: any[];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private topnavbarService: TopnavbarService
  ) {
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.menuItems = [];
  }
  ngOnInit() {
    this.getCompaniesList();
    this.getMenuItems();
    this.getMenuOpen();
    // this.toggleMenu() ;
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['login']);
  }

  open(content) {
    this.modalService.open(content, { size: 'lg' }).result.then(
      result => {
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  setup() {
    this.router.navigate(['setup']);
  }

  onCompanyList(value) {
    const tokenKey = 'currentUser';
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    user.CompanyId = value;
    user.CompanyName = this.companyList[0].Text;
    sessionStorage.setItem(tokenKey, JSON.stringify(user));
    this.getCurrencyList();
    window.location.reload();
  }

  getCompaniesList() {
    const tokenKey = 'currentUser';
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.topnavbarService.getCompanyList().subscribe(data => {
      this.companyList = data;
      user.CompanyName = this.companyList[0].Text;
      sessionStorage.setItem(tokenKey, JSON.stringify(user));
      this.getCurrencyList();
    });
  }

  getCurrencyList() {
    this.topnavbarService.getCurrencyPropertiesList().subscribe(data => {
      const tokenKey = 'currentUser';
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      user.CurrencyCode = data.Currency.CurrencyCode;
      user.CurrencyId = data.Currency.CurrencyId;
      sessionStorage.setItem(tokenKey, JSON.stringify(user));
    });
  }

  setSelectedPage(page: any) {
    this.topnavbarService.setPage(page);
  }

  getMenuItems() {
    this.topnavbarService.getMenuItems().subscribe(data => {
      this.menuItems = data.Access;
      setTimeout(() => {
        this.calcWidth.next('hello');
      }, 0);
    });
  }

  toggleMenu() {
    // $('.hamburger').toggleClass('is-active');

    // $('body').toggleClass('menu_expanded');
    window.dispatchEvent(new Event('resize'));
  }

  getMenuOpen() {
    // $('body').addClass('menu_expanded');
  }
}
