import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../../../ApiService/api.service';
import { VariableService } from '../../../VariableService/variable.service';
@Injectable()
export class NewEventService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService) {
  }

  getNewEventType() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/newevent/getall?BaseId=${q.paramId}&MasterType=${q.type}&`
    );
  }
  // Create Method for NewEvent
  addNewEvent(form) {
    return this.apiService.post('api/newevent/create', form);
  }
  // get by id method for NewEvent
  newEventById(EventId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/newevent/getbyid?Id=${EventId}&MasterType=${q.type}&`);
  }
}
