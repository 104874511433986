<div class="user_track">
  <div class="row">
    <h2 class="col-md-12">User Tracking</h2>
    <div class="col-sm-3 user_track_cont">
      <h4>Date</h4>
      <input type="text" placeholder="Select Date" class="form-control" #dp="bsDatepicker" bsDatepicker [bsValue]="bsValue" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',showWeekNumbers: false,containerClass :'theme-dark-blue'}"
        [(ngModel)]="selectedDate">
      <h4 class="user_list_title">User lists</h4>
      <div class="list_of_users">
        <div (click)="select(i)" *ngFor="let user of userList; let i=index" [ngClass]="{'active': selectedIndex == i}" class="user_lists" >
          <div (click)="updateMap(user.UserProfileId)">{{user.FirstName}} {{user.LastName}}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-9 mapContainer">
      <!-- <agm-map style="height: 500px"> -->
      <!-- <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker> -->
      <!-- </agm-map> -->
      <div #googleMap class="google_map"></div>
    </div>
  </div>
</div>

<!-- api/userProfile/getall -->