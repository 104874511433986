import { AutocompleteModel } from './../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from './../../../services/autocomplete/autocomplete.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.css']
})
export class TaskDetailComponent implements OnInit {
  @Input() taskData: any;

  constructor(
  ) { }

  ngOnInit() {

  }

}
