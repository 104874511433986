import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Editor } from 'ngx-editor';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { EmailService } from '../../../../services/common/activity-page/email/email.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../../services/common/common/common.service';

declare var $: any;

@Component({
  selector: 'app-email-template-modal',
  templateUrl: './email-template-modal.component.html',
  styleUrls: ['./email-template-modal.component.css']
})
export class EmailTemplateModalComponent implements OnInit {
  @Output() getData = new EventEmitter();
  emailTemplateForm: UntypedFormGroup;
  emialTemplateData:any;
  editor: Editor;
  HTMLValue: any;
  columns=[];
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '20rem',
    width: 'auto',
    translate: 'no',
    'toolbar': [
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['code', 'blockquote'],
      ['ordered_list', 'bullet_list'],
      [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
      ['link', 'image'],
      ['text_color', 'background_color'],
      ['align_left', 'align_center', 'align_right', 'align_justify'],
      ['horizontal_rule', 'format_clear'],
    ]
  };
  constructor(public fb: UntypedFormBuilder,
    private emailService:EmailService,
    public activeModal: NgbActiveModal,
    private commonService:CommonService
    ) { }
    onSubmit(form: UntypedFormGroup) {
      this.getData.emit(form.value);
    }
  ngOnInit(): void {
    this.editor = new Editor();
    this.emailTemplateForm = this.fb.group({
      EmailTemplateName: '',
      RelatedType: '',
      Subject: '',
      HTMLContent: '',
      Description: '',
      Category:'',
      Type:''
    });
    if (this.emialTemplateData !== undefined) {

      this.emailTemplateForm.patchValue(this.emialTemplateData);
      this.HTMLValue = this.emialTemplateData.HTMLContent;
    }
  }

  onEntity(value) {
    if (!this.HTMLValue) {
      this.HTMLValue = '';
    }
    this.HTMLValue =  ' ';
    this.commonService.getColumnData('CRM'+value).subscribe((data)=>{
      this.columns = data.ColumnNames;
    })
  }

  insert(name){
    console.log('sdf',this.emailTemplateForm.get('HTMLContent').value)
    this.HTMLValue = this.emailTemplateForm.get('HTMLContent').value? this.emailTemplateForm.get('HTMLContent').value+"<span>{{"+name+"}}</span>":"<span>{{"+name+"}}</span>";
  }


}
