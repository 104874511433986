<div class="tab_title">
    <h3 class="page_title pull-left">
      <span class="menu_icon CRMQuotes fa fa-tags">
      </span>
      Subscriptions ({{subscriptions?.length}})
    </h3>
    <div class="buttonHeader pull-right">
      <div class="buttonNew" (click)="open()">Add/Edit</div>
    </div>
  </div>
  
  
  <div class="table-container">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-lg-12 col-sm-12">
  
        <ngx-datatable   
          class="material"
          columnMode="force"
          [headerHeight]="50"
          [footerHeight]="0"
          [rowHeight]="50"
          [scrollbarH]="true"
          [limit]="10"
          [rows]="subscriptions">
          <ngx-datatable-column [width]='100' [sortable]="false" prop="SerialNo" name="S.No"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" prop="ServiceName" name="Service Name"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" prop="StartDate" name="Start Date">
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value | date: 'dd-MM-YYYY'}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" prop="EndDate" name="End Date">
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value | date: 'dd-MM-YYYY'}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" prop="BillingType" name="Billing Type"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" prop="Charges" name="Charges"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" prop="InvoiceOn" name="Invoice On"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" prop="IsAutomatic" name="Automatic">
            <ng-template let-value="value" ngx-datatable-cell-template>
                    <span *ngIf="value == false">False</span>
                    <span *ngIf="value == true">True</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" prop="Remarks" name="Remarks"></ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
  