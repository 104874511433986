import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OpportunityService } from '../../../../services/opportunity/opportunity.service';
import { Router } from '@angular/router';
import { VariableService } from '../../../../services/VariableService/variable.service';
@Component({
  selector: 'app-opportunities-related',
  templateUrl: './opportunities-related.component.html',
  styleUrls: ['./opportunities-related.component.css']
})
export class OpportunitiesRelatedComponent implements OnInit {
  @Input() searchKeys;
  masterType: any;
  opportunities: Array<any> = [];
  constructor(
    private opportunityService: OpportunityService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.getAllOpportunities();
  }
  getAllOpportunities() {
    this.opportunityService.getTypeOpportunities().subscribe(data => {
      this.opportunities = data;
    });
  }

  // openOpportunity(opportunityContent) {
  //   this.modalService
  //     .open(opportunityContent, { size: 'lg', backdrop: 'static' })
  //     .result.then();
  // }

  gotoDetailPage(id) {
    const route = 'opportunityDetail/' + id;
    this.router.navigate([route]);
  }
}
