import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { Component, OnInit, Input } from '@angular/core';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.css']
})
export class ContactDetailComponent implements OnInit {
  @Input() contactData: any;
  contactForm: UntypedFormGroup;
  public LeadSourceModel: AutocompleteModel[];
  public TerritoryLookup: AutocompleteModel[];

  constructor(
    private fb: UntypedFormBuilder,
    private masterLookupService: MasterLookupService,
    private configService: ConfigurationService,
  ) { }

  ngOnInit() {
    this.contactForm = this.fb.group({
      ContactId: null,
      Salutation: '',
      FirstName: '',
      LastName: '',
      Title: '',
      Email: '',
      Phone: '',
      MobilePhone: '',
      ReportsToId: '',
      Department: '',
      Fax: '',
      AccountId: '',
      Description: '',
      OwnerId: '',
      HomePhone: '',
      Level: '',
      Languages: '',
      AssistantName: '',
      AssistantPhone: '',
      BirthDate: '',
      OtherPhone: '',
      LeadSource: '',
      FaceBookID: '',
      TwitterID: '',
      SkypeID: '',
      TerritoryName: '',
      ContactOwnerName: '',
      AccountName: '',
      ContactName: '',

      BillingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      }),
      ShippingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      })
    });
    this.getLeadSourceList();
    this.getTerritoryList();
    this.contactForm.patchValue(this.contactData);
  }

  getLeadSourceList() {
    this.masterLookupService.getDropdownList('Lead Source').subscribe(data => {
      this.LeadSourceModel = data;
    });
  }

  getTerritoryList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryLookup = data;
    });
  }

}
