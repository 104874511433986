<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">Project Tasks For Milestone </h4>
</div>

<div class="modal-body">
  <div class="table_cont">
    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <table class="table table-striped table-bordered dynomic_table">
        <thead>
          <tr>
            <th class="number_small">
              <input class="custom_checkbox" type="checkbox" [(ngModel)]="selectAll" (change)="selectedAll();">
            </th>
            <th class="number_small">S.No.</th>
            <th class="name_long">Project Task Name</th>
            <th class="name_long">Assigned To</th>
            <th class="number_medium">Start Date</th>
            <th class="number_medium">End Date</th>
            <th class="number_largeshort">Percenatge </th>
            <th class="number_largeshort">Weightage </th>
            <th class="number_short">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let list of taskOfMilestoneForm.ListOfTaskOfMilestoneTable; let i=index">
            <td>
              <input class="custom_checkbox" type="checkbox" [(ngModel)]="list.IsSelect"
                (change)="isSelected();checkIfAllSelected();">
            </td>
            <td style="text-align: center;">
              <input type="hidden" class="form-control" value={{i+1}} [(ngModel)]="list.SerialNo">{{i+1}}
            </td>
            <td>
              <input type="text" class="form-control" [(ngModel)]="list.ProjectTaskName">
            </td>
            <td>
              <app-autocomplete [autoCompleteText]="list.autoCompleteText" (setAutoComplete)="setAutoComplete($event)"
                [autoCompleteValue]="list.autoCompleteValue" [key]="i" (fiteredData)="fiteredData($event)"
                [filteredOptions]="list.filteredOptions">
              </app-autocomplete>
            </td>
            <td>
              <input type="text" (bsValueChange)="onStartDate($event,i)" class="form-control" #dp="bsDatepicker"
                [(ngModel)]="list.StartDate" [(bsValue)]="list.StartDate" value="{{ StartDate | date: 'dd-MM-yyyy' }}"
                bsDatepicker [bsConfig]="bsConfig" placement="right">
              <p class="inputdate" (click)="dp.toggle()">
                <i class="fa fa-calendar-alt fa-fw"></i>
              </p>
            </td>
            <td>
              <input type="text" (bsValueChange)="onEndDate($event,i)" class="form-control" #bsdp="bsDatepicker"
                [(ngModel)]="list.EndDate" [(bsValue)]="list.EndDate" value="{{ EndDate | date: 'dd-MM-yyyy' }}"
                bsDatepicker [bsConfig]="bsConfig" placement="right">
              <p class="inputdate" (click)="bsdp.toggle()">
                <i class="fa fa-calendar-alt fa-fw"></i>
              </p>
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="list.Percentage"
                (input)="addFieldValue(i);isSelected()" />
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="list.Weightage" (input)="addFieldValue(i)" />
            </td>

            <td>
              <div class="btn-group" dropdown>
                <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
                <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot align="right" style="border: 1px solid #ccc;">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>Total</th>
            <th>{{totalSum}}</th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" id="btnCancel"
    (click)="activeModal.close('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
</div>