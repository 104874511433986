import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class MessageService {
    private subject = new Subject<any>();
    show = false;
    @Output() change: EventEmitter<boolean> = new EventEmitter();
    sendMessage(message) {
        this.subject.next(message);
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    toggle() {
        this.show = true;
        this.change.emit(this.show);
    }

    closeToggle() {
        this.show = false;
        this.change.emit(this.show);
    }
}
