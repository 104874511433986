import { DeleteModalComponent } from './../../../../../common/delete-modal/delete-modal.component';
import { NewQuotationComponent } from './../new-quotation/new-quotation.component';
import { EmployeeInfoService } from './../../../services/employeeInfo/employee-info.service';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Editor,toHTML } from 'ngx-editor';
import { FormGroup, FormControl } from '@angular/forms';

import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { VariableService } from '../../../services/VariableService/variable.service';
import { QuoteService } from '../../../services/quote/quote.service';
import { CommonService } from '../../../services/common/common/common.service';
import * as Utils from '../../../../../common/utils';
import { Page } from '../../../../../models/page';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';

import { serviceURLs } from './../../../constants/CRMService/serviceURLs';
import { InvoiceService } from '../../../services/invoice/invoice.service';
import { GlobalService } from '../../../services/global-Service/global.service';
declare var $: any;

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.css']
})
export class QuotesComponent implements OnInit {
  @Input()
  deleteData: any;
  temp: any[] = [];
  alerts: any[];
  quoteData: any;
  selectedPageFilter: any;
  pageFilters: any[];
  getPageFilterCalled = false;
  quoteDownloadData: Array<any> = [];
  ReportId: any;
  filetype: any;
  TransactionNo: any;
  RDLName: any;
  ToMailId = "";
  FromEmail: any;
  Subject: any;
  Body: any;
  showSpinner: Boolean = true;
  quotes: Array<any> = [];
  filterObj: any;
  public showFilter: Boolean = false;
  filterArray: Array<any> = [];
  filterLogic = '';
  selectedPage: any = {};
  columnNames: any[];
  isFilterLogic: boolean;
  page = new Page();
  form = new FormGroup({
    Body: new FormControl(null),
  });
  searchStr: String = '';
  searchValueSet = false;
  searchFilterAdded = false;
  newListName = ''; AccessType = '';
  editor: Editor;
  HTMLValue: any;
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '20rem',
    width: 'auto',
    translate: 'no',
    'toolbar': [
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['code', 'blockquote'],
      ['ordered_list', 'bullet_list'],
      [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
      ['link', 'image'],
      ['text_color', 'background_color'],
      ['align_left', 'align_center', 'align_right', 'align_justify'],
      ['horizontal_rule', 'format_clear'],
    ]
  };
  constructor(
    private modalService: NgbModal,
    private quoteService: QuoteService,
    private variableService: VariableService,
    private invoiceService: InvoiceService,
    private topnavbarService: TopnavbarService,
    private commonService: CommonService,
    private cService: CommonService,
    private employeeInfoService: EmployeeInfoService,
    private globalService: GlobalService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
    this.filterArray = [];
    this.filterObj = {};
    this.page.size = 15;
    this.selectedPageFilter = {};
  }

  ngOnInit() {
    this.editor = new Editor();
    this.columnNames = [];
    this.getAllColumns();
    this.getPageFilters();
  }

  getQuoteData(reqOptions?: any) {
    this.variableService.setSearchKeys({
      type: this.selectedPage.pagename
    });

    if (!reqOptions) {
      reqOptions = {};
      reqOptions.PageNumber = 1;
      reqOptions.PageSize = this.page.size;
      reqOptions.TotalRecords = null;
    }
    reqOptions.MasterType = this.selectedPage.pagename;
    if (this.searchStr && this.searchStr.length > 1) {
      this.searchFilterAdded = true;
      reqOptions.PageNumber = 1;
      const columnNames = [];
      this.columnNames.map(column => {
        columnNames.push(column.Name);
      });

      if (this.filterArray.length === 1) {
        this.filterLogic += `1 AND ${this.filterArray.length + 1}`;
      } else if (this.filterArray.length > 1) {
        this.filterLogic += ` AND ${this.filterArray.length + 1}`;
      }
      this.filterArray.push({
        columnName: `concat(${columnNames.join(',')})`,
        sqlOperator: 'LIKE',
        value: `%${this.searchStr}%`
      });
    }
    reqOptions.Filter = {
      logic: _.cloneDeep(this.filterLogic),
      FilterItems: _.cloneDeep(this.filterArray)
    };

    if (this.searchStr && this.searchStr.length > 1) {
      this.filterArray.pop();
      this.setLogic();
    }
    this.commonService.getTableData(reqOptions).subscribe(
      res => {
        const rows = res.Data;
        this.page = {
          totalElements: res.TotalRecords,
          totalPages: Math.ceil(
            res.TotalRecords / reqOptions.PageSize
          ),
          size: this.page.size,
          pageNumber: reqOptions.PageNumber - 1
        };
        // this.quotes = res.Data;
        // this.page.totalElements = res.TotalRecords;
        // this.page.pageNumber = 0;
        // this.page.totalPages = Math.ceil(
        //   res.TotalRecords / reqOptions.PageSize
        // );
        this.showSpinner = false;
        this.temp = res;
        for (const row of rows) {
          row.height = Math.floor(Math.random() * 80) + 50;
        }
        this.quotes = rows;
        if (this.getPageFilterCalled) {
          this.showFilter = true;
          this.getPageFilterCalled = false;
        }
      },
      err => {
        this.showSpinner = false;
        if (this.searchStr && this.searchStr.length > 2) {
          this.filterArray.pop();
          this.setLogic();
        }
      }
    );
  }

  setSearchStr(val) {
    this.searchStr = val;
    if (this.searchStr.length > 1) {
      this.showSpinner = true;
      this.getQuoteData();
    } else if (!this.searchStr) {
      if (this.searchFilterAdded) {
        this.filterArray.pop();
        this.setLogic();
        this.searchFilterAdded = false;
      }
      this.getQuoteData();
    }
  }

  setLogic() {
    if (this.filterArray.length > 1) {
      let l: any;
      // this.filterArray.splice(0, 1);
      this.filterArray.forEach((filter, i) => {
        if (i > 0) {
          l += 'AND ' + (i + 1);
        } else {
          l = '1 ';
        }
      });
      this.applyFilterLogic(l);
    } else {
      this.applyFilterLogic('');
    }
  }

  getAllColumns() {
    this.commonService
      .getColumnData(this.selectedPage.pagename)
      .subscribe(res => {
        this.columnNames = res.ColumnNames;
        this.filterObj = Utils.getColumnsObject(res.ColumnNames);
      });
  }

  open() {
    const modalRef = this.modalService.open(NewQuotationComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.getData.subscribe(() => {
      this.getQuoteData();
    });
  }

  editopen(data) {
    this.quoteService.editQuoteById(data.masterId).subscribe(resData => {
      this.quoteData = resData;
      const modalRef = this.modalService.open(NewQuotationComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.quoteData = this.quoteData;
      modalRef.componentInstance.getData.subscribe(() => {
        this.getQuoteData();
      });
    });
  }

  onDelete(data) {
    this.deleteData = data;
    const modalRef = this.modalService.open(DeleteModalComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.deleteData = this.deleteData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getQuoteData();
    });
  }

  toggle() {
    this.showFilter = !this.showFilter;
    this.quotes = [...this.quotes];
  }

  applyFilter(arr: any[]) {
    this.filterArray = arr;
    if (this.selectedPageFilter.FilterID) {
      this.createNewPageFilter(
        this.selectedPageFilter.FilterName,
        0,
        { FilterItems: arr, logic: this.filterLogic },
        this.selectedPageFilter.FilterID,
        1
      );
    } else {
      this.showSpinner = true;
      this.getQuoteData();
    }
  }

  applyFilterLogic(logic: string) {
    this.filterLogic = logic;
  }

  addLogic() {
    this.isFilterLogic = true;
  }

  removeLogic() {
    this.filterLogic = '';
    this.isFilterLogic = false;
  }

  custommenu(content) {
    const columnFilCMP = this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static'
    });
  }

  makeColumnsVisible(data) {
    const cols = [];
    data.oldCols.forEach(c => {
      if (data.newCols[c.Name]) {
        c.Visible = data.val;
        cols.push(c);
      } else {
        cols.push(c);
      }
    });
    this.columnNames = cols;
  }

  /**Page filter (header level) */
  getPageFilterData(pageFilter) {
    this.showFilter = false;
    this.getPageFilterCalled = true;
    this.selectedPageFilter = pageFilter;
    const filters = JSON.parse(pageFilter.FilterQuery);

    this.filterLogic = filters && filters.logic ? filters.logic : '';
    this.filterArray =
      filters && filters.FilterItems ? filters.FilterItems : [];
    this.showSpinner = true;
    this.getQuoteData();
  }

  getPageFilters(b = false) {
    this.cService.getPageFilter(this.selectedPage.pagename).subscribe(
      res => {
        this.pageFilters = [];

        this.pageFilters.push({
          FilterName: 'All',
          FilterID: null,
          FilterQuery: null,
          MasterType: this.selectedPage.pagename,
          AccessType: 1
        });
        res.forEach(obj => {
          this.pageFilters.push(obj);
        });
        if (!b && !this.selectedPageFilter.FilterID) {
          this.selectedPageFilter = this.pageFilters[0];
        } else {
          if (!b) {
            this.selectedPageFilter = this.pageFilters[
              this.pageFilters.length - 1
            ];
          }
        }
        this.showSpinner = true;
        this.getQuoteData();
      },
      err => {
        console.log('err===', err);
      }
    );
  }

  createNewPageFilterModal(content) {
    this.modalService
      .open(content, { size: 'sm', backdrop: 'static' })
      .result.then(result => {
        // console.log(`Closed with: ${result}`);
      });
  }

  createNewPageFilter(
    name,
    accessType,
    filterQuery = {},
    filterID = null,
    mode = 0
  ) {
    this.cService
      .createPageFilter({
        FilterID: filterID,
        FilterName: name,
        AccessType: parseInt(accessType, 10),
        Mode: mode,
        FilterQuery: JSON.stringify(filterQuery),
        MasterType: this.selectedPage.pagename
      })
      .subscribe(
        res => {
          this.selectedPageFilter.FilterQuery = JSON.stringify(filterQuery);
          if (!filterID) {
            if (name !== 'All') {
              this.pageFilters.push(res);
              this.selectedPageFilter = res;
              $('#btnCancel').trigger('click');
              this.newListName = '';
              this.AccessType = '';
            }
          }

          this.getPageFilterData(this.selectedPageFilter);
        },
        err => {
          console.log('err===', err);
        }
      );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onSelectCheckBox(data) {
    this.quoteDownloadData = data;
  }

  getQuoteDownloadData() {
    for (let i = 0; i < this.quoteDownloadData.length; i++) {
      this.ReportId = this.quoteDownloadData[i].QuoteID;
      this.filetype = 'pdf';
      this.TransactionNo = this.quoteDownloadData[i].QuotationNo;
      this.RDLName = this.quoteDownloadData[i].RDLName;
      this.ToMailId = this.ToMailId ? this.ToMailId+','+this.quoteDownloadData[i].ToMailId:this.quoteDownloadData[i].ToMailId;
    }
  }

  generatePDF() {
    if (this.quoteDownloadData.length === 0) {
      this.globalService.showError();
    } else {
      this.getQuoteDownloadData();
      const user = JSON.parse(sessionStorage.getItem('currentUser'));

      // tslint:disable-next-line:max-line-length
      const url = `api/files/getfile?ReportId=${this.ReportId}&FileType=${
        this.filetype
        }&TransactionNo=${this.TransactionNo}&RDLName=${this.RDLName}&CompanyId=${
        user.CompanyId
        }&UserId=${user.UserId}`;
      window.open(serviceURLs.CRMServiceBaseURL + url, '_blank');
    }
  }

  sendQuoteMail(quoteMailContent) {
    this.reset();
    if (this.quoteDownloadData.length === 0) {
      this.globalService.showError();
    } else {
      this.employeeInfoService.getAllInfo().subscribe(infodata => {
        this.FromEmail = infodata.EmailID;
        this.getQuoteDownloadData();
      });
      this.modalService.open(quoteMailContent, {
        size: 'sm',
        backdrop: 'static',
        keyboard: false
      });
    }
  }
  sendMail() {
    this.ToMailId = this.ToMailId;
    if (this.FromEmail === '' || this.ToMailId === '') {
      this.globalService.showEmailError();
    } else {
      const tomailName = this.ToMailId;
      // tslint:disable-next-line:max-line-length
      this.invoiceService
        .sendInvoiceEmail(
          this.ReportId,
          this.RDLName,
          this.TransactionNo,
          this.filetype,
          this.ToMailId,
          this.FromEmail,
          toHTML(this.form.value.Body),
          this.Subject
        )
        .subscribe(data => {
          this.globalService.showEmailSuccess({ masterName: tomailName });
        });
      $('#btnCancel').trigger('click');
      this.reset();
    }
  }

  reset() {
    this.Subject = '';
    this.Body = '';
    this.FromEmail = '';
    this.ToMailId = '';
  }
}
