import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit
} from "@angular/core";
// import {} from "googlemaps";
import "rxjs/add/operator/takeWhile";
import { UserTrackingService } from "../../services/user-tracking/user-tracking.service";

@Component({
  selector: "user-tracking",
  templateUrl: "./user-tracking.component.html",
  styleUrls: ["./user-tracking.component.css"]
})
export class UserTrackingComponent implements OnInit, OnDestroy, AfterViewInit {
  // @ViewChild("googleMap")
  gmapElement: any;
  // map: google.maps.Map;
  userList: any[];
  bsValue = new Date();
  selectedDate: any;
  selectedIndex: number;
  alive = false;
  // mapProp = {
  //   center: new google.maps.LatLng(28.4595, 77.0266),
  //   zoom: 14,
  //   mapTypeId: google.maps.MapTypeId.ROADMAP
  // };
  constructor(private _trackUser: UserTrackingService) {
    this.userList = [];
    this.alive = true;
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {
    this.getEmplayeesList();
    // this.drawMap();
  }

  ngAfterViewInit() {
    this._trackUser.watchLocationData$
      .takeWhile(() => this.alive)
      .subscribe((data: any) => {
        if (data.length > 0) {
          // this.map.setCenter(data[0]);
          // this.map.setZoom(50);
          // data.forEach(position => {
          //   const marker = new google.maps.Marker({ position });
          //   marker.setMap(this.map);
          // });
          // const polygon = new google.maps.Polygon({
          //   paths: data,
          //   strokeColor: "#FF0000",
          //   strokeOpacity: 0.8,
          //   strokeWeight: 2,
          //   fillColor: "transparent",
          //   fillOpacity: 0.35
          // });

          // polygon.setMap(this.map);
        } else {
          this.drawMap();
          // if (this.gmapElement && this.gmapElement.nativeElement) {
          // this.map = new google.maps.Map(
          //   this.gmapElement.nativeElement,
          //   mapProp
          // );
          // }
        }
      });
  }

  drawMap() {
    // mapTypeId: google.maps.MapTypeId.ROADMAP
    // this.map = new google.maps.Map(
    //   this.gmapElement.nativeElement,
    //   this.mapProp
    // );
  }

  getEmplayeesList() {
    this._trackUser.getEmployeesList().subscribe(
      res => {
        this.userList = res;
      },
      err => {
        console.log("err", err);
      }
    );
  }

  updateMap(id) {
    if (this.selectedDate) {
      const date = new Date(this.selectedDate);
      const d = `${date.getDate()}-${date.getMonth() +
        1}-${date.getFullYear()}`;
      this._trackUser.getEmployeeLocation(d, id);
    } else {
      window.alert("Select date");
    }
  }

  select(index: number) {
    this.selectedIndex = index;
  }
}
