import { DatePipe } from '@angular/common';
import { AutocompleteService } from './../../../../services/autocomplete/autocomplete.service';
import { ProjectTaskTemplateService } from './../../../../services/project-task-template/project-task-template.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { GlobalService } from '../../../../services/global-Service/global.service';

@Component({
  selector: 'app-task-of-milestone',
  templateUrl: './task-of-milestone.component.html',
  styleUrls: ['./task-of-milestone.component.css']
})
export class TaskOfMilestoneComponent implements OnInit {
  public bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  selectAll: any;
  taskOfMilestoneForm: TaskOfMilestoneForm;
  listOfMilestoneTaskObj: ListOfMilestoneTaskObj;
  taskTemplateData: any;
  templateData: any;
  validFrom = new Date();
  validTo = new Date();
  totalSum = 0.00;

  constructor(
    private projectTaskTemplateService: ProjectTaskTemplateService,
    public activeModal: NgbActiveModal,
    private autocompleteService: AutocompleteService,
    public datePipe: DatePipe,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.bsConfig.dateInputFormat = 'DD-MM-YYYY';
    this.bsConfig.showWeekNumbers = false;
    this.bsConfig.containerClass = 'theme-dark-blue';
    this.resetTaskOfMilestoneForm();
    this.resetListOfMilestoneTaskObj();
    this.getAllProjectTaskTemplateList();
  }

  addFieldValue(i) {
    this.resetListOfMilestoneTaskObj();
    const obj = this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable[i + 1];
    if (!obj) {
      this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable.push(this.listOfMilestoneTaskObj);
    }
    this.resetListOfMilestoneTaskObj();
  }

  deleteFieldValue(i: number) {
    if (this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable.length === 1) {
      return false;
    } else {
      this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable.splice(i, 1);
      return true;
    }
  }

  getAllProjectTaskTemplateList() {
    this.reset();
    const templateId = this.taskTemplateData.data;
    this.projectTaskTemplateService.getAllProjectTaskTemplate(templateId).subscribe(data => {
      this.templateData = data;
      data.map((obj) => {
        this.taskOfMilestoneForm['ListOfTaskOfMilestoneTable'].push(obj);
      });
      this.taskOfMilestoneForm['ListOfTaskOfMilestoneTable'].push(this.listOfMilestoneTaskObj);
      this.resetListOfMilestoneTaskObj();
    });
  }

  selectedAll() {
    const listData = this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable;
    for (let i = 0; i < listData.length; i++) {
      listData[i].IsSelect = this.selectAll;
    }
    this.isSelected();
  }

  checkIfAllSelected() {
    const listData = this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable;
    this.selectAll = listData.every((item: any) => {
      return item.IsSelect === true;
    });
    this.isSelected();
  }

  get milestoneListFormData() {
    return this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable;
  }

  isSelected() {
    this.totalSum = 0;
    const taskOfMilestone = this.milestoneListFormData;
    for (let k = 0; k < taskOfMilestone.length; k++) {
      if (taskOfMilestone[k].IsSelect === true) {
        this.totalSum += parseFloat(taskOfMilestone[k].Percentage);
      }
    }
  }

  fiteredData(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable[value.indexValue].filteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }

  setAutoComplete(data: any) {
    const value = data.data.option.value.Value;
    this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable[data.index].AssignedTo = value;
  }

  onStartDate(sd, i) {
    const startdate = this.datePipe.transform(sd, 'dd-MM-yyyy');
    this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable[i].StartDate = startdate;
  }

  onEndDate(ed, i) {
    const enddate = this.datePipe.transform(ed, 'dd-MM-yyyy');
    this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable[i].EndDate = enddate;
  }

  onSubmit() {
    const ListOfData = [];
    this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable = this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable.filter(
      obj => {
        return obj.IsSelect && obj.ProjectTaskName;
      }
    );

    let responseMessage = [];
    this.taskOfMilestoneForm.ListOfTaskOfMilestoneTable.forEach(i => {
      ListOfData.push({
        SerialNo: i.SerialNo,
        ProjectTaskName: i.ProjectTaskName,
        AssignedTo: i.AssignedTo,
        StartDate: this.datePipe.transform(i.StartDate, 'yyyy-MM-dd'),
        EndDate: this.datePipe.transform(i.EndDate, 'yyyy-MM-dd'),
        Percentage: i.Percentage,
        Weightage: i.Weightage,
      });
    });

    if (ListOfData.length === 0) {
      this.globalService.showError();
      setTimeout(() => {
        this.getAllProjectTaskTemplateList();
      }, 1500);

    } else {
      this.projectTaskTemplateService.createTaskOfMilestone({
        TaskOfMilestoneDetail: ListOfData,
        ProjectID: this.taskTemplateData.projectId,
        MileStoneId: this.taskTemplateData.milestoneId,
      }).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showDefaultSuccess({ masterName: 'Task of milestone' });
        }
      });
      this.activeModal.close();
    }
  }

  reset() {
    this.resetTaskOfMilestoneForm();
    this.resetListOfMilestoneTaskObj();
  }

  resetListOfMilestoneTaskObj() {
    this.listOfMilestoneTaskObj = {
      SerialNo: '',
      ProjectTaskName: '',
      AssignedTo: '',
      StartDate: '',
      EndDate: '',
      Percentage: 0,
      Weightage: '',
      IsSelect: false,
    };
  }

  resetTaskOfMilestoneForm() {
    this.taskOfMilestoneForm = {
      ListOfTaskOfMilestoneTable: [],
    };
  }

}


declare class ListOfMilestoneTaskObj {
  SerialNo: string;
  ProjectTaskName: string;
  AssignedTo: string;
  StartDate: string;
  EndDate: string;
  Percentage: Number;
  Weightage: string;
  IsSelect: boolean;
}

declare class TaskOfMilestoneForm {
  ListOfTaskOfMilestoneTable: Array<any>;
}
