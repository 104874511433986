import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';

@Injectable()
export class TargetService {

  constructor(
    private apiService: ApiService
  ) { }
  getTarget() {
    return this.apiService.get(`api/target/getall?`);
  }
  addTarget(form) {
    return this.apiService.post('api/target/create', form);
  }
  TargetById(targetId) {
    return this.apiService.get(`api/target/getbyid?Id=${targetId}&`);
  }
  updateTarget(form) {
    return this.apiService.put('api/target/edit', form);
  }
}
