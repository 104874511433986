<div class="main_header">
  <!-- class="container-fluid " -->
  <div >
    <div class="tabbable-panel upper_menu col-md-12 col-lg-12 col-xs-12 col-sm-12">
      <div class="tabbable-line text-center">
        <!-- <span class="menu_minifier pull-left" (click)="toggleMenu()">
          <div class="hamburger" id="hamburger-3">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>
        </span> -->
        <a class="logo pull-left" [routerLink]="['/home']">
          <img  src="/assets/images/logo.png">
        </a>

        <ul class="pull-right high_menu">
          <a class="pull-left" href="" *ngIf="this.user.CompanyLogoFilePath">
            <img style="height: 36px;"
            onError="this.src='/assets/images/b-icon.png'"
            src="{{this.user.CompanyLogoFilePath?this.user.CompanyLogoFilePath:'/assets/images/b-icon.png'}}">
          </a>
          <div class="dropdown drop_list_items">
            <select [(ngModel)]="user.CompanyId" class="form-control" (change)="onCompanyList($event.target.value)">
              <option *ngFor="let selectedCompany of companyList" value={{selectedCompany.Value}}>
                {{selectedCompany.Text}} </option>
            </select>

            <ul class="dropdown-menu add_task_dropdown" id="add_company_dropdown">
              <li>
                <div class="">
                  <div class="text-center user_img">
                  </div>
                  <div class="logged_user">
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- <div class="dropdown drop_list_items">
            <button class="btn add_task" type="button" data-toggle="dropdown">
              <i class="fa fa-plus fa-fw" aria-hidden="true"></i>
            </button>
            <ul class="dropdown-menu add_task_dropdown create_menu">
              <li>
                <h3>Create</h3>
              </li>
              <li>
                <a href="#">
                  <span class="new_task">
                    <i class="fa fa-tasks fa-fw" aria-hidden="true"></i>
                  </span>
                  New Task
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="new_event">
                    <i class="fa fa-calendar fa-fw" aria-hidden="true"></i>
                  </span>
                  Event
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="log_call">
                    <i class="fa fa-edit fa-fw" aria-hidden="true"></i>
                  </span>
                  Log a Call</a>
              </li>
              <li>
                <a href="#">
                  <span class="new_opportunity">
                    <i class="fa fa-user-secret fa-fw" aria-hidden="true"></i>
                  </span>
                  New Opportunity</a>
              </li>
              <li>
                <a href="#">
                  <span class="new_case">
                    <i class="fa fa-briefcase fa-fw" aria-hidden="true"></i>
                  </span>New Case</a>
              </li>
              <li>
                <a href="#">
                  <span class="new_lead">
                    <i class="fa fa-anchor fa-fw" aria-hidden="true"></i>
                  </span> New Lead
                </a>
              </li>
              <li>
                <a [routerLink]="['/email']">
                  <span class="email">
                    <i class="fa fa-envelope fa-fw" aria-hidden="true"></i>
                  </span> Email
                </a>
              </li>
            </ul>
          </div> -->

          <!-- <div class="dropdown drop_list_items">
            <button class="btn add_task" type="button" (click)="setup()">
              <i class="fa fa-cog fa-fw" aria-hidden="true"></i>
            </button>
          </div> -->

          <div class="dropdown drop_list_items">
            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
              <i class="fa fa-user" aria-hidden="true"></i>
              <!-- <span class="caret"></span> -->
            </button>
            <ul class="dropdown-menu">
              <li>
                <div class="">
                  <!-- <div class="text-center user_img">
                    <i class="fa fa-user fa-fw" aria-hidden="true"></i>
                  </div> -->
                  <div class="logged_user">
                    <a href="#" class="logged_user_name">{{user.FirstName}} {{user.LastName}}</a>
                    <a href="javascript:void(0)" class="logout_btn " (click)="logout()">Logout</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss" style="pos-rel">
  <div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h4 class="modal-title pull-left">App Launcher</h4>
    <div class="search_input">
      <input autocomplete="off" type="text" id="" placeholder="Find App or item" class="form-control" min-length="4" />
      <span>
        <i class="fa fa-search fa-fw"></i>
      </span>
    </div>
  </div>
  <div class="modal-body menu_modal_body">
    <div class="extra-pad">
      <div class="list_of_menu">
        <div class="dropdown menu_drop_list_items">
          <span class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
            <!-- <span class="caret"></span> -->
            All Apps
          </span>
          <ul class="dropdown-menu">
            <li>
              <a href="#">Logout</a>
            </li>
            <li>
              <a href="#">Profile</a>
            </li>
            <li>
              <a href="#">Help</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>