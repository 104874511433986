import { AutocompleteService } from './../../../services/autocomplete/autocomplete.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';


@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css']
})
export class TimesheetComponent implements OnInit {
  timesheetForm: TimesheetForm;
  listOfTimesheetObj: ListOfTimesheetObj;
  resourcefilterOption: any;
  resourceText: any; resourceValue: any;
  constructor(
    private autoCompleteService: AutocompleteService,
  ) { }

  ngOnInit() {
    this.resetTimesheetForm();
    this.resetListOfTimesheetObj();
    this.timesheetForm.ListOfTimesheet.push(this.listOfTimesheetObj);

  }

  addFieldValue(i) {
    console.log('oooo====', i);
    this.resetListOfTimesheetObj();
    const obj = this.timesheetForm.ListOfTimesheet[i + 1];
    if (!obj) {
      this.timesheetForm.ListOfTimesheet.push(this.listOfTimesheetObj);
    }
    this.resetListOfTimesheetObj();
  }
  deleteFieldValue(i: number) {
    if (this.timesheetForm.ListOfTimesheet.length === 1) {
      return false;
    } else {
      this.timesheetForm.ListOfTimesheet.splice(i, 1);
      return true;
    }
  }

  getResourceList(value) {
    this.autoCompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.resourcefilterOption = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }


  setAutoCompleteResource(data) {
    const employeeId = data.data.option.value.Value;
    this.timesheetForm.ResourceId = employeeId;
  }

  onPeriodType(value) {
    console.log(value);
  }

  resetForm() {
    console.log('Called');
  }

  onSubmit() {
    console.log('this.timesheetForm', this.timesheetForm);
  }

  resetListOfTimesheetObj() {
    this.listOfTimesheetObj = {
      General: '',
      ActivityType: '',
      Name: '',
      Sun: '',
      Mon: '',
      Tue: '',
      Wed: '',
      Thu: '',
      Fri: '',
      Sat: '',
    };
  }
  resetTimesheetForm() {
    this.timesheetForm = {
      ResourceId: '',
      PeriodType: '',
      ListOfTimesheet: [],
    };
  }


}


declare class ListOfTimesheetObj {
  General: '';
  ActivityType: '';
  Name: '';
  Sun: '';
  Mon: '';
  Tue: '';
  Wed: '';
  Thu: '';
  Fri: '';
  Sat: '';
}

declare class TimesheetForm {
  ResourceId: String;
  PeriodType: String;
  ListOfTimesheet: Array<any>;
}
