import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { KickoffService } from '../../../services/KickOff/kickoff.service';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-kick-off-detail',
  templateUrl: './kick-off-detail.component.html',
  styleUrls: ['./kick-off-detail.component.css']
})
export class KickOffDetailComponent implements OnInit {
  @Input() kickOffdata: any;
  kickOffForm: UntypedFormGroup;
  showSpinner = true;
  checkedByEmployee: any[];
  teamLeadEmployee: any[];
  responsiblePersonEmployee: any[];
  teamLeadText: any; teamLeadValue: any;
  checkedByText: any; checkedByValue: any;
  responsiblePersonValue: any; responsiblePersonText: any;
  public WeekDay = {};
  kickOffProject: any;
  WeekDayData = [];
  currentDay: any;
  constructor(private fb: UntypedFormBuilder,
    public autocompleteService: AutocompleteService,
    private kickOffService: KickoffService,
    private router: Router,
    private datePipe: DatePipe,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.kickOffForm = this.fb.group({
      KickOffID: '',
      ProjectId: '',
      ProjectName: '',
      Client: '',
      ClientProjectNo: '',
      ProjectNo: '',
      GCName: '',
      OwnerName: '',
      StartDate: '',
      DeadLine: '',
      KickoffDate: '',
      PurchaseOrderNo: '',
      Attendees: '',
      Coordinator: '',
      Handledby: '',
      DestinationforUpload: '',
      CheckedBy: '',
      PMResponsible: '',
      GMResponsible: '',
      ScopeofWork: '',
      DrawingStatus: '',
      ConnectionDetails: '',
      SequenceNumber: '',
      DrawingNumbering: '',

      SampleTemplate: '',
      SampleStandard: '',
      SampleReference: '',
      SampleReports: '',
      AdvanceBOM: '',
      IFAShop: '',
      IFASingle: '',
      IFANC: '',
      IFAReports: '',
      IFATransmittal: '',
      IFAFabtrol: '',
      IFAKSS: '',
      IFAOther: '',
      IFFShop: '',
      IFFSingle: '',
      IFFNC: '',
      IFFReports: '',
      IFFTransmittal: '',
      IFFFabtrol: '',
      IFFKSS: '',
      IFFOther: '',
      TeklaVersion: '',
      ContactRFI: '',
      ContactDelivery: '',
      UpdateWeek: '',
      ResponsiblePerson: '',
      ClarificationLog: '',
      ChangeLog: '',
      StatusList: '',
      DrawingList: '',
      ModelUpload: '',
      Description: '',
      AttendSignatures: '',

      ClarificationLogDay: '',
      ChangeLogDay: '',
      StatusListDay: '',
      DrawingListDay: '',
      ModelUploadDay: '',
    });
    this.getAllKickOff();
    this.getWeekDay();
    this.teamLeadText = this.kickOffdata.TeamLeadName;
    this.teamLeadValue = this.kickOffdata.TeamLead;
  }

  getTeamLeadList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.teamLeadEmployee = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }
  setAutoCompleteTeamLead(data) {
    const handleby = data.data.option.value.Value;
    this.kickOffForm.controls['Handledby'].setValue(handleby);
  }

  getCheckedByList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.checkedByEmployee = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }
  setAutoCompleteCheckedBy(data) {
    const checkedBy = data.data.option.value.Value;
    this.kickOffForm.controls['CheckedBy'].setValue(checkedBy);
  }


  getResponsiblePersonList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.responsiblePersonEmployee = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }
  setAutoCompleteResponsiblePerson(data) {
    const responsiblePerson = data.data.option.value.Value;
    this.kickOffForm.controls['ResponsiblePerson'].setValue(responsiblePerson);
  }

  getWeekDay() {
    const weeks = new Array(
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    );

    for (let i = 0; i < weeks.length; i++) {
      this.WeekDay = weeks[i];
      this.WeekDayData.push({ WeekId: i, WeekDay: weeks[i] });
    }
    this.currentDay = this.WeekDay;
  }

  getAllKickOff() {
    this.kickOffService.editKickOffById().subscribe(data => {
      if (data.ProjectId === null) {
        this.kickOffProject = this.kickOffdata;
        this.kickOffForm.patchValue(this.kickOffdata);
      } else {
        this.kickOffForm.patchValue(data);
        this.kickOffProject = data;
      }
      this.kickOffForm.controls['KickoffDate'].setValue(new Date(this.kickOffForm.value.KickoffDate));
      this.kickOffForm.value.Handledby = this.kickOffdata.TeamLead;
    });
  }


  // Submit Function
  onSubmit(form: UntypedFormGroup) {
    const kickOffName = form.value.ProjectName;
    let responseMessage = [];
    form.value.KickoffDate = this.datePipe.transform(form.value.KickoffDate, 'yyyy-MM-dd');
    this.kickOffService.addKickOff(form.value).subscribe(data => {
      responseMessage = data.ErrorMessages;
      if (responseMessage[1] === '0') {
        this.globalService.showSuccess({ masterName: 'Kickoff', name: kickOffName });
      }
    });
    this.resetForm();
    this.router.navigate(['project']);
  }

  resetForm() {
    this.kickOffForm.reset();
  }
}
