<div class="close-btn-container pos-abs" *ngIf="!removeCloseIcon">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h4 class="modal-title text-center">{{headingTitle}}</h4>
  </div>
  <div class="{{pagescroll?'modal-body':'modal-body-nonscroll'}}">
    <div class="table_cont">
      <table class="table table-striped table-bordered dynomic_table">
        <thead>
          <tr>
            <th *ngFor="let item of tableHeader | keyvalue : returnZero" class="{{item.value.className}}">
              {{item.value.name}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let list of productForm.ListOfProduct; let i=index">
            <ng-container *ngFor="let lineItem of tableHeader | keyvalue : returnZero">
              <td *ngIf="lineItem.key=='sn'" >
                {{i+1}}
                <input type="hidden" [(ngModel)]="list.SerialNo" value={{i+1}}>
              </td>
              <td *ngIf="lineItem.value.type=='autocomplete' && lineItem.value.value =='ProductName'" >
                <app-autocomplete [autoCompleteText]="list.autoCompleteText" (setAutoComplete)="setAutoComplete($event)"
                  [autoCompleteValue]="list.autoCompleteValue" [key]="i" (fiteredData)="fiteredData($event)"
                  [filteredOptions]="list.filteredOptions">
                </app-autocomplete>
              </td>
              <td *ngIf="lineItem.value.type=='autocomplete' && lineItem.value.value =='MachineName'" >
                <app-autocomplete [autoCompleteText]="list.autoCompleteText" (setAutoComplete)="setAutoMachineComplete($event)"
                  [autoCompleteValue]="list.autoCompleteValue" [key]="i" (fiteredData)="fiteredMachineData($event)"
                  [filteredOptions]="list.filteredOptions">
                </app-autocomplete>
              </td>
              <td *ngIf="lineItem.value.type=='autocomplete' && lineItem.value.value =='ServiceName'" >
                <app-autocomplete [autoCompleteText]="list.autoCompleteText" (setAutoComplete)="setAutoCompleteService($event)"
                  [autoCompleteValue]="list.autoCompleteValue" [key]="i" (fiteredData)="filterServiceListData($event)" 
                  [filteredOptions]="list.filteredOptions">
                </app-autocomplete>
              </td>
              <td *ngIf="lineItem.value.type=='number'" >
                <input class="form-control text-right pr-1" type="number" [(ngModel)]="list[lineItem.value.value]" (input)="addFieldValue(i)"
                  (keypress)="numberOnly($event)" />
              </td>
              <td *ngIf="lineItem.value.type=='label'" >
                <div class="string-text text-center">{{list[lineItem.value.value]}}</div>
                <input class="form-control" type="hidden" [(ngModel)]="list[lineItem.value.value]" (input)="addFieldValue(i)"
                  />
              </td>
              <td *ngIf="lineItem.value.type=='customLink'" >
                <a href="javascript:void(0)" (click)="editTrigger(list,true)">{{list[lineItem.value.value]}}</a>
              </td>
              
              <td *ngIf="lineItem.value.type=='link'" >
                <div class="text-center pencil">
                  <span class="edit_pen" (click)="editTrigger(list)">
                    <span class="glyphicon glyphicon-pencil"></span>
                  </span>
                </div>
              </td>
              <td *ngIf="lineItem.value.type=='label-number'" >
                <div class="string-text text-right pr-4">{{list[lineItem.value.value]}}</div>
                <input class="form-control" type="hidden" [(ngModel)]="list[lineItem.value.value]" (input)="addFieldValue(i)"
                  />
              </td>
              <td *ngIf="lineItem.value.type=='string'" >
                <input class="form-control" type="text" [(ngModel)]="list[lineItem.value.value]" (input)="addFieldValue(i)"
                  />
              </td>
              <td *ngIf="lineItem.value.type=='date'" >
                <input type="text" class="form-control" [(ngModel)]="list[lineItem.value.value]" value="{{ '' | date: 'dd-MM-yyyy' }}"
                    #bdp="bsDatepicker" bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY' }" [bsValue]="">
              </td>
              <td *ngIf="lineItem.value.type=='boolean'" >
                <input type="checkbox" [(ngModel)]="list[lineItem.value.value]" (input)="addFieldValue(i)" class="form-control checkbox">
              </td>
              <td *ngIf="lineItem.value.type=='select'" >
                <select class="form-control" [(ngModel)]="list[lineItem.value.value]">
                    <option *ngFor="let item of lineItem.value.options" value="{{item.key}}">{{item.value}}</option>
                </select>
              </td>
            </ng-container>
            <td *ngIf="addEditTable" class="number_short">
              <div class="btn-group" dropdown>
                <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
                <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ng-container *ngIf="cancelbutton">
    <div class="modal-footer">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')" >Cancel</button>
      <button type="button" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
    </div>
  </ng-container>
  <ng-container *ngIf="!cancelbutton && addEditTable">
    <div>
      <button type="button" class="btn btn-primary pull-right" id="btnSave" (click)="onSubmit()">Save</button>
    </div>
  </ng-container>

 