import { ApiService } from './../../../../services/ApiService/api.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailService } from '../../../../services/common/activity-page/email/email.service';
import { EmailTemplateModalComponent } from '../email-template-modal/email-template-modal.component';
import { TopnavbarService } from '../../../../services/top-nav-bar/topnavbar.service';
import { CommonService } from '../../../../services/common/common/common.service';
import * as Utils from '../../../../../../common/utils';
import { Page } from '../../../../../../models/page';
import { VariableService } from '../../../../services/VariableService/variable.service';
import { GlobalService } from '../../../../services/global-Service/global.service';
import { BulkEmailComponent } from '../../bulk-email/bulk-email.component';
import * as _ from 'lodash';

declare var $: any;

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit {
  selectedPage:any;
  columnNames=[];
  rows=[];
  emailTemplateData:any;
  filterObj: any;
  pageFilters:any;
  selectedPageFilter: any;
  page = new Page();
  selectedOption:any;
  public showFilter = false;
  getPageFilterCalled = false;
  filterLogic = '';
  isFilterLogic: boolean;
  filterArray: Array<any> = [];
  @Input() toEmailAddress: any;
  searchStr= '';
  searchFilterAdded = false;
  newListName = ''; AccessType = '';


  constructor(
    private modalService: NgbModal,
    private emailService:EmailService,
    private topnavbarService:TopnavbarService,
    private cService:CommonService,
    private variableService: VariableService,
    private globalService:GlobalService,
  ) {
    this.page.size = 15;
    this.selectedPage = topnavbarService.getSelectedPage();
    this.selectedPageFilter = {};
    this.selectedOption = {};
  }

  ngOnInit() {
    this.columnNames = [];
    this.getAllColumns();
    this.getPageFilters();
  }

  getAllColumns() {
    this.cService.getColumnData(this.selectedPage.pagename).subscribe(res => {
      this.columnNames = res.ColumnNames;
      this.filterObj = Utils.getColumnsObject(res.ColumnNames);
    });
  }

  getPageFilters(b = false) {
    this.cService.getPageFilter(this.selectedPage.pagename).subscribe(
      res => {
        this.pageFilters = [];

        this.pageFilters.push({
          FilterName: 'All',
          FilterID: null,
          FilterQuery: null,
          MasterType: this.selectedPage.pagename,
          AccessType: 1
        });
        res.forEach(obj => {
          this.pageFilters.push(obj);
        });
        if (!b && !this.selectedPageFilter.FilterID) {
          this.selectedPageFilter = this.pageFilters[0];
        } else {
          if (!b) {
            this.selectedPageFilter = this.pageFilters[
              this.pageFilters.length - 1
            ];
          }
        }
        this.getTableData();
      },
      err => {
        console.log('err===', err);
      }
    );
  }
  

  // getTableData(e){
  //   this.variableService.setSearchKeys({
  //     type: this.selectedPage.pagename
  //   });
  //   this.emailService.getEmailTemplates().subscribe((res)=>{
  //     console.log('res',res);
  //     this.rows = res;
  //   });
  // }

  open() {
    let modalRef = this.modalService.open(EmailTemplateModalComponent, { size: 'lg', backdrop: 'static', keyboard: false });
         
    modalRef.componentInstance.getData.subscribe((data) => {
      this.emailService.addEmailTemplate(data).subscribe((res)=>{
        this.getTableData();
        this.modalService.dismissAll();
        this.globalService.showSuccess({ masterName: 'Email Template', name: data.EmailTemplateName });

      })
    })
   
  }


  editTemplate(data){
    this.emailService.emailTemEditById(data.masterId).subscribe(emailData => {
      let modalRef = this.modalService.open(EmailTemplateModalComponent, { size: 'lg', backdrop: 'static', keyboard: false });
      modalRef.componentInstance.emialTemplateData = emailData;
      modalRef.componentInstance.getData.subscribe((data) => {
        let finalData = {...emailData,...data}
        this.emailService.updateEmailTemplate(finalData).subscribe((res)=>{
          this.globalService.showUpdate({ masterName: 'Email Template', name: finalData.EmailTemplateName });
          this.getTableData();
          this.modalService.dismissAll();
        })
      })
    });
   
  }

  onDelete(data) {
    // this.deleteData = data;
    // const modalRef = this.modalService.open(DeleteModalComponent, {
    //   size: 'sm',
    //   backdrop: 'static',
    //   keyboard: false,
    // });
    // modalRef.componentInstance.deleteData = this.deleteData;
    // modalRef.componentInstance.getData.subscribe(() => {
    //   this.getTableData();
    // });
  }
  getTableData(reqOptions?: any) {
    this.variableService.setSearchKeys({
      type: this.selectedPage.pagename
    });
    if (!reqOptions) {
      reqOptions = {};
      reqOptions.PageNumber = 1;
      reqOptions.PageSize = this.page.size;
      reqOptions.TotalRecords = null;
    }
    reqOptions.MasterType = this.selectedPage.pagename;
    if (this.searchStr && this.searchStr.length > 1) {
      reqOptions.PageNumber = 1;
      // this.searchFilterAdded = true;
      const columnNames = [];
      this.columnNames.map(column => {
        columnNames.push(column.Name);
      });

      if (this.filterArray.length === 1) {
        this.filterLogic += `1 AND ${this.filterArray.length + 1}`;
      } else if (this.filterArray.length > 1) {
        this.filterLogic += ` AND ${this.filterArray.length + 1}`;
      }
      this.filterArray.push({
        columnName: `concat(${columnNames.join(',')})`,
        sqlOperator: 'LIKE',
        value: `%${this.searchStr}%`
      });
    }

    reqOptions.Filter = {
      logic: _.cloneDeep(this.filterLogic),
      FilterItems: _.cloneDeep(this.filterArray)
    };

    if (this.searchStr && this.searchStr.length > 1) {
      this.filterArray.pop();
      this.setLogic();
    }

    this.cService.getTableData(reqOptions).subscribe(
      res => {
        this.rows = res.Data;
        this.page = {
          totalElements: res.TotalRecords,
          totalPages: Math.ceil(
            res.TotalRecords / reqOptions.PageSize
          ),
          size: this.page.size,
          pageNumber: reqOptions.PageNumber - 1
        };
      
        for (const row of this.rows) {
          row.height = Math.floor(Math.random() * 80) + 50;
        }

      },
      err => {
       
      }
    );
  }

  toggle() {
    this.showFilter = !this.showFilter;
    this.rows = [...this.rows];
  }
  /**Page filter (header level) */
  getPageFilterData(pageFilter) {
    this.showFilter = false;
    this.getPageFilterCalled = true;
    this.selectedPageFilter = pageFilter;
    const filters = JSON.parse(pageFilter.FilterQuery);

    this.filterLogic = filters && filters.logic ? filters.logic : '';
    this.filterArray =
      filters && filters.FilterItems ? filters.FilterItems : [];
    // this.showSpinner = true;
    this.getTableData();
  }


  sendEmail() {
    if (this.toEmailAddress === undefined) {
      this.globalService.showError();
    } else {
      const modalRef = this.modalService.open(BulkEmailComponent, {
        size: 'sm',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.toEmailAddress = this.toEmailAddress;
      modalRef.componentInstance.getData.subscribe(() => {
        this.getTableData();
      });
    }
  }

  // custom menus popup
  custommenu(content) {
    const columnFilCMP = this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static'
    });
  }

  makeColumnsVisible(data) {
    const cols = [];
    data.oldCols.forEach(c => {
      if (data.newCols[c.Name]) {
        c.Visible = data.val;
        cols.push(c);
      } else {
        cols.push(c);
      }
    });
    this.columnNames = cols;
  }

  setSearchStr(val='') {
    this.searchStr = val;
    if (this.searchStr.length > 1) {
      this.getTableData();
    } else if (!this.searchStr) {
      if (this.searchFilterAdded) {
        this.filterArray.pop();
        this.setLogic();
        this.searchFilterAdded = false;
      }
      this.getTableData();
    }
  }
  setLogic() {
    if (this.filterArray.length > 1) {
      let l: any;
      // this.filterArray.splice(0, 1);
      this.filterArray.forEach((filter, i) => {
        if (i > 0) {
          l += 'AND ' + (i + 1);
        } else {
          l = '1 ';
        }
      });
      this.applyFilterLogic(l);
    } else {
      this.applyFilterLogic('');
    }
  }
  applyFilter(arr: any[]) {
    this.filterArray = arr;
    if (this.selectedPageFilter.FilterID) {
      this.createNewPageFilter(
        this.selectedPageFilter.FilterName,
        0,
        { FilterItems: arr, logic: this.filterLogic },
        this.selectedPageFilter.FilterID,
        1
      );
    } else {
      // this.showSpinner = true;
      this.getTableData();
    }
  }
  createNewPageFilterModal(content) {
    this.modalService
      .open(content, { size: 'sm', backdrop: 'static' })
      .result.then(result => {
        // console.log(`Closed with: ${result}`);
      });
  }

  createNewPageFilter(
    name,
    accessType,
    filterQuery = {},
    filterID = null,
    mode = 0
  ) {
    this.cService
      .createPageFilter({
        FilterID: filterID,
        FilterName: name,
        AccessType: parseInt(accessType, 10),
        Mode: mode,
        FilterQuery: JSON.stringify(filterQuery),
        MasterType: this.selectedPage.pagename
      })
      .subscribe(
        res => {
          this.selectedPageFilter.FilterQuery = JSON.stringify(filterQuery);
          if (!filterID) {
            if (name !== 'All') {
              this.pageFilters.push(res);
              this.selectedPageFilter = res;
              $('#btnCancel').trigger('click');
              this.newListName = '';
              this.AccessType = '';
            }
          }

          this.getPageFilterData(this.selectedPageFilter);
        },
        err => {
          console.log('err===', err);
        }
      );
  }

  applyFilterLogic(logic: string) {
    this.filterLogic = logic;
  }
  onSelectCheckBox(selectedData) {
    const newObj = [];
    selectedData.forEach(element => {
      if(element.Email)
        newObj.push(element.Email);
    });
    this.toEmailAddress = newObj.join(',');
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }
}
