import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class ProductService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) {
  }

  getPOSProducts() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/product/getall?id=${q.paramId}&MasterType=${'CRMProduct'}&`);
  }
  // Create Method for Product
  addProduct(form) {
    return this.apiService.post('api/product/create', form);
  }
  // get by id method for product
  productById(productId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/product/getbyid?Id=${productId}&MasterType=${q.type}&`);
  }
  // Update Method for product
  updateProduct(form) {
    return this.apiService.put('api/product/edit', form);
  }

  getListOfProduct() {
    return this.apiService.get(`api/listOfProduct/GetListOfProductNamesList?`);
  }

  getPosProductList(productGroupId) {
    return this.apiService.get(`api/listOfProduct/GetPosProductList?Id=${productGroupId}&Text=${''}&`);
  }

  getAllPosProductList(text) {
    return this.apiService.get(`api/listOfProduct/GetPosProductList?Id=${null}&Text=${text}&`);
  }
}
