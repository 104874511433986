
import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../../../services/template/template.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { VariableService } from '../../../services/VariableService/variable.service';
import { GlobalService } from '../../../services/global-Service/global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-terms-template',
  templateUrl: './terms-template.component.html',
  styleUrls: ['./terms-template.component.css']
})
export class TermsTemplateComponent implements OnInit {
  termForm: TermForm;
  public TemplateList: AutocompleteModel[];
  listOfTermObj: ListOfTermObj;
  termListData: any;
  columnName:Array<any> = ['S.No.','Term Name'];
  tableData:Array<any> = [];

  constructor(
    private termService: TemplateService,
    private variableService: VariableService,
    private globalService: GlobalService,
    private modalService:NgbModal,
  ) {
  }

  ngOnInit() {
    this.resetTermForm();
    this.resetListOfTermObj();
    this.termForm.ListOfTerm.push(this.listOfTermObj);
    this.getAllTemplateList();
    this.getAllTermTemplate();
  }
  openTermsForm(termsModule){
    this.modalService.open(termsModule, { size: 'lg', backdrop: 'static', keyboard: false });
  }
  getAllTemplateList() {
    this.termService.getTemplateList().subscribe(data => {
      this.TemplateList = data;
    });
  }

  addFieldValue(i) {
    this.resetListOfTermObj();
    const obj = this.termForm.ListOfTerm[i + 1];
    if (!obj) {
      this.termForm.ListOfTerm.push(this.listOfTermObj);
    }
    this.resetListOfTermObj();
  }

  deleteFieldValue(i: number) {
    if (this.termForm.ListOfTerm.length === 1) {
      return false;
    } else {
      this.termForm.ListOfTerm.splice(i, 1);
      return true;
    }
  }

  getTemplateValue(value) {
    this.termService.getTermTemplate().subscribe(data => {
      this.termListData = data;
      this.getTermList();
      if (this.termListData.length === 0) {
        this.termService.getERPTerm(value).subscribe(resdata => {
          this.termListData = resdata[0].TermDetails;
          this.getTermList();
        });
      }
    });
  }

  getAllTermTemplate() {
    this.termService.getTermTemplate().subscribe(data => {
      this.termListData = data;
      this.getTermList();
    });
  }

  getTermList() {
    this.resetForm();
    this.tableData = [];
    this.termListData.map((obj, i) => {
      let termsTableData = {
        'S.No.':obj.SerialNo,
        'Term Name':obj.TermsName
      };
      this.termForm['ListOfTerm'].push({
        SerialNo: obj.SerialNo,
        TermsName: obj.TermsName,
      });
      this.tableData.push(termsTableData);
    });
    this.termForm['ListOfTerm'].push(this.listOfTermObj);
    this.resetListOfTermObj();
  }

  saveTerms() {
    const q: any = this.variableService.getSearchKeys();
    this.termForm.QuoteID = q.paramId;
    this.termForm.ListOfTerm = this.termForm.ListOfTerm.filter(
      obj => {
        return obj.TermsName;
      }
    );

    const termDataList = [];
    this.termForm.ListOfTerm.forEach(i => {
      termDataList.push({
        SerialNo: termDataList.length + 1,
        TermsName: i.TermsName,
      });
    });

    this.termService.addTermTemplate({
      QuoteID: q.paramId,
      TermDetails: termDataList,
    }).subscribe(data => {
      this.globalService.showDefaultSuccess({ masterName: 'Term' });
      this.modalService.dismissAll();
      this.getAllTermTemplate();
    });
  }

  reset() {
    this.resetTermForm();
    this.resetListOfTermObj();
    this.termForm.ListOfTerm.push(this.listOfTermObj);
  }

  resetForm() {
    this.resetTermForm();
    this.resetListOfTermObj();
  }

  resetListOfTermObj() {
    this.listOfTermObj = {
      TermsName: '',
      SerialNo: '',
    };
  }
  resetTermForm() {
    this.termForm = {
      QuoteID: '',
      ListOfTerm: [],
    };
  }
}


declare class ListOfTermObj {
  TermsName: string;
  SerialNo: string;
}

declare class TermForm {
  QuoteID: string;
  ListOfTerm: Array<any>;
}
