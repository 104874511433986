import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-milestone-config',
  templateUrl: './milestone-config.component.html',
  styleUrls: ['./milestone-config.component.css']
})
export class MilestoneConfigComponent implements OnInit {
  milestoneConfigForm: MilestoneConfigForm;
  listOfMilestoneObj: ListOfMilestoneObj;
  milestoneConfig: Array<any> = [];
  showSpinner: Boolean = true;
  constructor(
    private configService: ConfigurationService,
    private router: Router,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.resetMilestoneForm();
    this.resetListOfResourceObj();
    this.milestoneConfigForm.MilestoneStage.push(this.listOfMilestoneObj);
    this.getAllMilestoneConfig();
  }

  addFieldValue(i) {
    this.resetListOfResourceObj();
    const obj = this.milestoneConfigForm.MilestoneStage[i + 1];
    if (!obj) {
      this.milestoneConfigForm.MilestoneStage.push(this.listOfMilestoneObj);
    }
    this.resetListOfResourceObj();
  }
  deleteFieldValue(i: number) {
    if (this.milestoneConfigForm.MilestoneStage.length === 1) {
      return false;
    } else {
      this.milestoneConfigForm.MilestoneStage.splice(i, 1);
      return true;
    }
  }

  numberOnly(event): boolean {
    const keycode = (event.which) ? event.which : event.keyCode;
    if (!(keycode === 8 || keycode === 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      const parts = event.srcElement.value.split('.');
      if (parts.length > 1 && keycode === 46) {
        return false;
      }
      return true;
    }
  }

  getAllMilestoneConfig() {
    this.reset();
    this.configService.getMilestoneConfig().subscribe(data => {
      this.milestoneConfig = data;
      this.showSpinner = false;
      this.milestoneConfig.map((obj, i) => {
        this.milestoneConfigForm['MilestoneStage'].push(obj);
      });
      this.milestoneConfigForm['MilestoneStage'].push(this.listOfMilestoneObj);
      this.resetListOfResourceObj();
    });
  }

  onSubmit() {
    this.milestoneConfigForm.MilestoneStage = this.milestoneConfigForm.MilestoneStage.filter(
      obj => {
        return obj.SerialNo || obj.ActivityName || obj.PercentageCompleted;
      }
    );
    this.configService.addMilestoneConfig(this.milestoneConfigForm).subscribe(data => {
      this.globalService.showDefaultSuccess({ masterName: 'Milestone configuration' });
    });
    this.router.navigate(['home']);
  }

  resetListOfResourceObj() {
    this.listOfMilestoneObj = {
      SerialNo: '',
      MilestoneName: '',
      PercentageCompleted: ''
    };
  }
  resetMilestoneForm() {
    this.milestoneConfigForm = {
      MilestoneStage: []
    };
  }

  reset() {
    this.resetMilestoneForm();
    this.resetListOfResourceObj();
  }

  resetForm() {
    this.resetMilestoneForm();
    this.resetListOfResourceObj();
    this.milestoneConfigForm.MilestoneStage.push(this.listOfMilestoneObj);
  }
}



declare class ListOfMilestoneObj {
  SerialNo: '';
  MilestoneName: '';
  PercentageCompleted: '';
}

declare class MilestoneConfigForm {
  MilestoneStage: Array<any>;
}
