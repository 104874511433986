import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../../ApiService/api.service';
import { VariableService } from '../../VariableService/variable.service';

@Injectable()
export class CommonService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  getTableData(reqOptions: any) {
    return this.apiService.post(`api/mastertable/data`, reqOptions);
  }

  getColumnData(type: string) {
    return this.apiService.post(`api/mastertable/columns`, {
      MasterType: type,
      PageName: type
    });
  }

  getPageFilter(type: string) {
    return this.apiService.get(
      `api/pagefilter/getall?MasterType=${type}&Id=null&`
    );
  }

  createPageFilter(form) {
    return this.apiService.post(`api/pagefilter/create`, form);
  }

  getUserReport(q) {
    // const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/userreport/getall?Id=${q.paramId}&MasterType=${q.type}&`
    );
  }

  createUserReport(form) {
    return this.apiService.post('api/userreport/create', form);
  }

  getReportObjectColumn(id: string) {
    // const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/reportobjectcolumns/getall?Id=${id}&`);
  }

  getReportLookup() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/reportobjectslookup/getall?Id=${q.paramId}&MasterType=${q.type}&`
    );
  }

  getAllAppProductData() {
    return this.apiService.get(
      `api/appMasterData/getall?MasterType=${'Product'}&`
    );
  }

  getAllProductQuery(productId) {
    return this.apiService.get(`api/productQuery/getall?Id=${productId}&`);
  }
}
