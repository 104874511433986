<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Project Task</h4>
</div>
<form [formGroup]="projectTaskForm" novalidate (ngSubmit)="onSubmit(projectTaskForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="">

          <!-- <p class="headingclass">Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">

              <div class="form-group ">
                <label for="text">Project Name</label>
                <app-autocomplete [autoCompleteText]="projectText" (setAutoComplete)="setAutoCompleteProject($event)"
                  [autoCompleteValue]="projectValue" (fiteredData)="getProjectList($event)"
                  [filteredOptions]="projectfilteredOptions">
                </app-autocomplete>
              </div>

              <div class="form-group ">
                <label for="text">Milestone</label>
                <app-autocomplete [autoCompleteText]="milestoneText"
                  (setAutoComplete)="setAutoCompleteMilestone($event)" [autoCompleteValue]="milestoneValue"
                  (fiteredData)="getMilestoneList($event)" [filteredOptions]="milestonefilteredOptions">
                </app-autocomplete>
              </div>


              <div class="form-group ">
                <label for="text">Project Task Type</label>
                <select class="form-control" formControlName="ProjectTaskTypeId"
                  (change)="onSelectTemplate($event.target.value)">
                  <option value="0">--None--</option>
                  <option *ngFor="let task of TaskTemplate" value={{task.Value}}> {{task.Text}}</option>
                </select>
              </div>


              <div class="form-group ">
                <label> <span class="text-danger">*</span>Project Task Name</label>
                <div class="eventCSS">
                  <select class="form-control dropdownCss" (change)="onSelectedList($event.target.value)">
                    <option value="0">--None--</option>
                    <option *ngFor="let task of TaskTemplateList" value={{task.ProjectTaskName}}>
                      {{task.ProjectTaskName}}</option>
                  </select>
                  <input class="form-control inputName" onfocus="this.select()" type="text"
                    formControlName="ProjectTaskName" [(ngModel)]="selectedProjectName">
                </div>
              </div>
            </div>
          </div>



          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Assigned To</label>
              <app-autocomplete [autoCompleteText]="assignedToText" (setAutoComplete)="setAutoCompleteEmployee($event)"
                [autoCompleteValue]="assignedToValue" (fiteredData)="getEmployeeList($event)"
                [filteredOptions]="assignedTofilteredOptions">
              </app-autocomplete>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">StartDate</label>
              <input type="text" class="form-control" formControlName="StartDate" #dp="bsDatepicker"
                value="{{ projectTaskForm.get('StartDate').value | date: 'dd-MM-yyyy' }}" bsDatepicker
                [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
              <p class="inputdate" (click)="dp.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Due Date</label>
              <input type="text" class="form-control" formControlName="DueDate" #bsdp="bsDatepicker"
                value="{{ projectTaskForm.get('DueDate').value | date: 'dd-MM-yyyy' }}" bsDatepicker
                [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
              <p class="inputdate" (click)="bsdp.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Priority</label>
              <select class="form-control" formControlName="TaskPriority">
                <option value=0>--None--</option>
                <option *ngFor="let priority of PriorityList" value={{priority.Value}}>{{priority.Text}}</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Status</label>
              <select class="form-control" formControlName="Stage">
                <option value=0>--None--</option>
                <option *ngFor="let status of CaseStatusModel" value={{status.Value}}>{{status.Text}}</option>
              </select>
            </div>
          </div>

          <!-- <p class="headingclass">Blocked</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Estimated Hours</label>
              <input type="textbox" class="form-control" formControlName="EstimatedHours"
                (keypress)="numberOnly($event)">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Estimated Expense</label>
              <input type="textbox" class="form-control" formControlName="EstimatedExpense"
                (keypress)="numberOnly($event)">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Percentage Of Milestone</label>
              <input type="textbox" class="form-control" formControlName="MilestonePercent"
                (keypress)="numberOnly($event)">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Weightage Of Milestone</label>
              <input type="textbox" class="form-control" formControlName="MilestoneWeightage"
                (keypress)="numberOnly($event)">
            </div>
          </div>

          <!-- <p class="headingclass">Description</p> -->
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" formControlName="Description" rows="3"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel"
      (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!projectTaskForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!projectTaskForm">Save</button>
  </div>
</form>



<ng-template #sendContent let-c="close" let-d="dismiss" style="pos-rel">
  <div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="modalparagraph">Do you want to send email ?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="sendEmail()">Send</button>
  </div>
</ng-template>