<div class="tab_title">
  <h3 class="page_title pull-left">
    <span class="menu_icon CRMQuotes fa fa-tags">
    </span>
    Services Items ({{quoteServiceData?.length}})
  </h3>
  <div class="buttonHeader pull-right">
    <div class="buttonNew" (click)="open(openContent)">Add/Edit</div>
  </div>
</div>


<div class="table-container">
  <div class="row">
    <div class="col-md-12 col-xs-12 col-lg-12 col-sm-12">
      <ngx-datatable 
        class="material"
        columnMode="force"
        [headerHeight]="50"
        [footerHeight]="0"
        [rowHeight]="50"
        [scrollbarH]="true"
        [limit]="10"
        [rows]="quoteServiceData">
        <ngx-datatable-column prop="ServiceName" name="Service Name"></ngx-datatable-column>
        <ngx-datatable-column prop="Quantity" name="Quantity"></ngx-datatable-column>
        <ngx-datatable-column prop="Rate" name="Sales Price"></ngx-datatable-column>
        <ngx-datatable-column prop="Discount" name="Discount"></ngx-datatable-column>
        <ngx-datatable-column prop="Amount" name="SubTotal"></ngx-datatable-column>
        <ngx-datatable-column prop="TaxRate" name="Tax Rate"></ngx-datatable-column>
        <ngx-datatable-column prop="TaxAmount" name="Tax Amount"></ngx-datatable-column>
        <ngx-datatable-column prop="TotalValue" name="Total Value"></ngx-datatable-column>
        <ngx-datatable-column prop="Scope" name="Scope"></ngx-datatable-column>
        <ngx-datatable-column prop="Deliverables" name="Deliverables"></ngx-datatable-column>
        <ngx-datatable-column prop="Exclusions" name="Exclusions"></ngx-datatable-column>
        <ngx-datatable-column prop="DeliverySchedule" name="Delivery Schedule"></ngx-datatable-column>
        <ngx-datatable-column prop="InputFileRequired" name="Input File Required"></ngx-datatable-column>

      </ngx-datatable>
    </div>
  </div>
</div>




<ng-template #openContent let-c="close" let-d="dismiss" style="pos-rel">
  <div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h4 class="modal-title text-center"> Services </h4>
  </div>

  <div class="modal-body">
    <div class="table_cont">
      <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <table class="table table-bordered dynomic_table">
          <thead>
            <tr>
              <th class="number_small">S.No.</th>
              <th class="name_long">Service Name</th>
              <th class="number_largeshort">Quantity</th>
              <th class="amt_medium">Rate</th>
              <th id="Disc">Discount</th>
              <th class="amt_medium">Amount</th>
              <th class="number_largeshort">Tax Rate</th>
              <th class="amt_medium">Tax Amount</th>
              <th class="amt_medium">Total Value</th>

              <th class="name_long">Scopes Of Work</th>
              <th class="name_long">Deliverables</th>
              <th class="name_long">Exclusions</th>
              <th class="name_long">Delivery Schedule</th>
              <th class="name_long">Input Files Required</th>

              <th class="number_short">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let list of quoteServiceForm.ListOfQuoteServiceTable; let i=index">
              <td>
                {{i+1}}
                <input type="hidden" class="form-control" value={{i+1}} [(ngModel)]="list.SerialNo">
              </td>
              <td>
                <select class="form-control" [(ngModel)]="list.ServiceId" (change)="addFieldValue(i)">
                  <option value=0>--None--</option>
                  <option *ngFor="let service of ServiceList" value={{service.Value}}>{{service.Text}} </option>
                </select>
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="list.Quantity" (input)="addFieldValue(i);calculate(i)"
                  (keypress)="numberOnly($event)" />
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="list.Rate" (input)="addFieldValue(i);calculate(i)"
                  (keypress)="numberOnly($event)" />
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="list.Discount" (input)="addFieldValue(i);calculate(i)"
                  (keypress)="numberOnly($event)" />
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="list.Amount" (input)="addFieldValue(i);calculate(i);"
                  (keypress)="numberOnly($event)" readonly />
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="list.TaxRate" (input)="addFieldValue(i);calculate(i)"
                  (keypress)="numberOnly($event)" />
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="list.TaxAmount" (input)="addFieldValue(i);calculate(i);"
                  (keypress)="numberOnly($event)" readonly />
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="list.TotalValue" (input)="addFieldValue(i);calculate(i);"
                  (keypress)="numberOnly($event)" readonly />
              </td>

              <td>
                <input class="form-control" type="text" [(ngModel)]="list.Scope" (input)="addFieldValue(i)" />
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="list.Deliverables" (input)="addFieldValue(i)" />
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="list.Exclusions" (input)="addFieldValue(i)" />
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="list.DeliverySchedule" (input)="addFieldValue(i)" />
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="list.InputFileRequired" (input)="addFieldValue(i)">
              </td>
              <td>
                <div class="btn-group" dropdown>
                  <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
                  <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
  </div>
</ng-template>