<div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-header">
    <h4 class="modal-title text-center">{{EditNew}} Project</h4>
</div>
<form [formGroup]="projectForm" novalidate (ngSubmit)="onSubmit(projectForm)">
    <div class="modal-body">
        <div class="extra-pad">
            <div class="">
                <div class="">
                    <!-- <p class="headingclass">Project Handover From Marketing To Technical</p> -->
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Client</label>
                            <app-autocomplete [autoCompleteText]="clientText"
                                (setAutoComplete)="setAutoCompleteClient($event)" [autoCompleteValue]="clientValue"
                                (fiteredData)="getClientList($event)" [filteredOptions]="clientfilteredOptions">
                            </app-autocomplete>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Standard To Follow</label>
                            <select class="form-control" formControlName="StandardToFollow">
                                <option value=None>--None--</option>
                                <option value="Australia">Australia</option>
                                <option value="USA">USA</option>
                                <option value="Canadian">Canadian</option>
                                <option value="European">European</option>
                                <option value="India">India</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">
                                <span class="text-danger">*</span>Project Name</label>
                            <input type="textbox" class="form-control" formControlName="ProjectName">
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Client Project No.</label>
                            <input type="textbox" class="form-control" formControlName="ClientProjectNo">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Project No.</label>
                            <input type="textbox" class="form-control" formControlName="ProjectNo">
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Purchase Order No.</label>
                            <input type="textbox" class="form-control" formControlName="PurchaseOrderNo">
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Work Order No.</label>
                            <input type="textbox" class="form-control" formControlName="WorkOrderNo">
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Client contact names for RFI's</label>
                            <app-autocomplete [autoCompleteText]="contactRFIText"
                                (setAutoComplete)="setAutoCompleteContactRFI($event)"
                                [autoCompleteValue]="contactRFIValue" (fiteredData)="getContactRFIList($event)"
                                [filteredOptions]="contactRFIfilteredOptions">
                            </app-autocomplete>
                        </div>

                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Purchase Order Date</label>
                            <input type="text" class="form-control" formControlName="PurchaseOrderDate"
                                #d="bsDatepicker"
                                value="{{ projectForm.get('PurchaseOrderDate').value | date: 'dd-MM-yyyy' }}"
                                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
                            <p class="inputdate" (click)="d.toggle()">
                                <i class="fa fa-calendar-alt"></i>
                            </p>
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-left">
                            <label for="text">Client Contact Name For Delivery</label>
                            <app-autocomplete [autoCompleteText]="contactDeliveryText"
                                (setAutoComplete)="setAutoCompleteContactDelivery($event)"
                                [autoCompleteValue]="contactDeliveryValue"
                                (fiteredData)="getContactDeliveryList($event)"
                                [filteredOptions]="contactDeliveryfilteredOptions">
                            </app-autocomplete>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Start Date</label>
                            <input type="text" class="form-control" formControlName="StartDate" #bsdp="bsDatepicker"
                                value="{{ projectForm.get('StartDate').value | date: 'dd-MM-yyyy' }}" bsDatepicker
                                [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
                            <p class="inputdate" (click)="bsdp.toggle()">
                                <i class="fa fa-calendar-alt"></i>
                            </p>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">End Date For Approval</label>
                            <input type="text" class="form-control" formControlName="EndDate" #bEdp="bsDatepicker"
                                value="{{ projectForm.get('EndDate').value | date: 'dd-MM-yyyy' }}" bsDatepicker
                                [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
                            <p class="inputdate" (click)="bEdp.toggle()">
                                <i class="fa fa-calendar-alt"></i>
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Deadline</label>
                            <input type="text" class="form-control" formControlName="DeadLine" #dp="bsDatepicker"
                                value="{{ projectForm.get('DeadLine').value | date: 'dd-MM-yyyy' }}" bsDatepicker
                                [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
                            <p class="inputdate" (click)="dp.toggle()">
                                <i class="fa fa-calendar-alt"></i>
                            </p>
                        </div>

                        <!--     <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-left">
                            <label for="text">Lead Source</label>
                            <select class="form-control" formControlName="LeadSource">
                                <option value=0>--None--</option>
                                <option *ngFor="let leadSource of LeadSourceModel" value={{leadSource.Value}}>
                                    {{leadSource.Text}}
                                </option>
                            </select>
                        </div> -->
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Currency</label>
                            <app-autocomplete [autoCompleteText]="currencyText"
                                (setAutoComplete)="setAutoCompleteCurrency($event)" [autoCompleteValue]="currencyValue"
                                (fiteredData)="getCurrencyList($event)" [filteredOptions]="currencyfilteredOptions">
                            </app-autocomplete>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="exchangeRate===true">
                            <label for="text">Exchange Rate</label>
                            <input type="text" class="form-control" formControlName="ExchangeRate">
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Owner Name</label>
                            <app-autocomplete [autoCompleteText]="ownerText"
                                (setAutoComplete)="setAutoCompleteOwner($event)" [autoCompleteValue]="ownerValue"
                                (fiteredData)="getOwnerList($event)" [filteredOptions]="ownerfilteredOptions">
                            </app-autocomplete>
                        </div>

                        <!-- <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Project Value</label>
                            <input type="textbox" class="form-control" formControlName="ProjectValue" (keypress)="numberOnly($event)">
                        </div> -->
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Business Manager</label>
                            <app-autocomplete [autoCompleteText]="businessManagerText"
                                (setAutoComplete)="setBusinessManager($event)"
                                [autoCompleteValue]="businessManagerValue"
                                (fiteredData)="getBusinessManagerList($event)"
                                [filteredOptions]="businessManagerfilteredOptions">
                            </app-autocomplete>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Technical Head</label>
                            <app-autocomplete [autoCompleteText]="technicalHeadText"
                                (setAutoComplete)="setTechnicalHead($event)" [autoCompleteValue]="technicalHeadValue"
                                (fiteredData)="getTechnicalHeadList($event)"
                                [filteredOptions]="technicalHeadfilteredOptions">
                            </app-autocomplete>
                        </div>
                    </div>

                    <div class="row">

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Status</label>
                            <select class="form-control" formControlName="Status">
                                <option value=0>--None--</option>
                                <option value="1">Active</option>
                                <option value="2">Completed</option>
                                <option value="3">Inactive</option>
                                <option value="4">Onhold</option>
                                <option value="5">Planning</option>
                            </select>
                        </div>
                    </div>

                    <!-- <p class="headingclass">Budget</p> -->
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Total Hours Budget</label>
                            <input type="textbox" class="form-control" formControlName="TotalHoursBudget"
                                (keypress)="numberOnly($event)">
                        </div>

                        <!-- <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Total Expense Budget</label>
                            <input type="textbox" class="form-control" formControlName="TotalExpenseBudget"
                                (keypress)="numberOnly($event)">
                        </div> -->
                    </div>

                    <p class="headingclass">Work Order</p>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Branch</label>
                            <select formControlName="TerritoryID" class="form-control">
                                <option value=0>--None--</option>
                                <option *ngFor="let territory of TerritoryList" value={{territory.Value}}>
                                    {{territory.Text}}</option>
                            </select>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">PM/GM Responsible</label>
                            <app-autocomplete [autoCompleteText]="pmResponsibleText"
                                (setAutoComplete)="setPmResponsible($event)" [autoCompleteValue]="pmResponsibleValue"
                                (fiteredData)="getPmResponsibleList($event)"
                                [filteredOptions]="pmResponsiblefilteredOptions">
                            </app-autocomplete>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Team Lead</label>
                            <app-autocomplete [autoCompleteText]="teamLeadText" (setAutoComplete)="setTeamLead($event)"
                                [autoCompleteValue]="teamLeadValue" (fiteredData)="getTeamLeadList($event)"
                                [filteredOptions]="teamLeadfilteredOptions">
                            </app-autocomplete>
                        </div>
                    </div>

                    <!-- <p class="headingclass">Client Requirement</p> -->

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Clarification LOG every week</label>
                            <select class="form-control" formControlName="Clarification">
                                <option value="0">Yes</option>
                                <option value="1">No</option>
                            </select>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Change Order log every week</label>
                            <select class="form-control" formControlName="ChangeOrder">
                                <option value="0">Yes</option>
                                <option value="1">No</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Project status list/HOLD list every week</label>
                            <select class="form-control" formControlName="StatusHoldList">
                                <option value="0">Yes</option>
                                <option value="1">No</option>
                            </select>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Model Upload every week</label>
                            <select class="form-control" formControlName="ModelUpload">
                                <option value="0">Yes</option>
                                <option value="1">No</option>
                            </select>
                        </div>
                    </div>

                    <!-- <p class="headingclass">Marketing Requirement</p> -->
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Client communication responsibility</label>
                            <app-autocomplete [autoCompleteText]="communicationText"
                                (setAutoComplete)="setCommunication($event)" [autoCompleteValue]="communicationValue"
                                (fiteredData)="getCommunicationList($event)"
                                [filteredOptions]="communicationfilteredOptions">
                            </app-autocomplete>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Quality responsibility</label>
                            <app-autocomplete [autoCompleteText]="qualityText" (setAutoComplete)="setQuality($event)"
                                [autoCompleteValue]="qualityValue" (fiteredData)="getQualityList($event)"
                                [filteredOptions]="qualityfilteredOptions">
                            </app-autocomplete>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">MIS/Daily status/Weekly Status responsibility</label>
                            <app-autocomplete [autoCompleteText]="weeklyText"
                                (setAutoComplete)="setWeeklyStatus($event)" [autoCompleteValue]="weeklyValue"
                                (fiteredData)="getWeeklyStatusList($event)" [filteredOptions]="weeklyfilteredOptions">
                            </app-autocomplete>
                        </div>
                    </div>

                    <!-- <p class="headingclass">Description</p> -->
                    <div class="row">
                        <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label for="text">Description</label>
                            <textarea class="form-control" formControlName="Description" rows="3"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" id="btnCancel"
            (click)="activeModal.close('Close click')">Cancel</button>
        <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!projectForm">Save & New</button>
        <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!projectForm">Save</button>
    </div>
</form>