import { NavItemService } from '../../../services/navItem/nav-item.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.css']
})
export class NavigationItemComponent implements OnInit {
  showSpinner: Boolean = true;
  public menuItems: any[];
  public menuName: {};
  constructor(
    private topnavbarService: TopnavbarService,
    public activeModal: NgbActiveModal,
    private navItemService: NavItemService,
  ) { }

  ngOnInit() {
    this.getMenuItems();
  }


  getMenuItems() {
    this.topnavbarService.getMenuItems().subscribe(data => {
      this.menuItems = data.Access;
    });
  }

  onSubmit() {
    const ListOfNavItem = [];
    this.menuItems.forEach((obj) => {
      ListOfNavItem.push({ UserMenuName: obj.UserMenuName, MenuID: obj.MenuID });
    });
    this.navItemService.addNavItem({ ListOfNavItem }).subscribe(data => { });
     this.activeModal.dismiss();
  }
}



