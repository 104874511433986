import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import "rxjs/add/operator/toPromise";
// import { stringify } from "querystring";
import {stringify} from 'query-string';
import { serviceURLs } from "../product/crm/constants/CRMService/serviceURLs";
import { Observable } from "rxjs";

@Injectable()
export class AuthService {
  private BASE_URL: string = serviceURLs.CRMServiceBaseURL + "api/auth";
  private auth_url: string = serviceURLs.CRMServiceBaseURL;
  postLoginUrl = serviceURLs.CRMServiceBaseURL + "api/auth/login";
  private headers = new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded"
  });
  constructor(private http: HttpClient) {}

  authtoken(user): Promise<any> {
    let url: string = `${this.auth_url}/token`;
    // console.log("login URL :" + url);
    // console.log("user :" + stringify(user));

    return this.http
      .post(url, stringify(user), { headers: this.headers })
      .toPromise();
  }

  authLogin(form): Observable<any> {
    return this.http.post(this.postLoginUrl, form).pipe();
    // .catch((error: any) => Observable.throw(error.json || 'Server Error for Post Method'));
  }

  login(user): Promise<any> {
    let url: string = `${this.BASE_URL}/login`;
    // console.log("user :" + stringify(user));
    return this.http
      .post(url, stringify(user), { headers: this.headers })
      .toPromise();
  }

  register(user): Promise<any> {
    let url: string = `${this.BASE_URL}/register`;
    // console.log("Register URL :" + url);
    return this.http.post(url, user, { headers: this.headers }).toPromise();
  }

  public isAuthenticated(): boolean {
    const user = sessionStorage.getItem("currentUser");

    // Check whether the token is expired and return
    // true or false
    if (user) {
      return true;
    } else {
      return false;
    }
  }
}
