import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-contact-related-detail',
  templateUrl: './contact-related-detail.component.html',
  styleUrls: ['./contact-related-detail.component.css']
})
export class ContactRelatedDetailComponent implements OnInit {
  @Input() searchKeys;

  constructor() {}

  ngOnInit() {}
}
