<div class="body_inner">
    <div class="">
        <div class="row">
            <div class="topheader col-md-12">
                <div class="topheadertitle">
                    <span class="menu_icon location fa fa-location-arrow"></span>
                    <h3 class="report_page_title">
                        <p class="page_title_cont">Location</p>
                        <p class="page_sub_title">{{LocationDetials?.LocationName}}</p>
                    </h3>
                </div>
                <div>
                    <div class="topdownheader ">
                        <div class="block">
                            <label class="labelName">Code</label>
                            <p>{{LocationDetials?.Code}}</p>
                        </div>
                        <div class="block">
                            <label class="labelName">Contact Person</label>
                            <p>{{LocationDetials?.ContactPerson}}</p>
                        </div>
                        <div class="block">
                            <label class="labelName">EmailId</label>
                            <p>{{LocationDetials?.EmailId}}</p>
                        </div>
                        <div class="block">
                            <label class="labelName">Location Name</label>
                            <p>{{LocationDetials?.LocationName}}</p>
                        </div>
                        <div class="block">
                            <label class="labelName">Phone No</label>
                            <p>{{LocationDetials?.PhoneNo}}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row ">
            <div class="col-md-8 lowpadding-right">
                <nav class="navbar navbar-default custom_navbar mt-3">
                    <div class="nav nav-bar-inner">
                        <ul>
                            <li class="realted">
                                <a class="" (click)="selectTab()"
                                    [ngClass]="{'active': selectedTab==='Machines'}">Machines</a>
                            </li>
                        </ul>
                    </div>
                    <ul class="cl custom_content_tab">
                        <li class="nav-item">
                            <app-machine-location *ngIf="selectedTab==='Machines'"
                                [searchKeys]="searchKeys"></app-machine-location>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-4 bg_white_margin_top no_boxshadow">
                <nav class="navbar navbar-default custom_navbar">
                    <div class="nav nav-bar-inner">
                        <ul>
                            <li class="realted">
                                <a class="" [ngClass]="{'active': activityOn===true}">Activity</a>
                            </li>
                        </ul>
                    </div>
                    <ul class="cl custom_content_tab">
                        <li class="nav-item">
                            <app-activity *ngIf="activityOn===true"></app-activity>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
</div>