<div class="bg_white_margin_top">
  <form [formGroup]="otherEmailForm" novalidate (ngSubmit)="onSubmit(otherEmailForm)">
    
    <p class="headingclass">Mail Configuration</p>

    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Name</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" placeholder="Ex:John" formControlName="Name">
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Email Address</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" formControlName="EmailID">
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="text">IMAP Server</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" formControlName="IncomingMailServer">
      </div>
    </div>
    <!-- <div class="form-group">
      <label class="control-label col-sm-2" for="text">User Name</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" formControlName="Username">
      </div>
    </div> -->
    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Password</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" formControlName="Password">
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Port</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" formControlName="IncomingPortNo">
      </div>
    </div>

    <!-- <div class="form-group">
      <label class="control-label col-sm-2" for="pwd">Password</label>
      <div class="col-sm-4">
        <input type="password" class="form-control" placeholder="Enter password" formControlName="Password">
      </div>
    </div>

    <p class="headingclass">Incoming Server Details</p>

    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Incoming Mail Server Name</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" formControlName="IncomingMailServer">
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Use Secure Connection</label>
      <div class="col-sm-4">
        <select class="form-control" formControlName="IncomingSecureType">
          <option value="SSL">SSL</option>
          <option value="TLS">TLS</option>
          <option value="Never">Never</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Port Number</label>
      <div class="col-sm-4">
        <input type="number" class="form-control" formControlName="IncomingPortNo">
      </div>
    </div>

    <p class="headingclass">Outgoing Server Details</p>
    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Email Address</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" formControlName="FromAddress">
      </div>
    </div>


    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Outgoing Mail Server Name</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" formControlName="OutgoingMailServer">
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Use Secure Connection</label>
      <div class="col-sm-4">
        <select class="form-control" formControlName="OutgoingSecureType">
          <option value="SSL">SSL</option>
          <option value="TLS">TLS</option>
          <option value="Never">Never</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Port Number</label>
      <div class="col-sm-4">
        <input type="number" class="form-control" formControlName="OutgoingPortNo">
      </div>
    </div> -->

    <div class="form-group">
      <label for="IsAuthenticated" class="control-label col-sm-2">Authentication Required</label>
      <div class="col-sm-4">
        <input type="checkbox" formControlName="IsAuthenticated" class="custom_checkbox">
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-2" for="text">Authentication Key</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" formControlName="AuthenticationKey">
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-4">
        <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!otherEmailForm">Save</button>
      </div>
    </div>
  </form>
</div>