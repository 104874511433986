import { TaskService } from './../../../services/task/task.service';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { VariableService } from '../../../services/VariableService/variable.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {
  taskData: any;
  DetailOn = true;
  FollowUpOn = false;
  showSpinner: Boolean = true;
  selectedPage: any = {};
  constructor(
    private route: ActivatedRoute,
    private taskService: TaskService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMTask'
      });
      this.taskService.editTaskById(params.id).subscribe(data => {
        this.taskData = data;
        this.showSpinner = false;
      });
    });
  }
  OnDetail() {
    this.DetailOn = true;
    this.FollowUpOn = false;
  }
  OnFollowUp() {
    this.FollowUpOn = true;
    this.DetailOn = false;
  }
}
