import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-case-detail',
  templateUrl: './case-detail.component.html',
  styleUrls: ['./case-detail.component.css']
})
export class CaseDetailComponent implements OnInit {
  caseForm: UntypedFormGroup;
  @Input() caseData: any;
  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.caseForm = this.fb.group({
      CaseId: null,
      OwnerId: '',
      CaseNumber: '',
      AccountId: '',
      ContactId: '',
      Status: '',
      Type: '',
      CaseOrigin: '',
      CaseReason: '',
      Product: '',
      Priority: '',
      Subject: '',
      Description: '',
      WebEmail: '',
      WebCompany: '',
      WebName: '',
      WebPhone: '',
      SLAViolation: '',
      PotentialLiability: '',
      EngineeringReqNumber: '',
      ProjectId: '',
      Client: '',
      CaseDate: new Date,

      PriorityName: '',
      StatusName: '',
      ContactName: '',
      ClientName: '',
      ProjectName: '',
      AssignedTo: '',
      CaseOwnerName: '',
      AccountName: '',
      ProductName: '',
      OriginName: '',
      TypeName: '',
      ReasonName: '',
    });
    this.caseForm.patchValue(this.caseData);
  }
}
