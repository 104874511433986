<div class="body_inner">
    <div class="heading_cont">
        <div class="pull-left">
            <span class="menu_icon project_task_template fa fa-tasks">
            </span>
            <h3 class="report_page_title">
                <p class="page_title_cont">Project Task Template</p>
                <p class="page_sub_title">{{editName}}</p>
            </h3>
        </div>
    </div>


    <div class="well bg_white_margin_top min_top">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="col-lg-4 col-md-4 col-sm-5 col-xs-12" for="text">Template Name</label>
                    <input class="form-control col-lg-8 col-md-8 col-sm-7 col-xs-12" type="text" [(ngModel)]="taskTemplateForm.TemplateName">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="col-lg-4 col-md-4 col-sm-5 col-xs-12" for="text">Type</label>
                    <select class="form-control col-lg-8 col-md-8 col-sm-7 col-xs-12" [(ngModel)]="taskTemplateForm.Type">
                        <option value="Approved">Approved</option>
                        <option value="Fabrication">Fabrication</option>
                    </select>
                </div>
            </div>
        </div>


        <div class="table_cont">
            <table class="table table-striped table-bordered dynomic_table">
                <thead>
                    <tr>
                        <th class="number_small"><b>S.No.</b></th>
                        <th class="name_mediumlong"><b>Task Name</b></th>
                        <th class="number_small"><b>Percentage Completed</b></th>
                        <th class="number_small"><b>Weightage</b></th>
                        <th class="number_short"><b>Action</b></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let list of taskTemplateForm.ListOfTasks; let i=index">
                        <td>
                            <select class="form-control" [(ngModel)]="list.SerialNo" (change)="addFieldValue(i)">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                            </select>
                        </td>
                        <td>
                            <input class="form-control" type="text" [(ngModel)]="list.TaskName" placeholder="Task Name"
                                (input)="addFieldValue(i)" />
                        </td>
                        <td>
                            <input class="form-control" type="text" [(ngModel)]="list.PercentageCompleted" placeholder="Percentage Completed"
                                (input)="addFieldValue(i)" (keypress)="numberOnly($event)" />
                        </td>
                        <td>
                            <input class="form-control" type="text" [(ngModel)]="list.Weightage" placeholder="Weightage"
                                (input)="addFieldValue(i)" (keypress)="numberOnly($event)" />
                        </td>
                        <td>
                            <div class="btn-group" dropdown>
                                <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
                                <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <br />
        <div>
            <button type="button" class="btn btn-default" (click)="resetForm()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="onSubmit()">Save</button>
        </div>
    </div>

    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>

    <app-htmldatatable [tableData]="taskTemplate" [columnName]="columnName" [columnRowName]="columnRowName" (edit)="edit($event)"
        (onDelete)="onDelete($event)">
    </app-htmldatatable>
</div>