import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd,NavigationStart,ActivationStart,ActivationEnd } from '@angular/router';
import { UtilService } from './util.service';
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
      ngModule: Type<T>;
      providers?: Provider[];
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'test';
  progress=0;
  showProgressBar=true;
  constructor(
    router: Router,
    public utilService:UtilService
  ) {
    this.utilService.currentLoadBar.subscribe(message =>{
     if(message=='true') {
      this.initialLoad()
     }else{
      this.endLoad()
     }
    })
    router.events.subscribe((value) => {
      if(value instanceof (NavigationStart || ActivationStart)){
        this.initialLoad();
      }
      if(value instanceof (NavigationEnd || ActivationEnd)){
        this.endLoad();
      }
      window.scroll(0, 0);
    });
  }
  initialLoad(){
    
      this.showProgressBar = true
      this.progress=0
      setTimeout(()=>{this.progress=50},1000)
   
  }
  endLoad(){
    setTimeout(()=>{
      this.progress=100
      setTimeout(()=>{
        this.showProgressBar = false
      },800);
    },2000)
  }
  ngOnInit() {
    setTimeout(()=>{this.progress=50},500)
   }
}
