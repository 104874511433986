import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class QuoteService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  getQuotePrint(CRMQuotePrint) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/quotePrint/getbyid?id=${q.paramId}&MasterType=${CRMQuotePrint}&`);
  }

  getQuoteType() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/quote/getall?BaseId=${q.paramId}&MasterType=${q.type}&`);
  }
  // Post Method
  addQuote(form) {
    return this.apiService.post('api/quote/create', form);
  }

  // Edit Method By Id
  editQuoteById(quoteId) {
    const q: any = this.variableService.getSearchKeys();
    if (q.type === 'CRMOpportunity') {
      return this.apiService.get(`api/quote/getbyid?Id=${quoteId}&MasterType=${'CRMQuote'}&`);
    } else {
      return this.apiService.get(`api/quote/getbyid?Id=${quoteId}&MasterType=${q.type}&`);
    }
  }

  // Delete Method
  deleteQuote(quoteId) {
    return this.apiService.delete(`api/quote/delete?Id=${quoteId}&`);
  }
  // Put Method
  updateQuote(form) {
    return this.apiService.put('api/quote/edit', form);
  }

  getQuoteStatus() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/quoteStatus/getall?Id=${q.paramId}&`);
  }

  createQuoteStatus(form) {
    return this.apiService.put('api/quoteStatus/create', form);
  }

  getQuoteByIdStatus(quoteId) {
    return this.apiService.get('api/quoteStatus/getbyid' + '?Id=' + quoteId + '&');
  }

}
