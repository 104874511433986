import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
@Injectable()
export class GlobalService {
    NameTypeList = [
        {
            value: 'Contact', name: 'Contact', avatar: 'menu_icon_small contacts glyphicon glyphicon-book'
          },
          { value: 'Lead', name: 'Lead', avatar: 'menu_icon_small Leads glyphicon glyphicon-briefcase' },
          { value: 'Machines', name: 'Machines', avatar: 'menu_icon_small campaigns glyphicon glyphicon-bullhorn' },
          { value: 'Locations', name: 'Locations', avatar: 'menu_icon_small location fa fa-location-arrow' },
    
      ];
    
      RelatedTypeList = [
        {
            value: 'Account', name: 'Account', avatar: 'menu_icon_small Accounts glyphicon glyphicon-list-alt'
          },
          {
            value: 'Campaign', name: 'Campaign', avatar: 'menu_icon_small campaigns glyphicon glyphicon-bullhorn'
          },
          {
            value: 'Case', name: 'Case', avatar: 'menu_icon_small Cases glyphicon glyphicon-headphones'
          },
          {
            value: 'Client', name: 'Client', avatar: 'menu_icon_small Clients fa fa-user'
          },
          {
            value: 'Contract', name: 'Contract', avatar: 'menu_icon_small Contracts fa fa-file'
          },
          {
            value: 'Milestone', name: 'Milestone', avatar: 'menu_icon_small milestone_configuration glyphicon glyphicon-flag'
          },
          {
            value: 'Opportunity', name: 'Opportunity', avatar: 'menu_icon_small Opportunities glyphicon glyphicon-bookmark'
          },
          {
            value: 'Project', name: 'Project', avatar: 'menu_icon_small Projects fa fa-cubes'
          },
          {
            value: 'ProjectTask', name: 'ProjectTask', avatar: 'menu_icon_small ProjectTasks fa fa-tasks'
          },
          {
            value: 'Quote', name: 'Quote', avatar: 'menu_icon_small Quotes fa fa-tags'
          },
          { value: 'Locations', name: 'Locations', avatar: 'menu_icon_small location fa fa-location-arrow' },
      ];
    constructor(private toastr: ToastrService) { }
    showSuccess(data) {
        this.toastr.success(data.masterName + ' ' + '"' + data.name + '"' + ' ' + 'was created');
    }

    showUpdate(data) {
        this.toastr.success(data.masterName + ' ' + '"' + data.name + '"' + ' ' + 'was saved');
    }

    showDelete(data) {
        this.toastr.success(data.masterName + ' ' + '"' + data.name + '"' + ' ' + 'was deleted');
    }

    showError() {
        this.toastr.info('Please select one');
    }

    disposeToastr() {
        this.toastr.clear();
    }

    showDefaultSuccess(data) {
        this.toastr.success(data.masterName + ' ' + 'was created');
    }
    showDefaultUpdate(data) {
        this.toastr.success(data.masterName + ' ' + 'was saved');
    }

    showEmailError() {
        this.toastr.info('Cannot Send Email.!! From Address or To Address is not available');
    }

    showEmailSuccess(data) {
        this.toastr.success('Email Sent to' + ' " ' + data.name + ' " ' + 'successfully.');
    }

    showChangeStage() {
        this.toastr.success('Stage change successfully');
    }

    showConverted() {
        this.toastr.success('Converted to invoice successfully');
    }
    showConvertedSuccess() {
        this.toastr.success('Quote Converted Successfully');
    }


    showInfo() {
        this.toastr.info(
            'Cannot Send Email.!! From Address or To Address is not available'
        );
    }

    showProdutAdded() {
        this.toastr.success('Product Added For Quote Successfully.');
    }

    showServiceAdded() {
        this.toastr.success('Service Added For Quote Successfully.');
    }

    showStatusUpdate(data) {
        this.toastr.success('Status is upadated for' + ' ' + '"' + data.masterName + '"');
    }

    showBulkEmail() {
        this.toastr.success('Email sent successfully');
    }
    
    showSuccessData(message) {
        this.toastr.success(message);
    }

    showErrorData(message) {
        this.toastr.error(message);
    }

    errorProjectMessage() {
        this.toastr.error('Please Enter Different Project Number.');
    }
}
