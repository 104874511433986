import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class PendingOrderService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  getPendingOrder() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/projectOrder/getall?BaseId=${q.paramId}&`);
  }

}
