import { Component, OnInit, Input } from '@angular/core';
import { ClientCustomerService } from './../../../../services/client-customer/client-customer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { AddSubscriptionsComponent } from '../add-subscriptions/add-subscriptions.component';
import { VariableService } from '../../../../services/VariableService/variable.service';
import { ItemsComponent } from '../../items/items.component';
import { GlobalService } from '../../../../services/global-Service/global.service';


@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})
export class SubscriptionsComponent implements OnInit {
  @Input() searchKeys;
  @Input() subscriptions: Array<any> = [];
  constructor(private modalService: NgbModal,
    private clientCustomerService: ClientCustomerService,
    private variableService:VariableService,
    private globalService:GlobalService
  ) {
  }
  ngOnInit() {
    this.getAllSubscriptions();
  }


  getAllSubscriptions() {
    this.clientCustomerService.getCustomerSubscriptions().subscribe(data => {
      this.subscriptions = data;
    });
  }

  open() {
    const modalRef = this.modalService.open(ItemsComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'custom-modal',
      keyboard: false
    });
    modalRef.componentInstance.itemArr = this.subscriptions;
    modalRef.componentInstance.tableHeader = {
      'sn': {
        name: 'S.No.',
        type:'dynamic',
        className:'number_small'
      },
      // 'ServiceID':{
      //   name: 'Service ID',
      //   value:'ServiceID',
      //   type:'string'
      // },
      'ServiceName':{
        name: 'Service Name',
        value:'ServiceName',
        type:'autocomplete',
        className:'name_long'
      },
      'StartDate':{
        name: 'Start Date',
        value:'StartDate',
        type:'date',
        className:'number_short'
      },
      'EndDate':{
        name: 'End Date',
        value:'EndDate',
        type:'date',
        className:'number_short'
      },
      'BillingType':{
        name: 'Billing Type',
        value:'BillingType',
        type:'select',
        className:'number_largeshort',
        options:[
          {
            key:'Monthly',
            value:'Monthly'
          },
          {
            key:'Quarterly',
            value:'Quarterly'
          },
          {
            key:'Yearly',
            value:'Yearly'
          }
        ]
      },
      'Charges':{
        name: 'Charges',
        value:'Charges',
        type:'number',
        className:'number_short'
      },
      'InvoiceOn':{
        name: 'Invoice On',
        value:'InvoiceOn',
        type:'select',
        options:[...Array(31).keys()].map(i=> {return {key:i+1,value:i+1}}),
        className:'number_largeshort'
      },
      'IsAutomatic':{
        name: 'Automatic',
        value:'IsAutomatic',
        type:'boolean',
        className:'number_short'
      },
      'Remarks':{
        name: 'Remarks',
        value:'Remarks',
        type:'string',
        className:'number_short'
      },
    }
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAllSubscriptions();
    });
    modalRef.componentInstance.addData.subscribe((data) => {
      const q: any = this.variableService.getSearchKeys();

      let finalData = {
        CustomerId:q.paramId,
        ListofSubscriptions : data
      }
      this.clientCustomerService.addSubscripstion(finalData).subscribe(
        res=>{
          if (res.ErrorMessages[1] === '0') {
            this.globalService.showSuccessData('Subscription created successfully.');
            this.getAllSubscriptions();
          }
          // modalRef.close();
        }
      )
    });

  }

}
