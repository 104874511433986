import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductService } from '../../../services/product/product.service';
import { findIndex } from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import { PosService } from '../../../services/point-of-sale/pos.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';
import { PosPaymentModelComponent } from '../pos-payment-model/pos-payment-model.component';


@Component({
  selector: 'app-pos-dashboard',
  templateUrl: './pos-dashboard.component.html',
  styleUrls: ['./pos-dashboard.component.css']
})
export class PosDashboardComponent implements OnInit {
  @ViewChild('textInput') textInput: ElementRef;
  @ViewChild('addElelemt') addElelemt: ElementRef;
  @ViewChild('template') template: ElementRef;
  @ViewChild('autoCompleteComp') autoCompleteComp;
  filteredOptions: any;
  getPosData: any;
  getProductList: any[];
  productdata: any;
  productText: any;
  productValue: any;
  getGroupList: any;
  setPosData = [];
  totalCost: any;
  calcData: any;
  Quantity: any;
  Amount: any;
  showSpinner: Boolean = true;
  public pointOfSaleForm: any;
  userDetails = true;
  subTotal:any;
  taxTotal:any;
  totalDiscount:any;
  taxAmount:any;
  modalRef :any;
  user:any;
  finalObj:any;
  invoiceId :any;
  searchTerm:any;
  expand=true;
  PosId:any;
  searchSpinner=false;
  selectMultiAddress=false;
  multiAddressData = [];
  dropDownAddress:any;
  constructor(
    private productService: ProductService,
    private posService:PosService,
    private modalService: NgbModal,
    private activatedRoute:ActivatedRoute,
    private variableService:VariableService
  ) { }

  ngOnInit() {
    this.getProductGroupList();
    this.getItemList(event);
    this.setPosData = [];
    this.getGroupList = [];
    this.totalCost = '0.00';
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.pointOfSaleForm = new FormGroup({
      Email: new FormControl(''),
      CustomerName: new FormControl(''),
      MobileNo: new FormControl(''),
      Address: new FormControl(''),
      State: new FormControl(''),
      Pincode: new FormControl(''),
      Country: new FormControl(''),
      TaxID: new FormControl('')
    });
    
    this.activatedRoute.params.subscribe(params => {
      this.PosId = params.id;
      this.showSpinner = true;

      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMPos',
      });

      this.posService.editByPOSId(params.id).subscribe(data => {
        this.pointOfSaleForm.patchValue(data);
        data.ListOfPointOfSale.map((item)=>{
          item.Text = item.ProductName;
          item.quantity=item.Quantity || 0;
          item.Price = item.Amount || 0;
          item.Value = item.ProductId ;
          this.updateItem(item);
          return item;
        })
        this.setPosData = data.ListOfPointOfSale;
        this.calculateTotal();
        this.showSpinner = false;

      });
    });
  }

  getProductGroupList() {
    this.productService.getListOfProduct().subscribe(data => {
      this.getGroupList = data;
      this.showSpinner = false;
      let groupId = null;
      for (let i = 0; i < this.getGroupList.length; i++) {
        if (this.getGroupList[i].Text === 'Primary') {
          groupId = this.getGroupList[i].Value;
          break;
        }
      }
      if (groupId !== null) {
        this.getAllPosProductData(groupId);
      }
    });
  }

  getFormValues(data){

    let fObj = {
      ...data,
      ...this.pointOfSaleForm.value
    }
    this.finalObj = fObj;
    if(data.type=='pay'){
      let modalRef = this.modalService.open(PosPaymentModelComponent, {
        size: 'sm',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.save.subscribe((res) => {
        this.finalObj = {
          ...this.finalObj,
          ...res
        }
        modalRef.close();
        this.savePosData();
      });
    }else{
      this.savePosData();
    }
  }

  savePosData(){
    let responseMessage = [];
    if(this.PosId){
      this.finalObj['ID'] = this.PosId;
      this.posService.updatePos(this.finalObj).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          // this.toastr.success('Data Added Successfully.');
          this.invoiceId = responseMessage[2]
          this.modalRef = this.modalService.open(this.template, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
          });
        }
      });
    }else{
      this.posService.addPos(this.finalObj).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          // this.toastr.success('Data Added Successfully.');
          this.invoiceId = responseMessage[2]
          this.modalRef = this.modalService.open(this.template, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
          });
        }
      });
    }
  }

  addItemToList(data, qty) {
    this.userDetails = false;
    const index = findIndex(this.setPosData, ['Value', data.Value]);
    if (index !== -1) {
      this.setPosData[index].quantity += 1;
      // let d = this.setPosData[index].discount || 0;
      // let t = this.setPosData[index].TaxRate || 0;
      // this.setPosData[index].finalPrice = (this.setPosData[index].Price * (1 - (d/100)) * (1 + (t/100)));
      // this.setPosData[index].finalPrice = this.setPosData[index].quantity * t * (1-(d/100));
      // this.setPosData[index].taxAmount = ((t/100) * this.setPosData[index].Price);
      // this.setPosData[index].discountAmount =  this.setPosData[index].finalPrice - this.setPosData[index].Price ;
      this.updateItem(this.setPosData[index]);
    } else {
      if (!qty) {
        data.quantity = 1;
      }
     
      this.updateItem(data);
      this.setPosData.push(data);
    }
    this.calculateTotal();
    this.productText = '';
    this.autoCompleteComp.textInput.nativeElement.focus();
  }

  itemActions(data) {
    this.calcData = data.item;
    const index = this.setPosData.indexOf(data.item);
    switch (data.key) {
      case 'add':
        this.setPosData[index].quantity = parseInt(this.setPosData[index].quantity, 10) + 1;
        this.updateItem(this.setPosData[index]);
        this.calculateTotal();
        break;
      case 'sub':
        const qty = this.setPosData[index].quantity;
        if (qty > 0) {
          this.setPosData[index].quantity -= 1;
        }
        this.updateItem(this.setPosData[index]);
        this.calculateTotal();
        break;
      case 'update':
        if(data.name=='price'){
          this.setPosData[index].Price=data.event.target.value
        }
        if(data.name=='dis'){
          this.setPosData[index]['discount']=data.event.target.value;
        }
        // let d = this.setPosData[index].discount || 0;
        // let t = this.setPosData[index].TaxRate || 0;
        // this.setPosData[index].finalPrice = (this.setPosData[index].Price * (1 - (d/100)) * (1 + (t/100)))
        this.updateItem(this.setPosData[index]);
        this.calculateTotal();
        break;
      case 'remove':
        this.setPosData.splice(index, 1);
        this.calculateTotal();
        break;
      default:
        break;
    }
  }

  updateItem(item){
    let d = item.discount || 0;
    let t = item.TaxRate || 0;
    item.finalPrice = item.quantity * (item.Price * (1 - (d/100)))
    item.taxAmount = item.quantity * ((t/100) * (item.Price * (1 - (d/100))));
    item.discountAmount = item.quantity * (item.Price - ((item.Price * (1 - (d/100))))) ;
  }

  calculateTotal() {
    let total = 0.00,subTotal=0.00,cartitems = 0.00,discount = 0.00,taxAmount=0.00;
    this.setPosData.forEach((obj) => {
      total += (obj.finalPrice * obj.quantity);
      subTotal += (obj.finalPrice);
      cartitems += obj.quantity;
      discount += obj.discountAmount;
      taxAmount += obj.taxAmount
    });
    this.subTotal = subTotal.toFixed(2);
    this.totalCost = (subTotal+taxAmount).toFixed(2);
    this.totalDiscount = discount.toFixed(2);
    this.taxAmount = taxAmount.toFixed(2);
  }

  getItemList(event) {
    const ProductGroupId = event?.Value || '';
    if (ProductGroupId !== undefined) {
      this.getAllPosProductData(ProductGroupId);
    }
  }

  getAllPosProductData(groupId) {
    this.productService.getPosProductList(groupId).subscribe(res => {
      this.getPosData = res;
    });
  }

  filterData(value) {
    value.data = value.data.trim();
    this.productService.getAllPosProductList(value.data).subscribe(res => {
      this.getProductList = res;
    });
  }

  setAutoCompleteClient(data) {
    this.productdata = data.data.option.value;
    this.Quantity = 1;
    this.addElelemt.nativeElement.focus();

  }

  addItem(data) {
    if (data.quantity === undefined) {
      this.productdata.quantity = 1;
    } else {
      this.productdata.quantity = data.quantity;
    }
    this.addItemToList(this.productdata, true);
    this.filteredOptions = [];
    this.productText = '';
    this.Quantity = '';
    this.autoCompleteComp.textInput.nativeElement.value = '';
  }

  resetPosData(data){
    this.setPosData = [];
  }

  toggleUser(){
    this.userDetails = !this.userDetails;
  }
  searchMember(){
    this.searchSpinner = true;
    this.posService.search(this.searchTerm).subscribe((res)=>{
      if(res.listofposcustomers && res.listofposcustomers.length>0){
        if(res.listofposcustomers && res.listofposcustomers.length>1){
          this.selectMultiAddress = true;
          this.multiAddressData = res.listofposcustomers;
        }else{
          this.selectMultiAddress = false;
          res.listofposcustomers[0]['CustomerName'] = res.listofposcustomers[0]['Name']
          this.pointOfSaleForm.patchValue(res.listofposcustomers[0])
        }
      }
      this.searchSpinner = false;

    })
  }

  selectAddress(event){
    console.log()
    this.multiAddressData[event.target["selectedIndex"]]['CustomerName'] = this.multiAddressData[event.target["selectedIndex"]]['Name']
    this.pointOfSaleForm.patchValue(this.multiAddressData[event.target["selectedIndex"]])
  }

  closeModal(){
    this.modalRef.close();
    // this.resetPointOfSaleForm(form);
  }
  toggleExpand(){
    this.expand = !this.expand;
  }
  onPrint() {
    window.print();    
  }
  getTotalQty(){
    let qty = 0;
    this.setPosData.map((item)=>{
      qty += item.quantity
    })
    return qty;
  }
}
