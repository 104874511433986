import { LeadService } from './../../../../services/lead/lead.service';
import { VariableService } from '../../../../services/VariableService/variable.service';
import { AutocompleteService } from '../../../../services/autocomplete/autocomplete.service';
import { AutocompleteModel } from '../../../../model/sharedModel/autocomplete/autocomplete-model';
import { AccountService } from '../../../../services/accounts/account.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ContactService } from '../../../../services/contact/contact.service';
import { OpportunityService } from '../../../../services/opportunity/opportunity.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';

declare var $: any;
// declare var jquery: any;

@Component({
  selector: 'app-convert-lead',
  templateUrl: './convert-lead.component.html',
  styleUrls: ['./convert-lead.component.css']
})
export class ConvertLeadComponent implements OnInit {
  @ViewChild('convertContent') convertContent: ModalDirective;
  convertLeadForm: UntypedFormGroup;
  @Input() convertLeadData: any;
  public autoCompleteOpportunityStage: AutocompleteModel[];
  public contactMode = 'newContact';
  public accountMode = 'newAccount';
  public opportunityMode = 'newOpportunity';
  public oppAccountId: any;
  oppContactId: any;
  opportunityID: any;
  contactDetail: any;
  accountDetail: any;
  opportunityDetail: any;
  conversionStatus = 1;
  leadId: any;
  accountfilteredOptions: any;
  accountText: any; accountValue: any;
  contactfilteredOptions: any;
  contactText: any; contactValue: any;
  opportunityfilteredOptions: any;
  opportunityText: any; opportunityValue: any;
  constructor(
    private fb: UntypedFormBuilder,
    public autocompleteService: AutocompleteService,
    private accountService: AccountService,
    private contactService: ContactService,
    private opportunityService: OpportunityService,
    private leadService: LeadService,
    public activeModal: NgbActiveModal,
    private variableService: VariableService,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {
    const q: any = this.variableService.getSearchKeys();
    this.convertLeadForm = this.fb.group({
      AccountName: '',
      Salutation: '',
      FirstName: '',
      LastName: '',
      OpportunityName: '',
      AccountId: '',
      OpportunityId: '',
      ContactId: '',
      LeadOwnerId: '',
      IndustryId: '',
      AnnualRevenue: '',
      Phone: '',
      Fax: '',
      Email: '',
      Mobile: '',
      Title: '',
      LeadSource: '',
      NoofEmployees: '',
      LeadAmount: '',
      Website: '',
      TerritoryID: '',
      Stage: '',
      CloseDate: '',
      FaceBookID: '',
      TwitterID: '',
      SkypeID: '',
      Rating: '',
      Currency: '',
      BillingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      })
    });
    this.convertLeadForm.patchValue({
      LeadId: this.convertLeadData.LeadId,
      AccountName: this.convertLeadData.CompanyName,
      Salutation: this.convertLeadData.Salutation,
      FirstName: this.convertLeadData.FirstName,
      LastName: this.convertLeadData.LastName,
      OpportunityName: this.convertLeadData.CompanyName,
      LeadOwnerId: this.convertLeadData.OwnerId,
      IndustryId: this.convertLeadData.IndustryId,
      AnnualRevenue: this.convertLeadData.AnnualRevenue,
      Phone: this.convertLeadData.Phone,
      Fax: this.convertLeadData.Fax,
      NoofEmployees: this.convertLeadData.NoofEmployees,
      Email: this.convertLeadData.Email,
      Mobile: this.convertLeadData.Mobile,
      Title: this.convertLeadData.Title,
      LeadSource: this.convertLeadData.LeadSource,
      Website: this.convertLeadData.Website,
      LeadAmount: this.convertLeadData.LeadAmount,
      TerritoryID: this.convertLeadData.TerritoryID,
      FaceBookID: this.convertLeadData.FaceBookID,
      TwitterID: this.convertLeadData.TwitterID,
      SkypeID: this.convertLeadData.SkypeID,
      Rating: this.convertLeadData.Rating,
      Currency: this.convertLeadData.Currency,
      BillingAddress: {
        City: this.convertLeadData.BillingAddress.City,
        Street: this.convertLeadData.BillingAddress.Street,
        Country: this.convertLeadData.BillingAddress.Country,
        StateName: this.convertLeadData.BillingAddress.StateName,
        ZipCode: this.convertLeadData.BillingAddress.ZipCode
      }
    });
    this.getAutoCompleteOpportunityStage();
    this.leadId = q.paramId;
  }

  getAccountList(value) {
    this.autocompleteService.getAllAccountList(value.data).subscribe(
      res => {
        this.accountfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteAccount(data) {
    const accountId = data.data.option.value.Value;
    this.convertLeadForm.controls['AccountId'].setValue(accountId);
  }

  getContactList(value) {
    this.autocompleteService.getAllContactList(value.data).subscribe(
      res => {
        this.contactfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteContact(data) {
    const contactId = data.data.option.value.Value;
    this.convertLeadForm.controls['ContactId'].setValue(contactId);
  }

  getOpportunityList(value) {
    this.autocompleteService.getAllOpportunityList(value.data).subscribe(
      res => {
        this.opportunityfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteOpportunity(data) {
    const opportunityId = data.data.option.value.Value;
    this.convertLeadForm.controls['OpportunityId'].setValue(opportunityId);
  }

  getAutoCompleteOpportunityStage() {
    this.opportunityService.getOpportunityStageList().subscribe(data => {
      this.autoCompleteOpportunityStage = data;
    });
  }

  createNewAccount(value) {
    this.accountMode = value;
  }

  createExistingAccount(value: any) {
    this.accountMode = value;
  }

  createNewContact(value: any) {
    this.contactMode = value;
  }

  createExistingContact(value: any) {
    this.contactMode = value;
  }

  createNewOpportunity(value: any) {
    this.opportunityMode = value;
  }

  createExistingOpportunity(value: any) {
    this.opportunityMode = value;
  }

  accountResponseMessage() {
    if (this.accountMode === 'newAccount') {
      this.accountService
        .addAccount({
          AccountName: this.convertLeadForm.value.AccountName,
          AccountOwnerId: this.convertLeadForm.value.LeadOwnerId,
          IndustryId: this.convertLeadForm.value.IndustryId,
          AnnualRevenue: this.convertLeadForm.value.AnnualRevenue,
          Phone: this.convertLeadForm.value.Phone,
          Fax: this.convertLeadForm.value.Fax,
          NoOfEmployees: this.convertLeadForm.value.NoofEmployees,
          Website: this.convertLeadForm.value.Website,
          TerritoryID: this.convertLeadForm.value.TerritoryID,
          Rating: this.convertLeadForm.value.Rating,
          BillingAddress: {
            City: this.convertLeadForm.value.BillingAddress.City,
            Street: this.convertLeadForm.value.BillingAddress.Street,
            Country: this.convertLeadForm.value.BillingAddress.Country,
            StateName: this.convertLeadForm.value.BillingAddress.StateName,
            ZipCode: this.convertLeadForm.value.BillingAddress.ZipCode
          }
        })
        .subscribe(data => {
          this.oppAccountId = data.ErrorMessages[3];
          this.contactResponseMessage();
        });
    } else {
      this.oppAccountId = this.convertLeadForm.value.AccountId;
      this.contactResponseMessage();
    }
  }

  contactResponseMessage() {
    if (this.contactMode === 'newContact') {
      this.contactService
        .addContact({
          Salutation: this.convertLeadForm.value.Salutation,
          FirstName: this.convertLeadForm.value.FirstName,
          LastName: this.convertLeadForm.value.LastName,
          OwnerId: this.convertLeadForm.value.LeadOwnerId,
          AccountId: this.oppAccountId,
          MobilePhone: this.convertLeadForm.value.Mobile,
          Phone: this.convertLeadForm.value.Phone,
          Title: this.convertLeadForm.value.Title,
          Fax: this.convertLeadForm.value.Fax,
          Email: this.convertLeadForm.value.Email,
          LeadSource: this.convertLeadForm.value.LeadSource,
          TerritoryID: this.convertLeadForm.value.TerritoryID,
          FaceBookID: this.convertLeadForm.value.FaceBookID,
          TwitterID: this.convertLeadForm.value.TwitterID,
          SkypeID: this.convertLeadForm.value.SkypeID,
          BillingAddress: {
            City: this.convertLeadForm.value.BillingAddress.City,
            Street: this.convertLeadForm.value.BillingAddress.Street,
            Country: this.convertLeadForm.value.BillingAddress.Country,
            StateName: this.convertLeadForm.value.BillingAddress.StateName,
            ZipCode: this.convertLeadForm.value.BillingAddress.ZipCode
          }
        })
        .subscribe(data => {
          this.oppContactId = data.ErrorMessages[3];
          this.opportunityResponseMessage();
        });
    } else {
      this.oppContactId = this.convertLeadForm.value.ContactId;
      this.opportunityResponseMessage();
    }
  }

  opportunityResponseMessage() {
    if (this.opportunityMode === 'newOpportunity') {
      let responseMessage = [];
      this.opportunityService
        .addOpportunity({
          OpportunityName: this.convertLeadForm.value.OpportunityName,
          ContactId: this.oppContactId,
          AccountId: this.oppAccountId,
          OwnerId: this.convertLeadForm.value.LeadOwnerId,
          LeadSource: this.convertLeadForm.value.LeadSource,
          Amount: this.convertLeadForm.value.LeadAmount,
          TerritoryID: this.convertLeadForm.value.TerritoryID,
          Stage: this.convertLeadForm.value.Stage,
          CloseDate: this.convertLeadForm.value.CloseDate,
          Currency: this.convertLeadForm.value.Currency,
        })
        .subscribe(data => {
          responseMessage = data.ErrorMessages;
          this.opportunityID = responseMessage[3];
          if (responseMessage[1] === '0') {
            this.leadService.addLeadConversionStatus({
              Status: this.conversionStatus,
              LeadId: this.leadId,
            }).subscribe(statusdata => {
              this.getContactDetails();
              this.getOpportunityDetails();
              this.getAccountDetails();
              this.openConvert();
            });
          }
        });
    } else {
      this.opportunityID = this.convertLeadForm.value.OpportunityId;
      this.leadService.addLeadConversionStatus({
        Status: this.conversionStatus,
        LeadId: this.leadId,
      }).subscribe(statusdata => {
        this.getContactDetails();
        this.getOpportunityDetails();
        this.getAccountDetails();
        this.openConvert();
      });
    }
    this.activeModal.dismiss();
    this.resetForm();
  }


  getContactDetails() {
    this.contactService.getContactById(this.oppContactId, 'Contact').subscribe(data => {
      this.contactDetail = data;
    });
  }

  getAccountDetails() {
    this.accountService.getByAccountId(this.oppAccountId, 'Account').subscribe(data => {
      this.accountDetail = data;
    });
  }

  getOpportunityDetails() {
    this.opportunityService.getOpportunityById(this.opportunityID, 'Opportunity').subscribe(data => {
      this.opportunityDetail = data;
    });
  }

  openConvert() {
    this.modalService.open(this.convertContent, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  onGoToLead() {
    this.router.navigate(['/leads']);
    $('#btnCancel').trigger('click');
  }

  resetForm() {
    this.convertLeadForm.reset();
  }
}


