import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder
} from '@angular/forms';
@Component({
  selector: 'app-pos-payment-model',
  templateUrl: './pos-payment-model.component.html',
  styleUrls: ['./pos-payment-model.component.css']
})
export class PosPaymentModelComponent implements OnInit {
  @Output() save = new EventEmitter();
  paymentForm: UntypedFormGroup;
  type='cc';
  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      type: new UntypedFormControl('cc'),
      refNo:''
    })
  }
  saveData(){
    console.log('this.type',this.type)
    let obj = this.paymentForm.value;
    // obj.type = this.type;
    this.save.emit(obj);
  }
}
