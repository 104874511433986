import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { AccountService } from '../../../services/accounts/account.service';
import { QuoteStatusComponent } from '../quote-status/quote-status.component';
import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';
import { QuoteService } from '../../../services/quote/quote.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { serviceURLs } from './../../../constants/CRMService/serviceURLs';
import { InvoiceService } from '../../../services/invoice/invoice.service';
import { GlobalService } from '../../../services/global-Service/global.service';
import { Editor,toHTML } from 'ngx-editor';
import { FormGroup, FormControl } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {
  @ViewChild(QuoteStatusComponent) quoteStatusComponent: QuoteStatusComponent;
  @ViewChild('content') content: ElementRef;
  searchKeys: object = {};
  RealtedOn = true;
  DetailOn = false;
  activityOn = true;
  termOn = false;
  convertedQuoteData: any;
  @Input() convertQuoteData: any = {};
  quoteStatusData: any;
  showSpinner: Boolean = true;
  quoteDataList: any;
  quotePdfData: any;
  selectedPage: any = {};
  accountId: any;
  quoteData: any;
  termData: any;
  quoteAccountData: any;
  ReportId: any;
  filetype: any;
  TransactionNo: any;
  RDLName: any;
  quoteId: any;
  ToMailId: any;
  FromEmail: any;
  Body: any;
  Subject: any;
  editor: Editor;
  HTMLValue: any;
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '20rem',
    width: 'auto',
    translate: 'no',
    'toolbar': [
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['code', 'blockquote'],
      ['ordered_list', 'bullet_list'],
      [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
      ['link', 'image'],
      ['text_color', 'background_color'],
      ['align_left', 'align_center', 'align_right', 'align_justify'],
      ['horizontal_rule', 'format_clear'],
    ]
  };
  form = new FormGroup({
    Body: new FormControl(null),
  });
  disableIsDirect: Boolean = true;
  disableIsNotDirect: Boolean = true;
  constructor(private route: ActivatedRoute,
    private variableService: VariableService,
    private quoteService: QuoteService,
    private modalService: NgbModal,
    private accountService: AccountService,
    private topnavbarService: TopnavbarService,
    private invoiceService: InvoiceService,
    private globalService: GlobalService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.editor = new Editor();
    this.route.params.subscribe(params => {
      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMQuote'
      });

      this.quoteService.editQuoteById(params.id).subscribe(data => {
        this.quoteDataList = data;
        this.convertedQuoteData = data;
        this.quoteId = data.QuoteID;
        this.accountId = data.AccountId;
        this.quoteData = data;
        this.showSpinner = false;
        this.getAccountData(data.AccountId);
        this.setDisable();
      });
      // this.quoteStatusComponent.getQuoteStatusList(params.id);
    });
  }

  setDisable() {
    if (this.quoteDataList.IsDirect === true) {
      this.disableIsDirect = true;
      this.disableIsNotDirect = false;
    } else {
      this.disableIsDirect = false;
      this.disableIsNotDirect = true;
    }
  }


  getAccountData(accountId) {
    if (this.accountId !== null) {
      this.accountService.getByAccountId(this.accountId, 'Account').subscribe(data => {
        this.quoteAccountData = data;
      });
    }
    this.getAllQuotePrint();
  }

  OnRelated() {
    this.RealtedOn = true;
    this.DetailOn = false;
    this.termOn = false;
  }

  onTerm() {
    this.termOn = true;
    this.DetailOn = false;
    this.RealtedOn = false;
  }

  onActivity() {
    this.activityOn = true;
  }
  OnDetail() {
    this.DetailOn = true;
    this.RealtedOn = false;
    this.termOn = false;
  }

  getAllQuotePrint() {
    this.quoteService.getQuotePrint('CRMQuotePrint').subscribe(data => {
      this.quotePdfData = data;
      this.termData = data.TermsDetails;
      this.setInvoiceData();
    });
  }

  setInvoiceData() {
    this.ReportId = this.quoteId;
    this.filetype = 'pdf';
    this.TransactionNo = this.quotePdfData.QuoteNo;
    this.RDLName = this.quoteDataList.RDLName;
    this.ToMailId = this.quotePdfData.Email;
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.FromEmail = user.EmailId;
  }

  createPdf() {
    this.setInvoiceData();
    const user = JSON.parse(sessionStorage.getItem('currentUser'));

    const url = `api/files/getfile?ReportId=${this.ReportId}&FileType=${
      this.filetype
      }&TransactionNo=${this.TransactionNo}&RDLName=${this.RDLName}&CompanyId=${
      user.CompanyId
      }&UserId=${user.UserId}`;
    window.open(serviceURLs.CRMServiceBaseURL + url, '_blank');
  }

  openEmail(content) {
    this.setInvoiceData();
    this.modalService.open(content, { size: 'sm', backdrop: 'static', keyboard: false });
  }

  sendEmail() {
    this.setInvoiceData();
    this.Body = 'Dear' + ' ' + this.quotePdfData.AccountName + ',' +
      '<br/>' + '<br/>' + 'Email is genereated for quote' + '"' + this.quoteDataList.QuoteName + '"' +
      ' Please verify the details. ' + '<br/>' + '<br/>' +
      'Regards, ' + '<br/>' + this.quotePdfData.EmployeeDetails.OwnerName;
    this.Subject = 'Email Generated for quote' + ' " ' + this.quoteDataList.QuoteName + ' " ';
    if (this.FromEmail === '' || this.ToMailId === '') {
      this.globalService.showInfo();
    } else {
      this.invoiceService.sendInvoiceEmail(
        this.ReportId,
        this.RDLName,
        this.TransactionNo,
        this.filetype,
        this.ToMailId,
        this.FromEmail,
        toHTML(this.form.value.Body),
        this.Subject
      ).subscribe(data => {
        this.globalService.showEmailSuccess({ masterName: this.ToMailId });
      });
    }
    $('#btnCancel').trigger('click');

  }

  // const doc = new jsPDF();

  // const specialElementHandler = {
  //   '#editor': function (elements, render) {
  //     return true;
  //   }
  // };

  // const content = this.content.nativeElement;
  // doc.fromHTML(content.innerHTML, 13, 13, {
  //   'width': 170,
  //   'elementHandlers': specialElementHandler
  // });
  // window.open(doc.output('bloburl'));
  // }

}
