import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { EmployeeService } from '../../../services/Employee/employee.service';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {
  @Input() eventData: any;
  constructor(
  ) { }

  ngOnInit() {
  }

}
