import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../../../ApiService/api.service';
import { VariableService } from '../../../VariableService/variable.service';
@Injectable()
export class EmailService {
  messages: any;
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }


  getEmailMessages() {
    return this.apiService.post('api/emailclient/messages', {});
  }

  getEmailType() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/emailmessage/getall?BaseId=${q.paramId}&MasterType=${q.type}&`
    );
  }

  addEmail(form) {
    return this.apiService.post('api/emailmessage/create', form);
  }

  emailById(EmailSentID) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/emailmessage/getbyid?Id=${EmailSentID}&MasterType=${q.type}&`
    );
  }

  sendEmail(form) {
    return this.apiService.post('api/emailclient/sendemail', form);
  }

  setEmailData(data: any) {
    this.messages = data;
  }

  sendEmailCode(code: string) {
    return this.apiService.post('api/emailclient/profile', { code: code });
  }

  getEmailTemplates(){
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/emailtemplate/getall?MasterType=CRMEmailTemplate&`);
  }

  addEmailTemplate(form) {
    return this.apiService.post('api/emailtemplate/create', form);
  }
  updateEmailTemplate(form) {
    return this.apiService.put('api/emailtemplate/edit', form);
  }
  emailTemEditById(emailTemEditId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/emailtemplate/getbyid?Id=${emailTemEditId}&MasterType=${q.type}&`);
  }

}
