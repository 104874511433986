<div class="close-btn-container pos-abs">
    <!--  -->
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h4 class="modal-title text-center">Send Message</h4>
  </div>
  <form [formGroup]="campainForm" class="form-validate" (ngSubmit)="onSubmit(campainForm)">
    <div class="modal-body">
      <div class="extra-pad">
        <div class="">
          <div class="">
            <div class="row">
                <!-- {{senderDetails}} -->
            </div>
            <div class="row">

              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="text">Select Communication Type</label>
                <select class="form-control" formControlName="Type" (change)="onEntity($event.target.value)">
                  <option value="SMS">SMS</option>
                  <option value="Email">Email</option>
                  <option value="WhatsApp">WhatsApp</option>
                </select>
              </div>
            </div>
            <div class="row">

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label for="text">Select Template</label>
                  <select class="form-control" formControlName="templateId" [disabled]="templateArr.length==0">
                    <option *ngFor="let template of templateArr" value="{{template.Value}}">{{template.Text}}</option>
                  </select>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">Cancel</button>
      <button type="submit" class="btn btn-primary" [disabled]="!campainForm.valid">send</button>
    </div>
  </form>
