<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Call log</h4>
</div>
<form [formGroup]="callLogForm" novalidate (ngSubmit)="onSubmit(callLogForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="form-group ">
          <label for="name">Subject</label>
          <input type="textbox" class="form-control" formControlName="Subject">
          <input type="hidden" formControlName="CallLogId">
        </div>
        <div class="form-group">
          <label for="name">Comments</label>
          <textarea class="form-control" rows="3" formControlName="Comments"></textarea>
        </div>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 two_input">
            <label for="name">Name</label>
            <div class="select_cont mini_cont">
              <ng-select [items]="NameTypeList" bindLabel="name" bindValue="name" lass="form-control selectType half_select"
                [(ngModel)]="selectedName" [clearable]="false" formControlName="NameType" id="contactLeadTo">
                <ng-template ng-label-tmp let-item="item">
                  <span class="{{item.avatar}}"></span>
                  <span style="padding-left:10px;">{{item.name}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <span class="{{item.avatar}}"></span>
                  <span style="padding-left:10px;">{{item.name}}</span>
                </ng-template>
              </ng-select>
            </div>

            <div class="select_cont max_cont">
              <app-autocomplete [autoCompleteText]="contactLeadText" (setAutoComplete)="setcontactLeadType($event)"
                [autoCompleteValue]="contactLeadValue" (fiteredData)="getLeadContactList($event)" [filteredOptions]="contactLeadFilterOption">
              </app-autocomplete>
            </div>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 two_input">
            <label for="text">Related To</label>
            <div class="select_cont mini_cont">
              <ng-select [items]="RelatedTypeList" bindLabel="name" bindValue="name" lass="form-control selectType half_select"
                [(ngModel)]="selectedRelatedType" [clearable]="false" formControlName="RelatedType" id="contactLeadTo">
                <ng-template ng-label-tmp let-item="item">
                  <span class="{{item.avatar}}"></span>
                  <span style="padding-left:10px;">{{item.name}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <span class="{{item.avatar}}"></span>
                  <span style="padding-left:10px;">{{item.name}}</span>
                </ng-template>
              </ng-select>
            </div>
            <div class="select_cont max_cont">
              <app-autocomplete [autoCompleteText]="RelatedText" (setAutoComplete)="setAutocompleteRelatedType($event)"
                [autoCompleteValue]="RelatedValue" (fiteredData)="getRealtedTypeList($event)" [filteredOptions]="relatedTypeFilterOption">
              </app-autocomplete>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">Cancel</button>
      <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!callLogForm">Save</button>
    </div>
  </div>
</form>