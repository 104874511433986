
import { VariableService } from '../../../../../services/VariableService/variable.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ChatterService } from '../../../../../services/common/activity-page/chatter/chatter.service';
import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-chatter',
  templateUrl: './chatter.component.html',
  styleUrls: ['./chatter.component.css']
})
export class ChatterComponent implements OnInit {

  chatterForm: UntypedFormGroup;
  MasterType: any;
  chatterDetail: Array<any> = [];
  editor: Editor;
  html: '';

  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '20rem',
    width: 'auto',
    translate: 'no',
    'toolbar': [
      ['bold', 'italic'], ['underline', 'strike'],
      // ['justifyLeft', 'justifyRight', 'justifyFull', 'outdent'],
      ['align_left', 'align_center', 'align_right', 'align_justify'],
      ['link']
    ]
  };

  constructor(
    private fb: UntypedFormBuilder,
    private chatterService: ChatterService,
    private variableService: VariableService,
  ) {
  }
  ngOnInit() {
    this.editor = new Editor();

    const q: any = this.variableService.getSearchKeys();
    this.chatterForm = this.fb.group({
      ChatterId: '',
      TextPosted: '',
      ParentId: '',
      IsLiked: '',
      RelatedToId: q.paramId,
      IsCommented: '',
      NofoViews: '',
      SharedWith: '',
    });
    this.getAllChatter();
    this.MasterType = 'this' + ' ' + q.type.slice(3).toLowerCase();
  }

  getAllChatter() {
    this.chatterService.getChatter().subscribe(data => {
      this.chatterDetail = data;
    });
  }
  onSubmit(form: UntypedFormGroup) {
    this.chatterService.createChatter(form.value).subscribe(data => {
      this.getAllChatter();
    });
    this.chatterForm.patchValue({ TextPosted: '' });
  }
}
