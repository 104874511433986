<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon dashboard fa fa-cog">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Dashboards Display Settings</p>
      </h3>
    </div>
  </div>

  <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>

  <div class="bg_white_margin_top min_top">


    <table class="table table-striped">
      <thead>
        <tr>
          <th class="name_full_width"> Name</th>
          <th class="name_short"> Display</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let dashboard of dashBoardForm.ListOfDashBoard ; let i=index">
          <td class="name_full_width">{{dashboard.DashBoardName}}</td>
          <td class="name_short">
            <input type="checkbox" class="custom_checkbox" [(ngModel)]="dashboard.IsDisplay">
          </td>
        </tr>
      </tbody>
    </table>

    <button type="button" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
  </div>
</div>