import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from '../../../services/role/role.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { DeleteModalComponent } from '../../../../../common/delete-modal/delete-modal.component';
import { GlobalService } from '../../../services/global-Service/global.service';

declare var $: any;

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  @Input() deleteData: any;

  roleForm: UntypedFormGroup;
  editName: any;
  showSpinner: Boolean = true;
  roleData: Array<any> = [];
  columnName = [];
  columnRowName = [];
  constructor(
    private fb: UntypedFormBuilder,
    private roleService: RoleService,
    private modalService: NgbModal,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.roleForm = this.fb.group({
      RoleName: '',
      Description: '',
      RoleId: null,
      ClientId: '',
    });
    this.getAllRole();
    this.columnName = [];
    this.columnRowName = [];
  }

  getAllRole() {
    this.roleService.getRoles().subscribe(data => {
      this.roleData = data;
      this.columnName = ['Role Name', 'Description'];
      this.columnRowName = ['RoleName', 'Description'];
      this.showSpinner = false;
    });
  }

  edit(editData) {
    const roleId = editData.data.RoleId;
    this.editName = editData.data.RoleName;
    if (roleId !== null) {
      this.roleService.editRoleById(roleId).subscribe(data => {
        this.roleForm.patchValue(data);
      });
    }
  }

  onDelete(data) {
    this.deleteData = ({ id: data.data.RoleId, name: data.data.RoleName, page: 'Role' });
    const modalRef = this.modalService.open(DeleteModalComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.deleteData = this.deleteData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAllRole();
    });
  }

  onSubmit(form: UntypedFormGroup) {
    const roleName = form.value.RoleName;
    let responseMessage = [];
    if (form.value.RoleId === null) {
      this.roleService.addRole(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Role', name: roleName });
        }
        this.showSpinner = true;
        this.getAllRole();
      });
    } else {
      this.roleService.updateRole(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Role', name: roleName });
        }
        this.showSpinner = true;
        this.getAllRole();
        this.globalService.disposeToastr();
      });
      this.resetForm();
    }
  }
  resetForm() {
    this.roleForm.reset();
  }
}
