import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MachineService } from '../../../services/machine/machine.service';
import { ItemsComponent } from '../../../shared/components/items/items.component';
import { VariableService } from '../../../services/VariableService/variable.service';
import { GlobalService } from './../../../services/global-Service/global.service';


@Component({
  selector: 'app-planogram',
  templateUrl: './planogram.component.html',
  styleUrls: ['./planogram.component.css']
})
export class PlanogramComponent implements OnInit {
  planogramData:any;
  @Input() searchKeys;

  constructor(private modalService: NgbModal, 
    private machineService:MachineService,
    private variableService:VariableService,
    private globalService:GlobalService
    ) { }

  ngOnInit(): void {
    this.getAll();
  }

  getAll(){
    this.machineService.getTypePlanogram().subscribe(data => {
      this.planogramData=data;
    })
  }
  open(){
    const q: any = this.variableService.getSearchKeys();

    const modalRef = this.modalService.open(ItemsComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'custom-modal',
      keyboard: false
    });
    modalRef.componentInstance.tableHeader = {
      'sn': {
        name: 'S.No.',
        type:'dynamic',
        className:'number_small'
      },
      'ProductName':{
        name: 'Product Name',
        value:'ProductName',
        type:'autocomplete',
        className:'name_long'
      },
      'RowNo':{
        name: 'Row No',
        value:'RowNo',
        type:'string',
        className:'number_short'
      },
      'Capacity':{
        name: 'Capacity',
        value:'Capacity',
        type:'number',
        className:'number_short'
      },
      'OpeningBalance':{
        name: 'Opening Balance',
        value:'OpeningBalance',
        type:'number',
        className:'number_short'
      }
    }
    modalRef.componentInstance.itemArr = this.planogramData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAll();
    });
    modalRef.componentInstance.addData.subscribe((data) => {
      this.machineService.addPlanogram({
        MachineId:q.paramId,
        Planogram:data
      }).subscribe((responseMessage)=>{
        if (responseMessage.ErrorMessages[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Planogram Added/Updated' });
        }
        this.getAll();
      })
    });
  }
}
