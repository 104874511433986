import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { MasterLookupService } from './../../../services/common/master-lookup/master-lookup.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { EmployeeService } from '../../../services/Employee/employee.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-resource-detail',
  templateUrl: './resource-detail.component.html',
  styleUrls: ['./resource-detail.component.css']
})
export class ResourceDetailComponent implements OnInit {
  @Input() resourceData: any;
  resourceForm: UntypedFormGroup;
  public CategoryList: AutocompleteModel[];
  public EmployeeList: AutocompleteModel[];
  public TerritoryList: AutocompleteModel[];


  constructor(
    private configService: ConfigurationService,
    private masterLookupService: MasterLookupService,
    private employeeService: EmployeeService,
    private fb: UntypedFormBuilder,

  ) { }

  ngOnInit() {
    this.resourceForm = this.fb.group({
      ResourceID: null,
      Name: '',
      Code: '',
      CategoryId: '',
      TerritoryID: '',
      ManagerID: '',
      PhoneNo: '',
      MobileNo: '',
      EmailID: '',
    });
    this.getAllEmployeeList();
    this.getAllTerritoryList();
    this.getAllCategoryList();
    this.resourceForm.patchValue(this.resourceData);
  }
  getAllEmployeeList() {
    this.employeeService.getEmployeeList().subscribe(data => {
      this.EmployeeList = data.Result;
    });
  }

  getAllTerritoryList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }

  getAllCategoryList() {
    this.masterLookupService.getCategoryList().subscribe(data => {
      this.CategoryList = data;
    });
  }

}
