import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../../ApiService/api.service';
@Injectable()
export class MasterLookupService {
  constructor(private apiService: ApiService) { }
  getDropdownList(dropdowntype) {
    return this.apiService.get(
      `api/commonMasterLookup/GetCommonMasterLookupList?DropDownType=${dropdowntype}&`
    );
  }

  getCountryList() {
    return this.apiService.get(`api/commonMasterLookup/GetCountryLookupList?`);
  }

  getStateList(code) {
    return this.apiService.get(
      `api/commonMasterLookup/GetStateLookupList?CountryCode=${code}&`
    );
  }

  getDistrictList(stateCode, countrycode) {
    return this.apiService.get(
      `api/commonMasterLookup/GetDistrictLookupList?CountryCode=${countrycode}&StateCode=${stateCode}&`
    );
  }

  getTaxTypeList(Outward) {
    return this.apiService.get(`api/commonMasterLookup/GetTaxTypeLookupList?DropDownType=${Outward}&`);
  }

  getServiceList() {
    return this.apiService.get(`api/commonMasterLookup/GetServiceLookupList?`);
  }
  getFilteredServiceList(value){
    return this.apiService.get(`api/commonMasterLookup/GetServiceLookupList?text=${value}&`);
  }
  getMultipleAddressList() {
    return this.apiService.get(
      `api/commonMasterLookup/GetMultipleAddressLookupList?`
    );
  }

  getStageProbList(value) {
    return this.apiService.get(
      `api/commonMasterLookup/GetStageProbabilityList?StageId=${value}&`
    );
  }

  getGroupLookupList() {
    return this.apiService.get(`api/commonMasterLookup/GetGroupLookupList?&`);
  }

  getFileNameList() {
    return this.apiService.get(`api/printFileName/GetPrintFileNameList?&`);
  }

  getCategoryList() {
    return this.apiService.get('api/category/GetCategoryNamesList' + '?prefixText=' + '&Id=' + '&');
  }
}
