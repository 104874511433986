<div class="">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon target fa fa-bullseye">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Target</p>
        <!-- <p class="page_sub_title">{{editName}}</p> -->
      </h3>
    </div>
    <div class="pull-right">
      <div  class="buttonHeader pull-right">
        <div  class="buttonNew" (click)="open(addTarget)">New</div>
      </div>
    </div>
  </div>
  <div class="norm_table_margin">
    <app-htmldatatable [tableData]="targetData" [columnName]="columnName" [columnRowName]="columnRowName" (edit)="edit($event)"
      (onDelete)="onDelete($event)">
    </app-htmldatatable>
  </div>
</div>

<ng-template #addTarget let-modal>
  <div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Target</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="TargetForm">
      <div class="form-group row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">Related To</label>
        <div >
          <select class="form-control" formControlName="RelatedType" (change)="onRelated($event.target.value)">
            <option value="Branch">Branch</option>
            <option value="Sales">Sales Executive </option>
            <option value="Groups">Groups</option>
            <option value="Services">Services</option>
          </select>
        </div>
      </div>
  
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">{{relatedTypeName}}</label>
        <div >
          <mat-form-field class="example-full-width app-input-no-underline customMaterial">
            <input id="relatedTextInput" #relatedTextInput matInput [matAutocomplete]="auto" (input)="getFilterData($event.target.value)"
              formControlName="TerritoryID" [(ngModel)]="TerritoryID">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="relatedDisplayFn">
              <mat-option *ngFor="let related of relatedTypeFilterOption" [value]="related">
                <span>{{related.Text}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
  </div>
  <div class="form-group row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">Target Type</label>
        <div >
          <select class="form-control" formControlName="TargetType">
            <option value="Sales">Sales </option>
            <option value="Collection">Collection</option>
            <option value="Orders">Orders</option>
          </select>
        </div>
      </div>
  
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">Year</label>
        <div >
          <select class="form-control" formControlName="YearID">
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
            <option value="2028">2028</option>
            <option value="2029">2029</option>
            <option value="2030">2030</option>
          </select>
        </div>
      </div>
  </div>
  <div class="form-group row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">Currency</label>
        <div >
          <app-autocomplete [autoCompleteText]="currencyText" (setAutoComplete)="setAutoCompleteCurrency($event)"
            [autoCompleteValue]="currencyValue" (fiteredData)="getCurrencyList($event)" [filteredOptions]="currencyFilterOption">
          </app-autocomplete>
        </div>
      </div>
  
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="exchangeRate===true">
        <label class="control-label" for="text">Exchange Rate</label>
        <div>
          <input type="text" class="form-control" formControlName="ExchangeRate">
        </div>
      </div>
  
  </div>
      <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  
      <nav class="navbar navbar-default custom_navbar grey_back mt-5" >
        <div class="nav-bar-inner">
          <ul class="nav navbar-nav cl" routerLinkActive="active">
            <li class="nav-item" [ngClass]="{'active': addUser===true}">
              <a class="nav-link" (click)="OnAddUser()">Details</a>
            </li>
          </ul>
  
          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <div *ngIf="addUser===true">
                <div class="table_cont">
  
                  <table class="table table-striped table-bordered dynomic_table">
                    <thead>
                      <tr>
                        <th class="name_medium"><b>Month</b></th>
                        <th lass="amt_long"><b>Amount</b></th>
                        <th class="number_short"><b>Action</b></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let list of targetListForm.ListOfTarget; let i=index">
                        <td>
                          <select class="form-control" [(ngModel)]="list.MonthID" (change)="addFieldValue(i)"
                            [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let month of MonthList" value={{month.Value}}>{{month.Text}}</option>
                          </select>
                        </td>
                        <td>
                          <input class="form-control" type="text" [(ngModel)]="list.Amount" (input)=addFieldValue(i)
                            (keypress)="numberOnly($event)" [ngModelOptions]="{standalone: true}" />
                        </td>
                        <td>
                          <div class="btn-group" dropdown>
                            <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
                            <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
                          </div>
                        </td>
                      </tr>
  
  
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div class="form-group text-right">
        <button type="button" class="btn btn-default" (click)="resetForm()">Cancel</button>
        <button type="button" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
      </div>
    </form>
  </div>
</ng-template>
<!-- <div class="bg_white_margin_top min_top">
  
</div> -->