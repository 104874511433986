<div class="body_inner">
    <div class="">
        <div class="row">
            <div class="topheader col-md-12">
                <div class="topheadertitle">
                    <span class="menu_icon CRMCampaigns glyphicon glyphicon-bullhorn"></span>
                    <h3 class="report_page_title">
                        <p class="page_title_cont">Machine</p>
                        <p class="page_sub_title">{{machineDetials?.MachineName}}</p>
                    </h3>
                </div>
                <div>
                    <div class="topdownheader ">
                        <div class="block">
                            <label class="labelName">Type</label>
                            <p>{{machineDetials?.TypeName}}</p>
                        </div>
                        <div class="block">
                            <label class="labelName">Code</label>
                            <p>{{machineDetials?.Code}}</p>
                        </div>
                        <div class="block">
                            <label class="labelName">Key</label>
                            <p>{{machineDetials?.Key}}</p>
                        </div>
                        <div class="block">
                            <label class="labelName">Model</label>
                            <p>{{machineDetials?.Model}}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row ">
            <div class="col-md-8 lowpadding-right">
                <nav class="navbar navbar-default custom_navbar mt-3">
                    <div class="nav nav-bar-inner">
                        <ul>
                            <li class="realted">
                                <a class="" (click)="selectTab()"
                                    [ngClass]="{'active': selectedTab==='Planogram'}">Planogram</a>
                            </li>
                        </ul>
                    </div>
                    <ul class="cl custom_content_tab">
                        <li class="nav-item">
                            <app-planogram *ngIf="selectedTab==='Planogram'"
                                [searchKeys]="searchKeys"></app-planogram>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-4 bg_white_margin_top no_boxshadow">
                <nav class="navbar navbar-default custom_navbar">
                    <div class="nav nav-bar-inner">
                        <ul>
                            <li class="realted">
                                <a class="" [ngClass]="{'active': activityOn===true}">Activity</a>
                            </li>
                        </ul>
                    </div>
                    <ul class="cl custom_content_tab">
                        <li class="nav-item">
                            <app-activity *ngIf="activityOn===true"></app-activity>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
</div>