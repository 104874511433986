interface Scripts {
    name: string;
    src: string;
}
export const ScriptStore: Scripts[] = [
    // { name: 'skype', src: 'http://swc.cdn.skype.com/sdk/v1/sdk.min.js' },
    { name: 'google', src: 'https://apis.google.com/js/api.js' },
    { name: 'jquery', src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js' },
    { name: 'googlemaps', src: 'https://maps.googleapis.com/maps/api/js?' },
    { name: 'fontawesome', src: 'https://use.fontawesome.com/releases/v5.0.10/js/all.js' },
    { name: 'popper', src: 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.3/umd/popper.min.js' },
    { name: 'bootstrap', src: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta.2/js/bootstrap.min.js' },
    // { name: 'skypejs', src: '../../../../../lib/skype/index.js' }

    // { name: 'skypejs', src: './lib/skype/index.js' }
];
