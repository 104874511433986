import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TargetService } from '../../../services/target/target.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { DeleteModalComponent } from './../../../../../common/delete-modal/delete-modal.component';
import { GlobalService } from '../../../services/global-Service/global.service';
import { ModalDirective } from 'ngx-bootstrap/modal';


declare var $: any;

@Component({
  selector: 'app-collection-target',
  templateUrl: './collection-target.component.html',
  styleUrls: ['./collection-target.component.css']
})
export class CollectionTargetComponent implements OnInit {
  @ViewChild('relatedTextInput') relatedTextInput: ElementRef;
  @ViewChild('currencyTextInput') currencyTextInput: ElementRef;
  @Input() deleteData: any;
  @ViewChild('addTarget') addTarget: ModalDirective;

  TargetForm: UntypedFormGroup;
  MonthList: AutocompleteModel[];
  relatedOption: any;
  targetListData: any;
  showSpinner: Boolean = true;
  addUser = true;
  relatedTypeName = 'Branch';
  targetListForm: TargetListForm;
  listOfTargetObj: ListOfTargetObj;
  public targetData: Array<any> = [];
  targetList: any;
  currencyFilterOption: any[];
  columnName = []; columnRowName = [];
  currencyText: any; currencyValue: any;
  relatedTypeFilterOption: any[];
  relatedText: any; relatedValue: any;
  editName = '';
  currencyExchangeRate: any;
  exchangeRate: Boolean = false;
  addTargetModal:any;

  constructor(
    private targetService: TargetService,
    private modalService: NgbModal,
    private autoCompleteService: AutocompleteService,
    private masterLookupService: MasterLookupService,
    private fb: UntypedFormBuilder,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.TargetForm = this.fb.group({
      TargetID: null,
      TargetType: '',
      RelatedType: '',
      YearID: '',
      CurrencyID: '',
      TerritoryID: '',
      ExchangeRate: '',
      ListOfTarget: new UntypedFormGroup({
        MonthID: new UntypedFormControl(),
        Amount: new UntypedFormControl(),
      }),
    });
    this.getAllTarget();
    this.columnName = ['Target Type', 'Amount', 'Branch'];
    this.columnRowName = ['TargetType', 'TotalAmount', 'TerritoryName'];
    this.resetTargetListForm();
    this.resetListOfTargetObj();
    this.targetListForm.ListOfTarget.push(this.listOfTargetObj);
    this.getAllMonthList();
    this.getSessionData();
  }

  getAllMonthList() {
    this.masterLookupService.getDropdownList('Months').subscribe(data => {
      this.MonthList = data;
    });
  }

  addFieldValue(i) {
    this.resetListOfTargetObj();
    const obj = this.targetListForm.ListOfTarget[i + 1];
    if (!obj) {
      this.targetListForm.ListOfTarget.push(this.listOfTargetObj);
    }
    this.resetListOfTargetObj();
  }
  deleteFieldValue(i: number) {
    if (this.targetListForm.ListOfTarget.length === 1) {
      return false;
    } else {
      this.targetListForm.ListOfTarget.splice(i, 1);
      return true;
    }
  }

  open(content) {
    this.addTargetModal = this.modalService.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }
  getAllTarget() {
    this.targetService.getTarget().subscribe(data => {
      this.targetData = data;
      this.showSpinner = false;
    });
  }

  edit(editData) {
    this.reset();
    this.editName = editData.data.TargetType;
    this.targetService.TargetById(editData.data.TargetID).subscribe(data => {
      this.addTargetModal = this.modalService.open(this.addTarget, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      })
      this.targetListData = data;
      this.targetList = data.ListOfTarget;
      this.TargetForm.patchValue({
        TargetType: data.TargetType,
        YearID: data.YearID,
        TargetID: data.TargetID,
        RelatedType: data.RelatedType,
        TerritoryID:data.TerritoryID
      });
      this.onRelated(data.RelatedType);

      this.currencyText = data.CurrencyName;
      this.currencyValue = data.CurrencyID;

      const relatedObj = {
        Text: data.TerritoryName,
        Value: data.TerritoryID,
      };
      // this.relatedTextInput.nativeElement.value = relatedObj.Text;
      setTimeout(()=>{
        (document.getElementById('relatedTextInput') as HTMLInputElement).value  = relatedObj.Text;
      },10)
      this.relatedDisplayFn(relatedObj);

      this.targetList.map((obj) => {
        this.targetListForm['ListOfTarget'].push(obj);
      });
      this.targetListForm['ListOfTarget'].push(this.listOfTargetObj);
      this.resetListOfTargetObj();
    });
    
  }

  onDelete(data) {
    this.deleteData = ({ id: data.data.TargetID, name: data.data.TargetType, page: 'Target' });
    const modalRef = this.modalService.open(DeleteModalComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.deleteData = this.deleteData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAllTarget();
    });
  }

  onRelated(value) {
    this.relatedTypeFilterOption = [];
    if (value === 'Sales') {
      this.relatedTypeName = 'Sales Executive';
    } else {
      this.relatedTypeName = value;
    }
  }

  getFilterData(textValue) {
    if (textValue.length > 1) {
      this.autoCompleteService.getBranchRelated(textValue, this.relatedTypeName).subscribe(data => {
        this.relatedTypeFilterOption = data;
      }, error => {
        console.log('Error', error);
      });
    }
  }

  relatedDisplayFn(related: any) {
    this.relatedOption = related;
    return related ? related.Text : '';
  }


  getSessionData() {
    const sessionUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.currencyText = sessionUser.CurrencyCode;
    this.currencyValue = sessionUser.CurrencyId;
    this.currencyExchangeRate = sessionUser.CurrencyId;
    this.TargetForm.controls['CurrencyID'].setValue(this.currencyValue);
  }

  getCurrencyList(value) {
    this.autoCompleteService.getallCurrencyList(value.data).subscribe(
      res => {
        this.currencyFilterOption = res;
      },
      err => {
        console.log('errr====', err);
      });
  }


  setAutoCompleteCurrency(data) {
    const currencyId = data.data.option.value.Value;
    this.TargetForm.controls['CurrencyID'].setValue(currencyId);
    if (this.currencyExchangeRate !== currencyId) {
      this.exchangeRate = true;
    } else {
      this.exchangeRate = false;
    }
  }

  numberOnly(event) {
    const keycode = (event.which) ? event.which : event.keyCode;
    if (!(keycode === 8 || keycode === 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      const parts = event.srcElement.value.split('.');
      if (parts.length > 1 && keycode === 46) {
        return false;
      }
      return true;
    }
  }



  onSubmit() {
    let responseMessage = [];
    const targetType = this.TargetForm.value.TargetType;
    let territoryId;
    if (this.TargetForm.value.TerritoryID === null || this.TargetForm.value.TerritoryID === '') {
      territoryId = this.targetListData.TerritoryID;
    } else {
      territoryId = this.TargetForm.value.TerritoryID.Value;
    }
    this.targetListForm.ListOfTarget = this.targetListForm.ListOfTarget.filter(
      obj => {
        return obj.MonthID || obj.Amount;
      }
    );

    this.TargetForm.value.TerritoryID = territoryId;
    this.TargetForm.value.ListOfTarget = this.targetListForm.ListOfTarget;

    if (this.TargetForm.value.TargetID === null) {
      this.targetService.addTarget(this.TargetForm.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[2] === '0') {
          this.globalService.showSuccess({ masterName: 'Target', name: targetType });
          this.showSpinner = true;
          this.getAllTarget();
          this.addTargetModal.close();
        }
      });
    } else {
      this.targetService.updateTarget(this.TargetForm.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[2] === '0') {
          this.globalService.showUpdate({ masterName: 'Target', name: targetType });

          this.showSpinner = true;
          this.getAllTarget();
        }
        this.TargetForm.reset();
        this.resetForm();
        this.addTargetModal.close();
        this.globalService.disposeToastr();
      });
    }
  }

  resetForm() {
    this.resetTargetListForm();
    this.resetListOfTargetObj();
    this.targetListForm.ListOfTarget.push(this.listOfTargetObj);
  }

  reset() {
    this.resetTargetListForm();
    this.resetListOfTargetObj();
  }

  OnAddUser() {
    this.addUser = true;
  }

  resetListOfTargetObj() {
    this.listOfTargetObj = {
      MonthID: '',
      Amount: '',
    };
  }
  resetTargetListForm() {
    this.targetListForm = {
      ListOfTarget: [],
    };
  }
}


declare class ListOfTargetObj {
  MonthID: '';
  Amount: '';
}

declare class TargetListForm {
  ListOfTarget: Array<any>;
}
