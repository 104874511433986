import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public loadBar = new Subject();
  currentLoadBar = this.loadBar.asObservable();

  constructor() { }

  changeLoaderStatus(message: string) {
    this.loadBar.next(message);
  }
}
