import { ProjectTaskService } from './../../../services/projectTask/ProjectTask-Service/project-task.service';
import { EmployeeService } from './../../../services/Employee/employee.service';
import { CompanyList } from './../../../model/companyListModel/company-list';

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-sales-order',
  templateUrl: './sales-order.component.html',
  styleUrls: ['./sales-order.component.css']
})
export class SalesOrderComponent implements OnInit {

  userForm: UntypedFormGroup;
  filteredUsers: any;
  selectedOption: any;
  employeeData: any;

  @ViewChild('textInput') textInput: ElementRef;
  isLoading = false;

  constructor(
    public fb: UntypedFormBuilder,
    private employeeService: EmployeeService,
    private projectService: ProjectTaskService
  ) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      userInput: ""
    });
    this.getProjectData();
    this.selectedOption = {};
  }

  _filterStates(value: string) {
    this.selectedOption = {};
    this.employeeService.searchUser(value).subscribe(
      res => {
        this.filteredUsers = res.Result;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }

  getProjectData() {
    this.projectService
      .projectTaskById('339C2778-997B-E811-82B5-7054D27BDE71')
      .subscribe(data => {
        console.log('data', data);
        this.employeeData = data;
        const obj = {
          Text: this.employeeData.OwnerName,
          Value: this.employeeData.AssignedTo,
        };
        this.textInput.nativeElement.value = obj.Text;
        this.selectedOption = obj;
        this.displayFn(obj);
      });
  }

  displayFn(user: CompanyList) {
    this.selectedOption = user;
    return user ? user.Text : '';
  }

  onSubmit() {
    // console.log("data==", this.usersForm.value.userInput.Value);
  }
}
