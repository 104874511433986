import { VariableService } from './../../product/crm/services/VariableService/variable.service';
import { DeleteService } from './../../product/crm/services/deleteService/delete.service';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {
  @Output() getData = new EventEmitter();
  @Input() deleteData: any;
  MasterType: any;
  pageName: any;
  constructor(
    private variableService: VariableService,
    public activeModal: NgbActiveModal,
    private deleteService: DeleteService,
    public toastr: ToastrService,
  ) { }

  ngOnInit() {
    const q: any = this.variableService.getSearchKeys();
    if (this.deleteData.page === 'Target') {
      this.MasterType = 'target';
      this.pageName = 'Target';
    } else if (this.deleteData.page === 'UserProfile') {
      this.MasterType = 'user';
      this.pageName = 'User';
    } else if (this.deleteData.page === 'Role') {
      this.MasterType = 'role';
      this.pageName = 'Role';
    } else if (this.deleteData.page === 'Territory') {
      this.MasterType = 'territory';
      this.pageName = 'Territory';
    } else if (this.deleteData.page === 'ProjectTaskTemplate') {
      this.MasterType = 'task template';
      this.pageName = 'Task template';
    } else if (this.deleteData.page === 'MultipleAddress') {
      this.MasterType = 'address';
      this.pageName = 'Multiple address';
    } else {
      this.pageName = q.type.slice(3);
      this.MasterType = this.pageName.toLowerCase();
    }
  }

  delete() {
    const q: any = this.variableService.getSearchKeys();
    let responseMessage = [];
    const masterName = this.deleteData.name;
    const masterId = this.deleteData.id;
    let masterType = '';

    if (this.deleteData.page === 'Target') {
      masterType = 'CRMTarget';
    } else if (this.deleteData.page === 'UserProfile') {
      masterType = 'UserProfile';
    } else if (this.deleteData.page === 'Role') {
      masterType = 'Role';
    } else if (this.deleteData.page === 'Territory') {
      masterType = 'Territory';
    } else if (this.deleteData.page === 'ProjectTaskTemplate') {
      masterType = 'TaskTemplate';
    } else if (this.deleteData.page === 'MultipleAddress') {
      masterType = 'MultipleAddress';
    } else {
      masterType = q.type;
    }

    this.deleteService.deleteMasterData(masterType, masterId).subscribe(deletedata => {
      responseMessage = deletedata.ErrorMessages;
      if (responseMessage[1] === '0') {
        this.toastr.success(this.pageName + ' ' + '"' + masterName + '"' + ' ' + 'was deleted.');
      } else {
        this.toastr.error('Error has occured...!');
      }
      this.getData.emit();
    });
    this.activeModal.close();
    this.toastr.clear();
  }
}
