import { AccountService } from '../../../services/accounts/account.service';
import { Component, OnInit, Input } from '@angular/core';
import { ContactService } from '../../../services/contact/contact.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-case-contact-detail',
  templateUrl: './case-contact-detail.component.html',
  styleUrls: ['./case-contact-detail.component.css']
})
export class CaseContactDetailComponent implements OnInit {

  contactCaseData: any;
  acccountCaseData: any;
  constructor(
    private contactService: ContactService,
    private accountService: AccountService,
    public router: Router,
  ) { }

  ngOnInit() {
  }


  getAllCaseContact(contactId) {
    if (contactId !== null) {
      this.contactService.getContactById(contactId, 'contact').subscribe(data => {
        this.contactCaseData = data;
      });
    }
  }

  getAllCaseAccount(accountId) {
    if (accountId !== null) {
      this.accountService.getByAccountId(accountId, 'account').subscribe(data => {
        this.acccountCaseData = data;
      });
    }
  }
  gotoAccountPage(id) {
    const route = 'accountDetail/' + id;
    this.router.navigate([route]);
  }

  gotoContactPage(id) {
    const route = 'contactDetail/' + id;
    this.router.navigate([route]);
  }

}
