import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class CampaignService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  private isEmailCode = new Subject<boolean>();
  public emailCode = '';

  emailCodeReceived(): Observable<any> {
    // this.emailCode = emailCode;
    return this.isEmailCode.asObservable();
  }

  setEmailCode(emailCode) {
    this.emailCode = emailCode;
    this.isEmailCode.next(true);
  }

  getEmailCode() {
    return this.emailCode;
  }

  getTypeCampaigns() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/campaign/getall?BaseId=${q.paramId}&MasterType=${q.type}&`
    );
  }
  // Create Method for campaign
  addCampaign(form) {
    return this.apiService.post('api/campaign/create', form);
  }

  // Edit Method By Id
  editByCampaignId(campaignId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/campaign/getbyid?id=${campaignId}&MasterType=${q.type}&`
    );
  }

  // Update Method for Campaign
  updateCampaign(form) {
    return this.apiService.put('api/campaign/edit', form);
  }

  // getCamapignData(reqOptions: any) {
  //   // const q: any = this.variableService.getSearchKeys();
  //   return this.apiService.post(`api/mastertable/data`, reqOptions);
  // }

  // getColumnData(type: string) {
  //   // const q: any = this.variableService.getSearchKeys();
  //   return this.apiService.post(`api/mastertable/columns`, {
  //     MasterType: type,
  //     PageName: type
  //   });
  // }

  sendEmailCode(code: string) {
    return this.apiService.post('api/emailclient/profile', { code: code });
  }

  scheduler(form) {
    return this.apiService.post('api/campaign/scheduler', form);
  }
}
