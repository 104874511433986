import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class RolePermissionService {

  constructor(private apiService: ApiService,
    private variableService: VariableService
  ) { }

  // Post Method
  addRolePermission(form) {
    return this.apiService.post('api/rolePermission/create', form);
  }

  // Edit Method By Id
  editRolePermissionById(roleId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/rolePermission/getall?Id=${roleId}&MasterType=${q.type}&`);
  }

}
