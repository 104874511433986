<div class="body_inner">
  <div class="">
    <div class="topheader">

      <div class="topheadertitle">
        <span class="menu_icon CRMProjects fa fa-cubes">
        </span>
        <h3 class="report_page_title">
          <p class="page_title_cont">Project</p>
          <p class="page_sub_title">{{projectData?.ProjectName}}</p>
        </h3>
      </div>
      <div class="">
        <div class="topdownheader ">
              <div class="">
                <div class="block">
                    <label class="labelName">Client Name</label>
                    <p>{{projectData.ClientName}}</p>
                </div>
                <div class="block">
                    <label class="labelName">Project No</label>
                    <p>{{projectData.ProjectNo}}</p>
                </div>
                <div class="block">
                    <label class="labelName">Client Project No</label>
                    <p>{{projectData.ClientProjectNo}}</p>
                </div>

                <div class="block">
                    <label class="labelName">Start Date</label>
                    <p>
                        {{projectData.StartDate | date:'dd/MM/yyyy'}}
                    </p>
                </div>
                <div class="block">
                  <label class="labelName">End date</label>
                  <p>
                      {{projectData.EndDate | date:'dd/MM/yyyy'}}
                  </p>
                </div>
                <div class="block">
                    <label class="labelName">Owner</label>
                    <p>
                        {{projectData.OwnerName}}
                    </p>
                </div>

              </div>
          </div>
      </div>
    </div>
  </div>
  <div class="row ">
    <div class="col-md-8 lowpadding-right">
      <nav class="navbar navbar-default custom_navbar">
        <div class="nav nav-bar-inner">
          <ul>
            <li class="realted">
              <a class="" (click)="OnRelated()" [ngClass]="{'active': RelatedOn===true}">Milestones</a>
            </li>
            <li class="realted">
              <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Details</a>
            </li>
            <li class="realted">
              <a class="" (click)="onKickOff()" [ngClass]="{'active': KickOff===true}">KickOff Details</a>
            </li>
            <li class="realted" *ngIf="natureOfWork">
              <a class="" (click)="onOrder()" [ngClass]="{'active': Order===true}">Orders</a>
            </li>
            <li class="related" *ngIf="natureOfWork">
              <a (click)="onInvoiceOrderClick()" [ngClass]="{'active':invoiceOrder===true}">Pending Orders</a>
            </li>
            <li class="related" *ngIf="natureOfWork">
              <a (click)="onInvoiceClick()" [ngClass]="{'active':invoice===true}">Invoices</a>
            </li>
          </ul>
        </div>

        <ul class="cl custom_content_tab">
          <li class="nav-item disabled_form">
            <app-project-detail [projectData]="projectData" *ngIf="DetailOn===true &&projectData.ProjectId">
            </app-project-detail>
          </li>

          <li class="nav-item">
            <app-milestone-related [searchKeys]="searchKeys" [milestoneProjectData]="milestoneProjectData"
              *ngIf="RelatedOn===true"></app-milestone-related>
          </li>

          <li class="nav-item">
            <app-kick-off-detail [kickOffdata]="kickOffdata" *ngIf="KickOff===true"></app-kick-off-detail>
          </li>

          <li class="nav-item">
            <app-project-order *ngIf="Order===true"></app-project-order>
          </li>

          <li class="nav-item">
            <app-invoice-order *ngIf="invoiceOrder===true"></app-invoice-order>
          </li>

          <li class="nav-item">
            <app-project-invoice *ngIf="invoice===true"></app-project-invoice>
          </li>

        </ul>

      </nav>
    </div>
    <div class="col-md-4 bg_white_margin_top no_boxshadow">
      <nav class="navbar navbar-default custom_navbar">
        <div class="nav nav-bar-inner">
          <ul>
            <li class="realted">
              <a class="" (click)=onActivity() [ngClass]="{'active': activityOn===true}">Activity</a>
            </li>

            <li class="activity">
              <a class="" (click)=OnChatter() [ngClass]="{'active': NewChatterOn===true}">Chatter</a>
            </li>
          </ul>
        </div>
        <ul class="cl custom_content_tab">
          <li class="nav-item">
            <app-activity *ngIf="activityOn===true"></app-activity>
          </li>
          <li class="nav-item">
            <app-chatter *ngIf="NewChatterOn===true"></app-chatter>
          </li>
        </ul>
      </nav>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
</div>