import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';
@Injectable()
export class OpportunityService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  getTypeOpportunities() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/opportunity/getall?BaseId=${q.paramId}&MasterType=${q.type}&`);
  }
  addOpportunity(form) {
    return this.apiService.post('api/opportunity/create', form);
  }

  editOpportunityById(opportunityId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/opportunity/getbyid?Id=${opportunityId}&MasterType=${q.type}&`);
  }

  getOpportunityById(opportunityId, Opportunity) {
    return this.apiService.get(`api/opportunity/getbyid?Id=${opportunityId}&MasterType=${Opportunity}&`);
  }


  // Edit Method for Opportunity
  updateOpportunity(form) {
    return this.apiService.put('api/opportunity/edit', form);
  }



  /**  Opportunity Status*/

  getAllOpportunityStage() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/opportunitystatus/getall?id=${q.paramId}&`);
  }
  // Get all method for OpportunityStatus By Id
  getByIdStatus(OpportunityId) {
    return this.apiService.get(
      'api/opportunitystatus/getbyid' + '?Id=' + OpportunityId + '&'
    );
  }
  getOpportunityStageList() {
    return this.apiService.get('api/opportunitystatus/GetOpportunityStatusNamesList' + '?prefixText=' + '&Id' + '&');
  }
  // Create method for OpportunityStatus
  createOpportunityStatus(form) {
    return this.apiService.put('api/opportunitystatus/create', form);
  }

  createOpportunityCloseStatus(form) {
    return this.apiService.post('api/opportunitystatus/opporCloseStatusCreate', form);
  }
}
