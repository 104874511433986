import { ProjectOrderService } from '../../../services/projectOrder/project-order.service';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { ProjectService } from '../../../services/project/project.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/modal/modal';
import { VariableService } from '../../../services/VariableService/variable.service';
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  searchKeys: object = {};
  projectData: any;
  milestoneProjectData: any;
  natureOfWork: Boolean = true;
  kickOffdata: any;
  DetailOn = false;
  RelatedOn = true;
  NewChatterOn = false;
  activityOn = true;
  showSpinner: Boolean = true;
  KickOff = false;
  projectId: any;
  Order = false;
  invoiceOrder = false;
  invoice = false;
  selectedPage: any = {};
  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMProject',
      });
      this.disableTab();
      this.projectId = params.id;
      this.projectService.projectById(params.id).subscribe(data => {
        this.projectData = data;
        this.kickOffdata = data;
        this.milestoneProjectData = data;
        this.showSpinner = false;
      });
    });
  }

  disableTab() {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    if (user.NatureOfWork === 'true') {
      this.natureOfWork = true;
    } else {
      this.natureOfWork = false;
    }
  }
  OnDetail() {
    this.DetailOn = true;
    this.RelatedOn = false;
    this.KickOff = false;
    this.Order = false;
    this.invoiceOrder = false;
  }
  OnRelated() {
    this.DetailOn = false;
    this.RelatedOn = true;
    this.KickOff = false;
    this.Order = false;
    this.invoiceOrder = false;
    this.invoice = false;
  }
  onKickOff() {
    this.DetailOn = false;
    this.RelatedOn = false;
    this.KickOff = true;
    this.Order = false;
    this.invoiceOrder = false;
    this.invoice = false;
  }
  onOrder() {
    this.DetailOn = false;
    this.RelatedOn = false;
    this.KickOff = false;
    this.Order = true;
    this.invoiceOrder = false;
    this.invoice = false;
  }

  onInvoiceOrderClick() {
    this.DetailOn = false;
    this.RelatedOn = false;
    this.KickOff = false;
    this.Order = false;
    this.invoiceOrder = true;
    this.invoice = false;
  }

  onInvoiceClick() {
    this.DetailOn = false;
    this.RelatedOn = false;
    this.KickOff = false;
    this.Order = false;
    this.invoiceOrder = false;
    this.invoice = true;
  }


  OnChatter() {
    this.NewChatterOn = true;
    this.activityOn = false;
    this.Order = false;
  }
  onActivity() {
    this.NewChatterOn = false;
    this.activityOn = true;
  }

}
