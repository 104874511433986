import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from './../ApiService/api.service';

@Injectable()
export class AutocompleteService {
  constructor(
    private apiService: ApiService
  ) {
  }

  getAccountList() {
    return this.apiService.get('api/account/GetAccountNamesList' + '?prefixText=' + '&Id=' + '&');
  }

  getAllAccountList(text) {
    return this.apiService.get(`api/account/GetAccountNamesList?Text=${text}&`);
  }

  getAllCampaignList(text) {
    return this.apiService.get(`api/campaign/GetCampaignNamesList?Text=${text}&`);
  }

  getAllContactAccountList(text, AccountId) {
    return this.apiService.get(`api/contact/GetContactNamesList?Text=${text}&RelatedId=${AccountId}&`);
  }

  getAllContactList(text) {
    return this.apiService.get(`api/contact/GetContactNamesList?Text=${text}&`);
  }

  getAllProjectList(text) {
    return this.apiService.get(`api/project/GetProjectNamesList?Text=${text}&`);
  }

  getAllProductList(text) {
    return this.apiService.get(`api/product/GetProductNamesList?Text=${text}&`);
  }

  getAllMachineList(text) {
    return this.apiService.get(`api/machine/GetMachineNamesList?Text=${text}&`);
  }

  getProjectAccountList(text, clientid) {
    return this.apiService.get(`api/project/GetProjectNamesList?Text=${text}&RelatedId=${clientid}&`);
  }

  getMilestoneProjectList(text, projectId) {
    return this.apiService.get(`api/milesstone/GetMilesStoneNamesList?Text=${text}&RelatedId=${projectId}&`);
  }

  getRoleNameList() {
    return this.apiService.get('api/role/GetRoleNamesList' + '?Text=' + '&Id=' + '&');
  }

  getAllOpportunityList(text) {
    return this.apiService.get(`api/opportunity/GetOpportunityNamesList?Text=${text}&`);
  }

  getQuoteOpportunityList(opportunityId, text) {
    return this.apiService.get(`api/quote/GetQuoteNamesList?RelatedId=${opportunityId}&Text=${text}&`);
  }

  getCompanyList() {
    return this.apiService.get('api/userProfile/GetCompanyNamesList' + '?prefixText=' + '&Id=' + '&');
  }

  getAllClientList(text) {
    return this.apiService.get(`api/clientLookup/GetClientLookupNamesList?Text=${text}&`);
  }

  getAllEmployeeList(text, type) {
    return this.apiService.get(`api/employee/GetEmployeeCRMNamesList?Text=${text}&MasterType=${type}&`);
  }

  getAllEmailTemplateList(text, type) {
    return this.apiService.get(`api/emailtemplate/GetEmailTemplateNamesList?Text=${text}&MasterType=${type}&`);
  }

  getallCurrencyList(text) {
    return this.apiService.get(`api/countryCurrency/GetCountryCurrencyList?Text=${text}&`);
  }


  commonAutocomplete(optionType, text) {
    if (optionType === 'user') {
      return this.apiService.get(`api/commonMasterLookup/GetEmployeeList?Text=${text}&`);
    } else if (optionType === 'contact') {
      return this.apiService.get(`api/contact/GetContactNamesList?Text=${text}&`);
    }
  }

  getLeadContactList(optionValue, text) {
    if (optionValue === 'Lead') {
      return this.apiService.get(`api/lead/GetLeadNamesList?Text=${text}&`);
    } else if (optionValue === 'Contact') {
      return this.apiService.get(`api/contact/GetContactNamesList?Text=${text}&`);
    }
  }

  getAllRelatedTypeList(relatedValue, text) {
    if (relatedValue === 'Campaign') {
      return this.apiService.get(`api/campaign/GetCampaignNamesList?Text=${text}&`);
    } else if (relatedValue === 'Account') {
      return this.apiService.get(`api/account/GetAccountNamesList?Text=${text}&`);
    } else if (relatedValue === 'Opportunity') {
      return this.apiService.get(`api/opportunity/GetOpportunityNamesList?Text=${text}&`);
    } else if (relatedValue === 'Product') {
      return this.apiService.get(`api/product/GetProductNamesList?Text=${text}&`);
    } else if (relatedValue === 'Contract') {
      return this.apiService.get(`api/contract/GetContractNamesList?Text=${text}&`);
    } else if (relatedValue === 'Quote') {
      return this.apiService.get(`api/quote/GetQuoteNamesList?Text=${text}&`);
    } else if (relatedValue === 'Milestone') {
      return this.apiService.get(`api/milesstone/GetMilesStoneNamesList?Text=${text}&`);
    } else if (relatedValue === 'Project') {
      return this.apiService.get(`api/project/GetProjectNamesList?Text=${text}&`);
    } else if (relatedValue === 'ProjectTask') {
      return this.apiService.get(`api/projecttask/GetProjectTaskNamesList?Text=${text}&`);
    } else if (relatedValue === 'Client') {
      return this.apiService.get(`api/clientLookup/GetClientLookupNamesList?Text=${text}&`);
    } else if (relatedValue === 'Case') {
      return this.apiService.get(`api/case/GetCaseNamesList?Text=${text}&`);
    }
  }

  getMasterTypeListData(relatedType, masterId) {
    if (relatedType === 'CRMCampaign') {
      return this.apiService.get(`api/campaign/GetCampaignNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMAccount') {
      return this.apiService.get(`api/account/GetAccountNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMOpportunity') {
      return this.apiService.get(`api/opportunity/GetOpportunityNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMProduct') {
      return this.apiService.get(`api/product/GetProductNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMContract') {
      return this.apiService.get(`api/contract/GetContractNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMQuote') {
      return this.apiService.get(`api/quote/GetQuoteNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMMilestone') {
      return this.apiService.get(`api/milesstone/GetMilesStoneNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMProject') {
      return this.apiService.get(`api/project/GetProjectNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMProjectTask') {
      return this.apiService.get(`api/projecttask/GetProjectTaskNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMClient') {
      return this.apiService.get(`api/clientLookup/GetClientLookupNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMCase') {
      return this.apiService.get(`api/case/GetCaseNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMLead') {
      return this.apiService.get(`api/lead/GetLeadNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMContact') {
      return this.apiService.get(`api/contact/GetContactNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMMachines') {
      return this.apiService.get(`api/machine/GetMachineNamesList?Id=${masterId}&`);
    } else if (relatedType === 'CRMLocations') {
      return this.apiService.get(`api/location/GetLocationNamesList?Id=${masterId}&`);
    }
  }

  getBranchRelated(text, relatedValue) {
    if (relatedValue === 'Branch') {
      return this.apiService.get(`api/territory/GetTerritoryNamesList?Text=${text}&`);
    } else if (relatedValue === 'Sales Executive') {
      return this.apiService.get(`api/commonMasterLookup/GetEmployeeList?Text=${text}&`);
    } else if (relatedValue === 'Groups') {
      return this.apiService.get(`api/commonMasterLookup/GetGroupLookupList?Text=${text}&`);
    } else if (relatedValue === 'Services') {
      return this.apiService.get(`api/commonMasterLookup/GetServiceLookupList?`);
    }
  }
  getUserDetails(){
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }
}
