<mat-form-field class="example-full-width app-input-no-underline customBorder">
    <input #textInput matInput [matAutocomplete]="auto" (input)="setfiteredData({data:$event.target.value, indexValue:key})">
    <!-- <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon> -->
    <mat-icon class="aggregator-arrow-selector rotating" matSuffix *ngIf="progress">
        cached
      </mat-icon>
    <mat-icon class="aggregator-arrow-selector" matSuffix (click)="setfiteredData({data:$event.target.value || '' , indexValue:key||0})">
        filter_list
      </mat-icon>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="setAutoComplete.next({data: $event, index: key})">
        <mat-option *ngFor="let user of filteredOptions" [value]="user">
            <span>{{user.Text}}</span>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>