<div class="">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon CRMTasks glyphicon glyphicon-list-alt">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Tasks</p>
        <div class="dropdown drop_list_items">
          <page-filter [selectedPageFilter]="selectedPageFilter" [pageFilters]="pageFilters" (getPageFilterData)="getPageFilterData($event)">
          </page-filter>
        </div>
      </h3>
    </div>
    <div class="buttonHeader pull-right">
      <div class="buttonNew" *ngIf="selectedPage.roles.indexOf('create') !== -1" (click)="openTask()">New</div>
      <div class="buttonEditDelete" (click)="statusUpdate()">Status Update</div>
    </div>
  </div>

  <custom-table-header [commonFilterCMP]="commonFilterCMP" [selectedPage]="selectedPage" [tableData]="tasks" [columns]="columnNames"
    [createNewFilter]="createNewFilter" (toggle)="toggle()">
  </custom-table-header>

  <custom-table (getData)="getTaskData($event)" [columns]="columnNames" [rows]="tasks" [page]="page" [showFilter]="showFilter"
    (edit)="editopen($event)" (onDelete)="onDelete($event)" (onSelect)="onSelectCheckBox($event)" [searchStr]="searchStr"
    (setSearchStr)="setSearchStr($event)">
  </custom-table>

  <!-- Table Filters -->
  <table-filter *ngIf='showFilter' (applyFilter)="applyFilter($event)" [filterArray]="filterArray" (toggleFilter)="toggle($event)"
    [cols]="columnNames" [filterColumns]="filterObj" [filterLogic]="filterLogic" (setFilterLogic)="applyFilterLogic($event)"
    [isFilterLogic]="isFilterLogic" (addLogic)="addLogic()" (removeLogic)="removeLogic()">
  </table-filter>


  <ng-template #commonFilterCMP>
    <column-filter [columns]="columnNames" (setColumnsVisible)="makeColumnsVisible($event)" (closeModal)="closeModal()"></column-filter>
  </ng-template>


  <!-- Page Filter New -->
  <ng-template #createNewFilter let-c="close" let-d="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-header">
      <h4 class="modal-title text-center">New List View</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">
            <span class="text-danger">*</span>List Name</label>
          <input type="textbox" class="form-control" [(ngModel)]="newListName">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <div class="radio">
            <label>
              <input type="radio" name="optradio" value="0" [(ngModel)]="AccessType">Private</label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="optradio" value="1" [(ngModel)]="AccessType">Public</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="createNewPageFilter(newListName, AccessType)">Save</button>
    </div>
  </ng-template>

  <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>


  <!-- Status Update -->

  <ng-template #statusUpdateContent let-cl="close" let-di="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
      <button type="button" class="close" aria-label="Close" (click)="di('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-header">
      <h4 class="modal-title text-center">Status Update</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">Is Completed</label>
          <select class="form-control" [(ngModel)]="IsCompleted">
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">Status</label>
          <select class="form-control" [(ngModel)]="UpdateStatus">
            <option *ngFor="let taskStage of TaskStageModel" value={{taskStage.Value}}> {{taskStage.Text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">Next Due Date</label>
          <input type="text" class="form-control" #dp="bsDatepicker" [(ngModel)]="NextDueDate" [(bsValue)]="validFrom"
            value="{{ validFrom | date: 'dd-MM-yyyy' }}" bsDatepicker [bsConfig]="{containerClass :'theme-dark-blue',showWeekNumbers : false,dateInputFormat: 'DD-MM-YYYY'}">
          <p class="inputdate" (click)="dp.toggle()">
            <i class="fa fa-calendar-alt fa-fw"></i>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">Outcome</label>
          <textarea class="form-control" rows="3" [(ngModel)]="Remarks"></textarea>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="cl('Close click')
      ">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="saveStatus()">Save</button>
    </div>
  </ng-template>

  <!-- <div class="row">
    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <label for="text">Barcode</label>
      <input type="text" class="form-control" [(ngModel)]="Barcode" (keyup)="onChange($event)" >
    </div>
  </div> -->

</div>