
import { CaseService } from '../../../services/case/case.service';
import { VariableService } from '../../../services/VariableService/variable.service';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { EmployeeService } from '../../../services/Employee/employee.service';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-case-properties',
  templateUrl: './case-properties.component.html',
  styleUrls: ['./case-properties.component.css']
})
export class CasePropertiesComponent implements OnInit {
  @Input() casePropertyData: any;
  casePropertyForm: UntypedFormGroup;
  public CaseStatusModel: AutocompleteModel[];
  public CaseTypeModel: AutocompleteModel[];
  public CasePriorityModel: AutocompleteModel[];
  public autoCompleteEmployee: AutocompleteModel[];
  responseMessage: any;
  constructor(
    private fb: UntypedFormBuilder,
    private masterLookupService: MasterLookupService,
    private employeeService: EmployeeService,
    private caseService: CaseService,
    private variableService: VariableService,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    const q: any = this.variableService.getSearchKeys();
    this.casePropertyForm = this.fb.group({
      Type: '',
      Priority: '',
      Status: '',
      OwnerId: '',
      CaseId: q.paramId,
      Description: '',
    });
    this.getCasePriorityList();
    this.getCaseStatusList();
    this.getCaseTypeList();
    this.getAllEmployee();
    this.casePropertyForm.patchValue(this.casePropertyData);
  }

  getCasePriorityList() {
    this.masterLookupService.getDropdownList('Case Priority').subscribe(data => {
      this.CasePriorityModel = data;
    });
  }

  getCaseStatusList() {
    this.masterLookupService.getDropdownList('Case Status').subscribe(data => {
      this.CaseStatusModel = data;
    });
  }

  getCaseTypeList() {
    this.masterLookupService.getDropdownList('Case Type').subscribe(data => {
      this.CaseTypeModel = data;
    });
  }

  getAllEmployee() {
    this.employeeService.getEmployeeList().subscribe(data => {
      this.autoCompleteEmployee = data.Result;
    });
  }

  onSubmit(form: UntypedFormGroup) {
    if (form.value.CaseId !== null) {
      this.caseService.addCaseProperties(form.value).subscribe(data => {
        this.responseMessage = data.ErrorMessages;
        if (this.responseMessage[1] === '0') {
          this.globalService.showDefaultSuccess({ masterName: 'Case property' });
        }
      });
    }
    this.resetFrom();
    this.globalService.disposeToastr();
  }

  resetFrom() {
    this.casePropertyForm.reset();
  }

}
