<div class="tab_title">
  <h3 class="page_title pull-left">
    <span class="menu_icon CRMQuotes fa fa-tags">
    </span>
    Quote Line Items ({{quoteProduct?.length}})
  </h3>
  <div class="buttonHeader pull-right">
    <div class="buttonNew" (click)="open()">Add/Edit</div>
  </div>
</div>


<div class="table-container">
  <div class="row">
    <div class="col-md-12 col-xs-12 col-lg-12 col-sm-12">

      <ngx-datatable #productstable class="bootstrap" [sorts]="[{prop: 'Name', dir: 'asc'}, {prop: 'Title', dir: 'asc'}]" [headerHeight]="'auto'"
        [limit]="13" [columnMode]="'force'" [footerHeight]="30" [rowHeight]="'auto'" [trackByProp]="'updated'" [rows]="quoteProduct">

        <ngx-datatable-column prop="ProductName" name="Product Name"></ngx-datatable-column>
        <ngx-datatable-column prop="Rate" name="Sales Price"></ngx-datatable-column>
        <ngx-datatable-column prop="Quantity" name="Quantity"></ngx-datatable-column>
        <ngx-datatable-column prop="Amount" name="SubTotal"></ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>
