import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { MasterLookupService } from './../../../services/common/master-lookup/master-lookup.service';
import { ResourceService } from './../../../services/resource/resource.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-new-resource',
  templateUrl: './new-resource.component.html',
  styleUrls: ['./new-resource.component.css']
})
export class NewResourceComponent implements OnInit {
  @Output() getData = new EventEmitter();
  resourceForm: UntypedFormGroup;
  resourceFormData: any;
  EditNew: any;
  public TerritoryList: AutocompleteModel[];
  public CategoryList: AutocompleteModel[];
  public WorkList: AutocompleteModel[];
  employeefilteredOptions: any[];
  employeeText: any; employeeValue: any;
  constructor(
    private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private configService: ConfigurationService,
    private resourceService: ResourceService,
    public activeModal: NgbActiveModal,
    private masterlookupService: MasterLookupService,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.resourceForm = this.fb.group({
      ResourceID: null,
      Designation: '',
      Name: '',
      Code: '',
      CategoryId: '',
      TerritoryID: '',
      ManagerID: '',
      PhoneNo: '',
      MobileNo: '',
      EmailID: '',
      NatureOfWorkId: '',
      SkypeID: '',
    });
    this.getAllTerritoryList();
    this.getAllCategoryList();
    this.getAllNatureOfWorkList();
    this.getEditData();
    this.getEditNewName();
  }

  getEditNewName() {
    if (this.resourceForm.value.ResourceID === null) {
      this.EditNew = 'New';
    } else {
      this.EditNew = 'Edit';
    }
  }

  getEditData() {
    if (this.resourceFormData !== undefined) {
      this.resourceForm.patchValue(this.resourceFormData);

      this.employeeText = this.resourceFormData.ManagerName;
      this.employeeValue = this.resourceFormData.ManagerID;
    }
  }

  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.employeefilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteEmployee(data) {
    const employeeID = data.data.option.value.Value;
    this.resourceForm.controls['ManagerID'].setValue(employeeID);
  }

  getAllNatureOfWorkList() {
    this.masterlookupService.getDropdownList('Nature Of Work').subscribe(data => {
      this.WorkList = data;
    });
  }

  getAllTerritoryList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }

  getAllCategoryList() {
    this.masterlookupService.getCategoryList().subscribe(data => {
      this.CategoryList = data;
    });
  }
  onSubmit(form: UntypedFormGroup) {
    const name = form.value.Name;
    let responseMessage = [];
    if (form.value.ResourceID === null) {
      this.resourceService.addResource(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Resource', name: name });
        }
        this.getData.emit();
      });
    } else {
      this.resourceService.updateResource(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Resource', name: name });
        }
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.resetForm();
    this.globalService.disposeToastr();
  }

  resetForm() {
    this.resourceForm.reset();
  }
}
