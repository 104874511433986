<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="closeModal.emit()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">Select fields to display</h4>
</div>

<form novalidate>
  <div class="modal-body">
    <div class="">
      <div class="row">
        <div class="col-md-5 ">
          <div class="actualcolums">
            <p>Available Fields</p>
            <ul>
              <ng-container *ngFor="let col of columns">
                <li *ngIf="!col.Visible" (click)="addCols(col, true)" [ngClass]="{'active': cols[col.Name] === col.Name }">{{col.Value}}</li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="col-md-2 addremoveColumn_cont">
          <div class="addremoveColumn">
            <ul>
              <li (click)="setCols(true)"><i class="fas fa-caret-right"></i></li>
              <li (click)="setCols(false)">
                <i class="fas fa-caret-left"></i></li>
            </ul>
          </div>
        </div>
        <div class="col-md-5 ">
          <div class="displaycolumns">
            <p>Visible Fields</p>
            <ul>
              <ng-container *ngFor="let col of columns">
                <li *ngIf="col.Visible" (click)="rmCols(col, false)" [ngClass]="{'active': cols[col.Name] === col.Name}">{{col.Value}}</li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>