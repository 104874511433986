<div class="">
  <table class="table table-striped table-bordered dynomic_table">
    <thead>
      <tr class="header">
        <th class="name_long">Nature</th>
        <th class="name_long">Activity Type</th>
        <th class="name_long">Name</th>
        <th class="name_short">Sun</th>
        <th class="name_short">Mon</th>
        <th class="name_short">Tue</th>
        <th class="name_short">Wed</th>
        <th class="name_short">Thu</th>
        <th class="name_short">Fri</th>
        <th class="name_short">Sat</th>
        <th class="name_small">Action</th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let list of timeSheetList; let i=index">
        <td>
          <select class="form-control" [(ngModel)]="list.General" (change)="addFieldValue.next(i)">
            <option value="General">General</option>
            <option value="Project">Project</option>
          </select>
        </td>
        <td>
          <select class="form-control" [(ngModel)]="list.ActivityType" (change)="addFieldValue.next(i)" (change)="selectType($event.target.value, i)">
            <option value="Task">Task</option>
            <option value="Event">Event</option>
            <option value="CallLog">Call Log</option>
            <option value="Email">Email</option>
          </select>
        </td>
        <td>
          <div class="custom_align">
            <input type="text" style="width: 28rem" class="form-control" [(ngModel)]="list.Name">
            <span class="custom_click pull-right" title="Create New {{activityType}}" (click)="openType()">+</span>
          </div>
        </td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="list.Mon">
        </td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="list.Tue">
        </td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="list.Wed">
        </td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="list.Thu">
        </td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="list.Fri">
        </td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="list.Sat">
        </td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="list.Sun">
        </td>
        <td>
          <div class="btn-group" dropdown>
            <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue.next(i)">&#43;</a>
            <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue.next(i)">&times;</a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>