import { GlobalService } from './../../../services/global-Service/global.service';
import { ClientService } from './../../../services/client/client.service';
import { AccountService } from './../../../services/accounts/account.service';
import { ContactService } from './../../../services/contact/contact.service';
import { VariableService } from './../../../services/VariableService/variable.service';
import { MasterLookupService } from './../../../services/common/master-lookup/master-lookup.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, Input, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteService } from '../../../services/quote/quote.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
@Component({
  selector: 'app-new-quotation',
  templateUrl: './new-quotation.component.html',
  styleUrls: ['./new-quotation.component.css']
})
export class NewQuotationComponent implements OnInit {
  @Output() getData = new EventEmitter();
  public QuoteStatusList: AutocompleteModel[];
  public MultipleAddressList: AutocompleteModel[];
  quoteForm: UntypedFormGroup;
  @Input() quoteData: any;
  currencyText: any; currencyValue: any;
  clientText: any; clientValue: any;
  contactText: any; contactValue: any;
  employeeText: any; employeeValue: any;
  accountText: any; accountValue: any;
  opportunityText: any; opportunityValue: any;

  currencyfilteredOptions: any[];
  clientfilteredOptions: any[];
  contactfilteredOptions: any[];
  employeefilteredOptions: any[];

  accountfilteredOptions: any[];
  opportunityfilteredOption: any[];
  disableAccount: Boolean = true;
  disableClient: Boolean = true;
  EditNew: any;
  ownerfilteredOption: any[];
  ownerText: any; ownerValue: any;
  public FileNameList: AutocompleteModel[];
  currencyExchangeRate: any;
  exchangeRate: Boolean = false;

  constructor(
    public fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private globalService: GlobalService,
    public activeModal: NgbActiveModal,
    private quoteService: QuoteService,
    private autoCompleteService: AutocompleteService,
    private masterLookupService: MasterLookupService,
    private accountService: AccountService,
    private variableService: VariableService,
    private contactService: ContactService,
    private clientService: ClientService,
  ) { }

  ngOnInit() {
    this.quoteForm = this.fb.group({
      QuoteID: null,
      QuoteNo: '',
      ExpiryDate: new Date(),
      QuoteName: '',
      OpportunityId: '',
      AccountId: '',
      QuoteStatus: '',
      Remarks: '',
      ShippingCharges: '',
      ContactId: '',
      Phone: '',
      Email: '',
      Fax: '',
      Currency: '',
      ExchangeRate: '',
      TermsTemplateId: '',
      ScopeOfWork: '',
      Deliverables: '',
      Exclusions: '',
      DeliverySchedule: '',
      TotalAmount: '',
      Tax: '',
      SubTotal: '',
      ApprovedBy: '',
      MailingAddressId: '',
      Client: '',
      QuoteDate: new Date(),
      OtherCost: '',
      IsDirect: '',

      OwnerId: '',
      PrintFileName: '',

      Validity: '',

      BillingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      }),
      ShippingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      }),
    });
    this.getAllQuoteStatusList();
    this.getAllMultipleAddressList();
    this.onEditData();
    this.editNewQuote();
    this.getAllFileNameList();
  }

  getFormattedDate(date) {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return day + '/' + month + '/' + year;
  }

  setDisable() {
    const q: any = this.variableService.getSearchKeys();
    if (q.type === 'CRMQuote') {
      this.disableClient = true;
      this.disableAccount = false;
      this.quoteForm.controls['IsDirect'].setValue(1);
    } else if (q.type === 'CRMOpportunity') {
      this.disableClient = false;
      this.disableAccount = true;
      this.quoteForm.controls['IsDirect'].setValue(0);
      this.getContactAccountQuote();
      this.ownerText = this.quoteData.OpportunityOwnerName;
      this.ownerValue = this.quoteData.OwnerId;
    }
  }

  editNewQuote() {
    if (this.quoteForm.value.QuoteID === null) {
      this.EditNew = 'New';
      this.setDisable();
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      this.currencyText = user.CurrencyCode;
      this.currencyValue = user.CurrencyId;
      this.currencyExchangeRate = user.CurrencyId;
      this.ownerText = user.EmpName;
      this.ownerValue = user.EmpID;
      this.quoteForm.controls['OwnerId'].setValue(user.EmpID);
    } else {
      this.quoteForm.controls['QuoteDate'].setValue(new Date(this.quoteForm.value['QuoteDate']));
      this.quoteForm.controls['ExpiryDate'].setValue(new Date(this.quoteForm.value['ExpiryDate']));
      this.EditNew = 'Edit';
      this.onEditSelectingIsDirect();
    }
  }

  onEditData() {
    if (this.quoteData !== undefined) {

      this.quoteForm.patchValue(this.quoteData);
      this.currencyText = this.quoteData.CurrencyName;
      this.currencyValue = this.quoteData.Currency;

      this.clientText = this.quoteData.ClientName;
      this.clientValue = this.quoteData.Client;

      this.employeeText = this.quoteData.EmployeeName;
      this.employeeValue = this.quoteData.ApprovedBy;

      this.contactText = this.quoteData.ContactName;
      this.contactValue = this.quoteData.ContactId;

      this.ownerText = this.quoteData.OwnerName;
      this.ownerValue = this.quoteData.OwnerId;
      this.quoteForm.controls['QuoteDate'].setValue(new Date(this.quoteForm.value.QuoteDate));
      this.quoteForm.controls['ExpiryDate'].setValue(new Date(this.quoteForm.value.ExpiryDate));
    }
  }

  onEditSelectingIsDirect() {
    const q: any = this.variableService.getSearchKeys();
    if (q.type === 'CRMQuote') {
      if (this.quoteData.IsDirect === true) {
        this.disableClient = true;
        this.disableAccount = false;
        this.quoteForm.controls['IsDirect'].setValue(1);
      } else {
        this.onIsOpportunity();
      }
    } else if (q.type === 'CRMOpportunity') {
      this.onIsOpportunity();
    }
  }

  onIsOpportunity() {
    if (this.quoteData.IsDirect === false) {
      this.disableClient = false;
      this.disableAccount = true;
      this.quoteForm.controls['IsDirect'].setValue(0);
      this.getContactAccountQuote();
    }
  }

  getContactAccountQuote() {
    const contactId = this.quoteData.ContactId;
    const accountId = this.quoteData.AccountId;

    this.contactText = this.quoteData.ContactName;
    this.contactValue = this.quoteData.ContactId;

    this.opportunityText = this.quoteData.OpportunityName;
    this.opportunityValue = this.quoteData.OpportunityId;

    this.accountText = this.quoteData.AccountName;
    this.accountValue = this.quoteData.AccountId;

    if (contactId !== null) {
      this.contactService.getContactById(contactId, 'Contact').subscribe(data => {
        this.quoteForm.patchValue({
          Email: data.Email,
          Phone: data.Phone,
          Fax: data.Fax,
        });
      });
    }

    if (accountId !== null) {
      this.accountService.getByAccountId(accountId, 'Account').subscribe(accountData => {
        this.quoteForm.patchValue({
          BillingAddress: (accountData.BillingAddress),
          ShippingAddress: (accountData.ShippingAddress),
        });
      });
    }
  }

  getOwnerList(value) {
    this.autoCompleteService.getAllEmployeeList(value.data, 'CRMQuotes').subscribe(
      res => {
        this.ownerfilteredOption = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }
  setAutoCompleteOwner(data) {
    const ownerid = data.data.option.value.Value;
    this.quoteForm.controls['OwnerId'].setValue(ownerid);
  }

  getAccountList(value) {
    this.autoCompleteService.getAllAccountList(value.data).subscribe(
      res => {
        this.accountfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteAccount(data) {
    const accountId = data.data.option.value.Value;
    this.quoteForm.controls['AccountId'].setValue(accountId);
  }

  getOpportunityList(value) {
    this.autoCompleteService.getAllOpportunityList(value.data).subscribe(
      res => {
        this.opportunityfilteredOption = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteOpportunity(data) {
    const opportunityId = data.data.option.value.Value;
    this.quoteForm.controls['OpportunityId'].setValue(opportunityId);
  }

  getCurrencyList(value) {
    this.autoCompleteService.getallCurrencyList(value.data).subscribe(
      res => {
        this.currencyfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteCurrency(data) {
    const currencyId = data.data.option.value.Value;
    this.quoteForm.controls['Currency'].setValue(currencyId);
    if (this.currencyExchangeRate !== currencyId) {
      this.exchangeRate = true;
    } else {
      this.exchangeRate = false;
    }
  }


  getClientList(value) {
    this.autoCompleteService.getAllClientList(value.data).subscribe(
      res => {
        this.clientfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }
  setAutoCompleteClient(data) {
    const client = data.data.option.value.Value;
    this.quoteForm.controls['Client'].setValue(client);
    if (client !== null) {
      this.clientService.editByClientId(client).subscribe(res => {
        this.quoteForm.patchValue({
          BillingAddress: {
            City: res.CRMAddress.City,
            Street: res.CRMAddress.Address1 + ', ' + res.CRMAddress.Address2 + ', ' + res.CRMAddress.Address3,
            Country: res.CRMAddress.Country,
            StateName: res.CRMAddress.StateName,
            ZipCode: res.CRMAddress.ZipCode
          },
          ShippingAddress: {
            City: res.ShippingAddress.City,
            Street: res.ShippingAddress.Street,
            Country: res.ShippingAddress.Country,
            StateName: res.ShippingAddress.StateName,
            ZipCode: res.ShippingAddress.ZipCode
          },
        });
      });
    }
  }

  getContactList(value) {
    const accountid = this.quoteForm.value.AccountId;
    this.autoCompleteService.getAllContactAccountList(value.data, accountid).subscribe(
      res => {
        this.contactfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }
  setAutoCompleteContact(data) {
    const contactId = data.data.option.value.Value;
    this.quoteForm.controls['ContactId'].setValue(contactId);
    if (contactId !== null) {
      this.contactService.getContactById(contactId, 'Contact').subscribe(res => {
        this.quoteForm.patchValue({
          Email: res.Email,
          Phone: res.Phone,
          Fax: res.Fax,
        });
      });
    }
  }


  getEmployeeList(value) {
    this.autoCompleteService.getAllEmployeeList(value.data, 'CRMQuotes').subscribe(
      res => {
        this.employeefilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }
  setAutoCompleteEmployee(data) {
    const approvedby = data.data.option.value.Value;
    this.quoteForm.controls['ApprovedBy'].setValue(approvedby);
  }

  getAllQuoteStatusList() {
    this.masterLookupService.getDropdownList('Quote Status').subscribe(data => {
      this.QuoteStatusList = data;
    },
      err => {
        console.log('errr====', err);
      });
  }

  getAllMultipleAddressList() {
    this.masterLookupService.getMultipleAddressList().subscribe(data => {
      this.MultipleAddressList = data;
    },
      err => {
        console.log('errr====', err);
      });
  }

  getAllFileNameList() {
    this.masterLookupService.getFileNameList().subscribe(data => {
      this.FileNameList = data;
    });
  }
  onSubmit(form: UntypedFormGroup) {
    const quoteName = form.value.QuoteName;
    let responseMessage = [];
    this.quoteForm.value.ExpiryDate = this.datePipe.transform(form.value.ExpiryDate, 'yyyy-MM-dd');
    this.quoteForm.value.QuoteDate = this.datePipe.transform(form.value.QuoteDate, 'yyyy-MM-dd');

    if (form.value.QuoteID === null) {
      this.quoteService.addQuote(this.quoteForm.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Quote', name: quoteName });
        }
        this.resetForm();
        this.getData.emit();
      });
    } else {
      this.quoteService.updateQuote(this.quoteForm.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Quote', name: quoteName });

        }
        this.resetForm();
        this.getData.emit();
      });
    }
    this.globalService.disposeToastr();
    this.activeModal.close();
  }

  resetForm() {
    this.quoteForm.reset();
  }
}
