<div class="">
  <form [formGroup]="clientForm">
    <div class="">
      <div class="">
        <div>
          <p class="headingclass">Client Information</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text"> Client Name</label>
              <input type="textbox" class="form-control" required formControlName="ClientName" required>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Client Code</label>
              <input type="textbox" class="form-control" required formControlName="ClientCode">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Contact</label>
              <input type="textbox" class="form-control" formControlName="ContactID">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Phone</label>
              <input type="textbox" class="form-control" formControlName="Phone" pattern="^[1-9]+[0-9]*$">
            </div>
          </div>

          <div class="row">

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Mobile</label>
              <input type="textbox" class="form-control" formControlName="Mobile" pattern="^[1-9]+[0-9]*$">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Fax</label>
              <input type="textbox" class="form-control" formControlName="Fax">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Email</label>
              <input type="textbox" class="form-control" formControlName="Email">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Territory Name</label>
              <select class="form-control" formControlName="TerritoryID">
                <option *ngFor="let territory of TerritoryList" value={{territory.Value}}> {{territory.Text}}
                </option>
              </select>
            </div>
          </div>

          <p class="headingclass">Address Information</p>
          <div class="row">
            <div formGroupName="CRMAddress" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <p class="headingclass">Billing Address</p>
                <label for="text">Address 1</label>
                <input type="textbox" class="form-control" formControlName="Address1">
                <label for="text">Address 2</label>
                <input type="textbox" class="form-control" formControlName="Address2">
                <label for="text">Address 3</label>
                <input type="textbox" class="form-control" formControlName="Address3">
                <div class="row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Billing City</label>
                    <input type="textbox" class="form-control" formControlName="City">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Billing State/Province</label>
                    <input type="textbox" class="form-control" formControlName="StateName">
                  </div>
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Billing ZipCode/Postal Code</label>
                    <input type="textbox" class="form-control" formControlName="ZipCode">
                  </div>
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Billing Country</label>
                    <input type="textbox" class="form-control" formControlName="Country">
                  </div>
                </div>
              </div>
            </div>

            <div formGroupName="ShippingAddress" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <p _ngcontent-c3="" class="headingclass">Shipping Address</p>
                <label for="text">Shipping Street</label>
                <textarea class="form-control" rows="3" formControlName="Street"></textarea>

                <div class="row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Shipping City</label>
                    <input type="textbox" class="form-control" formControlName="City">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Shipping State/Province</label>
                    <input type="textbox" class="form-control" formControlName="StateName">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Shipping ZipCode/Postal Code</label>
                    <input type="textbox" class="form-control" formControlName="ZipCode">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Shipping Country</label>
                    <input type="textbox" class="form-control" formControlName="Country">
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
</div>