import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { VariableService } from '../../../services/VariableService/variable.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../services/global-Service/global.service';
import { ProductService } from '../../../services/product/product.service';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {
  @Input() searchKeys;
  @Input() itemArr: Array<any> = [];
  @Output() getData = new EventEmitter();
  @Output() addData = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Input() afterSaveReset = true;
  @Input() pagescroll = true;
  @Input() removeCloseIcon = false;
  @Input() headingTitle = 'Edit Line Items';
  filteredOptions: any[];
  selectedOption: any;
  @Input() cancelbutton=true;
  amount: any;
  key: any;
  ListOfData = [];
  taxAmount: any;
  responseMessage: any;
  productForm: any;
  @Input() tableHeader;
  @Input() addEditTable=true;
  ServiceList = [];
  listOfObj: {
  };
  constructor(
    private variableService: VariableService,
    public activeModal: NgbActiveModal,
    private autocompleteService: AutocompleteService,
    private globalService: GlobalService,
    private productService:ProductService,
    private masterLookupService:MasterLookupService
  ) { }

  ngOnInit() {
    this.resetForm();
    this.resetListOfObj();
    this.listOfdata();
  }

  editTrigger(data,customEdit){
    this.edit.emit({...data,customEdit})
  }

  ngOnChanges(){
    this.resetForm();
    this.resetListOfObj();
    this.listOfdata();
  }

  addFieldValue(i) {
    this.resetListOfObj();
    const obj = this.productForm.ListOfProduct[i + 1];
    if (!obj && this.addEditTable) {
      this.productForm.ListOfProduct.push(this.listOfObj);
    }
    this.resetListOfObj();
  }

  deleteFieldValue(i: number) {
    if (this.productForm.ListOfProduct.length === 1) {
      return false;
    } else {
      this.productForm.ListOfProduct.splice(i, 1);
      return true;
    }
  }

  listOfdata() {
    this.itemArr.forEach((ob, i) => {
      if(ob.ProductName){
        ob['autoCompleteText'] = ob.ProductName;
      }
      if(ob.MachineName){
        ob['autoCompleteText'] = ob.MachineName;
      }
      if(ob.ServiceName){
        ob['autoCompleteText'] = ob.ServiceName;
      }
      this.productForm['ListOfProduct'].push(ob);
    });
    if(this.addEditTable){
      this.productForm['ListOfProduct'].push(this.listOfObj);
    }
  }

  get quoteFormData() {
    return this.productForm.ListOfProduct;
  }

  numberOnly(event): boolean {
    const keycode = (event.which) ? event.which : event.keyCode;
    if (!(keycode === 8 || keycode === 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      const parts = event.srcElement.value.split('.');
      if (parts.length > 1 && keycode === 46) {
        return false;
      }
      return true;
    }
  }

  setAutoComplete(data: any) {
    const value = data.data.option.value.Value;
    this.productForm.ListOfProduct[data.index].ProductID = value;
    this.productForm.ListOfProduct[data.index].ProductName = data.data.option.value.Text;
  }

  setAutoCompleteService(data: any) {
    const value = data.data.option.value.Value;
    this.productForm.ListOfProduct[data.index].ServiceID = value;
    this.productForm.ListOfProduct[data.index].ServiceName = data.data.option.value.Text;
  }

  fiteredData(value) {
    this.selectedOption = {};
    this.productService.getAllPosProductList(value.data).subscribe(
      res => {
        this.productForm.ListOfProduct[value.indexValue].filteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }


  setAutoMachineComplete(data: any) {
    const value = data.data.option.value.Value;
    this.productForm.ListOfProduct[data.index].MachineID = value;
    this.productForm.ListOfProduct[data.index].MachineName = data.data.option.value.Text;
  }

  fiteredMachineData(value) {
    this.selectedOption = {};
    this.autocompleteService.getAllMachineList(value.data).subscribe(
      res => {
        this.productForm.ListOfProduct[value.indexValue].filteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      }
    );
  }
  filterServiceListData(value){
    value.data = value.data.trim();
    this.masterLookupService.getFilteredServiceList(value.data).subscribe(res => {
      this.productForm.ListOfProduct[value.indexValue].filteredOptions = res;
    });
  }
  // calculate(i) {
  //   this.calculateTaxAmount(i);
  //   this.calculateTotalValue(i);
  //   this.calculateAmount(i);
  // }

  // calculateAmount(i) {
  //   const value = this.quoteFormData[i];
  //   let totalamount = (value.Quantity * value.Rate) * ((100 - value.Discount) / 100);
  //   this.amount = totalamount;
  //   if (!isNaN(totalamount)) {
  //     totalamount = parseFloat(totalamount.toFixed(2));
  //     this.productForm.ListOfProduct[i].Amount = totalamount;
  //   }
  // }

  // calculateTaxAmount(i) {
  //   const value = this.quoteFormData[i];
  //   let taxamount = this.amount * ((value.GST) / 100);
  //   this.taxAmount = taxamount;
  //   if (!isNaN(taxamount)) {
  //     taxamount = parseFloat(taxamount.toFixed(2));
  //     this.productForm.ListOfProduct[i].TaxAmount = taxamount;
  //   }
  // }

  // calculateTotalValue(i) {
  //   let totalvalue = this.amount + this.taxAmount;
  //   if (!isNaN(totalvalue)) {
  //     totalvalue = parseFloat(totalvalue.toFixed(2));
  //     this.productForm.ListOfProduct[i].TotalValue = totalvalue;
  //   }
  // }
  onSubmit() {
    this.ListOfData =[];
    const q: any = this.variableService.getSearchKeys();

    // this.productForm.ListOfProduct = this.productForm.ListOfProduct.filter(
    //   obj => {
    //     return obj.ProductId || obj.ProductName || obj.RowNo || obj.Capacity || obj.OpeningBalance;
    //   }
    // );
    let temptData = [...this.productForm.ListOfProduct];
    if(Object.keys(temptData[temptData.length-1]).length==0){
      temptData.pop();
    }

    temptData.forEach((i,n) => {
      delete i.filteredOptions;     
      this.ListOfData.push({
        ...i,
        SerialNo: n + 1
        // ProductID: i.ProductID,
        // ProductName: i.ProductName,
        // RowNo: i.RowNo,
        // Capacity: i.Capacity,
        // OpeningBalance: i.OpeningBalance,
      });
    });
    this.addData.emit(this.ListOfData);
    // this.quoteProductService.addQuote({
    //   ListOfProduct: this.ListOfData,
    //   QuoteID: q.paramId
    // }).subscribe(data => {
    //   this.responseMessage = data.ErrorMessages;
    //   if (this.responseMessage[1] === '0') {
    //     this.globalService.showProdutAdded();
    //   }
    //   this.getData.emit();
    // });
    this.activeModal.dismiss();
    this.afterSaveReset && this.resetCurrentForm();
  }

  resetCurrentForm() {
    this.resetForm();
    this.resetListOfObj();
  }

  resetListOfObj() {
    this.listOfObj = {
    };
  }
  resetForm() {
    this.ListOfData =[];
    this.productForm = {
      ListOfProduct: [],
    };
  }

  returnZero() {
    return 0
    }

}
