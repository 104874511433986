<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Ticket</h4>
</div>
<form [formGroup]="caseForm" novalidate (ngSubmit)="onSubmit(caseForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="">
          <!-- <p class="headingclass"> Case Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div class="form-group ">
                <label for="text">Case Owner</label>
                <app-autocomplete [autoCompleteText]="employeeOwnerText" (setAutoComplete)="setAutoCompleteEmployee($event)"
                  [autoCompleteValue]="employeeOwnerValue" (fiteredData)="getEmployeeList($event)" [filteredOptions]="employeeOwnerfilteredOptions">
                </app-autocomplete>
              </div>

              <div class="form-group">
                <label for="text">Case Date</label>
                <input type="text" class="form-control" formControlName="CaseDate" #dp="bsDatepicker" value="{{ caseForm.get('CaseDate').value | date: 'dd-MM-yyyy' }} "
                  bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
                <p class="inputdate" (click)="dp.toggle()">
                  <i class="fa fa-calendar-alt fa-fw"></i>
                </p>
              </div>

              <div class="form-group ">
                <label for="text">Case Number</label>
                <input type="textbox" class="form-control" formControlName="CaseNumber">
              </div>

              <div class="form-group ">
                <label for="text">Client Name</label>
                <app-autocomplete [autoCompleteText]="clientText" (setAutoComplete)="setAutoCompleteClient($event)"
                  [autoCompleteValue]="clientValue" (fiteredData)="getClientList($event)" [filteredOptions]="clientfilteredOptions">
                </app-autocomplete>
              </div>

            
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-6">
            

              <div class="form-group ">
                <label for="text">Account Name</label>
                <app-autocomplete [autoCompleteText]="accountText" (setAutoComplete)="setAutoCompleteAccount($event)"
                  [autoCompleteValue]="accountValue" (fiteredData)="getAccountList($event)" [filteredOptions]="accountfilteredOptions">
                </app-autocomplete>
              </div>

              <div class="form-group ">
                <label for="text">Contact Name</label>
                <app-autocomplete [autoCompleteText]="contactText" (setAutoComplete)="setAutoCompleteContact($event)"
                  [autoCompleteValue]="contactValue" (fiteredData)="getContactList($event)" [filteredOptions]="contactfilteredOptions">
                </app-autocomplete>
              </div>

              <div class="form-group ">
                <label for="text">Project Name</label>
                <app-autocomplete [autoCompleteText]="projectText" (setAutoComplete)="setAutoCompleteProject($event)"
                  [autoCompleteValue]="projectValue" (fiteredData)="getProjectList($event)" [filteredOptions]="projectfilteredOptions">
                </app-autocomplete>
              </div>
            </div>
          </div>

          <!-- <p class="headingclass"> Additional Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Status</label>
              <select class="form-control" formControlName="Status">
                <option value=0>--None--</option>
                <option *ngFor="let caseStatus of CaseStatusModel" value={{caseStatus.Value}}>{{caseStatus.Text}}</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Type</label>
              <select class="form-control" formControlName="Type">
                <option value=0>--None--</option>
                <option *ngFor="let caseType of CaseTypeModel" value={{caseType.Value}}>{{caseType.Text}}</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Case Origin</label>
              <select class="form-control" formControlName="CaseOrigin">
                <option value=0>--None--</option>
                <option *ngFor="let caseOrigin of CaseOriginModel" value={{caseOrigin.Value}}>{{caseOrigin.Text}}</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Case Reason</label>
              <select class="form-control" formControlName="CaseReason">
                <option value=0>--None--</option>
                <option *ngFor="let caseReason of CaseReasonModel" value={{caseReason.Value}}>{{caseReason.Text}}</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Priority</label>
              <select class="form-control" formControlName="Priority">
                <option value=0>--None--</option>
                <option *ngFor="let casePriority of CasePriorityModel" value={{casePriority.Value}}>{{casePriority.Text}}</option>
              </select>
            </div>
          </div>

          <!-- <p class="headingclass"> Web Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Web Email</label>
              <input type="textbox" class="form-control" formControlName="WebEmail">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="name">Web Company</label>
              <input type="textbox" class="form-control" formControlName="WebCompany">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="name">Web Name</label>
              <input type="textbox" class="form-control" formControlName="WebName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="name">Web Phone</label>
              <input type="textbox" class="form-control" formControlName="WebPhone">
            </div>
          </div>

          <!-- <p class="headingclass">Additional Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Product</label>
              <app-autocomplete [autoCompleteText]="productText" (setAutoComplete)="setAutoCompleteProduct($event)"
                [autoCompleteValue]="productValue" (fiteredData)="getProductList($event)" [filteredOptions]="productfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Engineering Req Number</label>
              <input type="textbox" class="form-control" formControlName="EngineeringReqNumber">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Potential Liability</label>
              <input type="checkbox" formControlName="PotentialLiability" class="custom_checkbox">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">SLA Violation</label>
              <input type="checkbox" formControlName="SLAViolation" class="custom_checkbox">
            </div>
          </div>

          <!-- <p class="headingclass">Description Information</p> -->
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Subject</label>
              <input type="textbox" class="form-control" formControlName="Subject">
            </div>

            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" formControlName="Description"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!caseForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!caseForm">Save</button>
  </div>
</form>