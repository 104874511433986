import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class EmployeeInfoService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  getEmployeeInfoList(EmployeeId) {
    return this.apiService.get(`api/employeeInfo/getall?RelatedId=${EmployeeId}&`);
  }

  getAllInfo() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/employeeInfo/getall?id=${q.paramId}&MasterType=${q.type}&`);
  }

}
