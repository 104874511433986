import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { AutocompleteService } from './../../../services/autocomplete/autocomplete.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ClientService } from '../../../services/client/client.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.css']
})
export class NewClientComponent implements OnInit {
  @Output() getData = new EventEmitter();

  public TerritoryList: AutocompleteModel[];
  public GroupList: AutocompleteModel[];
  clientForm: UntypedFormGroup;
  clientFormData: any;
  EditNew: any;
  ownerfilteredOptions: any[];
  ownerText: any; ownerValue: any;

  constructor(
    private fb: UntypedFormBuilder,
    private configService: ConfigurationService,
    private masterlookupService: MasterLookupService,
    private clientService: ClientService,
    public activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private autoCompleteService: AutocompleteService
  ) { }

  ngOnInit() {
    this.clientForm = this.fb.group({
      CustomerId: null,
      ClientName: '',
      ClientCode: '',
      ContactID: '',
      Phone: '',
      Mobile: '',
      Fax: '',
      Email: '',
      TerritoryID: '',
      Address1: '',
      Address2: '',
      Address3: '',
      CaseDate: '',
      ParentId: '',
      OwnerId: '',
      CRMAddress: new UntypedFormGroup({
        Address1: new UntypedFormControl(),
        Address2: new UntypedFormControl(),
        Address3: new UntypedFormControl(),
        City: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      }),
      ShippingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      }),
    });
    this.getAllTerritoryList();
    this.getAllGroupList();
    this.getEditData();
    this.getEditNewName();
  }

  getEditNewName() {
    if (this.clientForm.value.CustomerId === null) {
      let userDetails = this.autoCompleteService.getUserDetails();
      this.ownerText = userDetails.EmpName;
      this.ownerValue = userDetails.EmpID;
      this.clientForm.controls['OwnerId'].setValue(userDetails.EmpID);
      this.EditNew = 'New';
    } else {
      this.EditNew = 'Edit';
    }
  }

  getOwnerList(value) {
    this.autoCompleteService.getAllEmployeeList(value.data, 'CRMClient').subscribe(
      res => {
        this.ownerfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteOwner(data) {
    const ownerId = data.data.option.value.Value;
    this.clientForm.controls['OwnerId'].setValue(ownerId);
  }

  getEditData() {
    if (this.clientFormData !== undefined) {
      this.clientForm.patchValue(this.clientFormData);

      this.ownerText = this.clientFormData.OwnerName;
      this.ownerValue = this.clientFormData.OwnerId;
    }
  }


  getAllTerritoryList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }

  getAllGroupList() {
    this.masterlookupService.getGroupLookupList().subscribe(data => {
      this.GroupList = data;
    });
  }


  onSubmit(form: UntypedFormGroup) {
    const clientName = form.value.ClientName;
    let responseMessage = [];
    if (form.value.CustomerId === null) {
      this.clientService.addClient(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[2] === '0') {
          this.globalService.showSuccess({ masterName: 'Client', name: clientName });
        }
        this.getData.emit();
      });
    } else {
      this.clientService.updateClient(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[2] === '0') {
          this.globalService.showUpdate({ masterName: 'Client', name: clientName });
        }
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.clientForm.reset();
    this.globalService.disposeToastr();
  }
}
