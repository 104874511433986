<div class="">

  <form [formGroup]="leadForm">
    <div class="">
      <div class="">
        <div class="">

          <p class="headingclass">Lead Information</p>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <table class="w-full">
                <tbody>
                  <tr>
                    <td>
                      <label for="text"> Lead Status</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.StatusName}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Lead Owner</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.LeadOwnerName}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Salutation</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.Salutation}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> First Name</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.FirstName}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Last Name</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.LastName}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Email</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.Email}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Phone</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.Phone}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Annual Revenue</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.AnnualRevenue}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Rating</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{getDisplayName(leadData.Rating,'Value','Text',RatingModel)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Currency</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.CurrencyName}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Lead Value</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.LeadAmount}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Branch</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{getDisplayName(leadData.TerritoryName,'Value','Text',TerritoryList)}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <table class="w-full">
                <tbody>
                  <tr>
                    <td>
                      <label for="text"> Website</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.Website}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Title</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.Title}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Company</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.CompanyName}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Industry</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{getDisplayName(leadData.IndustryId,'Value','Text',IndustryModel)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> No.Of Employees</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.NoofEmployees}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text">Fax</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.Fax}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Lead Source</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{getDisplayName(leadData.LeadSource,'Value','Text',LeadSourceModel)}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Mobile</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.Mobile}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Skype ID</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.SkypeID}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Twitter</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.TwitterID}} 
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Facebook</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.FaceBookID}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Linkdin</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.LinkdInId}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <p class="headingclass"> Address Information</p>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <table class="w-full">
                <tbody>
                  <tr>
                    <td>
                      <label for="text"> Street</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.Street}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> City</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.City}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Country</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.Country}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <table class="w-full">
                <tbody>
                  <tr>
                    <td>
                      <label for="text"> State/Province</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.StateName}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> ZipCode</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.ZipCode}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          

          <p class="headingclass"> Additional Information</p>


          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <table class="w-full">
                <tbody>
                  <tr>
                    <td>
                      <label for="text"> Product Interest</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.ProductName}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Current Generator(s)</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.CurrentGenerators}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <table class="w-full">
                <tbody>
                  <tr>
                    <td>
                      <label for="text"> SIC Code</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.SicCode}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="text"> Number Of Locations</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.NoOfLocations}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p class="headingclass">Description Information</p>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <table class="w-full">
                <tbody>
                  <tr>
                    <td>
                      <label for="text"> Description</label>
                    </td>
                    <td>
                      :
                    </td>
                    <td class="pl-5">
                      {{leadData.Description}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
</div>