<div class="body_inner">
  <div class="">
    <div class="topheader">
      <div class="topheader">
        <div class="topheadertitle">
          <span class="menu_icon CRMTasks glyphicon glyphicon-list-alt">
          </span>
          <h3 class="report_page_title">
            <p class="page_title_cont">Task</p>
            <p class="page_sub_title">{{taskData?.Subject}}</p>
          </h3>
        </div>
        <div class="">
          <div class="topdownheader">
            <div class="">
              <div class="block">
                <label class="labelName">Status</label>
                <p>{{taskData?.StatusName}}</p>
              </div>
              <div class="block">
                <label class="labelName">Assigned To</label>
                <p>{{taskData?.AssignedName}}</p>
              </div>
              <div class="block">
                <label class="labelName">Due Date</label>
                <p>{{taskData?.DueDate | date:'dd-MM-yyyy'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <nav class="navbar navbar-default custom_navbar">
        <div class="nav nav-bar-inner">
          <ul>
            <li class="realted">
              <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Details</a>
            </li>
            <li class="realted">
              <a class="" (click)="OnFollowUp()" [ngClass]="{'active': FollowUpOn===true}"> Follow-up History</a>
            </li>
          </ul>
        </div>
        <ul class="cl custom_content_tab">
          <li class="nav-item disabled_form">
            <app-task-detail [taskData]="taskData" *ngIf="DetailOn===true && taskData?.TaskId "></app-task-detail>
          </li>
          <li class="nav-item">
            <app-task-follow-up *ngIf="FollowUpOn===true"></app-task-follow-up>
          </li>
        </ul>
      </nav>
    </div>
    <app-spinner-loader *ngIf="
              showSpinner">
    </app-spinner-loader>
  </div>
</div>