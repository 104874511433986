<ng-template #importModal let-c="close" let-d="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-header dnd-m-header">
        <div *ngIf="stepOne">
            <h4 class="modal-title text-center mb-4">Upload your file</h4>
            <p class="mb-4">Drag and drop your file to start importing your data. After that, you'll name your fields.</p>
            <div class="text-center">
                Please click <a href="javascript:void(0);" (click)="downloadFile()">here</a> to dowload the format.
            </div>
        </div>
        <div *ngIf="!stepOne">
            <h4 class="modal-title text-center mb-4">Match Fields</h4>
            <p class="mb-4">Match each column from your file to a field.</p>
        </div>
      </div>
      <div class="modal-body">
        <div class="step-one" *ngIf="stepOne">
            <div class="container-dnd" appDnd (fileDropped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef"  (change)="fileBrowseHandler($event.target.files)" />
                <i class="fa fa-upload upload-btn" aria-hidden="true"></i>
                <h3>Drag and drop file here</h3>
                <h3>or</h3>
                <label for="fileDropRef">Browse for file</label>
            </div>
            <div class="files-list">
                <div class="single-file" *ngFor="let file of files; let i = index">
                    <div class="file-icon" style="width: 50px">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
                            <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                            <g>
                                <path style="fill:#CEC9AE;"
                                    d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                                <path style="fill:#CEC9AE;"
                                    d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                                <path style="fill:#CEC9AE;"
                                    d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                                <path style="fill:#CEC9AE;"
                                    d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                                <path style="fill:#CEC9AE;"
                                    d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                            </g>
                            <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                        </svg>
                    </div>
                    <div class="info">
                        <h4 class="name">
                            {{ file?.name }}
                        </h4>
                        <p class="size">
                            {{ formatBytes(file?.size) }}
                        </p>
                        <!-- <app-progress [progress]="file?.progress"></app-progress> -->
                    </div>
            
                    <div class="delete" (click)="deleteFile(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                            <path fill="#B1B1B1" fill-rule="nonzero"
                                d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="step-two"  *ngIf="!stepOne">
            <div class="row">
                <div class="form-group col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label  for="text">Select file:</label>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        <select formcontrolname="LeadStatus" class="form-control ng-pristine ng-valid ng-touched" (change)="selectFile($event.target.value)">
                            <option value='' class="ng-star-inserted"> --select file-- </option>
                            <ng-container *ngFor="let item of fileList.ExcelSheetNameList">
                                <option value={{item.Value}} class="ng-star-inserted"> {{item.Text}} </option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>
            <div class="tableWrap" *ngIf="formDataReady">
                <form [formGroup]="ImportFileDetails">
                    <table class="table align-middle mb-0 bg-white impdata-table">
                        <thead class="bg-light">
                            <tr>
                                <th>
                                    Imported Field
                                </th>
                                <th>
                                    Destination Field
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let field of ImportFileDetails.controls; let i = index">
                                <tr [formGroup]="field">
                                    <td>
                                        <span class="pull-left">{{field.value.FileHeaderName}}</span>
                                        <span class="pull-right">Map Corresponding Field---></span>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <!-- <input type="text" class="form-control"> -->
                                                <select formControlName="TableColumnName" class="form-control ng-pristine ng-valid ng-touched" >
                                                    <option value='' class="ng-star-inserted"> --Select Option-- </option>
                                                    <ng-container *ngFor="let item of fileData.TableColumnNameList">
                                                        <option value={{item.Value}} class="ng-star-inserted"> {{item.Text}} </option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
      </div>
      <div class="modal-footer d-block">
        <div *ngIf="stepOne" >
            <button class="btn btn-primary pull-right" (click)="nextStep()" [disabled]="!files.length" *ngIf="!StepOneLoader">
                Next
            </button>
            <button class="btn btn-primary" type="button" disabled *ngIf="StepOneLoader">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Processing...
            </button>
        </div>
        <div *ngIf="!stepOne">
            <button class="btn btn-primary pull-left" (click)="prevStep()">
                Previous
            </button>
            <ng-container *ngIf="formDataReady">
                <button class="btn btn-primary pull-right" (click)="saveForm()" [disabled]="!fieldMappingForm.valid" *ngIf="!showSpinner">
                    Save
                </button>
                <button class="btn btn-primary" type="button" disabled *ngIf="showSpinner">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
            </ng-container>
            <ng-container *ngIf="!formDataReady">
                <button class="btn btn-primary pull-right" [disabled]="true">
                    Save
                </button>
            </ng-container>
        </div>
      </div>
</ng-template>
  
  
  <div class="">
        <button class="buttonNew" (click)="openModel(importModal)">Import</button>
  </div>
  <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
