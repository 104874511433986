<div class="">
  <form [formGroup]="opportunityForm">
    <div class="extra-pad">
      <div class="">

        <div class="">
          <p class="headingclass"> Opportunity Information</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Opportunity Name</label>
              <input type="textbox" class="form-control" formControlName="OpportunityName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Opportunity Owner</label>
              <input type="textbox" class="form-control" formControlName="OpportunityOwnerName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Account Name</label>
              <input type="textbox" class="form-control" formControlName="AccountName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Contact Name</label>
              <input type="textbox" class="form-control" formControlName="ContactName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Close Date</label>
              <input type="text" class="form-control" formControlName="CloseDate" value="{{ opportunityForm.get('CloseDate').value | date: 'dd-MM-yyyy' }}">
              <p class="inputdate">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Type</label>
              <select class="form-control" formControlName="Type">
                <option *ngFor="let type of TypeModel" value={{type.Value}}> {{type.Text}}
                </option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Stage</label>
              <input type="textbox" class="form-control" formControlName="OpporStageName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Primary Campaign Source</label>
              <input type="textbox" class="form-control" formControlName="CampaignName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Probability(%)</label>
              <input type="textbox" class="form-control" formControlName="Probability">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Currency</label>
              <input type="textbox" class="form-control" formControlName="CurrencyName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Amount</label>
              <input type="textbox" class="form-control" formControlName="Amount">
            </div>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="private">Private</label>
            <input type="checkbox" formControlName="IsPrivate" class="custom_checkbox">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Loss Reason</label>
            <select class="form-control" formControlName="LossReason">
              <option *ngFor="let reason of ReasonModel" value={{reason.Value}}> {{reason.Text}}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Territory Name</label>
            <select class="form-control" formControlName="TerritoryName">
              <option *ngFor="let territory of TerritoryList" value={{territory.Value}}> {{territory.Text}}
              </option>
            </select>
          </div>
        </div>

        <p class="headingclass"> Additional Information</p>
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Next Step</label>
            <input type="textbox" class="form-control" formControlName="NextStep">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Lead Source</label>
            <select class="form-control" formControlName="LeadSource">
              <option *ngFor="let leadSource of LeadSourceModel" value={{leadSource.Value}}> {{leadSource.Text}}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Order Number</label>
            <input type="textbox" class="form-control" formControlName="OrderNo">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Main Competitor(s)</label>
            <input type="textbox" class="form-control" formControlName="MainCompetitors">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Current Generator(s)</label>
            <input type="textbox" class="form-control" formControlName="CurrentGenerators">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Delivery/Installation Status</label>
            <select class="form-control" formControlName="DeliveryInstallationStatus">
              <option *ngFor="let status of StatusModel" value={{status.Value}}> {{status.Text}}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Tracking Number</label>
            <input type="textbox" class="form-control" formControlName="TrackingNo">
          </div>
        </div>
        <p class="headingclass"> Description Information</p>
        <div class="row">
          <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label for="text">Description</label>
            <textarea class="form-control" formControlName="Description" rows="3"></textarea>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>