import { GlobalService } from './../../../services/global-Service/global.service';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl
} from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-new-location',
  templateUrl: './new-location.component.html',
  styleUrls: ['./new-location.component.css']
})
export class NewLocationComponent implements OnInit {

  locationFormData: any;
  @Output() getData = new EventEmitter();
  public StatusModel: AutocompleteModel[];
  public CampaignTypeModel: AutocompleteModel[];
  locationForm: UntypedFormGroup;
  Type:any;
  EditNew: any;
  SegmentList:any;
  new: any;
  constructor(
    private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private masterLookupService: MasterLookupService,
    private globalService: GlobalService,
    private datePipe: DatePipe,
    private locationService: LocationService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.locationForm = this.fb.group({
      LocationId:'',
      LocationName: null,
      Code: '',
      ContactPerson: '',
      PhoneNo: '',
      EmailId: '',
      BillingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      })
    });
    this.getLocationsList();
    this.getEditData();
    this.getEditNewName();
  }

  getEditData(){
    if(this.locationFormData !== undefined){
      this.locationForm.patchValue(this.locationFormData);
      this.DateFormat()
    }
  }

  getEditNewName() {
    if (!this.locationForm.value.MachineId) {
      this.EditNew = 'New';
    } else {
      this.EditNew = 'Edit';
    }
  }


  setAutoCompleteCampaign(data) {
    const parentId = data.data.option.value.Value;
    this.locationForm.controls['ParentCampaignId'].setValue(parentId);
  }

  DateFormat() {
    this.locationForm.value.PlacedOn = this.datePipe.transform(this.locationForm.value.PlacedOn, 'dd-MM-yyyy');
  }

  onSubmit(form: UntypedFormGroup) {
    let responseMessage = [];
    const locationName = form.value.LocationName;
    this.DateFormat();
    if (!form.value.LocationId) {
      this.locationService.addLocation(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccessData(`Location ${locationName} was created.`);
        }
        this.getData.emit();
      });
    } else {
      this.locationService.updateLocation(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccessData(`Location ${locationName} was updated.`);
        }
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.locationForm.reset();
    this.globalService.disposeToastr();
  }
  getLocationsList() {
    this.masterLookupService.getDropdownList('Location Types').subscribe(data => {
      this.Type = data;
    });
  }

}
