import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { models, service } from 'powerbi-client';
// import * as MS from '@microsoft/teams-js';

// import {
//   ChartReadyEvent,
//   ChartMouseOverEvent,
//   ChartErrorEvent,
//   ChartMouseOutEvent,
//   ChartSelectEvent,
// } from 'ng2-google-charts';




declare var $: any;
declare let google: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('cchart') cchart;
  @ViewChild('openCloseExample') private openCloseExample: any;
  showSpinner: Boolean = true;
  // public selectEvent: ChartSelectEvent;
  public DataColors = ['blue', 'yellow', 'green', 'purple', 'geona', 'red'];
  public kpi_dashboards = [];
  public dashboardsFull = [];
  public dashboardsHalf = [];
  public isOpen: Boolean = false;
  dashBoard=true;
  loading = false;
  embedConfig = {
    type: this.dashBoard?"dashboard":'report', //'report' ,'dashboard'
    id: "7d222124-00fd-4858-8a3a-72d220345cfa",
    embedUrl: "https://app.powerbi.com/reportEmbed?reportId=7d222124-00fd-4858-8a3a-72d220345cfa&groupId=7fbddfd4-b5ba-4fac-921d-e751e86ba28d&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsInNraXBRdWVyeURhdGFTYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFQYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFFeHBvcnRUbyI6dHJ1ZX19",
    accessToken: "H4sIAAAAAAAEACWTxQrtCAJE_-VtMxC3gV5Eb_TGdRd39zTz73Ob3h8K6hT19x8zefopyf_89w8ES5iv5EKXEbPWQxC9tdREMxAuNpQ1EZRbhPGOiqik6Y0WjK61vEpHhFf3w25QncBLFxGhHJPCZ4HUVAGBpZ35kKt1TYzEFzSO_ggU9lBYk4a1LZ3btxaW436ttuVs7FRTC-whoZRvA3E0e9xY3uC3oVYKa5YIoHI4J6CfkpyNXVbZ0To6te0e4GGeNdw_aZAzAN1t_t09DAaTLH3xX6Ta2xXFrqDR-i0H8G5H4wdEq4ZCI9lUmF_gQBVUoc61CJqB-j3h6W0fxTzkD_XdLKrUsDy7yRIq6supL7bhA8PEngqjNWqwHTkk5u3V1EaHMjol17zeHhLwbjUSoyzNWFKkTdRvzhclS03EWFrJ214bJJLhiiyOejkXhjHaog0qSZJdJ1NH7YN_WlK44KW91cQ4_RUzT7mC9FpDCyY1tDmlFMKYVIYRlmzRCjEj40urxPG-LnfAvTYlZdwWcZc1R74zLGThqQF58LjYqoQY8UP2FoOod_CL7bSPd4R5lf4-vhYMqxxRQFHjp6_-gS69854zK5dYnnMlqp1Bj0oHePGxqwtz28m40LQ08Osh-bIXhimzrkjb01ybegYa7xDmS5XFAr22Pc7evFUBaBWOOMMCDx7R_CpGBEe2DInu46UC-GbneVd9XsrQZtyeVsnaq_9iatucSy-p0-0WAOojtSIfeK5q12y69nMYM9eFDSi-tu9uMpR_prwHLtJY68VomHBCo4URnXwJl3J4RG4eDTHo0zLaHA6gCync5YgenoPB-TrDh9vR2O3isw5RSWDrm_rKqLBuKMtoY-_8_gRAi7DSIRkbvbBOQQqaXl9cd-09BPrnP3-49Zn3SS2e351KviKNkzR96vSKw6mnqk0DUEa_4p0oiOZg5RWgFkhA5aVcS51XespfGjNywALWQ3qXCyotjnaYUrtrvvDBZVnbwhRFfDevezFrr7woW8Bt_VpXgA-erbD-gJvtiuwS8SWUicB0QgdhcPIN2LnkG83iVJ9lpGZtz1u5xR1WtGO7ns-D3sdnbL_yqpqow5vJbfv2jDOd9vVxoQi6L94YEBFzkINtSJtog9O6rpsNjmSkYT5JOYaPXNY-bVaF0-KSN8YqbydlEsgJDkboCpVsPBnM3U6jT18A_iKa26yQaC7b4uHnKo_d7NKlpyltVePZzKRSrL3FQuYQ4hszihC2jfJpyOqvv_7R_Mx1scr-z_IsUTKxCyWBqoFKn1JEI5jxL-U01Zjsx1r8MCeRjQzO7PBz_AaN9vkEaJ2rJHQhviVmocyA4IGFY1vecKWvNpRHoDzXr-qwOdtaqRsbZHyooiZT9ZZjPPsodCwz5jmNGcj0UpokBjNa7ZJk9YXNFb6zVIMiO9QthibNVzg3fAd3BsKyWVN6Kovg11zyqCKJYvFAdKS2lBadA3laxXadUJqstNCyC8etG9db697OtANYFAyG3IFuD9IWvrMDOqH3fWx7ihELVOownccy8lHYEFs55qRNheD3pNZCPxQyv5FDRcqcbgyUUxKyjUu8-6KeZ6JcshRoM-UIej2FDB4U3C3rNuHyPpFHCNpAC5nOLmADGwgiGYxbvfZjX7_WT_P__g_UMfGzQgYAAA==.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6ZmFsc2V9fQ==",
    tokenType: models.TokenType.Embed,
    settings: {
        panes: {
            filters: {
                expanded: false,
                visible: false
            }
        },
        background: models.BackgroundType.Transparent,
    }
  };
  reportClass='reportClass';
  isEmbedded=false;
  eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>) => void>([
    ['loaded', () => console.log('Report has loaded')],
    [
      'rendered',
      () => {
        console.log('Report has rendered');

        // Update embed status
        this.isEmbedded = true;
      },
    ],
    [
      'error',
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.log(event.detail);
        }
      },
    ],
    // ['visualClicked', (e) => console.log('visual clicked',e)],
    // ['pageChanged', (event) => console.log(event)],
  ]);

  constructor(
    private dashboardService: DashboardService,
  ) {

  }

  ngOnInit() {
    this.dashBoard && this.getDashBoard();
    !this.dashBoard && this.getReport();
    
  }

  msApp(){
    // MS.authentication.authenticate({
    //   url: config.BASE_URL + '/app/microsoft-teams/tabs/auth',
    //   width: 600,
    //   height: 535,
    //   successCallback: (onAuthSuccess)=>{console.log(onAuthSuccess)},
    //   failureCallback: (failure)=>{console.log(failure)},
    // });
  }

  getReport(){
    this.dashboardService.getReport({WorkSpaceID:null,ReportID:""}).subscribe(data => {
      if(data && data.EmbedToken){
        this.embedConfig.accessToken = data.EmbedToken.token;
        this.embedConfig.id = data.EmbedReports[0].ReportId;
        this.embedConfig.embedUrl = data.EmbedReports[0].EmbedUrl;
      }
      this.loading = true;
    });
  }

  getDashBoard() {
    this.dashboardService.getDashboard({WorkSpaceID:null,ReportID:""}).subscribe(data => {
      if(data && data.EmbedToken){
        this.embedConfig.accessToken = data.EmbedToken.token;
        this.embedConfig.id = data.DashboardId;
        this.embedConfig.embedUrl = data.EmbedUrl;
      }
      this.loading = true;
      // const kpi = [];
      // const dashboardHalf = [];
      // const dashboardFull = [];
      // data.forEach(function (item) {
      //   if (!item.chartType.indexOf('KPI')) {
      //     kpi.push(item);
      //   } else {
      //     if (item.multiplier === 1) {
      //       dashboardHalf.push(item);
      //     } else if (item.multiplier === 2) {
      //       dashboardFull.push(item);
      //     }

      //   }
      // });
      // this.kpi_dashboards = kpi;
      // this.dashboardsFull = dashboardFull;
      // this.dashboardsHalf = dashboardHalf;
      // this.showSpinner = false;
    });
  }

  // public ready(event: ChartReadyEvent) {
  //   console.log(event.message);
  // }


  // public error(event: ChartErrorEvent) {
  //   const chartObj = document.getElementById(event.id);
  //   chartObj.innerHTML = 'No data availabe.';
  // }


  // public select(event: ChartSelectEvent) {
  //   this.selectEvent = event;
  // }

  // public mouseOver(event: ChartMouseOverEvent) {
  //   console.log('ChartMouseOverEvent');
  //   console.log('bb: ' + JSON.stringify(event.boundingBox));
  //   console.log('pos: ' + JSON.stringify(event.position));
  //   console.log('type: ' + JSON.stringify(event.columnType));
  //   console.log('label: ' + JSON.stringify(event.columnLabel));
  //   console.log('value: ' + JSON.stringify(event.value));
  // }

  // public mouseOut(event: ChartMouseOutEvent) {
  //   console.log('ChartMouseOutEvent');
  //   console.log('bb: ' + JSON.stringify(event.boundingBox));
  //   console.log('pos: ' + JSON.stringify(event.position));
  //   console.log('type: ' + JSON.stringify(event.columnType));
  //   console.log('label: ' + JSON.stringify(event.columnLabel));
  //   console.log('value: ' + JSON.stringify(event.value));
  // }
}
