<div class="">
  <form [formGroup]="projectForm">
    <div class="">
      <div class="">
        <p class="headingclass">Project Handover From Marketing To Technical</p>
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Client</label>
            <input type="textbox" class="form-control" formControlName="ClientName">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Standard To Follow</label>
            <input type="textbox" class="form-control" formControlName="StandardToFollow">
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Project Name</label>
            <input type="textbox" class="form-control" formControlName="ProjectName">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Client Project No.</label>
            <input type="textbox" class="form-control" formControlName="ClientProjectNo">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Project No.</label>
            <input type="textbox" class="form-control" formControlName="ProjectNo">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">

            <label for="text">Purchase Order No.</label>
            <input type="textbox" class="form-control" formControlName="PurchaseOrderNo">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Client contact names for RFI's</label>
            <input type="textbox" class="form-control" formControlName="ContactRFIName">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Purchase Order Date</label>
            <input type="text" class="form-control" formControlName="PurchaseOrderDate" value="{{ projectForm.get('PurchaseOrderDate').value | date: 'dd-MM-yyyy' }}">
            <p class="inputdate">
              <i class="fa fa-calendar-alt"></i>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="form-group  col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Contact Contact Name For Delivery</label>
            <input type="textbox" class="form-control" formControlName="ContactName">
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Start Date</label>
            <input type="text" class="form-control" formControlName="StartDate" value="{{ projectForm.get('StartDate').value | date: 'dd-MM-yyyy' }}">
            <p class="inputdate">
              <i class="fa fa-calendar-alt"></i>
            </p>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Deadline</label>
            <input type="text" class="form-control" formControlName="DeadLine" value="{{ projectForm.get('DeadLine').value | date: 'dd-MM-yyyy' }}">
            <p class="inputdate">
              <i class="fa fa-calendar-alt"></i>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-left">
            <label for="text">Lead Source</label>
            <input type="textbox" class="form-control" formControlName="LeadSourceName">
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Business Manager</label>
            <input type="textbox" class="form-control" formControlName="BusinessManagerName">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Technical Head</label>
            <input type="textbox" class="form-control" formControlName="TechnicalHeadName">
          </div>
        </div>

        <div class="row">

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Currency</label>
            <input type="textbox" class="form-control" formControlName="CurrencyName">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Status</label>
            <select class="form-control" formControlName="Status">
              <option value=0>--None--</option>
              <option value="1">Active</option>
              <option value="2">Completed</option>
              <option value="3">Inactive</option>
              <option value="4">Onhold</option>
              <option value="5">Planning</option>
            </select>
          </div>
        </div>

        <p class="headingclass">Budget</p>
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Total Hours Budget</label>
            <input type="textbox" class="form-control" formControlName="TotalHoursBudget">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Total Expense Budget</label>
            <input type="textbox" class="form-control" formControlName="TotalExpenseBudget">
          </div>
        </div>

        <p class="headingclass">Work Order</p>
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Branch</label>
            <input type="textbox" class="form-control" formControlName="TerritoryName">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">PM/GM Responsible</label>
            <input type="textbox" class="form-control" formControlName="ProjectManagerName">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Team Lead</label>
            <input type="textbox" class="form-control" formControlName="TeamLeadName">
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Work Order No.</label>
            <input type="textbox" class="form-control" formControlName="WorkOrderNo">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">End Date</label>
            <input type="text" class="form-control" formControlName="EndDate" value="{{ projectForm.get('EndDate').value | date: 'dd-MM-yyyy' }}">
            <p class="inputdate">
              <i class="fa fa-calendar-alt"></i>
            </p>
          </div>
        </div>

        <p class="headingclass">Client Requirement</p>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Clarification LOG every week</label>
            <select class="form-control" formControlName="Clarification">
              <option value="0">Yes</option>
              <option value="1">No</option>
            </select>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Change Order log every week</label>
            <select class="form-control" formControlName="ChangeOrder">
              <option value="0">Yes</option>
              <option value="1">No</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Project status list/HOLD list every week</label>
            <select class="form-control" formControlName="StatusHoldList">
              <option value="0">Yes</option>
              <option value="1">No</option>
            </select>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Model Upload every week</label>
            <select class="form-control" formControlName="ModelUpload">
              <option value="0">Yes</option>
              <option value="1">No</option>
            </select>
          </div>
        </div>

        <p class="headingclass">Marketing Requirement</p>
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Client communication responsibility</label>
            <input type="textbox" class="form-control" formControlName="CommunicateOwnerName">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Quality responsibility</label>
            <input type="textbox" class="form-control" formControlName="QualityOwnerName">
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">MIS/Daily status/Weekly Status responsibility</label>
            <input type="textbox" class="form-control" formControlName="MISOwnerName">
          </div>
        </div>

        <p class="headingclass">Description</p>
        <div class="row">
          <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label for="text">Description</label>
            <textarea class="form-control" formControlName="Description" rows="3"></textarea>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>