<div class="tab_title">
  <h3 class="page_title pull-left">
    <span class="icon_holder new_case">
      <i class="fa fa-briefcase fa-fw" aria-hidden="true"></i>
    </span>
    Tickets ({{cases.length}})
  </h3>
</div>

<div class="related_details">
  <div class="row">
    <div class="col-md-6 col-xs-12 mb-10" *ngFor="let item of cases">
      <a class="dottedLine" (click)="gotoDetailPage(item.CaseId)" href="javascript:void(0)">{{item.CaseNumber}}</a>
      <table class="custom_table">
        <tr>
          <th class="custom_name">Contact Name</th>
          <th class="custom_colon">:</th>
          <td class="caseheadLine">{{item.ContactName}}</td>
        </tr>

        <tr>
          <th class="custom_name">Subject</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{item.Subject}}</td>
        </tr>

        <tr>
          <th class="custom_name">Priority Name</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{item.PriorityName}}</td>
        </tr>
      </table>
      
    </div>
  </div>
</div>