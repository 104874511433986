<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon profile glyphicon glyphicon-user">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Profile</p>
        <!-- <p class="page_sub_title">{{editName}}</p> -->
      </h3>
    </div>
    <div class="pull-right">
      <div  class="buttonHeader pull-right">
        <div  class="buttonNew" (click)="open(addTarget)">New</div>
      </div>
    </div>
  </div>




  <app-htmldatatable [tableData]="userProfileData" [columnName]="columnName" [columnRowName]="columnRowName"
    (edit)="edit($event)" (onDelete)="onDelete($event)">
  </app-htmldatatable>
</div>

<ng-template #addTarget let-modal>
  <div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header text-center">
    <h4 class="modal-title">Create Profile</h4>
  </div>
  <div class="modal-body">
  <div class="bg_white_margin_top column_editor min_top remove_box-show form-group">
    <form [formGroup]="ProfileForm">
    <div class="row form-group"> 
      <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">Login ID</label>
        <div>
          <input type="text" class="form-control" formControlName="UserNameId">
        </div>
      </div>

      <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
        <label class="control-label" for="text">Password</label>
        <div>
          <input type="password" class="form-control" formControlName="Password">
        </div>
      </div>
    </div>
    <div class="row form-group"> 
      <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">First Name</label>
        <div>
          <input type="text" class="form-control" formControlName="FirstName">
        </div>
      </div>

      <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">Last Name</label>
        <div>
          <input type="text" class="form-control" formControlName="LastName">
        </div>
      </div>
    </div>
    <div class="row form-group"> 
      <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">Is Active</label>
        <div>
          <select class="form-control" formControlName="IsActive">
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
      </div>


      <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">Profile Valid From</label>
        <div>
          <input type="text" class="form-control" #dp="bsDatepicker" formControlName="ProfileValidFrom"
            value="{{ ProfileForm.get('ProfileValidFrom').value | date: 'dd-MM-yyyy' }} " bsDatepicker
            [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
          <p class="inputdate" (click)="dp.toggle()">
            <i class="fa fa-calendar-alt fa-fw"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="row form-group"> 
      <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">Profile Valid To</label>
        <div>
          <input type="text" class="form-control" formControlName="ProfileValidTo"
            value="{{ ProfileForm.get('ProfileValidTo').value | date: 'dd-MM-yyyy' }} " #bsdp="bsDatepicker"
            bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
          <p class="inputdate" (click)="bsdp.toggle()">
            <i class="fa fa-calendar-alt fa-fw"></i>
          </p>
        </div>
      </div>



      <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="control-label" for="text">Resource</label>
        <div>
          <!-- <mat-form-field class="example-full-width app-input-no-underline customMaterial">
            <input #relatedTextInput matInput [matAutocomplete]="auto" (input)="getResourceList($event.target.value)"
              formControlName="ResourceID">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="relatedDisplayFn"
              (optionSelected)="setAutoCompleteResource($event.option.value.Value)">
              <mat-option *ngFor="let related of resourcefilteredOptions" [value]="related">
                <span>{{related.Text}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field> -->
          <app-autocomplete [autoCompleteText]="resourceText" (setAutoComplete)="setAutoCompleteResource($event)"
            [autoCompleteValue]="resourceValue" (fiteredData)="getResourceList($event)" [filteredOptions]="resourcefilteredOptions">
          </app-autocomplete>
        </div>
      </div>
    </div>
      <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>


      <nav class="navbar navbar-default custom_navbar grey_back">
        <div class="nav-bar-inner pb-4">
          <ul class="nav navbar-nav cl" routerLinkActive="active">
            <li class="nav-item" [ngClass]="{'active': address===true}">
              <a class="nav-link" (click)="OnAddress()">Address</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': companyRight===true}">
              <a class="nav-link" (click)="onCompanyRight()">Company Rights</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': security===true}">
              <a class="nav-link" (click)="onSecurityRight()">Security</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': pos===true}">
              <a class="nav-link" (click)="onPOS()">POS</a>
            </li>
          </ul>

          <ul class="cl custom_content_tab pl-0 pr-0">
            <li class="nav-item">
              <div *ngIf="address===true">
                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">Address1</label>
                  <div>
                    <input type="text" class="form-control" formControlName="Address1">
                  </div>
                </div>

                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">Address2</label>
                  <div>
                    <input type="text" class="form-control" formControlName="Address2">
                  </div>
                </div>

                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">Address3</label>
                  <div>
                    <input type="text" class="form-control" formControlName="Address3">
                  </div>
                </div>

                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">City</label>
                  <div>
                    <input type="text" class="form-control" formControlName="CityCode">
                  </div>
                </div>

                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">Country</label>
                  <div>
                    <select class="form-control" formControlName="CountryCode"
                      (change)="onCountryStateSelect($event.target.value)">
                      <option *ngFor="let country of CountryList" value={{country.Value}}>{{country.Text}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">State</label>
                  <div>
                    <select class="form-control" formControlName="State"
                      (change)="onStateDistrictSelect($event.target.value, code)">
                      <option *ngFor="let state of StateList" value={{state.Value}}>{{state.Text}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">District</label>
                  <div>
                    <select class="form-control" formControlName="DistrictCode">
                      <option *ngFor="let district of DistrictList" value={{district.Value}}>{{district.Text}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">Mobile</label>
                  <div>
                    <input type="text" class="form-control" formControlName="MobileNo">
                  </div>
                </div>

                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">Phone</label>
                  <div>
                    <input type="text" class="form-control" formControlName="Phone">
                  </div>
                </div>

                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">Fax</label>
                  <div>
                    <input type="text" class="form-control" formControlName="Fax">
                  </div>
                </div>

                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">Email</label>
                  <div>
                    <input type="text" class="form-control" formControlName="Email">
                  </div>
                </div>

                <div class="form-group form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="control-label" for="text">Pincode</label>
                  <div>
                    <input type="text" class="form-control" formControlName="PostalCode">
                  </div>
                </div>
              </div>
            </li>

            <li class="nav-item">
              <div *ngIf="companyRight===true">

                <div class="tablerow pl-3 pr-3">
                  <table class="table table-striped table-bordered dynomic_table">
                    <thead>
                      <tr>
                        <th class="number_small"></th>
                        <th class="name_long"><b>Company Name</b></th>
                        <th class="name_small"><b>Type</b></th>
                        <th class="name_short"><b>Role</b></th>
                        <th class="name_short"><b>Is Approved</b></th>
                        <th class="name_short"><b>Allow back dates</b></th>
                        <th class="number_medium"><b>No. of Days</b></th>
                        <th class="number_short"
                          style="text-overflow: ellipsis; white-space: nowrap;  overflow: hidden;">
                          <b>Max Disc.</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let list of userProfileForm.CRMUserPermissions; let i=index">
                        <td>
                          <input class="custom_checkbox" type="checkbox" [(ngModel)]="list.IsSelected"
                            (change)="onIsSelected($event, i)" [ngModelOptions]="{standalone: true}">
                        </td>
                        <td>
                          <label for="name" style="margin-left: 10px;">{{list.CompanyName}}</label>
                          <input type="hidden" class="form-control" [(ngModel)]="list.CompanyName"
                            [ngModelOptions]="{standalone: true}">
                        </td>
                        <td>
                          <input type='radio' name="list.IsPrimary" (change)="valueChange($event.target.value, i)"
                            [checked]="list.IsPrimary" value={{list.IsPrimary}}>
                          <input type="hidden" [(ngModel)]="list.IsPrimary" [ngModelOptions]="{standalone: true}">
                        </td>
                        <td>
                          <select [disabled]="!list.IsSelected" class="form-control" [(ngModel)]="list.RoleId"
                            [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let role of RoleNameList" value={{role.Value}}>{{role.Text}}</option>
                          </select>
                        </td>
                        <td>
                          <select [disabled]="!list.IsSelected" class="form-control" [(ngModel)]="list.IsApproved"
                            [ngModelOptions]="{standalone: true}">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </td>
                        <td>
                          <select [disabled]="!list.IsSelected" class="form-control" [(ngModel)]="list.AllowBackDays"
                            [ngModelOptions]="{standalone: true}">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </td>
                        <td>
                          <input type="textbox" class="form-control" [(ngModel)]="list.NoOfDays"
                            [ngModelOptions]="{standalone: true}">
                        </td>
                        <td>
                          <input type="textbox" class="form-control" [(ngModel)]="list.MaxDisc"
                            [ngModelOptions]="{standalone: true}">
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </li>

            <li class="nav-item">
              <div *ngIf="security===true">

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Control User Login</label>
                  <div class="col-sm-4">
                    <select class="form-control" (change)="onControl($event.target.value)"
                      formControlName="ControlUserLogin">
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
                </div>

                <div class="" *ngIf="disableControl===true">
                  <div class="form-group">
                    <label class="control-label col-sm-2" for="text">Login Starting Time</label>
                    <div class="col-sm-4">
                      <input type="text" class="form-control" formControlName="LoginStartingTime">
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="control-label col-sm-2" for="text">Login End Time</label>
                    <div class="col-sm-4">
                      <input type="text" class="form-control" formControlName="LoginEndTime">
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="control-label col-sm-2" for="text">IP Address</label>
                    <div class="col-sm-4">
                      <input type="text" class="form-control" formControlName="UserIPAddress">
                    </div>
                  </div>

                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">IMEI No</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="IMEINO">
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <div *ngIf="pos===true">
                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">Transaction Type</label>
                  <div class="col-sm-4">
                    <select class="form-control" formControlName="TransactionTypeId">
                      <option *ngFor="let transaction of TransactionList" value={{transaction.Value}}>{{transaction.Text}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-sm-2" for="text">⁠Brach A/c Ledger</label>
                  <div class="col-sm-4">
                    <app-autocomplete [autoCompleteText]="BranchAccountLedgerName" (setAutoComplete)="setAutoCompleteClient($event)"
                      [autoCompleteValue]="clientValue" (fiteredData)="getClientList($event)" [filteredOptions]="clientfilteredOptions">
                    </app-autocomplete>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>


      <div class="form-group text-right">
        <button type="button" class="btn btn-default" (click)="resetForm()">Cancel</button>
        <button type="button" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
      </div>
    </form>
  </div>
  </div>
</ng-template>