import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentService } from '../../../services/documentService/document.service';
import { VariableService } from '../../../services/VariableService/variable.service';
import { ToastrService } from 'ngx-toastr';


import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray
} from '@angular/forms';


@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.css']
})
export class ImportDataComponent implements OnInit {
  modal:any;
  stepOne=true;
  fileList:any;
  fieldMappingForm:FormGroup
  formDataReady=false;
  fileData:any;
  StepOneLoader=false;
  MaxFileSize=512000;
  showSpinner: Boolean = false;

  constructor(private renderer: Renderer2,private modalService:NgbModal,private documentService:DocumentService,private fb: FormBuilder,private toastr: ToastrService) { }

  ngOnInit(): void {
   
  }
  openModel(importModel){
    this.modal = this.modalService.open(importModel, { size: 'lg', backdrop: 'static', keyboard: false });

  }
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files) {
    if((files[0].size)/1024 > this.MaxFileSize){
      this.toastr.error('Please verify the file Size max will support '+this.MaxFileSize+'KB');
    }else{
      const formData = new FormData(); 
      formData.append("file", files[0], files[0].name);

      for (const item of files) {
        item.progress = 0;
        item.formData = formData;
        this.files.push(item);
      }
      this.uploadFilesSimulator(0);
      this.toastr.success('File received Successfully click on Next button');
    }

  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  nextStep(){
    this.StepOneLoader = true;
    this.documentService.uploadDocs(this.files[0].formData).subscribe((data)=>{
      this.fileList = data;
      this.stepOne=false;
      this.StepOneLoader = false;
    },()=>{
      this.toastr.error('Please verify the file Size/Formate which is not supporting.');
      this.StepOneLoader = false;
    })
  }
  prevStep(){
    this.stepOne=true;
  }
  selectFile(value){
   this.documentService.getDocFields(value,this.fileList.FileImportId).subscribe((data)=>{
      this.fileData = data;
      this.constructForm(value,this.fileList.FileImportId,data)
   })
  }

  get ImportFileDetails() {
    return this.fieldMappingForm.get('ImportFileDetails') as FormArray;
  }

  getField(row,dbcolums){
    let colName = this.getColumnName(row,dbcolums);
    let obj={
      Id:null,
      TableColumnName:new FormControl(colName,Validators.required),
      FileHeaderName: row.FileHeaderName
    }
    console.log('obj',obj);
    return obj;
  }
  getColumnName(row,dbcolums){
    let colName = null;
    dbcolums.forEach((val)=>{
      if(val.Value == row.FileHeaderName){
        colName = val.Value;
      }
    })
    return colName;
  }
  constructForm(sheet,id,data){
    this.fieldMappingForm = this.fb.group({
      MasterType:'',
      Id:id,
      SheetName:sheet,
      ImportFileDetails:this.fb.array([],Validators.required)
    });
    data.ImportFileDetails.forEach((value)=>{
      this.ImportFileDetails.push(this.fb.group(this.getField(value,data.TableColumnNameList)))
    });
    this.formDataReady = true;
  }
  saveForm(){
    this.showSpinner=true;
    this.documentService.saveMappedFields(this.fieldMappingForm.value).subscribe((res)=>{
      this.stepOne=true;
      this.files = [];
      this.modal.close();
      this.showSpinner=false;
    })
  }

  downloadFile() {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '/assets/Format.xlsx');
    link.setAttribute('download', `Format.xlsx`);
    link.click();
    link.remove();
 }
}
