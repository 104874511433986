import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';

let tableFiltersVar: any[] = [];

@Component({
  selector: 'column-filter',
  templateUrl: './columnfilter.component.html',
  styleUrls: ['./columnfilter.component.css']
})
export class ColumnFilterComponent implements OnInit, OnChanges {
  @Input() columns: Array<any> = [];
  @Output() setColumnsVisible = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  cols = {};
  colsType = 'add';

  constructor(
  ) { }
  ngOnInit() { }

  addCols(col, val) {
    if (this.colsType === 'remove') {
      this.cols = {};
      // this.colsType = "add";
    }
    this.colsType = 'add';
    if (!this.cols[col.Name]) {
      this.cols[col.Name] = col.Name;
    } else {
      delete this.cols[col.Name];
    }
  }

  rmCols(col, val) {
    if (this.colsType === 'add') {
      this.cols = {};
      // this.colsType = "remove";
    }
    this.colsType = 'remove';
    if (!this.cols[col.Name]) {
      this.cols[col.Name] = col.Name;
    } else {
      delete this.cols[col.Name];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      const change = changes[propName];
      const curVal = JSON.stringify(change.currentValue);
      const prevVal = JSON.stringify(change.previousValue);
    }
  }

  setCols(val) {
    this.setColumnsVisible.next({
      oldCols: this.columns,
      newCols: this.cols,
      val: val
    });
    this.cols = {};
  }
}
