<div class="tablerow order_summary_div">
  <div class="scroll_table member_details">
    <table class="table table-striped table-bordered dynomic_table">
      <!-- <thead>
        <tr>
          <td class="sub_table_cont">
            <div class="scroll_table_header">
              <table>
                
              </table>
            </div>
          </td>
        </tr>
      </thead> -->
      <!-- <tbody> -->
        <thead>
        <tr>
          <th class="text-left item_name_field">
            Item Name
          </th>
          <th class="showButton">
            Quantity
          </th>
          <th class="text-right price_field">
            Price
          </th>
          
          <th class="text-right price_field">
            Disc %
          </th>
          <th class="text-right price_field">
            Amount
          </th>
          <th class="text-right price_field" [hidden]="expand">
            Tax Rate
          </th>
          <th class="text-right price_field" [hidden]="expand">
            Tax Amount
          </th>
          <th class="text-right price_field" [hidden]="expand">
            Total Value
          </th>
          <th class="remove_item"></th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of setPosData; let i=index" class="table-background">
          <td class="text-left item_name_field">
            {{item.Text}}
          </td>
          <td class="showButton">
            <button (click)="itemActions.next({item:item, key:'sub'})" class="btn btn-raised btn-warning" title="Remove One">
              <i class="fas fa-minus"></i>
            </button>
            <input type="text" class="form-control custom-input" value="{{item.quantity}}"/>
            <button (click)="itemActions.next({item:item, key:'add'})" class="btn btn-raised btn-success" title="Add One">
              <i class="fas fa-plus"></i>
            </button>
          </td>
          <td class="text-right price_field disc-field">
            <input type="text" class="form-control custom-input full-input" value="{{item.Price}}" (change)="itemActions.next({item:item, key:'update',name:'price',event:$event})"/>
          </td>
          
          <td class="text-right price_field disc-field">
            <input type="text" class="form-control custom-input full-input" (change)="itemActions.next({item:item, key:'update',name:'dis',event:$event})" />
          </td>
          <td class="text-right">
            {{item.finalPrice.toFixed(2)}}
          </td>
          <td class="text-right price_field disc-field p-3" [hidden]="expand">
            {{item.TaxRate}}
          </td>
          <td class="text-right price_field disc-field p-3" [hidden]="expand">
            {{item.taxAmount.toFixed(2)}}
          </td>
          <td class="text-right price_field disc-field p-3" [hidden]="expand">
            {{item.taxAmount + item.finalPrice}}
          </td>
          <td class="remove_item">
            <button (click)="itemActions.next({item:item, key:'remove'})" class="btn btn-raised btn-danger" title="Remove">
              <i class="fas fa-times"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <!-- <form name="pointOfSaleForm" #pointOfSaleForm="ngForm"> -->
  <!-- <div class="col-md-6">
    <div class="member_details_cont">
      <h5 class="member_title">Member Details</h5>
      <div class="search_cont">
        <input type="text" placeholder="Mobile No / Name" class="search_member">
        <span class="display_member"><i class="fas fa-search"></i></span>
      </div>
      <div class="member_details">
       
          <table class="table table-bordered">
            <tr>
              <th>Name</th>
              <td>
                <input type="text"  name="CustomerName" class="form-control" [(ngModel)]="pointOfSaleForm.CustomerName">
              </td>
            </tr>
            <tr>
              <th>Mobile</th>
              <td>
                <input type="text" name="MobileNo" class="form-control" [(ngModel)]="pointOfSaleForm.MobileNo">
              </td>
            </tr>
            <tr>
              <th>Email</th>
              <td>
                <input type="text" name="Email" class="form-control" [(ngModel)]="pointOfSaleForm.Email">
              </td>
            </tr>
            <tr>
              <th>Address</th>
              <td>
                <input type="text" name="Address" class="form-control" [(ngModel)]="pointOfSaleForm.Address">
              </td>
            </tr>
            <tr>
              <th>Mode Of Payment</th>
              <td>
                <select class="form-control"  name="ModeofPay" [(ngModel)]="pointOfSaleForm.ModeofPay">
                  <option value="Cash">Cash</option>
                  <option value="Card">Card</option>
                </select>
              </td>
            </tr>
          </table>
       
      </div>
    </div>
  </div> -->
  <div class="col-md-12">
    <div class="custom_bill_details order_summary_div">
      <table class="custom_table">
        <thead>
          <th>Subtotal</th>
          <!-- <th>Discounts</th> -->
          <th>Tax</th>
          <th>Total</th>
        </thead>
        <tbody>
          <tr>
            <td>{{subTotal}}</td>
            <!-- <td>{{totalDiscount}}</td> -->
            <td>{{taxAmount}}</td>
            <td>{{totalCost}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- <div class="col-md-12 mb-2">
    <div class="paymentTypes">
      <span class="radio"><label><input type="radio" name="optradio" value="cash" >Cash</label></span>
      <span class="radio"><label><input type="radio" name="optradio" value="card" >Card</label></span>
    </div>
  </div> -->
  <div class="col-md-12">
    <div class="col-md-6">
      <button class="btn btn_submit" type="button" (click)="print('print')">Save & Print</button>
    </div>
    <div class="col-md-6">
      <button class="btn btn_submit" type="button" (click)="print('pay')">Pay & Print</button>
    </div>
  </div>
<!-- </form> -->
</div>

