import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-project-task-detail',
  templateUrl: './project-task-detail.component.html',
  styleUrls: ['./project-task-detail.component.css']
})
export class ProjectTaskDetailComponent implements OnInit {
  projectTaskForm: UntypedFormGroup;
  @Input() projectTaskdata: any;
  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.projectTaskForm = this.fb.group({
      ProjectTaskId: null,
      AssignedId: '',
      Status: '',
      Subject: '',
      ContactId: '',
      UserId: '',
      RelatedToId: '',
      DueDate: '',
      AssignedTo: '',
      Description: '',
      CallDisposition: '',
      EstimatedHours: '',
      EstimatedExpense: '',
      TaskPriority: '',
      Stage: '',
      ProjectTaskName: '',
      ProjectMilestone: '',
      StartDate: '',
      ProjectId: '',
      OwnerName: '',
      MilestoneName: '',
      ProjectName: '',

      MilestonePercent: '',
      MilestoneWeightage: '',
    });
    this.projectTaskForm.patchValue(this.projectTaskdata);
  }

}
