<div class="">
    <form [formGroup]="logCallForm" novalidate (ngSubmit)="onSubmit(logCallForm)">
        <div class="form-group ">
            <label for="name">Subject</label>
            <input type="textbox" class="form-control" formControlName="Subject">
            <input type="hidden" formControlName="CallLogId">
        </div>
        <div class="form-group">
            <label for="name">Comments</label>
            <textarea class="form-control" rows="3" formControlName="Comments"></textarea>
        </div>

        <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 two_input">
                <label for="name">Name</label>
                <br />
                <div class="select_cont mini_cont">
                    <ng-select [items]="NameTypeList" bindLabel="name" bindValue="name" lass="form-control selectType half_select"
                        [clearable]="false" formControlName="NameType" id="contactLeadTo">
                        <ng-template ng-label-tmp let-item="item">
                            <span class="{{item.avatar}}"></span>
                            <span style="padding-left:10px;">{{item.name}}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <span class="{{item.avatar}}"></span>
                            <span style="padding-left:10px;">{{item.name}}</span>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="select_cont max_cont">
                    <mat-form-field class="example-full-width app-input-no-underline material_normalise customMaterial"
                        id="contactLeadTo">
                        <input #contactLeadtextInput matInput [matAutocomplete]="auto" (input)="getLeadContactList($event.target.value)"
                            formControlName="ContactLeadId">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayContactFn">
                            <mat-option *ngFor="let contactLead of contactLeadFilterOption" [value]="contactLead">
                                <span>{{contactLead.Text}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group  col-lg-12 col-md-12 col-sm-12 col-xs-12 two_input" *ngIf="disableDropdown===true">
                <label for="text">Related To</label>
                <br />
                <div class="select_cont mini_cont">
                    <ng-select [items]="RelatedTypeList" bindLabel="name" bindValue="name" lass="form-control selectType half_select"
                        [clearable]="false" formControlName="RelatedType" id="contactLeadTo">
                        <ng-template ng-label-tmp let-item="item">
                            <span class="{{item.avatar}}"></span>
                            <span style="padding-left:10px;">{{item.name}}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <span class="{{item.avatar}}"></span>
                            <span style="padding-left:10px;">{{item.name}}</span>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="select_cont max_cont">
                    <mat-form-field class="example-full-width app-input-no-underline material_normalise customMaterial">
                        <input #relatedtextInput matInput [matAutocomplete]="auto" (input)="getRealtedTypeList($event.target.value)"
                            formControlName="RelatedToId">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayRelatedFn">
                            <mat-option *ngFor="let related of relatedTypeFilterOption" [value]="related">
                                <span>{{related.Text}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="text-right">
            <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!logCallForm">Save</button>
        </div>
    </form>
</div>