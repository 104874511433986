import { ProjectTaskTemplateService } from './../../../services/project-task-template/project-task-template.service';
import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeInfoService } from './../../../services/employeeInfo/employee-info.service';
import { ProjectTaskService } from './../../../services/projectTask/ProjectTask-Service/project-task.service';
import { MasterLookupService } from './../../../services/common/master-lookup/master-lookup.service';
import { DatePipe } from '@angular/common';
import { AutocompleteService } from './../../../services/autocomplete/autocomplete.service';
import { EventEmitter, ViewContainerRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output } from '@angular/core';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { EmailService } from '../../../services/common/activity-page/email/email.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GlobalService } from '../../../services/global-Service/global.service';

declare var $: any;
@Component({
  selector: 'app-new-project-task',
  templateUrl: './new-project-task.component.html',
  styleUrls: ['./new-project-task.component.css']
})
export class NewProjectTaskComponent implements OnInit {
  @Output() getData = new EventEmitter();
  @ViewChild('sendContent') sendContent: ModalDirective;
  projectTaskNameList: any;
  public PriorityList: AutocompleteModel[];
  public CaseStatusModel: AutocompleteModel[];
  projectTaskFormData: any;
  projectTaskForm: UntypedFormGroup;
  projectfilteredOptions: any[];
  milestonefilteredOptions: any[];
  assignedTofilteredOptions: any[];
  projectText: any; projectValue: any;
  milestoneText: any; milestoneValue: any;
  assignedToText: any; assignedToValue: any;
  EditNew: any; relatedId: any;
  public TaskTemplate: AutocompleteModel[];
  TaskTemplateList: [];
  subject = 'Project Task is created';
  fromAddress: any = null;
  toAddress: any = null;
  fromName: '';
  selectedProjectName: any;
  textBody = 'Project task is created for the opportunity';
  constructor(
    private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private datePipe: DatePipe,
    public activeModal: NgbActiveModal,
    private globalService: GlobalService,
    vcr: ViewContainerRef,
    private emailService: EmailService,
    private configService: ConfigurationService,
    private masterLookupService: MasterLookupService,
    private projectTaskService: ProjectTaskService,
    private employeeInfoService: EmployeeInfoService,
    public modalService: NgbModal,
    private projectTaskTemplateService: ProjectTaskTemplateService
  ) { }

  ngOnInit() {
    this.projectTaskForm = this.fb.group({
      ProjectTaskId: null,
      Status: '',
      Subject: '',
      ContactId: '',
      UserId: '',
      RelatedToId: '',
      DueDate: '',
      AssignedTo: '',
      Description: '',
      CallDisposition: '',
      EstimatedHours: '',
      EstimatedExpense: '',
      TaskPriority: '',
      Stage: '',
      ProjectTaskName: '',
      ProjectMilestone: '',
      StartDate: '',
      ProjectId: '',

      MilestonePercent: '',
      MilestoneWeightage: '',
      ProjectTaskTypeId: '',
    });
    this.getAllTaskTemplateList();
    this.getCaseStatusList();
    this.getCasePriorityList();
    this.getAllEmployeeInfo();
    this.getEditData();
    this.getEditNewName();
  }

  getEditNewName() {
    if (this.projectTaskForm.value.ProjectTaskId === null) {
      this.EditNew = 'New';
    } else {
      this.EditNew = 'Edit';
    }
  }

  getEditData() {
    if (this.projectTaskFormData !== undefined) {
      this.projectTaskForm.patchValue(this.projectTaskFormData);

      this.projectText = this.projectTaskFormData.ProjectName;
      this.projectValue = this.projectTaskFormData.ProjectId;

      this.milestoneText = this.projectTaskFormData.MilestoneName;
      this.milestoneValue = this.projectTaskFormData.ProjectMilestone;

      this.assignedToText = this.projectTaskFormData.OwnerName;
      this.assignedToValue = this.projectTaskFormData.AssignedTo;

      this.selectedProjectName = this.projectTaskFormData.ProjectTaskName;
      this.projectTaskForm.controls['StartDate'].setValue(new Date(this.projectTaskForm.value.StartDate));
      this.projectTaskForm.controls['DueDate'].setValue(new Date(this.projectTaskForm.value.DueDate));

    }
  }

  getProjectList(value) {
    this.autocompleteService.getAllProjectList(value.data).subscribe(
      res => {
        this.projectfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteProject(data) {
    const projectId = data.data.option.value.Value;
    this.projectTaskForm.controls['ProjectId'].setValue(projectId);
  }

  getMilestoneList(value) {
    const projectId = this.projectTaskForm.value.ProjectId;
    this.autocompleteService.getMilestoneProjectList(value.data, projectId).subscribe(
      res => {
        this.milestonefilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteMilestone(data) {
    const milestoneId = data.data.option.value.Value;
    this.projectTaskForm.controls['ProjectMilestone'].setValue(milestoneId);
  }

  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMProjectTasks').subscribe(
      res => {
        this.assignedTofilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  getAllTaskTemplateList() {
    this.configService.getTaskTemplateList().subscribe(data => {
      this.TaskTemplate = data;
    });
  }

  onSelectTemplate(tempId) {
    this.projectTaskTemplateService.getAllProjectTaskTemplate(tempId).subscribe(data => {
      this.TaskTemplateList = data;
    });
  }

  onSelectedList(name) {
    this.selectedProjectName = name;
  }

  numberOnly(event): boolean {
    const keycode = (event.which) ? event.which : event.keyCode;
    if (!(keycode === 8 || keycode === 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      const parts = event.srcElement.value.split('.');
      if (parts.length > 1 && keycode === 46) {
        return false;
      }
      return true;
    }
  }

  setAutoCompleteEmployee(data) {
    const assignedID = data.data.option.value.Value;
    this.projectTaskForm.controls['AssignedTo'].setValue(assignedID);
    this.employeeInfoService.getEmployeeInfoList(assignedID).subscribe(res => {
      this.toAddress = res.EmailID;
      this.fromName = res.EmployeeName;
    });
  }

  getCaseStatusList() {
    this.masterLookupService.getDropdownList('Project Task Status').subscribe(data => {
      this.CaseStatusModel = data;
    });
  }

  getCasePriorityList() {
    this.masterLookupService.getDropdownList('Case Priority').subscribe(data => {
      this.PriorityList = data;
    });
  }

  sendEmail() {
    if (this.fromAddress != null && this.toAddress != null) {
      this.emailService.sendEmail({
        Subject: this.subject,
        FromAddress: this.fromAddress,
        ToAddress: this.toAddress,
        FromName: this.fromName,
        TextBody: this.textBody,
        RelatedToId: this.relatedId,
      }).subscribe(senddata => { });
    }
    $('#btnCancel').trigger('click');
  }

  getAllEmployeeInfo() {
    this.employeeInfoService.getAllInfo().subscribe(infodata => {
      this.fromAddress = infodata.EmailID;
    });
  }

  onSendMail() {
    this.modalService.open(this.sendContent, { size: 'sm', backdrop: 'static' });
  }

  dateFormat() {
    this.projectTaskForm.value.StartDate = this.datePipe.transform(this.projectTaskForm.value.StartDate, 'yyyy-MM-dd');
    this.projectTaskForm.value.DueDate = this.datePipe.transform(this.projectTaskForm.value.DueDate, 'yyyy-MM-dd');
  }
  onSubmit(form: UntypedFormGroup) {
    this.dateFormat();
    const projectTaskName = form.value.ProjectTaskName;

    let responseMessage = [];
    if (form.value.ProjectTaskId == null) {
      this.projectTaskService.addProjectTask(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        this.relatedId = data.ErrorMessages[3];
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Project Task', name: projectTaskName });
          this.onSendMail();
        }
        this.getData.emit();
      });
      // Update Method for Project Task
    } else {
      this.projectTaskService.updateProjectTask(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        this.relatedId = data.ErrorMessages[3];
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Project Task', name: projectTaskName });
          this.onSendMail();
        }
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.projectTaskForm.reset();
    this.globalService.disposeToastr();
  }
}
