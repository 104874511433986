import { MilestoneService } from './../../../services/milestone/milestone.service';
import { NewMilestoneComponent } from './../../milestone-page/new-milestone/new-milestone.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-milestone-related',
  templateUrl: './milestone-related.component.html',
  styleUrls: ['./milestone-related.component.css']
})
export class MilestoneRelatedComponent implements OnInit {
  @Input() milestoneProjectData: any;
  milestone: Array<any> = [];
  @Input() searchKeys;
  constructor(private modalService: NgbModal,
    private milestoneService: MilestoneService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.getAllMilestones();
  }

  getAllMilestones() {
    this.milestoneService.getMilestoneType().subscribe(data => {
      this.milestone = data;
    });
  }
  openMilestone() {
    const modalRef = this.modalService.open(NewMilestoneComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.milestoneProjectData = this.milestoneProjectData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAllMilestones();
    });
  }

  gotoDetailPage(id) {
    const route = 'milestoneDetail/' + id;
    this.router.navigate([route]);
  }
}
