<h3 class="report_page_title">
  <div class="dropdown drop_list_items">
    <p class="dropdown-toggle page_sub_title" data-toggle="dropdown">
      {{selectedPageFilter.FilterName}}
    </p>
    <ul class="dropdown-menu">
      <li *ngFor="let filter of pageFilters">
        <a href="javascript:void(0)" (click)="getPageFilterData.emit(filter)">{{filter.FilterName}}</a>
      </li>
    </ul>
  </div>
</h3>