import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { Component, OnInit } from '@angular/core';
import { NewEventService } from '../../../services/common/activity-page/newEvent/new-event.service';
import { ActivatedRoute } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {
  DetailOn = true;
  showSpinner: Boolean = true;
  eventData: any;
  selectedPage: any = {};
  constructor(
    private route: ActivatedRoute,
    private eventService: NewEventService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {

    this.route.params.subscribe(params => {

        this.variableService.setSearchKeys({
          paramId: params.id,
          type: 'CRMEvent'
        });

        this.eventService.newEventById(params.id).subscribe(data => {
          this.eventData = data;
          this.showSpinner = false;
        });

      });
  }
  OnDetail() {
    this.DetailOn = true;
  }

}
