import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class ProjectOrderService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }
  getProjectsOrder() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/projectOrder/getall?BaseId=${q.paramId}&`);
  }

  getProjectsOrderById(orderID) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/projectOrder/getall?BaseId=${q.paramId}&Id=${orderID}&`);
  }

  getProjectInvoiceList(transNo) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/projectInvoice/getbyid?Id=${q.paramId}&ListOfTransaction=${transNo}&`);
  }

  createProjectInvoiceList(form) {
    return this.apiService.post('api/projectInvoice/create', form);
  }

  getAllProjectInvoice() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/projectInvoice/getall?Id=${q.paramId}&`);
  }

}
