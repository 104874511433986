<div class="row text-center calender_header">
  <div class="col-md-4">
    <div class="btn-group btnchange">
      <div class="btn btn-warning" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
        Previous
      </div>
      <div class="btn btn-info" mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
        Present
      </div>
      <div class="btn btn-warning" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
        Next
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</h3>
  </div>
  <div class="col-md-4">
    <div class="btn-group btnMonth">
      <div class="btn btn-primary" (click)="viewChange.emit('month')" [class.active]="view === 'month'">
        Month
      </div>
      <div class="btn btn-primary" (click)="viewChange.emit('week')" [class.active]="view === 'week'">
        Week
      </div>
      <div class="btn btn-primary" (click)="viewChange.emit('day')" [class.active]="view === 'day'">
        Day
      </div>
    </div>
  </div>
</div>
<br>