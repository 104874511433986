import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';

@Injectable()
export class UserProfileService {

  constructor(
    private apiService: ApiService,
  ) { }

  getUserProfile() {
    return this.apiService.get(`api/userProfile/getall?`);
  }

  addUserProfile(form) {
    return this.apiService.post('api/userProfile/create', form);
  }

  editUserProfileById(userProfileId, name) {
    return this.apiService.get(`api/userProfile/getbyid?Id=${userProfileId}&Text=${name}&`);
  }

  getByUserProfileId() {
    return this.apiService.get(`api/userProfile/getbyid?&`);
  }

  updateUserProfile(form) {
    return this.apiService.put('api/userProfile/edit', form);
  }

}
