import { GlobalService } from './../../../services/global-Service/global.service';
import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../../services/Employee/employee.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { EmailService } from '../../../services/common/activity-page/email/email.service';
import { DatePipe } from '@angular/common';
import { VariableService } from './../../../services/VariableService/variable.service';
import { EmployeeInfoService } from './../../../services/employeeInfo/employee-info.service';
import { RfqService } from './../../../services/common/activity-page/rfq/rfq.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-rfq-page',
  templateUrl: './rfq-page.component.html',
  styleUrls: ['./rfq-page.component.css']
})
export class RfqPageComponent implements OnInit {
  rfqForm: UntypedFormGroup;
  EmployeeId: any;
  toAddress = null;
  fromAddress = null;
  fromName: any;
  employeeInfoData: any;
  relatedId: any;
  textBody: any = 'Dear Sir , New request for quote is created ';
  jobWork: any;
  TerritoryList: AutocompleteModel[];
  public autoCompleteEmployee: AutocompleteModel[];
  rfqDetails: Array<any> = [];
  responseMessage: any;
  constructor(
    private fb: UntypedFormBuilder,
    private rfqService: RfqService,
    private employeeService: EmployeeService,
    private emailService: EmailService,
    private employeeInfoService: EmployeeInfoService,
    private variableService: VariableService,
    private datePipe: DatePipe,
    private configService: ConfigurationService,
    private globalService: GlobalService,
    private modalService:NgbModal,
  ) {
  }

  ngOnInit() {
    const q: any = this.variableService.getSearchKeys();
    this.rfqForm = this.fb.group({
      RFQId: null,
      UserId: '',
      Remarks: '',
      JobWork: '',
      RelatedToId: q.paramId,
      DeadLine: new Date,
      AssignedToId: '',
      TotalHoursBudget: '',
      TerritoryId: '',
    });
    this.getAllEmployeeList();
    this.getAllRfq();
    this.getTerritoryList();
  }
  getAllRfq() {
    this.rfqService.getRfqType().subscribe(data => {
      this.rfqDetails = data;
    });
  }

  openRFQForm(rfqModel){
    this.modalService.open(rfqModel, { size: 'sm', backdrop: 'static', keyboard: false });
  }

  getAllEmployeeList() {
    this.employeeService.getEmployeeList().subscribe(data => {
      this.autoCompleteEmployee = data.Result;
    });
  }

  onEmployeeSelect(value) {
    this.EmployeeId = value;
    this.getEmployeeInfo();
  }

  getEmployeeInfo() {
    this.employeeInfoService.getEmployeeInfoList(this.EmployeeId).subscribe(data => {
      this.employeeInfoData = data;
      // this.toAddress = data.EmailID;
      this.fromName = data.EmployeeName;
    });
  }

  // getAllEmployeeInfo() {
  //   this.employeeInfoService.getAllInfo().subscribe(infodata => {
  //     this.fromAddress = infodata.EmailID;
  //   });
  // }

  getTerritoryList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }

  onSubmit(form: UntypedFormGroup,rfqModel) {
    this.jobWork = form.value.JobWork;
    this.relatedId = form.value.RelatedToId;
    // this.textBody = 'Dear Sir ,' + '<br/>' + 'New request for quote is created for the jobwork' + '"' + this.jobWork + '"' +;
    form.value.DeadLine = this.datePipe.transform(form.value.DeadLine, 'yyyy-MM-dd');
    this.rfqService.createRfq(form.value).subscribe(data => {
      this.fromAddress = data.ErrorMessages[2];
      this.toAddress = data.ErrorMessages[3];
      this.responseMessage = data.ErrorMessages;
      if (this.responseMessage[1] === '0') {
        this.globalService.showDefaultUpdate({ masterName: 'Request for quote' });
      }
      this.sendEmail();
      this.getAllRfq();
      this.modalService.dismissAll();
    });
    this.resetForm();
  }
  resetForm() {
    this.rfqForm.patchValue({
      RFQId: null,
      UserId: '',
      Remarks: '',
      JobWork: '',
      DeadLine: new Date,
      AssignedToId: '',
      TotalHoursBudget: '',

      TerritoryId: '',
    });
  }

  sendEmail() {
    if (this.toAddress != null || this.fromAddress != null) {
      this.emailService.sendEmail({
        Subject: this.jobWork,
        FromAddress: this.fromAddress,
        ToAddress: this.toAddress,
        FromName: this.fromName,
        TextBody: this.textBody,
        RelatedToId: this.relatedId,
      }).subscribe(senddata => { });
    }
  }
}

