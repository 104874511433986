<div class="">

    <form [formGroup]="chatterForm" novalidate (ngSubmit)="onSubmit(chatterForm)">

        <div class="form-group">
            <label for="name">To
                <span class="masterText">{{MasterType}}</span>
            </label>
            <div class="editor">
                <!-- <app-ngx-editor [config]="editorConfig" formControlName="TextPosted"></app-ngx-editor> -->
                <ngx-editor-menu [editor]="editor"  [toolbar]="this.editorConfig.toolbar"> </ngx-editor-menu>
                <ngx-editor
                formControlName="TextPosted"
                    [editor]="editor"
                    [ngModel]="html"
                    [disabled]="false"
                    [placeholder]="'Type here...'"
                ></ngx-editor>
            </div>
        </div>

        <div class="text-right">
            <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!chatterForm">Share</button>
        </div>
    </form>
</div>


<div class="related_details">
    <div class="row">
        <div class="col-md-8 mb-10" *ngFor="let chatter of chatterDetail">
            <div class="chatter">
                <img src=" /assets/images/userAccount.png " class="iconClass ">
                <p class="">
                    <span class="dottedTextLine">{{chatter.PostBy}}</span>
                </p>
                <p class="bodyText" [innerHTML]=chatter.TextPosted>{{chatter.TextPosted}}</p>
            </div>
        </div>
    </div>
</div>