<div >
    <div class="mt-4">
      <div class="" style="margin-bottom: 10px;">
        <span class="menu_icon CRMContracts fa fa-file">
        </span>
        <h3 class="report_page_title">
            <!--  ({{contractorsData?.length?contractorsData.length:'0'}}) -->
          <p class="page_title_cont">Contracts</p>
        </h3>
  
        <div class="btn-group pull-right" dropdown style="margin-top: 10px;">
          <button type="button" dropdownToggle class="btn btn-default dropdown-toggle" style="margin-top:-9px">
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li role="menuitem">
              <a class="dropdown-item" href="javascript:void(0)" (click)="open()">New Contract</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  
  
    <!-- Delete Quote -->
    <!-- <ng-template #deleteContent let-c="close" let-d="dismiss" style="pos-rel">
      <div class="close-btn-container pos-abs">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-header">
        <h4 class="modal-title text-center">Delete Contract</h4>
      </div>
      <div class="modal-body">
        <p class="modalparagraph">Are you sure you want to delete this Contract?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="deleteQuote()">Delete</button>
      </div>
    </ng-template> -->
  
  
    <div class="related_details">
      <div class="row">
        <!-- *ngFor="let quoteList of contractorsData" -->
        <div class="col-md-12 col-xs-12 mb-10" >
  
          <!-- <div class="btn-group pull-right" dropdown style="margin-right:-10px;">
            <button type="button" dropdownToggle class="btn btn-default dropdown-toggle">
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li role="menuitem">
                <a class="dropdown-item" href="javascript:void(0)" (click)="
                editOpen({masterId:quoteList.QuoteID})">Edit</a>
              </li>
              <li role="menuitem">
                <a class="dropdown-item" href="javascript:void(0)" (click)="onDelete(deleteContent,quoteList.QuoteID , quoteList.QuoteName)">Delete</a>
              </li>
            </ul>
          </div> -->
          <table class="custom_table">
            <tr>
              <th class="custom_name">Contract Number</th>
              <th class="custom_colon">:</th>
              <td class="custom_detail_name" title="{{contractorsData.ContractName}}">          <a class="dottedLine" (click)="gotoDetailPage(contractorsData.ContractId)" href="javascript:void(0)">{{contractorsData.ContractNumber}}</a>
              </td>
            </tr>
            <tr>
              <th class="custom_name">Contract Name</th>
              <th class="custom_colon">:</th>
              <td class="custom_detail_name" title="{{contractorsData.ContractName}}">{{contractorsData.ContractName}}</td>
            </tr>
  
            <tr>
              <th class="custom_name">Expiry Date</th>
              <th class="custom_colon">:</th>
              <td class="custom_detail_name">{{contractorsData.EndDate | date:'dd-MM-yyyy'}}</td>
            </tr>
  
            <tr>
              <th class="custom_name">Account Name</th>
              <th class="custom_colon">:</th>
              <td class="custom_detail_name" title={{contractorsData.AccountName}}>{{contractorsData.AccountName}}</td>
            </tr>
  
          </table>
        </div>
      </div>
    </div>
  </div>