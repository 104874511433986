import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { CaseService } from '../../../services/case/case.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';
import { CaseContactDetailComponent } from '../case-contact-detail/case-contact-detail.component';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.css']
})
export class CaseComponent implements OnInit {
  @ViewChild(CaseContactDetailComponent) caseContactDatail: CaseContactDetailComponent;
  caseData: any;
  casePropertyData: any;
  newChatterOn = false;
  contactOn = true;
  casePropertyOn = true;
  DetailOn = false;
  activityOn = false;
  showSpinner: Boolean = true;
  selectedPage: any = {};
  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }
  ngOnInit() {
    this.route.params.subscribe(params => {

      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMCase'
      });

      this.caseService.editCaseById(params.id).subscribe(data => {
        this.caseData = data;
        this.casePropertyData = data;
        this.showSpinner = false;
        this.caseContactDatail.getAllCaseContact(data.ContactId);
        this.caseContactDatail.getAllCaseAccount(data.AccountId);
      });
    });
  }
  OnDetail() {
    this.DetailOn = true;
    this.newChatterOn = false;
    this.casePropertyOn = false;
    this.activityOn = false;
  }
  OnChatter() {
    this.newChatterOn = true;
    this.DetailOn = false;
    this.casePropertyOn = false;
    this.activityOn = false;
  }

  onCaseProperty() {
    this.newChatterOn = false;
    this.DetailOn = false;
    this.casePropertyOn = true;
    this.activityOn = false;
  }
  OnContact() {
    this.contactOn = true;
  }


  onActivity() {
    this.activityOn = true;
    this.newChatterOn = false;
    this.DetailOn = false;
    this.casePropertyOn = false;
  }
}
