import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';
@Injectable()
export class ContactService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService) {
  }

  getTypeContacts() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/contact/getall?BaseId=${q.paramId}&MasterType=${q.type}&`);
  }

  addContact(form) {
    return this.apiService.post('api/contact/create', form);
  }

  editContactById(contactId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/contact/getbyid?Id=${contactId}&MasterType=${q.type}&`);
  }

  getContactById(contactId, Contact) {
    return this.apiService.get(`api/contact/getbyid?Id=${contactId}&MasterType=${Contact}&`);
  }

  updateContact(form) {
    return this.apiService.put('api/contact/edit', form);
  }
}
