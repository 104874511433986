<div class="container">

  <div class="col-md-12 main main_login">
    <div class="col-md-6 left-side">
      <h3 class="text-center">
        <a class="logo_login" href="">
          <img src="https://peppybooks.com/img/logo.png">
        </a>
      </h3>


    </div>
    <!--col-sm-6-->

    <div class="col-md-6 right-side">
      <div class="login_form">
        <h3>Signup</h3>

        <!--Form with header-->
        <div class="form">


          <div class="form-group">
            <label for="form2">Your usersname / e-mail</label>
            <input type="text" id="form2" class="form-control input-lg">

          </div>

          <div class="form-group">
            <label for="form4">Your password</label>
            <input type="password" id="form4" class="form-control input-lg">
          </div>

          <div class="form-group">
            <label for="form4">Confirm password</label>
            <input type="password" id="form4" class="form-control input-lg">
          </div>

          <div class="form-group">
            <label for="form4">Phone number</label>
            <input type="text" class="form-control input-lg" minlength="10" maxlength="10">
          </div>

          <div class="text-xs-center">
            <button class="btn btn-deep-purple" (click)="login()">Signup</button>
          </div>


        </div>
        <!--/Form with header-->
      </div>

    </div>
    <!--col-sm-6-->


  </div>
  <!--col-sm-8-->

</div>
<!--container-->