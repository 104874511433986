import { ClientCustomerService } from './../../../../services/client-customer/client-customer.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-invoice-related',
  templateUrl: './invoice-related.component.html',
  styleUrls: ['./invoice-related.component.css']
})
export class InvoiceRelatedComponent implements OnInit {
  @Input() searchKeys;
  columnName = [];
  invoiceData: Array<any> = [];
  totalSum = 0.00;
  constructor(
    private customerClientService: ClientCustomerService,
  ) {
    this.columnName = [];
  }

  ngOnInit() {
    this.getAllInvoices();
  }

  getAllInvoices() {
    this.customerClientService.getCustomerInvoice().subscribe(res => {
      this.invoiceData = res.Data;
      const columns = [];
      const columnsIn = res.Data[0];
      // tslint:disable-next-line:forin
      for (const key in columnsIn) {
        columns.push(key);
        this.columnName = columns;
      }
      for (let k = 0; k < this.invoiceData.length; k++) {
        this.totalSum += this.invoiceData[k].TotalAmount;
      }
    });
  }


}
