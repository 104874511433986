import { Component, OnInit, Input } from '@angular/core';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { EmployeeService } from '../../../services/Employee/employee.service';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css']
})
export class ProjectDetailComponent implements OnInit {
  @Input() projectData: any;
  public autoCompleteEmployee: AutocompleteModel[];
  projectForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    public autocompleteService: AutocompleteService,
    private employeeService: EmployeeService,
  ) { }

  ngOnInit() {
    this.projectForm = this.fb.group({
      ProjectId: null,
      OwnerId: '',
      UserId: '',
      ProjectName: '',
      ProjectOwnerName: '',
      Status: '',
      StartDate: '',
      DeadLine: '',
      Description: '',
      TotalHoursBudget: '',
      TotalExpenseBudget: '',
      ContactId: '',
      ClientProjectNo: '',
      ClientName: '',
      PurchaseOrderNo: '',
      ProjectNo: '',
      TerritoryID: '',
      PurchaseOrderDate: '',
      PMResponsible: '',
      TechnicalHead: '',
      TeamLead: '',
      BusinessManager: '',
      Deliverable: '',
      LeadSource: '',
      Clarification: '',
      ChangeOrder: '',
      StatusHoldList: '',
      ModelUpload: '',
      CommunicateOwner: '',
      QualityOwner: '',
      MISOwner: '',
      Currency: '',
      ProjectValue: '',
      TeamLeadName: '',
      ProjectManagerName: '',
      OwnerName: '',
      ScopeOfWork: '',
      Exclusions: '',

      TerritoryName: '',
      ContactName: '',
      LeadSourceName: '',
      CurrencyName: '',

      StandardToFollow: '',

      ContactRFI: '',
      WorkOrderNo: '',
      EndDate: '',
      ContactRFIName: '',

      BusinessManagerName: '',
      TechnicalHeadName: '',
      CommunicateOwnerName: '',
      QualityOwnerName: '',
      MISOwnerName: '',
    });
    this.projectForm.patchValue(this.projectData);
  }
}
