import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
@Component({
  selector: 'custom-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class CustomButtonComponent implements OnInit {
  @Output() callBack = new EventEmitter();
  @Input() flat: boolean;
  constructor() {}
  ngOnInit() {}
}
