<div class="tab_title">
  <h3 class="page_title pull-left">
    <span class="menu_icon CRMInvoice glyphicon glyphicon-usd">
    </span>
    Invoices ({{invoiceData?.length}})
  </h3>
</div>

<app-htmltable [tableData]="invoiceData" [columnName]="columnName"></app-htmltable>
<div>
  <table class="table custom_table_cont">
    <tbody>
      <tr>
        <th colspan="3"></th>
        <th><strong>Total</strong></th>
        <th><strong>{{totalSum}}</strong></th>
      </tr>
    </tbody>
  </table>
</div>