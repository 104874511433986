import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  LocationDetials:any;
  showSpinner=true;
  selectedTab='Machines';
  activityOn=true;
  constructor(
    private locationService: LocationService,
    private route: ActivatedRoute,
    private variableService: VariableService,) { }

  ngOnInit(): void {
    this.getLocation();
  }
  getLocation(){
    this.route.params.subscribe(params => {

      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMLocations'
      });

      this.locationService.editByLocationID(params.id).subscribe(data => {
        this.LocationDetials = data;
        this.showSpinner = false;
      });
    });
  }
  selectTab(){

  }
}
