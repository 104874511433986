import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from '../../../services/Employee/employee.service';
import { Component, OnInit, ViewContainerRef, Input, ViewChild } from '@angular/core';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { DeleteModalComponent } from './../../../../../common/delete-modal/delete-modal.component';
import { GlobalService } from '../../../services/global-Service/global.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

declare var $: any;

@Component({
  selector: 'app-territory-management',
  templateUrl: './territory-management.component.html',
  styleUrls: ['./territory-management.component.css']
})
export class TerritoryManagementComponent implements OnInit {
  @Input() deleteData: any;
  showSpinner: Boolean = true;
  territoryForm: TerritoryForm;
  listOfHourBudgetObj: ListOfHourBudgetObj;
  listOfTerritoryUserObj: ListOfTerritoryUserObj;
  addUser = true; hourBudget = false;
  public EmployeeList: AutocompleteModel[];
  public autoCompleteTerritory: AutocompleteModel[];
  public UserList: AutocompleteModel[];
  public territoryData: Array<any> = [];
  public territoryList: Array<any> = [];
  public HourList: Array<any> = [];
  public MonthList: AutocompleteModel[];
  editName: any;
  addTerrirtoryModal:any;
  @ViewChild('addTerritory') addTerritory: ModalDirective;

  columnName = [];
  columnRowName = [];
  constructor(
    private employeeService: EmployeeService,
    private configService: ConfigurationService,
    private modalService: NgbModal,
    private masterLookupService: MasterLookupService,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.resetForm();
    this.getAllLookupTerritory();
    this.getAllTerritory();
    this.columnName = [];
    this.columnRowName = [];
    this.getAllEmployeeList();
  }


  addFieldValue(i) {
    this.resetListOfTerritoryUserObj();
    const obj = this.territoryForm.ListOfTerritoryUser[i + 1];
    if (!obj) {
      this.territoryForm.ListOfTerritoryUser.push(this.listOfTerritoryUserObj);
    }
    this.resetListOfTerritoryUserObj();
  }
  deleteFieldValue(i: number) {
    if (this.territoryForm.ListOfTerritoryUser.length === 1) {
      return false;
    } else {
      this.territoryForm.ListOfTerritoryUser.splice(i, 1);
      return true;
    }
  }
  open(content) {
    this.addTerrirtoryModal = this.modalService.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  /* Hours Budget*/
  addField(k) {
    this.resetListOfHourBudgetObj();
    const obj = this.territoryForm.ListOfHourBudget[k + 1];
    if (!obj) {
      this.territoryForm.ListOfHourBudget.push(this.listOfHourBudgetObj);
    }
    this.resetListOfHourBudgetObj();
  }
  deleteField(k: number) {
    if (this.territoryForm.ListOfHourBudget.length === 1) {
      return false;
    } else {
      this.territoryForm.ListOfHourBudget.splice(k, 1);
      return true;
    }
  }

  getAllEmployeeList() {
    this.employeeService.getEmployeeList().subscribe(data => {
      this.EmployeeList = data.Result;
      this.UserList = data.Result;
    });
  }

  getAllLookupTerritory() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.autoCompleteTerritory = data;
    });
  }

  getAllMonth() {
    this.masterLookupService.getDropdownList('Months').subscribe(data => {
      this.MonthList = data;
    });
  }

  edit(editData) {
    this.reset();
    const territoryId = editData.data.TerritoryID;
    this.editName = editData.data.TerritoryName;
    this.configService.editTerritoryById(territoryId).subscribe(data => {
      this.territoryList = data.ListOfTerritoryUser;
      this.HourList = data.ListOfHourBudget;
      this.territoryForm.ManagerID = data.ManagerID;
      this.territoryForm.TerritoryName = data.TerritoryName;
      this.territoryForm.ParentID = data.ParentID;
      this.territoryForm.TerritoryID = data.TerritoryID;

      this.territoryList.map((obj) => {
        this.territoryForm['ListOfTerritoryUser'].push(obj);
      });
      this.territoryForm['ListOfTerritoryUser'].push(this.listOfTerritoryUserObj);
      this.resetListOfTerritoryUserObj();

      this.HourList.map((object) => {
        this.territoryForm['ListOfHourBudget'].push(object);
      });
      this.territoryForm['ListOfHourBudget'].push(this.listOfHourBudgetObj);
      this.resetListOfHourBudgetObj();
      this.addTerrirtoryModal = this.modalService.open(this.addTerritory, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
    });
  }

  onDelete(data) {
    this.deleteData = ({ id: data.data.TerritoryID, name: data.data.TerritoryName, page: 'Territory' });
    const modalRef = this.modalService.open(DeleteModalComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.deleteData = this.deleteData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAllTerritory();
    });
  }

  getAllTerritory() {
    this.configService.getTerritory().subscribe(data => {
      this.territoryData = data;
      this.columnName = ['Territory Name', 'Under', 'Manager'];
      this.columnRowName = ['TerritoryName', 'ParentTerritory', 'ManagerName'];
      this.showSpinner = false;
    });
  }

  onSubmit() {
    const territoryName = this.territoryForm.TerritoryName;
    let responseMessage = [];
    this.territoryForm.ListOfTerritoryUser = this.territoryForm.ListOfTerritoryUser.filter(
      obj => {
        return obj.ResourceID || obj.WorkType;
      });
    this.territoryForm.ListOfHourBudget = this.territoryForm.ListOfHourBudget.filter(
      obj => {
        return obj.MonthID || obj.HoursBudget;
      });

    if (this.territoryForm.TerritoryID === '') {
      this.configService.addTerritory(this.territoryForm).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[2] === '0') {
          this.globalService.showSuccess({ masterName: 'Territory', name: territoryName });
        }
        this.getAllTerritory();
        this.showSpinner = true;
      });
    } else {
      this.configService.updateTerritory(this.territoryForm).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[2] === '0') {
          this.globalService.showUpdate({ masterName: 'Territory', name: territoryName });
        }
        this.getAllTerritory();
        this.showSpinner = true;
        this.addTerrirtoryModal.close();
      });
    }
    this.globalService.disposeToastr();
    this.resetForm();
  }

  resetForm() {
    this.resetListOfTerritoryUserObj();
    this.resetListOfHourBudgetObj();
    this.resetTerritoryForm();
    this.territoryForm.ListOfTerritoryUser.push(this.listOfTerritoryUserObj);
    this.territoryForm.ListOfHourBudget.push(this.listOfHourBudgetObj);
  }

  reset() {
    this.resetTerritoryForm();
    this.resetListOfTerritoryUserObj();
    this.resetListOfHourBudgetObj();
  }

  OnAddUser() {
    this.addUser = true;
    this.hourBudget = false;
  }
  OnHourBudget() {
    this.addUser = false;
    this.hourBudget = true;
    this.getAllMonth();
  }

  resetListOfTerritoryUserObj() {
    this.listOfTerritoryUserObj = {
      ResourceID: '',
      WorkType: '',
    };
  }

  resetListOfHourBudgetObj() {
    this.listOfHourBudgetObj = {
      MonthID: '',
      HoursBudget: '',
    };
  }
  resetTerritoryForm() {
    this.territoryForm = {
      TerritoryID: '',
      TerritoryName: '',
      ManagerID: '',
      ParentID: '',
      AddUser: '',
      AccessType: '',
      ManagerName: '',
      ParentTerritory: '',
      ListOfHourBudget: [],
      ListOfTerritoryUser: [],
    };
  }
}

declare class ListOfTerritoryUserObj {
  ResourceID: '';
  WorkType: '';
}

declare class ListOfHourBudgetObj {
  MonthID: '';
  HoursBudget: '';
}

declare class TerritoryForm {
  TerritoryID: String;
  TerritoryName: String;
  ManagerID: String;
  ParentID: String;
  AddUser: String;
  AccessType: String;
  ManagerName: String;
  ParentTerritory: String;
  ListOfHourBudget: Array<any>;
  ListOfTerritoryUser: Array<any>;
}
