import { BottomNavbarComponent } from './../bottomNavbar/bottomNavbar.component';
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";

import { LayoutComponent } from "./layout.component";
import { AuthLayoutComponent } from "./authLayout.component";

import { TopNavigationNavbarComponent } from "../topnavbar/topnavigationnavbar.component";
import { SideMenuBarComponent } from "../side-menu-bar/sidemenubar.component";

@NgModule({
  declarations: [
    LayoutComponent,
    AuthLayoutComponent,
    TopNavigationNavbarComponent,
    SideMenuBarComponent,
    BottomNavbarComponent
  ],
  imports: [BrowserModule, RouterModule, FormsModule],
  exports: [
    LayoutComponent,
    AuthLayoutComponent,
    TopNavigationNavbarComponent,
    SideMenuBarComponent,
    BottomNavbarComponent
  ]
})
export class LayoutsModule { }
