<div class="custom_nav_pills_cont">

    <div class="wizard" id="wizard">
        <ul class="nav nav-pills nav-wizard" #widthIdentifier>
            <li *ngFor="let status of leadStatusData" placement="bottom" tooltip="{{status.LeadStatusName}}" [ngStyle]="{'width':taileWidth!==0?taileWidth+'px':'inherit'}">
                <a data-toggle="tab" class={{status.LeadStatus}} [ngClass]="{'active': toggle}" (click)="setCurrentStatus(status)">
                    <span>{{status.LeadStatusName}}</span>
                </a>
            </li>
        </ul>

        <button type="button" [ngClass]="currentStatus.LeadStatus + ' btn btn-default stage_btn'" (click)="setCompleteStatus()">
            <span>{{statusValue}}</span>
        </button>
    </div>
</div>