import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class LocationService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }
  // Create Method for campaign
  addLocation(form) {
    return this.apiService.post('api/location/create', form);
  }

  // Update Method for Campaign
  updateLocation(form) {
    return this.apiService.put('api/location/edit', form);
  }
  editByLocationID(id) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/location/getbyid?id=${id}&MasterType=${q.type}&`
    );
  }
  addMachineLocation(form){
    return this.apiService.post('api/location/machines/create', form);
  }
  getTypeLocationMachines(data={id:'',type:''}){
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/location/machines/getall?BaseId=${data.id || q.paramId}&MasterType=${data.type || q.type}&`
    );
  }
  getLocationList() {
    return this.apiService.get('api/location/GetLocationNamesList' + '?prefixText=' + '&Id=' + '&');
  }
}
