import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from '../../../services/common/activity-page/email/email.service';
declare var $: any;
let user = JSON.parse(sessionStorage.getItem('currentUser'));
import { download } from '../../../../../../app/common/utils';
@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.css']
})
export class EmailsComponent implements OnInit {
  messages: any[];
  showDetail = false;
  messageBody = '';
  attachments = [];
  monthArray: string[] = [
    'Jan',
    'Feb',
    'March',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  IsEmailAuthenticated = false;
  emails: any[];
  constructor(
    public emailService: EmailService,
    private route: ActivatedRoute
  ) {
    this.messages = [];
    let user = JSON.parse(sessionStorage.getItem('currentUser'));
    // console.log('user', user);
    if (user.IsEmailAuthenticated) {
      this.IsEmailAuthenticated = true;
      this.getEmails();
    }
    route.queryParams.filter(params => params.code).subscribe(params => {
      window.opener['emailCodeReceived'](params.code);
      window.close();
    });
  }

  ngOnInit() {
    // this.getEmails();
    this.attachments = [];
  }

  openGmailLogin() {
    const session = sessionStorage;
    // console.log('session', session);
    const scopes =
      'https://mail.google.com/+https://www.googleapis.com/auth/userinfo.email';

    window.open(
      // tslint:disable-next-line:max-line-length
      `https://accounts.google.com/o/oauth2/auth?redirect_uri=http://localhost:8080/email&response_type=code&client_id=215938282303-4r18b5iu3vu5dp8gjpd3hulb2l9amfd1.apps.googleusercontent.com&scope=https://mail.google.com/+https://www.googleapis.com/auth/userinfo.email&approval_prompt=force&access_type=offline`,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=500,width=640,height=500'
    );
    window['emailCodeReceived'] = code => {
      // console.log("parent called", code);
      this.emailService.sendEmailCode(code).subscribe(
        response => {
          // console.log("response", response);
          user.IsEmailAuthenticated = true;
          // session.setIem("currentUser", JSON.stringify(user));
          this.IsEmailAuthenticated = true;
          this.getEmails();
        },
        err => {
          console.log('errr', err);
        }
      );
    };
  }

  getEmails() {
    // const data = this.emailService.getEmail();
    const emails = [];
    // const startInter: any = setInterval(() => {
    //   console.log("called");
    // }, 1000);
    this.emailService.getEmailMessages().subscribe(
      res => {
        console.log('res===', res);
        this.emails = res.messages;
        // this.emailService.setEmailData(res);
        // this.router.navigate(["email"]);
        // res.MessageInfo.forEach(email => {
        //   console.log("email content", JSON.parse(email.Message));
        // });
        res.messages.forEach(e => {
          // const message = JSON.parse(e.Message);
          emails.push(this.getHeaderDetail(e.payload.headers));
        });
        // clearInterval(startInter);
        this.messages = emails;
      },
      err => {
        console.log('err===', err);
      }
    );
    // console.log("this.messages====", this.messages);
  }

  getHeaderDetail(headers) {
    const obj: any = {};
    headers.forEach(head => {
      if (head.name === 'From') {
        obj.from = this.getFrom(head.value);
      } else if (head.name === 'Subject') {
        obj.subject = head.value;
      } else if (head.name === 'Date') {
        obj.date = this.getDate(head.value);
      }
    });
    return obj;
  }

  getFrom(str) {
    const fromIndex = str.indexOf('\u003c');
    // const toIndex = str.indexOf(">");

    return str.slice(0, fromIndex);
  }

  getDate(date) {
    const m = new Date(date).getMonth();
    const d = new Date(date).getDate();

    return this.monthArray[m] + ' ' + d;
  }

  showEmailDetail(i) {
    this.showDetail = true;
    setTimeout(() => {
      const data: any = {};
      //this.emailService.getEmail()
      // console.log('this.messages[i]', this.emails[i]);
      const message = this.emails[i].payload;
      // console.log('message', message);
      let encodedBody = '';
      if (!message.parts) {
        encodedBody = message.body.data;
      } else {
        encodedBody = this.getHTMLPart(message.parts);
        this.getAttachments(message.parts);
      }
      // console.log('encodedBody====', encodedBody);
      encodedBody = encodedBody
        .replace(/-/g, '+')
        .replace(/_/g, '/')
        .replace(/\s/g, '');
      console.log(
        'decodeURIComponent(escape(window.atob(encodedBody)))===',
        window.atob(encodedBody).replace('type=\'text/css\'', '')
      );

      const ifrm = $('#message-iframe')[0].contentDocument.documentElement;
      ifrm.innerHTML = window.atob(encodedBody);
      this.resizeIframe();
    }, 1000);
  }

  getHTMLPart(arr) {
    // console.log('parts arr', arr);
    for (let x = 0; x < arr.length; x++) {
      if (!arr[x].parts) {
        if (arr[x].mimeType === 'text/html') {
          return arr[x].body.data;
        } else if (arr[x].mimeType === 'application/pdf') {
          this.attachments.push(arr[x]);
          return arr[x].body.data;
        }
      } else {
        // if(arr[x].parts) {
        return this.getHTMLPart(arr[x].parts);
        // }
      }
    }
    return '';
  }

  getAttachments(arr) {
    arr.map(a => {
      if (a.mimeType === 'application/pdf') {
        this.attachments.push(a);
      }
    });
  }

  hideDetail() {
    this.showDetail = false;
    this.attachments = [];
  }

  openAttachment(content, filename, mimeType) {
    download(content, filename, mimeType);
  }

  resizeIframe() {
    // iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";
    // this.showDetail = true;
    var iFrameID: any = document.getElementById('message-iframe');
    // console.log('iFrameID==', iFrameID);
    if (iFrameID) {
      // here you can make the height, I delete it first, then I make it again
      // console.log(
      //   'iFrameID.contentWindow.document.body==',
      //   iFrameID.contentWindow.document.body
      // );
      iFrameID.height = '';
      iFrameID.height =
        iFrameID.contentWindow.document.body.scrollHeight + 'px';
    }
  }

  // getBody(message) {
  //   var encodedBody = '';
  //   if (typeof message.parts === 'undefined') {
  //     encodedBody = message.body.data;
  //   }
  //   else {
  //     encodedBody = getHTMLPart(message.parts);
  //   }
  //   encodedBody = encodedBody.replace(/-/g, '+').replace(/_/g, '/').replace(/\s/g, '');
  //   return decodeURIComponent(escape(window.atob(encodedBody)));
  // }
}
