import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()

export class TaskService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) {
  }

  getTaskType() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/task/getall?BaseId=${q.paramId}&MasterType=${q.type}&`);
  }

  addTask(form) {
    return this.apiService.post('api/task/create', form);
  }

  editTaskById(taskId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/task/getbyid?Id=${taskId}&MasterType=${q.type}&`);
  }

  updateTask(form) {
    return this.apiService.put('api/task/edit', form);
  }


  /**Task Follow up */
  addTaskFollowUp(form) {
    return this.apiService.post('api/taskFollowUp/create', form);
  }

  getTaskFollowUp() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/taskFollowUp/getbyid?Id=${q.paramId}&`);
  }
}
