import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';

@Component({
  selector: 'app-opportunity-detail',
  templateUrl: './opportunity-detail.component.html',
  styleUrls: ['./opportunity-detail.component.css']
})
export class OpportunityDetailComponent implements OnInit {
  @Input() opportunityData: any;
  opportunityForm: UntypedFormGroup;
  public StatusModel: AutocompleteModel[];
  public LeadSourceModel: AutocompleteModel[];
  public TypeModel: AutocompleteModel[];
  public ReasonModel: AutocompleteModel[];
  public TerritoryList: AutocompleteModel[];
  constructor(
    private fb: UntypedFormBuilder,
    private masterLookupService: MasterLookupService,
    private configService: ConfigurationService,
  ) { }

  ngOnInit() {
    this.opportunityForm = this.fb.group({
      OpportunityId: null,
      OpportunityName: '',
      OwnerId: '',
      AccountId: '',
      CloseDate: new Date(),
      Type: '',
      Stage: '',
      CampaignId: '',
      Probability: '',
      Amount: '',
      NextStep: '',
      Description: '',
      LeadSource: '',
      IsPrivate: false,
      OrderNo: '',
      DeliveryInstallationStatus: '',
      MainCompetitors: '',
      CurrentGenerators: '',
      TrackingNo: '',
      LossReason: '',
      ContactId: '',
      TerritoryName: '',
      CurrencyId: '',

      OpportunityOwnerName: '',
      OpporStageName: '',
      AccountName: '',

      ContactName: '',
      CampaignName: '',
      ProductName: '',
      CurrencyName: '',

    });
    this.opportunityForm.patchValue(this.opportunityData);
    this.getOpportunityReasonList();
    this.getOpportunityStatusList();
    this.getOpportunityTypeList();
    this.getLeadSourceList();
    this.getAllTerritoryLookupList();
  }

  getOpportunityTypeList() {
    this.masterLookupService.getDropdownList('oppor Type').subscribe(data => {
      this.TypeModel = data;
    });
  }

  getOpportunityReasonList() {
    this.masterLookupService.getDropdownList('oppor Reason').subscribe(data => {
      this.ReasonModel = data;
    });
  }

  getLeadSourceList() {
    this.masterLookupService.getDropdownList('Lead Source').subscribe(data => {
      this.LeadSourceModel = data;
    });
  }

  getOpportunityStatusList() {
    this.masterLookupService.getDropdownList('oppor Status').subscribe(data => {
      this.StatusModel = data;
    });
  }

  getAllTerritoryLookupList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }
}
