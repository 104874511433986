import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class MachineService {
  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }
  getTypeCampaigns() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/machine/getall?BaseId=${q.paramId}&MasterType=${q.type}&`
    );
  }

  getMachineTypesList() {
    return this.apiService.get('api/machinestatus/GetMachineStatusNamesList' + '?prefixText=' + '&Id=' + '&');
  }

  getTypePlanogram() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/machine/planogram/getall?BaseId=${q.paramId}&MasterType=${q.type}&`
    );
  }
  addPlanogram(form) {
    return this.apiService.post('api/machine/planogram/create', form);
  }
  // Create Method for campaign
  addMachine(form) {
    return this.apiService.post('api/machine/create', form);
  }

  // Update Method for Campaign
  updateMachine(form) {
    return this.apiService.put('api/machine/edit', form);
  }
  editByMachineId(MachineId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/machine/getbyid?id=${MachineId}&MasterType=${q.type}&`
    );
  }
  getStockDetails(form){
    return this.apiService.post('api/stocktake/create', form);
  }

  getAllPlanogramProducts() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(
      `api/stocktake/getall?BaseId=${q.paramId}&MasterType=${q.type}&`
    );
  }

  editPlanograms(form) {
    return this.apiService.get(
      `api/stocktake/getbyid?BaseID=${form.MachineId}&MasterType=${form.MasterType}&RelatedId=${form.LocationId}&ToDate=${form.DateTime}&`
    );
  }
}
