<div class="body_inner">
  <div class="">
    <div class="topheader">

      <div class="topheadertitle">
        <span class="menu_icon CRMProjectTasks fa fa-tasks">
        </span>
        <h3 class="report_page_title">
          <p class="page_title_cont">Project Task</p>
          <p class="page_sub_title">{{projectTaskdata?.ProjectTaskName}}</p>
        </h3>
      </div>
      <div class="row">
        <div class="topdownheader ">
              <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                <div class="block">
                    <label class="labelName">Project Name</label>
                    <p>{{projectTaskdata.ProjectName}}</p>
                </div>
                <div class="block">
                    <label class="labelName">Project No</label>
                    <p>{{projectTaskdata.ProjectNo}}</p>
                </div>
                <div class="block">
                    <label class="labelName">Milestone</label>
                    <p>{{projectTaskdata.MilestoneName}}</p>
                </div>

                <div class="block">
                    <label class="labelName">Due Date</label>
                    <p>
                        {{projectTaskdata.DueDate | date:'dd/MM/yyyy'}}
                    </p>
                </div>
                <div class="block">
                  <label class="labelName">Task Owner</label>
                  <p>
                      {{projectTaskdata.OwnerName}}
                  </p>
                </div>
                <div class="block green" *ngIf="projectTaskdata.CheckInDateTime" style="color: #337ab7">
                  <label class="labelName" style="font-weight: bold;">Check In Time</label>
                  <p>
                    {{projectTaskdata.CheckInDateTime | date:'dd/MM/yyyy HH:MM:SS'}}
                  </p>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div class="row mt-5">
                    <button (click)="checkIn(projectTaskdata.ProjectTaskId,projectTaskdata.IsCheckIn)" class="btn btn-primary m-btn ml-4">
                      {{projectTaskdata.IsCheckIn?"CheckOut":"CheckIn"}}
                    </button>
                </div>
              </div>
          </div>
      </div>
    </div>
  </div>
  <div class="row ">
    <div class="col-md-8 lowpadding-right">
      <nav class="navbar navbar-default custom_navbar">
        <div class="nav nav-bar-inner">
          <ul>
            <li class="realted">
              <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Details</a>
            </li>

            <li class="realted">
              <a class="" (click)="onResourceDetail()" [ngClass]="{'active': resourceDetailOn===true}">Resource Details</a>
            </li>
          </ul>
        </div>
        <ul class="cl custom_content_tab">
          <li class="nav-item disabled_form">
            <app-project-task-detail [projectTaskdata]="projectTaskdata" *ngIf="DetailOn===true && projectTaskdata?.ProjectTaskId"></app-project-task-detail>
          </li>
          <li class="nav-item">
            <app-task-resource *ngIf="resourceDetailOn===true"></app-task-resource>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-md-4 bg_white_margin_top no_boxshadow">
      <nav class="navbar navbar-default custom_navbar">
        <div class="nav nav-bar-inner">
          <ul>
            <li class="realted">
              <a class="" (click)=onActivity() [ngClass]="{'active': activityOn===true}">Activity</a>
            </li>
            <li class="activity">
              <a class="" (click)=OnChatter() [ngClass]="{'active': newChatterOn===true}">Chatter</a>
            </li>
          </ul>
        </div>
        <ul class="cl custom_content_tab">
          <li class="nav-item">
            <app-activity *ngIf="activityOn===true"></app-activity>
          </li>

          <li class="nav-item">
            <app-chatter *ngIf="newChatterOn===true"></app-chatter>
          </li>
        </ul>
      </nav>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
</div>