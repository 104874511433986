import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { UntypedFormControl } from '@angular/forms';
import { AutocompleteModel } from './../../../model/sharedModel/autocomplete/autocomplete-model';
import { DatePipe } from '@angular/common';
import { SalesOrderService } from '../../../services/salesorder/sales-order.service';
import { EmployeeInfoService } from '../../../services/employeeInfo/employee-info.service';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, Input, ViewChild, QueryList } from '@angular/core';
import { ProjectService } from '../../../services/project/project.service';
import { AccountService } from '../../../services/accounts/account.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailService } from '../../../services/common/activity-page/email/email.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ClientService } from '../../../services/client/client.service';
import { GlobalService } from '../../../services/global-Service/global.service';

declare var $: any;
// declare var jquery: any;


@Component({
  selector: 'app-opportunity-conversion',
  templateUrl: './opportunity-conversion.component.html',
  styleUrls: ['./opportunity-conversion.component.css']
})
export class OpportunityConversionComponent implements OnInit {
  @Input() convertOpportunityData: any;
  @ViewChild('sendContent') sendContent: ModalDirective;
  public opportunityMode = 'new';
  convertOpportunityForm: UntypedFormGroup;
  public TerritoryList: AutocompleteModel[];

  fromName: any;
  fromAddress: any = '';
  toAddress: any = '';
  subject = 'Project created';
  relatedId: any;
  orderClientId: any;
  orderMode = 'new';
  clientMode = 'new';
  textBody = 'Opportunity is converted to project';
  accountData: any;
  accountID: any;
  projClientId: any;
  clientResponseMessage: any;
  address1: any;
  address2: any;
  address3: any;
  startDate: any;
  deadLine: any;
  clientfilteredOptions: any;
  clientText: any; clientValue: any;
  ownerfilteredOptions: any;
  ownerText: any; ownerValue: any;
  technicalHeadfilteredOptions: any;
  technicalHeadText: any; technicalHeadValue: any;
  projectfilteredOptions: any;
  projectText: any; projectValue: any;
  quotefilteredOptions: any;
  quoteText: any; quoteValue: any;
  technicalHeadName: any;
  projectName: any;
  QuoteList: AutocompleteModel[];
  constructor(
    private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private projectService: ProjectService,
    public activeModal: NgbActiveModal,
    private emailService: EmailService,
    private employeeInfoService: EmployeeInfoService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private clientService: ClientService,
    private orderService: SalesOrderService,
    private datePipe: DatePipe,
    private configService: ConfigurationService,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.convertOpportunityForm = this.fb.group({
      Client: '',
      ProjectName: '',
      oppAccountId: '',
      oppContactId: '',
      OwnerId: '',
      LeadSource: '',
      TerritoryID: '',
      Amount: '',
      CustomerId: '',
      ClientAccountId: '',
      ClientName: '',
      DeadLine: '',
      Currency: '',
      Remarks: '',
      Date: new UntypedFormControl(new Date()),
      OrderClientID: '',
      QuoteID: '',

      PurchaseOrderDate: '',
      PurchaseOrderNo: '',
      ClientProjectNo: '',
      ProjectNo: '',
      TechnicalHead: '',
      StartDate: new UntypedFormControl(new Date()),
      EndDate: '',

    });
    this.convertOpportunityForm.patchValue({
      ProjectName: this.convertOpportunityData.OpportunityName,
      oppAccountId: this.convertOpportunityData.AccountId,
      oppContactId: this.convertOpportunityData.ContactId,
      LeadSource: this.convertOpportunityData.LeadSource,
      TerritoryID: this.convertOpportunityData.TerritoryID,
      Amount: this.convertOpportunityData.Amount,
      ClientName: this.convertOpportunityData.AccountName,
      Currency: this.convertOpportunityData.Currency,
      OwnerId: this.convertOpportunityData.OwnerId,
    });
    this.ownerText = this.convertOpportunityData.OpportunityOwnerName;
    this.ownerValue = this.convertOpportunityData.OwnerId;

    this.onClientAccountSelect();
    this.getQuoteList();
    this.getAllTerritoryLookupList();
  }

  getClientList(value) {
    this.autocompleteService.getAllClientList(value.data).subscribe(
      res => {
        this.clientfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteClient(data) {
    const clientID = data.data.option.value.Value;
    this.convertOpportunityForm.controls['CustomerId'].setValue(clientID);
  }

  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.ownerfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteEmployee(data) {
    const employeeId = data.data.option.value.Value;
    this.convertOpportunityForm.controls['OwnerId'].setValue(employeeId);
  }

  getTechnicalHeadList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.technicalHeadfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteTechnicalHead(data) {
    const techEmployeeId = data.data.option.value.Value;
    this.technicalHeadName = data.data.option.value.Text;
    this.convertOpportunityForm.controls['TechnicalHead'].setValue(techEmployeeId);
    this.onSelectEmployee(techEmployeeId);
  }


  getProjectList(value) {
    this.autocompleteService.getAllProjectList(value.data).subscribe(
      res => {
        this.projectfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteProject(data) {
    const projectId = data.data.option.value.Value;
    this.convertOpportunityForm.controls['Client'].setValue(projectId);
  }

  getAllTerritoryLookupList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }

  getQuoteList() {
    const opportunityId = this.convertOpportunityData.OpportunityId;
    this.autocompleteService.getQuoteOpportunityList(opportunityId, '').subscribe(
      res => {
        this.QuoteList = res;
      },
      err => {
        console.log('errr====', err);
      });
  }


  onClientAccountSelect() {
    this.accountID = this.convertOpportunityData.AccountId;
    if (this.accountID !== null) {
      this.accountService.getByAccountId(this.accountID, 'CRMAccount').subscribe(data => {
        this.accountData = data;
      });
    }
  }

  createNewProject(value: any) {
    this.opportunityMode = value;
  }

  createExistingProject(value: any) {
    this.opportunityMode = value;
  }

  createNewClient(value: any) {
    this.clientMode = value;
  }

  createExistingClient(value: any) {
    this.clientMode = value;
  }

  createNewOrder(value: any) {
    this.orderMode = value;
  }

  clientSubmit() {
    if (this.accountData !== undefined) {
      if (this.accountData.BillingAddress !== null || this.accountData.BillingAddress !== ''
        || this.accountData.BillingAddress !== undefined) {
        const str = this.accountData.BillingAddress.Street;
        if (str !== null) {
          let temp = new Array();
          temp = str.split(',');

          this.address1 = temp[0];
          this.address2 = temp[1];

          temp.splice(0, 2);
          const x = temp.join(', ');
          this.address3 = x;
        }
      }
    } else {
      this.address1 = this.address2 = this.address3 = null;
    }

    if (this.clientMode === 'new') {
      this.clientService
        .addClient({
          ClientName: this.convertOpportunityForm.value.ClientName,
          ClientCode: this.accountData.AccountNumber,
          Phone: this.accountData.Phone,
          Mobile: this.accountData.Mobile,
          Fax: this.accountData.Fax,
          Email: this.accountData.Email,
          TerritoryID: this.convertOpportunityForm.value.TerritoryID,
          OwnerId: this.convertOpportunityForm.value.OwnerId,
          ContactID: this.convertOpportunityForm.value.oppContactId,
          CRMAddress: {
            Address1: this.address1,
            Address2: this.address2,
            Address3: this.address3,
            City: this.accountData.BillingAddress.City,
            Country: this.accountData.BillingAddress.Country,
            StateName: this.accountData.BillingAddress.StateName,
            ZipCode: this.accountData.BillingAddress.ZipCode
          },
          ShippingAddress: {
            City: this.accountData.ShippingAddress.City,
            Street: this.accountData.ShippingAddress.Street,
            Country: this.accountData.ShippingAddress.Country,
            StateName: this.accountData.ShippingAddress.StateName,
            ZipCode: this.accountData.ShippingAddress.ZipCode
          }
        }).subscribe(data => {
          this.projClientId = data.ErrorMessages[3];
          this.clientResponseMessage = data.ErrorMessages[2];
          if (this.clientResponseMessage === '0') {
            this.projectSubmit();
          }
        });
    } else {
      this.projClientId = this.convertOpportunityForm.value.CustomerId;
      this.projectSubmit();
    }
  }

  projectSubmit() {
    this.startDate = this.datePipe.transform(this.convertOpportunityForm.value.StartDate, 'yyyy-MM-dd');
    this.deadLine = this.datePipe.transform(this.convertOpportunityForm.value.DeadLine, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.convertOpportunityForm.value.EndDate, 'yyyy-MM-dd');
    const purchaseOrderDate = this.datePipe.transform(this.convertOpportunityForm.value.PurchaseOrderDate, 'yyyy-MM-dd');

    this.projectName = this.convertOpportunityForm.value.ProjectName;

    if (this.opportunityMode === 'new') {
      this.projectService
        .addProject({
          Client: this.projClientId,
          ProjectName: this.convertOpportunityForm.value.ProjectName,
          ContactId: this.convertOpportunityForm.value.oppContactId,
          OwnerId: this.convertOpportunityForm.value.OwnerId,
          LeadSource: this.convertOpportunityForm.value.LeadSource,
          TerritoryID: this.convertOpportunityForm.value.TerritoryID,
          BusinessManager: this.convertOpportunityForm.value.OwnerId,
          ProjectValue: this.convertOpportunityForm.value.Amount,
          Currency: this.convertOpportunityForm.value.Currency,
          DeadLine: this.deadLine,

          StartDate: this.startDate,
          TechnicalHead: this.convertOpportunityForm.value.TechnicalHead,
          ClientProjectNo: this.convertOpportunityForm.value.ClientProjectNo,
          PurchaseOrderNo: this.convertOpportunityForm.value.PurchaseOrderNo,
          PurchaseOrderDate: purchaseOrderDate,
          ProjectNo: this.convertOpportunityForm.value.ProjectNo,
          EndDate: endDate,
        })
        .subscribe(data => {
          const errorMessage = data.ErrorMessages;
          if (errorMessage[1] === '1') {
            this.globalService.errorProjectMessage();
          } else {
            this.relatedId = data.ErrorMessages[3];
            this.salesOrderSubmit();
          }
        });
    } else {
      this.relatedId = this.convertOpportunityForm.value.Client;
      this.salesOrderSubmit();
    }
  }

  salesOrderSubmit() {
    if (this.orderMode === 'new') {
      this.orderService
        .addSalesOrder({
          OrderClientID: this.projClientId,
          QuoteID: this.convertOpportunityForm.value.QuoteID,
          Date: this.convertOpportunityForm.value.Date,
          Remarks: this.convertOpportunityForm.value.Remarks,
          ProjectID: this.relatedId,
        })
        .subscribe(data => {
          this.orderClientId = data.ErrorMessages[3];
          this.getAllEmployeeInfo();
          this.onSendMail();
        });
    }
    this.activeModal.close();
    this.resetForm();
  }

  /** Send Email */

  onSelectEmployee(employeeId) {
    this.employeeInfoService.getEmployeeInfoList(employeeId).subscribe(infodata => {
      this.toAddress = infodata.EmailID;
    });
  }

  getAllEmployeeInfo() {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.fromAddress = user.EmailId;
    this.fromName = user.FirstName + '' + user.LastName;

    this.textBody = 'Dear' + ' ' + this.technicalHeadName + ',' + '<br/>' + '<br/>' +
      '<strong>' + 'Subject :' + '</strong>' + ' ' + 'New Project is created.' + '<br/>' + '<br/>' +
      'New Project is created' + ' " ' + this.projectName + ' " '
      + 'which start on ' + this.startDate + 'and deadline is on ' +
      this.deadLine + '.' + 'Kindly allocate your time for this project.'
      + '<br/>' + '<br/>' +
      'Regards, ' + '<br/>' + this.fromName + '<br/>' + user.CompanyName + '<br/>';
  }

  onSendMail() {
    this.modalService.open(this.sendContent, { size: 'sm', backdrop: 'static', keyboard: false });
  }

  sendEmail() {
    if (this.fromAddress !== '' && this.toAddress !== '') {
      this.emailService.sendEmail({
        Subject: this.subject,
        FromAddress: this.fromAddress,
        ToAddress: this.toAddress,
        FromName: this.fromName,
        TextBody: this.textBody,
        RelatedToId: this.relatedId,
      }).subscribe(senddata => { });
    }
    $('#btnCancel').trigger('click');
  }

  resetForm() {
    this.convertOpportunityForm.reset();
  }
}
