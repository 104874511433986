<!-- <div class="container-fluid login_cont">

  <div class="row main main_login">


    <div class="col-md-5 right-side">

      <div class="login_form">
        <a class="logo_login" href="">
          <img src="https://peppybooks.com/img/logo.png">
        </a>
        <br />
        <mat-spinner *ngIf="loading" [diameter]="30" style="margin:0 auto;" mode="indeterminate" color="accent"></mat-spinner>

        <form (ngSubmit)="onSubmit(createForm)" #createForm="ngForm">
          <div class="form">

            <div class="error_message text-center">
              {{ myErrorMessage }}
            </div>
            <h3>Login</h3>

            <div class="field">
              <input type="text" ngModel name="userName" id="userName" #usersName="ngModel" placeholder="User Name / E-mail"
                autofocus>
              <label for="userName">user name / e-mail</label>
            </div>

            <div class="field">
              <input type="password" ngModel name="passWord" id="passWord" #passWord="ngModel" placeholder="Password">
              <label for="passWord">password</label>
            </div>

            <div class="text-center btn_cont">
              <button type="submit" class="btn btn-success" id="btnLogin" [disabled]="!createForm" tabindex="1">Login</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="col-md-7 left-side">
      <h3 class="text-center">
      </h3>
    </div>
  </div>
</div> -->


<div class="content">
  <div class="container-flued">
    <div class="row vertical-align">
      <div class="col-md-6 login-img">
        <div class="customer_login_left_img">
                
        </div>
      </div>
      <div class="col-md-6 contents ">
        <div class="row justify-content-center">
          <div class="col-md-5 login-box">
            <div class="mb-4 text-center">
              <img src="/assets/images/logo.png"  style="    width: 120px;"/>
              <!-- <h4 class="mt-3">Welcome To CRM</h4> -->
              <!-- <p class="mb-4">Hello! Please enter your details to login.</p> -->
            </div>
            <form (ngSubmit)="onSubmit(createForm)" #createForm="ngForm">
              <div class="form">
    
                <div class="error_message text-center">
                  {{ myErrorMessage }}
                </div>
                
                <div class="input-group  mt-5">
                  <span class="input-group-addon input-group-addon-mod" id="basic-addon1">
                    <i class="glyphicon glyphicon-envelope login-icons"></i>
                  </span>
                  <input type="text" ngModel name="userName" class="form-control form-control-mod" #usersName="ngModel" aria-describedby="basic-addon1" placeholder="User Name/E-mail" autofocus>
                </div>

                <div class="input-group mt-5">
                  <span class="input-group-addon input-group-addon-mod" id="basic-addon1">
                    <i class="glyphicon glyphicon-lock login-icons"></i>
                  </span>
                  <input type="password" ngModel name="passWord" class="form-control form-control-mod" id="passWord" #passWord="ngModel" placeholder="Password">
                </div>
                
                <div class="input-group text-center mt-5 mb-5">
                  <button type="submit" class="btn btn-primary btn-cust" id="btnLogin" [disabled]="loading" tabindex="1">
                    <span *ngIf="loading">
                      <i class="fa fa-spinner fa-spin" style="font-size:24px"></i>
                    </span>
                    Login{{loading?"...":''}}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

