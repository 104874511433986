<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Campaigns</h4>
</div>
<form [formGroup]="campaignForm" novalidate (ngSubmit)="onSubmit(campaignForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="">

          <!-- <p class="headingclass">Campaign Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Campaign Name</label>
              <input type="textbox" class="form-control" formControlName="CampaignName">
              <input type="hidden" formControlName="CampaignId">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Campaign Owner</label>
              <app-autocomplete [autoCompleteText]="ownerText" (setAutoComplete)="setAutoCompleteEmployee($event)"
                [autoCompleteValue]="ownerValue" (fiteredData)="getEmployeeList($event)" [filteredOptions]="ownerfilteredOptions">
              </app-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Active</label>
              <input type="checkbox" formControlName="IsActive" class="custom_checkbox">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Status</label>
              <select class="form-control" formControlName="Status">
                <option *ngFor="let status of StatusModel" value={{status.Value}}>{{status.Text}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Parent Campaign</label>
              <app-autocomplete [autoCompleteText]="campaignText" (setAutoComplete)="setAutoCompleteCampaign($event)"
                [autoCompleteValue]="campaignValue" (fiteredData)="getCampaignList($event)" [filteredOptions]="campaignfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Start Date</label>
              <input type="text" class="form-control" formControlName="StartDate" #dp="bsDatepicker" 
                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
              <p class="inputdate" (click)="dp.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Type</label>
              <select class="form-control" formControlName="Type">
                <option *ngFor="let type of CampaignTypeModel" value={{type.Value}}>{{type.Text}}</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">End Date</label>
              <input type="text" class="form-control" formControlName="EndDate" #bsdp="bsDatepicker"
                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
              <p class="inputdate" (click)="bsdp.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>
          </div>
          <!-- <p class="headingclass">Planning</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Num Sent in Campaign</label>
              <input type="textbox" class="form-control" placeholder="0" formControlName="NumSentCampaign" (keypress)="numberOnly($event)">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Budgeted Cost in Campaign</label>
              <input type="textbox" class="form-control" formControlName="BudgetCost" (keypress)="numberOnly($event)">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Expected Response (%)</label>
              <input type="textbox" class="form-control" formControlName="ExpectedResponse" placeholder="0.00%"
                (keypress)="numberOnly($event)">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Actual Cost in Campaign</label>
              <input type="textbox" class="form-control" formControlName="ActualCost" (keypress)="numberOnly($event)">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Expected Revenue in Campaign</label>
              <input type="textbox" class="form-control" formControlName="ExpectedRevenue" (keypress)="numberOnly($event)">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Segment Type</label>
              <select class="form-control" formControlName="SegmentType" (change)="onEntity($event.target.value)">
                <option value="null">--None--</option>
                <option value="CRMContact">Contact</option>
                <option value="CRMLead">Lead</option>
                <option value="CRMAccount">Account</option>
                <option value="CRMCampaign">Campaign</option>
                <option value="CRMCase">Case</option>
                <option value="CRMContract">Contract</option>
                <option value="CRMOpportunity">Opportunity</option>
                <option value="CRMProduct">Product</option>
                <option value="CRMQuote">Quote</option>
                <option value="CRMProject">Project</option>
                <option value="CRMProjectTask">Project Task</option>
                <option value="CRMMilestone">Milestone</option>
              </select>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">List/Segment</label>
              <!-- <input type="textbox" class="form-control" formControlName="SegmentListName" (keypress)="numberOnly($event)"> -->
              <select class="form-control" formControlName="SegmentListName">
                <option value=0>--None--</option>
                <option *ngFor="let type of SegmentList" value={{type.FilterID}}>
                    {{type.FilterName}}</option>
            </select>
            </div>
            
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <!-- <label for="text">Select Email Template</label>
              <app-autocomplete [autoCompleteText]="emailTemplateText" (setAutoComplete)="setAutoCompleteEmailTem($event)"
              [autoCompleteValue]="emailTemplateValue" (fiteredData)="getAllEmailTemplateList($event)" [filteredOptions]="emailfilterOptions">
            </app-autocomplete> -->
            <label for="text">Reply Email Address</label>
            <input type="text" class="form-control" formControlName="ReplyEMailID" >

            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Sender Email Address</label>
              <input type="text" class="form-control" formControlName="SenderEMailID" >
            </div>
          </div>
          <!-- <p class="headingclass">Description</p> -->
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" rows="3" formControlName="Description"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!campaignForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!campaignForm">Save</button>
  </div>
</form>