import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { CaseService } from '../../../../services/case/case.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cases-related',
  templateUrl: './cases-related.component.html',
  styleUrls: ['./cases-related.component.css']
})
export class CasesRelatedComponent implements OnInit {
  masterType: any;
  selectedPage: any = {};
  constructor(
    private caseService: CaseService,
    public router: Router,
  ) { }
  @Input() searchKeys;
  cases: Array<any> = [];
  caseForm: UntypedFormGroup;
  ngOnInit() {
    this.getAllCases();
  }

  getAllCases() {
    this.caseService.getTypeCase().subscribe(data => {
      this.cases = data;
    });
  }

  gotoDetailPage(id) {
    const route = 'caseDetail/' + id;
    this.router.navigate([route]);
  }

  gotoContactPage(id) {
    const route = 'contactDetail/' + id;
    this.router.navigate([route]);
  }
}
