import { EventNewComponent } from './../../event-page/event-new/event-new.component';
import { TaskNewComponent } from './../../tasks-page/task-new/task-new.component';
import { CalendarService } from '../../../services/calendar/calendar.service';
import { Subject } from 'rxjs/Subject';
import { Component, ChangeDetectionStrategy, OnInit, ViewChild } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { GlobalService } from '../../../services/global-Service/global.service';

declare var $: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnInit {
  @ViewChild('showContent') showContent: any;
  view: String = 'month';
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  type: any;
  subject: any;
  startDate: any;
  refresh: Subject<any> = new Subject();
  items: Array<CalendarEvent<{ time: any }>> = [];
  activeDayIsOpen: Boolean = false;
  constructor(
    private modalService: NgbModal,
    private calendarService: CalendarService,
    private globalService: GlobalService,
  ) {
  }
  ngOnInit() {
    this.getAllEventList();
  }

  addEvent(data, showTaskEventContent) {
    this.modalService.open(showTaskEventContent, { size: 'sm', backdrop: 'static', keyboard: false });
  }

  selectType(type) {
    $('#btnCancel').trigger('click');
    if (type === undefined) {
      this.globalService.showError();
    } else if (type === 'task') {
      const modalRef = this.modalService.open(TaskNewComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.getData.subscribe(() => {
        this.getAllEventList();
      });
    } else if (type === 'event') {
      const modalRef = this.modalService.open(EventNewComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.getData.subscribe(() => {
        this.getAllEventList();
      });
    } else {
      return false;
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  getAllEventList() {
    this.calendarService.getCalendarEventTask().subscribe(data => {
      for (let i = 0; i < data.length; i++) {
        this.items.push(
          {
            id: data[i].MasterType,
            title: data[i].Subject,
            start: new Date(data[i].StartDate),
            // end: new Date(data[i].EndDate),
            color: { primary: '#009FFF', secondary: '#ebf7ff' },
            draggable: false,
            resizable: {
              beforeStart: true,
              afterEnd: true
            },
            meta: {
              time: data[i].StartTime
            }
          });
        this.events = this.items;
        this.refresh.next();
      }
    });
  }


  handleEvent(action: string, event: CalendarEvent): void {
    this.subject = event.title;
    this.startDate = event.start;
    if (event.id === 'Event') {
      this.type = 'Event';
    } else {
      this.type = 'Task';
    }
    this.modalService.open(this.showContent, { size: 'sm', backdrop: 'static' });
  }
}
