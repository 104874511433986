import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css']
})
export class ClientDetailComponent implements OnInit {
  clientForm: UntypedFormGroup;
  @Input() clientData: any;
  public TerritoryList: AutocompleteModel[];

  constructor(
    private fb: UntypedFormBuilder,
    private configService: ConfigurationService,
  ) { }

  ngOnInit() {
    this.clientForm = this.fb.group({
      CustomerId: '',
      ClientName: '',
      ClientCode: '',
      ContactID: '',
      Phone: '',
      Mobile: '',
      Fax: '',
      Email: '',
      TerritoryID: '',
      Address1: '',
      Address2: '',
      Address3: '',
      CRMAddress: new UntypedFormGroup({
        Address1: new UntypedFormControl(),
        Address2: new UntypedFormControl(),
        Address3: new UntypedFormControl(),
        City: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      }),
      ShippingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      }),
    });
    this.clientForm.patchValue(this.clientData);
    this.getAllTerritoryList();
  }

  getAllTerritoryList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }

}
