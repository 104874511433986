import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { EmployeeService } from '../../../services/Employee/employee.service';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { Component, OnInit, Input, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { TaskService } from '../../../services/task/task.service';
import { DatePipe } from '@angular/common';
// import { ToastsManager, ToastOptions } from 'ng2-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-task-new',
  templateUrl: './task-new.component.html',
  styleUrls: ['./task-new.component.css']
})
export class TaskNewComponent implements OnInit {

  @Input() taskData: any;
  @Output() getData = new EventEmitter();
  taskNotifyName: any;
  responseMessage: Array<any> = [];
  public autoCompleteEmployee: AutocompleteModel[];
  public TaskStageModel: AutocompleteModel[];
  taskForm: UntypedFormGroup;
  EditNew: any;

  contactLeadFilterOption: any[];
  relatedTypeFilterOption: any[];
  employeeFilterOption: any[];
  contactLeadValue: any; contactLeadText: any;
  RelatedText: any; RelatedValue: any;
  AssignedToText: any; AssignedToValue: any;
  selectedName = '';
  selectedRelatedType = '';
  NameTypeList = this.globalService.NameTypeList;

  RelatedTypeList = this.globalService.RelatedTypeList;

  constructor(
    private taskService: TaskService,
    public autocompleteService: AutocompleteService,
    private fb: UntypedFormBuilder,
    private employeeService: EmployeeService,
    private masterLookupService: MasterLookupService,
    private datePipe: DatePipe,
    // public toastr: ToastsManager,
    public activeModal: NgbActiveModal,
    public globalService:GlobalService,
  ) { }

  ngOnInit() {

    this.taskForm = this.fb.group({
      TaskId: null,
      UserId: '',
      Subject: '',
      AssignedTo: '',
      ContactLeadId: '',
      DueDate: new Date(),
      Name: '',
      RelatedToId: '',
      Description: '',
      Priority: '',
      Status: '',
      CallDisposition: '',
      NameType: '',
      RelatedType: '',
      StatusName: '',
      PriorityType: '',
      AssignedName: '',
    });
    this.getTaskStageList();
    this.getEditTaskData();
    this.getEditNew();
  }

  getEditNew() {
    if (this.taskForm.value.TaskId === null) {
      this.EditNew = 'New';
      this.selectedName = 'Contact';
      this.selectedRelatedType = 'Account';
    } else {
      this.EditNew = 'Edit';
      this.selectImg();
    }
  }
  selectImg() {
    if (this.taskData.NameType === '') {
      this.selectedName = 'Contact';
      this.selectedRelatedType = this.taskData.RelatedType;
    } else if (this.taskData.RelatedType === '') {
      this.selectedRelatedType = 'Account';
      this.selectedName = this.taskData.NameType;
    }
  }

  getEditTaskData() {
    if (this.taskData !== undefined) {
      this.taskForm.patchValue(this.taskData);
      this.AssignedToText = this.taskData.AssignedName;
      this.AssignedToValue = this.taskData.AssignedTo;

      this.contactLeadText = this.taskData.ContactLeadName;
      this.contactLeadValue = this.taskData.ContactLeadId;

      this.RelatedText = this.taskData.RelatedToName;
      this.RelatedValue = this.taskData.RelatedToId;
      this.taskForm.controls['DueDate'].setValue(new Date(this.taskForm.value.DueDate));

    }
  }

  getTaskStageList() {
    this.masterLookupService.getDropdownList('Task Stage').subscribe(data => {
      this.TaskStageModel = data;
    });
  }

  setAutocompleteAssignedTo(data) {
    const assignedTo = data.data.option.value.Value;
    this.taskForm.controls['AssignedTo'].setValue(assignedTo);
  }

  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, null).subscribe(
      res => {
        this.employeeFilterOption = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutocompleteRelatedType(data) {
    const relatedTo = data.data.option.value.Value;
    this.taskForm.controls['RelatedToId'].setValue(relatedTo);
  }

  getRealtedTypeList(value) {
    const relatedValue = this.taskForm.value.RelatedType;
    if (relatedValue !== '') {

      this.autocompleteService.getAllRelatedTypeList(relatedValue, value.data).subscribe(
        res => {
          this.relatedTypeFilterOption = res;
        },
        err => {
          console.log('errr====', err);
        }
      );
    }
  }

  setcontactLeadType(data) {
    const contactLeadTo = data.data.option.value.Value;
    this.taskForm.controls['ContactLeadId'].setValue(contactLeadTo);
  }
  getLeadContactList(value) {
    const optionValue = this.taskForm.value.NameType;
    if (optionValue !== '') {
      this.autocompleteService.getLeadContactList(optionValue, value.data).subscribe(
        res => {
          this.contactLeadFilterOption = res;
        },
        err => {
          console.log('errr====', err);
        }
      );
    }
  }

  getAutoCompleteEmployeeList() {
    this.employeeService.getEmployeeList().subscribe(data => {
      this.autoCompleteEmployee = data.Result;
    });
  }


  dateFormat() {
    this.taskForm.value.Date = this.datePipe.transform(this.taskForm.value.Date, 'yyyy-MM-dd');
    this.taskForm.value.DueDate = this.datePipe.transform(this.taskForm.value.DueDate, 'yyyy-MM-dd');
  }
  // Submit Function
  onSubmit(form: UntypedFormGroup) {
    this.dateFormat();
    if (form.value.TaskId == null) {
      this.taskNotifyName = form.value.Subject;
      this.taskService.addTask(this.taskForm.value).subscribe(data => {
        this.responseMessage = data.ErrorMessages;
        if (this.responseMessage[1] === '0') {
          // this.toastr.success('Task' + ' ' + '"' + this.taskNotifyName + '"' + ' ' + 'was created.'
          // );
        }
        this.getData.emit();
      });
      // Update Method
    } else {
      this.taskNotifyName = form.value.Subject;
      this.taskService.updateTask(this.taskForm.value).subscribe(data => {
        this.responseMessage = data.ErrorMessages;
        if (this.responseMessage[1] === '0') {
          // this.toastr.success('Task' + ' ' + '"' + this.taskNotifyName + '"' + ' ' + 'was saved.'
          // );
        }
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.resetForm();
  }
  resetForm() {
    this.taskForm.reset();
  }
}

