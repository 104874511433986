<div class="body_inner">
  <div class="">
    <div class="topheader">

      <div class="topheadertitle">
        <span class="menu_icon CRMProducts fa fa-barcode">
        </span>
        <h3 class="report_page_title">
          <p class="page_title_cont">Product</p>
          <p class="page_sub_title">{{productData?.Name}}</p>
        </h3>
      </div>
    </div>
    <div class="">
      <div class="topdownheader ">
        <div class="">
          <div class="block">
            <label class="labelName">Product Code</label>
            <p>{{productData?.Code}}</p>
          </div>

          <div class="block">
            <label class="labelName">Product Family</label>
            <p>None</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <nav class="navbar navbar-default custom_navbar">
        <div class="nav nav-bar-inner">
          <ul>
            <li class="realted">
              <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Details</a>
            </li>
          </ul>
        </div>
        <ul class="cl custom_content_tab">
          <li class="nav-item disabled_form">
            <app-product-detail [productData]="productData" *ngIf="DetailOn===true && productData?.ProductId "></app-product-detail>
          </li>
        </ul>
      </nav>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
</div>