import { MilestoneService } from '../../../services/milestone/milestone.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VariableService } from '../../../services/VariableService/variable.service';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
@Component({
  selector: 'app-milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.css']
})
export class MilestoneComponent implements OnInit {
  milestoneData: any;
  searchKeys: any;
  DetailOn = false;
  NewChatterOn = false;
  RelatedOn = true;
  activityOn = true;
  showSpinner: Boolean = true;
  selectedPage: any = {};
  constructor(
    private route: ActivatedRoute,
    private milestoneService: MilestoneService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {

      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMMilestone',
      });

      this.milestoneService
        .milestoneById(params.id)
        .subscribe(data => {
          // debugger;
          this.milestoneData = data;
          this.showSpinner = false;
        });

    });
  }
  OnDetail() {
    this.DetailOn = true;
    this.RelatedOn = false;
  }
  OnChatter() {
    this.NewChatterOn = true;
    this.activityOn = false;
  }
  OnRelated() {
    this.DetailOn = false;
    this.RelatedOn = true;
  }

  onActivity() {
    this.NewChatterOn = false;
    this.activityOn = true;
  }
}
