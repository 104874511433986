import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../product/crm/services/messageService/message.service';
declare var $: any;
@Component({
  selector: 'basic',
  templateUrl: 'layout.template.html'
})
export class LayoutComponent implements OnInit {
  constructor(
    private messageService: MessageService,
  ) { }
  show: Boolean;
  ngOnInit() {
    this.show = false;
    this.calcWidth();

    $(window).on('resize load', () => {
      this.calcWidth();
    });
  }

  calcWidth(a?: any) {
    let navwidth = 0;
    const morewidth = $('#main .more').outerWidth(true);
    $('#main > li:not(.more)').each(function () {
      navwidth += $(this).outerWidth(true);
    });

    const availablespace = $('#nav-main').width() - morewidth;

    if (navwidth > availablespace) {
      const lastItem = $('#main > li:not(.more)').last();
      lastItem.attr('data-width', lastItem.outerWidth(true));
      lastItem.prependTo($('#main .more ul'));
      this.calcWidth();
    } else {
      const firstMoreElement = $('#main li.more li').first();
      if (navwidth + firstMoreElement.data('width') < availablespace) {
        firstMoreElement.insertBefore($('#main .more'));
      }
    }

    if ($('.more li').length > 0) {
      $('.more').css('display', 'block');
    } else {
      $('.more').css('display', 'none');
    }
  }

  toggle() {
    this.messageService.toggle();
  }

  onCloseClick() {
    // this.show = !this.show;
    this.messageService.closeToggle();
  }
}
