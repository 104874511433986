<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Contact</h4>
</div>
<form [formGroup]="contactForm" novalidate (ngSubmit)="onSubmit(contactForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="">

          <!-- <p class="headingclass"> Contact Information</p> -->

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Client Name</label>
              <app-autocomplete [autoCompleteText]="clientText" (setAutoComplete)="setAutoCompleteClient($event)"
                [autoCompleteValue]="clientValue" (fiteredData)="getClientList($event)" [filteredOptions]="clientfilteredOptions">
              </app-autocomplete>
            </div>


            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <input type="hidden" formControlName="ContactId">
              <label for="text">Contact Owner</label>
              <app-autocomplete [autoCompleteText]="employeeText" (setAutoComplete)="setAutoCompleteEmployee($event)"
                [autoCompleteValue]="employeeValue" (fiteredData)="getEmployeeList($event)" [filteredOptions]="employeefilteredOptions">
              </app-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-1 col-md-1 col-sm-12 col-xs-12">
              <div class="form-group ">
                <label for="text">Salutation</label>
                <select class="form-control" formControlName="Salutation">
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Prof.">Prof.</option>
                </select>
              </div>
            </div>
            <div class="form-group col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <div class="form-group ">
                <label for="text"><span class="text-danger">*</span>First Name</label>
                <input type="textbox" class="form-control" formControlName="FirstName">
              </div>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group ">
                <label for="text">
                  <span class="text-danger">*</span>Last Name</label>
                <input type="textbox" class="form-control" formControlName="LastName">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Account Name</label>
              <app-autocomplete [autoCompleteText]="accountText" (setAutoComplete)="setAutoCompleteAccount($event)"
                [autoCompleteValue]="accountValue" (fiteredData)="getAccountList($event)" [filteredOptions]="accountfilteredOptions">
              </app-autocomplete>
              <!-- <select formControlName="AccountId" class="form-control" placeholder="Search Accounts...." (click)="onAccountList($event.target.value)">
                <option value=0>--None--</option>
                <option *ngFor="let account of autoCompleteAccount" value={{account.Value}}>{{account.Text}}
                </option>
              </select> -->
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right">
              <label for="text">Reports To</label>
              <app-autocomplete [autoCompleteText]="contactText" (setAutoComplete)="setAutoCompleteContact($event)"
                [autoCompleteValue]="contactValue" (fiteredData)="getContactList($event)" [filteredOptions]="contactfilteredOptions">
              </app-autocomplete>
              <!-- <select formControlName="ReportsToId" class="form-control" placeholder="Search Contacts....">
                <option value=0>--None--</option>
                <option *ngFor="let contact of autoCompleteContact" value={{contact.Value}}>{{contact.Text}}
                </option>
              </select> -->
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Title</label>
              <input class="form-control" type="text" formControlName="Title">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Department</label>
              <input class="form-control" type="text" formControlName="Department">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
              <label for="text">Email</label>
              <input class="form-control" formControlName="Email" type="text">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Phone</label>
              <input class="form-control" formControlName="Phone" type="textbox">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Mobile</label>
              <input class="form-control" formControlName="MobilePhone" type="textbox">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Fax</label>
              <input class="form-control" formControlName="Fax" type="textbox">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Home Phone</label>
              <input class="form-control" type="textbox" formControlName="HomePhone">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Other Phone</label>
              <input class="form-control" type="textbox" formControlName="OtherPhone">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Birthdate</label>
              <input class="form-control" type="text" formControlName="BirthDate" #dp="bsDatepicker" value="{{ contactForm.get('BirthDate').value | date: 'dd-MM-yyyy' }}"
                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'DD-MM-YYYY'}">
              <p class="inputdate" (click)="dp.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right">
              <label for="text">Assistant</label>
              <input class="form-control" type="text" formControlName="AssistantName">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12  pull-right">
              <label for="text">Asst. Phone</label>
              <input class="form-control" type="textbox" formControlName="AssistantPhone">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Lead Source</label>
              <select class="form-control" formControlName="LeadSource">
                <option value=0>--None--</option>
                <option *ngFor="let leadSource of LeadSourceModel" value={{leadSource.Value}}> {{leadSource.Text}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Skype ID</label>
              <input class="form-control" formControlName="SkypeID" type="textbox">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Twitter</label>
              <input class="form-control" formControlName="TwitterID" type="textbox">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Facebook</label>
              <input class="form-control" formControlName="FaceBookID" type="textbox">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">List Type</label>
                            <select class="form-control" formControlName="ListTypeID">
                              <option *ngFor="let listType of ListTypeData" value={{listType.Value}}> {{listType.Text}}
                              </option>
                            </select>
                          </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Branch</label>
              <select class="form-control" formControlName="TerritoryID">
                <option value=0>--None--</option>
                <option *ngFor="let territory of TerritoryList" value={{territory.Value}}> {{territory.Text}}
                </option>
              </select>
            </div>
          </div>

          <!-- <p class="headingclass"> Address Information</p> -->
          <div class="row">
            <div formGroupName="BillingAddress" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <p class="headingclass">Mailing Address</p>
                <label for="text">Mailing Street</label>
                <textarea class="form-control" rows="2" formControlName="Street"></textarea>

                <div class="row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text"> Mailing City</label>
                    <input type="textbox" class="form-control" formControlName="City">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Mailing State/Province</label>
                    <input type="textbox" class="form-control" formControlName="StateName">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Mailing Zip/Postal Code</label>
                    <input type="textbox" class="form-control" formControlName="ZipCode">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Mailing Country</label>
                    <input type="textbox" class="form-control" formControlName="Country">
                  </div>
                </div>
              </div>
            </div>

            <div formGroupName="ShippingAddress" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <p class="headingclass">Other Address</p>
                <label for="text">Other Street</label>
                <textarea class="form-control" rows="2" formControlName="Street"></textarea>

                <div class="row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text"> Other City</label>
                    <input type="textbox" class="form-control" formControlName="City">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Other State/Province</label>
                    <input type="textbox" class="form-control" formControlName="StateName">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Other Zip/Postal Code</label>
                    <input type="textbox" class="form-control" formControlName="ZipCode">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Other Country</label>
                    <input type="textbox" class="form-control" formControlName="Country">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <p class="headingclass">Additional Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Languages</label>
              <input type="textbox" class="form-control" formControlName="Languages">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Level</label>
              <select class="form-control" formControlName="Level">
                <option value=0>--None--</option>
                <option value="1">Primary</option>
                <option value="2">Secondary</option>
                <option value="3">Tertiary</option>
              </select>
            </div>
          </div>
          <!-- <p class="headingclass">Description</p> -->
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" rows="3" formControlName="Description"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!contactForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!contactForm">Save</button>
  </div>
</form>