<div class="body_inner">
  <div class="">
    <div class="topheader">
      <div class="topheadertitle">
        <span class="menu_icon CRMEvents glyphicon glyphicon-calendar">
        </span>
        <h3 class="report_page_title">
          <p class="page_title_cont">Event</p>
          <p class="page_sub_title">{{eventData?.Subject}}</p>
        </h3>
      </div>
    </div>
    <div class="">
      <div class="topdownheader ">
        <div class="">
          <div class="block">
            <label class="labelName">Location</label>
            <p>{{eventData?.Location}}</p>
          </div>

          <div class="block">
            <label class="labelName">Start</label>
            <p>{{eventData?.StartDate | date:'dd-MM-yy'}}</p>
          </div>

          <div class="block">
            <label class="labelName">End</label>
            <p>{{eventData?.EndDate | date:'dd-MM-yy'}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <nav class="navbar navbar-default custom_navbar">
        <div class="nav nav-bar-inner">
          <ul>
            <li class="realted">
              <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Details</a>
            </li>
          </ul>
        </div>

        <ul class="cl custom_content_tab">
          <li class="nav-item disabled_form">
            <app-event-detail [eventData]="eventData" *ngIf="DetailOn===true && eventData?.EventId "></app-event-detail>
          </li>
        </ul>
      </nav>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
</div>