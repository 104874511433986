<div class="related_details">
  <div class="row">
    <div class="col-md-12 col-xs-12">

      <div class="caseContact">
        <p class="custom_heading_name h4"><u>Contact Details</u></p>
        <table class="custom_table">
          <tr>
            <th class="custom_name">Contact Name</th>
            <th class="custom_colon">:</th>
            <td class="dottedLine" (click)="gotoContactPage(contactCaseData?.ContactId)" href="javascript:void(0)">{{contactCaseData?.ContactName}}</td>
          </tr>

          <tr>
            <th class="custom_name">Contact Email</th>
            <th class="custom_colon">:</th>
            <td class="custom_detail_name">{{contactCaseData?.Email}}</td>
          </tr>

          <tr>
            <th class="custom_name">Contact Phone</th>
            <th class="custom_colon">:</th>
            <td class="custom_detail_name">{{contactCaseData?.Phone}}</td>
          </tr>
        </table>
      </div>

      <br />

      <div class="caseAccount">
        <p class="custom_heading_name h4"><u>Account Details</u></p>
        <table class="custom_table">
          <tr>
            <th class="custom_name">Account Name</th>
            <th class="custom_colon">:</th>
            <td class="dottedLine" (click)="gotoAccountPage(acccountCaseData?.AccountId)" href="javascript:void(0)">{{acccountCaseData?.AccountName}}</td>
          </tr>

          <tr>
            <th class="custom_name">Account Phone</th>
            <th class="custom_colon">:</th>
            <td class="custom_detail_name">{{acccountCaseData?.Phone}}</td>
          </tr>

        </table>
      </div>

    </div>
  </div>
</div>