import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class InvoiceService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  // GetAll Method
  getInvoice() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/invoice/getall?Id=${q.paramId}&MasterType=${q.type}&`
    );
  }
  // Create Method for Leads
  addInvoice(form) {
    return this.apiService.post('api/invoice/create', form);
  }
  updateInvoice(form) {
    return this.apiService.post('api/invoice/create', form);
  }
  // Edit byId
  InvoiceEditById(invoiceId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/invoice/getbyid?Id=${invoiceId}&MasterType=${q.type}&`);
  }

  sendInvoiceEmail(ReportId, RDLName, TransactionNo, FileType, ToMailId, FromEmail, Body, Subject) {
    // tslint:disable-next-line:max-line-length
    return this.apiService.get(`api/files/sendmail?ReportId=${ReportId}&RDLName=${RDLName}&TransactionNo=${TransactionNo}&FileType=${FileType}&ToMailId=${ToMailId}&FromEmailId=${FromEmail}&Body=${Body}&Subject=${Subject}&`);
  }

}
