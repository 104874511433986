import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { EmailService } from '../../../services/common/activity-page/email/email.service';
import { GlobalService } from '../../../services/global-Service/global.service';
import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-bulk-email',
  templateUrl: './bulk-email.component.html',
  styleUrls: ['./bulk-email.component.css']
})
export class BulkEmailComponent implements OnInit {
  @Output() getData = new EventEmitter();

  bulkEmailForm: UntypedFormGroup;
  toEmailAddress: any;
  editor: Editor;

  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '20rem',
    width: 'auto',
    translate: 'no',
    // 'toolbar': [
    //   ['bold', 'italic', 'underline', 'strikeThrough'],
    //   ['justifyLeft', 'justifyRight', 'justifyFull', 'outdent'],
    //   ['link']
    // ]
    'toolbar': [
      ['bold', 'italic'], ['underline', 'strike'],
      // ['justifyLeft', 'justifyRight', 'justifyFull', 'outdent'],
      ['align_left', 'align_center', 'align_right', 'align_justify'],
      ['link']
    ]
  };

  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private emailService: EmailService,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.editor = new Editor();

    this.bulkEmailForm = this.fb.group({
      FromAddress: '',
      ToAddress: '',
      Subject: '',
      TextBody: '',
    });
    this.toEmailAddress = this.toEmailAddress || '';
  }

  onSubmit(form) {
    this.emailService.sendEmail(form).subscribe(data => {
      this.globalService.showBulkEmail();
    });
    this.activeModal.close();
    this.getData.emit();
  }
}
