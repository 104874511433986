import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { EmployeeInfoService } from './../../../services/employeeInfo/employee-info.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from '@angular/core';
import { LeadService } from '../../../services/lead/lead.service';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { MasterLookupModel } from '../../../model/sharedModel/masterLookup/master-lookup-Model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output } from '@angular/core';
import { EmailService } from '../../../services/common/activity-page/email/email.service';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-new-lead',
  templateUrl: './new-lead.component.html',
  styleUrls: ['./new-lead.component.css']
})
export class NewLeadComponent implements OnInit {
  @Input() leadFormData: any;
  @Output() getData = new EventEmitter();
  leadForm: UntypedFormGroup;
  public TerritoryList: AutocompleteModel[];
  public leadStatus: AutocompleteModel[];
  public IndustryModel: MasterLookupModel[];
  public LeadSourceModel: MasterLookupModel[];
  public RatingModel: MasterLookupModel[];

  currencyfilteredOptions: any[];
  ownerfilteredOptions: any[];
  productfilteredOptions: any[];
  currencyText: any; currencyValue: any;
  ownerText: any; ownerValue: any;
  productText: any; productValue: any;
  fromName: any;
  fromaddress: any = null;
  toaddress: any = null;
  subject = 'New Lead';
  textBody = 'New Lead is created';
  currencyExchangeRate: any;
  exchangeRate: Boolean = false;
  EditNew: any;
  campaignText: any; campaignValue: any;
  campaignfilteredOptions: any[];
  ListTypeData=[];


  constructor(
    private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private masterLookupService: MasterLookupService,
    private leadService: LeadService,
    private configService: ConfigurationService,
    private emailService: EmailService,
    public activeModal: NgbActiveModal,
    private employeeInfoService: EmployeeInfoService,
    private globalService: GlobalService,

  ) { }

  ngOnInit() {
    this.leadForm = this.fb.group({
      LeadId: null,
      LeadStatus: '',
      LeadOwnerName: '',
      Salutation: '',
      FirstName: '',
      LastName: '',
      OwnerId: '',
      Title: '',
      CompanyName: '',
      Website: '',
      Email: '',
      IndustryId: '',
      Phone: '',
      Fax: '',
      NoofEmployees: '',
      Mobile: '',
      LeadSource: '',
      Rating: '',
      Description: '',
      SicCode: '',
      CurrentGenerators: '',
      ProductId: '',
      NoOfLocations: ' ',
      AnnualRevenue: '',
      LeadAmount: '',
      Currency: '',
      TerritoryID: '',
      FaceBookID: '',
      TwitterID: '',
      SkypeID: '',
      LinkdInId: '',
      ListTypeID:'',
      ExchangeRate: '',
      CampaignId: '',
      BillingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      })
    });
    this.getIndustryList();
    this.getAutoCompleteLeadStatus();
    this.getLeadSourceList();
    this.getRatingList();
    this.getAllTerritoryLookupList();
    this.getAllEmployeeInfo();
    this.getEditData();
    this.getEditNewName();
    this.getListTypesStatus();
  }

  getEditNewName() {
    if (this.leadForm.value.LeadId === null) {
      this.EditNew = 'New';
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      this.currencyText = user.CurrencyCode;
      this.currencyValue = user.CurrencyId;
      this.currencyExchangeRate = user.CurrencyId;
      this.ownerText = user.EmpName;
      this.ownerValue = user.EmpID;
      this.leadForm.controls['LeadOwnerName'].setValue(user.EmpID);
    } else {
      this.EditNew = 'Edit';
    }
  }

  getEditData() {
    if (this.leadFormData !== undefined) {

      this.leadForm.patchValue(this.leadFormData);

      this.ownerText = this.leadFormData.LeadOwnerName;
      this.ownerValue = this.leadFormData.OwnerId;

      this.currencyText = this.leadFormData.CurrencyName;
      this.currencyValue = this.leadFormData.Currency;

      this.productText = this.leadFormData.ProductName;
      this.productValue = this.leadFormData.ProductId;

      this.campaignText = this.leadFormData.CampaignName;
      this.campaignValue = this.leadFormData.CampaignId;
    }
  }

  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMLeads').subscribe(
      res => {
        this.ownerfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteEmployee(data) {
    const ownerId = data.data.option.value.Value;
    this.leadForm.controls['OwnerId'].setValue(ownerId);
    this.employeeInfoService.getEmployeeInfoList(ownerId).subscribe(resData => {
      this.fromName = resData.EmployeeName;
      this.toaddress = resData.EmailID;
    });
  }

  getAllEmployeeInfo() {
    this.employeeInfoService.getAllInfo().subscribe(infodata => {
      this.fromaddress = infodata.EmailID;
    });
  }

  getCurrencyList(value) {
    this.autocompleteService.getallCurrencyList(value.data).subscribe(
      res => {
        this.currencyfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteCurrency(data) {
    const currencyId = data.data.option.value.Value;
    this.leadForm.controls['Currency'].setValue(currencyId);
    if (this.currencyExchangeRate !== currencyId) {
      this.exchangeRate = true;
    } else {
      this.exchangeRate = false;
    }
  }

  getProductList(value) {
    this.autocompleteService.getAllProductList(value.data).subscribe(
      res => {
        this.currencyfilteredOptions = res;
        this.productfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteProduct(data) {
    const productId = data.data.option.value.Value;
    this.leadForm.controls['ProductId'].setValue(productId);
  }

  getCampaignList(value) {
    this.autocompleteService.getAllCampaignList(value.data).subscribe(
      res => {
        this.campaignfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteCampaign(data) {
    const campaignId = data.data.option.value.Value;
    this.leadForm.controls['CampaignId'].setValue(campaignId);
  }

  getIndustryList() {
    this.masterLookupService.getDropdownList('Industry').subscribe(data => {
      this.IndustryModel = data;
    });
  }

  getAutoCompleteLeadStatus() {
    this.leadService.getLeadStatusList().subscribe(data => {
      this.leadStatus = data;
      this.leadForm.controls['LeadStatus'].setValue(data[0]['Value']);
    });
  }

  getListTypesStatus() {
    this.masterLookupService.getDropdownList('List Type').subscribe(data => {
      this.ListTypeData = data;
    });
  }
  getLeadSourceList() {
    this.masterLookupService.getDropdownList('Lead Source').subscribe(data => {
      this.LeadSourceModel = data;
    });
  }

  getRatingList() {
    this.masterLookupService.getDropdownList('Rating').subscribe(data => {
      this.RatingModel = data;
    });
  }

  getAllTerritoryLookupList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }
  onSubmit(form: UntypedFormGroup) {
    const leadName = form.value.FirstName + ' ' + form.value.LastName;
    let responseMessage = [];
    if (form.value.LeadId == null) {
      form.value.ownerId = this.ownerValue;
      this.leadService.addLead(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showDefaultSuccess({ masterName: 'Lead' });
          this.sendEmail();
        }
        this.getData.emit();
      });
      // Update Method for Lead
    } else {
      this.leadService.updateLead(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Lead', name: leadName });
        }
        this.getData.emit();
      });
    }
    this.globalService.disposeToastr();
    this.activeModal.close();
    this.leadForm.reset();
  }

  sendEmail() {
    if (this.fromaddress != null && this.toaddress != null) {
      this.emailService
        .sendEmail({
          Subject: this.subject,
          FromAddress: this.fromaddress,
          ToAddress: this.toaddress,
          FromName: this.fromName,
          TextBody: this.textBody
        })
        .subscribe(senddata => { });
    }
  }
}
