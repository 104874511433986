import { DocumentService } from '../../../services/documentService/document.service';
import { Component, OnInit, EventEmitter } from '@angular/core';
// import { FileUploader } from 'ng2-file-upload';


@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {
  // public uploader: FileUploader = new FileUploader({
  //   disableMultipart: true,
  //   url: 'http://localhost:62817/api/upload/upload'
  // });
  public hasBaseDropZoneOver: Boolean = false;
  public hasAnotherDropZoneOver: Boolean = false;
  myFiles = [];
  fileData = [];
  fileObject: any;
  constructor(
    private documentService: DocumentService,
  ) { }

  ngOnInit() {
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }


  onFileSelected(event: File[]) {
    this.myFiles = event;
    let allPromises = [];
    let file;
    for (let i = 0; i < this.myFiles.length; i++) {
      file = this.myFiles[i];
      // console.log("file", file);
      allPromises.push(
        new Promise(resolve => {
          this.documentService.addDocument({ file })
            .subscribe(
              data => {
                resolve(data);
                // console.log('resole', data);
              });
        })
    );
    }
    Promise.all(allPromises).then(data => {
      // console.log("data", data);
    });
  }

}
