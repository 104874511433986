import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';

import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OpportunityService } from '../../../services/opportunity/opportunity.service';
import { Router, ActivatedRoute } from '@angular/router';
import { OpportunityStageComponent } from '../opportunity-stage/opportunity-stage.component';
import { VariableService } from '../../../services/VariableService/variable.service';
import { NewQuoteComponent } from '../../quotation-page/new-quote/new-quote.component';
import { ContactService } from '../../../services/contact/contact.service';
declare var SkypeWebControl: any;
@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.css']
})
export class OpportunityComponent implements OnInit {

  @ViewChild(OpportunityStageComponent)
  opportunityStageComponent: OpportunityStageComponent;
  @ViewChild(NewQuoteComponent) newQuoteComponent: NewQuoteComponent;

  opportunitystageData: any;
  opportunityData: any;
  quoteData: any;
  NewsOn = false;
  DetailOn = false;
  activityOn = true;
  newChatterOn = false;
  contactData: any;
  rfqOn = false;
  convertOpportunityData: any = {};
  showSpinner: Boolean = true;
  selectedPage: any = {};

  constructor(
    private route: ActivatedRoute,
    private opportunityService: OpportunityService,
    private variableService: VariableService,
    private contactService: ContactService,
    private topnavbarService: TopnavbarService,
    public router: Router
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.variableService.setSearchKeys({
        paramId: params.id,
        type: 'CRMOpportunity'
      });

      this.opportunityService.editOpportunityById(params.id).subscribe(data => {
        this.opportunityData = data;
        this.convertOpportunityData = data;
        this.quoteData = data;
        this.getContactOpportunity(data.ContactId);
        this.showSpinner = false;
      });
      // Get Opportunity Stage Data
      // this.opportunityStageComponent.getOpportuniyStage(params.id);
      // Get New quote Data
      // this.newQuoteComponent.getAllQuotesType();
    });
  }

  skypeChat(id) {
    SkypeWebControl.SDK.Chat.startChat({
      ConversationId: id
    });
  }

  getContactOpportunity(contactId) {
    if (contactId !== null) {
      this.contactService
        .getContactById(contactId, 'contact')
        .subscribe(data => {
          this.contactData = data;
        });
    }
  }

  gotoDetailPage(id) {
    const route = 'accountDetail/' + id;
    this.router.navigate([route]);
  }

  OnDetail() {
    this.DetailOn = true;
    this.NewsOn = false;
    this.activityOn = false;
    this.newChatterOn = false;
    this.rfqOn = false;
  }

  OnNews() {
    this.NewsOn = true;
    this.DetailOn = false;
    this.activityOn = false;
    this.newChatterOn = false;
    this.rfqOn = false;
  }

  onRfq() {
    this.NewsOn = false;
    this.DetailOn = false;
    this.activityOn = false;
    this.newChatterOn = false;
    this.rfqOn = true;
  }

  onActivity() {
    this.activityOn = true;
    this.NewsOn = false;
    this.DetailOn = false;
    this.newChatterOn = false;
    this.rfqOn = false;
  }

  OnChatter() {
    this.activityOn = false;
    this.newChatterOn = true;
    this.NewsOn = false;
    this.DetailOn = false;
    this.rfqOn = false;
  }
}
