import { Subscription } from 'rxjs/Subscription';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultipleAddressService } from './../../../services/multiple-Address/multiple-address.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Component, OnInit, ViewContainerRef, OnDestroy, Input } from '@angular/core';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { DeleteModalComponent } from '../../../../../common/delete-modal/delete-modal.component';
import { GlobalService } from '../../../services/global-Service/global.service';

declare var $: any;

@Component({
  selector: 'app-mailing-address',
  templateUrl: './mailing-address.component.html',
  styleUrls: ['./mailing-address.component.css']
})
export class MailingAddressComponent implements OnInit, OnDestroy {
  StateList: AutocompleteModel[];
  CountryList: AutocompleteModel[];
  DistrictList: AutocompleteModel[];
  subscription: Subscription;
  showSpinner: Boolean = true;
  columnName = [];
  columnRowName = [];
  stateCode: any;
  countryCode: any;
  @Input() deleteContentData: any;
  mailingAddressForm: UntypedFormGroup;
  security = false;
  address = true;
  multipleAddressData: any;
  isUT = false;
  editName = '';
  constructor(
    private fb: UntypedFormBuilder,
    private masterLookupService: MasterLookupService,
    private multipleAddressService: MultipleAddressService,
    public modalService: NgbModal,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.mailingAddressForm = this.fb.group({
      MailingAddressId: null,
      AddressType: '',
      Mailing: '',
      PANNo: '',
      CINNo: '',
      GSTIN: '',
      VATNo: '',
      MultipleAddress: new UntypedFormGroup({
        Address1: new UntypedFormControl(),
        Address2: new UntypedFormControl(),
        Address3: new UntypedFormControl(),
        CityName: new UntypedFormControl(),
        DistrictCode: new UntypedFormControl(),
        State: new UntypedFormControl(),

        CountryCode: new UntypedFormControl(),
        PostalCode: new UntypedFormControl(),
        Phone: new UntypedFormControl(),
        Fax: new UntypedFormControl(),
        Mobile: new UntypedFormControl(),

        Email: new UntypedFormControl(),
        Website: new UntypedFormControl(),
        IsUnionTerritory: new UntypedFormControl(),
      })
    });
    this.getAllCountryList();
    this.getAllAddress();
    this.columnName = ['Address Type', 'Mailing'];
    this.columnRowName = ['AddressType', 'Mailing'];
  }
  OnAddress() {
    this.address = true;
    this.security = false;
  }
  onCompanyRight() {
    this.address = false;
    this.security = true;
  }

  getAllAddress() {
    this.subscription = this.multipleAddressService.getMultipleAddress().subscribe(data => {
      this.multipleAddressData = data;
      this.showSpinner = false;
    });
  }

  getAllCountryList() {
    this.subscription = this.masterLookupService.getCountryList().subscribe(data => {
      this.CountryList = data;
    });
  }

  onCountryStateSelect(value) {
    this.countryCode = value;
    if (this.countryCode !== '') {
      this.subscription = this.masterLookupService.getStateList(this.countryCode).subscribe(data => {
        this.StateList = data;
      });
    }
  }

  onStateDistrictSelect(value, countrycode) {
    this.stateCode = value;
    if (this.stateCode !== '') {
      this.subscription = this.masterLookupService.getDistrictList(this.stateCode, this.countryCode).subscribe(data => {
        this.DistrictList = data;
      });
    }
  }

  onDelete(data) {
    this.deleteContentData = ({ id: data.data.MailingAddressId, name: data.data.AddressType, page: 'MultipleAddress' });
    const modalRef = this.modalService.open(DeleteModalComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.deleteData = this.deleteContentData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAllAddress();
    });
  }

  editAddress(editdata) {
    const id = editdata.data.MailingAddressId;
    this.editName = editdata.data.AddressType;
    this.subscription = this.multipleAddressService.editById(id).subscribe(data => {
      this.mailingAddressForm.patchValue(data);
      this.onCountryStateSelect(data.MultipleAddress.CountryCode); // State
      this.onStateDistrictSelect(data.MultipleAddress.State, data.MultipleAddress.CountryCode);
    });
    $('html, body').animate({
      scrollTop: $('.column_editor').offset().top - 150
    }, 300);
  }

  onSubmit(form: UntypedFormGroup) {
    let responseMessage = [];
    const addressName = form.value.AddressType;
    if (form.value.MailingAddressId === null) {
      this.subscription = this.multipleAddressService.addMultipleAddress(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[2] === '0') {
          this.globalService.showDefaultSuccess({ masterName: addressName });
        }
        this.getAllAddress();
      });
    } else {
      this.subscription = this.multipleAddressService.updateMultipleAddress(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[2] === '0') {
          this.globalService.showDefaultUpdate({ masterName: addressName });
        }
        this.getAllAddress();
      });
    }
    this.globalService.disposeToastr();
    this.mailingAddressForm.reset();
  }

  resetForm() {
    this.mailingAddressForm.reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
