import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder
} from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.css']
})
export class AccountDetailComponent implements OnInit {
  @Input() accountData: any;
  accountForm: UntypedFormGroup;
  public RatingModel: AutocompleteModel[];
  public PriorityModel: AutocompleteModel[];
  public TerritoryLookup: AutocompleteModel[];

  constructor(
    private masterLookupService: MasterLookupService,
    private configService: ConfigurationService,
    private fb: UntypedFormBuilder,

  ) { }

  ngOnInit() {
    this.accountForm = this.fb.group({
      AccountOwnerId: '',
      AccountName: new UntypedFormControl('', Validators.required),
      AccountSite: '',
      AccountId: null,
      Type: '',
      ParentId: '',
      Website: '',
      Phone: '',
      Description: '',
      IndustryId: '',
      NoOfEmployees: '',
      Email: '',
      Rating: '',
      AccountOwnerName: '',
      Fax: '',
      AccountNumber: '',
      TickerSymbol: '',
      AnnualRevenue: '',
      SicCode: '',
      AccountSource: '',
      Active: '',
      CleanStatus: '',
      Priority: '',
      SLA: '',
      SLANo: '',
      SLADate: '',
      NoOfLocations: '',
      Ownership: '',
      UpsellOpportunity: '',
      TerritoryName: '',

      IndustryName: '',
      AccountType: '',
      ParentName: '',
      BillingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      }),
      ShippingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      })
    });
    this.accountForm.patchValue(this.accountData);
    this.getRatingList();
    this.getPriorityList();
    this.getTerritoryList();
  }

  getRatingList() {
    this.masterLookupService.getDropdownList('Rating').subscribe(data => {
      this.RatingModel = data;
    });
  }

  getPriorityList() {
    this.masterLookupService.getDropdownList('Priority').subscribe(data => {
      this.PriorityModel = data;
    });
  }

  getTerritoryList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryLookup = data;
    });
  }

}
