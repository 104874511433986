import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class RoleService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  getRoles() {
    return this.apiService.get(`api/role/getall?`);
  }

  // Post Method
  addRole(form) {
    return this.apiService.post('api/role/create', form);
  }


  editRoleById(roleId) {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/role/getbyid?Id=${roleId}&MasterType=${q.type}&`);
  }

  updateRole(form) {
    return this.apiService.put('api/role/edit', form);
  }

}
