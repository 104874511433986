import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.css']
})
export class ContractDetailComponent implements OnInit {
  contractForm: UntypedFormGroup;
  @Input() contractData: any;

  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.contractForm = this.fb.group({
      ContractId: null,
      OwnerId: '',
      StartDate: new Date(),
      ContractNumber: '',
      ContractTerm: '',
      AccountId: '',
      OwnerExpirationNotice: '',
      Status: '',
      Description: '',
      SpecialTerms: '',
      ContractName: '',
      CustomerSignedId: '',
      CustomerSignedTitle: '',
      CompanySignedDate: new Date(),
      CustomerSignedDate: new Date(),
      CompanySignedBy: '',
      AccountName: '',
      ContractOwnerName: '',
      StatusName: '',
      CustomerSignedName: '',
      BillingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      })
    });
    this.contractForm.patchValue(this.contractData);
  }
}
