<div class="tab_title">
  <h3 class="page_title pull-left">
    <span class="menu_icon CRMProjects fa fa-cubes">
    </span>
    Projects ({{projects.length}})
  </h3>
</div>

<div class="related_details">
  <div class="row">
    <div class="col-md-6 col-xs-12 mb-10" *ngFor="let item of projects">
      <a class="dottedLine" (click)="gotoDetailPage(item.ProjectId)" href="javascript:void(0)">{{item.ProjectName}}</a>
      <table class="custom_table">
        <tr>
          <th class="custom_name">Contact Name</th>
          <th class="custom_colon">:</th>
          <td class="caseheadLine">{{item.ContactName}}</td>
        </tr>

        <tr>
          <th class="custom_name">Project No</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{item.ProjectNo}}</td>
        </tr>

        <tr>
          <th class="custom_name">Team Lead</th>
          <th class="custom_colon">:</th>
          <td class="custom_detail_name">{{item.TeamLeadName}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>