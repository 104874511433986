import { QuoteLineItemComponent } from './../quote-line-item/quote-line-item.component';
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteProductService } from '../../../services/quoteProduct/quote-product.service';

@Component({
  selector: 'app-quote-related',
  templateUrl: './quote-related.component.html',
  styleUrls: ['./quote-related.component.css']
})
export class QuoteRelatedComponent implements OnInit {
  @Input() searchKeys;
  @Input() quoteProduct: Array<any> = [];
  constructor(private modalService: NgbModal,
    private quoteProductService: QuoteProductService,
  ) {
  }
  ngOnInit() {
    this.getAllQuoteProduct();
  }


  getAllQuoteProduct() {
    this.quoteProductService.getQuoteProduct('Quote').subscribe(data => {
      this.quoteProduct = data;
    });
  }

  open() {
    const modalRef = this.modalService.open(QuoteLineItemComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'custom-modal',
      keyboard: false
    });
    modalRef.componentInstance.quoteProduct = this.quoteProduct;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getAllQuoteProduct();
    });
  }
}
