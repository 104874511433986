<div class="body_inner">
    <div class="">
        <div class="topheader">
            <div class="topheader">
                <div class="topheadertitle">
                    <span class="menu_icon CRMLeads glyphicon glyphicon-briefcase">
                    </span>
                    <h3 class="report_page_title">
                        <p class="page_title_cont">Leads</p>
                        <p class="page_sub_title">{{leadData.Name}}</p>
                    </h3>
                </div>
                <div class="">
                    <div class="topdownheader ">
                        <div class="">
                            <div class="block">
                                <label class="labelName">Title</label>
                                <p>{{leadData.Title}}</p>
                            </div>

                            <div class="block">
                                <label class="labelName">Company</label>
                                <p>{{leadData.CompanyName}}</p>
                            </div>

                            <div class="block">
                                <label class="labelName">Phone</label>
                                <p>{{leadData.Phone}}</p>
                            </div>

                            <div class="block">
                                <label class="labelName">Email</label>
                                <p>
                                    <a href="mailto:{{leadData.Email}}">{{leadData.Email}}</a>
                                </p>
                            </div>

                            <!-- <div class="block">
                                <label class="labelName">Skype</label>
                                <p class="ownerHeading" (click)="skypeChat(leadData.SkypeID)">
                                    <span class="fab fa-skype"></span>
                                    <span class="skype-chat" style="cursor: pointer;">
                                    </span>
                                    {{leadData?.SkypeID}}
                                </p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class=" ">
        <div class="steps_bar" *ngIf="!showSpinner">
            <app-lead-status [leadStatusData]="leadStatusData && leadStatusData.LeadId" [convertLeadData]="convertLeadData">
            </app-lead-status>
        </div>

        <div class="row">
            <div class="col-md-8 lowpadding-right">
                <nav class="navbar navbar-default custom_navbar">
                    <div class="nav nav-bar-inner">
                        <ul>
                            <li class="realted">
                                <a class="" (click)=onActivity() [ngClass]="{'active': activityOn===true}">Activity</a>
                            </li>
                            <li class="realted ">
                                <a class=" " (click)=OnChatter() [ngClass]="{ 'active': newChatterOn===true} ">Chatter</a>
                            </li>
                            <li class="realted">
                                <a class="" (click)=OnDetail() [ngClass]="{'active':DetailOn===true}">Details</a>
                            </li>
                            <!-- <li class="realted">
                                <a class="" (click)=OnNews() [ngClass]="{'active': NewsOn===true}">NEWS</a>
                            </li> -->
                        </ul>
                    </div>
                    <ul class="cl custom_content_tab">
                        <li class="nav-item">
                            <app-activity *ngIf="activityOn===true"></app-activity>
                        </li>
                        <li class="nav-item ">
                            <app-chatter *ngIf="newChatterOn===true "></app-chatter>
                        </li>
                        <li class="nav-item disabled_form">
                            <app-lead-detail [leadData]="leadData" *ngIf="DetailOn===true && leadData.LeadId"></app-lead-detail>
                        </li>
                        <li class="nav-item">
                            <app-news *ngIf="NewsOn===true"></app-news>
                        </li>
                    </ul>
                </nav>
            </div>

            <div class="col-md-4 bg_white_margin_top no_boxshadow">
                <nav class="navbar navbar-default custom_navbar">
                    <ul class="cl custom_content_tab">
                        <li class="nav-item">
                            <app-lead-related-detail [searchKeys]="searchKeys"></app-lead-related-detail>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
    </div>
</div>