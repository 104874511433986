
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-htmldatatable',
  templateUrl: './htmlDataTable.component.html',
  styleUrls: ['./htmlDataTable.component.css']
})
export class HtmlDataTableComponent implements OnInit {

  @Input() content: any;
  @Input() columnName: any;
  @Input() columnRowName: any;
  @Input() tableData: any;
  @Output() edit = new EventEmitter();
  @Output() onDelete = new EventEmitter();


  constructor(
  ) {

  }
  ngOnInit() {
  }
}
