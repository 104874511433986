<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon report_detail fa fa-list-alt">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Report</p>
        <p class="page_title_cont"><b>{{reportName}}</b></p>

      </h3>
    </div>
    <div class="text-right mt-2 mr-4">
      <div class="dropdown" style="z-index: 1;">
        <button class="buttonNew add_task" type="button" data-toggle="dropdown">
          <!-- <span class="menu_icon export">
          </span> -->
          <i class="fa fa-cog fa-fw" aria-hidden="true"></i>
        </button>
        <ul class="dropdown-menu add_task_dropdown create_menu">
          <li>
            <a href="javascript:void(0)" (click)="exportTo('excel')">
              Excel
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="exportTo('csv')">
              Csv
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="exportTo('pdf')">
              Pdf</a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="email()">
              Email</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- <div class="well bg_white_margin_top min_top report_table_cont pt-4 pb-4">
    

  </div> -->
  <div class="table-container table-report-detail" >
    <custom-table (getData)="getReportsData($event)" [columns]="columnName" [showCheckbox]=false [rows]="reports" [page]="page" [showFilter]="false" [showSearch]="false" [showEdit]="false"
     >
  </custom-table>
  </div>
</div>