
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { CompanyList } from './../../product/crm/model/companyListModel/company-list';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit {
  @Input() autoCompleteText = '';
  @Input() autoCompleteValue: any;
  @Input() key: any;
  @Output() setAutoComplete = new EventEmitter();
  @Output() fiteredData = new EventEmitter();
  @Input() filteredOptions: any;
  filteredUsers: any;
  public selectedOption: any;
  progress=false;
  @ViewChild('textInput') textInput: ElementRef;
  constructor(
  ) { }
  ngOnInit() {
    this.getAutoCompleteData();
    
  }
  ngOnChanges(){
    this.progress=false;
  }
  getAutoCompleteData() {
    if (this.autoCompleteText === undefined) {
      return false;
    } else {
      const obj = {
        Text: this.autoCompleteText,
        Value: this.autoCompleteValue,
      };
      setTimeout(() => {
        this.textInput.nativeElement.value = obj.Text;
      }, 1000);
      this.selectedOption = obj;
      this.progress=false;
    }
  }

  displayFn(user: CompanyList) {
    this.selectedOption = user;
    return user ? user.Text : '';
  }

  setfiteredData(data: any) {
    if (data.data.length >= 0) {
      this.progress=true;
      this.fiteredData.next(data);
    }
  }
}
