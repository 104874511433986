import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-campaign-related-detail',
  templateUrl: './campaign-related-detail.component.html',
  styleUrls: ['./campaign-related-detail.component.css']
})
export class CampaignRelatedDetailComponent implements OnInit {
  @Input() searchKeys;
  constructor() { }

  ngOnInit() {
  }

}
