import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiService } from '../ApiService/api.service';
import { VariableService } from '../VariableService/variable.service';

@Injectable()
export class TemplateService {

  constructor(private apiService: ApiService, private variableService: VariableService) { }

  getTemplateList() {
    return this.apiService.get('api/templateLookup/GetTemplateLookupNamesList' + '?prefixText=' + '&Id=' + '&');
  }

  addTermTemplate(form) {
    return this.apiService.post('api/termsTemplate/create', form);
  }

  getTermTemplate() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/termsTemplate/getall?Id=${q.paramId}&`);
  }

  getERPTerm(termId) {
    return this.apiService.get(`api/termsTemplate/getallterm?Id=${termId}&`);
  }
  getEtemplates(type){
    return this.apiService.get(`api/emailtemplate/GetEmailTemplateNamesList?DropdownType=${type}&`)
  }
}
