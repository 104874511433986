import { Injectable } from '@angular/core';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { ApiService } from '../../ApiService/api.service';
import { VariableService } from '../../VariableService/variable.service';
@Injectable()
export class ResourceDetailService {

  constructor(
    private apiService: ApiService,
    private variableService: VariableService
  ) { }

  addResourceDetail(form) {
    return this.apiService.post('api/resourceDetail/create', form);
  }
  getResourceDetail() {
    const q: any = this.variableService.getSearchKeys();
    return this.apiService.get(`api/resourceDetail/getall?BaseId=${q.paramId}&`);
  }

}
