import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Editor } from 'ngx-editor';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../services/common/common/common.service';
import { CampaignService } from '../../../services/campaigns/campaign.service';
import { TemplateService } from '../../../services/template/template.service';

@Component({
  selector: 'app-campain-modal',
  templateUrl: './campain-modal.component.html',
  styleUrls: ['./campain-modal.component.css']
})
export class CampainModalComponent implements OnInit {
  campainForm:UntypedFormGroup;
  senderDetails=[];
  templateArr = [];
  constructor(    
    public fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private templateService:TemplateService,
    private campaignService:CampaignService,
    ) { 
      this.campainForm = this.fb.group({
        Type: '',
        templateId: '',
        senderEmails: this.senderDetails || []
      })
    }

  ngOnInit(): void {
  }
  onSubmit(form){
    let formData = {...form.value,IsScheduled:false}
    this.campaignService.scheduler(formData).subscribe((data)=>{
      console.log(data);
    })
  }
  onEntity(value) {
    this.templateService.getEtemplates(value).subscribe((data)=>{
      this.templateArr = data;
    })
  }
}
