import { LeadService } from '../../../services/lead/lead.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LeadStatusComponent } from '../lead-status/lead-status.component';
import { VariableService } from '../../../services/VariableService/variable.service';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { ScriptService } from '../../../services/scriptFile/script.service';

declare var SkypeWebControl: any;
@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.css']
})
export class LeadComponent implements OnInit {
  @ViewChild(LeadStatusComponent) leadStatusComponent: LeadStatusComponent;
  leadData: any = {};
  searchKeys: object = {};
  selectedPage: any = {};
  convertLeadData: any = {};
  leadStatusData: any;
  NewsOn = false;
  DetailOn = false;
  activityOn = true;
  newChatterOn = false;
  showSpinner: Boolean = true;

  constructor(
    private route: ActivatedRoute,
    private leadService: LeadService,
    public variableService: VariableService,
    private topnavbarService: TopnavbarService,
    private scriptService: ScriptService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.variableService.setSearchKeys({
        type: 'CRMLead',
        paramId: params.id
      });

      this.leadService.leadEditById(params.id).subscribe(data => {
        this.leadData = data;
        this.convertLeadData = data;
        this.showSpinner = false;
        this.scriptService.load('skype').then(res => {
          console.log(res);
        }).catch(error => console.log(error));
      });

      // get Lead status
      // this.leadStatusComponent.getLeadStatusData(params.id);
    });
  }

  OnDetail() {
    this.DetailOn = true;
    this.NewsOn = false;
    this.activityOn = false;
    this.newChatterOn = false;
  }

  OnNews() {
    this.NewsOn = true;
    this.DetailOn = false;
    this.activityOn = false;
    this.newChatterOn = false;
  }

  onActivity() {
    this.activityOn = true;
    this.NewsOn = false;
    this.DetailOn = false;
    this.newChatterOn = false;
  }

  OnChatter() {
    this.activityOn = false;
    this.newChatterOn = true;
    this.NewsOn = false;
    this.DetailOn = false;
  }

  skypeChat(id) {
    SkypeWebControl.SDK.Chat.startChat({
      ConversationId: id
    });
  }
}
