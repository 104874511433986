import { AbstractControl, ValidatorFn } from '@angular/forms';

export class NumberValidators {

    static isNumber(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if ((c.value || c.value === 0) && !isNaN(c.value)) {
                return { range: true };
            }
            return null;
        };
    }
}