<!-- Wrapper-->
<div id="wrapper">

  <!-- Top navigation -->
  <topnavbar (calcWidth)="calcWidth($event)"></topnavbar>

  <!-- Main view/routes wrapper-->
  <div class="container-fluid">

    <sidemenubar></sidemenubar>

    <!-- <app-bottomnavbar (toggle)="toggle()" (onCloseClick)="onCloseClick()" [show]="show">
    </app-bottomnavbar> -->


    <div class="main_body">
      <router-outlet></router-outlet>
    </div>


  </div>

</div>
<!-- End wrapper-->